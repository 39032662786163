import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFeature } from "../features/claim-payments.feature";
import { ClaimPaymentsFacade } from "./claim-payments.facade";
import { DamageClaimNavigateValidationFacade } from "./damage-claim-navigate-validation.facade";

const CLAIM_CURRENT_PATH: string = CLAIM_PAYMENTS_PATH.VEHICLE_DATA;
const AGREED_CURRENT_PATH: string = AGREED_PAYMENTS_PATH.VEHICLE_DATA;

/**
 *
 */
export namespace VehicleDataFacade {

  /** */
  export const useVehicleDataFacade = () => {
    const { currentDamageClaim, currentVehicle, claimVehicle, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>();
    const [vehicle, updateVehicle] = useState<Vehicle | undefined>();
    const allowUpdate: MutableRefObject<boolean> = useRef<boolean>(true);
		const dispatch = useDispatch();

		/** */
		const scrollToTop = () => {
			window.scrollTo({top: 0});
		}

    /**
     * 
     * @param vehicle 
     */
    const selectVehicle = (_vehicle?: Vehicle) => {
      if (allowUpdate.current) {
        if (_vehicle)
          setSelectedVehicle(_vehicle);
        else if (_vehicle === undefined && selectedVehicle)
          setSelectedVehicle(undefined);
      }
    }

    /**
     * 
     */
    useEffect(() => {
      if (currentVehicle?.vin) {
        setSelectedVehicle(currentVehicle);
        updateVehicle(undefined);
        updateVehicle(currentVehicle);
      } else if (claimVehicle?.plate) {
        setSelectedVehicle(claimVehicle);
        updateVehicle(undefined);
        updateVehicle(claimVehicle);
      }
      //return () => { allowUpdate.current = false }
    }, [currentVehicle, claimVehicle, allowUpdate]);

		useEffect(() => {
			return () => { allowUpdate.current = false }
		}, [allowUpdate])

		useEffect(() => {
			dispatch(ClaimPaymentsAction.updateCurrentPath(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH));
		}, [dispatch, isAgreedPayment]);

		useEffect(() => {
			scrollToTop();
		}, [currentDamageClaim]);

    return { currentDamageClaim, currentVehicle, selectedVehicle, vehicle, claimVehicle, isAgreedPayment, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, selectVehicle };
  };

  /** */
  export const useCreateVehicle = () => {
    /** */
    const [isLoading, updateIsLoading] = useState(false);
    const { currentDamageClaim, currentVehicle, claimVehicle, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
    const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();
		const dispatch = useDispatch();

    /**
     * @param newVehicle 
     * @returns 
     */
    const createVehicle = async (newVehicle: Vehicle): Promise<void> => {
      try {
        updateIsLoading(true);
				if(currentVehicle?.vin)
					newVehicle.vin = currentVehicle.vin;
				else if(claimVehicle?.vin)
					newVehicle.vin = claimVehicle.vin;
				
        await ClaimPaymentsFeature.updateVehicleDataFeature(newVehicle!, currentDamageClaim!);
				
        currentDamageClaim!.vehicle.vin = newVehicle.vin;
				dispatch(ClaimPaymentsAction.storeDamageClaim(currentDamageClaim!));

				dispatch(ClaimPaymentsAction.storeClaimVehicle(newVehicle));
        navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward');
      } catch (e) {
        console.error(e);
      } finally {
        updateIsLoading(false);
      }
    }

    return { isLoading, createVehicle }
  }

}
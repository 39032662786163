import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IPictureUploadRequest } from "../interfaces/IPictureUploadRequest";
import { IPictureUploadResponse } from "../interfaces/IPictureUploadResponse";

export namespace PictureManagementService {

  /**
   * 
   * @param data 
   */
	 export const updateClaimPictureService = (data: IPictureUploadRequest): AxiosPromise<IPictureUploadResponse> => {
    return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).put(Util.API_PATH.CLAIM_UPDATE_PICTURE(data.id!, data.requirement!, data.hash!), data.picture,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  /**
   * 
   * @param data 
   */
  export const uploadClaimPictureService = (data: IPictureUploadRequest): AxiosPromise<IPictureUploadResponse> => {
    return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).put(Util.API_PATH.CLAIM_UPLOAD_PICTURE(data.id!, data.requirement!), data.picture,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }
}
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Plan } from "src/classes/plan/Plan";
import { Policy } from "src/classes/Policy";
import { POLICY_RENEWAL_PATH } from "src/routes/paths/policy-renewal.paths";
import { PolicyRenewalFacade } from "./policy-renewal.facade";

export namespace PlanPaymentRenewalFacade {


  /**
   * 
   */
  export const usePlanPayment = (plan: Plan) => {

    const { user  } = PolicyRenewalFacade.usePolicyRenewalActors();
    const { policyNumber } = useParams<string>();
    const navigate = useNavigate();

    /**
     * 
     */
    const onCheckoutDone = async (policy: Policy): Promise<void> => {
			const userEmail: string = user.email;
			navigate(`${POLICY_RENEWAL_PATH.POLICY_RENEWAL_COMPLETION(policyNumber!)}?user=${userEmail}&policy=${policy?.policyNumber}`, { replace: true });
    }

    useEffect(
      () => {
        if (!plan) {
          navigate(`${POLICY_RENEWAL_PATH.POLICY_RENEWAL}/${policyNumber}`, {replace: true});
        }
      }, [plan, navigate, policyNumber]
    )

    return { onCheckoutDone }
  }

}
import { AuthToken } from "src/classes/AuthToken"
import { Util } from "src/utils/Util";

export namespace CoreTokenStorageFeature {

  interface ITokenStorage {

    accessToken: string;

    refreshToken: string;
  }

  /**
   * 
   */
  export const saveTokenToStorageFeature = async (authToken: AuthToken): Promise<void> => {
    try {
      let _storage: ITokenStorage = {
        accessToken: authToken.access,
        refreshToken: authToken.refresh,
      }

      localStorage.setItem(Util.KEY.TOKEN_STORAGE, JSON.stringify(_storage));
    } catch (e) {
      console.error('Error saveTokenToStorageFeature', e);
    }
  }

  /**
   * 
   */
   export const removeTokenFromStorageFeature = async (): Promise<void> => {
    try {
      localStorage.removeItem(Util.KEY.TOKEN_STORAGE);
    } catch (e) {
      console.error('Error removeTokenFromStorageFeature', e);
    }
  }

  /**
   * 
   * @returns 
   */
  export const getTokenFeature = (bearer?: boolean): string => {
    const token: ITokenStorage = JSON.parse(localStorage.getItem(Util.KEY.TOKEN_STORAGE) as string);

    return token ? `${bearer? 'Bearer ' : ''}${token.accessToken}` : '';
  }

  /**
   * 
   */
  export const verifyTokenFeature = (): boolean | undefined => {
    try {
      const token = localStorage.getItem(Util.KEY.TOKEN_STORAGE);

      return token != null ? true : false;
    } catch (e) {
      console.error('Error verifyTokenFeature', e);
    }
  }
}

import { AxiosPromise } from "axios";
import { Util } from "src/utils/Util";
import { IAddressData } from "../interfaces/address/IAddressData";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";

export namespace CoreAddressService {

	/**
   * 
   * @returns 
   */
	export const validatePostalCodeService = (postalCode: string): AxiosPromise<IAddressData> => {
		return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VERIFY_POSTAL_CODE(postalCode));
	}
}
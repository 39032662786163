import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Plan } from "src/classes/plan/Plan";
import { Policy } from "src/classes/Policy";
import { IPolicyRenewalStateReducer } from "src/store/states/modules-state/IPolicyRenewalStateReducer";

/**
 *
 */
export namespace PolicyRenewalSlice {
  /**
   * 
   */
  const initialState: IPolicyRenewalStateReducer = {
    currentPolicy: undefined,
    invalidPolicy: false,
    plan: undefined
  };

  /**
   * 
   * @param state 
   */
  const clear: CaseReducer<IPolicyRenewalStateReducer> = (state) => {
    state.currentPolicy = undefined;
    state.invalidPolicy = false;
    state.plan = undefined;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const currentPolicy: CaseReducer<IPolicyRenewalStateReducer, PayloadAction<Policy>> = (state, action) => {
    state.currentPolicy = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const setPlan: CaseReducer<IPolicyRenewalStateReducer, PayloadAction<Plan>> = (state, action) => {
    state.plan = action.payload;
  }

  /**
   * 
   * @param state 
   */
  const removePlan: CaseReducer<IPolicyRenewalStateReducer> = (state) => {
    state.plan = undefined;
  }


  /**
   * 
   * @param state 
   * @param action 
   */
  const invalidPolicy: CaseReducer<IPolicyRenewalStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.invalidPolicy = action.payload;
  }

  const reducers = {
    clear,
    currentPolicy,
    invalidPolicy,
    setPlan,
    removePlan
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'policyRenewal',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IExchangeRewardRequest } from "../interfaces/IExchangeRewardRequest";
import { IRewardsData } from "../interfaces/IRewardsData";
import { IRewardsHistoryData } from "../interfaces/IRewardsHistoryData";
import { IRewardsHistoryRequest } from "../interfaces/IRewardsHistoryRequest";

/**
 *
 */
export namespace ReferralsService {

  /**
   * 
   * @returns 
   */
  export const rewardsService = (): AxiosPromise<IRewardsData> => {
    return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).get(Util.API_PATH.REFERRALS_REWARDS);
  }

  /**
   * 
   * @returns 
   */
  export const exchangeRewardService = (request: IExchangeRewardRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).post(Util.API_PATH.EXCHANGE_REWARD, request);
  }

  /**
   * 
   * @returns 
   */
  export const rewardsHistoryService = (params: IRewardsHistoryRequest): AxiosPromise<IRewardsHistoryData> => {
    return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).get(Util.API_PATH.REWARDS_HISTORY, { params });
  }
}
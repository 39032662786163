import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InsuranceVehicleAction } from "../actions/insurance-vehicle.action";

export namespace InsuranceCompletionFacade {

  export const useInsuranceCompletion = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateRoot = () => {
      navigate('/', {replace: true});
    }

    useEffect(
      () => {
        dispatch(InsuranceVehicleAction.picturesCompleted(true));
      }, [dispatch]
    )

    return { navigateRoot }
  }
}
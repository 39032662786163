import { Spinner } from 'src/components';
import { IconFactory } from 'src/factory/icon.factory';
import { PolicyPaymentHistoryFacade } from 'src/modules/policy/controller/facades/policy-payment-history.facade';
import { PolicyFacade } from 'src/modules/policy/controller/facades/policy.facade';
import { Util } from 'src/utils/Util';
import './PolicyPaymentHistory.scss';

/**
 *
 */
const PolicyPaymentHistoryPage = (): JSX.Element => {
  const { selectedPolicy } = PolicyFacade.usePolicyManagement();
  const { nextRecord, loading } = PolicyPaymentHistoryFacade.usePaymentHistory();

  return (
    <div className="policyPaymentHistory height-100 relative flex column">
      {
        selectedPolicy ?
          <>

            <div className="policyPaymentHistory__info flex align-center justify-center">
              <>
                {
                  !loading.value ?
                    <>
                      {
                        nextRecord &&
                        <p>Pr&oacute;xima fecha de pago <br /><b>{Util.TRANSFORM.DATE.formatDate(nextRecord.date)}</b></p>
                      }
                    </>
                    :
                    <Spinner color="main" relative/>
                }
              </>
            </div>

            <ul className="policyPaymentHistory__list">
              {
                selectedPolicy.paymentHistory.map(
                  (record, index) => {
                    return (
                      <li className="policyPaymentHistory__list__item flex align-center justify-center" key={`record-${index}`}>
                        <span className={`policyPaymentHistory__list__item__counter flex align-center justify-center relative 
                          ${index === 0 ? 'first' : ''} ${(index + 1) === selectedPolicy.paymentHistory.length ? 'last' : ''}`}
                        >
                          {record.count}
                        </span>
                        <div className="policyPaymentHistory__list__item__content flex-grow-100">
                          <section className="policyPaymentHistory__list__item__content__section flex column space-around">
                            <div className="policyPaymentHistory__list__item__content__section__status flex align-center justify-center">
                              {
                                record.isPaid() &&
                                <p className="policyPaymentHistory__list__item__content__section__status__value policyPaymentHistory__list__item__content__section__status__value--paid">
                                  Pagado
                                </p>
                              }
                              {
                                record.isPending() &&
                                <p className="policyPaymentHistory__list__item__content__section__status__value policyPaymentHistory__list__item__content__section__status__value--pending">
                                  Pendiente
                                </p>
                              }
                              {
                                record.isPaymentError() &&
                                <p className="policyPaymentHistory__list__item__content__section__status__value policyPaymentHistory__list__item__content__section__status__value--error">
                                  Error
                                </p>
                              }
                              <h2>{Util.TRANSFORM.CURRENCY.MXN(record.amount)}</h2>
                            </div>
                            <p>Fecha: {Util.TRANSFORM.DATE.formatDate(record.date)}</p>
                          </section>
                          <section className="policyPaymentHistory__list__item__content__section policyPaymentHistory__list__item__content__section--amount flex align-center">
                            <button type="button"
                              onClick={() => window.open(record.invoiceUrl, '_blank')}
                            >
                              {IconFactory.downloadIcon()}
                            </button>

                          </section>
                        </div>
                      </li>
                    )
                  }
                )
              }
            </ul>
          </>
          :
          <Spinner color="main" circles />
      }
    </div>
  );
};

export default PolicyPaymentHistoryPage;
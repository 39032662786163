import { Navigate } from "react-router-dom";
import { POLICY_ENDORSEMENTS_PATH } from "src/routes/paths/policy-endorsements.paths";
import EndorsementCompletionPage from "../ui/pages/EndorsementCompletion/EndorsementCompletion";
import EndorsementConfigurationPage from "../ui/pages/EndorsementConfiguration/EndorsementConfiguration";
import EndorsementSummaryPage from "../ui/pages/EndorsementSummary/EndorsementSummary";
import EndorsementVerificationPage from "../ui/pages/EndorsementVerification/EndorsementVerification";
import IdentityValidationPage from "../ui/pages/IdentityValidation/IdentityValidation";
import PolicyEndorsementsPage from '../ui/pages/PolicyEndorsements';

/**
 *
 */
export const PolicyEndorsementsRoutes = (isValidAuth: boolean, redirect: string) => {
  return {
    path: POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_PATH, 
    element: isValidAuth === true ? <PolicyEndorsementsPage /> :  <Navigate to={redirect} />,
    children : [
      { path: `${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_VERIFICATION_PATH}`, element: <EndorsementVerificationPage />},
      { path: `${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_CONFIGURATION_PATH}`, element: <EndorsementConfigurationPage />},
      { path: `${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_SUMMARY_PATH}`, element: <EndorsementSummaryPage />},
			{ path: `${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_IDENTITY_VALIDATION_PATH}`, element: <IdentityValidationPage />},
			{ path: `${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_COMPLETION_PATH}`, element: <EndorsementCompletionPage />},
    ]
  }
}
import { Button } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { EndorsementConfigurationFacade } from "src/modules/policy-endorsements/controller/facades/endorsement-configuration.facade";
import EndorsementConfigurationItem from "../../atoms/EndorsementConfigurationItem/EndorsementConfigurationItem";
import './EndorsementConfiguration.scss';


/**
 *
 */
const EndorsementConfigurationPage = (): JSX.Element => {
  const {
    addEndorsementRow, onChangeType, removeEndorsementRow, allowAddEndorsementRow, setEndorsement, onCompleteConfiguration,
    endorsementsList, endorsementsAvailableTypeList, isValidConfiguration
  } = EndorsementConfigurationFacade.useEndorsementConfiguration();

  return (
    <div id="endorsements-configuration-wrapper" className="height-100 flex column space-between">
      <section>
        <h1 className="text-center">Datos a modificar</h1>
        <div className="endorsements-changes">
          {
            endorsementsList.map(
              (e: any, index: number) =>
                <EndorsementConfigurationItem key={index + 1} typesList={endorsementsAvailableTypeList} index={index}
                  onNewValue={(isValid, type, value) => setEndorsement(isValid, index, type, value)}
                  onChangeType={onChangeType}
                  onRemove={removeEndorsementRow} />
            )
          }
        </div>
        <div className="endorsement-add-button" disabled-button={!allowAddEndorsementRow() ? '' : null}>
          <button type="button" className="flex column align-center justify-center" onClick={(e) => { e.preventDefault(); addEndorsementRow() }}>
            {IconFactory.addIcon()}
            <span>Agregar otro campo</span>
          </button>
        </div>
      </section>
      <section className="buttons-wrapper text-center">
        <Button type="button" disabled={!isValidConfiguration} onClick={(e) => {e.preventDefault(); onCompleteConfiguration()}}>Continuar</Button>
      </section>
    </div>
  );
};

export default EndorsementConfigurationPage;
import Webcam from 'react-webcam';
import { Spinner } from "src/components";
import { IconFactory } from 'src/factory/icon.factory';
import { CameraFacade } from '../../controller/facades/camera.facade';

import './Camera.scss';

/**
 *
 */
interface ICamera {

  onSelect: (picture: string) => void;

  onCancel: () => void;
}


const videoConstraints = {
  facingMode: 'environment' // user or environment
};


/**
 *
 */
const Camera = (props: ICamera): JSX.Element => {
  const { windowSize, webcamRef, takePicture, currentPicture, removeCurrentPicture, selectCurrentPicture, cancelPicture, currentAnimation } = CameraFacade.useCamera(props.onSelect);
  const { cameraAvailable } = CameraFacade.usePermissions();

  return (
    <div className="camera-wrapper height-100 width-100 fixed flex justify-center" style={{animation: currentAnimation}}>
      <button type="button" className="cancel-button absolute" onClick={(e) => {e.preventDefault(); cancelPicture(props.onCancel)}}>
        {IconFactory.leftArrowIcon()}
        <span className="inline-block vertical-align-middle">Volver</span>
      </button>

      {
        !currentPicture ?
          <>
            {
              cameraAvailable === undefined ?
                <Spinner color="white" />
                :
                <>
                  {
                    cameraAvailable === true &&
                    <Webcam
                      autoPlay={true}
                      width={windowSize.width}
                      height={windowSize.height}
                      audio={false}
                      ref={cameraAvailable !== true ? null : webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                    />
                  }

                  {
                    cameraAvailable === false &&
                    <div className="camera-permissions-message text-center">
                      {IconFactory.cameraIcon()}
                      <h3>Necesitas activar los permisos de la c&aacute;mara para continuar</h3>
                    </div>
                  }
                </>
            }
          </>
          :
          <div className="current-camera-image flex align-center justify-center ">
            <img className="width-100" src={currentPicture} alt="CurrentImage" />
          </div>
      }
      <div className="camera-actions absolute flex">
        {
          !currentPicture &&
          <button type="button" disabled={!cameraAvailable} onClick={takePicture} className="take-picture"></button>
        }

        {
          currentPicture && 
          <>
            <button type="button" onClick={removeCurrentPicture}>
              {IconFactory.closeIcon()}
            </button>
            <button type="button" onClick={(e) => {e.preventDefault(); selectCurrentPicture(props.onCancel)}}>
              {IconFactory.checkIcon()}
            </button>
          </>
        }
      </div>

    </div>
  );
};

export default Camera;
import { Util } from "src/utils/Util";
import { Vehicle } from "../vehicle/Vehicle";

export class Endorse {

  /**
   *
   */
  private _date: Date | undefined;
  set date(_date: Date | undefined) { this._date = _date };

  /**
   *
   */
  private _vehicle: { before: Vehicle, after: Vehicle, code?: number } | undefined;
  set vehicle(_vehicle: { before: Vehicle, after: Vehicle, code?: number } | undefined) { this._vehicle = _vehicle };
  get vehicle(): { before: Vehicle, after: Vehicle, code?: number } | undefined { return this._vehicle };

  constructor(_date?: Date) {
    this._date = _date ? _date : undefined;
  }

  /**
   * 
   * @returns 
   */
  public getDate(): string {
    if (this._date) {
      return Util.TRANSFORM.DATE.formatDate(this._date);
    }

    return '--';
  }
}
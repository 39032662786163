import { Outlet } from "react-router";
import { ClaimPaymentsStepsFacade } from "../../controller/facades/claim-payments-steps.facade";
import { ClaimPaymentsFacade } from "../../controller/facades/claim-payments.facade";
import ClaimPaymentSidebar from "../organisms/ClaimPaymentSidebar/ClaimPaymentSidebar";
import "./ClaimPayments.scss";


/** */
const ClaimPaymentsPage = (): JSX.Element => {
  ClaimPaymentsFacade.useClaimPaymentFacade();
  ClaimPaymentsStepsFacade.useStepStatus();

  return (
    <div id="claim-payments-wrapper" className="flex-grow-100 flex">
      <div className="sidebar-wrapper flex column">
        <ClaimPaymentSidebar/>
      </div>

      <div className="claim-payment-content">
        <Outlet />
      </div>
    </div>
  );
};

export default ClaimPaymentsPage;

export class Address {

  /**
   * 
   */
  private _postalCode: string;
  get postalCode(): string { return this._postalCode; }
  set postalCode(_postalCode: string) { this._postalCode = _postalCode; }

  /**
   *
   */
  private _name: string;
  set name(_name: string) { this._name = _name };
  get name(): string { return this._name };
  /**
   * 
   */
  private _stateCode: number;
  get stateCode(): number { return this._stateCode; }
  set stateCode(_stateCode: number) { this._stateCode = _stateCode; }
  /**
   *
   */
  private _countryCode: number;
  set countryCode(_countryCode: number) { this._countryCode = _countryCode };
  get countryCode(): number { return this._countryCode };
  /**
   *
   */
  private _municipalityCode: string;
  set municipalityCode(_municipalityCode: string) { this._municipalityCode = _municipalityCode };
  get municipalityCode(): string { return this._municipalityCode };
  /**
   *
   */
  private _municipality: string;
  set municipality(_municipality: string) { this._municipality = _municipality };
  get municipality(): string { return this._municipality };
  /**
   *
   */
  private _state: string;
  set state(_state: string) { this._state = _state };
  get state(): string { return this._state };
  /**
   *
   */
  private _street: string;
  set street(_street: string) { this._street = _street };
  get street(): string { return this._street };
  /**
   *
   */
  private _neighborhood: string;
  set neighborhood(_neighborhood: string) { this._neighborhood = _neighborhood };
  get neighborhood(): string { return this._neighborhood };

  /**
   *
   */
  private _genericReference: string;
  set genericReference(_genericReference: string) { this._genericReference = _genericReference };
  get genericReference(): string { return this._genericReference };
  /**
   *
   */
  private _externalReference: string;
  set externalReference(_externalReference: string) { this._externalReference = _externalReference };
  get externalReference(): string { return this._externalReference };
  /**
   *
   */
  private _internalReference: string;
  set internalReference(_internalReference: string) { this._internalReference = _internalReference };
  get internalReference(): string { return this._internalReference };

  /**
   *
   */
  private _latitude: number | undefined;
  set latitude(_latitude: number | undefined) { this._latitude = _latitude };
  get latitude(): number | undefined { return this._latitude };

  /**
   *
   */
  private _longitude: number | undefined;
  set longitude(_longitude: number | undefined) { this._longitude = _longitude };
  get longitude(): number | undefined { return this._longitude };

  constructor(_postalCode?: string, _countryCode?: number, _stateCode?: number, _municipalityCode?: string) {
    this._postalCode = _postalCode ? _postalCode : '';
    this._name = '';
    this._countryCode = _countryCode ? _countryCode : 0;
    this._stateCode = _stateCode ? _stateCode : 0;
    this._municipalityCode = _municipalityCode ? _municipalityCode : '0';
    this._neighborhood = '';
    this._street = '';
    this._externalReference = '';
    this._internalReference = '';
    this._municipality = '';
    this._state = '';
    this._genericReference = '';
  }
}
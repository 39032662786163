import { Plan } from 'src/classes/plan/Plan';
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { IconFactory } from 'src/factory/icon.factory';
import { PlanWrapperFacade } from 'src/libs/PlanManagement/controller/facades/plan-wrapper.facade';
import PlanCoverages from '../PlanCoverages/PlanCoverages';
import './PlanDetails.scss';

/**
 *
 */
interface IPlanDetails {

  /**
   * 
   */
  plans: Plan[];
  /**
   * 
   */
  vehicle: Vehicle;
  /**
   * 
   */
  onCancel: () => void;
  /**
   * 
   */
  isRenewal?: boolean;
}

/**
 *
 */
const PlanDetails = (props: IPlanDetails): JSX.Element => {
  const { coverages, currentPlan } = PlanWrapperFacade.usePlanWrapper(props.plans);

  return (
    <div className="planDetails">
      <div className="planDetails__section__background"></div>
      {
        currentPlan &&
        <>
          <section className="planDetails__section">

            <div className="planDetails__section__type">
              <h2 className="text-center">Hablemos de tu auto</h2>
              <p className="text-center">Cobertura por daños a tu auto</p>

              <PlanCoverages collapsable coverages={coverages.damageCoverages} vehicle={props.vehicle}>
                {
                  !props.isRenewal &&
                  <>
                    <button type="button" className="planDetails__section__type__action" onClick={() => props.onCancel()}>
                      <span>
                        Cotizar otro auto
                      </span>
                      {IconFactory.editIcon()}
                    </button>
                    <button type="button" className="planDetails__section__type__action planDetails__section__type__action--mobile" onClick={() => props.onCancel()}>
                      <span>
                        Otro auto
                      </span>
                      {IconFactory.editIcon()}
                    </button>
                  </>
                }
              </PlanCoverages>
            </div>
          </section>
          <section className="planDetails__section">

            <div className="planDetails__section__type">
              <h2 className="text-center">Otros adicionales que te acompañan</h2>
              <p className="text-center">Coberturas que te facilitan la vida en situaciones impredecibles</p>

              <PlanCoverages coverages={coverages.extraCoverages} />
            </div>
          </section>
        </>
      }
    </div >
  );
};

export default PlanDetails;
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect, useState } from "react";
import { AlertFactory } from "src/factory/alert.factory";
import { Util } from "src/utils/Util";
import { OtpFeature } from "../features/otp.feature";

/**
 *
 */
export namespace OtpFacade {

  export const useOtp = (onVerify: (isValid: boolean, token?: string) => void, phoneOnly?: boolean, phone?: string) => {
    const [validCode, setValidCode] = useState<string>('');
    const [code, setCode] = useState<string>('');
		const [uuid, setUuid] = useState<string>();
    const [emailCodeLoading, setEmailCodeLoading] = useState<boolean>(false);
    const [emailCountdownSeconds, setEmailCountdownSeconds] = useState<number>(0);
    const [emailTimer, setEmailTimer] = useState<string>('');

    const [smsCodeLoading, setSmsCodeLoading] = useState<boolean>(false);
    const [smsCountdownSeconds, setSmsCountdownSeconds] = useState<number>(0);
    const [smsTimer, setSmsTimer] = useState<string>('');

    const [isVerifyingCode, setIsVerifyingCode] = useState<boolean>(false);
    const [allowStateChange, setAllowStateChange] = useState<boolean>(true);

    /**
     * 
     */
    const generateCode = async (type: string): Promise<void> => {
      try {
				if(type === Util.CONSTANT.OTP.CODE_TYPE.SMS && smsCountdownSeconds === 0 && phoneOnly === true){
					setSmsCodeLoading(true);
					const _uuid = uuidv4();
					setUuid(_uuid);
					await OtpFeature.generateCodeFeature(type, phone, _uuid);
					setSmsCountdownSeconds(Util.CONSTANT.OTP.TIME);
          setSmsTimer(Util.TRANSFORM.TIMER.coutndownBySeconds(Util.CONSTANT.OTP.TIME));
				}else if (type === Util.CONSTANT.OTP.CODE_TYPE.EMAIL) {
          setEmailCodeLoading(true);
          await OtpFeature.generateCodeFeature(type);
          OtpFeature.storeCurrentTime(undefined, emailCountdownSeconds);
          setEmailCountdownSeconds(Util.CONSTANT.OTP.TIME);
          setEmailTimer(Util.TRANSFORM.TIMER.coutndownBySeconds(Util.CONSTANT.OTP.TIME));
        } else if (type === Util.CONSTANT.OTP.CODE_TYPE.SMS && smsCountdownSeconds === 0) {
          setSmsCodeLoading(true);
          await OtpFeature.generateCodeFeature(type);
          OtpFeature.storeCurrentTime(smsCountdownSeconds);
          setSmsCountdownSeconds(Util.CONSTANT.OTP.TIME);
          setSmsTimer(Util.TRANSFORM.TIMER.coutndownBySeconds(Util.CONSTANT.OTP.TIME));
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        setEmailCodeLoading(false);
        setSmsCodeLoading(false);

      }
    }

    /**
     * 
     */
    const verifyCode = useCallback(
      async () => {
        try {
					if (validCode !== code && allowStateChange) {
            setIsVerifyingCode(true);
						const token = await OtpFeature.verifyCodeFeature(code, uuid);
            setValidCode(code);
            onVerify(true, token);
          }
        } catch (e) {
          AlertFactory.errorAlert((e as Error).message);
          onVerify(false);
        } finally {
          setIsVerifyingCode(false);
        }
      }, [code, onVerify, validCode, allowStateChange, uuid]
    );

    /**
     * 
     */
    useEffect(
      () => {
        return () => {
          setAllowStateChange(false);
        }
      }, [allowStateChange]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (code.length === 6) {
          verifyCode();
        }
      }, [code.length, verifyCode]
    );

    /**
     * SMS COUNTDOWN TIMER EFFECT
     */
    useEffect(
      () => {
        if (smsCountdownSeconds > 0) {
          setTimeout(() => {
            setSmsCountdownSeconds(smsCountdownSeconds - 1)
            setSmsTimer(Util.TRANSFORM.TIMER.coutndownBySeconds(smsCountdownSeconds - 1));
          }, 1000);
        }
      }, [smsCountdownSeconds]
    );

    /**
     * EMAIL COUNTDOWN TIMER EFFECT
     */
    useEffect(
      () => {
        if (emailCountdownSeconds > 0) {
          setTimeout(() => {
            setEmailCountdownSeconds(emailCountdownSeconds - 1)
            setEmailTimer(Util.TRANSFORM.TIMER.coutndownBySeconds(emailCountdownSeconds - 1));
          }, 1000);
        }
      }, [emailCountdownSeconds]
    );

    /**
     * Mount effect. Find a stored time then set values if a time exists.
     * @returns 
     */
    const useMountEffect = () => useEffect(
      () => {
        const timeRemaining = OtpFeature.getStoredTime();
        if (timeRemaining.sms && timeRemaining.sms > 0) {
          setSmsCountdownSeconds(timeRemaining.sms);
          setSmsTimer(Util.TRANSFORM.TIMER.coutndownBySeconds(timeRemaining.sms));
        }

        if (timeRemaining.email && timeRemaining.email > 0) {
          setEmailCountdownSeconds(timeRemaining.email);
          setEmailTimer(Util.TRANSFORM.TIMER.coutndownBySeconds(timeRemaining.email));
        }
      }, []
    );
    useMountEffect();

    return {
      code,
      loader: { emailCodeLoading, smsCodeLoading, isVerifyingCode },
      timer: { smsTimer, smsCountdownSeconds, emailTimer, emailCountdownSeconds },
      setCode,
      generateCode
    }
  }

}
import { Policy } from "src/classes/Policy";
import { Button } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { PolicyCancellationFacade } from "src/modules/policy/controller/facades/policy-cancellation.facade";
import { Util } from "src/utils/Util";
import './PolicyCancellationPrompt.scss';

/**
 *
 */
interface IPolicyCancellationPrompt {

  selectedPolicy: Policy;

  onBack: () => void;

  onNavigate: () => void;

}

/**
 *
 */
const PolicyCancellationPrompt = (props: IPolicyCancellationPrompt): JSX.Element => {
  const { getRemainingDaysMessage } = PolicyCancellationFacade.useCancellationPrompt();

  return (
    <div className="policy-cancellation-prompt-wrapper">
      <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.WARNING_IMAGE})` }}></span>
      <h1 className="text-center">
        A&uacute;n {getRemainingDaysMessage(props.selectedPolicy)} para tu siguiente pago mensual.
      </h1>
      <h2 className="text-center">
        Si cancelas ahora tu p&oacute;liza seguir&aacute; vigente y no ser&aacute; renovada en tu siguiente d&iacute;a de corte.
      </h2>

      <Button type="button" className="block border-box"
        onClick={(e) => { e.preventDefault(); props.onBack(); }}
      >
        He cambiado de opini&oacute;n
      </Button>

      <Button type="button" fill="none" className="block border-box cancel"
        icon={IconFactory.cancelIcon()}
        iconposition="left"
        color="red"
        onClick={(e) => { e.preventDefault(); props.onNavigate(); }}
      >
        Entiendo, quiero cancelar
      </Button>
    </div>
  );
};

export default PolicyCancellationPrompt;
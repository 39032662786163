import { Button } from "src/components";
import { Util } from "src/utils/Util";
import './PolicyCancelledPrompt.scss';

/**
 *
 */
interface IPolicyCancelledPrompt {
  cancelledDate?: Date;
  onBack: () => void;
}

/**
 *
 */
const PolicyCancelledPrompt = (props: IPolicyCancelledPrompt): JSX.Element => {
	/** */
	const { cancelledDate, onBack } = props;

  return (
    <div className="policy-cancelled-prompt-wrapper">
      <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.WARNING_IMAGE})` }}></span>
      <h1 className="text-center">
        {
					cancelledDate ? 'Tu póliza está cancelada': 'Tu póliza ya está pagada'
				}
      </h1>

      <h2 className="text-center">
				{
					cancelledDate ?
						`Ya no realizaremos cargos a tu método de pago; pero ten en cuenta que estarás sin protección a partir de ${Util.TRANSFORM.DATE.formatDate(cancelledDate)}`
					:
						`No te preocupes, tu póliza ya no tiene cuotas pendientes por pagarse y no hace falta cancelarla. Automáticamente a partir de la finalización de la vigencia quedarás sin protección y no realizaremos la renovación.`
				}
      </h2>

      <Button type="button" className="block border-box" onClick={(e) => { e.preventDefault(); onBack(); }}>
        Entendido
      </Button>
    </div>
  );
};

export default PolicyCancelledPrompt;
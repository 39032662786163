import { RootState } from "src/store/store";

export namespace CoreSelector {

  export const currentUser = (state: RootState) => state.instance.user!;

  export const maxProducts = (state: RootState) => state.instance.maxProducts;

  export const pageMenu = (state: RootState) => state.instance.pageMenu;
  
  export const openMenu = (state: RootState) => state.instance.openMenu;
}


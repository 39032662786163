//import { useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { POLICY_RENEWAL_PATH } from "src/routes/paths/policy-renewal.paths";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { PolicyRenewalAction } from "../actions/policy-renewal.action";
//import { Util } from "src/utils/Util";


export namespace PolicyRenewalReturnValidactionFacade {

  /**
   * 
   * @returns 
   */
  export const useReturnValidation = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { policyNumber } = useParams<string>();
    const steps = {
      plan: 0,
      review: 1,
      payment: 2,

    }

    /**
     * 
     */
    const onReturn = (step: number) => {
      let path: string = '';
      switch (step) {
        case steps.plan:
          path = `${POLICY_PATH.DETAILS}/${policyNumber}`;
          break;
        case steps.review:
          path = POLICY_RENEWAL_PATH.POLICY_RENEWAL_PLAN(policyNumber!);
          dispatch(PolicyRenewalAction.removePlan());
          break;
        case steps.payment:
          path = POLICY_RENEWAL_PATH.POLICY_RENEWAL_REVIEW(policyNumber!);
      }
      navigate(path, { replace: true });
    }

    return { onReturn, steps };
  }
}
import { Link } from "react-router-dom";
import { Person  } from "src/classes/Person";
import { Support } from "src/components";
import { HeaderFacade } from "src/libs/Header/controller/facades/header.facade";
import { SIGN_OUT_PATH } from "src/routes/paths/sign-out.paths";
import CurrentUser from "../../molecules/CurrentUser/CurrentUser";

import './Session.scss';

/**
 *
 */
interface ISession {

  user: Person;
}

/**
 *
 */
const Session = (props: ISession): JSX.Element => {

  const { showOptions, toggleOptions, showSession } = HeaderFacade.useSession();

  return (
    <>
      {
        showSession &&
        <div className="session-wrapper relative" onClick={() => toggleOptions(!showOptions)}
          onMouseLeave={() => toggleOptions(false)}
          onMouseEnter={() => toggleOptions(true)}>
          <div className="session-user flex align-center height-100">
            <CurrentUser user={props.user} />
          </div>
          {
            showOptions &&
            <div className="session-options-wrapper absolute width-100 border-box">
              <section>
                <p>Si necesitas ayuda, puedes contactarnos por medio de <a href="mailto:contacto@crabi.com">contacto@crabi.com</a></p>
              </section>
              <section>
                <Support />
              </section>
              <section className="nav-options">
                <Link className="underline" to={SIGN_OUT_PATH.SIGN_OUT} replace>Cerrar sesi&oacute;n </Link>
              </section>
            </div>
          }
        </div>
      }
    </>
  );
};

export default Session;
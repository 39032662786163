import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

/**
 *
 */
export namespace CommonFacade {

  const REDIRECT_TIMER: number = 60;

  export const useRedirect = (url: string) => {
    const [redirectCount, setRedirectCount] = useState<number>(0);
    const navigate = useNavigate();

    /**
     * 
     */
    const redirectNavigation = useCallback(
      () => {
        navigate(url, {replace: true});
      }, [navigate, url]
    );

    useEffect(() => {
      if (redirectCount < REDIRECT_TIMER) {
        setTimeout(() => {
          setRedirectCount(redirectCount + 1);
        }, 1000);
      } else {
        redirectNavigation();
      }
    }, [redirectNavigation, redirectCount])

  }
}
import React, { PropsWithChildren } from 'react';
import './DropdownItem.scss';

/**
 *
 */
interface IDropdownItem {

  item: {id: number | string, value: string};

  onSelect: (value: {id: number | string, value: string}) => void;
}

/**
 *
 */
export const DropdownItem = (props: PropsWithChildren<IDropdownItem & React.HtmlHTMLAttributes<HTMLDivElement>>): JSX.Element => {
  return (
    <div className="dropdown-item" onClick={() => props.onSelect(props.item)}>
      {props.children}
    </div>
  );
};
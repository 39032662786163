import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useInjectSaga } from "redux-injectors";
import { CoreSelector } from "src/core/selectors/core.selector";
import { POLICY_CREATION_PATH } from "src/routes/paths/policy-creation.paths";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { MainProductLimitAction } from "../actions/product-limit.action";
import { MainProductLimitSaga } from "../sagas/product-limit.saga";

export namespace ProductLimitFacade {

  /**
   * 
   */
  export const useProductLimit = () => {
    useInjectSaga({ key: 'productLimit', saga: MainProductLimitSaga.productLimitSaga });
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const maxProducts = useSelector(CoreSelector.maxProducts);
    const location = useLocation();
    const dispatch = useDispatch();
    
    useEffect(
      () => {
        if (location.pathname.includes(POLICY_CREATION_PATH.POLICY_CREATION) || location.pathname.includes(POLICY_PATH.OWNER)) {
          if (!maxProducts) {
            dispatch(MainProductLimitAction.productLimit());
          }
        }
      },
      [dispatch, maxProducts, location]
    );

    useEffect(
      () => {
        if (maxProducts) {
          setShowAlert(true);
        }
      }, [maxProducts]
    );

    return { showAlert, setShowAlert }
  }
}
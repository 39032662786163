import { CoreVinValidationService } from "../services/vin-validation.service";

export namespace CoreVinValidationFeature {

  /**
   * 
   * @param vin 
   * @returns 
   */
  export const validateVinFeature = async (vin: string): Promise<boolean> => {
    try {
      const { data } = await CoreVinValidationService.validateVinService(vin);

      return !data.exists ? true : false;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
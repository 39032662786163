import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Policy } from "src/classes/Policy";
import { Endorse } from "src/classes/policy/Endorse";
import { IPolicyEndorsementsStateReducer } from "src/store/states/modules-state/IPolicyEndorsementsStateReducer";

/**
 *
 */
export namespace PolicyEndorsementsSlice {
  /**
   * 
   */
  const initialState: IPolicyEndorsementsStateReducer = {
    policy: undefined,
    verified: false,
    completed: false,
    endorsements: []
  };

  /**
   * 
   * @param state 
   */
  const clear: CaseReducer<IPolicyEndorsementsStateReducer> = (state) => {
    state.verified = false;
    state.completed = false;
    state.policy = undefined;
    state.endorsements = [];
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const currentPolicy: CaseReducer<IPolicyEndorsementsStateReducer, PayloadAction<Policy>> = (state, action) => {
    state.policy = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const newEndorsements: CaseReducer<IPolicyEndorsementsStateReducer, PayloadAction<Endorse[]>> = (state, action) => {
    state.endorsements = action.payload;
  }

  /**
   * 
   * @param state 
   */
  const endorsementVerification: CaseReducer<IPolicyEndorsementsStateReducer> = (state) => {
    state.verified = true;
  }

  /**
   * 
   * @param state 
   */
  const endorsementCompleted: CaseReducer<IPolicyEndorsementsStateReducer> = (state) => {
    state.completed = true;
  }

  const reducers = {
    clear,
    endorsementVerification,
    currentPolicy,
    newEndorsements,
    endorsementCompleted
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'policyEndorsements',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PaymentRecord } from "src/classes/policy/PaymentRecord"
import { AlertFactory } from "src/factory/alert.factory";
import { LoadingFactory } from "src/factory/loading.factory";
import { PolicyPaymentFeature } from "../feature/policy-payment.feature";
import { PolicySelector } from "../selectors/policy.selector";

export namespace PolicyPaymentHistoryFacade {

  export const usePaymentHistory = () => {
    const selectedPolicy = useSelector(PolicySelector.selectedPolicy);
    const [nextRecord, setNextRecord] = useState<PaymentRecord>();
    const loading = LoadingFactory.useLoader();

    /**+
     * 
     */
    const getNextRecord = useCallback(
      async (policyNumber: string) => {
        try {
          loading.set(true);
          const record = await PolicyPaymentFeature.policyNextPaymentFeature(policyNumber);
          if (record) {
            setNextRecord(record);
          }
        } catch(e) {
          AlertFactory.errorAlert((e as Error).message);
        } finally {
          loading.set(false);
        }
      }, // eslint-disable-next-line
      [] 
    );


    useEffect(
      () => {
        if (selectedPolicy) {
          getNextRecord(selectedPolicy.policyNumber);
        }
      }, [selectedPolicy, getNextRecord]
    )

    return { nextRecord, loading }
  }
}
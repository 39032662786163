import { ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";

/**
 *
 */
export namespace ClaimsAction {

    export const policyList: ActionCreatorWithoutPayload = createAction('ACTIVE_POLICIES_LIST');
    export const claimList: ActionCreatorWithoutPayload = createAction('CLAIM_POLICIES_LIST');
    export const agreedClaimList: ActionCreatorWithoutPayload = createAction('AGREED_CLAIM_POLICIES_LIST');
}
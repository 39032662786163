import PictureManagement from 'src/modules/claim-payments/ui/organisms/PictureManagement/PictureManagement';
import { AttorneyLetterFacade } from 'src/modules/claim-payments/controller/facades/attorney-letter.facade';
import { DamageClaimNavigateValidationFacade } from 'src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade';
import ButtonSection from '../../molecules/ButtonSection/ButtonSection';
import './AttorneyLetterPage.scss';

/**
 *
 */
interface IAttorneyLetter {

}

/**
 *
 */
const AttorneyLetterPage = (props: IAttorneyLetter): JSX.Element => {
	
	/** */
	const { currentDamageClaim, picturesValidation, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, isAgreedPayment, validatePictures } = AttorneyLetterFacade.useAttorneyLetterFacade();
	const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id='attorney-letter-wrapper' className='flex-grow-100 flex'>
			<section className='content height-100'>
				<h1>{`Solicitud pago ${ isAgreedPayment ? 'pactado' : 'de daño' }`}</h1>
				<h3>Carta de poder</h3>
				<p>Anexa una carta donde el dueño del auto te ceda los derechos del pago.</p>

				{
					currentDamageClaim &&
						<PictureManagement 
							attachment={currentDamageClaim.attachmentLegal} 
							defaultPicture={currentDamageClaim.attachmentLegal.thumbnail} 
							status
							onUpload={(a) => {currentDamageClaim.attachmentLegal = a; validatePictures()}}
							allowPdf
						>
							{currentDamageClaim.attachmentLegal.title}
						</PictureManagement>
				}
			</section>

			<ButtonSection 
				backHandler={() => {navigationHandler( isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'backward')}} 
				nextHandler={() => {navigationHandler( isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward')}} 
				skipHandler={() => navigationHandler( isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward')}
				nextButtonDisabled={!picturesValidation}
				hasSkip={true}
			/>
    </div>
  );
};

export default AttorneyLetterPage;
import { Logo, Text } from "src/components";
import './ClaimFormDisabled.scss';

/**
 *
 */
const ClaimFormDisabledPage = (): JSX.Element => {
  return (
    <div className="claimFormDisabled">
      <div className="claimFormDisabled__logo">
        <Logo type="full"/>
      </div>

      <div className="claimFormDisabled__message">
        <Text type="paragraph" align="center">¡Hola! 👋</Text>
        <Text type="title" align="center" className="claimFormDisabled__message__large">Parece que este formulario ya concluy&oacute; o no est&aacute; disponible.</Text>

        <Text type="paragraph" align="center">Si necesitas ayuda adicional no dudes en escribirnos por WhatsApp.</Text>


        <Text type="paragraph" align="center" bold className="claimFormDisabled__message__link">Ir a <a href="https://crabi.com" target="_self" >crabi.com</a></Text>
      </div>
    </div>
  );
};

export default ClaimFormDisabledPage;
import { Plan } from "src/classes/plan/Plan";
import { PlanCheckout } from "src/classes/plan/PlanCheckout";
import { Policy } from "src/classes/Policy";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { IAutomaticRenewalRequest } from "../interfaces/policy/IAutomaticRenewalRequest";
import { IPolicyMonthlyPaymentRequest } from "../interfaces/policy/IPolicyMonthlyPaymentRequest";
import { IPolicyPaymentRequest } from "../interfaces/policy/IPolicyPaymentRequest";
import { IPolicyRequest } from "../interfaces/policy/IPolicyRequest";
import { IPolicyRequestData } from "../interfaces/policy/IPolicyRequestData";

/**
 * 
 */
export namespace CorePolicyRequestAdapter {

  /**
   * 
   */
   export const policyRequestAdapter = (vehicle: Vehicle, plan: Plan, driver?: Person): IPolicyRequest => {
    let data: IPolicyRequest = {
      coupon: plan.discount? plan.coupon : '',
      plan_id: plan.id,
      quotation_id: plan.quotation,
      vehicle: { vin: vehicle.vin }
    }
    
    if (driver) {
      data.driver = {id: driver.id}
    }

    return data;
  }

  /**
   * 
   * @param plan 
   */
   export const policyPaymentRequestDataAdapter = (checkout: PlanCheckout): IPolicyPaymentRequest => {
    const data: IPolicyPaymentRequest = {
      card: { card: checkout.cardType, card_type: 2 },
      payment_plan_id: checkout.paymentId,
      device_session_id: 'UND3F1N3D'
    }

    return data;
  }

	/**
	 * 
	 * @param tokenId 
	 * @returns 
	 */
	export const paymentMontlyRequestAdapter = (checkout: PlanCheckout, tokenId: string): IPolicyMonthlyPaymentRequest => {
    let request: IPolicyMonthlyPaymentRequest = {
			card: {
				card: checkout.cardType,
				encoded_card: tokenId,
				card_type: 2 
			},
      device_session_id: 'UND3F1N3D',
      payment_plan_id: checkout.paymentId
    }

    return request;
  }

	/**
	 * 
	 * @param tokenId 
	 * @returns 
	 */
	export const policyRequestDataAdapter = (data: IPolicyRequestData): Policy => {
		let _policy = new Policy('');
		
		for(const policy of data.policies) {
			if (policy.number.includes('AE')){
				_policy.id = policy.id;
			}
		};

		return _policy;
	}

	/**
	 * 
	 * @param tokenId 
	 * @returns 
	 */
	export const policyAutomaticRenewalRequestAdapter = (policy: Policy, value: boolean): IAutomaticRenewalRequest => {
		const request: IAutomaticRenewalRequest = {
			policy_id: policy.id,
			accept: value
		};

		return request;
	}
}
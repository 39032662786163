import { RootState } from "src/store/store";

/**
 *
 */
export namespace ReferralsSelector {

  export const rewardsList = (state: RootState) => state.referrals?.rewardsList;

  export const rewardsHistoryList = (state: RootState) => state.referrals?.rewardsHistoryList;

  export const rewardsHistoryListOffset = (state: RootState) => state.referrals?.rewardsHistoryListOffset;

  export const rewardsLoading = (state: RootState) => state.referrals?.isLoading;
}
import { MdArrowBackIos, MdClose, MdMail, MdMailOutline, MdInfo, MdArrowForwardIos, MdSearch, MdUploadFile, MdAdd, MdConfirmationNumber, MdTimer, MdDoubleArrow } from 'react-icons/md';
import { RiArrowDropDownLine, RiErrorWarningLine, RiEyeFill, RiEyeOffFill, RiMenu2Fill, RiBillLine, RiHistoryFill, RiHandCoinLine, RiCouponFill, RiArrowLeftRightFill } from 'react-icons/ri';
import { BiBookBookmark, BiCheck, BiDownload, BiReceipt, BiSad, BiImages, BiPencil } from 'react-icons/bi';
import { BsFileEarmarkArrowDown, BsFillCloudArrowDownFill, BsFillTelephoneFill, BsLock, BsLockFill } from 'react-icons/bs';
import { AiFillCar, AiFillDollarCircle, AiFillStar, AiOutlineFileProtect } from 'react-icons/ai';
import { IoShieldCheckmark, IoCard, IoPersonOutline, IoCopyOutline, IoTrashOutline } from 'react-icons/io5';
import { FiMessageSquare, FiUpload  } from 'react-icons/fi';
import { CgSandClock } from 'react-icons/cg';
import { IconBaseProps } from 'react-icons';
import { FaCarCrash, FaRegAddressCard, FaRegCheckCircle } from "react-icons/fa";
import { ImMinus } from "react-icons/im";
import { IoCameraSharp } from "react-icons/io5";
import { GoTasklist } from "react-icons/go";
import { TiCancel } from "react-icons/ti";
import { FaRegFileLines, FaRegFile, FaRegFilePdf, FaRegFileCode } from "react-icons/fa6";

import { Icon } from "src/components";

/**
 *
 */
export namespace IconFactory {
  export const undefinedIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiSad);
  };

  export const searchIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdSearch);
  };

  export const menuIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiMenu2Fill);
  };

  export const checkIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiCheck);
  };

  export const infoIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdInfo);
  };

  export const arrowRightLeft = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiArrowLeftRightFill);
  };

  export const dropDownArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiArrowDropDownLine);
  };

  export const lockIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BsLockFill);
  };

  export const lockOutlineIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BsLock);
  };

  export const mailIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdMail);
  };

  export const mailOutlineIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdMailOutline);
  };

  export const leftArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdArrowBackIos);
  };

  export const rightArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdArrowForwardIos);
  };

  export const doubleRightArrowIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdDoubleArrow);
  };

  export const closeIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdClose);
  };

  export const cancelIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, TiCancel);
  };

  export const addIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdAdd);
  }

  export const warningIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiErrorWarningLine);
  };

  export const phoneIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BsFillTelephoneFill);
  };

  export const carIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, AiFillCar);
  };

  export const dollarIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, AiFillDollarCircle);
  };

  export const cardIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, IoCard);
  };

  export const profileIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, IoPersonOutline);
  };

  export const shieldIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, IoShieldCheckmark);
  };

  export const eyeIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiEyeFill);
  };

  export const eyeOffIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiEyeOffFill);
  };

  export const downloadIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiDownload);
  };

  export const termsIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiBookBookmark);
  };

  export const sumaryTermsIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, AiOutlineFileProtect);
  };

  export const policyDownloadIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BsFileEarmarkArrowDown);
  };

  export const receiptIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiReceipt);
  };

  export const cameraIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, IoCameraSharp);
  };

  export const dropFileIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BsFillCloudArrowDownFill);
  };

  export const crashIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, FaCarCrash);
  };

  export const sandClockIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, CgSandClock);
  };

  export const clipboardIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, IoCopyOutline);
  }

	export const minusWarningIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, ImMinus);
  };

	export const userCardIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, FaRegAddressCard);
  };

	export const billIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiBillLine);
  };

	export const fileIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdUploadFile);
  };

  export const SmsIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, FiMessageSquare)
  }

  export const confirmationNumberIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, MdConfirmationNumber)
  }

  export const editIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, BiPencil)
  }

	export const uploadIcon = (props?: IconBaseProps) => {
		return Icon(props ? props : {}, FiUpload)
	}

	export const clockIcon = (props?: IconBaseProps) => {
		return Icon(props ? props : {}, MdTimer);
	}
	export const historyIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiHistoryFill);
  };

	export const exchangeIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, RiHandCoinLine);
  };

	export const starIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, AiFillStar);
  };

	export const tasksIcon = (props?: IconBaseProps) => {
    return Icon(props ? props : {}, GoTasklist);
  };

  export const galleryIcon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, BiImages);
  }

  export const couponIcon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, RiCouponFill);
  }

  export const removeIcon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, IoTrashOutline);
  }

  export const file2Icon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, FaRegFile);
  }

  export const file3Icon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, FaRegFileLines);
  }

  export const pdfFileIcon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, FaRegFilePdf);
  }

  export const xmlIcon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, FaRegFileCode );
  }

  export const checkCircleIcon = (props?: IconBaseProps) => {
    return Icon(props? props : {}, FaRegCheckCircle);
  }

}


import { AxiosError } from "axios";
import { DamageClaim } from "src/classes/DamageClaim";
import { Policy } from "src/classes/Policy";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { ClaimPaymentPolicyDataAdapter } from "../adapters/claim-payment-policy-data.adapter";
import { ClaimPaymentsAdapter } from "../adapters/claim-payments.adapter";
import { ClaimPaymentsService } from "../services/claim-payments.service";

/**
 *
 */
export namespace ClaimPaymentsFeature {

	/**
	 * 
	 * @param damageClaimID 
	 * @returns 
	 */
	export const getClaimDamageByIdFeature = async (damageClaimID: string): Promise<DamageClaim | undefined> => {
		try {
			const { data } = await ClaimPaymentsService.getDamageClaimByIdService(damageClaimID);
			const damageClaim = ClaimPaymentsAdapter.getDamageClaimByIdAdapter(data);

			return damageClaim;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	/**
	 * 
	 * @param damageClaimID 
	 * @returns 
	 */
	export const getAgreedClaimByIdFeature = async (damageClaimID: string): Promise<DamageClaim | undefined> => {
		try {
			const { data } = await ClaimPaymentsService.getAgreedClaimByIdService(damageClaimID);
			const damageClaim = ClaimPaymentsAdapter.getDamageClaimByIdAdapter(data);

			return damageClaim;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

  /**
   * 
   * @param policyData 
   * @returns 
   */
	export const createDamagePaymentFeature = async (damageClaim: DamageClaim, isAuthenticated: boolean, policyUID?: string): Promise<DamageClaim | undefined> => {
    try {
      const requestData = ClaimPaymentsAdapter.claimPaymentRequestDataAdapter(damageClaim, policyUID);
      const { data } = await ClaimPaymentsService.createDamagePaymentService(requestData, isAuthenticated);
			const damagePayment = ClaimPaymentPolicyDataAdapter.claimPaymentDataAdapter(data);
			
      return damagePayment;
    } catch (e) {
			const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response?.status === 400 && axiosError.response.data.major === 42) {
        error.message = `El número de póliza no existe.`;
      }
      throw error;
    }
  }

	/**
   * 
   * @param policyData 
   * @returns 
   */
	export const createAgreedPaymentFeature = async (damageClaim: DamageClaim, isAuthenticated: boolean, policyUID?: string): Promise<DamageClaim | undefined> => {
		try {
			const requestData = ClaimPaymentsAdapter.claimPaymentRequestDataAdapter(damageClaim, policyUID);
			const { data } = await ClaimPaymentsService.createAgreedPaymentService(requestData, isAuthenticated);
			const agreedPayment = ClaimPaymentPolicyDataAdapter.claimPaymentDataAdapter(data);
			
			return agreedPayment;
		} catch (e) {
			const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response?.status === 400 && axiosError.response.data.major === 42) {
        error.message = `El número de póliza no existe.`;
      }
      throw error;
		}
	}

	/**
	 * 
	 * @param user 
	 * @param claimPayment 
	 * @returns 
	 */
	export const updatePersonalDataFeature = async (user: Person, claimPayment: DamageClaim): Promise<boolean> => {
		try {
			const requestData = ClaimPaymentsAdapter.updatePersonalDataAdapter(user);
			await ClaimPaymentsService.updatePersonalDataService(requestData, claimPayment.uid);
			return true;
		} catch (e) {
			console.error(e);
      return false;
		}
	}

	/**
	 * 
	 * @param vehicle 
	 * @param claimPayment 
	 * @returns 
	 */
	export const updateVehicleDataFeature = async (vehicle: Vehicle, claimPayment: DamageClaim): Promise<boolean> => {
		try {
			const requestData = ClaimPaymentsAdapter.updateVehicleDataAdapter(vehicle);
			await ClaimPaymentsService.updateVehicleDataService(requestData, claimPayment.uid);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	/**
	 * 
	 */
	export const updateDamageClaimFromPolicyFeature = (policy: Policy): { damageClaim: DamageClaim, vehicle: Vehicle } => {
		let _damageClaim: DamageClaim = new DamageClaim('');
		_damageClaim.policyNumber = policy.policyNumber;
		_damageClaim.policyUID = policy.id;

		let _vehicle: Vehicle = new Vehicle();
		_vehicle = policy.vehicle!;
	
		return {
			damageClaim: _damageClaim,
			vehicle: _vehicle
		};
	}
}
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { Endorse } from "src/classes/policy/Endorse";
import { Person  } from "src/classes/Person";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { CoreInstanceSlice } from "src/core/slices/instance.slice";
import { PolicyEndorsementsAction } from "../actions/policy-endorsements.action";
import { PolicyEndorsementsSlice } from "../slices/policy-endorsements.slice";

/**
 *
 */
export namespace PolicyEndorsementsSaga {

  type PolicyByNumber = SagaReturnType<typeof CorePolicyFeature.policyByNumberFeature>;

  /**
   * 
   */
  function* clear() {
    yield put(PolicyEndorsementsSlice.actions.clear());
  }

  /**
   * 
   */
  function* policyByNumber(action: PayloadAction<string>) {
    try {
      const policy: PolicyByNumber = yield call(CorePolicyFeature.policyByNumberFeature, action.payload);
      yield put(PolicyEndorsementsSlice.actions.currentPolicy(policy!));
    } catch (e) { }
  };

	/**
   * 
   */
	function* updateUserStatus(action: PayloadAction<Person>) {
		yield put(CoreInstanceSlice.actions.currentUser(action.payload));
	}

  /**
   * 
   */
  function* endorsementVerification() {
    yield put(PolicyEndorsementsSlice.actions.endorsementVerification());
  }

  /**
   * 
   */
  function* newEndorsements(action: PayloadAction<Endorse[]>) {
    yield put(PolicyEndorsementsSlice.actions.newEndorsements(action.payload));
  }

  /**
   * 
   */
  function* endorsementCompleted() {
    yield put(PolicyEndorsementsSlice.actions.endorsementCompleted());
  }

  /**
   * 
   */
  export function* policyEndorsementsSaga() {
    yield takeLatest(PolicyEndorsementsAction.clear.type, clear);
    yield takeLatest(PolicyEndorsementsAction.policyEndorsement.type, policyByNumber);
    yield takeLatest(PolicyEndorsementsAction.endorsementVerification.type, endorsementVerification);
    yield takeLatest(PolicyEndorsementsAction.newEndorsements.type, newEndorsements);
    yield takeLatest(PolicyEndorsementsAction.endorsementCompleted.type, endorsementCompleted);
		yield takeLatest(PolicyEndorsementsAction.updateUserStatus.type, updateUserStatus);
  }
}
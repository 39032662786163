import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";

/**
 *
 */
export namespace VehicleManagementAction {

  /**
   * 
   */
  export const getBrands: ActionCreatorWithoutPayload = createAction('GET_VEHICLE_BRANDS');
  /**
   * 
   */
  export const getModels: ActionCreatorWithPayload<VehicleBrand> = createAction('GET_VEHICLE_MODELS');
  /**
   * 
   */
  export const getYears: ActionCreatorWithPayload<VehicleModel> = createAction('GET_VEHICLE_YEARS');
  /**
   * 
   */
  export const getVersion: ActionCreatorWithPayload<VehicleYear> = createAction('GET_VEHICLE_VERSION');
  /**
   * 
   */
  export const getVehicleStored: ActionCreatorWithoutPayload = createAction('GET_VEHICLE_STORED');
  /**
   * 
   */
  export const onItemSelectedFormList: ActionCreatorWithPayload<string> = createAction('ITEM_SELECTED');
  /**
   * 
   */
  export const clear: ActionCreatorWithoutPayload = createAction('CLEAR_VEHICLE_MANAGEMENT');
}
import { IPersonManagementEconomicActivity } from "../interfaces/IPersonManagementEconomicActivity";
import { IPersonManagementEconomicActivityData } from "../interfaces/IPersonManagementEconomicActivityData";
import { IPersonManagementOwnBusinessData } from "../interfaces/IPersonManagementOwnBusinessData";

/**
 * 
 */
export namespace PersonManagementEconomicActivityAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const economicActivitiesDataAdapter = (data: IPersonManagementEconomicActivityData): IPersonManagementEconomicActivity[] => {
		let dataEntities = data.economic_activities;
		let returningData: IPersonManagementEconomicActivity[] = [];
		if(dataEntities[0].id === undefined)
			dataEntities[0].id = 0;

		dataEntities.forEach((item) => {
			let newItem: IPersonManagementEconomicActivity = {
				id: item.id,
				value: item.description
			}

			returningData.push(newItem);
		});

    return returningData;
  };

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	export const ownBusinessDataAdapter = (data: IPersonManagementOwnBusinessData): IPersonManagementEconomicActivity[] => {
		let dataEntities = data.complex_economic_activities;
		let returningData: IPersonManagementEconomicActivity[] = [];

		dataEntities.forEach((item) => {
			let newItem: IPersonManagementEconomicActivity = {
				id: item.id,
				value: item.description
			}

			returningData.push(newItem);
		})

    return returningData;
  };
}
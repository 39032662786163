import { DamageClaim } from "src/classes/DamageClaim";
import { ICreateDamagePaymentResponse } from "../interfaces/ICreateDamagePaymentResponse";

export namespace ClaimPaymentPolicyDataAdapter {


	export const claimPaymentDataAdapter = (response: ICreateDamagePaymentResponse): DamageClaim => {
		let damagePayment: DamageClaim = new DamageClaim(response.id);

		damagePayment.status = response.iscrabeler;
		damagePayment.policyNumber = response.policy_number;
		damagePayment.damageClaimNumber = response.sinister_number;
    if (response.status) {
      damagePayment.status = response.status
    }

		return damagePayment;
	}


}
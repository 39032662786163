import { Util } from "src/utils/Util";
import { DamageClaim } from "./DamageClaim";
import { Coverage } from "./plan/Coverage";
import { PaymentMethod } from "./policy/PaymentMethod";
import { PaymentRecord } from "./policy/PaymentRecord";
import { Vehicle } from "./vehicle/Vehicle";
import { Person } from "./Person";

export class Policy {

  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };
  /**
   *
   */
  private _policyNumber: string;
  set policyNumber(_policyNumber: string) { this._policyNumber = _policyNumber };
  get policyNumber(): string { return this._policyNumber };
  /**
   *
   */
  private _expirationDate: Date | undefined;
  set expirationDate(_expirationDate: Date | undefined) { this._expirationDate = _expirationDate };
  get expirationDate(): Date | undefined { return this._expirationDate };
  /**
   *
   */
  private _startDate: Date | undefined;
  set startDate(_startDate: Date | undefined) { this._startDate = _startDate };
  get startDate(): Date | undefined { return this._startDate };
  /**
   *
   */
  private _renewalDate: Date | undefined;
  set renewalDate(_renewalDate: Date | undefined) { this._renewalDate = _renewalDate };
  get renewalDate(): Date | undefined { return this._renewalDate };
  /**
   *
   */
  private _type: string;
  set type(_type: string) { this._type = _type };
  get type(): string { return this._type };
  /**
   *
   */
  private _vehicle: Vehicle | undefined;
  set vehicle(_vehicle: Vehicle | undefined) { this._vehicle = _vehicle };
  get vehicle(): Vehicle | undefined { return this._vehicle };
  /**
   *
   */
  private _coverages: Coverage[];
  set coverages(_coverages: Coverage[]) { this._coverages = _coverages };
  get coverages(): Coverage[] { return this._coverages };
	/**
	 * 
	 */
	private _selectedDamageClaim: DamageClaim | undefined;
	set selectedDamageClaim(_selectedDamageClaim: DamageClaim | undefined) { this._selectedDamageClaim = _selectedDamageClaim };
	get selectedDamageClaim(): DamageClaim | undefined { return this._selectedDamageClaim };
  /**
   *
   */
  private _allowRenewal: boolean;
  set allowRenewal(_allowRenewal: boolean) { this._allowRenewal = _allowRenewal };
  get allowRenewal(): boolean { return this._allowRenewal };
  /**
   *
   */
  private _renewed: boolean;
  set renewed(_renewed: boolean) { this._renewed = _renewed };
  get renewed(): boolean { return this._renewed };
  /**
   *
   */
  private _status: { active?: boolean, pending?: boolean, canceled?: boolean, expired?: boolean, toBegin?: boolean };
  set status(_status: { active?: boolean, pending?: boolean, canceled?: boolean, expired?: boolean, toBegin?: boolean }) { this._status = _status };

  /**
   *
   */
  private _hasPictures: boolean | undefined;
  set hasPictures(_hasPictures: boolean | undefined) { this._hasPictures = _hasPictures };
  get hasPictures(): boolean | undefined { return this._hasPictures };
  /**
   *
   */
  private _paymentStatus: number;
  set paymentStatus(_paymentStatus: number) { this._paymentStatus = _paymentStatus };
  get paymentStatus(): number { return this._paymentStatus };
  /**
   *
   */
  private _paymentMethod: PaymentMethod | undefined;
  set paymentMethod(_paymentMethod: PaymentMethod | undefined) { this._paymentMethod = _paymentMethod };
  get paymentMethod(): PaymentMethod | undefined { return this._paymentMethod };
  /**
   *
   */
  private _paymentHistory: PaymentRecord[];
  set paymentHistory(_paymentHistory: PaymentRecord[]) { this._paymentHistory = _paymentHistory };
  get paymentHistory(): PaymentRecord[] { return this._paymentHistory };

  /**
   *
   */
  private _owner: Person | undefined;
  set owner(_owner: Person | undefined) { this._owner = _owner };
  get owner(): Person | undefined { return this._owner };

  constructor(_policyNumber: string, _expirationDate?: Date, _startDate?: Date) {
    this._id = '';
    this._type = '';
    this._allowRenewal = false;
    this._expirationDate = _expirationDate ? _expirationDate : undefined;
    this._startDate = _startDate ? _startDate : undefined;
    this._coverages = [];
    this._policyNumber = _policyNumber;
    this._status = {};
    this._renewed = false;
    this._hasPictures = undefined;
    this._paymentStatus = 0;
    this._paymentHistory = [];
  }

  /**
   * 
   * @returns 
   */
  public getStartDateFormat(): string {
    return Util.TRANSFORM.DATE.formatDate(this._startDate as Date)
  }

  /**
   * 
   */
  public getExpirationDateFormat(): string {
    return Util.TRANSFORM.DATE.formatDate(this._expirationDate as Date);
  }

  /**
   * 
   */
  public getRenewalDateFormat(): string {
    if (this._renewalDate) {
      let _format: string = Util.TRANSFORM.DATE.formatUTCDate(this._renewalDate as Date);
      return _format;
    }

    return '--';
  }

  /**
   * 
   */
  public getStatusValue(): string {
    let _statusValue: string = '';
    if (this.isCanceled()) {
      _statusValue = 'Cancelada';
    } else if (this.isActive()) {
      _statusValue = 'Activa';
    } else if (this.isPending()) {
      _statusValue = 'Pendiente';
    } else if (this.isExpired()) {
      _statusValue = 'Expirada'
    } else if (this.toBegin()) {
      _statusValue = 'Por iniciar'
    }
    return _statusValue;
  }

  /**
   * 
   */
  public isActive(): boolean {
    if (this._status.active) {
      return true;
    }
    return false;
  }

  /**
   * 
   */
  public toBegin(): boolean {
    if (this._status.toBegin) {
      return true;
    }
    return false;
  }

  /**
   * 
   */
  public isPending(): boolean {
    if (this._status.pending) {
      return true;
    }
    return false;
  }

  /**
   * 
   */
  public isCanceled(): boolean {
    if (this._status.canceled) {
      return true;
    }
    return false;
  }

  /**
   * 
   * @returns 
   */
  public isExpired(): boolean {
    if (this._status.expired) {
      return true;
    }
    return false;
  }

  /**
   * 
   * @returns 
   */
  public isPaymentFailed(): boolean {
    return this._paymentStatus === Util.STATUS_CODE.POLICY_PAYMENT_STATUS.GRACE_PERIOD;
  }

  /**
   * 
   */
  public getTypeName(): string {
    let name: string = '';
    if (this._type === Util.STATUS_CODE.PLAN_TYPE.ANNUAL) {
      name = 'Anual';
    } else if (this._type === Util.STATUS_CODE.PLAN_TYPE.SUBSCRIPTION) {
      name = 'Mensual';
    } else if (this._type === Util.STATUS_CODE.PLAN_TYPE.MONTHLY) {
			name = 'Diferido';
		}
    return name;
  }

  /**
   * 
   * @returns 
   */
  public isPlanPaymentAnnual(): boolean {
    return this._type === Util.STATUS_CODE.PLAN_TYPE.ANNUAL;
  }

  /**
   * 
   * @returns 
   */
  public isPlanPaymentMonthly(): boolean {
    return this._type === Util.STATUS_CODE.PLAN_TYPE.SUBSCRIPTION;
  }
}
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { DamageClaim } from "src/classes/DamageClaim";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { CoreTokenStorageFeature } from "src/core/features/token-storage.feature";
import { AlertFactory } from "src/factory/alert.factory";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { Util } from "src/utils/Util";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFeature } from "../features/claim-payments.feature";
import { ClaimPaymentsSaga } from "../sagas/claim-payments.saga";
import { ClaimPaymentsSlice } from "../slices/claim-payments.slice";
import { ClaimPaymentsFacade } from "./claim-payments.facade";
import { DamageClaimNavigateValidationFacade } from "./damage-claim-navigate-validation.facade";

const NO_MATCH_CLAIM_NUMBER_ERROR_MESSAGE: string = `El número de siniestro no corresponde a esta póliza.`
const CLAIM_CURRENT_PATH: string = CLAIM_PAYMENTS_PATH.POLICY_DATA;
const AGREED_CURRENT_PATH: string = AGREED_PAYMENTS_PATH.POLICY_DATA;

/**
 *
 */
export namespace PolicyDataFacade {

	/** */
	export const usePolicyDataFacade = () => {
		const { currentDamageClaim, currentVehicle, isAgreedPayment, isLoadingDamageClaim } = ClaimPaymentsFacade.useDamageClaimActors();
		const [hasPolicyNumber, updateHasPolicyNumber] = useState<boolean>(false);
		const [currentPath, setCurrentPath] = useState<string>('');

		const { search } = useLocation();
		const searchParams = useMemo(() => new URLSearchParams(search), [search]);
		const policyNumberParam = searchParams.get("policy-number");


		const dispatch = useDispatch();

		useEffect(() => {
			const authValidation = CoreTokenStorageFeature.verifyTokenFeature();

			if((policyNumberParam && policyNumberParam.length > 0) || (currentDamageClaim?.policyNumber && currentDamageClaim?.policyNumber.length > 0 && authValidation))
				updateHasPolicyNumber(true);
		}, [policyNumberParam, currentDamageClaim]);

		useEffect(() => {
			setCurrentPath(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH);
		}, [isAgreedPayment]);

		useEffect(() => {
			dispatch(ClaimPaymentsAction.updateCurrentPath(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH));
		}, [dispatch, isAgreedPayment]);

		return { currentDamageClaim, currentVehicle, isAgreedPayment, hasPolicyNumber, currentPath, isLoadingDamageClaim };
	};

	/**
   * 
   */
	export const useClaimPolicy = () => {
		/** */
		useInjectReducer({ key: 'claimPayments', reducer: ClaimPaymentsSlice.reducer });
		useInjectSaga({ key: 'claimPayments', saga: ClaimPaymentsSaga.claimPaymentSaga });
		const { currentDamageClaim } = ClaimPaymentsFacade.useDamageClaimActors();
		const [damageClaim, updateDamageClaim] = useState<DamageClaim>();

		useEffect(() => {
			updateDamageClaim(currentDamageClaim);
		}, [currentDamageClaim])

		return { damageClaim }
	}

	/** */
	export const useCreateDamagePayment = () => {
		/** */
		const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();
		const { currentDamageClaim, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();

		const [isLoading, updateIsLoading] = useState<boolean>(false);

		const dispatch = useDispatch();
		const { search } = useLocation();
		const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const navigate = useNavigate();

		const claimIdParam = searchParams.get("claim");

		/**
		 * 
		 */
		const authValidation = () => {
			const isValid = CoreTokenStorageFeature.verifyTokenFeature();
			return isValid;
		};

		/**
     * @param policy 
     * @returns 
     */
		const createDamagePayment = async (damageClaim: DamageClaim): Promise<void> => {
			try {
				updateIsLoading(true);
				const isAuthenticated = await authValidation();

				let policyUID: string = '';

				if(claimIdParam && claimIdParam !== '' && isAuthenticated){
					const policyNumber = damageClaim.policyNumber.toLocaleUpperCase();
					const currentPolicy = await CorePolicyFeature.policyByNumberFeature(policyNumber);
					policyUID = currentPolicy?.id!;
				}

				let updatedDamageClaim: DamageClaim | undefined;

				if(isAgreedPayment)
					updatedDamageClaim = await ClaimPaymentsFeature.createAgreedPaymentFeature(damageClaim, isAuthenticated!, policyUID);
				else
					updatedDamageClaim = await ClaimPaymentsFeature.createDamagePaymentFeature(damageClaim, isAuthenticated!, policyUID);

        const missingProposal: boolean = updatedDamageClaim! && updatedDamageClaim.checkAllPhotosApproved() && !updatedDamageClaim.proposal;
				
        if(updatedDamageClaim && updatedDamageClaim.status === Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.PENDING_PROPOSAL){
          dispatch(ClaimPaymentsAction.getDamageClaimById({ damageClaim: updatedDamageClaim.uid, isAgreedPayment: isAgreedPayment! }));

					if(isAgreedPayment) {
						navigate(`${AGREED_PAYMENTS_PATH.PROPOSAL}${search}`);
					} else {
						navigate(`${CLAIM_PAYMENTS_PATH.PROPOSAL}${search}`);
					}
        }else if(updatedDamageClaim &&  (updatedDamageClaim.status === Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.PENDING_PAYMENT || updatedDamageClaim.status === Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.PAID || missingProposal)){
          dispatch(ClaimPaymentsAction.getDamageClaimById({ damageClaim: updatedDamageClaim.uid, isAgreedPayment: isAgreedPayment! }));

					if(isAgreedPayment) {
						navigate(`${AGREED_PAYMENTS_PATH.COMPLETION}${search}`);
					} else {
						navigate(`${CLAIM_PAYMENTS_PATH.COMPLETION}${search}`);
					}
        } else {
          validateDamageClaims(damageClaim, updatedDamageClaim!);
        }

			} catch (e) {
				const error = e as Error;
				if(error) {
					AlertFactory.errorAlert(error.message);
				}
			} finally {
				updateIsLoading(false);
			}
		}

		/**
		 * 
		 */
		const validateDamageClaims = (damageClaim: DamageClaim, incomingDamageClaim: DamageClaim) => {
			const CURRENT_PATH: string = isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH;

			if(currentDamageClaim && currentDamageClaim.uid){
				currentDamageClaim.policyNumber = incomingDamageClaim.policyNumber;
				currentDamageClaim.policyUID = damageClaim.policyUID;

				dispatch(ClaimPaymentsAction.storeDamageClaim(currentDamageClaim));
				navigationHandler(CURRENT_PATH, 'forward', incomingDamageClaim);
			} else {
				if(damageClaim.policyNumber === incomingDamageClaim.policyNumber){
					if(damageClaim.policyUID.length > 0)
						incomingDamageClaim.policyUID = damageClaim.policyUID;
	
					dispatch(ClaimPaymentsAction.storeDamageClaim(incomingDamageClaim));
					navigationHandler(CURRENT_PATH, 'forward', incomingDamageClaim);
				}else{
					AlertFactory.errorAlert(NO_MATCH_CLAIM_NUMBER_ERROR_MESSAGE);
				}
			}
		}

		return { isLoading, createDamagePayment }
	}

}
import { CLAIM_ADJUSTER_PERSON_EDIT_PARAMS } from "src/routes/paths/claim-register.paths";
import { Button, Text } from "src/components";
import { Util } from "src/utils/Util";
import ClaimContent from "../../components/ClaimContent/ClaimContent";
import ClaimDamage from "../../components/ClaimDamage/ClaimDamage";
import { IconFactory } from "src/factory/icon.factory";
import { ClaimPersonManagementFacade } from "src/modules/claim-register/controller/facades/claim-person-management.facade";

import './ClaimPersonDamage.scss';
import DeleteItemModal from "../../components/DeleteItemModal/DeleteItemModal";

/**
 *
 */
const ClaimPersonDamagePage = (): JSX.Element => {
	
	/**
	 * 
	*/
	const { damageNavigation } = ClaimPersonManagementFacade.useClaimPersonManagement();
	const { registerInfo, states, urlParam, formDamages } = ClaimPersonManagementFacade.useClaimPersonManagementForm();

  return (
    <div className="claimPersonDamage">

			{
        urlParam.editPage &&
        <>

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LIST &&
						<ClaimContent
							title="Gestión de daños"
							subtitle="Añade cada daño uno a uno para que luego aparezcan en la orden"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.EDIT_CLAIM_REGISTER, true)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonDamage__content">
								<div className="claimPersonDamage__content__wrapper">
									{
										registerInfo?.personType.isNA() &&
											<Text className="claimPersonDamage__content__wrapper__warningText" type="paragraph" align="center">Es posible que el asegurado ya haya añadido daños desde su formulario. En caso de que la pieza esté mal, entra en el daño para corregirlo.</Text>
									}

									{
										registerInfo && registerInfo.damages.map(
											(damage, index) => (
												<div className="claimPersonDamage__content__wrapper__damage" key={`damage-${index}`}>
													<span className="claimPersonDamage__content__wrapper__damage__image" style={{ backgroundImage: `url(${Util.ASSET.COVERAGE_DT})` }}></span>
													<div className="claimPersonDamage__content__wrapper__damage__type">
														<Text type="label" uppercase>&Aacute;rea del da&ntilde;o</Text>
														<Text type="paragraph" bold>{damage.type.name}</Text>
													</div>
													<div className="claimPersonDamage__content__wrapper__damage__buttons">
														<button type="button" className="claimPersonDamage__content__wrapper__damage__buttons__edit"
															onClick={() => { formDamages.setDamageEdition(damage, index); damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.DATA); }}
														>
															{IconFactory.editIcon()}
														</button>
														{
															!damage.isPrior &&
																<button type="button" className="claimPersonDamage__content__wrapper__damage__buttons__remove"
																	onClick={() => { states.setDeleteItemIndex(index); states.setShowDeleteModal(true) }}
																>
																	{IconFactory.removeIcon()}
																</button>
														}
													</div>
												</div>
											)
										)
									}

									<div className="claimPersonDamage__content__wrapper__add-button">
										<button type="button"
											onClick={() => { formDamages.setDamageEdition(); damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.DATA) }}
										>
											{IconFactory.addIcon()}
											<Text type="paragraph" align="center" uppercase>
												A&ntilde;adir
												{states.selectedDamages.length > 0 ? <>&nbsp;otro&nbsp;</> : <>&nbsp;</>}
												&nbsp;da&ntilde;o
											</Text>
										</button>
									</div>
								</div>

								<div className="claimRegister__button-wrapper">
									<Button 
										type="button" 
										disabled={states.selectedDamages.length === 0}
										onClick={() => damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.EDIT_CLAIM_REGISTER)}
									>
										Siguiente
									</Button>
								</div>
								
								<DeleteItemModal
									type="damage" 
									show={states.showDeleteModal}
									onConfirmDelete={formDamages.removeDamage}
									onCancel={() => states.setShowDeleteModal(false)}
								/>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA && 
						<ClaimContent
							title="Gestión de daños"
							subtitle="Añade, edita o elimina"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.LIST, true)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonDamage__content">
								<div className="claimPersonDamage__content__wrapper">
									<ClaimDamage
										selectedDamage={formDamages.damageToEdit}
										selectedIndex={formDamages.damageIndexToEdit}
										formType='adjuster'
										onSelect={(damage) => { formDamages.addDamage(damage); damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.LIST, true) }}
										onEdit={(damage) => { formDamages.editDamage(damage); damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.LIST, true) }}
										damageType={states.selectedVehicleType}
										hasPreexistence
										hasComments
									/>
								</div>

								{/* <div className="claimRegister__button-wrapper">
									<Button type="button" onClick={() => damageNavigation(ClaimPersonDamageFacade.NAVIGATION_PARAMS.LIST)}>Crear</Button>
								</div> */}
							</div>
						</ClaimContent>
					}

					{/* {
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DAMAGE_SIGNATURE &&
						<ClaimContent
							title="Firma digital"
							subtitle="Firma de aceptación de los daños del vehículo"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.DATA, true)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">
								<div className="claimPersonManagement__content__wrapper">
									<div className="claimPersonManagement__content__wrapper__signatureText">
										<Controller
											control={form.control}
											name="signature"
											rules={{
												required: true
											}}
											render={({ field: { onChange } }) => (
												<ClaimSignature
													label="Firma del conductor"
													onSign={(signature) => onChange(signature!)}
													personFullName={`${form.getValues('name')} ${form.getValues('lastName')} ${form.getValues('secondLastName')}`}
												/>
											)}
										/>
									</div>
								</div>

								<div className="claimRegister__button-wrapper">
									<Button type="button" onClick={() => sendCurrentAdjusterInfo().then(() => damageNavigation(ClaimPersonManagementFacade.DAMAGE_NAVIGATION_PARAMS.EDIT_CLAIM_REGISTER))}>Validar</Button>
								</div>
							</div>
						</ClaimContent>
					} */}
        </>
      }
    </div>
  );
};

export default ClaimPersonDamagePage;
import { useRef } from 'react';
import PictureManagement from 'src/modules/claim-payments/ui/organisms/PictureManagement/PictureManagement';
import { DamageClaimNavigateValidationFacade } from 'src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade';
import { IdDocumentsFacade } from 'src/modules/claim-payments/controller/facades/id-documents.facade';
import ButtonSection from '../../molecules/ButtonSection/ButtonSection';
import './IdDocumentsPage.scss';

/**
 *
 */
interface IIdDocuments {

}

/**
 *
 */
const IdDocumentsPage = (props: IIdDocuments): JSX.Element => {
	/** */
	const contentRef = useRef<HTMLDivElement>(null);
	const { currentDamageClaim, picturesValidation, validatePictures, isAgreedPayment, AGREED_CURRENT_PATH, CLAIM_CURRENT_PATH } = IdDocumentsFacade.useIdDocumentsFacade(contentRef);
	const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id='id-documents-wrapper' className='flex-grow-100 flex'>
			<section id='id-documents-content' className='content height-100' ref={contentRef}>
				<h1>{`Solicitud pago ${isAgreedPayment ? 'pactado' : 'de daño'}`}</h1>
				<h3>Fotos de tu identificación</h3>
				<p>Por favor, ayúdanos a tomar las siguientes fotografías.</p>

				<div className='documents-wrapper'>
					{
						currentDamageClaim?.attachmentIdentification.map(
							(attachment, index) => {

								return <div className="picture-item" key={index}>
									<PictureManagement 
										attachment={attachment} 
										defaultPicture={attachment.thumbnail} 
										status
										onUpload={(a) => {attachment = a; validatePictures()}}
									>
										{attachment.title}
									</PictureManagement>
								</div>
							}
						)
					}
				</div>
			</section>

			<ButtonSection 
				backHandler={() => {navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'backward')}} 
				nextHandler={() => {navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward')}} 
				skipHandler={() => navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward')}
				nextButtonDisabled={!picturesValidation}
				hasSkip={true}
			/>
    </div>
  );
};

export default IdDocumentsPage;
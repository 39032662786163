import classNames from 'classnames';
import { DropdownItem, Label, Spinner } from "src/components";
import { createRef, PropsWithChildren, useCallback } from 'react';
import { useEffect, useState } from 'react';
import { IconFactory } from 'src/factory/icon.factory';

import './Dropdown.scss';
import { Util } from "src/utils/Util";

/**
 *
 */
interface IDropdown {

  items: { id: number | string, value: string }[];

  placeholder?: string;

  required?: boolean;

  onChange?: (item: { id: number | string, value: string }) => void;

	onDelete?: () => void;

  defaultValue?: { id: number | string, value: string };

	isLoading?: boolean;

	notFoundText?: string;

  allowEmpty?: boolean;
  /** */
  singleSelect?: boolean;

  disabled?: boolean;
  /** 
   * 
   */
	hasDeleteButton?: boolean;
  /**
   * 
   */
  cancelSearch?: boolean;
  /**
   * 
   */
  openOverflow?: boolean;
  /**
   * 
   */
  onDropdownOpen?: () => void;
}

/**
 *
 */
export const Dropdown = (props: PropsWithChildren<IDropdown>): JSX.Element => {

  const [isDefaultValueAvailable, setIsDefaultValueAvailable] = useState(true)
  const [isDropdowTouched, setIsDropdowTouched] = useState(false);
  const [isInputSelected, setIsInputSelected] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isMouseOver, setIsMouserOver] = useState(false);
	const [searchNotFound, setSearchNotFound] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [singleSelected, setSingleSelected] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<{ id: number | string, value: string }>({ id: -1, value: '' });

  const dropdownClassNames = classNames({
    'dropdown-wrapper': true,
    'relative': true,
    'disabled': (props.items.length === 0 && !props.allowEmpty) || props.disabled || singleSelected,
    'listOverflow': props.openOverflow && (isInputSelected || isMouseOver) && (props.items.length > 0 || searchNotFound)
  });

  /**
   * 
   * @param item 
   */
  const selectItem = useCallback((item: { id: number | string, value: string }): void => {
    setItemSelected(item);
    setInputValue('');
    setIsMouserOver(false);
    if (props.onChange) {
      props.onChange(item);
    }
  }, [props])

  /**
   * 
   * @param item 
   */
  const handleItemClick = (item: { id: number, value: string }): void => {
    setIsDropdowTouched(true);
    selectItem(item);

    if (props.cancelSearch) {
      setIsInputSelected(false)
    }
  }

  /**
	 * 
	 */
	const deteleSelection = () => {
		setItemSelected({ id: -1, value: '' });
		setInputValue('');
		if (props.onDelete)
			props.onDelete();
	};

  /**
   * 
   */
  const setDefaultSelectedValue = useCallback(
    () => {
      if (props.defaultValue && isDefaultValueAvailable) {
        setIsDefaultValueAvailable(false);
        selectItem(props.defaultValue);
        setIsDropdowTouched(true);
      }
    },
    [props.defaultValue, isDefaultValueAvailable, selectItem],
  )

  useEffect( () => {
    if (props.items.length === 1 && props.singleSelect) {
      selectItem(props.items[0]);
      setIsDropdowTouched(true);
      setSingleSelected(true);
    }
  }, [props.singleSelect, props.items, selectItem])

  /**
   * 
   */
  useEffect(
    () => {
      if (props.items.length === 0 && isFilled && !props.allowEmpty && !props.defaultValue) {
        setItemSelected({ id: -1, value: '' });
        setInputValue('');
      }

      if (props.items.length > 0 && !isFilled && isDropdowTouched) {
        setIsFilled(true);
      }

    }, [props.items, isDropdowTouched, isFilled, props.allowEmpty, props.defaultValue]
  )

  /**
   * 
   */
  const dropdownRef = createRef<HTMLDivElement>();
  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsInputSelected(false);
      }
    }

    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [dropdownRef]);

  /**
   * 
   */
  useEffect(
    () => {
      setDefaultSelectedValue();
    }, [setDefaultSelectedValue]
  )

	/**
	 * 
	 */
	useEffect(() => {
		if(props.notFoundText && inputValue.length > 0){
			const items = props.items;
			let notFound = true;
			for (const i of items) {
				if (i.value.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())) {
					notFound = false;
					break;
				}
			}
			setSearchNotFound(notFound);
		}
	}, [inputValue, setSearchNotFound, props.items, props.notFoundText])

  /**
   * 
   */
  useEffect(() => {
    if ((isInputSelected || isMouseOver) && (props.items.length > 0 || searchNotFound) && props.onDropdownOpen){
      props.onDropdownOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isInputSelected, isMouseOver, props.items, searchNotFound])

  return (
    <div className={dropdownClassNames} ref={dropdownRef}>

      <Label required={props.required} className="absolute">{props.children}</Label>

      <div className="selection relative">
        {
          inputValue === '' &&
          <>
            {
              itemSelected.value ?
                <p className="dropdown-value-selected absolute align-center height-100 width-100 border-box">
                  {itemSelected.value}
                </p> :
                <p className="dropdown-value-selected absolute align-center height-100 width-100 border-box placeholder">
                  {props.placeholder}
                </p>
            }
          </>
        }

        {
          !props.cancelSearch ?
            <input type="text" className="dropdown-value relative width-100 border-box"
              onChange={(e) => setInputValue(e.target.value)}
              onFocus={() => setIsInputSelected(true)}
              onBlur={() => setIsInputSelected(false)}
              value={inputValue}
            />
            :
            <p className="dropdown-value relative width-100 border-box"
              onClick={() => setIsInputSelected(true)}
            >
              {/* {itemSelected.value} */}
            </p>
        }

				{
					props.isLoading &&
					<span className="selection__loader absolute">
						<Spinner />
					</span>
				}

        {
					props.hasDeleteButton && itemSelected.value &&
					<span className="deleteIcon absolute flex align-center justify-center height-100" onClick={deteleSelection}>
						{IconFactory.closeIcon()}
					</span>
				}

        <span className="absolute flex align-center justify-center height-100">
          {IconFactory.dropDownArrowIcon({ className: 'arrowIcon' })}
        </span>
      </div>

      {
        (isInputSelected || isMouseOver) && (props.items.length > 0 || searchNotFound) &&
        <div className="dropdown" onMouseOver={() => setIsMouserOver(true)} onMouseLeave={() => setIsMouserOver(false)}>
          {
            props.items.map((item, index) => {
              const normalizedItemValue = Util.TRANSFORM.TEXT.normalize(item.value.toLocaleLowerCase())
              const normalizedInputValue = Util.TRANSFORM.TEXT.normalize(inputValue.toLocaleLowerCase())
              if (inputValue === '') {
                return (
                  <DropdownItem key={`${index}-${item.id}`} item={item} onSelect={(i) => handleItemClick(i as { id: number, value: string })}>
                    {item.value}
                  </DropdownItem>
                )
              } else if ((normalizedItemValue.includes(normalizedInputValue))) {
                return (
                  <DropdownItem key={`${index}-${item.id}`} item={item} onSelect={(i) => handleItemClick(i as { id: number, value: string })}>
                    {item.value}
                  </DropdownItem>
                )
              } else {
                return undefined;
              }

            })
          }

					{
						props.notFoundText && searchNotFound && 
						<div className="dropdown__notFoundText">
							<p>{props.notFoundText}</p>
							<p className="dropdown__notFoundText--small">(Asegúrate que esté bien escrito)</p>
						</div>
					}
        </div>
      }
    </div>
  );
};
import { Person  } from "src/classes/Person";
import { IPasswordData } from "../interfaces/IPassowrdData";

export namespace ChangePasswordAdapter {

  /**
   * 
   * @param user 
   * @param newPassowrd 
   */
  export const passwordDataAdapter = (user: Person, newPassowrd: string): IPasswordData => {
    let data: IPasswordData = {
      currentPassword: user.password,
      email: user.email,
      newPassword: newPassowrd,
      new_password_repeat: newPassowrd,
    }

    return data;
  }
}
import { SkeletonLoader } from "src/components";
import { Util } from "src/utils/Util";

import "./ClaimCardPicture.scss";

/**
 *
 */
interface IClaimCardPicture {

  /**
   * 
   */
  loader?: boolean;
}

/**
 *
 */
const ClaimCardPicture = (props: IClaimCardPicture): JSX.Element => {
  return (
    <div className="claim-picture height-100 flex align-center justify-center">
      {
        !props.loader &&
        <div className="claim-default-picture flex column justify-center align-center height-80 width-90">
          <img alt="crash-vehicle" src={Util.ASSET.CAR_CRASH} />
        </div>
      }
      {
        props.loader &&
        <SkeletonLoader type="square" />
      }
    </div>
  );
};

export default ClaimCardPicture;

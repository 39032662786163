import Camera from "src/libs/Camera/ui/template/Camera";
import './ClaimPicture.scss';
import { ClaimFileFacade } from "src/modules/claim-register/controller/facades/claim-file.facade";
import { Button, Modal } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { useEffect } from "react";

/**
 *
 */
interface IClaimPicture {

  defaultPicture?: string;

  onSelected?: (picture: string) => void;

  cancelEdit?: boolean;

	hasDelete?: boolean;

	removePicture?: () => void;
}

/**
 *
 */
const ClaimPicture = (props: IClaimPicture): JSX.Element => {
  const { camera, options, source } = ClaimFileFacade.useClaimFile(props.defaultPicture);

  useEffect(
    () => {
      if (props.onSelected) {
        props.onSelected(source);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source]
  )

  return (
    <div className="claimPicture">

      <div className="claimPicture__picture">
				{
					source === '' && props.hasDelete &&
						<div className="claimPicture__picture__close" onClick={() => { props.removePicture && props.removePicture() }}>
							{IconFactory.closeIcon()}
						</div>
				}
        {
          source !== '' ?
            <>
              {
                !props.cancelEdit &&
                <button type="button" className="claimPicture__picture__edit"
                  onClick={(e) => { e.preventDefault(); options.openOptions() }}
                >
                  {IconFactory.editIcon()}
                </button>
              }
              <img className="claimPicture__picture__image" src={source} alt="claim" />
            </>
            :
            <button type="button" className="claimPicture__picture__button"
              onClick={(e) => { e.preventDefault(); options.openOptions() }}
            >
              {IconFactory.cameraIcon()}
            </button>
        }
      </div>

      {
        camera.showCamera &&
        <Camera onSelect={(picture) => camera.onTakePicture(picture)} onCancel={() => camera.onCancelCamera()}></Camera>
      }

      <Modal show={options.showOptions} position="bottom" backgroundDismiss onClose={options.onCancelOptions}>
        <div className="vehicle-picture-options flex column">
          <Button type="button" icon={IconFactory.cameraIcon()} onClick={(e) => { e.preventDefault(); camera.openCamera() }}>
            Tomar fotos
          </Button>
          <Button type="button" fill="outline" icon={IconFactory.galleryIcon()} onClick={(e) => { e.preventDefault(); options.openGallery() }}>
            Subir desde galer&iacute;a
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ClaimPicture;
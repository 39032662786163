import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Person  } from "src/classes/Person";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { ClaimPaymentsFeature } from "../features/claim-payments.feature";
import { ClaimPaymentsSaga } from "../sagas/claim-payments.saga";
import { ClaimPaymentsSelector } from "../selectors/claim-payments.selector";
import { ClaimPaymentsSlice } from "../slices/claim-payments.slice";
import { ClaimPaymentsFacade } from "./claim-payments.facade";
import { DamageClaimNavigateValidationFacade } from "./damage-claim-navigate-validation.facade";

const CLAIM_CURRENT_PATH: string = CLAIM_PAYMENTS_PATH.PERSONAL_DATA;
const AGREED_CURRENT_PATH: string = AGREED_PAYMENTS_PATH.PERSONAL_DATA;

/**
 *
 */
export namespace ClaimsPersonalDataFacade {

	export const useClaimsPersonalDataFacade = () => {

		return { CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH };
	};

	export const useCreatePersonalData = () => {
		/** */
		useInjectReducer({ key: 'claimPayment', reducer: ClaimPaymentsSlice.reducer });
		useInjectSaga({key: 'claimPayment', saga: ClaimPaymentsSaga.claimPaymentSaga});
		const { currentUser, claimUser, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
		const [user, updateUser] = useState<Person>();
		const [isLoading, updateIsLoading] = useState(false);
		const storedClaimPayment = useSelector(ClaimPaymentsSelector.currentDamageClaim);
		const userPhone = `${currentUser?.profile?.phone?.countryCode?.code!}${currentUser?.profile?.phone?.number!}`;
		const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();
		const dispatch = useDispatch();

		/**
     * @param newVehicle 
     * @returns 
     */
		const createUser = async (newUser: Person): Promise<void> => {
			try {
				updateIsLoading(true);
				await ClaimPaymentsFeature.updatePersonalDataFeature(newUser, storedClaimPayment!);
				dispatch(ClaimPaymentsSlice.actions.storeClaimUser(newUser));
				navigationHandler( isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward');
			} catch (e) {
				console.error(e);
			} finally {
				updateIsLoading(false);
			}
		}

		useEffect(() => {
			if(claimUser)
				updateUser(claimUser);
		}, [claimUser, updateUser]);

		return { isAgreedPayment, user, userPhone, isLoading, createUser }
	}

}
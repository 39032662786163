import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects'
import { PolicyDriverDataAction } from "../actions/policy-driver-data.action";
import { PolicyDriverFeature } from "../features/policy-driver.feature";
import { PolicyDriverDataSlice } from "../slices/policy-driver-data.slice";

/**
 *
 */
export namespace PolicyDriverDataSaga {

  type StoredDriver = SagaReturnType<typeof PolicyDriverFeature.getDriverStoredFeature>;

  /**
   * 
   */
  function* getDriverStored() {
    const driver: StoredDriver = yield call(PolicyDriverFeature.getDriverStoredFeature);
    if (driver) {
      yield put(PolicyDriverDataSlice.actions.driverStored(driver));
    }
  }


  export function* driverDataSaga() {
    yield takeLatest(PolicyDriverDataAction.getDriverStored.type, getDriverStored)
  }
}
import { AxiosPromise } from "axios";
import { IActiveClaimsData } from "../interfaces/IActiveClaimsData";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IActiveAgreedClaimsData } from "../interfaces/IActiveAgreedClaimsData";
/**
 *
 */
export namespace ClaimsService {


  /**
   * 
   * @returns 
   */
  export const activeClaimListService = (): AxiosPromise<IActiveClaimsData> => {
    const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;
    return EnvironmentFactory.Http.SecureHttp(baseURL).get(Util.API_PATH.DAMAGE_PAYMENT);
  }

  /**
   * 
   * @returns 
   */
  export const activeAgreedClaimListService = (): AxiosPromise<IActiveAgreedClaimsData> => {
    const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;
    return EnvironmentFactory.Http.SecureHttp(baseURL).get(Util.API_PATH.AGREED_PAYMENT);
  }
}
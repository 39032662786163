import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { DamageClaim } from "src/classes/DamageClaim";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";

/**
 *
 */
export namespace ClaimPaymentsAction {

	export const getDamageClaimById: ActionCreatorWithPayload<{ damageClaim: string, isAgreedPayment: boolean }> = createAction('DAMAGE_CLAIM_BY_ID');

	export const updateCurrentPath: ActionCreatorWithPayload<string> = createAction('UPDATE_CURRENT_PATH');

	export const policyByNumber: ActionCreatorWithPayload<string, string> = createAction('POLICY_BY_NUMBER');

	export const storeDamageClaim: ActionCreatorWithPayload<DamageClaim> = createAction('CLAIM_PAYMENT');

	export const storeClaimUser: ActionCreatorWithPayload<Person> = createAction('STORE_CLAIM_USER');

	export const storeVehicle: ActionCreatorWithPayload<Vehicle> = createAction('STORE_VEHICLE');

	export const storeClaimVehicle: ActionCreatorWithPayload<Vehicle> = createAction('STORE_CLAIM_VEHICLE');

	export const updatePersonalData: ActionCreatorWithPayload<{ user: Person, damagePayment: DamageClaim }> = createAction('UPDATE_PERSONAL_DATA');

	export const updateDataStep: ActionCreatorWithPayload<number> = createAction('UPDATE_DATA_STEP');

	export const updatePicturesStep: ActionCreatorWithPayload<number> = createAction('UPDATE_PICTURES_STEP');

	export const updateDocumentsStep: ActionCreatorWithPayload<number> = createAction('UPDATE_DOCUMENTS_STEP');

	export const updateAccountStep: ActionCreatorWithPayload<number> = createAction('UPDATE_ACCOUNT_STEP');

	export const updateProposal: ActionCreatorWithPayload<boolean> = createAction('UPDATE_PROPOSAL');
	
  export const clearDamageClaim: ActionCreatorWithoutPayload = createAction('CLEAR_DAMAGE_CLAIM');

	export const updateIsAgreedPayment: ActionCreatorWithPayload<boolean> = createAction('UPDATE_IS_AGREED_PAYMENT');

	export const updateIsLoadingDamageClaim: ActionCreatorWithPayload<boolean> = createAction('UPDATE_IS_LOADING_DAMAGE_CLAIM');
}
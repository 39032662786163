import { Coverage } from "src/classes/plan/Coverage";
import { CoverageOption } from "src/classes/plan/CoverageOption";
import { Util } from "src/utils/Util";
import { ICoverageData } from "../interfaces/policy/ICoverageData";

export namespace CoreQuotationDataAdapter {

  /**
   * 
   */
  export const coverageDataAdapter = (coverages: ICoverageData[]): Coverage[] => {
    let coveragesList: Coverage[] = [];
    const rcpData = coverages.find(c => c.coverage_type.id === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCP.id);
    const rcbData = coverages.find(c => c.coverage_type.id === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCB.id);
    if (rcpData && rcbData) {
      let newCoverage: Coverage = new Coverage();
      newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.type;
      newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.name;
      newCoverage.id = rcpData.id;
      newCoverage.isEnabled = rcpData.enabled;
      newCoverage.assured = rcpData.sum_assured + rcbData.sum_assured;
      newCoverage.deductible = rcpData.deductible;
      newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DT.description;
      newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DT.include;
      newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DT.exclude;
      newCoverage.image = Util.ASSET.COVERAGE_DT;
      coveragesList.push(newCoverage);
    }

    for (const coverageData of coverages) {
      if (coverageData.coverage_type.id !== Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCP.id &&
        coverageData.coverage_type.id !== Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RCB.id) {
        let newCoverage: Coverage = new Coverage();
        newCoverage.id = coverageData.id;
        newCoverage.isEnabled = coverageData.enabled !== undefined ? true : false;
        newCoverage.assured = coverageData.sum_assured;
        newCoverage.deductible = coverageData.deductible;
        // Switch between known coverages ids in Utils and push it to the list
        switch (coverageData.coverage_type.id) {
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.GM.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.GM.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.GM.exclude;
            newCoverage.image = Util.ASSET.COVERAGE_GM;
            coveragesList.push(newCoverage);
            break;
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AL.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AL.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AL.exclude;
            newCoverage.image = Util.ASSET.COVERAGE_AL;
            coveragesList.push(newCoverage);
            break;
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AV.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AV.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.AV.exclude;
            newCoverage.image = Util.ASSET.COVERAGE_AV;
            newCoverage.isDefault = true;
            coveragesList.push(newCoverage);
            break;
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DM.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DM.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.DM.exclude;
            newCoverage.isDeductible = true;
            newCoverage.image = Util.ASSET.COVERAGE_DM;
            newCoverage.isDefault = true;
            coveragesList.push(newCoverage);
            break;
          case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.id:
            newCoverage.type = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.type;
            newCoverage.name = Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.name;
            newCoverage.description = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.RT.description;
            newCoverage.includeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.RT.include;
            newCoverage.excludeList = Util.CONSTANT.QUOTATION.COVERAGE_DESCRIPTIONS.RT.exclude;
            newCoverage.isDeductible = true;
            newCoverage.image = Util.ASSET.COVERAGE_RT;
            newCoverage.isDefault = true;
            coveragesList.push(newCoverage);
            break;
        }
      }
    }
    // end Set plan coverages

    // Set coverages options
    for (const coverage of coveragesList) {
      switch (coverage.type) {
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.type:
          coverage.customitationOptions.push(new CoverageOption(undefined, 2000000));
          coverage.customitationOptions.push(new CoverageOption(undefined, 3000000));
          coverage.customitationOptions.push(new CoverageOption(undefined, 4000000));
          coverage.allowDisable = false;
          break;
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.type:
          coverage.customitationOptions.push(new CoverageOption(undefined, 200000));
          coverage.customitationOptions.push(new CoverageOption(undefined, 300000));
          coverage.customitationOptions.push(new CoverageOption(undefined, 400000));
          coverage.allowDisable = false;
          break;
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.type:
          coverage.allowDisable = false;
          break;
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.type:
          coverage.customitationOptions.push(new CoverageOption(0.03, undefined));
          coverage.customitationOptions.push(new CoverageOption(0.05, undefined));
          coverage.customitationOptions.push(new CoverageOption(0.1, undefined));
          break;
        case Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.type:
          coverage.customitationOptions.push(new CoverageOption(0.05, undefined));
          coverage.customitationOptions.push(new CoverageOption(0.1, undefined));
          coverage.customitationOptions.push(new CoverageOption(0.15, undefined));
          break;
      }
    }

    return coveragesList;
  }
}
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleVersion } from "src/classes/vehicle/VehicleVersion";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { IVehicleStorage } from "src/core/interfaces/vehicle/IVehicleStorage";

/**
 * 
 */
export namespace CoreVehicleStorageAdapter {

  /**
   * 
   * @param vehicle 
   * @returns 
   */
  export const vehicleStorageAdapter = (data: Vehicle | IVehicleStorage): IVehicleStorage | Vehicle => {
    if (data instanceof Vehicle) {
      const vehicle: Vehicle = data as Vehicle;
      const dataStorage: IVehicleStorage = {
        brand: {
          id: vehicle.brand?.id as number,
          name: vehicle.brand?.name as string,
        },
        model: {
          id: vehicle.model?.id as number,
          brand: vehicle.brand?.id as number,
          name: vehicle.model?.name as string,
        },
        year: {
          value: vehicle.year?.value as number,
          brand: vehicle.brand?.id as number,
          model: vehicle.model?.id as number,
        },
        version: {
          id: vehicle.version?.id as number,
          description: vehicle.version?.description as string,
        },
        color: vehicle.color,
        plate: vehicle.plate,
        vin: vehicle.vin,
      }
      return dataStorage;
    } else {
      const vehicleData: IVehicleStorage = data as IVehicleStorage;
      let vehicle: Vehicle = new Vehicle();
      vehicle.brand = new VehicleBrand(vehicleData.brand.id, vehicleData.brand.name);
      vehicle.model = new VehicleModel(vehicleData.model.id, vehicleData.model.brand, vehicleData.model.name);
      vehicle.year = new VehicleYear(vehicleData.year.brand, vehicleData.year.model, vehicleData.year.value);
      vehicle.version = new VehicleVersion(vehicleData.version.id, vehicleData.version.description);
      vehicle.vin = vehicleData.vin;
      vehicle.plate = vehicleData.plate;
      vehicle.color = vehicleData.color;
      return vehicle;
    }
  }
}
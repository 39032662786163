import { Endorse } from "src/classes/policy/Endorse";
import { PolicyEndorsementsHistoryAdapter } from "../adapters/policy-endorsements-history.adapter";
import { PolicyEndorsementsService } from "../services/policy-endorsements.service";

export namespace PolicyEndorsementsHistoryFeature {

  /**
   * 
   * @param policyNumber 
   * @returns 
   */
  export const endorsementsHistoryFeature = async (policyId: string): Promise<Endorse[] | undefined> => {
    try {
      const { data } = await PolicyEndorsementsService.policyEndorsementsService(policyId);
      const endorsements = PolicyEndorsementsHistoryAdapter.endorsementsHistoryDataAdapter(data);
      return endorsements;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

}
import { RootState } from "src/store/store";

/**
 *
 */
export namespace PolicyEndorsementsSelector {

  export const currentPolicy = (state: RootState) => state.policyEndorsements?.policy!;

  export const verified = (state: RootState) => state.policyEndorsements?.verified;

  export const completed = (state: RootState) => state.policyEndorsements?.completed;

  export const newEndorsements = (state: RootState) => state.policyEndorsements?.endorsements;
}
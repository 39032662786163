import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { CoreInstanceSlice } from "src/core/slices/instance.slice";
import { HeaderAction } from "../actions/header.action";

/**
 *
 */
export namespace HeaderSaga {


  /**
   * 
   */
  function* toggleMenu(action: PayloadAction<boolean>) {
    yield put(CoreInstanceSlice.actions.openMenu(action.payload));
  }


  export function* headerSaga() {
    yield takeLatest(HeaderAction.toggleMenu.type, toggleMenu);
  }
}
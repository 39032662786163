import { Outlet } from 'react-router';
import { PolicyCreationFacade } from '../../controller/facades/policy-creation.facade';
import './PolicyCreation.scss';

/**
 *
 */
interface IPolicyCreation {

}

/**
 *
 */
const PolicyCreationPage = (props: IPolicyCreation): JSX.Element => {
	/** */
  PolicyCreationFacade.usePolicyCreation();
  PolicyCreationFacade.useOwnerValidation();
  const { isLastStep } = PolicyCreationFacade.useStepStatus();

  return (
    <div id="policy-creation-wrapper" className="flex-grow-100 flex">
      <div className="border-box width-100 flex column">
        <div is-last-step={isLastStep ? '' : null} className="policy-creation-content flex-grow-100 relative border-box flex">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PolicyCreationPage;
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router';
import SetpIcon from '../../atoms/SetpIcon/SetpIcon';
import StepName from '../../atoms/StepName/StepName';
import './StepSegment.scss';

/**
 *
 */
interface IStepSegment {
  /**
   * 
   */
  path: string;
  /**
   * 
   */
  completed?: boolean;
  /**
   * 
   */
  Icon: JSX.Element;
  /**
   * 
   */
  isFirst?: boolean;
  /**
   * 
   */
  isLast?: boolean;
}

/**
 *
 */
const StepSegment = (props: PropsWithChildren<IStepSegment>): JSX.Element => {

  const location = useLocation();
  const active = location.pathname === props.path;

  return (
    <div className="policy-endorsements-step">
      <SetpIcon isFirst={props.isFirst} isLast={props.isLast} Icon={props.Icon} active={active} completed={props.completed} />
      <StepName>
        {props.children}
      </StepName>
    </div>
  );
};

export default StepSegment;
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Person  } from "src/classes/Person";
import { IDriverDataStateReducer } from "src/store/states/modules-state/IDriverDataStateReducer";

/**
 *
 */
export namespace PolicyDriverDataSlice {

  /**
   * 
   */
  const initialState: IDriverDataStateReducer = {
    storedDriver: undefined,
  };

  /**
   * 
   * @param state 
   * @param action 
   */
   const clear: CaseReducer<IDriverDataStateReducer> = (state) => {
    state.storedDriver = undefined;
  }
  /**
   * 
   * @param state 
   * @param action 
   */
   const driverStored: CaseReducer<IDriverDataStateReducer, PayloadAction<Person | undefined>> = (state, action) => {
    state.storedDriver = action.payload;
  }

  const reducers = {
    clear,
    driverStored,
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'driverData',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
import { useInjectSaga } from "redux-injectors";
import { DashboardSaga } from "../sagas/dashboard.saga";
import { useDispatch, useSelector } from "react-redux";
import { DashboardAction } from "../actions/dashboard.action";
import { useEffect, useState } from "react";
import { CoreSelector } from "src/core/selectors/core.selector";

export namespace DashboardFacade {

  /**
   * 
   */
  export const useDashboard = () => {
    useInjectSaga({ key: 'dashboard', saga: DashboardSaga.dashboardSaga });
    const dispatch = useDispatch();
    
    useEffect(() => {
      dispatch(DashboardAction.userInfo());
    }, [dispatch])

    useEffect(
      () => {
        dispatch(DashboardAction.dashboardMenu(true));
        return () => {
          dispatch(DashboardAction.dashboardMenu(false));
        }
      }, [dispatch]
    )
  }

  /**
   * 
   */
  export const useCurrentUserInfo = () => {


  }

  /**
   * 
   */
  export const useMenu = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const openMenu = useSelector(CoreSelector.openMenu);
    const dispatch = useDispatch();
    
    useEffect(
      () => {
        setShowMenu(openMenu);
      }, [openMenu]
    )

    /**
     * 
     */
    const toggleMenu = (): void => {
      dispatch(DashboardAction.menuVisibility(!showMenu));
      setShowMenu(!showMenu);
    }

    return { showMenu, toggleMenu }
  }
}
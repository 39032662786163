import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CoreTokenStorageFeature } from "src/core/features/token-storage.feature";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { LOGIN_PATH } from "src/routes/paths/login.paths";
import { Util } from "src/utils/Util";
import { ClaimPaymentsFacade } from "./claim-payments.facade";

export namespace OnboardingFacade {

	export const useOnboardingFacade = () => {
		/** */
		const { isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
		/** */
		const [step, updateStep] = useState<number>(0);
		const [crabelerStatus, updateCrabelerStatus] = useState<number>(0);
		
		/** */
		const navigate = useNavigate();

		/** */
		const authHandler = () => {
			if(!authValidation())
				stepHandler();
			else {
				if(isAgreedPayment)
					navigate(AGREED_PAYMENTS_PATH.POLICY_DATA);
				else
					navigate(CLAIM_PAYMENTS_PATH.POLICY_DATA);
			}
		};

		/** */
		const stepHandler = () => {
			if(step === 0)
				updateStep(step + 1);
			else {
				if (crabelerStatus === 1) {
					let queryParams: string;

					if (isAgreedPayment)
						queryParams = `?dc=${btoa('agreed-claim')}`;
					else 
						queryParams = `?dc=${btoa('damage-claim')}`;

					localStorage.setItem(Util.KEY.REDIRECT_ONBOARDING_STORAGE, 'true');
					navigate(`${LOGIN_PATH.LOGIN}${queryParams}`);
				} else {
					if (isAgreedPayment)
						navigate(AGREED_PAYMENTS_PATH.POLICY_DATA);
					else 
						navigate(CLAIM_PAYMENTS_PATH.POLICY_DATA);
				}
			}
		};

		/**
		 * 
		 */
		const authValidation = () => {
			const isValid = CoreTokenStorageFeature.verifyTokenFeature();
			return isValid;
		};

		useEffect(() => {
			localStorage.removeItem(Util.KEY.REDIRECT_ONBOARDING_STORAGE);
		}, [])

		return { 
			step,
			crabelerStatus,
			isAgreedPayment,
			updateCrabelerStatus,
			authHandler,
			stepHandler
		};
	};

}
import { combineReducers } from 'redux'
import { CoreAuthSlice } from 'src/core/slices/auth.slice'
import { CoreInstanceSlice } from 'src/core/slices/instance.slice'


export const rootReducer = (injectedReducers = {}) =>
  combineReducers(
    {
      auth: CoreAuthSlice.reducer,
      instance: CoreInstanceSlice.reducer,
      ...injectedReducers
    }
  )


import { Button, Modal, Spinner } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { ReferralsFacade } from "src/modules/referrals/controller/facades/referrals.facade";
import { Util } from "src/utils/Util";

import './RewardDetails.scss';


/**
 *
 */
const RewardDetailsPage = (): JSX.Element => {
  const { currentReward, onReturn, currentUser, isLoading, exchangePoints } = ReferralsFacade.useRewardDetails();

  return (
    <Modal show={true} position="center">
      <div id="reward-details-wrapper" className="relative">
        {
          currentReward && currentUser &&
          <>
            <button disabled={isLoading} type="button" className="absolute" onClick={(e) => { e.preventDefault(); onReturn() }}>
              {IconFactory.closeIcon()}
            </button>

            <h2 className="text-center">
              {currentReward.title}
            </h2>

            <section>
              <img className="block" alt="RewardImage" src={currentReward.image}></img>
            </section>

            <section className="flex column align-center justify-center">
              <span className="block" style={{ backgroundImage: `url(${Util.ASSET.BADGE_POINTS})` }}></span>
              <h3 className="text-center">
                Canjealo con Puntos Crabi <br />
                <span className="inline-block">{currentReward.points}</span>
              </h3>
            </section>

            <section className="current-points flex column align-center">
              <p><b>Puntos disponibles:</b> {currentUser.profile?.referral?.points}</p>
            </section>

            <section className="buttons-wrapper flex column">
              <Button type="button"
                loading={isLoading}
                disabled={isLoading || currentUser.profile?.referral?.points! < currentReward?.points}
                onClick={exchangePoints}
                icon={IconFactory.exchangeIcon()}>
                Canjear por {currentReward.points}
              </Button>
              <Button fill="outline" disabled={isLoading} onClick={(e) => { e.preventDefault(); onReturn() }}>Volver</Button>
            </section>

          </>
        }

        {
          !currentReward &&
          <Spinner color="main"></Spinner>
        }
      </div>
    </Modal>
  );
};

export default RewardDetailsPage;
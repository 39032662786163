import { Util } from "src/utils/Util";
import { InvoiceFile } from "./InvoiceFile";

export class InvoiceStatus {

  /**
   *
   */
  private _expirationDate: Date;
  set expirationDate(_expirationDate: Date) { this._expirationDate = _expirationDate };
  get expirationDate(): Date { return this._expirationDate };

  /**
   *
   */
  private _status: number;
  set status(_status: number) { this._status = _status };
  get status(): number { return this._status };

  /**
   *
   */
  private _invoicesFile: InvoiceFile[];
  set invoicesFile(_invoicesFile: InvoiceFile[]) { this._invoicesFile = _invoicesFile };
  get invoicesFile(): InvoiceFile[] { return this._invoicesFile };
  
	constructor(_expirationDate: Date, _status: number) {
    this._expirationDate = _expirationDate;
    this._status = _status;
    this._invoicesFile = [];
	}

  /**
   * 
   */
  public isAvailable(): boolean {
    return this._status === Util.STATUS_CODE.POLICY_INVOICE_STATUS.AVAILABLE;
  }

  /**
   * 
   */
  public isExpired(): boolean {
    return this._status === Util.STATUS_CODE.POLICY_INVOICE_STATUS.EXPIRED;
  }
  
  /**
   * 
   */
  public isFinished(): boolean {
    return this._status === Util.STATUS_CODE.POLICY_INVOICE_STATUS.FINISHED;
  }
  
  /**
   * 
   * @returns 
   */
  public isEmpty(): boolean {
    return this._status === Util.STATUS_CODE.POLICY_INVOICE_STATUS.EMPTY;
  }
  
  /**
   * 
   * @returns 
   */
  public filesError(): boolean {
    return this._status === Util.STATUS_CODE.POLICY_INVOICE_STATUS.FILE_ERROR;
  }
}
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IPasswordRestoreRequest } from "../interfaces/IPasswordRestoreRequest";

/**
 *
 */
export namespace ForgotPasswordService {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const sendRecoveryEmailService = (data: { email: string }): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp().post(Util.API_PATH.EMAIL_RECOVERY_PASSWORD, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const restorePasswordService = (data: IPasswordRestoreRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp().post(Util.API_PATH.RESTORE_PASSWORD, data);
  };

}
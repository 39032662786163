/**
 * 
 */
export class Phone {

  /**
   *
   */
  private _number: string;
  set number(_number: string) { this._number = _number };
  get number(): string { return this._number };
  /**
   *
   */
  private _countryCode: TCountryCode | undefined;
  set countryCode(_countryCode: TCountryCode | undefined) { this._countryCode = _countryCode };
  get countryCode(): TCountryCode | undefined { return this._countryCode };

  constructor(_number?: string) {
    this._number = _number? _number : '';
  }

  /**
   * 
   */
  public setCountryCodeByFullNumber(_fullNumber: string): void {
    const phoneIndex: number = _fullNumber.length - 10;
    const phoneNumber: string = _fullNumber.substring(_fullNumber.length, phoneIndex);
    const phoneCode: string = _fullNumber.slice(0, phoneIndex);
    this._number = phoneNumber;
    this._countryCode = {
      code: `${phoneCode}`
    }
  }

  /**
   * 
   * @returns 
   */
  public getFullNumber(defaultMx?: boolean): string {
    if (this._countryCode) {
      if (defaultMx) {
        return `+52${this._number}`
      } else {
        return `${this._countryCode.code}${this._number}`;
      }
    } else {
      return this._number;
    }
  }
}
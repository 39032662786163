import { ButtonLink, Segment } from "src/components";
import { Link } from "react-router-dom";
import { PolicyFacade } from "src/modules/policy/controller/facades/policy.facade";
import { POLICY_PARAMS, POLICY_PATH } from "src/routes/paths/policy.paths";
import PolicyList from "../../components/PolicyList/PolicyList";

import "./PolicyStatus.scss";
import { POLICY_CREATION_PATH, VEHICLE_ROUTE_ID } from "src/routes/paths/policy-creation.paths";

/**
 *
 */
const PolicyStatusPage = (): JSX.Element => {
  const { policyFilteredList, status, missingPolicyPictures, failedPolicyPayment } = PolicyFacade.usePolicyList();

  return (
    <div className="height-100 flex column space-between">
      <header>
        {
          missingPolicyPictures &&
          <div className="policy-warning pictures width-95 flex align-center border-box">
            <span className="flex-grow-100">
              Tu p&oacute;liza <b>{missingPolicyPictures.policyNumber}</b> no tiene fotos del veh&iacute;culo.
              Es necesario que las env&iacute;es para que podamos cubrirte en caso de un <b>siniestro</b>.
            </span>
            <Link className="text-center" type="button" to={`${POLICY_PATH.PICTURES}/${missingPolicyPictures.policyNumber}`}>
              Subir fotos
            </Link>
          </div>
        }

        {
          failedPolicyPayment &&
          <div className="policy-warning payment width-95 flex align-center border-box">
            <span className="flex-grow-100">
              Tu p&oacute;liza <b>{failedPolicyPayment.policyNumber}</b> ha presentado un fallo con el pago.
            </span>
            <Link className="text-center" type="button" to={`${POLICY_PATH.PAYMENT_METHOD}/${failedPolicyPayment.policyNumber}`}>
              Cambiar tarjeta
            </Link>
          </div>
        }
        <Segment links={
          [
            { to: `${POLICY_PATH.STATUS}/${POLICY_PARAMS.STATUS.ACTIVE}`, name: 'Activas' },
            { to: `${POLICY_PATH.STATUS}/${POLICY_PARAMS.STATUS.EXPIRED}`, name: 'Vencidas' },
            { to: `${POLICY_PATH.STATUS}/${POLICY_PARAMS.STATUS.CANCELLED}`, name: 'Canceladas' },
          ]
        }
        />
      </header>
      <PolicyList policyList={policyFilteredList} status={status} />

      <footer className="text-center">
        <ButtonLink to={POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_DATA)}>Contratar nueva p&oacute;liza</ButtonLink>
      </footer>
    </div>
  );
};

export default PolicyStatusPage;

import { createRef, PropsWithChildren, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Segment.scss';

/**
 *
 */
interface ISegment {

  links?: { to: string, name: string }[];

  buttons?: { name: string, code: number }[];

  onClick?: (code: number) => void;

	defaultValue?: number;
}

/**
 *
 */
export const Segment = (props: PropsWithChildren<ISegment>): JSX.Element => {
  const location = useLocation();
  const wrapperRef = createRef<HTMLDivElement>();
  const [currentName, setCurrentName] = useState<string>();
  const [indicatorElement, setIndicatorElement] = useState<HTMLSpanElement>();

  /**
   * 
   * @param value 
   * @returns 
   */
  const isActive = (value: string): boolean => {
    if (props.links) {
      if (location.pathname.includes(value)) {
        translateIndicator(props.links.findIndex(l => location.pathname.includes(l.to)))
      }

      return location.pathname.includes(value);
    } else if (props.buttons) {
      return currentName === value;
    }

    return false;
  }

  /**
   * 
   */
  const translateIndicator = (index: number): void => {
    if (indicatorElement) {
      indicatorElement.style.transform = `translateX(${100 * index}%)`;
    }
  }

  /**
   * 
   * @param name 
   */
  const onSegmentClick = (value: { name: string, code: number }, index: number): void => {
    translateIndicator(index);
    setCurrentName(value.name);
    if (props.onClick) {
      props.onClick(value.code);
    }
  }

  /**
   * 
   */
  useEffect(
    () => {
      if (wrapperRef.current) {
        const span = document.createElement('span');
        span.className = 'segment__indicator';
        span.style.width = `${wrapperRef.current.children[0].clientWidth}px`;

				if (props.defaultValue && props.buttons) {
					for (let i = 0; i < props.buttons.length; i++) {
						if (props.buttons[i].code === props.defaultValue) {
							span.style.transform = `translateX(${100 * i}%)`;
						}
					}
				}

        setIndicatorElement(span);
        if (indicatorElement === undefined) {
          wrapperRef.current.appendChild(span);
        } else {
          wrapperRef.current.replaceChild(span, indicatorElement);
        }
      }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] 
  );

  /**
   * Trigger default segment value: element at index 0.
   * @returns 
   */
  const useMountEffect = () => useEffect(
    () => {
      if (props.buttons) {
				if (props.defaultValue) {
					for (let i = 0; i < props.buttons.length; i++) {
						if (props.buttons[i].code === props.defaultValue) {
							onSegmentClick(props.buttons[i], i);
							break;
						}
					}
				} else {
					onSegmentClick(props.buttons[0], 0);
				}
      }
    }, []
  );
  useMountEffect();

  return (
    <div className="segment"
      ref={wrapperRef}
    >
      {
        props.links && props.links.map(
          (link, index) => {
            return <Link key={index} to={link.to} segment-active={isActive(link.to) ? '' : null} >{link.name}</Link>
          }
        )
      }

      {
        props.buttons && props.buttons.map(
          (value, index) => {
            return (
              <button type="button" className="segment__button"
                key={value.code}
                segment-active={isActive(value.name) ? '' : null}
                onClick={(e) => { e.preventDefault(); onSegmentClick(value, index) }}>
                {value.name}
              </button>
            )
          }
        )
      }
    </div>
  );
};
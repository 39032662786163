import { useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import { useLocation } from "react-router-dom";
import { IconFactory } from "src/factory/icon.factory";
import MenuLink from "../../atoms/MenuLink/MenuLink";
import './MenuSection.scss';

/**
 *
 */
interface IMenuSection {

  title?: string;

  items: { path: string, name: string }[]

  onNavigate?: () => void;
}

/**
 *
 */
const MenuSection = (props: IMenuSection): JSX.Element => {
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } = useCollapse({ duration: 400 });
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const isMobile: boolean = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(
    () => {
      const item = props.items.find(i => location.pathname.includes(i.path));
      if (item) {
        setExpanded(true);
        setIsActive(true);
      } else {
        setExpanded(false);
        setIsActive(false);
      }
    }, [location.pathname, setExpanded, props.items]
  )

  return (

    <section className="menu-section-wrapper"
      onMouseEnter={(e) => { e.preventDefault(); !isMobile && setExpanded(true) }}
      onMouseLeave={(e) => { e.preventDefault(); !isMobile && !isActive && setExpanded(false) }}
    >
      <div className="menu-toggle flex align-center space-between" {...getToggleProps()} menu-toggled={isExpanded ? '' : null}>
        <h2>{props.title}</h2>
        <span>
          {IconFactory.dropDownArrowIcon()}
        </span>

      </div>

      <ul {...getCollapseProps()}>
        {props.items.map(
          (item, index) => (
            <li key={index + item.name}>
              <MenuLink path={item.path} onNavigate={props.onNavigate}>
                {item.name}
              </MenuLink>
            </li>
          )
        )}
      </ul>
    </section>
  );
};

export default MenuSection;
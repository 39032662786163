import { Button, FormError, InputLabel } from "src/components";
import PasswordManagment from "src/libs/PasswordManagment/ui/template/PasswordManagment";
import { ChangePasswordFormFacade } from "src/modules/profile/controller/facades/change-password-form.facade";
import { SecurityFacade } from "src/modules/profile/controller/facades/security.facade";

import './Security.scss';

/**
 *
 */
interface ISecurity {

}

/**
 *
 */
const SecurityPage = (props: ISecurity): JSX.Element => {
  const { register, formState, CURRENT_PASSWORD_ERRORS, getValues } = ChangePasswordFormFacade.useChangePasswordForm();
  const { isNewPasswordValid, isUpdating, validateNewPassword, changePassword } = SecurityFacade.useChangePassword();
  return (
    <div id="security-wrapper">

      <div className="change-password-wrapper">
        <h1 className="text-center">Cambiar contrase&ntilde;a</h1>
        <form>

          <div className="inline-input relative">
            <InputLabel
              required
              placeholder="Ingresa tu contraseña"
              type="password"
              defaultValue=""
              errors={!!formState.errors.currentPassword}
              {...register("currentPassword", {
                required: true,
              })}
            >
              Contrase&ntilde;a actual
            </InputLabel>
            <FormError schema={CURRENT_PASSWORD_ERRORS} type={formState.errors?.currentPassword?.type} />
          </div>
        </form>

        <PasswordManagment isValidForm={validateNewPassword} />

        <div className="button-wrapper flex justify-flex-end">
          <Button type="button"
            loading={isUpdating}
            disabled={!formState.isValid || !isNewPasswordValid || isUpdating}
            onClick={() => changePassword(getValues().currentPassword)}
          >
            Actualizar contrase&ntilde;a
          </Button>
        </div>

      </div>


    </div>
  );
};

export default SecurityPage;
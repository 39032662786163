//https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg
// List originally from [jackocnr/intl-tel-input](https://github.com/jackocnr/intl-tel-input)
export const countries = [
  {
    id: 'AF',
    label: 'Afganistán',
    dialCode: '+93',
  },
  {
    id: 'AX',
    label: 'Alandia',
    dialCode: '+358',
  },
  {
    id: 'AL',
    label: 'Albania',
    dialCode: '+355',
  },
  {
    id: 'DZ',
    label: 'Argelia',
    dialCode: '+213',
  },
  {
    id: 'AS',
    label: 'Samoa Americana',
    dialCode: '+1684',
  },
  {
    id: 'AD',
    label: 'Andorra',
    dialCode: '+376',
  },
  {
    id: 'AO',
    label: 'Angola',
    dialCode: '+244',
  },
  {
    id: 'AI',
    label: 'Anguilla',
    dialCode: '+1264',
  },
  {
    id: 'AQ',
    label: 'Antártida',
    dialCode: '+672',
  },
  {
    id: 'AG',
    label: 'Antigua y Barbuda',
    dialCode: '+1268',
  },
  {
    id: 'AR',
    label: 'Argentina',
    dialCode: '+54',
  },
  {
    id: 'AM',
    label: 'Armenia',
    dialCode: '+374',
  },
  {
    id: 'AW',
    label: 'Aruba',
    dialCode: '+297',
  },
  {
    id: 'AU',
    label: 'Australia',
    dialCode: '+61',
  },
  {
    id: 'AT',
    label: 'Austria',
    dialCode: '+43',
  },
  {
    id: 'AZ',
    label: 'Azerbaiyán',
    dialCode: '+994',
  },
  {
    id: 'BS',
    label: 'Bahamas',
    dialCode: '+1242',
  },
  {
    id: 'BH',
    label: 'Bahrein',
    dialCode: '+973',
  },
  {
    id: 'BD',
    label: 'Bangladesh',
    dialCode: '+880',
  },
  {
    id: 'BB',
    label: 'Barbados',
    dialCode: '+1246',
  },
  {
    id: 'BY',
    label: 'Bielorrusia',
    dialCode: '+375',
  },
  {
    id: 'BE',
    label: 'Bélgica',
    dialCode: '+32',
  },
  {
    id: 'BZ',
    label: 'Belice',
    dialCode: '+501',
  },
  {
    id: 'BJ',
    label: 'Benín',
    dialCode: '+229',
  },
  {
    id: 'BM',
    label: 'Bermudas',
    dialCode: '+1441',
  },
  {
    id: 'BT',
    label: 'Bután',
    dialCode: '+975',
  },
  {
    id: 'BO',
    label: 'Bolivia',
    dialCode: '+591',
  },
  {
    id: 'BQ',
    label: 'Bonaire, San Eustaquio y Saba',
    dialCode: '+5997',
  },
  {
    id: 'BA',
    label: 'Bosnia y Herzegovina',
    dialCode: '+387',
  },
  {
    id: 'BW',
    label: 'Botswana',
    dialCode: '+267',
  },
  {
    id: 'BV',
    label: 'Isla Bouvet',
    dialCode: '+',
  },
  {
    id: 'BR',
    label: 'Brasil',
    dialCode: '+55',
  },
  {
    id: 'IO',
    label: 'Territorio Británico del Océano Índico',
    dialCode: '+246',
  },
  {
    id: 'UM',
    label: 'Islas Ultramarinas Menores de Estados Unidos',
    dialCode: '+',
  },
  {
    id: 'VG',
    label: 'Islas Vírgenes del Reino Unido',
    dialCode: '+1284',
  },
  {
    id: 'VI',
    label: 'Islas Vírgenes de los Estados Unidos',
    dialCode: '+1 340',
  },
  {
    id: 'BN',
    label: 'Brunei',
    dialCode: '+673',
  },
  {
    id: 'BG',
    label: 'Bulgaria',
    dialCode: '+359',
  },
  {
    id: 'BF',
    label: 'Burkina Faso',
    dialCode: '+226',
  },
  {
    id: 'BI',
    label: 'Burundi',
    dialCode: '+257',
  },
  {
    id: 'KH',
    label: 'Camboya',
    dialCode: '+855',
  },
  {
    id: 'CM',
    label: 'Camerón',
    dialCode: '+237',
  },
  {
    id: 'CA',
    label: 'Canadá°',
    dialCode: '+1',
  },
  {
    id: 'CV',
    label: 'Cabo Verde',
    dialCode: '+238',
  },
  {
    id: 'KY',
    label: 'Islas Caim√°n',
    dialCode: '+1345',
  },
  {
    id: 'CF',
    label: 'República Centroafricana',
    dialCode: '+236',
  },
  {
    id: 'TD',
    label: 'Chad',
    dialCode: '+235',
  },
  {
    id: 'CL',
    label: 'Chile',
    dialCode: '+56',
  },
  {
    id: 'CN',
    label: 'China',
    dialCode: '+86',
  },
  {
    id: 'CX',
    label: 'Isla de Navidad',
    dialCode: '+61',
  },
  {
    id: 'CC',
    label: 'Islas Cocos o Islas Keeling',
    dialCode: '+61',
  },
  {
    id: 'CO',
    label: 'Colombia',
    dialCode: '+57',
  },
  {
    id: 'KM',
    label: 'Comoras',
    dialCode: '+269',
  },
  {
    id: 'CG',
    label: 'Congo',
    dialCode: '+242',
  },
  {
    id: 'CD',
    label: 'Congo (Rep. Dem.)',
    dialCode: '+243',
  },
  {
    id: 'CK',
    label: 'Islas Cook',
    dialCode: '+682',
  },
  {
    id: 'CR',
    label: 'Costa Rica',
    dialCode: '+506',
  },
  {
    id: 'HR',
    label: 'Croacia',
    dialCode: '+385',
  },
  {
    id: 'CU',
    label: 'Cuba',
    dialCode: '+53',
  },
  {
    id: 'CW',
    label: 'Curaçao',
    dialCode: '+599',
  },
  {
    id: 'CY',
    label: 'Chipre',
    dialCode: '+357',
  },
  {
    id: 'CZ',
    label: 'República Checa',
    dialCode: '+420',
  },
  {
    id: 'DK',
    label: 'Dinamarca',
    dialCode: '+45',
  },
  {
    id: 'DJ',
    label: 'Yibuti',
    dialCode: '+253',
  },
  {
    id: 'DM',
    label: 'Dominica',
    dialCode: '+1767',
  },
  {
    id: 'DO',
    label: 'República Dominicana',
    dialCode: '+1809',
  },
  {
    id: 'EC',
    label: 'Ecuador',
    dialCode: '+593',
  },
  {
    id: 'EG',
    label: 'Egipto',
    dialCode: '+20',
  },
  {
    id: 'SV',
    label: 'El Salvador',
    dialCode: '+503',
  },
  {
    id: 'GQ',
    label: 'Guinea Ecuatorial',
    dialCode: '+240',
  },
  {
    id: 'ER',
    label: 'Eritrea',
    dialCode: '+291',
  },
  {
    id: 'EE',
    label: 'Estonia',
    dialCode: '+372',
  },
  {
    id: 'ET',
    label: 'Etiopía',
    dialCode: '+251',
  },
  {
    id: 'FK',
    label: 'Islas Malvinas',
    dialCode: '+500',
  },
  {
    id: 'FO',
    label: 'Islas Faroe',
    dialCode: '+298',
  },
  {
    id: 'FJ',
    label: 'Fiyi',
    dialCode: '+679',
  },
  {
    id: 'FI',
    label: 'Finlandia',
    dialCode: '+358',
  },
  {
    id: 'FR',
    label: 'Francia',
    dialCode: '+33',
  },
  {
    id: 'GF',
    label: 'Guayana Francesa',
    dialCode: '+594',
  },
  {
    id: 'PF',
    label: 'Polinesia Francesa',
    dialCode: '+689',
  },
  {
    id: 'GA',
    label: 'Gabón',
    dialCode: '+241',
  },
  {
    id: 'GM',
    label: 'Gambia',
    dialCode: '+220',
  },
  {
    id: 'GE',
    label: 'Georgia',
    dialCode: '+995',
  },
  {
    id: 'DE',
    label: 'Alemania',
    dialCode: '+49',
  },
  {
    id: 'GH',
    label: 'Ghana',
    dialCode: '+233',
  },
  {
    id: 'GI',
    label: 'Gibraltar',
    dialCode: '+350',
  },
  {
    id: 'GR',
    label: 'Grecia',
    dialCode: '+30',
  },
  {
    id: 'GL',
    label: 'Groenlandia',
    dialCode: '+299',
  },
  {
    id: 'GD',
    label: 'Grenada',
    dialCode: '+1473',
  },
  {
    id: 'GP',
    label: 'Guadalupe',
    dialCode: '+590',
  },
  {
    id: 'GU',
    label: 'Guam',
    dialCode: '+1671',
  },
  {
    id: 'GT',
    label: 'Guatemala',
    dialCode: '+502',
  },
  {
    id: 'GG',
    label: 'Guernsey',
    dialCode: '+44',
  },
  {
    id: 'GN',
    label: 'Guinea',
    dialCode: '+224',
  },
  {
    id: 'GW',
    label: 'Guinea-Bissau',
    dialCode: '+245',
  },
  {
    id: 'GY',
    label: 'Guyana',
    dialCode: '+592',
  },
  {
    id: 'HT',
    label: 'Haiti',
    dialCode: '+509',
  },
  {
    id: 'HM',
    label: 'Islas Heard y McDonald',
    dialCode: '+',
  },
  {
    id: 'VA',
    label: 'Santa Sede',
    dialCode: '+379',
  },
  {
    id: 'HN',
    label: 'Honduras',
    dialCode: '+504',
  },
  {
    id: 'HK',
    label: 'Hong Kong',
    dialCode: '+852',
  },
  {
    id: 'HU',
    label: 'Hungría',
    dialCode: '+36',
  },
  {
    id: 'IS',
    label: 'Islandia',
    dialCode: '+354',
  },
  {
    id: 'IN',
    label: 'India',
    dialCode: '+91',
  },
  {
    id: 'ID',
    label: 'Indonesia',
    dialCode: '+62',
  },
  {
    id: 'CI',
    label: 'Costa de Marfil',
    dialCode: '+225',
  },
  {
    id: 'IR',
    label: 'Iran',
    dialCode: '+98',
  },
  {
    id: 'IQ',
    label: 'Irak',
    dialCode: '+964',
  },
  {
    id: 'IE',
    label: 'Irlanda',
    dialCode: '+353',
  },
  {
    id: 'IM',
    label: 'Isla de Man',
    dialCode: '+44',
  },
  {
    id: 'IL',
    label: 'Israel',
    dialCode: '+972',
  },
  {
    id: 'IT',
    label: 'Italia',
    dialCode: '+39',
  },
  {
    id: 'JM',
    label: 'Jamaica',
    dialCode: '+1876',
  },
  {
    id: 'JP',
    label: 'Japón',
    dialCode: '+81',
  },
  {
    id: 'JE',
    label: 'Jersey',
    dialCode: '+44',
  },
  {
    id: 'JO',
    label: 'Jordania',
    dialCode: '+962',
  },
  {
    id: 'KZ',
    label: 'Kazajistán',
    dialCode: '+76',
  },
  {
    id: 'KE',
    label: 'Kenia',
    dialCode: '+254',
  },
  {
    id: 'KI',
    label: 'Kiribati',
    dialCode: '+686',
  },
  {
    id: 'KW',
    label: 'Kuwait',
    dialCode: '+965',
  },
  {
    id: 'KG',
    label: 'Kirguizistán',
    dialCode: '+996',
  },
  {
    id: 'LA',
    label: 'Laos',
    dialCode: '+856',
  },
  {
    id: 'LV',
    label: 'Letonia',
    dialCode: '+371',
  },
  {
    id: 'LB',
    label: 'Líbano',
    dialCode: '+961',
  },
  {
    id: 'LS',
    label: 'Lesotho',
    dialCode: '+266',
  },
  {
    id: 'LR',
    label: 'Liberia',
    dialCode: '+231',
  },
  {
    id: 'LY',
    label: 'Libia',
    dialCode: '+218',
  },
  {
    id: 'LI',
    label: 'Liechtenstein',
    dialCode: '+423',
  },
  {
    id: 'LT',
    label: 'Lituania',
    dialCode: '+370',
  },
  {
    id: 'LU',
    label: 'Luxemburgo',
    dialCode: '+352',
  },
  {
    id: 'MO',
    label: 'Macao',
    dialCode: '+853',
  },
  {
    id: 'MK',
    label: 'Macedonia',
    dialCode: '+389',
  },
  {
    id: 'MG',
    label: 'Madagascar',
    dialCode: '+261',
  },
  {
    id: 'MW',
    label: 'Malawi',
    dialCode: '+265',
  },
  {
    id: 'MY',
    label: 'Malasia',
    dialCode: '+60',
  },
  {
    id: 'MV',
    label: 'Maldivas',
    dialCode: '+960',
  },
  {
    id: 'ML',
    label: 'Mali',
    dialCode: '+223',
  },
  {
    id: 'MT',
    label: 'Malta',
    dialCode: '+356',
  },
  {
    id: 'MH',
    label: 'Islas Marshall',
    dialCode: '+692',
  },
  {
    id: 'MQ',
    label: 'Martinica',
    dialCode: '+596',
  },
  {
    id: 'MR',
    label: 'Mauritania',
    dialCode: '+222',
  },
  {
    id: 'MU',
    label: 'Mauricio',
    dialCode: '+230',
  },
  {
    id: 'YT',
    label: 'Mayotte',
    dialCode: '+262',
  },
  {
    id: 'MX',
    label: 'México',
    dialCode: '+52',
  },
  {
    id: 'FM',
    label: 'Micronesia',
    dialCode: '+691',
  },
  {
    id: 'MD',
    label: 'Moldavia',
    dialCode: '+373',
  },
  {
    id: 'MC',
    label: 'Mónaco',
    dialCode: '+377',
  },
  {
    id: 'MN',
    label: 'Mongolia',
    dialCode: '+976',
  },
  {
    id: 'ME',
    label: 'Montenegro',
    dialCode: '+382',
  },
  {
    id: 'MS',
    label: 'Montserrat',
    dialCode: '+1664',
  },
  {
    id: 'MA',
    label: 'Marruecos',
    dialCode: '+212',
  },
  {
    id: 'MZ',
    label: 'Mozambique',
    dialCode: '+258',
  },
  {
    id: 'MM',
    label: 'Myanmar',
    dialCode: '+95',
  },
  {
    id: 'NA',
    label: 'Namibia',
    dialCode: '+264',
  },
  {
    id: 'NR',
    label: 'Nauru',
    dialCode: '+674',
  },
  {
    id: 'NP',
    label: 'Nepal',
    dialCode: '+977',
  },
  {
    id: 'NL',
    label: 'Países Bajos',
    dialCode: '+31',
  },
  {
    id: 'NC',
    label: 'Nueva Caledonia',
    dialCode: '+687',
  },
  {
    id: 'NZ',
    label: 'Nueva Zelanda',
    dialCode: '+64',
  },
  {
    id: 'NI',
    label: 'Nicaragua',
    dialCode: '+505',
  },
  {
    id: 'NE',
    label: 'Niger',
    dialCode: '+227',
  },
  {
    id: 'NG',
    label: 'Nigeria',
    dialCode: '+234',
  },
  {
    id: 'NU',
    label: 'Niue',
    dialCode: '+683',
  },
  {
    id: 'NF',
    label: 'Isla de Norfolk',
    dialCode: '+672',
  },
  {
    id: 'KP',
    label: 'Corea del Norte',
    dialCode: '+850',
  },
  {
    id: 'MP',
    label: 'Islas Marianas del Norte',
    dialCode: '+1670',
  },
  {
    id: 'NO',
    label: 'Noruega',
    dialCode: '+47',
  },
  {
    id: 'OM',
    label: 'Omán',
    dialCode: '+968',
  },
  {
    id: 'PK',
    label: 'Pakist√°n',
    dialCode: '+92',
  },
  {
    id: 'PW',
    label: 'Palau',
    dialCode: '+680',
  },
  {
    id: 'PS',
    label: 'Palestina',
    dialCode: '+970',
  },
  {
    id: 'PA',
    label: 'Panam√°',
    dialCode: '+507',
  },
  {
    id: 'PG',
    label: 'Papúa Nueva Guinea',
    dialCode: '+675',
  },
  {
    id: 'PY',
    label: 'Paraguay',
    dialCode: '+595',
  },
  {
    id: 'PE',
    label: 'Perú',
    dialCode: '+51',
  },
  {
    id: 'PH',
    label: 'Filipinas',
    dialCode: '+63',
  },
  {
    id: 'PN',
    label: 'Islas Pitcairn',
    dialCode: '+64',
  },
  {
    id: 'PL',
    label: 'Polonia',
    dialCode: '+48',
  },
  {
    id: 'PT',
    label: 'Portugal',
    dialCode: '+351',
  },
  {
    id: 'PR',
    label: 'Puerto Rico',
    dialCode: '+1787',
  },
  {
    id: 'QA',
    label: 'Catar',
    dialCode: '+974',
  },
  {
    id: 'XK',
    label: 'Kosovo',
    dialCode: '+383',
  },
  {
    id: 'RE',
    label: 'Reunión',
    dialCode: '+262',
  },
  {
    id: 'RO',
    label: 'Rumania',
    dialCode: '+40',
  },
  {
    id: 'RU',
    label: 'Rusia',
    dialCode: '+7',
  },
  {
    id: 'RW',
    label: 'Ruanda',
    dialCode: '+250',
  },
  {
    id: 'BL',
    label: 'San Bartolomé',
    dialCode: '+590',
  },
  {
    id: 'SH',
    label: 'Santa Helena',
    dialCode: '+290',
  },
  {
    id: 'KN',
    label: 'San Cristóbal y Nieves',
    dialCode: '+1869',
  },
  {
    id: 'LC',
    label: 'Santa Lucía',
    dialCode: '+1758',
  },
  {
    id: 'MF',
    label: 'Saint Martin',
    dialCode: '+590',
  },
  {
    id: 'PM',
    label: 'San Pedro y Miquelón',
    dialCode: '+508',
  },
  {
    id: 'VC',
    label: 'San Vicente y Granadinas',
    dialCode: '+1784',
  },
  {
    id: 'WS',
    label: 'Samoa',
    dialCode: '+685',
  },
  {
    id: 'SM',
    label: 'San Marino',
    dialCode: '+378',
  },
  {
    id: 'ST',
    label: 'Santo Tomé y Príncipe',
    dialCode: '+239',
  },
  {
    id: 'SA',
    label: 'Arabia Saudí',
    dialCode: '+966',
  },
  {
    id: 'SN',
    label: 'Senegal',
    dialCode: '+221',
  },
  {
    id: 'RS',
    label: 'Serbia',
    dialCode: '+381',
  },
  {
    id: 'SC',
    label: 'Seychelles',
    dialCode: '+248',
  },
  {
    id: 'SL',
    label: 'Sierra Leone',
    dialCode: '+232',
  },
  {
    id: 'SG',
    label: 'Singapur',
    dialCode: '+65',
  },
  {
    id: 'SX',
    label: 'San Martín',
    dialCode: '+1721',
  },
  {
    id: 'SK',
    label: 'República Eslovaca',
    dialCode: '+421',
  },
  {
    id: 'SI',
    label: 'Eslovenia',
    dialCode: '+386',
  },
  {
    id: 'SB',
    label: 'Islas Salomón',
    dialCode: '+677',
  },
  {
    id: 'SO',
    label: 'Somalia',
    dialCode: '+252',
  },
  {
    id: 'ZA',
    label: 'República de Sudáfrica',
    dialCode: '+27',
  },
  {
    id: 'GS',
    label: 'Islas Georgias del Sur y Sandwich del Sur',
    dialCode: '+500',
  },
  {
    id: 'KR',
    label: 'Corea del Sur',
    dialCode: '+82',
  },
  {
    id: 'SS',
    label: 'Sudán del Sur',
    dialCode: '+211',
  },
  {
    id: 'ES',
    label: 'España',
    dialCode: '+34',
  },
  {
    id: 'LK',
    label: 'Sri Lanka',
    dialCode: '+94',
  },
  {
    id: 'SD',
    label: 'Sudan',
    dialCode: '+249',
  },
  {
    id: 'SR',
    label: 'Surinam',
    dialCode: '+597',
  },
  {
    id: 'SJ',
    label: 'Islas Svalbard y Jan Mayen',
    dialCode: '+4779',
  },
  {
    id: 'SZ',
    label: 'Suazilandia',
    dialCode: '+268',
  },
  {
    id: 'SE',
    label: 'Suecia',
    dialCode: '+46',
  },
  {
    id: 'CH',
    label: 'Suiza',
    dialCode: '+41',
  },
  {
    id: 'SY',
    label: 'Siria',
    dialCode: '+963',
  },
  {
    id: 'TW',
    label: 'Taiwán',
    dialCode: '+886',
  },
  {
    id: 'TJ',
    label: 'Tayikistán',
    dialCode: '+992',
  },
  {
    id: 'TZ',
    label: 'Tanzania',
    dialCode: '+255',
  },
  {
    id: 'TH',
    label: 'Tailandia',
    dialCode: '+66',
  },
  {
    id: 'TL',
    label: 'Timor Oriental',
    dialCode: '+670',
  },
  {
    id: 'TG',
    label: 'Togo',
    dialCode: '+228',
  },
  {
    id: 'TK',
    label: 'Islas Tokelau',
    dialCode: '+690',
  },
  {
    id: 'TO',
    label: 'Tonga',
    dialCode: '+676',
  },
  {
    id: 'TT',
    label: 'Trinidad y Tobago',
    dialCode: '+1868',
  },
  {
    id: 'TN',
    label: 'Túnez',
    dialCode: '+216',
  },
  {
    id: 'TR',
    label: 'Turquía',
    dialCode: '+90',
  },
  {
    id: 'TM',
    label: 'Turkmenistán',
    dialCode: '+993',
  },
  {
    id: 'TC',
    label: 'Islas Turks y Caicos',
    dialCode: '+1649',
  },
  {
    id: 'TV',
    label: 'Tuvalu',
    dialCode: '+688',
  },
  {
    id: 'UG',
    label: 'Uganda',
    dialCode: '+256',
  },
  {
    id: 'UA',
    label: 'Ucrania',
    dialCode: '+380',
  },
  {
    id: 'AE',
    label: 'Emiratos Arabes Unidos',
    dialCode: '+971',
  },
  {
    id: 'GB',
    label: 'Reino Unido',
    dialCode: '+44',
  },
  {
    id: 'US',
    label: 'Estados Unidos',
    dialCode: '+1',
  },
  {
    id: 'UY',
    label: 'Uruguay',
    dialCode: '+598',
  },
  {
    id: 'UZ',
    label: 'Uzbekistán',
    dialCode: '+998',
  },
  {
    id: 'VU',
    label: 'Vanuatu',
    dialCode: '+678',
  },
  {
    id: 'VE',
    label: 'Venezuela',
    dialCode: '+58',
  },
  {
    id: 'VN',
    label: 'Vietnam',
    dialCode: '+84',
  },
  {
    id: 'WF',
    label: 'Wallis y Futuna',
    dialCode: '+681',
  },
  {
    id: 'EH',
    label: 'Sahara Occidental',
    dialCode: '+212',
  },
  {
    id: 'YE',
    label: 'Yemen',
    dialCode: '+967',
  },
  {
    id: 'ZM',
    label: 'Zambia',
    dialCode: '+260',
  },
  {
    id: 'ZW',
    label: 'Zimbabue',
    dialCode: '+263',
  },
]
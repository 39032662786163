import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Policy } from "src/classes/Policy";
import { Endorse } from "src/classes/policy/Endorse";
import { IPolicyStateReducer } from "src/store/states/modules-state/IPolicyStateReducer";


/**
 *
 */
export namespace PolicySlice {
  /**
    * 
    */
  const initialState: IPolicyStateReducer = {
    policyList: undefined,
    endorsements: undefined,
    pictureToken: undefined,
    selectedPolicy: undefined

  };


  /**
   * 
   * @param state 
   * @param action 
   */
  const clear: CaseReducer<IPolicyStateReducer> = (state) => {
    state.policyList = undefined;
    state.endorsements = undefined;
    state.pictureToken = undefined;
    state.selectedPolicy = undefined;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const policyList: CaseReducer<IPolicyStateReducer, PayloadAction<Policy[]>> = (state, action) => {
    state.policyList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const endorsmentsList: CaseReducer<IPolicyStateReducer, PayloadAction<Endorse[] | undefined>> = (state, action) => {
    state.endorsements = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const pictureToken: CaseReducer<IPolicyStateReducer, PayloadAction<string | undefined>> = (state, action) => {
    state.pictureToken = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const selectedPolicy: CaseReducer<IPolicyStateReducer, PayloadAction<Policy | undefined>> = (state, action) => {
    state.selectedPolicy = action.payload;
  }

  const reducers = {
    clear,
    policyList,
    endorsmentsList,
    pictureToken,
    selectedPolicy
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'policy',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { IVehicleDataStateReducer } from "src/store/states/modules-state/IVehicleDataStateReducer";

/**
 *
 */
export namespace PolicyVehicleDataSlice {

  /**
   * 
   */
  const initialState: IVehicleDataStateReducer = {
    storedVehicle: undefined,
  };

  /**
   * 
   * @param state 
   * @param action 
   */
   const clear: CaseReducer<IVehicleDataStateReducer> = (state) => {
    state.storedVehicle = undefined;
  }
  /**
   * 
   * @param state 
   * @param action 
   */
   const vehicleStored: CaseReducer<IVehicleDataStateReducer, PayloadAction<Vehicle | undefined>> = (state, action) => {
    state.storedVehicle = action.payload;
  }

  const reducers = {
    clear,
    vehicleStored,
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'vehicleData',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
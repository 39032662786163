import { AxiosPromise } from "axios"
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IEndorsementsData } from "../interfaces/IEndorsementsData";

export namespace PolicyEndorsementsService {

  /**
   * 
   * @param policyNumber 
   * @returns 
   */
  export const policyEndorsementsService = (policyId: string): AxiosPromise<IEndorsementsData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_ENDORSEMENTS(policyId));
  }

}
export class RegimeUse {

  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };

  /**
   *
   */
  private _code: string;
  set code(_code: string) { this._code = _code };
  get code(): string { return this._code };

  /**
   *
   */
  private _name: string;
  set name(_name: string ) { this._name = _name };
  get name(): string  { return this._name };


  constructor(_id: string, _code: string, _name: string) {
    this._id = _id;
    this._code = _code;
    this._name = _name; 
  }
}
import { Person  } from "src/classes/Person";
import { CoreUserDataAdapter } from "../adapters/user-data.adapter";
import { CoreUserIdentityDataAdapter } from "../adapters/user-identity-data.adapter";
import { IIdentityStatus } from "../interfaces/user/IIdentityStatus";
import { CoreUserInfoService } from "../services/user-info.service"

/**
 * 
 */
export namespace CoreUserInfoFeature {

  /**
   * 
   */
  export const getUserInfoFeature = async (): Promise<Person | undefined> => {
    try {
      const { data } = await CoreUserInfoService.getUserInfoService();
      let user: Person = CoreUserDataAdapter.userDataAdapter(data);
      user.profile = CoreUserDataAdapter.profileDataAdapter(data);
			const identityStatus = await getUserIdentityStatusFeature();
			user.profile.identityStatus = identityStatus.status;

      return user;
    } catch (e) {
			console.error(e);
      //TODO not found;
    }
  }

	/**
   * 
   */
	export const getUserIdentityStatusFeature = async (): Promise<IIdentityStatus> => {
		try {
			const { data } = await CoreUserInfoService.getIdentityStatusService();
			let status = CoreUserIdentityDataAdapter.userIdentityDataAdapter(data);
			return status;
		} catch (e) {
			throw(e);
		}
	}
}
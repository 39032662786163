import PersonManagement from "src/libs/PersonManagement/ui/template/PersonManagement";
import { Button, ButtonBack } from "src/components";
import { ReturnValidactionFacade } from 'src/modules/policy-creation/controller/facades/return-validation.facade';
import { PolicyDriverFacade } from "src/modules/policy-creation/controller/facades/policy-driver.facade";
import { POLICY_CREATION_PATH } from "src/routes/paths/policy-creation.paths";
import './PolicyCreationDriver.scss';


/**
 *
 */
const PolicyCreationDriverPage = (): JSX.Element => {

  const { onReturn, steps } = ReturnValidactionFacade.useReturnValidation();
  const { currentDriver, isCreatingDriver, setDriver, createDriver, storedDriver } = PolicyDriverFacade.usePolicyDriver();


  return (
    <div className="policyCreationStepWrapper flex-grow-100 flex column space-between">

			<ButtonBack button text
				to={''}
				onClick={() => onReturn(steps.user, POLICY_CREATION_PATH.POLICY_OWNER)}
			/>

      <section className="content-section">
        <header>
          <h1>
            Datos personales
          </h1>
          <p>
            Cu&eacute;ntanos sobre la persona que estar&aacute; utilizando el veh&iacute;culo.
          </p>
        </header>

        <div className="policy-step-content">
          <PersonManagement onComplete={setDriver} storedPerson={storedDriver} />
        </div>
      </section>

      <section className="plan-management-bottom-section flex align-center">

        <div className="plan-button width-100 flex space-evenly">
          <Button type="button"
            loading={isCreatingDriver}
            onClick={() => createDriver()}
            disabled={!currentDriver || isCreatingDriver}
          >
            Siguiente
          </Button>
        </div>

      </section>
    </div>
  );
};

export default PolicyCreationDriverPage;
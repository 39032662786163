import { Policy } from 'src/classes/Policy';
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { Button, Card } from "src/components";
import { Util } from 'src/utils/Util';
import './PolicyOnboarding.scss';

/**
 *
 */
interface IPolicyOnboarding {
  /** */
  pendingPolicy: Policy;
  /** */
  proceed: (vehicle: Vehicle) => void;
  /** */
  onClose: () => void;
}

/**
 *
 */
const PolicyOnboarding = (props: IPolicyOnboarding): JSX.Element => {
  /** */
  const { pendingPolicy, proceed, onClose } = props;

  return (
    <div className="policy-onboarding-wrapper flex column justify-center align-center text-center">
      <h1>¡Bienvenido a Crabi!</h1>

      <p>Continúa con tu compra para el vehículo</p>
      {
        (pendingPolicy.vehicle && pendingPolicy.vehicle.brand!.name.length > 0) &&
				<>				
					<Card img={Util.ASSET.POLICY_VEHICLE_DATA} imageSize={'small'} disabled>
						<ul className='policy-onboarding-vehicle-info'>
							<li>
								<b>Marca: </b> {pendingPolicy && pendingPolicy.vehicle.brand?.name}
							</li>
							<li>
								<b>Modelo: </b> {pendingPolicy && pendingPolicy.vehicle.model?.name}
							</li>
							<li>
								<b>Año: </b> {pendingPolicy && pendingPolicy.vehicle.year?.value}
							</li>
						</ul>
					</Card>

					<div className='onboarding-image' style={{ backgroundImage: `url(${Util.ASSET.EMPTY_POLICY_STATUS_LIST})` }}></div>

					<div className='buttons-wrapper'>
						<Button onClick={onClose} fill='outline'>Cancelar</Button>
						<Button onClick={() => proceed(pendingPolicy.vehicle!)}>Contratar seguro</Button>
					</div>
				</>
      }

    </div>
  );
};

export default PolicyOnboarding;
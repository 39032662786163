import { Modal, Breadcrumb } from "src/components";
import PolicyOnboarding from '../components/PolicyOnboarding/PolicyOnboarding';
import { Outlet } from 'react-router';
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { PolicyOnboardingFacade } from '../../controller/facades/policy-onboarding.facade';
import { PolicyFacade } from "../../controller/facades/policy.facade";

import './Policy.scss';

/**
 *
 */
const PolicyPage = (): JSX.Element => {
  const { policyNumber, currentPolicyStatus } = PolicyFacade.usePolicy();
  const { showOnboarding, setShowOnboarding, policyList, navigateToVehicleCreation } = PolicyOnboardingFacade.usePolicyOnboardingFacade();

  return (
    <div id="policy-wrapper" className="height-100 flex column space-between border-box">

      <Breadcrumb 
        root={{title: 'Tus Pólizas', path: `${POLICY_PATH.STATUS}${currentPolicyStatus}`}}
        trails={
          [
            {title: `Póliza ${policyNumber}`, path: `${POLICY_PATH.DETAILS}/${policyNumber}`},
            {title: `Administrar Póliza ${policyNumber}`, path: `${POLICY_PATH.MANAGEMENT}/${policyNumber}`},
            {title: 'Método de Pago', previousIndexPath: 1, path: `${POLICY_PATH.PAYMENT_METHOD}/${policyNumber}`},
            {title: 'Historial de Pago', previousIndexPath: 1, path: `${POLICY_PATH.PAYMENT_HISTORY}/${policyNumber}`},
            {title: 'Historial de Endosos', previousIndexPath: 1, path: `${POLICY_PATH.ENDORSEMENTS_HISTORY}/${policyNumber}`},
            {title: 'Contratar nueva Póliza', path: `${POLICY_PATH.OWNER}`},
            {title: 'Cancelar Póliza', previousIndexPath: 1, path: `${POLICY_PATH.CANCELLATION}/${policyNumber}`},
            {title: 'Fotos del Vehículo', path: `${POLICY_PATH.PICTURES}/${policyNumber}`},
          ]
        }
      />

      <Outlet></Outlet>

      <Modal show={showOnboarding} position={'center'} backgroundDismiss={false}>
				{
					policyList && policyList.length === 1 &&
        	<PolicyOnboarding pendingPolicy={policyList[0]} proceed={navigateToVehicleCreation} onClose={() => setShowOnboarding(false)}></PolicyOnboarding>
				}
      </Modal>
    </div>
  );
};

export default PolicyPage;
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { CoreSelector } from "src/core/selectors/core.selector";
import { AlertFactory } from "src/factory/alert.factory";
import { DASHBOARD_PATH } from "src/routes/paths/dashboard.paths";
import { POLICY_RENEWAL_PATH } from "src/routes/paths/policy-renewal.paths";
import { PolicyRenewalAction } from "../actions/policy-renewal.action";
import { PolicyRenewalSaga } from "../sagas/policy-renewal.saga";
import { PolicyRenewalSelector } from "../selectors/policy-renewal.selector";
import { PolicyRenewalSlice } from "../slices/policy-renewal.slice";

/**
 *
 */
export namespace PolicyRenewalFacade {

  /**
   * 
   * @returns 
   */
  export const usePolicyRenewalActors = () => {
    const user = useSelector(CoreSelector.currentUser);
    const currentPolicy = useSelector(PolicyRenewalSelector.currentPolicy);
    const plan = useSelector(PolicyRenewalSelector.plan);

    return { currentPolicy, user, plan }
  }

  /**
   * 
   */
  export const usePolicyRenewal = () => {
    useInjectReducer({ key: 'policyRenewal', reducer: PolicyRenewalSlice.reducer });
    useInjectSaga({ key: 'policyRenewal', saga: PolicyRenewalSaga.policyRenewalSaga });
    const invalidPolicy = useSelector(PolicyRenewalSelector.invalidPolicy);
    const [validatingPolicy, setValidatingPolicy] = useState<boolean>(true);
    const [isLastStep, setIsLastStep] = useState<boolean>(false);
    const { currentPolicy, plan } = usePolicyRenewalActors();
    const { policyNumber } = useParams<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    /**
     * 
     */
    const invalidPolicyNumber = useCallback(
      (message: string) => {
        navigate(DASHBOARD_PATH.DASHBOARD, { replace: true });
        AlertFactory.errorAlert(message);
      }, [navigate]
    );

    /**
     * 
     */
    const validatePolicyRenewal = useCallback(
      () => {
        setValidatingPolicy(false);
        if (location.pathname === `${POLICY_RENEWAL_PATH.POLICY_RENEWAL}/${policyNumber}`) {
          if (currentPolicy.allowRenewal) {
            navigate(POLICY_RENEWAL_PATH.POLICY_RENEWAL_PLAN(policyNumber!), { replace: true });
          } else {
            invalidPolicyNumber('La póliza no está disponible para ser renovada.');
          }
        }
      }, [currentPolicy, invalidPolicyNumber, navigate, setValidatingPolicy, policyNumber, location]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (invalidPolicy) {
          invalidPolicyNumber('Número de póliza inválido.');
        }
      }, [invalidPolicy, invalidPolicyNumber]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (currentPolicy) {
          validatePolicyRenewal();
        }
      }, [currentPolicy, validatePolicyRenewal]
    );

    useEffect(
      () => {
        if (location.pathname.includes(POLICY_RENEWAL_PATH.POLICY_RENEWAL_COMPLETION(policyNumber!)) && plan) {
          setIsLastStep(true);
        } else if (isLastStep && location.pathname !== POLICY_RENEWAL_PATH.POLICY_RENEWAL_COMPLETION(policyNumber!)) {
          invalidPolicyNumber('La póliza ya ha sido pagada.');
        } else {
          setIsLastStep(false);
        }
      }, [location, policyNumber, isLastStep, invalidPolicyNumber, plan]
    )

    useEffect(
      () => {
        return () => { dispatch(PolicyRenewalAction.clearRenewal()); }
      }, [dispatch]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (!policyNumber) {
          invalidPolicyNumber('Número de póliza inválido.');
        } else if (policyNumber && !currentPolicy) {
          dispatch(PolicyRenewalAction.policyByNumber(policyNumber!));
        }
      }, [policyNumber, dispatch, invalidPolicyNumber, currentPolicy],
    );



    return { validatingPolicy, isLastStep }
  }

  /**
   * 
   */
  export const useRenewalCompletion = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const { plan } = PolicyRenewalFacade.usePolicyRenewalActors();
    const { policyNumber } = useParams<string>();

    useEffect(
      () => {
        if (!plan) {
          navigate(`${POLICY_RENEWAL_PATH.POLICY_RENEWAL}/${policyNumber}`, { replace: true });
        }
      }, [plan, navigate, policyNumber]
    )

    /***
     * 
     */
    const finishPolicyRenewal = (): void => {
      navigate(DASHBOARD_PATH.DASHBOARD, { replace: true });
    }

    return { finishPolicyRenewal, query }
  }
}
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects";
import { Policy } from "src/classes/Policy";
import { Endorse } from "src/classes/policy/Endorse";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { PolicyAction } from "../actions/policy.action";
import { PolicyEndorsementsHistoryFeature } from "../feature/policy-endorsements-history.feature";
import { PolicyPaymentFeature } from "../feature/policy-payment.feature";
import { PolicyPictureFeature } from "../feature/policy-picture.feature";
import { PolicySelector } from "../selectors/policy.selector";
import { PolicySlice } from "../slices/policy.slice";

/**
 *
 */
export namespace PolicySaga {

  type Endosorsements = SagaReturnType<typeof PolicyEndorsementsHistoryFeature.endorsementsHistoryFeature>;
  type PolicyList = SagaReturnType<typeof CorePolicyFeature.policyListFeature>;
  type PolicyPictureList = SagaReturnType<typeof CorePolicyFeature.policyActiveHasPicturesFeature>;
  type PictureToken = SagaReturnType<typeof PolicyPictureFeature.policyPicturesTokenFeature>;
  type PaymentMethod = SagaReturnType<typeof PolicyPaymentFeature.currentPaymentMethodFeature>;
  type PaymentHistory = SagaReturnType<typeof PolicyPaymentFeature.paymentHistoryFeature>;

  /**
   * 
   */
  function* getPolicyList() {
    let list: PolicyList;
    try {
      list = yield call(CorePolicyFeature.policyListFeature);
      const pictureList: PolicyPictureList = yield call(CorePolicyFeature.policyActiveHasPicturesFeature, list!);
      yield put(PolicySlice.actions.policyList(pictureList as Policy[]));

    } catch (e) {
      if (list) {
        yield put(PolicySlice.actions.policyList(list as Policy[]));
      }
    }
  }

  function* getPolicyEndorsementsByPolicyId(action: PayloadAction<string>) {
    try {
      const list: Endosorsements = yield call(PolicyEndorsementsHistoryFeature.endorsementsHistoryFeature, action.payload);
      yield put(PolicySlice.actions.endorsmentsList(list as Endorse[]));
    } catch (e) {

    }
  }

  function* clear() {
    yield put(PolicySlice.actions.clear());
  }


  function* picturetoken() {
    try {
      const token: PictureToken = yield call(PolicyPictureFeature.policyPicturesTokenFeature);
      yield put(PolicySlice.actions.pictureToken(token as string));
    } catch (e) {

    }
  }

  function* clearPictureToken() {
    try {
      yield put(PolicySlice.actions.pictureToken(undefined));
    } catch (e) {

    }
  }

  function* clearManagement() {
    try {
      yield put(PolicySlice.actions.endorsmentsList(undefined));
      yield put(PolicySlice.actions.selectedPolicy(undefined));
    } catch (e) {

    }
  }

  /**
   * 
   * @param action Payload: Policy number
   */
  function* selectPolicy(action: PayloadAction<string>) {
    const list: Policy[] = yield select(PolicySelector.policyList);
    const policy = list?.find(p => p.policyNumber === action.payload);
    if (policy) {
      if ((policy.isActive() || policy.toBegin()) && policy.isPlanPaymentMonthly()) {
        const method: PaymentMethod = yield call(PolicyPaymentFeature.currentPaymentMethodFeature, policy.id);
        policy.paymentMethod = method;
        
        const paymentHistory: PaymentHistory = yield call(PolicyPaymentFeature.paymentHistoryFeature, policy.policyNumber);
        if (paymentHistory) {
          policy.paymentHistory = paymentHistory;
        }
      }

      yield put(PolicySlice.actions.selectedPolicy(policy));
    }
  }

  /**
   * 
   * @param action Payload: Policy id
   */
  function* updateSelectedPolicy(action: PayloadAction<string>) {
    const list: Policy[] = yield select(PolicySelector.policyList);
    const policy = list?.find(p => p.id === action.payload);
    if (policy) {
      yield put(PolicySlice.actions.selectedPolicy(undefined));
      const method: PaymentMethod = yield call(PolicyPaymentFeature.currentPaymentMethodFeature, policy.id);
      policy.paymentMethod = method;
      yield put(PolicySlice.actions.selectedPolicy(policy));
    }
  }

  /**
   * 
   * @param action Payload: Selected policy
   */
  function* changeToCancelStatus(action: PayloadAction<Policy>) {
    action.payload.status = {canceled: true}
    yield put(PolicySlice.actions.selectedPolicy(action.payload));
  }

  export function* policySaga() {
    yield takeLatest(PolicyAction.policyList.type, getPolicyList);
    yield takeLatest(PolicyAction.endorsements.type, getPolicyEndorsementsByPolicyId);
    yield takeLatest(PolicyAction.clear.type, clear);
    yield takeLatest(PolicyAction.picturetoken.type, picturetoken);
    yield takeLatest(PolicyAction.clearPictureToken.type, clearPictureToken);
    yield takeLatest(PolicyAction.clearManagement.type, clearManagement);
    yield takeLatest(PolicyAction.selectPolicy.type, selectPolicy);
    yield takeLatest(PolicyAction.updateSelectedPolicy.type, updateSelectedPolicy);
    yield takeLatest(PolicyAction.changeToCancelStatus.type, changeToCancelStatus);
  }
}
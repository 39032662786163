import { ClaimProposal } from "src/classes/ClaimProposal";
import { DamageClaim } from "src/classes/DamageClaim";
import { Phone } from "src/classes/Phone";
import { Profile } from "src/classes/Profile";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { Util } from "src/utils/Util";
import { ICreateDamagePaymentRequest } from "../interfaces/ICreateDamagePaymentRequest";
import { IDamageClaimDetailsResponse } from "../interfaces/IDamageClaimDetailsResponse";
import { IUpdatePersonalDataRequest } from "../interfaces/IUpdatePersonalDataRequest";
import { IUpdateVehicleDataRequest } from "../interfaces/IUpdateVehicleDataRequest";



/**
 * 
 */
export namespace ClaimPaymentsAdapter {

  /**
   * 
   * @param _data
   * @returns 
   */
  export const claimPaymentRequestDataAdapter = (damageClaim: DamageClaim, _policyUID?: string): ICreateDamagePaymentRequest => {
		let currentPolicyUID: string = '';

		if(damageClaim.policyUID)
			currentPolicyUID = damageClaim.policyUID;
		else if(_policyUID)
			currentPolicyUID = _policyUID;

    const data: ICreateDamagePaymentRequest = {
      policy_id: currentPolicyUID.length > 0 ? currentPolicyUID : '',
      policy_number: currentPolicyUID.length > 0 ? '' : damageClaim.policyNumber,
      sinister_number: damageClaim.damageClaimNumber.toLocaleUpperCase()
    }

    return data;
  };

  /** */
  export const updatePersonalDataAdapter = (user: Person): IUpdatePersonalDataRequest => {
    const phoneNumber: string = user.profile?.getPhoneNumber()!.replace('(', '').replace(')', '').replaceAll(' ', '')!;
    const data: IUpdatePersonalDataRequest = {
      names: user.name.trim(),
      first_lastnames: user.lastName.trim(),
      second_lastnames: user.secondLastName.trim(),
      phone: phoneNumber,
      email: user.email
    }

    return data;
  }

  /** */
  export const updateVehicleDataAdapter = (vehicle: Vehicle): IUpdateVehicleDataRequest => {
    const data: IUpdateVehicleDataRequest = {
      model: vehicle.model?.name!,
      brand: vehicle.brand?.name!,
      vehicle_registration: vehicle.plate,
      year: (vehicle.year?.value!).toString(),
      serial_number: vehicle.vin ? vehicle.vin : '',
    }

    return data;
  }

  /** */
  export const getDamageClaimByIdAdapter = (data: IDamageClaimDetailsResponse): DamageClaim => {
    let damageClaim: DamageClaim = new DamageClaim(data.id);
    damageClaim.claimDate = new Date(data.date_claim);
    damageClaim.status = data.status;
    damageClaim.damageClaimNumber = data.sinister_number;
    damageClaim.policyNumber = data.policy_number;
    //damageClaim.deductible = data.deductible;
    //damageClaim.sinister.createdAt = new Date(data.date_claim);
    //damageClaim.sinister.crabiStatement = '-N/A-'; //CHANGE IT

    if (data.requirements) {
      if (data.requirements.bank_statement) {
        data.requirements.bank_statement.files.forEach((_bankStatement) => {
					damageClaim.attachmentAccountStatus.uid = _bankStatement.details.hash;
					damageClaim.attachmentAccountStatus.source = _bankStatement.url;
					damageClaim.attachmentAccountStatus.status = _bankStatement.details.status;
					damageClaim.attachmentAccountStatus.type = _bankStatement.requirement;
					damageClaim.attachmentAccountStatus.contentType = _bankStatement.details.content_type;
        });
      };

      if (data.requirements.vehicle_card) {
        data.requirements.vehicle_card.files.forEach((_vehicleCard) => {
          if (_vehicleCard.details.file_type === Util.KEY.PICTURE_NAME.FRONT) {
						damageClaim.attachmentVehicle[0].uid = _vehicleCard.details.hash;
            damageClaim.attachmentVehicle[0].source = _vehicleCard.url;
            damageClaim.attachmentVehicle[0].status = _vehicleCard.details.status;
          } else if (_vehicleCard.details.file_type === Util.KEY.PICTURE_NAME.LEFT) {
						damageClaim.attachmentVehicle[1].uid = _vehicleCard.details.hash;
            damageClaim.attachmentVehicle[1].source = _vehicleCard.url;
            damageClaim.attachmentVehicle[1].status = _vehicleCard.details.status;
          } else if (_vehicleCard.details.file_type === Util.KEY.PICTURE_NAME.RIGHT) {
						damageClaim.attachmentVehicle[2].uid = _vehicleCard.details.hash;
            damageClaim.attachmentVehicle[2].source = _vehicleCard.url;
            damageClaim.attachmentVehicle[2].status = _vehicleCard.details.status;
          } else if (_vehicleCard.details.file_type === Util.KEY.PICTURE_NAME.BACK) {
						damageClaim.attachmentVehicle[3].uid = _vehicleCard.details.hash;
            damageClaim.attachmentVehicle[3].source = _vehicleCard.url;
            damageClaim.attachmentVehicle[3].status = _vehicleCard.details.status;
          } else if (_vehicleCard.details.file_type === Util.KEY.PICTURE_NAME.SERIAL) {
						damageClaim.attachmentVehicle[4].uid = _vehicleCard.details.hash;
            damageClaim.attachmentVehicle[4].source = _vehicleCard.url;
            damageClaim.attachmentVehicle[4].status = _vehicleCard.details.status;
          }
        });
      };

      if (data.requirements.damage_image) {
        data.requirements.damage_image.files.forEach((_damageImage, index) => {
					if(index <= 5){
						damageClaim.attachmentDamage[index].uid = _damageImage.details.hash;
						damageClaim.attachmentDamage[index].source = _damageImage.url;
						damageClaim.attachmentDamage[index].status = _damageImage.details.status;
					}
        });
      };

			if(data.requirements.circulation_document){
      	data.requirements.circulation_document.files.forEach((_drivingCard) => {
					if(_drivingCard.details.file_type === Util.KEY.PICTURE_NAME.FRONT) {
						damageClaim.attachmentIdentification[0].uid = _drivingCard.details.hash;
						damageClaim.attachmentIdentification[0].source = _drivingCard.url;
						damageClaim.attachmentIdentification[0].status = _drivingCard.details.status;
						damageClaim.attachmentIdentification[0].contentType = _drivingCard.details.content_type;
					}
      	});
      };

			if(data.requirements.invoice_car){
				data.requirements.invoice_car.files.forEach((_carInvoice) => {
					damageClaim.attachmentBill.uid = _carInvoice.details.hash;
					damageClaim.attachmentBill.source = _carInvoice.url;
					damageClaim.attachmentBill.status = _carInvoice.details.status;
					damageClaim.attachmentBill.contentType = _carInvoice.details.content_type;
				});
			};

      if(data.requirements.personal_id){
      	data.requirements.personal_id.files.forEach((_personalID) => {
					if(_personalID.details.file_type === Util.KEY.PICTURE_NAME.FRONT){
						damageClaim.attachmentIdentification[1].uid = _personalID.details.hash;
						damageClaim.attachmentIdentification[1].source = _personalID.url;
						damageClaim.attachmentIdentification[1].status = _personalID.details.status;
						damageClaim.attachmentIdentification[1].contentType = _personalID.details.content_type;
					} else if(_personalID.details.file_type === Util.KEY.PICTURE_NAME.BACK){
						damageClaim.attachmentIdentification[2].uid = _personalID.details.hash;
						damageClaim.attachmentIdentification[2].source = _personalID.url;
						damageClaim.attachmentIdentification[2].status = _personalID.details.status;
						damageClaim.attachmentIdentification[2].contentType = _personalID.details.content_type;
					}
      	});
      };

      if (data.requirements.personal_info) {
				damageClaim.user = new Person(data.requirements.personal_info.properties.email.value)
        damageClaim.user.name = data.requirements.personal_info.properties.names.value;
        damageClaim.user.lastName = data.requirements.personal_info.properties.first_lastnames.value;
        damageClaim.user.secondLastName = data.requirements.personal_info.properties.second_lastnames.value;
				damageClaim.user.profile = new Profile();

				const phone: string = data.requirements.personal_info.properties.phone.value;
				const phoneIndex: number = phone.length - 10;
				const phoneNumber: string = phone.substring(phone.length, phoneIndex);
				const phoneCode: string = phone.slice(0, phoneIndex);
				damageClaim.user.profile.phone = new Phone(phoneNumber);
				damageClaim.user.profile.phone.countryCode = {
					code: `${phoneCode}`
				}
      };

      if (data.requirements.vehicle_info) {
        damageClaim.vehicle.brand = new VehicleBrand();
        damageClaim.vehicle.model = new VehicleModel();
        damageClaim.vehicle.year = new VehicleYear();

        damageClaim.vehicle.brand.name = data.requirements.vehicle_info.properties.brand?.value ? data.requirements.vehicle_info.properties.brand.value : '';
        damageClaim.vehicle.model.name = data.requirements.vehicle_info.properties.model?.value ? data.requirements.vehicle_info.properties.model.value : '';
        damageClaim.vehicle.plate = data.requirements.vehicle_info.properties.vehicle_registration?.value ? data.requirements.vehicle_info.properties.vehicle_registration.value : '';
        damageClaim.vehicle.year.value = data.requirements.vehicle_info.properties.year?.value ? +(data.requirements.vehicle_info.properties.year.value) : 0;
        damageClaim.vehicle.vin = data.requirements.vehicle_info.properties.serial_number?.value ? data.requirements.vehicle_info.properties.serial_number?.value : '';
      };

      if(data.requirements.receipt_payment && data.requirements.receipt_payment.files.length > 0){
      	damageClaim.receiptSource = data.requirements.receipt_payment.files[0].url;
				
      	// claimPayment.claimPaymentReceipt.source = data.requirements.receipt_payment.files[0].url;
      	// claimPayment.claimPaymentReceipt.contentType = data.requirements.receipt_payment.files[0].details.content_type;
      	// claimPayment.claimPaymentReceipt.status = data.requirements.receipt_payment.files[0].details.status;
      	// claimPayment.claimPaymentReceipt.hash = data.requirements.receipt_payment.files[0].details.hash;
      	// claimPayment.claimPaymentReceipt.id = data.requirements.receipt_payment.files[0].details.id;
      	// claimPayment.claimPaymentReceipt.comment = data.requirements.receipt_payment.files[0].details.comment;
      }
    }

    if (data.negotiation) {
      data.negotiation.forEach((_proposalData) => {
        if (_proposalData.status === Util.STATUS_CODE.CLAIM_PROPOSAL_STATUS.NEUTRAL || _proposalData.status === Util.STATUS_CODE.CLAIM_PROPOSAL_STATUS.APPROVED) {
          let _proposal: ClaimProposal = new ClaimProposal();

          _proposal.id = _proposalData.id;
          _proposal.carValue = +_proposalData.value_car;
          _proposal.claimValue = +_proposalData.value_crash;
          _proposal.deductiblePercent = +_proposalData.deductible;
          _proposal.deductibleValue = +_proposalData.value_deductible;
          _proposal.finalValue = +_proposalData.value_to_pay;
          _proposal.status = _proposalData.status;
          _proposal.proposalDate = new Date(_proposalData.created_at);

          damageClaim.proposal = _proposal;
        }
      });
    }

    return damageClaim;
  }

}
export class InvoicePremium {

  private _subTotal: number;
  set subTotal(_subTotal: number) { this._subTotal = _subTotal };
  get subTotal(): number { return this._subTotal };

  private _taxes: number;
  set taxes(_taxes: number) { this._taxes = _taxes };
  get taxes(): number { return this._taxes };

  private _policyNumber: string;
  set policyNumber(_policyNumber: string) { this._policyNumber = _policyNumber };
  get policyNumber(): string { return this._policyNumber };

  private _description: string;
  set description(_description: string) { this._description = _description };
  get description(): string { return this._description };
  

  constructor() {
    this._subTotal = 0;
    this._taxes = 0;
    this._policyNumber = '';
    this._description = '';
  }
}
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { Plan } from "src/classes/plan/Plan";

/**
 *
 */
export namespace PolicyRenewalAction {
  /**
   * 
   */
  export const policyByNumber: ActionCreatorWithPayload<string> = createAction('POLICY_BY_NUMBER');
  /**
   * 
   */
  export const setPlan: ActionCreatorWithPayload<Plan> = createAction('SET_PLAN_RENEWAL');
  /**
   * 
   */
  export const completeReview: ActionCreatorWithoutPayload = createAction('COMPLETE_REVIEW_RENEWAL');
  /**
   * 
   */
  export const removePlan: ActionCreatorWithoutPayload = createAction('REMOVE_RENEWAL_PLAN');
  /**
   * 
   */
  export const clearRenewal: ActionCreatorWithoutPayload = createAction('CLEAR_RENEWAL');
}
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { Util } from "src/utils/Util";
import { POLICY_CREATION_PATH } from "../../../../routes/paths/policy-creation.paths";
import { PolicyCreationAction } from "../actions/policy-creation.action";

export namespace ReturnValidactionFacade {

  /**
   * 
   * @returns 
   */
  export const useReturnValidation = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const steps = {
      user: 0,
      vehicle: 1,
      selection: 2,
      review: 3,
      payment: 4,
    }

    /**
     * 
     */
    const onReturn = (step?: number, path?: string) => {

      switch (step) {
        case steps.vehicle:
          dispatch(PolicyCreationAction.stepBackToDriver());
          break;
        case steps.selection:
        	dispatch(PolicyCreationAction.stepBackToVehicle());
          break;
        case steps.review:
          dispatch(PolicyCreationAction.stepBackToPlan());
          break;
        case steps.payment:
          dispatch(PolicyCreationAction.stepBackToReview());
          break;
      }

      if (path) {
        navigate(path, { replace: true });
      } else {
        const isCurrentUser = localStorage.getItem(Util.KEY.POLICY_CREATION_OWNER);
        if (isCurrentUser) {
          navigate(POLICY_PATH.OWNER, { replace: true });
        } else {
          navigate(POLICY_CREATION_PATH.PERSON_DATA, { replace: true });
        }
      }
    }

    return { onReturn, steps };
  }
}
import classNames from 'classnames';
import { IconFactory } from 'src/factory/icon.factory';
import './ClaimPaymentSidebarIndicator.scss';

/**
 *
 */
interface IClaimPaymentSidebarIndicator {
	/** */
  active: boolean;
	/** */
	icon: JSX.Element;
	/** */
	isDone: boolean;
	/** */
	isMissingInfo: boolean;
	/** */
	hasErrors: boolean;
}

/**
 *
 */
const ClaimPaymentSidebarIndicator = (props: IClaimPaymentSidebarIndicator): JSX.Element => {

	/** */
	const { active, icon, isDone, isMissingInfo, hasErrors } = props;

	/** */
  const indicatorClassNames = classNames({
    'claim-payment-indicator': true,
    'active': active,
		'done': isDone,
		'missingInfo': isMissingInfo,
		'hasErrors': hasErrors
  });

  return (
    <div className={indicatorClassNames}>
			{
				isDone && IconFactory.checkIcon({ fill: '#fff', stroke: '#fff' })
			}
			{
				isMissingInfo && IconFactory.minusWarningIcon({ fill: '#fff', stroke: '#fff' })
			}
			{
				hasErrors && IconFactory.warningIcon({ fill: '#fff', stroke: '#fff' })
			}
			{
				!isDone && !isMissingInfo && !hasErrors && icon
			}
					
			{/* {
				props.isDone ?
					IconFactory.checkIcon({ fill: '#fff', stroke: '#fff' })
				:
					props.icon
			} */}
    </div>
  );
};

export default ClaimPaymentSidebarIndicator;
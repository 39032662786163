import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFacade } from "./claim-payments.facade";

const CURRENT_PATH: string = CLAIM_PAYMENTS_PATH.VEHICLE_PICTURES;

/**
 *
 */
export namespace VehiclePicturesFacade {

	/** */
	export const useVehiclePicturesFacade = () => {
		/** */
		const { currentDamageClaim } = ClaimPaymentsFacade.useDamageClaimActors();
		const [picturesValidation, updatePicturesValidation] = useState(false);
		const navigate = useNavigate();
		const dispatch = useDispatch();

		/** */
		const scrollToTop = () => {
			window.scrollTo(0, 0);
		}

		/**
		 * 
		 * @param direction 
		 */
		const navigationHandler = (direction: 'forwards' | 'backwards') => {
			if(direction === 'forwards')
				navigate(`${CLAIM_PAYMENTS_PATH.ID_DOCUMENTS}?policy-number=${currentDamageClaim?.policyNumber}`);
			else
				navigate(`${CLAIM_PAYMENTS_PATH.VEHICLE_DATA}?policy-number=${currentDamageClaim?.policyNumber}`);
		}

		/**
		 * 
		 */
		const validatePictures = useCallback(() => {
			const vehiclePicturesCondition: boolean = currentDamageClaim?.attachmentVehicle[0].source.length! > 0 && currentDamageClaim?.attachmentVehicle[1].source.length! > 0
				&& currentDamageClaim?.attachmentVehicle[2].source.length! > 0 
				&& currentDamageClaim?.attachmentVehicle[3].source.length! > 0 
				&& currentDamageClaim?.attachmentVehicle[4].source.length! > 0;
			const damagePicturesCondition: boolean = currentDamageClaim?.attachmentDamage[0].source.length! > 0 || currentDamageClaim?.attachmentDamage[1].source.length! > 0
				|| currentDamageClaim?.attachmentDamage[2].source.length! > 0
				|| currentDamageClaim?.attachmentDamage[3].source.length! > 0
				|| currentDamageClaim?.attachmentDamage[4].source.length! > 0
				|| currentDamageClaim?.attachmentDamage[5].source.length! > 0;

			updatePicturesValidation(vehiclePicturesCondition && damagePicturesCondition);
		}, [currentDamageClaim, updatePicturesValidation]);

		useEffect(() => {
			scrollToTop();
			dispatch(ClaimPaymentsAction.updateCurrentPath(CURRENT_PATH));
			validatePictures();
		}, [dispatch, currentDamageClaim, validatePictures]);

		return { currentDamageClaim, picturesValidation, navigationHandler, validatePictures, CURRENT_PATH };
	};

}
import { Util } from "src/utils/Util";

export class PaymentRecord {

  /**
   *
   */
  private _date: Date;
  set date(_date: Date) { this._date = _date };
  get date(): Date { return this._date };

  /**
   *
   */
  private _amount: number;
  set amount(_amount: number) { this._amount = _amount };
  get amount(): number { return this._amount };

  /**
   *
   */
  private _count: number;
  set count(_count: number) { this._count = _count };
  get count(): number { return this._count };

  /**
   *
   */
  private _status: number;
  set status(_status: number) { this._status = _status };
  get status(): number { return this._status };
  /**
   *
   */
  private _invoiceUrl: string;
  set invoiceUrl(_invoiceUrl: string) { this._invoiceUrl = _invoiceUrl };
  get invoiceUrl(): string { return this._invoiceUrl };

  constructor (_date: Date, _amount: number, _count?: number) {
    this._date = _date;
    this._amount = _amount;
    this._count = _count? _count : 0;
    this._status = 0;
    this._invoiceUrl = '';
  }

  /**
   * 
   * @returns 
   */
  public isPaid(): boolean {
    return this.status === Util.STATUS_CODE.POLICY_PAYMENT_RECORD_STATUS.PAID;
  }

  /**
   * 
   * @returns 
   */
  public isPending(): boolean { 
    return this.status !== Util.STATUS_CODE.POLICY_PAYMENT_RECORD_STATUS.PAID && this.status < Util.STATUS_CODE.POLICY_PAYMENT_RECORD_STATUS.ERROR;
  }

  /**
   * 
   * @returns 
   */
  public isPaymentError(): boolean {
    return this.status >= Util.STATUS_CODE.POLICY_PAYMENT_RECORD_STATUS.ERROR;
  }
}
import { AxiosError, AxiosResponse } from "axios";
import { AuthToken } from "src/classes/AuthToken";
import { Person  } from "src/classes/Person";
import { CoreTokenDataAdapter } from "src/core/adapters/token-data.adapter";
import { IAuthTokenData } from "src/core/interfaces/auth/IAuthTokenData";
import { CoreLoginDataAdapter } from "../adapters/login-data.adapter";
import { CoreLoginService } from "../services/login.service";

export namespace CoreLoginFeature {

  /**
   * 
   */
  export const loginFeature = async (user: Person): Promise<AuthToken | undefined> => {

    try {
      let response: AxiosResponse<IAuthTokenData>;
      const loginData = CoreLoginDataAdapter.userLoginDataAdapter(user);
      response = await CoreLoginService.loginService(loginData);

      const authToken: AuthToken = CoreTokenDataAdapter.authTokenDataAdapter(response.data);
      return authToken;
    } catch (e: any | AxiosError) {
      if (e.response) {
        if ((e as AxiosError).response?.status === 403) {
          const error: Error = {
            message: 'El usuario o contraseña son incorrectos.',
            name: '403'
          }
          throw error;
        }
      } else if (!navigator.onLine) {
				const error: Error = {
					message: 'No tienes conexión a internet.',
					name: ''
				}
				throw error;
			} else {
				const error: Error = {
					message: 'Error de conexión con el servidor.',
					name: ''
				}
				throw error;
			}

    }
  }
}

import { Link } from "react-router-dom";
import { Button, Modal, InputLabel } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { FORGOT_PASSWORD_PATH } from "src/routes/paths/forgot-password.paths";
import { Util } from "src/utils/Util";
import { LoginFormFacade } from "../../controller/facades/login-form.facade";
import { LoginFacade } from "../../controller/facades/login.facade";
import LoginEmailValidation from "../atoms/LoginEmailValidation/LoginEmailValidation";

import "./Login.scss"

/**
 * 
 * @returns 
 */
const Login = (): JSX.Element => {

  const { missingEmailValidation, isLoading, initLogin, currentToken, closeEmailValidation } = LoginFacade.useLoginFacade();
  const { register, handleSubmit, formState, watch, formEmail, formPassword } = LoginFormFacade.useLoginFormFacade();

  return (
    <div id="login-wrapper" style={{ backgroundImage: `url(${Util.ASSET.LOGIN_BG})` }}>
      <div className="wrapper-box width-100">
        <form onSubmit={handleSubmit(() => { initLogin(formEmail, formPassword) })}>
          <div className="login-title flex justify-center align-center">
            <h1 className="text-center">
              Hola Crabeler
            </h1>
            <span>
              👋
            </span>
          </div>
          <h2 className="text-center">Recuerda que desde tu cuenta puedes:</h2>
          <ul>
            <li>Cotizar una p&oacute;liza con beneficios exclusivos.</li>
            <li>Consultar y hacer cambios en tu p&oacute;liza.</li>
            <li>Renovar tu seguro sin tr&aacute;mites o papeleo.</li>
          </ul>
          <div className="form-section">
            <InputLabel
              icon={watch().email ? IconFactory.mailIcon() : IconFactory.mailOutlineIcon()}
              defaultValue=""
              type="email"
              placeholder="Ingresa tu correo electrónico"
              errors={!!formState.errors.email || (!!formState.touchedFields.email && formState.errors.email)}
              {...register("email", { required: true, pattern: Util.PATTERN.EMAIL })}
            >
              Correo electr&oacute;nico
            </InputLabel>
          </div>

          <div className="form-section">
            <InputLabel
              icon={!watch().password ? IconFactory.lockOutlineIcon() : IconFactory.lockIcon()}
              defaultValue=""
              type="password"
              placeholder="Ingresa tu contraseña"
              errors={!!formState.errors.password}
              {...register("password", { required: true, minLength: 1 })}
            >
              Contrase&ntilde;a
            </InputLabel>
          </div>
          <div>
            <Link className="underline" to={FORGOT_PASSWORD_PATH.RECOVERY}>¿Olvidaste tu contrase&ntilde;a?</Link>
          </div>

          <div className="button-wrapper text-center">
            <Button type="submit"
              className="border-box"
              loading={isLoading}
              disabled={!formState.isValid || isLoading}>
              Iniciar Sesi&oacute;n
            </Button>
          </div>
        </form>
      </div>
      <Modal show={missingEmailValidation} position="center" backgroundDismiss onClose={closeEmailValidation}>
        <LoginEmailValidation
          isLoading={isLoading}
          email={formEmail}
          token={currentToken}
          onClose={closeEmailValidation}
          onValidate={() => initLogin(formEmail, formPassword)} />
      </Modal>
    </div>
  )
}

export default Login;
import { AxiosResponse } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IGenerateCodeRequest } from "../interfaces/IGenerateCodeRequest";
import { IVerifyCodeData } from "../interfaces/IVerifyCodeData";
import { IVerifyCodeRequest } from "../interfaces/IVerifyCodeRequest";

/**
 *
 */
export namespace OtpService {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const generateCodeService = (request: IGenerateCodeRequest): Promise<AxiosResponse<void>> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.OTP_GENERATE_CODE, request);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const generateUUIDCodeService = (request: IGenerateCodeRequest): Promise<AxiosResponse<void>> => {
		const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;
    return EnvironmentFactory.Http.PublicHttp(baseURL).post(Util.API_PATH.OTP_GENERATE_UUID_CODE, request);
  }
	
  /**
   * 
   * @param data 
   * @returns 
   */
  export const verifyCodeService = (request: IVerifyCodeRequest): Promise<AxiosResponse<IVerifyCodeData>> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.OTP_VERIFY_CODE, request);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const verifyUUIDCodeService = (request: IVerifyCodeRequest): Promise<AxiosResponse<IVerifyCodeData>> => {
		const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;
    return EnvironmentFactory.Http.PublicHttp(baseURL).post(Util.API_PATH.OTP_VERIFY_UUID_CODE, request);
  }
}
import { AxiosError } from "axios";
import { Endorse } from "src/classes/policy/Endorse";
import { PolicyEndorsementsAdapter } from "../adapters/policy-endorsements.adapter";
import { PolicyEndorsementsService } from "../services/policy-endorsements.service";

/**
 *
 */
export namespace PolicyEndorsementsFeature {

  /**
   * 
   * @param policyId 
   * @param endorsements 
   */
  export const applyEndorseFeature = async (policyId: string, otpToken: string, endorsements: Endorse[]): Promise<void | undefined> => {
    try {
      const data = PolicyEndorsementsAdapter.applyEndorseRequestAdapter(endorsements);
      await PolicyEndorsementsService.applyEndorseService(policyId, otpToken, data);
    } catch(e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }
}
import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements, StripeError } from "@stripe/stripe-js";
import { AxiosError, AxiosResponse } from "axios";
import { Plan } from "src/classes/plan/Plan";
import { PlanCheckout } from "src/classes/plan/PlanCheckout";
import { Policy } from "src/classes/Policy";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Util } from "src/utils/Util";
import { CorePolicyDataAdapter } from "../adapters/policy-data.adapter";
import { CorePolicyRequestAdapter } from "../adapters/policy-request.adapter";
import { IPolicyHasPicture } from "../interfaces/policy/IPolicyHasPicture";
import { CorePolicyService } from "../services/policy.service";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";

export namespace CorePolicyFeature {
  /**
   * 
   */
  export const policyListFeature = async (): Promise<Policy[] | undefined> => {
    try {
      const { data } = await CorePolicyService.policyListService();
      const list = CorePolicyDataAdapter.policyListDataAdapter(data);

      return list;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param activePoliciesList 
   */
  export const policyActiveHasPicturesFeature = async (policyList: Policy[]): Promise<Policy[] | undefined> => {
    try {
      const activePolicyList = policyList.filter(p => p.isActive() || p.toBegin());
      let requests: Promise<AxiosResponse<IPolicyHasPicture>>[] = [];

      for (const policy of activePolicyList) {
        requests.push(CorePolicyService.policyHasPicturesService(policy.id));
      }
      const response = await Promise.all(requests);
      response.forEach(
        (value, index) => {
          const currentPolicy = activePolicyList[index];
          if (currentPolicy && currentPolicy.startDate) {
            const allowUploadPictures: boolean = currentPolicy.startDate >= new Date(EnvironmentFactory.ENV.REACT_APP_RELEASE_PICTURES_DATE!);
            activePolicyList[index].hasPictures = value.data.has_photos === false && allowUploadPictures === true && value.data.required === true ? false : true;
          }
        }
      );

      return policyList;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   */
  export const isPoliciesMaxLimitFeature = async (): Promise<boolean | undefined> => {
    try {
      const { data } = await CorePolicyService.policiesMaxLimitService();

      return !data.canRequestNewPolicy;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   */
  export const policyByNumberFeature = async (number: string): Promise<Policy | undefined> => {
    try {
      const { data } = await CorePolicyService.policyListService();
      const policyList = CorePolicyDataAdapter.policyListDataAdapter(data);
      let policy: Policy = new Policy(number);

      policyList.forEach((_policy) => {
        if (_policy.policyNumber === number)
          policy = _policy;
      })

      return policy;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param vehicle 
   * @param plan 
   */
  export const policyRequestFeature = async (vehicle: Vehicle, plan: Plan, driver?: Person): Promise<Policy> => {
    try {
      const requestData = CorePolicyRequestAdapter.policyRequestAdapter(vehicle, plan, driver);
      const { data } = await CorePolicyService.policyRequestService(requestData);
			const policyOnlyWithId = CorePolicyRequestAdapter.policyRequestDataAdapter(data);
      return policyOnlyWithId;
    } catch (e) {
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }

  /**
   * 
   */
  export const policyPaymentFeature = async (checkout: PlanCheckout, lib: { stripe: Stripe, elements: StripeElements }): Promise<Policy | undefined> => {
    try {
			if (checkout.isMonthly) {
				const stripeData = await lib.stripe.createPaymentMethod({type: 'card', card: lib.elements.getElement(CardElement)!, billing_details: { name: checkout.ownerName } });
				const requestData = CorePolicyRequestAdapter.paymentMontlyRequestAdapter(checkout, stripeData?.paymentMethod?.id!);
				const { data } = await CorePolicyService.policyMonthlyPaymentService(requestData);
				let newPolicy = new Policy(data.policy_number);
				return newPolicy;
			} else {
				const requestData = CorePolicyRequestAdapter.policyPaymentRequestDataAdapter(checkout);
				const { data } = await CorePolicyService.policyPaymentService(requestData);
				const confirmation = await lib.stripe.confirmCardPayment(data.secret, { payment_method: { card: lib.elements.getElement(CardElement)!, billing_details: { name: checkout.ownerName } }, setup_future_usage: 'off_session' })
				if (confirmation.error) {
					throw confirmation.error;
				}
				let newPolicy = new Policy(data.policy_number);
				return newPolicy;
			}
    } catch (e) {
      console.error(e);
      let error = new Error();

      if ((e as StripeError).type) {
        const stripeError = e as StripeError;

        if (stripeError.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.DECLINED.CODE) {

          if (stripeError.decline_code === Util.STATUS_CODE.STRIPE_ERROR_CODE.DECLINED.DECLINE_CODE.INSUFFICIENT_FUNDS) {
            error.message = Util.CONSTANT.CARD_ERROR.FUNDS;
          } else {
            error.message = Util.CONSTANT.CARD_ERROR.DECLINE;
          }

        } else if (stripeError.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.EXPIRED) {
          error.message = Util.CONSTANT.CARD_ERROR.EXPIRED;

        } else if (stripeError.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.INCORRECT_CVC) {
          error.message = Util.CONSTANT.CARD_ERROR.CVC;

        } else if (stripeError.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.PROCESSING) {
          error.message = Util.CONSTANT.CARD_ERROR.PROCESSING;

        } else {
          error.message = `(${stripeError.code}) Ha ocurrido un error con el pago.`
        }

      } else {
        const axiosError = e as AxiosError;
				error.message = `(${axiosError.response?.status}) Ha ocurrido un error.`;

				if(axiosError.response?.data.code === Util.STATUS_CODE.PAYMENT_ERROR_CODE.INVALID_CARD.CODE && axiosError.response?.data.decline_code === Util.STATUS_CODE.PAYMENT_ERROR_CODE.INVALID_CARD.DECLINE_CODE){
					error.message = Util.CONSTANT.CARD_ERROR.INVALID;
				} else if (axiosError.response?.data.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.DECLINED.CODE) {
					if (axiosError.response?.data.decline_code === Util.STATUS_CODE.STRIPE_ERROR_CODE.DECLINED.DECLINE_CODE.INSUFFICIENT_FUNDS) {
						error.message = Util.CONSTANT.CARD_ERROR.FUNDS;
					} else {
						error.message = Util.CONSTANT.CARD_ERROR.DECLINE;
					}
				} else if (axiosError.response?.data.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.EXPIRED) {
					error.message = Util.CONSTANT.CARD_ERROR.EXPIRED;
				} else if (axiosError.response?.data.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.INCORRECT_CVC) {
					error.message = Util.CONSTANT.CARD_ERROR.CVC;
				} else if (axiosError.response?.data.code === Util.STATUS_CODE.STRIPE_ERROR_CODE.PROCESSING) {
					error.message = Util.CONSTANT.CARD_ERROR.PROCESSING;
				} else {
					error.message = `(${axiosError.response?.data.code}) Ha ocurrido un error con el pago.`
				}
      }

      throw error;
    }
  }

  /**
   * 
   * @returns 
   */
  export const activePoliciesListFeature = async (): Promise<Policy[] | undefined> => {
    try {
      const { data } = await CorePolicyService.policyListService();
      const list = CorePolicyDataAdapter.activePoliciesListDataAdapter(data);

      return list;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

	/**
	 * 
	 * @param policy 
	 * @param accept 
	 */
	export const automaticRenewalFeature = async (policy: Policy, accept: boolean): Promise<void> => {
		try {
			const requestData = CorePolicyRequestAdapter.policyAutomaticRenewalRequestAdapter(policy, accept);
			await CorePolicyService.automaticRenewalService(requestData);
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
}
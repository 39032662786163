import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { INSURANCE_INIT_PARAMS, INSURANCE_VEHICLE_PATH } from "src/routes/paths/insurance-vehicle.paths"

export namespace InsuranceInitFacade {

  const STEPS = [
    INSURANCE_INIT_PARAMS.INFO.FIRST,
    INSURANCE_INIT_PARAMS.INFO.SECOND,
    INSURANCE_INIT_PARAMS.INFO.THIRD,
    INSURANCE_INIT_PARAMS.INFO.FOURTH
  ]

  export const useInsuranceInit = () => {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [progressValue, setProgressValue] = useState<number>(0);
    const [transition, setTransition] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [allowChanges, setAllowChanges] = useState<boolean>(true);

    /**
     * 
     */
    const nextStep = (): void => {
      if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.FIRST)) {
        navigate(`${INSURANCE_VEHICLE_PATH.INIT}/${INSURANCE_INIT_PARAMS.INFO.SECOND}${location.search}`);
      } else if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.SECOND)) {
        navigate(`${INSURANCE_VEHICLE_PATH.INIT}/${INSURANCE_INIT_PARAMS.INFO.THIRD}${location.search}`);
      } else if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.THIRD)) {
        navigate(`${INSURANCE_VEHICLE_PATH.INIT}/${INSURANCE_INIT_PARAMS.INFO.FOURTH}${location.search}`);
      } else if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.FOURTH)) {
        navigate(`${INSURANCE_VEHICLE_PATH.PICTURES}${location.search}`);
      }
    }

    /**
     * 
     */
    const prevStepPath = (): string => {
      if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.FIRST)) {
        return ``;
      } else if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.SECOND)) {
        return `${INSURANCE_VEHICLE_PATH.INIT}/${INSURANCE_INIT_PARAMS.INFO.FIRST}${location.search}`;
      } else if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.THIRD)) {
        return `${INSURANCE_VEHICLE_PATH.INIT}/${INSURANCE_INIT_PARAMS.INFO.SECOND}${location.search}`;
      } else {
        return `${INSURANCE_VEHICLE_PATH.INIT}/${INSURANCE_INIT_PARAMS.INFO.THIRD}${location.search}`;
      }
    }

    useEffect(
      () => {
        if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.FIRST)) {
          setCurrentStepIndex(0);
        } else if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.SECOND)) {
          setCurrentStepIndex(1);
        } else if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.THIRD)) {
          setCurrentStepIndex(2);
        } else if (location.pathname.includes(INSURANCE_INIT_PARAMS.INFO.FOURTH)) {
          setCurrentStepIndex(3);
        }
      }, [location.pathname]
    )

    useEffect(
      () => {
        const limit = (100 / STEPS.length) * (currentStepIndex + 1);
        if (limit > progressValue) {
          if (progressValue <= limit) {
            setTimeout(() => {
              if (allowChanges)
                setProgressValue(progressValue + 1);
            }, 5);
          }
        } else if (limit < progressValue) {
          setTimeout(() => {
            if (allowChanges)
              setProgressValue(progressValue - 1);
          }, 5);
        }
      }, [currentStepIndex, progressValue, allowChanges]
    );

    useEffect(
      () => {
        return () => { 
          setAllowChanges(false); 
        }
      }, []
    )

    return { STEPS, currentStepIndex, nextStep, progressValue, prevStepPath, transition, setTransition }
  }
}
import { RegimeUse } from "./RegimeUse";

export class TaxRegime {

	/** Class attribute description. */
	private _id: number;
	set id(_id: number) { this._id = _id };
	get id(): number { return this._id };

	/** Class attribute description. */
	private _description: string;
	set description(_description: string) { this._description = _description };
	get description(): string { return this._description };

	/** Class attribute description. */
	private _isMoral: boolean;
	set isMoral(_isMoral: boolean) { this._isMoral = _isMoral };
	get isMoral(): boolean { return this._isMoral };

	/** Class attribute description. */
	private _isIndependant: boolean;
	set isIndependant(_isIndependant: boolean) { this._isIndependant = _isIndependant };
	get isIndependant(): boolean { return this._isIndependant };
	
	/**
	 *
	 */
	private _uses: RegimeUse[];
	set uses(_uses: RegimeUse[]) { this._uses = _uses };
	get uses(): RegimeUse[] { return this._uses };

	constructor (_id: number, _description: string, _isMoral: boolean, _isIndependant: boolean) {
		this._id = _id ? _id : -1;
		this._description = _description ? _description : '';
		this._isMoral = _isMoral ? _isMoral : false;
		this._isIndependant = _isIndependant ? _isIndependant : false;
		this._uses = [];
	}
}
import './UserName.scss';

/**
 *
 */
interface IUserName {

  name?: string;
}

/**
 *
 */
const UserName = (props: IUserName): JSX.Element => {
  return (
    <div className="user-name-wrapper">
      <h1><span>Hola</span> {props.name ? props.name : 'Crabeler'}</h1>
    </div>
  );
};

export default UserName;
import { IRewardsHistoryRequest } from './../interfaces/IRewardsHistoryRequest';
import { Reward } from "src/classes/referrals/Reward";
import { ReferralsAdapter } from "../adapters/referrals.adapter";
import { ReferralsService } from "../services/referrals.service";
import { AxiosError } from "axios";

/**
 *
 */
export namespace ReferralsFeature {

  /**
   * 
   */
  export const rewardsFeature = async (): Promise<Reward[] | undefined> => {
    try {
      const { data } = await ReferralsService.rewardsService();
      let list = ReferralsAdapter.rewardsDataAdapter(data);
      return list;

    } catch (e) {
      console.error(e);
    }
  }
  /**
   * 
   */
  export const exchangeRewardFeature = async (reward: Reward): Promise<boolean | undefined> => {
    try {
      const requestData = ReferralsAdapter.exchangeRewardRequestAdapter(reward);
      await ReferralsService.exchangeRewardService(requestData);
      return true;
    } catch (e) {
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        if (axiosError.response.status === 409) {
          error.message = `No tienes puntos Crabi suficientes.`;
        } else {
          error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
        }
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }

  /**
   *
   */
   export const rewardsHistoryFeature = async (params: IRewardsHistoryRequest): Promise<Reward[] | undefined> => {
    try {
      const { data } = await ReferralsService.rewardsHistoryService(params);
      let list = ReferralsAdapter.rewardsHistoryDataAdapter(data);
      return list;
    } catch (e) {
      console.error(e);
    }
  };
}
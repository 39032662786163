import { Address } from "src/classes/Address";
import { IAddressData } from "../interfaces/address/IAddressData";

export namespace CoreAddressAdapter {

	/**
   * 
   * @param data 
   * @returns 
   */
		 export const postalEntitiesDataAdapter = (data: IAddressData): Address => {
			const dataEntities = data.postal_entities[0];
			let address: Address = new Address(dataEntities.postal_code, dataEntities.country_code, dataEntities.state_code, dataEntities.municipality_sepomex_code);
	
			return address;
		}
}
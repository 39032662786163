import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Endorse } from "src/classes/policy/Endorse";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { POLICY_ENDORSEMENTS_PATH } from "src/routes/paths/policy-endorsements.paths";
import { Util } from "src/utils/Util";
import { PolicyEndorsementsAction } from "../actions/policy-endorsements.action";
import { IEndorsementType } from "../interfaces/IEndorsementType";
import { PolicyEndorsementsFacade } from "./policy-endorsements.facade";

export namespace EndorsementConfigurationFacade {

  export const ENDORSEMENTS_TYPE_LIST: IEndorsementType[] = [
    {
      category: Util.STATUS_CODE.ENDORSEMENT_CATEGORY.VEHICLE,
      code: Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.VIN,
      value: 'VIN',
      name: 'vin',
    },
    {
      category: Util.STATUS_CODE.ENDORSEMENT_CATEGORY.VEHICLE,
      code: Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.PLATE,
      value: 'Placas',
      name: 'plate'
    }
  ]

  /**
   * 
   */
  export const useEndorsementConfiguration = () => {
    const { currentPolicy } = PolicyEndorsementsFacade.useEndorsementsActors();
    const [endorsementsSelectedTypeList, setEndorsementsSelectedTypeList] = useState<IEndorsementType[]>(new Array(1));
    const [endorsementsAvailableTypeList, setendorsementsAvailableTypeList] = useState<IEndorsementType[]>(ENDORSEMENTS_TYPE_LIST)
    const [endorsementsList, setEndorsementsList] = useState<Endorse[]>([new Endorse()]);
    const [isValidConfiguration, setIsValidConfiguration] = useState<boolean>(false);
    const { search } = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * 
     * @returns 
     */
    const endorsementValidation = (): boolean => {
      let isValid: boolean = true;
      for (const endorse of endorsementsList) {
        if (!endorse.vehicle) {
          isValid = false;
        }
      }

      return isValid;
    }

    /**
     * 
     */
    const allowAddEndorsementRow = (): boolean => {
      if (endorsementsList.length < ENDORSEMENTS_TYPE_LIST.length) {
        return true;
      } else {
        return false
      }
    }

    /**
     * 
     */
    const addEndorsementRow = (): void => {
      if (endorsementsList.length < ENDORSEMENTS_TYPE_LIST.length) {
        setEndorsementsList(endorsementsList.concat([new Endorse()]));
        setEndorsementsSelectedTypeList(endorsementsSelectedTypeList.concat(new Array(1)));
        setIsValidConfiguration(false);
      }
    }

    /**
     * 
     * @param index 
     */
    const removeEndorsementRow = (index: number): void => {
      if (endorsementsList.length > 1) {
        setEndorsementsList(
          currentState => {
            currentState.splice(index, 1);
            return currentState;
          }
        );
        setEndorsementsSelectedTypeList(
          currentState => {
            currentState.splice(index, 1);
            return currentState;
          }
        );
        setendorsementsAvailableTypeList(
          () => {
            let newList = [];
            for (const item of ENDORSEMENTS_TYPE_LIST) {
              const index = endorsementsSelectedTypeList.findIndex(t => t !== undefined && t.code === item.code);
              if (index === -1) {
                newList.push(item);
              }
            }
            return newList;
          }
        );

        setIsValidConfiguration(endorsementValidation());
      }
    }

    /**
     * 
     * @param type 
     */
    const onChangeType = (type: IEndorsementType, index: number): void => {
      setEndorsementsSelectedTypeList(
        currentState => {
          currentState[index] = type;
          return currentState;
        }
      );
      setendorsementsAvailableTypeList(
        () => {
          let newList = [];
          for (const item of ENDORSEMENTS_TYPE_LIST) {
            const index = endorsementsSelectedTypeList.findIndex(t => t !== undefined && t.code === item.code);
            if (index === -1) {
              newList.push(item);
            }
          }
          return newList;
        }
      );
    }

    /**
     * 
     * @param type 
     * @param value 
     */
    const setEndorsement = (isValid: boolean, index: number, type: IEndorsementType, newValue?: string): void => {
      let endorseRow = endorsementsList[index];
      if (type.category === Util.STATUS_CODE.ENDORSEMENT_CATEGORY.VEHICLE) {
        if (isValid) {
          const currentVehicle = currentPolicy.vehicle!;
  
          if (type.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.VIN) {
            let newVehicle = new Vehicle();
            newVehicle.vin = newValue!;
            endorseRow.vehicle = { before: currentVehicle, after: newVehicle, code: type.code }
          } else if (type.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.PLATE) {
            let newVehicle = new Vehicle();
            newVehicle.plate = newValue!;
            endorseRow.vehicle = { before: currentVehicle, after: newVehicle, code: type.code }
          }
        } else {
          endorseRow.vehicle = undefined;
        }
      }
      setIsValidConfiguration(endorsementValidation());
    }

    /**
     * 
     */
    const onCompleteConfiguration = (): void => {
      if (endorsementValidation()) {
        dispatch(PolicyEndorsementsAction.newEndorsements(endorsementsList));
        navigate(`${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_SUMMARY(currentPolicy.policyNumber)}${search}`);
      }
    }

    return {
      endorsementsList, endorsementsAvailableTypeList, isValidConfiguration,
      addEndorsementRow, onChangeType, removeEndorsementRow, allowAddEndorsementRow, setEndorsement, onCompleteConfiguration
    }
  }

}
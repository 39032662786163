import { IconFactory } from "src/factory/icon.factory";
import copy from 'copy-to-clipboard';
import './CopyClipboard.scss';
import { AlertFactory } from "src/factory/alert.factory";
import { PropsWithChildren } from "react";

/**
 *
 */
interface ICopyClipboard {

  text: string;

  message: string

}

/**
 *
 */
export const CopyClipboard = (props: PropsWithChildren<ICopyClipboard>): JSX.Element => {

  /**
   * 
   */
  const onCopy = (): void => {
    copy(props.text);
    AlertFactory.infoAlert(props.message);
  }

  return (
    <>
      {
        props.children &&
        <button type="button" className="clipboard-text"
        onClick={(e) => { e.preventDefault(); onCopy() }}>
          {props.children}
        </button>
      }

      {
        !props.children &&
        <button className="button-clipboard inline-flex vertical-align-middle justify-center align-center" type="button"
          onClick={(e) => { e.preventDefault(); onCopy() }}>
          {IconFactory.clipboardIcon()}
        </button>
      }
    </>
  );
};
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Person  } from "src/classes/Person";
import { IPersonManagementAddressForm } from "../interfaces/IPersonManagementAddressForm";

export namespace PersonManagementAddressFormFacade {

  /**
   * 
   */
  const POSTAL_CODE_ERRORS_MESSAGE = {
    LENGTH: 'El código postal debe ser de 5 digitos.',
    INVALID: '¡Lo sentimos! Aún no llegamos a tu municipio.'
  };
  const POSTAL_CODE_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
    },
    {
      key: 'pattern',
      message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
    },
    {
      key: 'minLength',
      message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
    },
    {
      key: 'invalidPostalCode',
      message: POSTAL_CODE_ERRORS_MESSAGE.INVALID
    }
  ];

  /**
   * 
   */
  const ADDRESS_ERRORS_MESSAGE = {
    REQUIRED: 'Este campo es obligatorio',
    PATTERN: 'Campo inválido.'
  };
  const ADDRESS_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: ADDRESS_ERRORS_MESSAGE.REQUIRED
    },
    {
      key: 'pattern',
      message: ADDRESS_ERRORS_MESSAGE.PATTERN
    },
  ];

  /**
   * 
   * @returns 
   */
  export const usePersonAddressForm = (storedPerson?: Person) => {
    const { register, formState: { errors, isValid, touchedFields }, trigger, watch, setValue, getValues } = useForm<IPersonManagementAddressForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    /**
     * 
     */
    const validateDefaultData = useCallback((storedPerson: Person): void => {
      if (storedPerson) {
				if (storedPerson.profile?.address?.postalCode! && !touchedFields.postalCode) {
					setValue('postalCode', storedPerson.profile?.address?.postalCode!, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile?.address?.neighborhood! && !touchedFields.neighborhood) {
					setValue('neighborhood', storedPerson.profile?.address?.neighborhood!, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile?.address?.street! && !touchedFields.street) {
					setValue('street', storedPerson.profile?.address?.street!, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile?.address?.externalReference! && !touchedFields.externalNumber) {
					setValue('externalNumber', storedPerson.profile?.address?.externalReference!, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile?.address?.internalReference! && !touchedFields.internalNumber) {
					setValue('internalNumber', storedPerson.profile?.address?.internalReference, { shouldValidate: true, shouldTouch: true });
				}
      }

			const allDataCondition: boolean = storedPerson.profile?.address?.postalCode!.length! > 0 && storedPerson.profile?.address?.neighborhood!.length! > 0
				&& storedPerson.profile?.address?.street!.length! > 0 && storedPerson.profile?.address?.externalReference!.length! > 0 && storedPerson.profile?.address?.internalReference!.length! > 0;

			if(allDataCondition)
      	trigger();
    }, [setValue, touchedFields, trigger]);

    useEffect(() => {
      if (storedPerson) {
        validateDefaultData(storedPerson);
      }

    }, [storedPerson, validateDefaultData])

    return {
      FORM_ADDRESS_ERRORS: { POSTAL_CODE_ERRORS, ADDRESS_ERRORS },
      formAddress: { register, formState: { errors, isValid, touchedFields }, trigger, watch, setValue, getValues }
    };
  }

}
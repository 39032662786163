import { Segment } from "src/components";
import { Outlet } from "react-router";
import { ClaimsFacade } from "../../controller/facades/claims.facade";

import "./Claims.scss";

/**
 *
 */
interface IClaims { }

/**
 *
 */
const ClaimsPage = (props: IClaims): JSX.Element => {
  const { segmentData } = ClaimsFacade.usePolicy();
  const { claimList } = ClaimsFacade.useClaimList();

  return (
    <div
      id="claims-wrapper"
      className="height-100 flex column space-between border-box"
    >
      <header>
				{
					segmentData.length > 0 &&
						<Segment links={segmentData}
						>
							{
								claimList && claimList.length > 0 &&
								<span className="claim-list-counter absolute flex align-center justify-center">{claimList.length}</span>
							}
						</Segment>
				}
      </header>
      <Outlet />
    </div>
  );
};

export default ClaimsPage;

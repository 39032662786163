import { useState } from "react";
import { Modal } from "src/components";
import { IconFactory } from "src/factory/icon.factory";

import './PictureZoom.scss';

/**
 *
 */
interface IPictureZoom {

  source?: string;
}

/**
 *
 */
const PictureZoom = (props: IPictureZoom): JSX.Element => {
  const [zoom, setZoom] = useState<boolean>(false);

  return (
    <div className="picture-zoom-wrapper">
      {
        props.source &&
        <>
          <div className="zoom-handler absolute width-100 height-100" onClick={(e) => { e.preventDefault(); setZoom(true) }}></div>
          <Modal position="center" show={zoom} onClose={() => setZoom(false)}>

            <div className="zoom">
              <button type="button" className="absolute" onClick={(e) => { e.preventDefault(); setZoom(false) }}>
                {IconFactory.closeIcon()}
              </button>
              <img src={props.source} alt="Zoom Source"></img>
            </div>

          </Modal>
        </>
      }
    </div>
  );
};

export default PictureZoom;
import { DamageClaim } from "src/classes/DamageClaim";
import { SkeletonLoader } from "src/components";
import { Util } from "src/utils/Util";

import "./ClaimCardDetails.scss";

/**
 *
 */
interface IClaimCardDetails {
  /**
   *
   */
  claim?: DamageClaim;
  /**
   *
   */
  loader?: boolean;
}

/**
 *
 */
const ClaimCardDetails = (props: IClaimCardDetails): JSX.Element => {
  const { claim } = props;
  return (
    <div className="claim-card-details text-left flex column height-100 justify-center">
      {
        !props.loader && claim ?
          <>
            <section>
              <h1>Póliza: <span>{claim.policyNumber}</span> </h1>
              <h2>Siniestro: <span>{claim.damageClaimNumber}</span> </h2>
              <div>
                <p>
                  Creado el: <span className="block">{Util.TRANSFORM.DATE.formatUTCDate(claim.createdAt!)} </span>
                </p>
              </div>
            </section>
          </>
          :
          <>
            <section>
              <SkeletonLoader />
            </section>
            <section>
              <SkeletonLoader />
              <SkeletonLoader />
            </section>
          </>
      }
    </div >
  );
};

export default ClaimCardDetails;

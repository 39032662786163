export const pattern = {

  NUMBERS: /^\d+$/,

	NAMES: /^[a-zA-ZÀ-ÿ][\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,

	PERSON_NAMES: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,

	COMPANY_NAMES: /^[0-9A-Za-zÀ-ÿ\s,._+;()*~'#@!?&-]+$/,

  EMAIL: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g,

  ADDRESS: /^[A-Za-z0-9'#.,\-\s]/,

  ALPHANUMERIC: /^([a-zA-Z0-9 \u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]+)$/,

  DESCRIPTION: /^([a-zA-Z0-9À-ÿ\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9.,\s]+)$/,
  
  RFC: /^([A-ZÑ]|)?[A-ZÑ]{3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/i,

  VEHICLE_VIN: /^[0-9A-HJ-NPR-Z&]{17}$/i,

  VEHICLE_PLATE: /^[-_A-zÀ-ú0-9 ]{5,}$/,

	VEHICLE_MODEL: /^([ \w-+.]+)$/,

  PLAN_COUPON: /^[0-9a-zA-Z\s]{4,}$/i,
}
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Policy } from "src/classes/Policy";
import { AlertFactory } from "src/factory/alert.factory";
import { PolicyDocumentFeature } from "../feature/policy-document.feature"


export namespace PolicyDocumentFacade {

  /**
   * 
   * @param currentPolicy 
   * @returns 
   */
  export const usePolicyCover = (currentPolicy: Policy) => {
    const [loadingCover, setLoadingCover] = useState<boolean>(false);

    /**
     * 
     */
    const getCover = async (): Promise<void> => {
      try {
        setLoadingCover(true);
        const blob = await PolicyDocumentFeature.policyCoverFeature(currentPolicy.id);
        const fileUrl = window.URL.createObjectURL(blob!);
        window.open(fileUrl);
      } catch (e) {
        console.error(e)
        AlertFactory.errorAlert('Ha ocurrido un error al mostrar el documento.');
      } finally {
        setLoadingCover(false);
      }
    }

    return { getCover, loadingCover }
  }

  /**
   * 
   * @param currentPolicy 
   * @returns 
   */
  export const usePolicyReceipt = (currentPolicy: Policy) => {
    const [loadingReceipt, setLoadingReceipt] = useState<boolean>(false);

    /**
     * 
     */
    const getReceipt = async (): Promise<void> => {
      try {
        setLoadingReceipt(true);
        const blob = await PolicyDocumentFeature.policyReceiptFeature(currentPolicy.id);
        const fileUrl = window.URL.createObjectURL(blob!);
        window.open(fileUrl);
      } catch (e) {
        console.error(e)
        AlertFactory.errorAlert('Ha ocurrido un error al mostrar el documento.');
      } finally {
        setLoadingReceipt(false);
      }
    }

    return { getReceipt, loadingReceipt }
  }

  /**
   * 
   */
  export const usePolicyDocumentTerms = () => {
    const [loadingTerms, setLoadingTerms] = useState<boolean>(false);
    const { policyNumber } = useParams<string>();

    /**
     * 
     */
    const getTerms = async (): Promise<void> => {
      try {
        setLoadingTerms(true);
        const blob = await PolicyDocumentFeature.policyTermsFeature(policyNumber!);
        const fileUrl = window.URL.createObjectURL(blob!);
        window.open(fileUrl);
      } catch (e) {
        console.error(e)
        AlertFactory.errorAlert('Ha ocurrido un error al mostrar el documento.');
      } finally {
        setLoadingTerms(false);
      }
    }

    return { getTerms, loadingTerms }
  }

  /**
   * 
   */
  export const usePolicyDocumentTermsSumary = () => {
    const [loadingTermsSumary, setLoadingTermsSumary] = useState<boolean>(false);
    const { policyNumber } = useParams<string>();
    /**
     * 
     */
    const getTermsSumary = async (): Promise<void> => {
      try {
        setLoadingTermsSumary(true);
        const blob = await PolicyDocumentFeature.policyTermsSumaryFeature(policyNumber!);
        window.open(URL.createObjectURL(blob!));
      } catch (e) {
        console.error(e);
        AlertFactory.errorAlert('Ha ocurrido un error al mostrar el documento.');
      } finally {
        setLoadingTermsSumary(false);
      }
    }

    return { getTermsSumary, loadingTermsSumary }
  }
}
import React from "react";
import { PropsWithChildren } from 'react';
import './Radio.scss';

/**
 *
 */
interface IRadio {

  withoutForm?: boolean
}

/**
 *
 */
export const Radio = React.forwardRef(
  (
    props: PropsWithChildren<IRadio & React.InputHTMLAttributes<HTMLInputElement>>,
    ref: React.Ref<any>
  ): JSX.Element => {

    const {
      children,
      withoutForm,
      ...rest
    } = props;

    return (
      <div className="radio-wrapper relative inline-flex align-center vertical-align-middle">
        <input className="absolute width-100 height-100" type="radio" {...rest} ref={ref}  no-form={withoutForm? '' : null}/>
        <span className="checkmark relative inline-flex vertical-align-middle justify-center align-center"></span>
        <span className="radio-text inline-block vertical-align-middle">{props.children}</span>
      </div>
    );
  }
);
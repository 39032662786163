import { Outlet } from "react-router-dom";
import { InsuranceVehicleFacade } from "../../controller/facades/insurance-vehicle.facade";
import './InsuranceVehicle.scss';


/**
 *
 */
const InsuranceVehiclePage = (): JSX.Element => {
  InsuranceVehicleFacade.useInsuranceVehicle();
  return (
    <div id="insurance-vehicle-wrapper" className="fixed width-100 height-100 flex column border-box">
      <Outlet />
    </div>
  );
};

export default InsuranceVehiclePage;
import { useForm } from "react-hook-form";
import { IChangePasswordForm } from "../interfaces/IChangePasswordForm";

export namespace ChangePasswordFormFacade {

  const CURRENT_PASSWORD_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'Ingresa tu contraseña.',
    },
  ]


  /**
   * 
   */
  export const useChangePasswordForm = () => {
    const { register, formState: { errors, isValid }, getValues } = useForm<IChangePasswordForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    return { CURRENT_PASSWORD_ERRORS, register, formState: { errors, isValid }, getValues }
  }

}
import PlanManagement from "src/libs/PlanManagement/ui/template/PlanManagement";
import { PlanRenewalFacade } from "src/modules/policy-renewal/controller/facades/plan-renewal.facade";
import { PolicyRenewalReturnValidactionFacade } from "src/modules/policy-renewal/controller/facades/policy-renewal-return-validation.facade";
import { PolicyRenewalFacade } from "src/modules/policy-renewal/controller/facades/policy-renewal.facade";
import './PlanRenewal.scss';

/**
 *
 */
const PlanRenewalPage = (): JSX.Element => {
  const { onReturn, steps } = PolicyRenewalReturnValidactionFacade.useReturnValidation();
  const { selectPlan } = PlanRenewalFacade.usePlanRenewal();
  const { user, currentPolicy, plan } = PolicyRenewalFacade.usePolicyRenewalActors();

  return (
    <div id="plan-renewal-wrapper" className="height-100 width-100">
      {
        currentPolicy &&
        <>
          <PlanManagement 
						storedPlan={plan}
						user={user} 
						vehicle={currentPolicy.vehicle!} 
						coverages={currentPolicy.coverages} 
						isRenewal={true}
						onSelectPlan={selectPlan} onCancel={() => onReturn(steps.plan)} 
						onBackButton={() => onReturn(steps.plan)}
          />
        </>
      }
    </div>
  );
};

export default PlanRenewalPage;


import { DocumentSignatureFacade } from 'src/modules/common/controller/facades/document-signature.facade';
import { Text, Spinner, Checkbox, Button } from 'src/components';
import { DocumentFactory } from 'src/factory/DocumentFactory/document.factory';
import { Controller } from "react-hook-form";
import { DOCUMENT_SIGNATURE_PAGES } from "src/routes/paths/common.paths";
import { IconFactory } from "src/factory/icon.factory";
import ESign from "src/libs/ESign/ui/template/ESign";
import './DocumentSignature.scss';

/**
 *
 */
const DocumentSignaturePage = (): JSX.Element => {
  const { loading, updating, currentDocument, form, page, sendRequest, changePage } = DocumentSignatureFacade.useDocumentSignature();

  return (
    <div className="documentSignature">
      {
        loading.value ?
          <div className="documentSignature__loader">
            <Spinner color="main" circles />
          </div>
          :

          <>
            {
              currentDocument && page &&
              <>
                {
                  page === DOCUMENT_SIGNATURE_PAGES.APPROVAL && !currentDocument.completed &&
                  <div className="documentSignature__content">
                    <Text type="subtitle" align="center">¡Hola, necesitamos tu firma!</Text>
                    <Text type="paragraph" align="center">Por favor lee el siguiente documento y si est&aacute;s de acuerdo, procede a dejarnos tu firma.</Text>

                    <DocumentFactory.Document pdfUrl={currentDocument.fileUrl} />

                    <form className="documentSignature__content__form">
                      <div className="documentSignature__content__form__field">

                        <Controller
                          control={form.control}
                          name="confirmation"
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange } }) => (
                            <Checkbox
                              defaultChecked={form.getValues('confirmation')}
                              onChange={(e) => onChange(e.target.checked)}
                            >
                              He le&iacute;do el documento y procedo a firmar.
                            </Checkbox>
                          )}
                        />
                      </div>

                    </form>

                    <div className="documentSignature__content__button-wrapper">
                      <Button type="button"
                        loading={updating.value}
                        disabled={!form.formState.isValid || updating.value}
                        onClick={() => sendRequest(false).then(() => changePage('forward', DOCUMENT_SIGNATURE_PAGES.CONFIRMATION))}
                      >
                        Firmar documento
                      </Button>
                    </div>

                  </div>
                }

                {
                  page === DOCUMENT_SIGNATURE_PAGES.CONFIRMATION && !currentDocument.completed &&
                  <div className="documentSignature__content">
                    <button type="button" className="documentSignature__content__button-back" onClick={() => changePage('replace', DOCUMENT_SIGNATURE_PAGES.APPROVAL)}>
                      {IconFactory.leftArrowIcon()}
                    </button>
                    <Text type="subtitle" align="center">Ingresa tu firma <br /> en el espacio indicado</Text>

                    <div className="documentSignature__content__signature">
                      <Controller
                        control={form.control}
                        name="signature"
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange } }) => (
                          <ESign
                            onApply={(eSign) => onChange(eSign!)}
                            defaultImage={form.getValues('signature')}
                          />

                        )}
                      />

                      <Text type="paragraph" align="center">Firma de</Text>
                      <Text type="paragraph" align="center" bold>{currentDocument.personName}</Text>
                    </div>

                    <div className="documentSignature__content__button-wrapper">
                      <Button type="button"
                        loading={updating.value}
                        disabled={!form.formState.isValid || updating.value}
                        onClick={() => sendRequest(true).then(() => changePage('replace', DOCUMENT_SIGNATURE_PAGES.COMPLETED))}
                      >
                        Enviar firma
                      </Button>
                    </div>

                  </div>
                }


                {
                  page === DOCUMENT_SIGNATURE_PAGES.COMPLETED &&
                  <div className="documentSignature__content">
                    <div className="documentSignature__content__completed">
                      <Text type="title" align="center">¡Muchas Gracias!</Text>
                      <Text type="title" align="center">Pronto te confirmaremos el pago</Text>

                      <Text type="paragraph" align="center" className="documentSignature__content__completed__info">
                        Este documento ya ha sido firmado y est&aacute; cerrado. No se podr&aacute; acceder nuevamente.
                      </Text>

                      <Text type="paragraph" align="center" bold className="documentSignature__content__completed__link">
                        Ir a <a href="https://crabi.com" target="_self" >crabi.com</a>
                        </Text>

                    </div>
                  </div>
                }
              </>

            }
          </>
      }



    </div>
  );
};

export default DocumentSignaturePage;
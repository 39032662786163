import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "src/routes/paths/dashboard.paths";

/**
 * 
 */
export namespace PolicyCompletionFacade {

  /**
   * 
   */
  export const usePolicyCompletion = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    /***
     * 
     */
    const finishPolicyManagment = (): void => {
      navigate(DASHBOARD_PATH.DASHBOARD, { replace: true });
    }

    return { finishPolicyManagment, query }
  }
}
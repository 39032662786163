import { useEffect } from 'react';
import { Person  } from 'src/classes/Person';
import { FormError, InputLabel } from "src/components";
import PasswordManagment from 'src/libs/PasswordManagment/ui/template/PasswordManagment';
import { AccountDataFormFacade } from 'src/modules/quoter/controller/facades/account-data-form.facade';
import { AccountDataFacade } from 'src/modules/quoter/controller/facades/account-data.facade';
import { Util } from 'src/utils/Util';

import './AccountData.scss';

/**
 *
 */
interface IAccountData { 
	/** */
	storedPerson?: Person;
	/** */
	onComplete: (email?: string, password?: string) => void;
}

/**
 *
 */
const AccountData = (props: IAccountData): JSX.Element => {
	/** */
	const { storedPerson, onComplete } = props;
	/** */
	const { FORM_DATA_ERRORS, formData } = AccountDataFormFacade.usePersonDataForm();
	const { validatePersonEmail, isValidatingEmail } = AccountDataFormFacade.useEmailValidation();
	const { email, password, setEmail, checkPasswordForm } = AccountDataFacade.useAccountDataFacade();

	/** */
	const onFormChange = () => {
		setEmail(formData.getValues().email);
	};

	/** */
	useEffect(() => {
		if(email && password && formData.formState.isValid)
			onComplete(email, password);
		else
			onComplete();
	}, [email, password, formData.formState.isValid, onComplete]);

  return (
    <div id='account-data-wrapper'>
			<form onChange={onFormChange}>
				<div className="inline-input">
					<InputLabel
						required
						defaultValue={storedPerson? storedPerson.email : ''}
						type="email"
						maxLength={100}
						loading={isValidatingEmail}
						errors={!!formData.formState.errors.email}
						{...formData.register("email", {
							required: true,
							pattern: Util.PATTERN.EMAIL,
							validate: { invalidEmail: async (email) => await validatePersonEmail(email) }
						})}
					>
						Correo electr&oacute;nico
					</InputLabel>
					<FormError schema={FORM_DATA_ERRORS.EMAIL_ERRORS} type={formData.formState.errors.email?.type} />
				</div>
			</form>

			<PasswordManagment isValidForm={checkPasswordForm}/>
    </div>
  );
};

export default AccountData;
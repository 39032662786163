
const BASE_URL: string = '/referrals';

/**
 * 
 */
 export const REFERRALS_PATH = {

  REFERRALS: BASE_URL,
  REWARDS: `${BASE_URL}/rewards`,
  REWARDS_HISTORY: `${BASE_URL}/history`,
  REWARD_DETAILS_PATH: `${BASE_URL}/rewards/details/:title`,
  REWARD_DETAILS: (title: string) => `${BASE_URL}/rewards/details/${title}`,
}
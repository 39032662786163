import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { IconFactory } from 'src/factory/icon.factory';
import './ButtonBack.scss';

/**
 *
 */
interface IButtonBack {

  text?: boolean;

  overlay?: boolean;

  button?: boolean;

	hideText?: boolean;

  onClick?: () => void;
}

/**
 *
 */
export const ButtonBack = (props: IButtonBack & LinkProps): JSX.Element => {

	const { text, overlay, to, button, hideText, onClick } = props;
	const linkClasses = classNames({
		'back-button': true,
		'block': true,
		'hide-text-on-mobile': hideText
	});
	const buttonClasses = classNames({
		'back-button': true,
		'hide-text-on-mobile': hideText
	})

  return (
    <>
      {
        !button &&
        <Link to={to} replace className={linkClasses} link-overlay={overlay ? '' : null}>
          {IconFactory.leftArrowIcon()}
          {
            text &&
            <span>
              Regresar
            </span>
          }
        </Link>
      }

      {
        button &&
        <button type="button" className={buttonClasses} link-overlay={overlay ? '' : null}
          onClick={(e) => { e.preventDefault(); onClick ? onClick() : console.warn('Missing onClick event') }}>
          {IconFactory.leftArrowIcon()}
          {
            text &&
            <span>
              Regresar
            </span>
          }
        </button>
      }
    </>
  );
};
import { useLocation } from "react-router-dom";
import { ClaimsFacade } from "src/modules/claims/controller/facades/claims.facade";
import { CLAIM_PAYMENTS_LIST_PATH } from "src/routes/paths/claim-payments-list.paths";
import ActivePoliciesList from "../../organisms/ActivePoliciesList/ActivePoliciesList";
import "./ActivePolicies.scss";

/**
 *
 */
interface IActivePolicies { }

/**
 *
 */
const ActivePoliciesPage = (props: IActivePolicies): JSX.Element => {
  const { isLoading, policyList } = ClaimsFacade.usePolicyList();
  const location = useLocation();

	/** */
	const isClaimPayments = (): boolean => {
		if (location.pathname.includes(CLAIM_PAYMENTS_LIST_PATH.CLAIM_PAYMENTS_LIST)) {
			return true;
		}

    return false;
	}
  

  return (
    <div className="height-100 flex column space-between">

			{
				(policyList === undefined || (policyList && policyList.length > 0)) &&
				<>
					<h2 className="text-center">{isClaimPayments()? 'Pago de daños' : 'Pago pactado'}</h2>
      		<p className="text-center">Selecciona la póliza en la que has tenido un siniestro</p>
      		<ActivePoliciesList isloading={isLoading} policyList={policyList} />
				</>
			}
			
			{
				policyList && policyList.length === 0 &&
				<div className="empty-state height-100">
					<p>Por ahora no tienes una póliza activa</p>
				</div>
			}
    </div>
  );
};

export default ActivePoliciesPage;

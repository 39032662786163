import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFacade } from "./claim-payments.facade";

const CLAIM_CURRENT_PATH: string = CLAIM_PAYMENTS_PATH.ID_DOCUMENTS;
const AGREED_CURRENT_PATH: string = AGREED_PAYMENTS_PATH.ID_DOCUMENTS;

/**
 *
 */
export namespace IdDocumentsFacade {

	/** */
	export const useIdDocumentsFacade = (ref?: React.RefObject<HTMLDivElement>) => {
		/** */
		const { currentDamageClaim, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
		const [picturesValidation, updatePicturesValidation] = useState(false);
		const dispatch = useDispatch();

		/** */
    const scrollIntoView = (ref?: React.RefObject<HTMLDivElement>): void => {
      if (ref && ref.current) {
				window.scrollTo(0, ref.current!.offsetTop);
				setTimeout(() => window.scrollTo(0, ref.current!.offsetTop), 500);
      }
    }

		/**
		 * 
		 */
		const validatePictures = useCallback(() => {
			const idDocumentsCondition: boolean = currentDamageClaim?.attachmentIdentification[0].source.length! > 0 && currentDamageClaim?.attachmentIdentification[1].source.length! > 0
				&& currentDamageClaim?.attachmentIdentification[2].source.length! > 0;

			updatePicturesValidation(idDocumentsCondition);
		}, [currentDamageClaim, updatePicturesValidation]);

		useEffect(() => {
			scrollIntoView(ref);
			dispatch(ClaimPaymentsAction.updateCurrentPath(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH));
			validatePictures();
		}, [dispatch, currentDamageClaim, validatePictures, ref, isAgreedPayment]);

		return { currentDamageClaim, picturesValidation, isAgreedPayment, AGREED_CURRENT_PATH, CLAIM_CURRENT_PATH, validatePictures };
	};

}
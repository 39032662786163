import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VehicleBrand } from 'src/classes/vehicle/VehicleBrand';
import { VehicleModel } from 'src/classes/vehicle/VehicleModel';
import { VehicleVersion } from 'src/classes/vehicle/VehicleVersion';
import { VehicleYear } from 'src/classes/vehicle/VehicleYear';
import { IVehicleManagementStateReducer } from "src/store/states/libs-state/IVehicleManagementStateReducer";

/**
 *
 */
export namespace VehicleManagementSlice {

  /**
   * 
   */
   const initialState: IVehicleManagementStateReducer = {
    brands: [],
    models: [],
    years: [],
    version: [],
		isLoadingBrands: false,
		isLoadingModels: false,
		isLoadingYears: false,
		isLoadingVersions: false
  };

  /**
   * 
   * @param state 
   * @param action 
   */
   const clear: CaseReducer<IVehicleManagementStateReducer> = (state) => {
    state.brands = [];
    state.models = [];
    state.version = [];
    state.years = [];
		state.isLoadingBrands = false;
		state.isLoadingModels = false;
		state.isLoadingYears = false;
		state.isLoadingVersions = false;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const vehicleBrandsList: CaseReducer<IVehicleManagementStateReducer, PayloadAction<VehicleBrand[]>> = (state, action) => {
    state.brands = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const vehicleModelsList: CaseReducer<IVehicleManagementStateReducer, PayloadAction<VehicleModel[]>> = (state, action) => {
    state.models = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const vehicleYearsList: CaseReducer<IVehicleManagementStateReducer, PayloadAction<VehicleYear[]>> = (state, action) => {
    state.years = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const vehicleVersionsList: CaseReducer<IVehicleManagementStateReducer, PayloadAction<VehicleVersion[]>> = (state, action) => {
    state.version = action.payload;
  }

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setLoadingBrands: CaseReducer<IVehicleManagementStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingBrands = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setLoadingModels: CaseReducer<IVehicleManagementStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingModels = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setLoadingYears: CaseReducer<IVehicleManagementStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingYears = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setLoadingVersion: CaseReducer<IVehicleManagementStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingVersions = action.payload;
	}

  const reducers = {
    clear,
    vehicleBrandsList,
    vehicleModelsList,
    vehicleYearsList,
    vehicleVersionsList,
		setLoadingBrands,
		setLoadingModels,
		setLoadingYears,
		setLoadingVersion
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'vehicleData',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
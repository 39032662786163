import { PolicyPictureTokenAdapter } from "../adapters/policy-picture-token.adapter";
import { PolicyPicturesService } from "../services/policy-pictures.service"

export namespace PolicyPictureFeature {

  /**
   * 
   */
  export const policyPicturesTokenFeature = async (): Promise<string | undefined> => {
    try {
      const { data } = await PolicyPicturesService.policyPicturesTokenService();
      const token = PolicyPictureTokenAdapter.pictureTokenDataAdapter(data);

      return token;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
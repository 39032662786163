import { useEffect, useState } from "react"
import { PaymentCost } from "src/classes/PaymentCost"
import { Plan } from "src/classes/plan/Plan";
import { Util } from "src/utils/Util";

export namespace PlanCostFacade {

  /**
   * 
   */
  export const PAYMENT_METHOD = {
    DEBIT: { name: 'Débito', code: 1 },
    CREDIT: { name: 'Crédito', code: 2 },
  }

  /**
   * 
   */
  export const PLAN_COST = {
    ANNUAL: { id: 0, name: 'Pago de contado' },
    SUSCRIPTION: { id: 1, name: 'Pago mes a mes' },
  }


  export const usePlanCost = (currentPlan?: Plan, storedPlan?: Plan) => {
    const [selectedPlanCost, setSelectedPlanCost] = useState<PaymentCost>();
    const [selectedDiscountPlanCost, setSelectedDiscountPlanCost] = useState<PaymentCost>();
    const [additionalCost, setAdditionalCost] = useState<PaymentCost>();
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState<{name: string, code: number}>(PAYMENT_METHOD.DEBIT);
    const [currentCostId, setCurrentCostId] = useState<number>(PLAN_COST.ANNUAL.id);
		const [defaultTerm, setDefaultTerm] = useState<{ id: number, value: string }>();

    /**
     * 
     * @param code 
     */
    const selectPaymentMethodByCode = (code: number): void => {
      if (code === PAYMENT_METHOD.CREDIT.code) {
        setCurrentPaymentMethod(PAYMENT_METHOD.CREDIT);
      } else if (code === PAYMENT_METHOD.DEBIT.code) {
        setCurrentPaymentMethod(PAYMENT_METHOD.DEBIT);
      }
    }

    /**
     * 
     * @param id 
     */
    const selectCost = (id?: number): void => {
      if (currentPlan) {
        const costId = id !== undefined? id : currentCostId;

        if (id !== undefined) {
          setCurrentCostId(id);
        }
        
        if (costId === PLAN_COST.ANNUAL.id) {
          setSelectedPlanCost(currentPlan.cost);
          setAdditionalCost(undefined);
          if (currentPlan.discount) {
            setSelectedDiscountPlanCost(currentPlan.discount.cost);
          }

        } else if (costId === PLAN_COST.SUSCRIPTION.id) {
          setSelectedPlanCost(currentPlan.subscription.firstCost);

          if (!currentPlan.discount) {
            setAdditionalCost(currentPlan.subscription.cost);
          } else {
            setSelectedDiscountPlanCost(currentPlan.discount.subscription.firstCost);
            setAdditionalCost(currentPlan.discount.subscription.cost);
          }

        } else if (costId > PLAN_COST.SUSCRIPTION.id) {
          // id value === (index + 1) + PLAN COST ID
          setSelectedPlanCost(currentPlan.monthly.costs[(costId - 1) - PLAN_COST.SUSCRIPTION.id]);
          setAdditionalCost(undefined);

          if (currentPlan.discount) {
            setSelectedDiscountPlanCost(currentPlan.discount.monthly.costs[(costId - 1) - PLAN_COST.SUSCRIPTION.id]);
          }
        }

        // Clear discount cost
        if (!currentPlan.discount && selectedDiscountPlanCost) {
          setSelectedDiscountPlanCost(undefined);
        }
      }
    };

		/**
		 * 
		 */
		useEffect(
			() => {
				if (storedPlan) {
					if(storedPlan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) {
						setDefaultTerm({ id: PlanCostFacade.PLAN_COST.ANNUAL.id, value: PlanCostFacade.PLAN_COST.ANNUAL.name });
						selectCost(PlanCostFacade.PLAN_COST.ANNUAL.id);
					} else if (storedPlan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) {
						setDefaultTerm({ id: PlanCostFacade.PLAN_COST.SUSCRIPTION.id, value: PlanCostFacade.PLAN_COST.SUSCRIPTION.name });
						selectCost(PlanCostFacade.PLAN_COST.SUSCRIPTION.id);
					} else if (storedPlan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS) {
						for (let i = 0; i <= storedPlan.monthly.costs.length; i ++) {
							const cost = storedPlan.monthly.costs[i];
							if (cost.monthsQuantity === storedPlan.selectedPlanPayment.paymentsCount) {
								setDefaultTerm({ id: (i + 2), value: `Pago a ${cost.monthsQuantity} meses` });
								selectCost(i);
								break;
							}
						}
					}
				} else {
					setDefaultTerm({ id: PlanCostFacade.PLAN_COST.SUSCRIPTION.id, value: PlanCostFacade.PLAN_COST.SUSCRIPTION.name });
					selectCost(PlanCostFacade.PLAN_COST.SUSCRIPTION.id);
				}
			}, //eslint-disable-next-line
			[]
		)

    return { selectedPlanCost, additionalCost, currentPaymentMethod, selectedDiscountPlanCost, selectPaymentMethodByCode, selectCost, defaultTerm }
  }
}
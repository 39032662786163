import React from 'react';
import { Spinner } from "src/components";

import './Button.scss';

interface IButtonProps {
  /**
   * Values: outline, fill. Default value fill.
   */
  fill?: 'outline' | 'fill' | 'none';
  /**
   * 
   */
  loading?: boolean;
  /**
   * 
   */
  shadowless?: boolean;
  /**
   * 
   */
  icon?: JSX.Element;
  /**
   * 
   */
  iconposition?: 'left' | 'right'
  /**
   * Default color: main
   */
  color?: 'main' | 'red'
}

/**
 *
 */
export const Button = (props: IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {

  const { className, loading, shadowless, ...rest } = props;

  return (
    //@ts-ignore
    <button fill={props.fill ? props.fill : 'fill'} is-loading={loading ? '' : null}
      className={`c-button width-100 relative ${className ? className : ''} ${props.color === 'red'? 'color-red' : ''} ${shadowless ? 'c-button--noShadow' : ''}`}
      {...rest}>
      <span className={loading ? 'hide' : ''}>
        {
          props.icon && props.iconposition === 'left' &&
          <span className="button-icon left">
            {props.icon}
          </span>
        }

        {props.children}

        {
          props.icon && (!props.iconposition || props.iconposition === 'right') &&
          <span className="button-icon">
            {props.icon}
          </span>
        }
      </span>
      {
        loading && <Spinner />
      }
    </button>
  );
};
import { useEffect } from "react";
import { FormError, InputLabel } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { PasswordManagmentFacade } from "../../controller/facades/password-managment.facade";
import PasswordManagmentSecurityRule from "../atoms/PasswordManagmentSecurityRule/PasswordManagmentSecurityRule";

import './PasswordManagment.scss';
/**
 *
 */
interface IPasswordManagment {

  label?: string;

  email?: string;

  isValidForm?: (isValid: boolean, password?: Password) => void;
}

type Password = {

  password: string;

  confirmPassword: string;
}


/**
 *
 */
const PasswordManagment = (props: IPasswordManagment): JSX.Element => {
  const { isValidForm } = props;
  const { 
    register, formState, watch, getValues,
    PASSWORD_ERRORS, CONFIRM_PASSWORD_ERRORS, passwordState, validatePassword 
  } = PasswordManagmentFacade.usePasswordValidation();
  

  useEffect(() => {
    if (isValidForm) {
      const password: Password = {
        password: getValues().newPassowrd,
        confirmPassword: getValues().confirmNewPassword
      }
      isValidForm(formState.isValid, formState.isValid? password : undefined);
    }

  }, [formState.isValid, isValidForm, getValues])

  return (
    <div className="password-managment-wrapper">
      <form>
        <div className="password-section inline-input relative">
          <InputLabel
            required
            placeholder="Ingresa tu nueva contraseña"
            type="password"
            defaultValue=""
            errors={!!formState.errors.newPassowrd}
            {...register("newPassowrd", {
              required: true,
              onChange: (event) => validatePassword.validateOnChange(event.target.value as string, watch().confirmNewPassword),
              validate: {
                patternSpecificWord: (password) => validatePassword.validateSpecificWord(password),
                repeatedElements: (password) => validatePassword.validateRepeatedElements(password),
                secuentialElements: (password) => validatePassword.validateSecuentialElements(password),
                includesCurrentEmail: (password) => validatePassword.validateCurrentEmail(password, props.email),
                invalidParams: (password) => validatePassword.validatePasswordParams(password),
              }
            })}
          >
            {props.label? props.label : 'Nueva contraseña'}
          </InputLabel>
          <FormError schema={PASSWORD_ERRORS} type={formState.errors?.newPassowrd?.type} />
        </div>

        <div className="password-section inline-input relative">
          <InputLabel
            required
            placeholder="Ingresa de nuevo tu nueva contraseña"
            type="password"
            defaultValue=""
            errors={!!formState.errors.confirmNewPassword}
            {...register("confirmNewPassword", {
              required: true,
              validate: {
                notSame: (confirmNewPassword) => validatePassword.validateSamePassword(confirmNewPassword, watch().newPassowrd),
              }
            })}
          >
            {props.label? `Confirmar ${props.label.toLocaleLowerCase()}` : 'Confirmar nueva contraseña'}
          </InputLabel>
          <FormError schema={CONFIRM_PASSWORD_ERRORS} type={formState.errors?.confirmNewPassword?.type} />
        </div>

        <div className="password-params">
          <ul>
            <li className="flex">
              {
                passwordState.hasUppercase ?
                  <span className="inline-block vertical-align-middle validation-icon valid-param">{IconFactory.checkIcon()}</span> :
                  <span className="inline-flex vertical-align-middle validation-icon invalid-param"></span>
              }
              <span className="inline-block vertical-align-middle">Una may&uacute;scula</span>
            </li>
            <li className="flex">
              {
                passwordState.hasLowercase ?
                  <span className="inline-block vertical-align-middle validation-icon valid-param">{IconFactory.checkIcon()}</span> :
                  <span className="inline-flex vertical-align-middle validation-icon invalid-param"></span>
              }
              <span className="inline-block vertical-align-middle">Una min&uacute;scula</span>
            </li>
            <li className="flex">
              {
                passwordState.hasDigit ?
                  <span className="inline-block vertical-align-middle validation-icon valid-param">{IconFactory.checkIcon()}</span> :
                  <span className="inline-flex vertical-align-middle validation-icon invalid-param"></span>
              }
              <span className="inline-block vertical-align-middle">Un n&uacute;mero</span>
            </li>
            <li className="flex">
              {
                passwordState.validLength ?
                  <span className="inline-block vertical-align-middle validation-icon valid-param">{IconFactory.checkIcon()}</span> :
                  <span className="inline-flex vertical-align-middle validation-icon invalid-param"></span>
              }
              <span className="inline-block vertical-align-middle">8 caracteres m&iacute;nimo</span>
            </li>
          </ul>
        </div>
        <PasswordManagmentSecurityRule />
      </form>
    </div>
  );
};

export default PasswordManagment;
import { useNavigate } from "react-router-dom";
import { Button, ButtonBack } from "src/components";
import { PolicyOwnerFacade } from 'src/modules/policy-creation/controller/facades/policy-owner.facade';
import { POLICY_CREATION_PATH } from "src/routes/paths/policy-creation.paths";
import './PolicyOwner.scss';

/**
 *
 */
interface IPolicyOwner {

}

/**
 *
 */
const PolicyOwner = (props: IPolicyOwner): JSX.Element => {

  const isCurrentUserOwner = PolicyOwnerFacade.usePolicyOwner();
	const navigate = useNavigate();

  return (
    <div id="policyOwnerWrapper" className="flex column flex-grow-100">
			<ButtonBack button text
				to={''}
				onClick={() => navigate(POLICY_CREATION_PATH.POLICY_VEHICLE_SALVAGE)}
			/>

      <div className="policyOwnerContent flex flex-grow-100">

        <div className="width-60 text-center">
          <h1>¿Qui&eacute;n usar&aacute; este veh&iacute;culo?</h1>
          <p className="width-50">Por favor ay&uacute;danos a saber a qui&eacute;n estar&iacute;amos protegiendo</p>

          <div className="flex space-around align-center">
            <Button fill="outline" className="flex-grow-45" onClick={() => isCurrentUserOwner(false)}>
              Un familiar
            </Button>
            <Button className="flex-grow-45" onClick={() => isCurrentUserOwner(true)}>
              Yo
            </Button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PolicyOwner;
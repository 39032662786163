import { Button, InputLabel } from "src/components";
import { useEffect } from "react";
import { IconFactory } from "src/factory/icon.factory";
import { ForgotPasswordFormFacade } from "src/modules/forgot-password/controller/facades/forgot-password-form.facade";
import { ForgotPasswordFacade } from "src/modules/forgot-password/controller/facades/forgot-password.facade";
import { Util } from "src/utils/Util";
import PasswordManagment from "src/libs/PasswordManagment/ui/template/PasswordManagment";

import './PasswordRestore.scss';

/**
 *
 */
const PasswordRestorePage = (): JSX.Element => {
  const { register, setValue } = ForgotPasswordFormFacade.useRestoreForm();
  const { email, validateNewPassword, isNewPasswordValid, restorePassword, isUpdating } = ForgotPasswordFacade.useRestorePassword();

  useEffect(() => {
    setValue('email', email);
  }, [email, setValue])

  return (
    <div id="password-restore-wrapper" style={{ backgroundImage: `url(${Util.ASSET.LOGIN_BG})` }}>

      <div className="wrapper-content">

        <h1 className="text-center">
          Reestablecer contrase&ntilde;a
        </h1>

        <form>
          <div className="form-section">
            <InputLabel
              icon={IconFactory.mailIcon()}
              defaultValue=""
              type="email"
              disabled
              {...register("email", { required: true })}
            >
              Correo electr&oacute;nico
            </InputLabel>
          </div>
        </form>
        <PasswordManagment label="Contraseña" email={email} key={email} isValidForm={validateNewPassword} />

        <div className="button-wrapper flex justify-flex-end">
          <Button type="button"
            loading={isUpdating}
            disabled={!isNewPasswordValid || isUpdating}
            onClick={() => restorePassword()}
          >
            Reestablecer
          </Button>
        </div>
      </div>

    </div>
  );
};

export default PasswordRestorePage;
import { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IconFactory } from "src/factory/icon.factory";
import './Breadcrumb.scss';

interface ITrail {
  title: string;

  path: string;

  previousIndexPath?: number;
}

/**
 *
 */
interface IBreadcrumb {

  root: ITrail;

  trails: ITrail[];

}

/**
 *
 */
export const Breadcrumb = (props: IBreadcrumb): JSX.Element => {
  const location = useLocation();
  const [activeTrails, setActiveTrails] = useState<ITrail[]>([]);
  const [currentTrail, setCurrentTrail] = useState<ITrail>();
  /**
   * 
   * @param trail Recursive function to verify and concat a previous trail with the current trail.
   * @returns 
   */
  const addPaths = (trail: ITrail): ITrail[] => {
    if (trail.previousIndexPath !== undefined) {
      return [...addPaths(props.trails[trail.previousIndexPath]), ...[trail]];
    }
    return [...[trail]];
  }

  const newTrails = useMemo(
    () => {
      let list: ITrail[] = activeTrails;
      const index = activeTrails.findIndex(at => location.pathname.includes(at.path));
      list.forEach(l => {
        const propsTrail = props.trails.find(pt => pt.path.includes(l.path));
        if (propsTrail) {
          l.path = propsTrail?.path;
          l.title = propsTrail.title;
        }
      })
      if (index === -1) {
        const newTrail = props.trails.find(t => location.pathname.includes(t.path))
        if (newTrail) {
          list = [...addPaths(newTrail)];
          setActiveTrails(list);
          setCurrentTrail(list[list.length - 1]);
          return list;
        } else {
          return [];
        }
      } else {
        const newList: ITrail[] = [];
        for (let pos: number = 0; pos <= index; pos++) {
          newList.push(list[pos]);
        }
        setActiveTrails(newList);
        setCurrentTrail(newList[newList.length - 1]);
        return newList;
      }
    }, [location, props.trails] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {newTrails.length > 0 &&
        <div className="bc-wrapper">

          {
            currentTrail &&
            <h1>{currentTrail.title}</h1>
          }

          <div className="trails">
            <Link to={props.root.path}>
              <span>{props.root.title}</span>
              {
                newTrails.length > 0 &&
                <>
                  {IconFactory.doubleRightArrowIcon()}
                </>

              }
            </Link>

            {
              newTrails.map(
                (trail, index) => {
                  return (
                    <span key={trail.path}>
                      {
                        location.pathname.includes(trail.path) ?
                          <p className="inline-block trail">
                            <span>{trail.title}</span>
                          </p>
                          :
                          <Link to={trail.path} key={trail.path} className="trail">
                            <span>{trail.title}</span>
                            {
                              (index + 1) < newTrails.length &&
                              IconFactory.rightArrowIcon()
                            }
                          </Link>
                      }
                    </span>
                  )
                }
              )
            }
          </div>
        </div>

      }

    </>
  );
};
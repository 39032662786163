import Session from "../organisms/Session/Session";
import { createRef, useCallback, useEffect } from "react";
import { Logo } from "src/components";
import { HeaderFacade } from "../../controller/facades/header.facade";
import { IconFactory } from "src/factory/icon.factory";

import './Header.scss';
import { useLocation } from "react-router-dom";

/**
 *
 */
interface IHeader {

}

/**
 *
 */
const Header = (props: IHeader): JSX.Element => {
  const { pageMenu, onClickMenu } = HeaderFacade.useHeader();
  const { user  } = HeaderFacade.useCurrentUser();
  const location = useLocation();
  const headerRef = createRef<HTMLHeadingElement>();

  /**
   * 
   */
  const headerShadowController = useCallback(
    () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
      const scrolled = winScroll / height;

      if (headerRef.current) {
        if (scrolled < 0.1) {

          if (scrolled > 0) {
            const value = Math.round((scrolled * 100) / 0.1);
            headerRef.current.style.boxShadow = `0px 1px 10px rgba(204, 204, 204, ${value}%)`;
          } else {
            headerRef.current.style.boxShadow = '';
          }
        } else if (scrolled > 0.1) {
          headerRef.current.style.boxShadow = `0px 1px 10px rgb(204, 204, 204)`;
        } else {
          headerRef.current.style.boxShadow = '';
        }
      }
    }, [headerRef]
  );

  /**
   * 
   */
  useEffect(
    () => {
      window.addEventListener('scroll', headerShadowController)
    }, [headerShadowController]
  );

  useEffect(
    () => {
      headerShadowController();
    }, [location.pathname, headerShadowController]
  )

  /**
   * 
   */
  useEffect(
    () => {
      return () => window.removeEventListener('scroll', headerShadowController)
    }, [headerShadowController]
  )

  return (
    <header id="app-header" className="appHeader" ref={headerRef}>
      <div className="appHeader__content flex space-between">
        <div className="appHeader__content__logo flex">
          <span hide-logo={pageMenu ? '' : null}>
            <Logo navigateRoot/>
          </span>
          {
            pageMenu &&
            <button type="button" onClick={(e) => { e.preventDefault(); onClickMenu() }}>
              {IconFactory.menuIcon()}
            </button>
          }

        </div>

        {
          user !== undefined && <Session user={user} />
        }
      </div>
    </header>
  );
}


export default Header;
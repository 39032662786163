import { DamageClaim } from "src/classes/DamageClaim";
import { IActiveClaimsData } from "../interfaces/IActiveClaimsData";

export namespace ClaimsDataAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const claimListDataAdapter = (data: IActiveClaimsData): DamageClaim[] => {
    let claims: DamageClaim[] = [];

    if (data.damage_payments) {
      for (const claimData of data.damage_payments) {
        let newClaim = new DamageClaim(claimData.id, new Date(claimData.created_at), claimData.status, claimData.policy_number, claimData.sinister_number);
        claims.push(newClaim);
      }
    }
		
    return claims;
  }
}

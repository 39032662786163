import { Address } from "../Address";

export class Provider {

  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };

	/**
	 * 
	 */
	private _name: string;
	set name(_name: string) { this._name = _name };
	get name(): string { return this._name };

	/**
	 * 
	 */
	private _isSubProvider: boolean;
	set isSubProvider(_isSubProvider: boolean) { this._isSubProvider = _isSubProvider };
	get isSubProvider(): boolean { return this._isSubProvider };

	/**
	 * 
	 */
	private _phone: string;
	set phone(_phone: string) { this._phone = _phone };
	get phone(): string { return this._phone };

	/**
	 * 
	 */
	private _address: Address | undefined;
	set address(_address: Address | undefined) { this._address = _address };
	get address(): Address | undefined { return this._address };

  constructor () { 
		this._id = '';
    this._name = '';
		this._isSubProvider = false;
		this._phone = '';
  }
}
/**
 * 
 */
export const key = {

  TOKEN_STORAGE: 'auth_token',

	REDIRECT_ONBOARDING_STORAGE: 'return_to_onboarding',

  POLICY_CREATION_OWNER: 'is_current_user_policy',

  POLICY_NEW_VEHICLE: 'new_vehicle',

  POLICY_NEW_DRIVER: 'new_driver',

  OTP_SMS_TIME: 'otp_sms_time',

  OTP_EMAIL_TIME: 'otp_email_time',
  
  CLAIM_PICTURE_TYPE: {
    PERSONAL_INFO: 'personal_info',
    PERSON_ID: 'personal_id',
    DOCUMENT: 'circulation_document',
    VEHICLE_CARD: 'vehicle_card',
    BANK: 'bank_statement',
    DAMAGE: 'damage_image',
    RECEIPT: 'receipt_payment',
    CAR_LEGAL: 'invoice_car'
  },

  PICTURE_NAME: {
    BACK: 'back_photo',
    FRONT: 'front_photo',
    LEFT: 'left_photo',
    RIGHT: 'right_photo',
    SERIAL: 'serial_photo',
    GENERIC: 'photo',
  },
	
  SESSION_ACTION: 'last_action',

  QUERY_PARAMS: {
    POLICY_ID: 'policyId',
    PRODUCT_ID: 'productId',
    POLICY_NUMBER: 'policyNumber',
    BRAND_NAME: 'brandName',
    MODEL_NAME: 'modelName',
    YEAR: 'year',
    TOKEN:'t'
  },

	REGISTER_UTMS: {
		UTM_SOURCE: 'utm_source',
		UTM_MEDIUM: 'utm_medium',
		UTM_CAMPAIGN: 'utm_campaign',
		UTM_TERM: 'utm_term',
		UTM_CONTENT: 'utm_content',
	}
}
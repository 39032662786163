import { useState } from "react";
import { useForm } from "react-hook-form";
import { Util } from "src/utils/Util";
import { QuoterFeature } from "../features/quoter.feature";
import { IAccountDataForm } from "../interfaces/IAccountDataForm";

export namespace AccountDataFormFacade {

  /** */
  const EMAIL_ERRORS_MESSAGE = {
    REQUIRED: 'El correo electrónico es obligatorio.',
    PATTERN: 'El formato del correo electrónico es inválido.',
    INVALID: 'El correo que ingresaste ya está ligado a otra póliza. Por favor ingresa un correo diferente.'
  };
	/** */
  const EMAIL_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: EMAIL_ERRORS_MESSAGE.REQUIRED
    },
    {
      key: 'pattern',
      message: EMAIL_ERRORS_MESSAGE.PATTERN
    },
    {
      key: 'invalidEmail',
      message: EMAIL_ERRORS_MESSAGE.INVALID
    },
  ];

  /**
   * 
   * @returns 
   */
  export const usePersonDataForm = () => {
    const { register, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues } = useForm<IAccountDataForm>(
      {
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }
    );

    return {
      FORM_DATA_ERRORS: { EMAIL_ERRORS },
      formData: { register, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues }
    }
  };


	/**
   * 
   * @returns 
   */
	export const useEmailValidation = () => {
		const [isValidatingEmail, setIsValidatingEmail] = useState<boolean>(false)
		const [validatedEmail, setValidatedEmail] = useState('');
		const [previousValidation, setPreviousValidation] = useState<boolean | null>(null);

		/**
		 * 
		 * @param email 
		 * @returns 
		 */
		const validatePersonEmail = async (email: string): Promise<boolean | undefined> => {
			try {
				if (Util.PATTERN.EMAIL.test(email)) {
					if (validatedEmail !== email) {
						setIsValidatingEmail(true);
						setValidatedEmail(email);
						const validation = await QuoterFeature.validateEmailFeature(email);
						setPreviousValidation(validation)
						return validation;
					} else {
						return previousValidation as boolean;
					}

				}
			} catch (e) {

			} finally {
				setIsValidatingEmail(false);
			}
		};

		return { validatePersonEmail, isValidatingEmail }
	};
}
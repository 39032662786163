import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useInjectSaga } from "redux-injectors";
import { CoreSelector } from "src/core/selectors/core.selector";
import { MainAuthAction } from "../actions/auth.action";
import { MainCurrentUserAction } from "../actions/current-user.action";
import { MainAuthValidationSaga } from "../sagas/auth-validation.saga";
import { MainAuthSelector } from "../selectors/auth.selector";


export namespace AuthValidationFacade {
  
  /**
   * 
   */
  export const useAuthValidationFacade = () => {

    useInjectSaga({ key: 'auth', saga: MainAuthValidationSaga.authValidationSaga });
    const currentUser = useSelector(CoreSelector.currentUser);
    const location = useLocation();
    const dispatch = useDispatch();
    dispatch(MainAuthAction.authValidation());

    useEffect(
      () => {
        dispatch(MainAuthAction.authValidation());
      },
      [location, dispatch]
    );

    useEffect(
      () => {
        if (!currentUser) {
          dispatch(MainCurrentUserAction.userInfo());
        }
      },
      [dispatch, currentUser, location ]
    );

  }

  /**
   * 
   * @returns 
   */
  export const useValidAuthFacade = (): boolean => {
    const isValidAuth = useSelector(MainAuthSelector.isValidAuth);

    return isValidAuth;
  }
}

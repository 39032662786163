import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Phone } from "src/classes/Phone";
import { Profile } from "src/classes/Profile";
import { Person  } from "src/classes/Person";
import { IPersonalDataForm } from "../interfaces/IPersonalDataForm";
import { ClaimsPersonalDataFacade } from "./claims-personal-data.facade";

export namespace ClaimsPersonalDataFormFacade {

  /** 
	 * 
	 */
	const NAME_ERRORS_MESSAGE = {
    REQUIRED: 'El nombre es obligatorio.',
    INVALID: 'El nombre es inválido.'
  };
  const NAME_ERRORS: TFormErrorMessage[] = [
    { key: 'required', message: NAME_ERRORS_MESSAGE.REQUIRED },
    { key: 'pattern',  message: NAME_ERRORS_MESSAGE.INVALID },
    { key: 'minLength', message: NAME_ERRORS_MESSAGE.INVALID }
  ];

	/** 
	 * 
	 */
	const LASTNAME_ERRORS_MESSAGE = {
    REQUIRED: 'El apellido es obligatorio.',
    INVALID: 'El apellido es inválido.'
  };
	const LAST_NAME_ERRORS: TFormErrorMessage[] = [
    { key: 'required', message: LASTNAME_ERRORS_MESSAGE.REQUIRED },
    { key: 'pattern',  message: LASTNAME_ERRORS_MESSAGE.INVALID },
    { key: 'minLength', message: LASTNAME_ERRORS_MESSAGE.INVALID }
  ];

	/** 
	 * 
	 */
  const EMAIL_ERRORS_MESSAGE = {
    REQUIRED: 'El correo electrónico es obligatorio.',
    PATTERN: 'El formato del correo electrónico es inválido.',
    INVALID: 'El correo que ingresaste ya está ligado a otra póliza. Por favor ingresa un correo diferente.'
  };
  const EMAIL_ERRORS: TFormErrorMessage[] = [
    { key: 'required', message: EMAIL_ERRORS_MESSAGE.REQUIRED },
    { key: 'pattern', message: EMAIL_ERRORS_MESSAGE.PATTERN },
    { key: 'invalidEmail', message: EMAIL_ERRORS_MESSAGE.INVALID }
  ];

	/**
	 * 
	 */
	const PHONE_ERRORS_MESSAGE = {
    INVALID: 'El número de teléfono debe ser de 10 digitos.'
  };
  const PHONE_ERRORS: TFormErrorMessage[] = [
    { key: 'required', message: PHONE_ERRORS_MESSAGE.INVALID },
    { key: 'pattern', message: PHONE_ERRORS_MESSAGE.INVALID },
    { key: 'minLength', message: PHONE_ERRORS_MESSAGE.INVALID }
  ];

  /**
   * 
   * @returns 
   */
  export const useClaimsPersonalDataForm = (storedPerson?: Person) => {
    const { createUser } = ClaimsPersonalDataFacade.useCreatePersonalData();
    const { register, handleSubmit, formState: { errors, isValid, touchedFields }, trigger, watch, setValue, getValues } = useForm<IPersonalDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

		/** */
    const validateDefaultData = useCallback((storedPerson: Person): void => {
      if (storedPerson && !touchedFields.name) {
        setValue('name', storedPerson.name, {shouldValidate: true, shouldTouch: true});
      }

      if (storedPerson && !touchedFields.lastName) {
        setValue('lastName', storedPerson.lastName, {shouldValidate: true, shouldTouch: true});
      }

			if (storedPerson && !touchedFields.secondLastName) {
        setValue('secondLastName', storedPerson.secondLastName, {shouldValidate: true, shouldTouch: true});
      }

			if (storedPerson && !touchedFields.email) {
        setValue('email', storedPerson.email, {shouldValidate: true, shouldTouch: true});
      }

			if (storedPerson && !touchedFields.phone) {
        setValue('phone', storedPerson.profile?.phone?.number!, {shouldValidate: true, shouldTouch: true});
      }
      trigger();
    }, [setValue, touchedFields, trigger]);

		/**
		 * 
		 * @param personalForm 
		 */
		 const selectCountryCode = (code: string): void => {
      setValue('countryCode', code);
    }

    /**
     * 
     * @param vehicleData 
     */
    const submitFormData = (personalForm: IPersonalDataForm): void => {
			let _user: Person = new Person(personalForm.email);
			_user.profile = new Profile();
			_user.profile.phone = new Phone();

			_user.name = personalForm.name;
			_user.lastName = personalForm.lastName;
			_user.secondLastName = personalForm.secondLastName;
			_user.profile.phone.number = personalForm.phone;
			_user.profile.phone.countryCode = {
				code: personalForm.countryCode
			}

			createUser(_user);
    }
    
    useEffect(() => {
      if (storedPerson) {
        validateDefaultData(storedPerson);
      }

    }, [storedPerson, validateDefaultData])

    return { submitFormData, register, handleSubmit, selectCountryCode, formState: { errors, isValid, touchedFields}, trigger, watch, getValues };
  }

  /**
   * 
   */
  export const useClaimsPersonalDataValidation = () => {
    return { NAME_ERRORS, LAST_NAME_ERRORS, EMAIL_ERRORS, PHONE_ERRORS }
  }
}
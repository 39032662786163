import { Policy } from "src/classes/Policy";
import { SkeletonLoader } from "src/components";

import "./ClaimPolicyCardDetails.scss";

/**
 *
 */
interface IClaimPolicyCardDetails {
  /**
   *
   */
  policy?: Policy;
  /**
   *
   */
  loader?: boolean;
}

/**
 *
 */
const ClaimPolicyCardDetails = (
  props: IClaimPolicyCardDetails
): JSX.Element => {
  const { policy } = props;

  return (
    <div className="claim-policy-card-details text-left flex column height-100 justify-center">
      {!props.loader && policy ? (
        <>
          <section>
            <h1>Póliza: <span>{policy.policyNumber}</span></h1>
            <div>
              <p>
                Marca: <span>{policy.vehicle?.brand?.name}</span>
              </p>
              <p>
                Modelo: <span>{policy.vehicle?.model?.name}</span>
              </p>
              <p>
                Año: <span>{policy.vehicle?.year?.value}</span>
              </p>
              <p>
                Placas: <span>{policy.vehicle?.plate}</span>
              </p>
            </div>
          </section>
        </>
      ) : (
        <>
          <section>
            <SkeletonLoader />
          </section>
          <section>
            <SkeletonLoader />
            <SkeletonLoader />
          </section>
        </>
      )}
    </div>
  );
};

export default ClaimPolicyCardDetails;

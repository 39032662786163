import Menu from "../organisms/Menu/Menu";
import { Outlet } from 'react-router';
import { DashboardFacade } from '../../controller/facades/dashboard.facade';
import './Dashboard.scss';


/**
 *
 */
const Dashboard = (): JSX.Element => {

  DashboardFacade.useDashboard();
  DashboardFacade.useCurrentUserInfo();
  const { showMenu, toggleMenu } = DashboardFacade.useMenu();

  return (
    <div id="dashboard-wrapper" className="flex-grow-100 flex">

      <Menu showMenu={showMenu} onHideMenu={toggleMenu} />
      {/* <Sidebar showMenu={showMenu} onHideMenu={toggleMenu} /> */}

      <div className="dashboard-content flex-grow-100">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AlertFactory } from "src/factory/alert.factory";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { Util } from "src/utils/Util";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimProposalFeature } from "../features/claim-proposal.feature";
import { ClaimPaymentsFacade } from "./claim-payments.facade";
import { DamageClaimNavigateValidationFacade } from "./damage-claim-navigate-validation.facade";

export namespace ClaimProposalFacade {

  /**
   * 
   * @returns 
   */
  export const useClaimProposal = () => {
    const { currentDamageClaim, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();
    const dispatch = useDispatch();
		const [eSign, updateESign] = useState('');

    /**
     * 
     */
    const approveProposal = async (confirmation: boolean) => {
      try {
        if (confirmation && currentDamageClaim) {
          setIsLoading(true);
          await ClaimProposalFeature.approveProposalNegotationFeature(currentDamageClaim.uid, currentDamageClaim.proposal!, eSign, isAgreedPayment);
          dispatch(ClaimPaymentsAction.storeDamageClaim(currentDamageClaim));
          navigationHandler( isAgreedPayment ? AGREED_PAYMENTS_PATH.PROPOSAL : CLAIM_PAYMENTS_PATH.PROPOSAL, "forward");
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        setIsLoading(false);
      }
      
    }

    useEffect(
      () => {
        if (currentDamageClaim && currentDamageClaim.proposal?.isApproved()) {
					currentDamageClaim.status = Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.NEGOTIATING;
          navigationHandler( isAgreedPayment ? AGREED_PAYMENTS_PATH.PROPOSAL : CLAIM_PAYMENTS_PATH.PROPOSAL, "forward");
        }
      }, [currentDamageClaim, isAgreedPayment, navigationHandler]
    )

    return { currentDamageClaim, approveProposal, isLoading, eSign, updateESign }
  }

}
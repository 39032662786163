import { Link, useLocation } from "react-router-dom";
import { Policy } from "src/classes/Policy";
import { IconFactory } from "src/factory/icon.factory";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_LIST_PATH } from "src/routes/paths/claim-payments-list.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import ClaimPolicyCardDetails from "../../atoms/ClaimPolicyCardDetails/ClaimPolicyCardDetails";
import ClaimPolicyCardPicture from "../../atoms/ClaimPolicyCardPicture/ClaimPolicyCardPicture";
import "./ActivePolicyClaimCard.scss";

/**
 *
 */
interface IActivePolicyClaimCard {
  /**
   *
   */
  policy?: Policy;
  /**
   *
   */
  loader?: boolean;
}

/**
 *
 */
const ActivePolicyClaimCard = (props: IActivePolicyClaimCard): JSX.Element => {
  const { policy } = props;
	const location = useLocation();

	/** */
	const claimsRoute = (): string => {
		if (location.pathname.includes(CLAIM_PAYMENTS_LIST_PATH.CLAIM_PAYMENTS_LIST)) {
			return CLAIM_PAYMENTS_PATH.POLICY_DATA;
		} else {
			return AGREED_PAYMENTS_PATH.POLICY_DATA;
		}
	}

  return (
    <Link
      to={`${claimsRoute()}${
        policy?.policyNumber ? "?policy-number=" + policy.policyNumber : ""
      }`}
      className="active-policy-claim-card-wraper inline-flex vertical-align-middle"
      is-loader={props.loader ? "" : null}
    >
      <section>
        <ClaimPolicyCardPicture policy={policy!} loader={props.loader} />
      </section>
      <section>
        <ClaimPolicyCardDetails policy={policy!} loader={props.loader} />
      </section>

      <section>
        <div className="card-arrow flex height-100 align-center justify-flex-end">
          {IconFactory.rightArrowIcon()}
        </div>
      </section>
    </Link>
  );
};

export default ActivePolicyClaimCard;

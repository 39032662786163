import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { IRewardsHistoryRequest } from './../interfaces/IRewardsHistoryRequest';
import { Reward } from "src/classes/referrals/Reward";
import { ReferralsAction } from "../actions/referrals.action";
import { ReferralsFeature } from "../features/referrals.feature";
import { ReferralsSlice } from "../slices/referrals.slice";
import { CoreUserInfoFeature } from "src/core/features/user-info.feature";
import { Person  } from "src/classes/Person";
import { CoreInstanceSlice } from "src/core/slices/instance.slice";

/**
 *
 */
export namespace ReferralsSaga {

  type Rewards = SagaReturnType<typeof ReferralsFeature.rewardsFeature>;
  type RewardsHistory = SagaReturnType<typeof ReferralsFeature.rewardsHistoryFeature>;
  type CurrentUser = SagaReturnType<typeof CoreUserInfoFeature.getUserInfoFeature>;

  function* getRewardsList() {
    try {
      const list: Rewards = yield call(ReferralsFeature.rewardsFeature);
      yield put(ReferralsSlice.actions.rewardsList(list as Reward[]));

      const user: CurrentUser = yield call(CoreUserInfoFeature.getUserInfoFeature);
      yield put(CoreInstanceSlice.actions.currentUser(user as Person));

    } catch (e) {

    }
  }

  function* getRewardsHistoryList(params: PayloadAction<IRewardsHistoryRequest>) {
    try {
      yield put(ReferralsSlice.actions.rewardsLoading(true))

      const list: RewardsHistory = yield call(ReferralsFeature.rewardsHistoryFeature, params.payload);
      yield put(ReferralsSlice.actions.rewardsHistoryList(list as Reward[]));

      const offset: number = (list) ? params.payload.offset + list.length : -1;
      if (list && list.length < params.payload.limit) yield put(ReferralsSlice.actions.rewardsHistoryListOffset(offset))
      else yield put(ReferralsSlice.actions.rewardsHistoryListOffset(-1))

    } catch (e) {

    } finally {
      yield put(ReferralsSlice.actions.rewardsLoading(false));
    }
  }

  export function* referralsSaga() {
    yield takeLatest(ReferralsAction.rewardsList.type, getRewardsList);
    yield takeLatest(ReferralsAction.rewardsHistoryList.type, getRewardsHistoryList);
  }
}
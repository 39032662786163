
const BASE_URL: string = '/agreed-claim-payments';

/**
 * 
 */
 export const AGREED_PAYMENTS_LIST_PATH = {

  AGREED_PAYMENTS_LIST: BASE_URL,
  ACTIVE_POLICIES: `${BASE_URL}/active-policies`,
  OPEN_CLAIMS: `${BASE_URL}/open-claims`,
}
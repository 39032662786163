import './ErrorMessage.scss';

/**
 *
 */
interface IErrorMessage {

  message?: string;
}

/**
 *
 */
export const ErrorMessage = (props: IErrorMessage): JSX.Element => {
  return (
    <p className="error-message">
      {props.message}
    </p>
  );
};
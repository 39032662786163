import { IconFactory } from "src/factory/icon.factory";
import { CommonFacade } from "src/modules/common/controller/facades/common.facade";
import './EndorsementIdentityCompleted.scss';

/**
 *
 */
const EndorsementIdentityCompletedPage = (): JSX.Element => {
  CommonFacade.useRedirect('/');
  
  return (
    <div id="endorsement-identity-completed-wrapper" className="flex align-center justify-center">
      <div>
        <div className="relative">
          <span className="identity-completed-background block" style={{ backgroundImage: `url(https://crabi-assets.s3.us-east-2.amazonaws.com/endorsements/truora-identity-validation.png)` }}></span>
          <span className="identity-completed-icon absolute flex align-center justify-center">
            {IconFactory.checkIcon()}
          </span>
        </div>
        <h1 className="text-center">Muchas gracias</h1>
        <h2 className="text-center">Tu validaci&oacute;n de documentos ha culminado.</h2>
        <p className="text-center">Ya puedes cerrar esta ventana y volver a tu sesi&oacute;n de Crabi.</p>
      </div>
    </div>
  );
};

export default EndorsementIdentityCompletedPage;
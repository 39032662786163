import { AxiosResponse } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";


export namespace LoginService {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const sendEmailValidationService = (token: string): Promise<AxiosResponse<void>> => {
    return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.SEND_EMAIL_VALIDATION, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }
}

import { PropsWithChildren } from "react";
import { Spinner } from "src/components";

import './PolicyDocumentFile.scss';

/**
 *
 */
interface IPolicyDocumentFile {

  
  onClickDocument: () => void;
  
  loading?: boolean;

  last?: boolean;

  primary?: boolean;

}

/**
 *
 */
const PolicyDocumentFile = (props: PropsWithChildren<IPolicyDocumentFile>): JSX.Element => {

  return (
    <button type="button" disabled={props.loading} className="policy-document-file-wrapper flex align-center space-between" onClick={(e) => { props.onClickDocument(); e.preventDefault() }}
    primary-file={props.primary? '' : null}
    last-document={props.last? '' : null}>
      {
        !props.loading ?
          <>
            {props.children}
          </>
          :
          <Spinner relative />
      }
    </button>
  );
};

export default PolicyDocumentFile;
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useState } from "react";
import { PaymentCost } from "src/classes/PaymentCost";
import { Plan } from "src/classes/plan/Plan";
import { PlanCheckout } from "src/classes/plan/PlanCheckout";
import { Policy } from "src/classes/Policy";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { AlertFactory } from "src/factory/alert.factory";
import { Util } from "src/utils/Util";

/**
 * 
 */
export namespace PlanPaymentManagementFacade {

	/** */
	const PAYMENT_TYPE = {
		DOWN: 'down',
		MONTHLY: 'monthly',
		INSTALLMENTS: 'installments'
	};



	/**
	 * 
	 */
	export const usePlanManagementPayment = (plan: Plan, policy: Policy | undefined, onPaymentDone: (policy: Policy) => void) => {

		/** if true: isCredit; otherwise if false: isDebit */
		const [isCredit, setIsCredit] = useState<boolean>(true);
		const [isMonthly, setIsMonthly] = useState<boolean>(false);
		const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
		const [showPicturesPrompt, setShowPicturesPrompt] = useState<boolean>(false);
		const [stripe, setStripe] = useState<Stripe | undefined>();
		const [stripeElement, setStripeElement] = useState<StripeElements | undefined>();
		const [selectedPlanType, setSelectedPlanType] = useState<string>(
			() => {
				let typeName: string = plan.selectedPlanPayment.type;
				return typeName;
			}
		);
		const [planCheckout, setPlanCheckout] = useState<PlanCheckout>(() => {
			let checkout: PlanCheckout = new PlanCheckout();
			checkout.paymentId = plan.selectedPlanPayment.id;
			return checkout;
		});

		const [paymentCost, setPaymentCost] = useState<PaymentCost>(() => {
			let cost: PaymentCost = new PaymentCost();
			if (plan) {
				if (plan.discount) {
					cost = plan.discount.cost;
				} else {
					cost = plan.cost;
				}
			}
			return cost;
		});

		const [isCardCompleted, setIsCardCompleted] = useState<boolean>(false);
		const [isCompletingCheckout, setIsCompletingCheckout] = useState<boolean>(false);
		const [showRenewalModal, setShowRenewalModal] = useState<boolean>(false);
		const [isAutomaticRenewal, setIsAutomaticRenewal] = useState<boolean>();

		/** */
		const onPaymentChange = (type?: 'down' | 'monthly' | 'installments', months?: number) => {
			if (type === undefined)
				return;
			let paymentId: string = '';
			let paymentCost: PaymentCost = new PaymentCost();

			setIsMonthly(type === PAYMENT_TYPE.MONTHLY || type === PAYMENT_TYPE.INSTALLMENTS);

			if (type === PAYMENT_TYPE.DOWN) {
				paymentCost = plan.discount ? plan.discount.cost : plan.cost;

				for (const payment of plan.payments) {
					if (payment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) {
						paymentId = payment.id;
					}
				}
				setSelectedPlanType(Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL);
				setCheckoutPaymentId(paymentId, paymentCost, false);
			} else if (type === PAYMENT_TYPE.MONTHLY) {

				paymentCost = plan.subscription.firstCost;

				for (const payment of plan.payments) {
					if (payment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) {
						paymentId = payment.id;
					}
				}
				setSelectedPlanType(Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION);
				setCheckoutPaymentId(paymentId, paymentCost, false);

			} else if (type === PAYMENT_TYPE.INSTALLMENTS && months !== undefined) {
				if (months > 0) {
					if (plan.discount) {
						paymentCost = plan.discount.monthly.costs.find(p => p.monthsQuantity === months)!
					} else {
						paymentCost = plan.monthly.costs.find(p => p.monthsQuantity === months)!;
					}

					for (const payment of plan.payments) {
						if (payment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && payment.paymentsCount === months) {
							paymentId = payment.id;
						}

					}
				}
				setSelectedPlanType(Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS);
				setCheckoutPaymentId(paymentId, paymentCost, true);
			}

		};

		/** */
		const getTotal = (): string => {
			if (isMonthly) {
				if (isCredit) {
					return paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0);
				} else {
					return plan.discount ? plan.discount.subscription.getTotal() : plan.subscription.getTotal();
				}
			} else {
				return paymentCost.getTotal();
			}
		};

		/** */
		const getTotalByMonth = (): string => {
			if (isCredit) {
				return paymentCost.monthsQuantity > 1 ? paymentCost.getAmountByMonth() : Util.TRANSFORM.CURRENCY.MXN(0);
			} else {
				return plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal();
			}
		};

		/** */
		const getNetAmount = (): string => {
			if (!isCredit && isMonthly) {
				return plan.discount ? plan.discount.subscription.cost.getNetAmount() : plan.subscription.cost.getNetAmount();
			} else {
				return paymentCost.getNetAmount();
			}
		}

		const getMonthFeeAmount = (): string => {
			if (isCredit) {
				return paymentCost.monthsQuantity > 1 ? paymentCost.getMonthFeeAmount() : Util.TRANSFORM.CURRENCY.MXN(0)
			} else {
				return plan.discount ? plan.discount.subscription.cost.getMonthFeeAmount() : plan.subscription.cost.getMonthFeeAmount();
			}
		}

		const getSubTotal = (): string => {
			if (paymentCost.monthsQuantity <= 1 && isMonthly && isCredit) {
				return Util.TRANSFORM.CURRENCY.MXN(0);
			} else if (!isCredit && isMonthly) {
				return plan.discount ? plan.discount.subscription.cost.getSubTotal() : plan.subscription.cost.getSubTotal();
			} else {
				return paymentCost.getSubTotal();
			}
		}

		const getTaxes = (): string => {
			if (paymentCost.monthsQuantity <= 1 && isMonthly && isCredit) {
				return Util.TRANSFORM.CURRENCY.MXN(0);
			} else if (!isCredit && isMonthly) {
				return plan.discount ? plan.discount.subscription.cost.getTaxes() : plan.subscription.cost.getTaxes();
			} else {
				return paymentCost.getTaxes();
			}
		}

		/**
		 * @param id 
		 */
		const setCheckoutPaymentId = (id: string, cost: PaymentCost, isMonthly: boolean): void => {
			planCheckout.paymentId = id;
			planCheckout.cost = cost;
			planCheckout.isMonthly = isMonthly;
			setPaymentCost(cost);
			setPlanCheckout(planCheckout);
		}

		/**
		 * @param name 
		 */
		const setCheckoutOwner = (name: string): void => {
			planCheckout.ownerName = name.trim();
			setPlanCheckout(planCheckout);
		}

		/**
		 * 
		 * @param type 
		 */
		const setCheckoutCard = (isCompleted: boolean, stripe?: Stripe, elements?: StripeElements, type?: string): void => {
			setIsCardCompleted(isCompleted);
			if (isCompleted) {
				planCheckout.cardType = type!;
				setStripe(stripe);
				setStripeElement(elements);
			} else {
				planCheckout.cardType = '';
			}
			setPlanCheckout(planCheckout);
		}

		/**
		 * 
		 */
		const initCheckout = async (): Promise<void> => {
			try {
				if (showPicturesPrompt) {
					setShowPicturesPrompt(false);
				}

				setIsCompletingCheckout(true);
				const newPolicy = await CorePolicyFeature.policyPaymentFeature(planCheckout, { stripe: stripe!, elements: stripeElement! });

				if (isAutomaticRenewal !== undefined && policy) {
					await CorePolicyFeature.automaticRenewalFeature(policy, isAutomaticRenewal);
				}
				onPaymentDone(newPolicy!);
			} catch (e) {
				AlertFactory.errorAlert((e as Error).message);
			} finally {
				setIsCompletingCheckout(false);
			}

		}

		return {
			selectedPlanType,
			isCredit, setIsCredit, isMonthly, onPaymentChange, getTotal, getTotalByMonth, getNetAmount, getMonthFeeAmount, getSubTotal, getTaxes, showDetailsModal, setShowDetailsModal,
			paymentCost, isCardCompleted, isCompletingCheckout, initCheckout, setCheckoutPaymentId, setCheckoutOwner, setCheckoutCard, showPicturesPrompt, setIsCardCompleted, setShowPicturesPrompt,
			showRenewalModal, setShowRenewalModal, isAutomaticRenewal, setIsAutomaticRenewal
		}
	}

}
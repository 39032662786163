import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";

/**
 *
 */
export namespace InsuranceVehicleAction {

  export const picturesCompleted: ActionCreatorWithPayload<boolean> = createAction('INSURANCE_VEHICLE_PICTURES_COMPLETED'); 

  export const clear: ActionCreatorWithoutPayload = createAction('CLEAR_INSURANCE_VEHICLE');
}
import PictureManagement from 'src/modules/claim-payments/ui/organisms/PictureManagement/PictureManagement';
import { DamageClaimNavigateValidationFacade } from 'src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade';
import { VehicleBillFacade } from 'src/modules/claim-payments/controller/facades/vehicle-bill.facade';
import ButtonSection from '../../molecules/ButtonSection/ButtonSection';
import './VehicleBillPage.scss';

/**
 *
 */
interface IVehicleBill {

}

/**
 *
 */
const VehicleBillPage = (props: IVehicleBill): JSX.Element => {
	
	/** */
	const { currentDamageClaim, picturesValidation, CLAIM_CURRENT_PATH, AGREED_CLAIM_PATH, isAgreedPayment, validatePictures } = VehicleBillFacade.useVehicleBillFacade();
	const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id='vehicle-bill-wrapper' className='flex-grow-100 flex'>
			<section className='content height-100'>
				<h1>{`Solicitud pago ${ isAgreedPayment ? 'pactado' : 'de daño' }`}</h1>
				<h3>Factura del vehículo</h3>
				<p>¡Ojo 👀 Debe estar a nombre de quien tramita el pago {`${ isAgreedPayment ? 'pactado' : 'de daños'}`}!</p>

				{
					currentDamageClaim &&
						<PictureManagement 
							attachment={currentDamageClaim.attachmentBill} 
							defaultPicture={currentDamageClaim.attachmentBill.thumbnail} 
							status
							onUpload={(a) => {currentDamageClaim.attachmentBill = a; validatePictures()}}
							allowPdf
						>
							{currentDamageClaim.attachmentBill.title}
						</PictureManagement>
				}
			</section>

			<ButtonSection 
				backHandler={() => {navigationHandler(isAgreedPayment ? AGREED_CLAIM_PATH : CLAIM_CURRENT_PATH, 'backward')}} 
				nextHandler={() => {navigationHandler(isAgreedPayment ? AGREED_CLAIM_PATH : CLAIM_CURRENT_PATH, 'forward')}} 
				skipHandler={() => navigationHandler(isAgreedPayment ? AGREED_CLAIM_PATH : CLAIM_CURRENT_PATH, 'forward')}
				nextButtonDisabled={!picturesValidation}
				hasSkip={true}
			/>
    </div>
  );
};

export default VehicleBillPage;
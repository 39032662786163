import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects'
import { CoreVehicleStoreFeature } from 'src/core/features/vehicle-storage.feature';
import { PolicyVehicleDataAction } from '../actions/policy-vehicle-data.action';
import { PolicyVehicleDataSlice } from '../slices/policy-vehicle-data.slice';

/**
 *
 */
export namespace PolicyVehicleDataSaga {

  type StoredVehicle = SagaReturnType<typeof CoreVehicleStoreFeature.getStoredVehicleFeature>;

  /**
   * 
   */
  function* getVehicleStored() {
    const vehicle: StoredVehicle = yield call(CoreVehicleStoreFeature.getStoredVehicleFeature);
    if (vehicle) {
      yield put(PolicyVehicleDataSlice.actions.vehicleStored(vehicle));
    }
  }


  export function* vehicleDataSaga() {
    yield takeLatest(PolicyVehicleDataAction.getVehicleStored.type, getVehicleStored)
  }
}
import { AxiosPromise } from "axios"
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IPictureTokenData } from "../interfaces/IPictureTokenData";

export namespace PolicyPicturesService {

  /**
   * 
   * @returns 
   */
  export const policyPicturesTokenService = (): AxiosPromise<IPictureTokenData> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.PICTURES_TOKEN);
  }

}
import { useEffect, useState } from "react"
import { ClaimRegisterPicture } from "src/classes/claims/ClaimRegisterPicture";

export namespace ClaimPicturesFacade {

  /**
   * 
   * @returns 
   */
  export const useClaimPictures = (length?: number, defaultPictures?: ClaimRegisterPicture[]) => {
    const [isValidPictures, setIsValidPictures] = useState<boolean>(false);
    const [pictures, setPictures] = useState<string[]>(
      () => {
        let values: string[];
        if (length) {
          values = new Array(length);
          return values;
        } else {
          values = [];
          values.push('');
          return values;
        }
      }
    );

    /**
     * 
     * @param picture 
     * @param index 
     */
    const addPicture = (picture: string, pictureIndex: number): void => {
      setPictures(
        (defaultValue) => {
          let newValues: string[] = [];
          defaultValue.forEach(
            (item, index) => {
              newValues.push(item);
              if (index === pictureIndex) {
                newValues[index] = picture;
              }
            }
          );
          return newValues;
        }
      );
    }

		/**
		 * 
		 * @param pictureIndex 
		 */
		const removePicture = (pictureIndex: number): void => {
			const updatedPictures = pictures.filter((_, index) => index !== pictureIndex);
			setPictures(updatedPictures);
		}

    /**
     * 
     */
    const newPicture = (): void => {
      setPictures((defaultValue) => [...defaultValue, ''])
    }

    /**
     * 
     * @param pictures 
     */
    const setDefaulPictures = (pictures: string[]): void => {
      setPictures(
        () => {
          let newValues: string[] = [];
          pictures.forEach(
            (item) => {
              newValues.push(item);
            }
          );
          return newValues;
        }
      );
    }

    /**
     * 
     * @param index 
     * @param picture 
     */
    const setPictureValue = (index: number, picture: string): void => {
      pictures[index] = picture;
      const item = pictures.find(p => p === '');
      setIsValidPictures(item === undefined ? true : false);
    }

    /**
     * 
     */
    useEffect(
      () => {
        const item = pictures.find(p => p === '');
        setIsValidPictures(item === undefined ? true : false);
      }, [pictures]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (defaultPictures) {
          let newValues: string[] = [];
          for (const picture of defaultPictures) {
            newValues.push(picture.url);
            setPictures(newValues);
          }
        }
      }, [defaultPictures]
    )

    return { pictures, newPicture, addPicture, isValidPictures, removePicture, setDefaulPictures, setPictureValue }
  }
}
import { DamageClaimNavigateValidationFacade } from 'src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade';
import { VehiclePicturesFacade } from 'src/modules/claim-payments/controller/facades/vehicle-pictures.facade';
import PictureManagement from "src/modules/claim-payments/ui/organisms/PictureManagement/PictureManagement";
import ButtonSection from '../../molecules/ButtonSection/ButtonSection';
import './VehiclePicturesPage.scss';

/**
 *
 */
const VehiclePicturesPage = (): JSX.Element => {

  const { currentDamageClaim, picturesValidation, validatePictures, CURRENT_PATH } = VehiclePicturesFacade.useVehiclePicturesFacade();
  const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id="vehicle-pictures-wrapper" className="flex-grow-100 flex">
      <section className="content flex column align-center justify-center height-100">
        <h1>Solicitud pago de daño</h1>
        <h3>Fotos del vehículo</h3>
        <p><b>Necesitamos fotografías del estado de tu unidad para valorar los daños.</b></p>
        {/* <p><b>IMPORTANTE:</b> Las fotografías deben ser tomadas al momento de llenar esta solicitud, NO antes.</p> */}

        <div className="vehicle-pictures flex justify-center">
          {
            currentDamageClaim?.attachmentVehicle.map(
              (attachment, index) => {

                return <div className="picture-item" key={index}>
                  <PictureManagement status
                    onUpload={(a) => { attachment = a; validatePictures() }}
                    attachment={attachment}
                    defaultPicture={attachment.thumbnail} >
                    {attachment.title}
                  </PictureManagement>
                </div>
              }
            )
          }
        </div>

        <h3>Fotos del daño</h3>

        <div className="vehicle-pictures flex justify-center">
          {
            currentDamageClaim?.attachmentDamage.map(
              (attachment, index) => {

                return <div className="picture-item" key={index}>
                  <PictureManagement status
                    onUpload={(a) => { attachment = a; validatePictures() }}
                    attachment={attachment}
                    defaultPicture={attachment.thumbnail} 
									>
                    {attachment.title}
                  </PictureManagement>
                </div>
              }
            )
          }
        </div>
      </section>

      <ButtonSection
        backHandler={() => navigationHandler(CURRENT_PATH, 'backward')}
				nextHandler={() => navigationHandler(CURRENT_PATH, 'forward')}
				skipHandler={() => navigationHandler(CURRENT_PATH, 'forward')}
        nextButtonDisabled={!picturesValidation}
				//nextButtonDisabled={!formState.isValid}
				hasSkip={true}
      />
    </div>
  );
};

export default VehiclePicturesPage;
import { AuthToken } from "src/classes/AuthToken";
import { IAuthTokenData } from "../interfaces/auth/IAuthTokenData";


export namespace CoreTokenDataAdapter {
  /**
   * 
   * @param data 
   * @returns 
   */
  export const authTokenDataAdapter = (data: IAuthTokenData): AuthToken => {
    let authToken: AuthToken = new AuthToken(data.access_token.token, data.refresh_token.token);

    return authToken;
  }
}

import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { Plan } from "src/classes/plan/Plan";
import { Policy } from "src/classes/Policy";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";

/**
 *
 */
export namespace PolicyCreationAction {

  /**
   * 
   */
  export const currentDriver: ActionCreatorWithPayload<Person> = createAction('NEW_DRIVER');
  /**
   * 
   */
  export const currentVehicle: ActionCreatorWithPayload<Vehicle> = createAction('NEW_VEHICLE');
  /**
   * 
   */
  export const currentPlan: ActionCreatorWithPayload<Plan> = createAction('NEW_PLAN');
	/**
	 * 
	 */
	export const currentPolicy: ActionCreatorWithPayload<Policy> = createAction('SET_CURRENT_POLICY');
	/**
	 * 
	 */
	export const setVehiclePostalCode: ActionCreatorWithPayload<string> = createAction('SET_VEHICLE_POSTAL_CODE');
  /**
   * 
   */
  export const completeReview: ActionCreatorWithoutPayload = createAction('PLAN_REVIEW');
  /**
   * 
   */
  export const clearManagement: ActionCreatorWithoutPayload = createAction('POLICY_CLEAR_MANAGMENT');
  /**
   * 
   */
  export const clearQuotationVehicle: ActionCreatorWithoutPayload = createAction('POLICY_CLEAR_QUOTATION_VEHICLE');
  /**
   * 
   */
  export const stepBackToDriver: ActionCreatorWithoutPayload = createAction('STEP_BACK_DRIVER');
  /**
   * 
   */
  export const stepBackToVehicle: ActionCreatorWithoutPayload = createAction('STEP_BACK_VEHCILE');
  /**
   * 
   */
  export const stepBackToPlan: ActionCreatorWithoutPayload = createAction('STEP_BACK_PLAN');
  /**
   * 
   */
  export const stepBackToReview: ActionCreatorWithoutPayload = createAction('STEP_BACK_REVIEW');
}
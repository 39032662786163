import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import './ClaimPaymentSidebarItem.scss';

/**
 *
 */
interface IClaimPaymentSidebarItem {
  selected: boolean;
}

/**
 *
 */
const ClaimPaymentSidebarItem = (props: PropsWithChildren<IClaimPaymentSidebarItem>) : JSX.Element => {

	/** */
  const indicatorClassNames = classNames({
    'claim-payment-sidebar-text': true,
    'selected': props.selected
  });

  return (
    <span className={indicatorClassNames}>
      {props.children}
    </span>
  );
};

export default ClaimPaymentSidebarItem;
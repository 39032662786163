import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { Util } from "src/utils/Util";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFacade } from "./claim-payments.facade";

const CLAIM_CURRENT_PATH: string = CLAIM_PAYMENTS_PATH.COMPLETION;
const AGREED_CURRENT_PATH: string = AGREED_PAYMENTS_PATH.COMPLETION;

/**
 *
 */
export namespace CompletionFacade {

  /** */
  export const useCompletionFacade = () => {
    const [isCompleted, updateIsCompleted] = useState<boolean>(false);
    const [isProposalApproved, setIsProposalApproved] = useState<boolean>(false);
		const [isPaymentPending, updateIsPaymentPending] = useState<boolean>(false);
		const [isPaymentDone, updateIsPaymentDone] = useState<boolean>(false);
    const { currentDamageClaim, dataStep, picturesStep, documentsStep, accountStep, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
		const dispatch = useDispatch();

    /** */
    const checkCompletion = useCallback(
      () => {
        const isProposal = searchParams.get('proposal');
        if (!isProposal) {
					if(currentDamageClaim?.status === Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.PENDING_PAYMENT){
						updateIsPaymentPending(true);
						return;
					}else if(currentDamageClaim?.status === Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.PAID){
						updateIsPaymentDone(true);
						return;
					}

					if ((isAgreedPayment && isAgreedPayment === true && dataStep === 1 && documentsStep === 1 && accountStep === 1) || (dataStep === 1 && picturesStep === 1 && documentsStep === 1 && accountStep === 1)){
						updateIsCompleted(true);
					}
        } else {
          setIsProposalApproved(true);
        }
      }, [dataStep, picturesStep, documentsStep, accountStep, searchParams, currentDamageClaim, isAgreedPayment, updateIsPaymentPending]
    );

		/** */
    useEffect(() => {
      checkCompletion();
    }, [currentDamageClaim, checkCompletion, searchParams]);

		/** */
		useEffect(() => {
			dispatch(ClaimPaymentsAction.updateCurrentPath( isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH ));
		}, [dispatch, isAgreedPayment]);

    return { isCompleted, isProposalApproved, isPaymentPending, isPaymentDone, currentDamageClaim, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, isAgreedPayment };
  };

}
import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import './InputDate.scss';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Util } from 'src/utils/Util';
import { Label } from "src/components";

/**
 *
 */
interface IInputDate {

  validateConsent?: boolean;
  /**
   * 
   */
  errors?: boolean;
  /**
   * 
   */
  required?: boolean;
  /**
   * 
   */
  minDate?: Date,

  /**
   * 
   */
  Icon?: JSX.Element;

  /**
   * 
   */
  position?: 'bottom' | 'top';

  /**
   * 
   */
  onSelectDate?: (newDate: Date) => void;
}

/**
 *
 */
export const InputDate = React.forwardRef(
  (
    props: IInputDate & PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>>,
    ref: React.Ref<any>
  ):
    JSX.Element => {

    const {
      errors = false,
      minDate,
      children,
      onSelectDate,
      defaultValue,
      validateConsent,
      position = 'bottom',
      ...rest
    } = props;

    const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
    const [date, setDate] = useState<Date>(
      () => {
        const date = new Date();
        return validateConsent ? new Date(date.getFullYear() - 16, date.getMonth(), date.getDate()) : date;
      }
    );
    const [selectedDate, setSelectedDate] = useState<string>('');


    const inputWrapperClassNames = classNames({
      'datepicker-wrapper': true,
      'input-label-wrapper': true,
      'invalid': errors ? true : false,
      'flex': true,
      'align-center': true,
      'relative': true,
      'calendar-active': displayCalendar
    })

    const inputClassNames = classNames({
      'height-100': true,
      'width-100': true,
      'with-icon': !!props.Icon,
      'border-box': true,
    })

    /**
     * 
     * @param newDate 
     */
    const onDateChange = (newDate: Date): void => {
      setDate(newDate);
      setDisplayCalendar(false);
      setSelectedDate(Util.TRANSFORM.DATE.formatDate(newDate));
      if (onSelectDate) {
        onSelectDate(newDate);
      }
    }

    const validateDateConsent = (): Date | undefined => {
      if (validateConsent) {
        const d = new Date();
        return new Date(d.getFullYear() - 16, d.getMonth(), d.getDate());
      }
    }


    // Detect when click component
    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const closeCalendar = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
          setDisplayCalendar(false);
        } else {
          setDisplayCalendar(true);
        }
      }

      document.addEventListener("mousedown", closeCalendar);
      return () => {
        document.removeEventListener("mousedown", closeCalendar);
      };
    }, [wrapperRef]);
    // end Detection

    useEffect(
      () => {
        if (defaultValue !== '') {
          const newDate = new Date(defaultValue as string);
          setDate(newDate);
          setSelectedDate(Util.TRANSFORM.DATE.formatDate(newDate));
        }
      }, [defaultValue]
    );

    return (
      <div className={inputWrapperClassNames} ref={wrapperRef}>
        <Label required={props.required} className="absolute">{children}</Label>
        <input className={inputClassNames} {...rest} ref={ref} defaultValue={selectedDate} tabIndex={-1}></input>
        {displayCalendar &&
          <Calendar className={`calendar-wrapper absolute ${position}`}
            maxDate={validateDateConsent()}
            minDate={minDate}
            date={date}
            onChange={onDateChange} />
        }
      </div>
    );
  });

import { DamageClaim } from 'src/classes/DamageClaim';
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { SkeletonLoader } from "src/components";
import { Util } from "src/utils/Util";

import './ClaimPaymentPolicyCard.scss';

/**
 *
 */
interface IClaimPaymentPolicyCard {
	/** */
	damageClaim?: DamageClaim;
	/** */
	vehicle?: Vehicle;
	/** */
	loader?: boolean
}

/**
 *
 */
const ClaimPaymentPolicyCard = (props: IClaimPaymentPolicyCard): JSX.Element => {

	const { damageClaim, vehicle } = props;

  return (
    <div className='claim-payment-policy-card'>
			{
				!props.loader && damageClaim && vehicle ?
					(
						<>
							<div className='picture-wrapper'>
								{
									<div className='no-picture'>
										<img alt="no-vehicle" src={Util.ASSET.EMPTY_CAR_PICTURE} />
									</div>
								}
							</div>

							<div className='info-wrapper'>
								<h1 className='policy-number'>N. Póliza: {damageClaim.policyNumber}</h1>
								<p>Marca: <span>{vehicle.brand?.name}</span></p>
								<p>Modelo: <span>{vehicle.model?.name}</span></p>
								<p>Año: <span>{vehicle.year?.value}</span></p>
								<p>Placas: <span>{vehicle.plate!}</span></p>
							</div>
						</>
					) : (
						<>
							<div className='picture-wrapper'>
								<SkeletonLoader />
							</div>
							<div className='info-wrapper'>
								<SkeletonLoader />
								<SkeletonLoader />
							</div>
						</>
					)
			}


    </div>
  );
};

export default ClaimPaymentPolicyCard;
import { CLAIM_REGISTER_PATH } from "src/routes/paths/claim-register.paths";
import ClaimRegisterPage from '../ui/pages/ClaimRegister';
import ClaimPersonPage from "../ui/pages/ClaimPerson/ClaimPerson";
import ClaimFormDisabledPage from "../ui/pages/ClaimFormDisabled/ClaimFormDisabled";
import ClaimPersonManagementPage from "../ui/pages/ClaimPersonManagement/ClaimPersonManagement";
import ClaimAdjusterPage from "../ui/pages/ClaimAdjuster/ClaimAdjuster";
import ClaimPersonGeneralPage from "../ui/pages/ClaimPersonGeneral/ClaimPersonGeneral";
import ClaimPersonVehiclePage from "../ui/pages/ClaimPersonVehicle/ClaimPersonVehicle";
import ClaimPersonDamagePage from "../ui/pages/ClaimPersonDamage/ClaimPersonDamage";
import ClaimPersonServicePage from "../ui/pages/ClaimPersonService/ClaimPersonService";

/**
 *
 */
export const ClaimRegisterRoutes = (/* Auth validation and redirect params if needed */) => {
  return {
    path: CLAIM_REGISTER_PATH.CLAIMS,
    element: <ClaimRegisterPage />,
    children: [

      { path: `${CLAIM_REGISTER_PATH.FORMS}/:formType/:formId/:key`, element: <ClaimPersonPage /> },
      { path: `${CLAIM_REGISTER_PATH.FORMS}/:formType/:pageName/:formId/:key`, element: <ClaimPersonPage /> },
			{ path: `${CLAIM_REGISTER_PATH.ADJUSTER}/:formId/:key`, element: <ClaimAdjusterPage />},
      { path: `${CLAIM_REGISTER_PATH.ADJUSTER}/person/:pageName`, element: <ClaimPersonManagementPage /> },
      { path: `${CLAIM_REGISTER_PATH.ADJUSTER}/:formType/:pageName`, element: <ClaimPersonManagementPage /> },
      { path: `${CLAIM_REGISTER_PATH.FORM_DISABLED}`, element: <ClaimFormDisabledPage /> },
			{
        path: `${CLAIM_REGISTER_PATH.ADJUSTER}/:sectionName/:formId/:key`,
        element: <ClaimAdjusterPage />,
        children: [
					{ 
						path: `${CLAIM_REGISTER_PATH.ADJUSTER}/:sectionName/:formId/:key/person/:pageName`, 
						element: <ClaimPersonManagementPage/>,
						children: [
							{ path: `${CLAIM_REGISTER_PATH.ADJUSTER}/:sectionName/:formId/:key/person/:pageName/general/:editPage`, element: <ClaimPersonGeneralPage /> },
							{ path: `${CLAIM_REGISTER_PATH.ADJUSTER}/:sectionName/:formId/:key/person/:pageName/vehicle/:editPage`, element: <ClaimPersonVehiclePage /> },
							{ path: `${CLAIM_REGISTER_PATH.ADJUSTER}/:sectionName/:formId/:key/person/:pageName/damage/:editPage`, element: <ClaimPersonDamagePage /> },
							{ path: `${CLAIM_REGISTER_PATH.ADJUSTER}/:sectionName/:formId/:key/person/:pageName/service/:editPage`, element: <ClaimPersonServicePage /> },
						]
					},
        ]
      },
    ]
  }
}
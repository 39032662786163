import { Text } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { UseFormReturn } from "react-hook-form";
import { IClaimPersonForm } from "src/modules/claim-register/controller/interfaces/IClaimPersonForm";
import { Address } from "src/classes/Address";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { Damage } from "src/classes/claims/Damage";
import { Util } from "src/utils/Util";
import { ClaimPersonSummaryFacade } from "src/modules/claim-register/controller/facades/claim-person-summary.facade";
import { CLAIM_REGISTER_PERSON_PARAMS } from "src/routes/paths/claim-register.paths";
import './ClaimSummary.scss';
import { Vehicle } from "src/classes/vehicle/Vehicle";

/**
 *
 */
interface IClaimSummary {

  damages: Damage[];

  vehiclePictures: string[];

  vinPictures: string[];

  drivingPermissionPicture: string[];

  inePictures: string[];

  licensePictures: string[];

  referencePictures: string[];

  vehicleColor?: StatusTypeItem;

  licenceType?: StatusTypeItem;

  vehicleType?: StatusTypeItem;

  address?: Address;

  vehicle?: Vehicle;

  licenseAlternative?: boolean;

  form: UseFormReturn<IClaimPersonForm>
}

/**
 *
 */
const ClaimSummary = (props: IClaimSummary): JSX.Element => {
  const { form } = props;
  const { editSection, isExternalForm, registerInfo, sectionValidation } = ClaimPersonSummaryFacade.useClaimPersonSummary();
  return (
    <div className="claimSummary">
      {
        registerInfo && registerInfo.claimType &&
        <>
          <section className="claimSummary__section">
            <div className="claimSummary__section__title">
              <span></span>
              <Text type="subtitle" className="claimSummary__section__title__text">Datos del conductor</Text>
              <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.NAME)}>
                {IconFactory.editIcon()}
              </button>
            </div>

            <div className="claimSummary__section__info">
              <div className="claimSummary__section__info__item">
                <Text type="label" uppercase align="center" block>Nombre</Text>
                <Text type="paragraph" uppercase align="center">{form.getValues('name')}</Text>
              </div>
              <div className="claimSummary__section__info__item">
                <Text type="label" uppercase align="center" block>Apellido paterno</Text>
                <Text type="paragraph" uppercase align="center">{form.getValues('lastName')}</Text>
              </div>
              <div className="claimSummary__section__info__item">
                <Text type="label" uppercase align="center" block>Apellido Materno</Text>
                <Text type="paragraph" uppercase align="center">{form.getValues('secondLastName')}</Text>
              </div>
              {
                !isExternalForm &&
                <div className="claimSummary__section__info__item">
                  <Text type="label" uppercase align="center" block>Fecha de nacimiento</Text>
                  <Text type="paragraph" uppercase align="center">{Util.TRANSFORM.DATE.formatDate(new Date(form.getValues('birthdate')))}</Text>
                </div>
              }

            </div>
          </section>

          <section className="claimSummary__section">
            <div className="claimSummary__section__title">
              <span></span>
              <Text type="subtitle" className="claimSummary__section__title__text">Datos de contacto</Text>
              <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.CONTACT)}>
                {IconFactory.editIcon()}
              </button>
            </div>

            <div className="claimSummary__section__info">
              <div className="claimSummary__section__info__item">
                <Text type="label" uppercase align="center" block>Correo electr&oacute;nico</Text>
                <Text type="paragraph" uppercase align="center">{form.getValues('email')}</Text>
              </div>
              <div className="claimSummary__section__info__item">
                <Text type="label" uppercase align="center" block>Tel&eacute;fono</Text>
                <Text type="paragraph" uppercase align="center">{form.getValues('phone')}</Text>
              </div>
            </div>

          </section>

          {
            !isExternalForm &&
            <>
              <section className="claimSummary__section">
                <div className="claimSummary__section__title">
                  <span></span>
                  <Text type="subtitle" className="claimSummary__section__title__text">Narraci&oacute;n de los hechos</Text>
                  <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.DESCRIPTION)}>
                    {IconFactory.editIcon()}
                  </button>
                </div>

                <div className="claimSummary__section__info">
                  <div className="claimSummary__section__info__item">
                    <Text type="label" uppercase align="center" block>Narraci&oacute;n del conductor</Text>
                    <Text type="paragraph" align="center">
                      {form.getValues('facts')}
                    </Text>
                  </div>
                </div>
              </section>

              <section className="claimSummary__section">
                <div className="claimSummary__section__title">
                  <span></span>
                  <Text type="subtitle" className="claimSummary__section__title__text">Fotos de crucero</Text>
                  <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.GENERIC_PICTURES)}>
                    {IconFactory.editIcon()}
                  </button>
                </div>

                <div className="claimSummary__section__info">

                  {
                    sectionValidation.validatePictures(props.referencePictures) ?
                      <div className="claimSummary__section__info__pictures">
                        {
                          props.referencePictures.map(
                            (image, index) => (
                              <div className="claimSummary__section__info__pictures__picture" key={`reference-picture-${index}`}>
                                <img alt="item" src={image} />
                              </div>
                            )
                          )
                        }
                      </div>
                      :
                      <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan fotos -</i></Text>
                  }

                </div>
              </section>
            </>
          }

          {
            !registerInfo.claimType.isClaimRobbery() &&
            <section className="claimSummary__section">
              <div className="claimSummary__section__title">
                <span></span>
                <Text type="subtitle" className="claimSummary__section__title__text">Datos del domicilio</Text>
                <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.LOCATION)}>
                  {IconFactory.editIcon()}
                </button>
              </div>

              <div className="claimSummary__section__info">

                {
                  sectionValidation.validateAddress(props.address) ?
                    <>
                      {
                        props.address?.postalCode!! &&
                        <div className="claimSummary__section__info__item">
                          <Text type="label" uppercase align="center" block>C&oacute;digo postal</Text>
                          <Text type="paragraph" uppercase align="center">{props.address?.postalCode}</Text>
                        </div>
                      }

                      {
                        !isExternalForm &&
                        <>
                          {
                            props.address?.name!! &&
                            <div className="claimSummary__section__info__item">
                              <Text type="label" uppercase align="center" block>Direcci&oacute;n</Text>
                              <Text type="paragraph" uppercase align="center">{props.address?.name}</Text>
                            </div>
                          }

                          {
                            props.address?.municipality!! &&
                            <div className="claimSummary__section__info__item">
                              <Text type="label" uppercase align="center" block>Municipio</Text>
                              <Text type="paragraph" uppercase align="center">{props.address?.municipality}</Text>
                            </div>
                          }

                          {
                            props.address?.state!! &&
                            <div className="claimSummary__section__info__item">
                              <Text type="label" uppercase align="center" block>Estado</Text>
                              <Text type="paragraph" uppercase align="center">{props.address?.state}</Text>
                            </div>
                          }
                        </>
                      }
                    </>
                    :
                    <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan datos -</i></Text>
                }
              </div>
            </section>
          }

          {
            !registerInfo.claimType.isClaimRobbery() && registerInfo.claimType && !registerInfo.claimType.isClaimWindscreen() &&
            <section className="claimSummary__section">
              <div className="claimSummary__section__title">
                <span></span>
                <Text type="subtitle" className="claimSummary__section__title__text">Datos de licencia</Text>
                <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.LICENSE)}>
                  {IconFactory.editIcon()}
                </button>
              </div>

              <div className="claimSummary__section__info">

                {
                  sectionValidation.validateLicense(form, props.licenceType) ?
                    <>
                      {
                        form.getValues('licenseNumber')!! &&
                        <div className="claimSummary__section__info__item">
                          <Text type="label" uppercase align="center" block>Nro de licencia</Text>
                          <Text type="paragraph" uppercase align="center">{form.getValues('licenseNumber')}</Text>
                        </div>
                      }

                      {
                        !isExternalForm &&
                        <>
                          {
                            form.getValues('licenseState') &&
                            <div className="claimSummary__section__info__item">
                              <Text type="label" uppercase align="center" block>Estado de emeisi&oacute;n</Text>
                              <Text type="paragraph" uppercase align="center">{form.getValues('licenseState')}</Text>
                            </div>
                          }

                          {
                            props.licenceType &&
                            <div className="claimSummary__section__info__item">
                              <Text type="label" uppercase align="center" block>Tipo de licencia</Text>
                              <Text type="paragraph" uppercase align="center">{props.licenceType?.name}</Text>
                            </div>
                          }

                          {
                            (form.getValues('licenseStartDate')!! && form.getValues('licenseEndDate')!!) &&
                            <div className="claimSummary__section__info__item">
                              <Text type="label" uppercase align="center" block>Fecha de vigencia</Text>
                              <Text type="paragraph" uppercase align="center">
                                {Util.TRANSFORM.DATE.formatDate(new Date(form.getValues('licenseStartDate')))} al {Util.TRANSFORM.DATE.formatDate(new Date(form.getValues('licenseEndDate')))}
                              </Text>
                            </div>
                          }
                        </>
                      }

                    </>
                    :
                    <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan datos -</i></Text>
                }

              </div>
            </section>
          }

          {
            !props.licenseAlternative ?
              <section className="claimSummary__section">
                <div className="claimSummary__section__title">
                  <span></span>
                  <Text type="subtitle" className="claimSummary__section__title__text">Fotos de licencia</Text>
                  <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.LICENSE_PICTURES)}>
                    {IconFactory.editIcon()}
                  </button>
                </div>

                <div className="claimSummary__section__info">
                  {
                    sectionValidation.validatePictures(props.licensePictures) ?
                      <div className="claimSummary__section__info__pictures">
                        {
                          props.licensePictures.map(
                            (image, index) => (
                              <div className="claimSummary__section__info__pictures__picture" key={`licence-picture-${index}`}>
                                <img alt="item" src={image} />
                              </div>
                            )
                          )
                        }
                      </div>
                      :
                      <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan fotos -</i></Text>
                  }
                </div>
              </section>
              :
              <section className="claimSummary__section">
                <div className="claimSummary__section__title">
                  <span></span>
                  <Text type="subtitle" className="claimSummary__section__title__text">Fotos de INE</Text>
                  <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.LICENSE_PICTURES)}>
                    {IconFactory.editIcon()}
                  </button>
                </div>

                <div className="claimSummary__section__info">

                  {
                    sectionValidation.validatePictures(props.inePictures) ?
                      <div className="claimSummary__section__info__pictures">
                        {
                          props.inePictures.map(
                            (image, index) => (
                              <div className="claimSummary__section__info__pictures__picture" key={`ine-picture-${index}`}>
                                <img alt="item" src={image} />
                              </div>
                            )
                          )
                        }
                      </div>
                      :
                      <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan fotos -</i></Text>
                  }
                </div>
              </section>
          }

          {
            !registerInfo.claimType.isClaimRobbery() &&
            <section className="claimSummary__section">
              <div className="claimSummary__section__title">
                <span></span>
                <Text type="subtitle" className="claimSummary__section__title__text">Fotos de tarjeta de circulaci&oacute;n</Text>
                <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.DRIVING_PERMISSION)}>
                  {IconFactory.editIcon()}
                </button>
              </div>

              <div className="claimSummary__section__info">
                {
                  sectionValidation.validatePictures(props.drivingPermissionPicture) ?
                    <div className="claimSummary__section__info__pictures">
                      {
                        props.drivingPermissionPicture.map(
                          (image, index) => (
                            <div className="claimSummary__section__info__pictures__picture" key={`driving-picture-${index}`}>
                              <img alt="item" src={image} />
                            </div>
                          )
                        )
                      }
                    </div>
                    :
                    <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan fotos -</i></Text>
                }
              </div>
            </section>
          }


          {
            <section className="claimSummary__section">
              <div className="claimSummary__section__title">
                <span></span>
                <Text type="subtitle" className="claimSummary__section__title__text">Datos del veh&iacute;culo</Text>
                <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_DATA)}>
                  {IconFactory.editIcon()}
                </button>
              </div>

              <div className="claimSummary__section__info">
                {
                  sectionValidation.validateVehicle(props.vehicle!, props.vehicleType) ?
                    <>
                      <div className="claimSummary__section__info__item">
                        <Text type="label" uppercase align="center" block>Tipo</Text>
                        <Text type="paragraph" uppercase align="center">{props.vehicleType?.name}</Text>
                      </div>

                      <div className="claimSummary__section__info__item">
                        <Text type="label" uppercase align="center" block>Marca</Text>
                        <Text type="paragraph" uppercase align="center">{form.getValues('vehicleBrand') || props.vehicle?.brand?.name}</Text>
                      </div>

                      <div className="claimSummary__section__info__item">
                        <Text type="label" uppercase align="center" block>Modelo</Text>
                        <Text type="paragraph" uppercase align="center">{form.getValues('vehicleModel') || props.vehicle?.model?.name}</Text>
                      </div>

                      <div className="claimSummary__section__info__item">
                        <Text type="label" uppercase align="center" block>A&ntilde;o</Text>
                        <Text type="paragraph" uppercase align="center">{form.getValues('vehicleYear') || props.vehicle?.year?.value}</Text>
                      </div>

                      <div className="claimSummary__section__info__item">
                        <Text type="label" uppercase align="center" block>Placas</Text>
                        <Text type="paragraph" uppercase align="center">{form.getValues('plates') || props.vehicle?.plate}</Text>
                      </div>

                      <div className="claimSummary__section__info__item">
                        <Text type="label" uppercase align="center" block>Color</Text>
                        <Text type="paragraph" uppercase align="center">{props.vehicleColor?.name || props.vehicle?.color}</Text>
                      </div>
                    </>
                    :
                    <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan datos -</i></Text>
                }
              </div>
            </section>
          }

          {
            !registerInfo.claimType.isClaimRobbery() &&
            <section className="claimSummary__section">
              <div className="claimSummary__section__title">
                <span></span>
                <Text type="subtitle" className="claimSummary__section__title__text">Nro. de serie</Text>
                <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_VIN)}>
                  {IconFactory.editIcon()}
                </button>
              </div>

              <div className="claimSummary__section__info">

                {
                  sectionValidation.validateVin(form, props.vehicleColor) ?
                    <>
                      {
                        sectionValidation.validatePictures(props.vinPictures) ?
                          <div className="claimSummary__section__info__item">
                            <div className="claimSummary__section__info__item__picture">
                              {
                                props.vinPictures.map(
                                  (image, index) => (
                                    <div className="claimSummary__section__info__pictures__picture" key={`vin-picture-${index}`}>
                                      <img alt="item" src={image} />
                                    </div>
                                  )
                                )
                              }
                            </div>
                          </div>
                          :
                          <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Sin foto -</i></Text>
                      }

                      {
                        form.getValues('vin')!! &&
                        <div className="claimSummary__section__info__item">
                          <Text type="label" uppercase align="center" block>Nro. de serie</Text>
                          <Text type="paragraph" uppercase align="center">{form.getValues('vin')}</Text>
                        </div>
                      }
                    </>
                    :
                    <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan datos -</i></Text>
                }
              </div>
            </section>
          }

          {
            !registerInfo.claimType.isClaimRobbery() && registerInfo.claimType && !registerInfo.claimType.isClaimWindscreen() &&
            <section className="claimSummary__section">
              <div className="claimSummary__section__title">
                <span></span>
                <Text type="subtitle" className="claimSummary__section__title__text">Fotos del veh&iacute;culo</Text>
                <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_PICTURES)}>
                  {IconFactory.editIcon()}
                </button>
              </div>

              <div className="claimSummary__section__info">
                {
                  sectionValidation.validatePictures(props.vehiclePictures) ?
                    <div className="claimSummary__section__info__pictures">
                      {
                        props.vehiclePictures.map(
                          (image, index) => (
                            <div className="claimSummary__section__info__pictures__picture" key={`vehicle-picture-${index}`}>
                              <img alt="item" src={image} />
                            </div>
                          )
                        )
                      }
                    </div>
                    :
                    <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Faltan fotos -</i></Text>
                }
              </div>
            </section>
          }

          {
            !registerInfo.claimType.isClaimRobbery() && registerInfo.claimType &&
            <section className="claimSummary__section">
              <div className="claimSummary__section__title">
                <span></span>
                <Text type="subtitle" className="claimSummary__section__title__text">Da&ntilde;os del veh&iacute;culo</Text>
                <button type="button" onClick={() => editSection(CLAIM_REGISTER_PERSON_PARAMS.DAMAGE)}>
                  {IconFactory.editIcon()}
                </button>
              </div>

              <div className="claimSummary__section__info">
                {
                  props.damages.length > 0 ?
                    <div className="claimSummary__section__info__damages">
                      {
                        props.damages.map(
                          (damage, index) => (
                            <div className="claimPerson__content__wrapper__damage" key={`damage-${index}`}>
                              <span className="claimPerson__content__wrapper__damage__image" style={{ backgroundImage: `url(${Util.ASSET.COVERAGE_DT})` }}></span>
                              <div className="claimPerson__content__wrapper__damage__type">
                                <Text type="label" uppercase>&Aacute;rea del da&ntilde;o</Text>
                                <Text type="paragraph" bold>{damage.type.name}</Text>
                              </div>
                            </div>
                          )
                        )
                      }
                    </div>
                    :
                    <Text type="label" className="claimSummary__section__info__empty" align="center" block><i>- Sin da&ntilde;os -</i></Text>
                }
              </div>
            </section>
          }

        </>
      }

    </div>
  );
};

export default ClaimSummary;
import { IGenerateCodeRequest } from "../interfaces/IGenerateCodeRequest";
import { IGenerateUUIDCodeRequest } from "../interfaces/IGenerateUUIDCodeRequest";
import { IVerifyCodeData } from "../interfaces/IVerifyCodeData";
import { IVerifyCodeRequest } from "../interfaces/IVerifyCodeRequest";
import { IVerifyUUIDCodeRequest } from '../interfaces/IVerifyUUIDCodeRequest';

export namespace OtpAdapter {

  /**
   * 
   * @param type 
   */
  export const generateCodeRequestAdapter = (type: string): IGenerateCodeRequest => {
    const data: IGenerateCodeRequest = {
      type: type
    }

    return data;
  }

	/**
	 * 
	 * @param type 
	 * @param phone 
	 * @returns 
	 */
	export const generateUUIDCodeRequestAdapter = (type: string, phone: string, uuid: string): IGenerateUUIDCodeRequest => {
		const data: IGenerateUUIDCodeRequest = {
			person_id: uuid,
			person_phone: phone,
			message: 'Tu código de verificación para Crabi Seguros es',
			type: type
		};

		return data;
	}

  /**
   * 
   * @param type 
   */
  export const verifyCodeRequestAdapter = (code: string): IVerifyCodeRequest => {
    const data: IVerifyCodeRequest = {
      code: code.toUpperCase()
    }

    return data;
  }

  /**
   * 
   * @param type 
   */
  export const verifyUUIDCodeRequestAdapter = (code: string, uuid: string): IVerifyUUIDCodeRequest => {
    const data: IVerifyUUIDCodeRequest = {
      code: code.toUpperCase(),
			person_id: uuid
    }

    return data;
  }

  /**
   * 
   * @param type 
   */
  export const verifyCodeDataAdapter = (data: IVerifyCodeData): string => {
    return data.token;
  }

}
import { Address } from "src/classes/Address";
import { Phone } from "src/classes/Phone";
import { Profile } from "src/classes/Profile";
import { Person  } from "src/classes/Person";
import { IPersonStorage } from "../interfaces/IPersonStorage";
import { IPolicyCreationPersonRequest } from "../interfaces/IPolicyCreationPersonRequest";

export namespace DriverDataAdapter {


  export const driverCreationRequestAdapter = (driver: Person): IPolicyCreationPersonRequest => {
    const profile = driver.profile!;
    const address = profile.address!;

    const data: IPolicyCreationPersonRequest = {
      address: {
        country_code: address.countryCode,
        external_number: address.externalReference,
        internal_number: address.internalReference? address.internalReference : '',
        is_valid_postal_code: true,
        municipality_code: address.municipalityCode.toString(),
        neighborhood: address.neighborhood,
        state_code: address.stateCode,
        postal_code: address.postalCode.toString(),
        street: address.street,
      },
      birthdate: profile.birthdate?.toISOString()!,
      did_accept_rfc_use: true,
      rfc: profile.rfc.toLocaleUpperCase(),
      is_valid_rfc: true,
      email: driver.email.toLocaleLowerCase(),
      first_name: driver.name.trim(),
      first_last_name: driver.lastName.trim(),
      second_last_name: driver.secondLastName.trim(),
      gender_code: profile.gender,
      phone: `${profile.phone?.countryCode?.code}${profile.phone?.number}`,
      pld_data: {
        country_of_origin_code: address.countryCode,
        state_of_origin_code: address.stateCode,
        nationality_code: address.countryCode,
        economic_activity_code: 1,
        resources_origin_code: 1,
        years_of_seniority: 1,
      }
    }
    data.phone = data.phone.slice(1, data.phone.length);

    return data;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const driverStorageAdapter = (data: Person | IPersonStorage): Person | IPersonStorage => {
    if (data instanceof Person) {
      const driver: Person = data as Person;
      const dataStorage: IPersonStorage = {
        name: driver.name,
        lastName: driver.lastName,
        secondLastName: driver.secondLastName,
        email: driver.email,
        rfc: driver.profile?.rfc!,
        birthdate: driver.profile?.birthdate?.toDateString()!,
        phone: {
          code: driver.profile?.phone?.countryCode?.code!,
          number: driver.profile?.phone?.number!,
        },
        gender: driver.profile?.gender!,
        address: {
          postalCode: driver.profile?.address?.postalCode!,
          neighborhood: driver.profile?.address?.neighborhood!,
          street: driver.profile?.address?.street!,
          externalReference: driver.profile?.address?.externalReference!,
          internalReference: driver.profile?.address?.internalReference,
        },
      }
      return dataStorage;
    } else {
      const driverData: IPersonStorage = data as IPersonStorage;
      const driver = new Person(driverData.email, driverData.name, driverData.lastName, driverData.secondLastName);
      driver.profile = new Profile(driverData.rfc, new Date(driverData.birthdate), driverData.gender);
      driver.profile.phone = new Phone(driverData.phone.number);
      driver.profile.phone.countryCode = {code: driverData.phone.code}
      driver.profile.address = new Address(driverData.address.postalCode);
      driver.profile.address.neighborhood = driverData.address.neighborhood;
      driver.profile.address.street = driverData.address.street;
      driver.profile.address.externalReference = driverData.address.externalReference;
      driver.profile.address.internalReference = driverData.address.internalReference? driverData.address.internalReference : '';
      return driver;
    }
  }
}

const BASE_URL: string = '/quoter';

/**
 * 
 */
 export const QUOTER_PATH = {

  QUOTER: BASE_URL,
	DATA_FORM: `${BASE_URL}/user-registration`

}
import { ForgotPasswordRoutes } from "src/modules/forgot-password/routes/forgot-password.routes";
import { InitRoutes } from "src/modules/init/routes/init.routes";
import { LoginRoutes } from "src/modules/login/routes/login.routes";
import { ClaimPaymentsRoutes } from "src/modules/claim-payments/routes/claim-payments.routes";
import { SignOutRoutes } from "src/modules/sign-out/routes/sign-out.routes";
import { DASHBOARD_PATH } from "./paths/dashboard.paths";
import { CommonRoutes } from "src/modules/common/routes/common.routes";
import { AgreedPaymentsRoutes } from "src/modules/claim-payments/routes/agreed-payments.routes";
import { InsuranceVehicleRoutes } from "src/modules/insurance-vehicle/routes/insurance-vehicle.routes";
import { QuoterRoutes } from "src/modules/quoter/routes/quoter.routes";
import { BillRoutes } from "src/modules/bill/routes/bill.routes";
import { ClaimRegisterRoutes } from "src/modules/claim-register/routes/claim-register.routes";

export const publicRoutes = (isValidAuth: boolean) => {
  
  return [
    {
      ...InitRoutes()
    },
    {
      ...LoginRoutes(isValidAuth, DASHBOARD_PATH.DASHBOARD)
    },
    {
      ...ClaimRegisterRoutes()
    },
    {
      ...BillRoutes()
    },
    {
      ...ForgotPasswordRoutes()
    },
		{
			...QuoterRoutes()
		},
		{
			...ClaimPaymentsRoutes(isValidAuth)
		},
		{
			...AgreedPaymentsRoutes(isValidAuth)
		},
    {
      ...SignOutRoutes()
    },
    {
      ...InsuranceVehicleRoutes()
    },
    {
      ...CommonRoutes()
    }
  ];
}
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Radio } from "src/components";
import { IconFactory } from 'src/factory/icon.factory';
import './RadioDropdown.scss';

/**
 *
 */

interface IRadioDropdownItem {
	id: number, 
	label: string, 
	secondLabel: string
}

interface IRadioDropdown {

  items: IRadioDropdownItem[];

  placeholder: string;

	secondPlaceholder?: string;

  required?: boolean;

  onChange?: (item: IRadioDropdownItem) => void;

  defaultValue?: IRadioDropdownItem;

	firstAsDefault?: boolean;

  //allowEmpty?: boolean;

	disabled?: boolean;

	transparent?: boolean;
}

/**
 *
 */
export const RadioDropdown = (props: IRadioDropdown): JSX.Element => {

	/** */
	const { items, placeholder, secondPlaceholder, defaultValue, /** required, disabled, */ firstAsDefault, onChange, transparent } = props;
	/** */
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<IRadioDropdownItem>();

	/** */
  const radioDropdownClassNames = classNames({
    'radio-dropdown-wrapper': true,
    'relative': true,
		'active': isExpanded,
    'disabled': (props.items.length === 0 || props.disabled),
		'width-100': true,
		'transparent': transparent === true
  });

	/** */
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      // event.preventDefault();
			// This is commented because it caused unexpected behaviors on the UI.
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    }

    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, [wrapperRef]);

	/** */
	useEffect(() => {
		if(selectedItem && onChange)
			onChange(selectedItem);
	}, [selectedItem, onChange]);

	useEffect(() => {
		if(firstAsDefault && items && !selectedItem && !defaultValue)
			setSelectedItem(items[0]);
	}, [firstAsDefault, items, selectedItem, defaultValue]);

	useEffect(() => {
		if(defaultValue && !selectedItem) {
			for(const item of items){
				if(item.id === defaultValue.id)
				setSelectedItem(item);
			}
		}
	}, [defaultValue, items, selectedItem]);

  return (
		<div className={radioDropdownClassNames} ref={wrapperRef}>
			<div className="placeholder relative" onClick={(e) => { e.preventDefault(); setIsExpanded(true)}}>
				{
					selectedItem ?
						<p>{selectedItem.label}</p>
					:
						<p>{placeholder} <span>({secondPlaceholder})</span></p>
				}
				{IconFactory.dropDownArrowIcon()}
			</div>

			{
				isExpanded &&
				<ul className="dropdown-opts absolute width-100">
					{
						items && items.map( (item, index) => {
							return (
								<li key={index} className="flex align-center" onClick={(e) => { e.preventDefault(); setSelectedItem(item); setIsExpanded(false) }}>
									<Radio name='radio-dropdown-opt' withoutForm={true} defaultChecked={selectedItem && item.id === selectedItem.id} />
									<p>{item.label}</p>
								</li>
							)
						})
					}
				</ul>
			}

		</div>
  );
};
import { Address } from "src/classes/Address";
import { Phone } from "src/classes/Phone";
import { Profile } from "src/classes/Profile";
import { Referral } from "src/classes/referrals/Referral";
import { Person  } from "src/classes/Person";
import { IUserData } from "../interfaces/user/IUserData";

/**
 * 
 */
export namespace CoreUserDataAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const userDataAdapter = (data: IUserData): Person => {
    let user: Person = new Person(data.email, data.first_name.trim(), data.first_last_name.trim(), data.second_last_name.trim());
    user.id = data.id;
    return user;
  }


  /**
   * 
   * @param data 
   * @returns 
   */
  export const profileDataAdapter = (data: IUserData): Profile => {
    const splitDate = data.birthdate.split('-');
    const year: number = parseInt(splitDate[0]);
    const month: number = parseInt(splitDate[1]);
    const day: number = parseInt(splitDate[2]);

    let profile: Profile = new Profile(data.rfc, new Date(year, month - 1, day), data.gender_code);
    const phoneIndex: number = data.phone.length - 10;
    const phoneNumber: string = data.phone.substring(data.phone.length, phoneIndex);
    const phoneCode: string = data.phone.slice(0, phoneIndex);
    profile.phone = new Phone(phoneNumber);
    profile.phone.countryCode = {
      code: `+${phoneCode}`
    }
    profile.gender = data.gender_code;
    profile.civilStatus = data.civil_status_code;
    profile.address = new Address(data.address.postal_code, parseInt(data.address.country_code), parseInt(data.address.state_code), data.address.municipality_code);
    profile.address.neighborhood = data.address.neighborhood? data.address.neighborhood : '';
    profile.address.street = data.address.street? data.address.street : '';
    profile.address.externalReference = data.address.external_number? data.address.external_number : '';
    profile.address.internalReference = data.address.internal_number? data.address.internal_number : ''; 
    
    profile.referral = new Referral(data.referral_to, data.referral_count);
    profile.referral.points = data.referral_points? data.referral_points : 0;

    return profile;
  }
}
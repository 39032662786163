import { DamageClaim } from "src/classes/DamageClaim";
import { IActiveAgreedClaimsData } from "../interfaces/IActiveAgreedClaimsData";

export namespace AgreedClaimsDataAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const agreedClaimListDataAdapter = (data: IActiveAgreedClaimsData): DamageClaim[] => {
    let claims: DamageClaim[] = [];

    if (data.agreed_payments) {
      for (const claimData of data.agreed_payments) {
        let newClaim = new DamageClaim(claimData.id, new Date(claimData.created_at), claimData.status, claimData.policy_number, claimData.sinister_number);
        claims.push(newClaim);
      }
    }

    return claims;
  }
}

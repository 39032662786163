import { call, put, takeLatest } from "redux-saga/effects";
import { CoreTokenStorageFeature } from "src/core/features/token-storage.feature";
import { CoreAuthSlice } from "src/core/slices/auth.slice";
import { CoreInstanceSlice } from "src/core/slices/instance.slice";
import { SignOutAction } from "../actions/sign-out.action";

/**
 *
 */
export namespace SignOutSaga {

  /**
   * 
   */
  function* signOut() {
    yield call(CoreTokenStorageFeature.removeTokenFromStorageFeature);
    const isValid =  CoreTokenStorageFeature.verifyTokenFeature();
    yield put(CoreInstanceSlice.actions.clear());
    yield put(CoreAuthSlice.actions.setAuthValidation(isValid as boolean));
  }

  export function* signOutSaga() {
    yield takeLatest(SignOutAction.signOut.type, signOut);
  }
}
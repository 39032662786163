import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { AlertFactory } from "src/factory/alert.factory";
import { POLICY_RENEWAL_PATH } from "src/routes/paths/policy-renewal.paths";
import { PolicyRenewalAction } from "../actions/policy-renewal.action";
import { PolicyRenewalFacade } from "./policy-renewal.facade";

export namespace PlanReviewRenewalFacade {

  /**
   * 
   */
  export const usePlanReviewRenewal = () => {
    const { plan, user, currentPolicy } = PolicyRenewalFacade.usePolicyRenewalActors();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { policyNumber } = useParams<string>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * 
     */
    const initPolicyRenewalRequest = async () => {
      try {
        setIsLoading(true);
        const success = await CorePolicyFeature.policyRequestFeature(currentPolicy.vehicle!, plan);
        if (success) {
          dispatch(PolicyRenewalAction.completeReview());
          navigate(POLICY_RENEWAL_PATH.POLICY_RENEWAL_PAYMENT(policyNumber!));
        }
      } catch (e) {
        AlertFactory.errorAlert('Ha ocurrido un error.');
        setIsLoading(false);
      }
    }

    useEffect(
      () => {
        if (!plan) {
          navigate(`${POLICY_RENEWAL_PATH.POLICY_RENEWAL}/${policyNumber}`, {replace: true});
        }
      }, [plan, navigate, policyNumber]
    )

    return { plan, user, currentPolicy, isLoading, initPolicyRenewalRequest }
  }
}
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DamageClaim } from "src/classes/DamageClaim";
import { Policy } from "src/classes/Policy";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { IClaimPaymentsStateReducer } from "src/store/states/modules-state/IClaimPaymentsStateReducer";
/**
 *
 */
export namespace ClaimPaymentsSlice {

	/**
	 * 
	 */
	const initialState: IClaimPaymentsStateReducer = {
		policyByNumber: undefined,
		currentPath: undefined,
		claimPayment: undefined,
		vehicle: undefined,
		claimUser: undefined,
		claimVehicle: undefined,
		dataStep: 0,
		picturesStep: 0,
		documentsStep: 0,
		accountStep: 0,
		proposalAvailable: false,
		isAgreedPayment: undefined,
		isLoadingDamageClaim: false,
	};

	/** */
	const storeVehicle: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<Vehicle>> = (state, action) => {
		state.vehicle = action.payload;
	};

	/** */
	const updateCurrentPath: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<string>> = (state, action) => {
		state.currentPath = action.payload;
	};

	/** */
	const storeDamageClaim: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<DamageClaim>> = (state, action) => {
		state.claimPayment = action.payload;
	};

	/** */
	const storeClaimUser: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<Person>> = (state, action) => {
		state.claimUser = action.payload;
	};

	/** */
	const storeClaimVehicle: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<Vehicle>> = (state, action) => {
		state.claimVehicle = action.payload;
	};

	/** */
	const updateDataStep: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<number>> = (state, action) => {
		state.dataStep = action.payload;
	};

	/** */
	const updatePicturesStep: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<number>> = (state, action) => {
		state.picturesStep = action.payload;
	};

	/** */
	const updateDocumentsStep: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<number>> = (state, action) => {
		state.documentsStep = action.payload;
	};

	/** */
	const updateAccountStep: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<number>> = (state, action) => {
		state.accountStep = action.payload;
	};

	/** */
	const updateProposal: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.proposalAvailable = action.payload;
	};

	/** */
	const updateIsAgreedPayment: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isAgreedPayment = action.payload;
	};

	/** */
	const updateIsLoadingDamageClaim: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<boolean>> = (state, action) => {
		state.isLoadingDamageClaim = action.payload;
	};

	/** */
	const clear: CaseReducer<IClaimPaymentsStateReducer> = (state) => {
		state.policyByNumber = undefined;
		state.currentPath = undefined;
		state.claimPayment = undefined;
		state.claimUser = undefined;
		state.claimVehicle = undefined;
		state.vehicle = undefined;
		state.dataStep = 0;
		state.picturesStep = 0;
		state.documentsStep = 0;
		state.accountStep = 0;
		state.proposalAvailable = false;
		state.isAgreedPayment = undefined;
		state.isLoadingDamageClaim = false;
	}

	/** */
  const policyByNumber: CaseReducer<IClaimPaymentsStateReducer, PayloadAction<Policy>> = (state, action) => {
    state.policyByNumber = action.payload;
  }

	const reducers = {
		storeVehicle,
		updateCurrentPath,
		storeDamageClaim,
		storeClaimUser,
		storeClaimVehicle,
		updateDataStep,
		updatePicturesStep,
		updateDocumentsStep,
		updateAccountStep,
		updateProposal,
		updateIsAgreedPayment,
		updateIsLoadingDamageClaim,
    clear,
    policyByNumber,
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'claimPayments',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
const BASE_URL: string = '/forgot-password';

/**
 * 
 */
export const FORGOT_PASSWORD_PATH = {

  FORGOT_PASSWORD: BASE_URL,
  RECOVERY: `${BASE_URL}/recovery`,
  RESTORE: `${BASE_URL}/restore`,
}
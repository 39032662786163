import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import { POLICY_ENDORSEMENTS_PATH } from "src/routes/paths/policy-endorsements.paths";
import { PolicyEndorsementsAction } from "../actions/policy-endorsements.action";
import { PolicyEndorsementsFacade } from "./policy-endorsements.facade";

export namespace EndorsementVerificationFacade {


  export const useEndorsementVerification = () => {
    const { currentPolicy } = PolicyEndorsementsFacade.useEndorsementsActors();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /**
     * 
     */
    const onVerify = (verification: boolean, token?: string): void => {
      if (verification) {
        dispatch(PolicyEndorsementsAction.endorsementVerification());
        if (token) {
					navigate(`${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_CONFIGURATION(currentPolicy.policyNumber)}?otp=${token}`, {replace: true});
        }
      }
    }

    return { onVerify }
  }
}
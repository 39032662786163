
const BASE_URL: string = '/policy/contract';

export const VEHICLE_ROUTE_ID = {
	VEHICLE_DATA: 'data',
	VEHICLE_ADITIONAL_DATA: 'aditional-data'
}

/**
 * 
 */
export const POLICY_CREATION_PATH = {
  POLICY_CREATION: BASE_URL,
  PERSON_DATA: `${BASE_URL}/person-data`,
	VEHICLE_DATA_PATH: `${BASE_URL}/vehicle/:view`,
  VEHICLE_DATA: (view: string) => `${BASE_URL}/vehicle/${view}`,
  PLAN_SELECTION: `${BASE_URL}/plan-selection`,
	POLICY_VEHICLE_SALVAGE: `${BASE_URL}/vehicle-salvage`,
  PLAN_REVIEW: `${BASE_URL}/plan-review`,
  PLAN_PAYMENT: `${BASE_URL}/plan-payment`,
	POLICY_OWNER: `${BASE_URL}/vehicle-owner`,
  BILL: `${BASE_URL}/bill`,
  COMPLETION: `${BASE_URL}/completion`,
}
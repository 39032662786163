import { AxiosError } from "axios";
import { UserEmailValidationService } from "../services/user-email-validation.service"

export namespace UserEmailValidationFeature {

  export const validateEmailFeature = async (token: string): Promise<void> => {
    try {
      await UserEmailValidationService.validateEmailService(token);
    } catch (e) {
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response && axiosError.response.status === 401) {
        error.message = `(${axiosError.response.status}) La validación no se ha procesado correctamente.`;
      } else if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }
}
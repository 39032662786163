import { ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";

/**
 *
 */
export namespace PolicyVehicleDataAction {

  /**
   * 
   */
   export const getVehicleStored: ActionCreatorWithoutPayload = createAction('GET_VEHICLE_STORED');
}
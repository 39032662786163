import { Button, ButtonLink, FormError, InputLabel } from "src/components";
import { Link } from "react-router-dom";
import { IconFactory } from "src/factory/icon.factory";
import { ForgotPasswordFacade } from "src/modules/forgot-password/controller/facades/forgot-password.facade";
import { ForgotPasswordFormFacade } from "src/modules/forgot-password/controller/facades/forgot-password-form.facade";
import { LOGIN_PATH } from "src/routes/paths/login.paths";
import { Util } from "src/utils/Util";

import './PasswordRecovery.scss';


/**
 *
 */
const PasswordRecoveryPage = (): JSX.Element => {
  const { RECOVERY_ERRORS, register, handleSubmit, watch, formState, getValues } = ForgotPasswordFormFacade.useRecoveryForm();
  const { sendEmail, isLoading, emailSended } = ForgotPasswordFacade.useRecoveryEmail();

  return (
    <div id="recovery-wrapper" style={{ backgroundImage: `url(${Util.ASSET.LOGIN_BG})` }}>
      {
        !emailSended &&
        <form onSubmit={handleSubmit((data) => sendEmail(data.email))}>
          <span className="recovery-image" style={{ backgroundImage: `url(${Util.ASSET.PASSWORD_RECOVERY})` }}></span>
          <h1 className="text-center">
            Recupera tu contrase&ntilde;a
          </h1>
          <p className="text-center">
            Te enviaremos un correo electr&oacute;nico con instrucciones sobre cómo restablecer tu contraseña.
          </p>

          <div className="form-section">
            <InputLabel
              icon={watch().email ? IconFactory.mailIcon() : IconFactory.mailOutlineIcon()}
              defaultValue=""
              type="email"
              placeholder="nombre@ejemplo.com"
              errors={!!formState.errors.email}
              {...register("email", { required: true, pattern: Util.PATTERN.EMAIL })}
            >
              Correo electr&oacute;nico
            </InputLabel>
            <FormError schema={RECOVERY_ERRORS} type={formState.errors?.email?.type} />
          </div>

          <div className="button-wrapper text-center">
            <Button type="submit"
              loading={isLoading}
              disabled={!formState.isValid}
            >
              Enviar correo electr&oacute;nico
            </Button>
            <p>
              ¿Ya tienes tu contrase&ntilde;a?
              <Link className="underline" to={LOGIN_PATH.LOGIN} replace>Iniciar sesi&oacute;n</Link>
            </p>
          </div>
        </form>
      }

      {
        emailSended &&
        <div className="email-sended-wrapper">
          <span className="recovery-image" style={{ backgroundImage: `url(${Util.ASSET.PASSWORD_RECOVERY})` }}></span>
          <h1 className="text-center">
            Correo electr&oacute;nico enviado
          </h1>
          <h2 className="text-center">
            Hemos enviado un correo electr&oacute;nico a <span>{getValues().email}</span> con instrucciones para restablecer la contrase&ntilde;a.
          </h2>
          <p className="text-center">
            Si no lo ves en tu bandeja de entrada, revisa la carpeta de <i>correo no deseado</i> o <i>spam</i>.
          </p>
          <div className="button-wrapper text-center">
            <ButtonLink className="width-100" to={LOGIN_PATH.LOGIN} replace>
              Entendido
            </ButtonLink>
          </div>
        </div>
      }
    </div>
  );
};

export default PasswordRecoveryPage;
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DamageClaim } from "src/classes/DamageClaim";
import { IPolicyDataForm } from "../interfaces/IPolicyDataForm";
import { ClaimPaymentsFacade } from "./claim-payments.facade";
import { PolicyDataFacade } from "./policy-data.facade";

/**
 *
 */
export namespace PolicyDataFormFacade {
	
  /**
   * 
   */
	 const POLICY_NUMBER_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'El número de póliza es obligatorio.'
    },
    {
      key: 'pattern',
      message: 'El número de póliza tiene caracteres inválidos.'
    },
    {
      key: 'minLength',
      message: 'El número de póliza debe tener al menos 4 caracteres.'
    },
		{
      key: 'maxLength',
      message: 'El número de póliza debe tener un máximo de 10 caracteres.'
    }
  ];

  /**
   * 
   */
   const CLAIM_PAYMENT_NUMBER_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'El número de siniestro es obligatorio. Ejemplo: CRA2022000012'
    },
    {
      key: 'pattern',
      message: 'El número de siniestro tiene caracteres inválidos.'
    },
    {
      key: 'minLength',
      message: 'El número de siniestro debe tener 13 caracteres.'
    },
		{
      key: 'maxLength',
      message: 'El número de siniestro debe tener 13 caracteres.'
    }
  ];

  /**
   * 
   * @returns 
   */
  export const usePolicyDataForm = () => {
		const { isLoading, createDamagePayment } = PolicyDataFacade.useCreateDamagePayment();
		const { damageClaim } = PolicyDataFacade.useClaimPolicy();
		const { currentDamageClaim } = ClaimPaymentsFacade.useDamageClaimActors();
    const { register, handleSubmit, formState: { errors, isValid, touchedFields }, trigger, watch, setValue, getValues } = useForm<IPolicyDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    const validateDefaultData = useCallback((damageClaim: DamageClaim): void => {
      if (damageClaim.policyNumber && !touchedFields.policyNumber) {
        setValue('policyNumber', damageClaim.policyNumber , {shouldValidate: true, shouldTouch: true});
      }

      if (damageClaim.damageClaimNumber && !touchedFields.damageClaimNumber) {
        setValue('damageClaimNumber', damageClaim.damageClaimNumber, {shouldValidate: true, shouldTouch: true});
      }

    }, [setValue, touchedFields]);

    /**
     * 
     * @param policyData 
     */
    const submitFormData = (policyData: IPolicyDataForm): void => {
      if (damageClaim) {
				if(damageClaim.policyNumber.length === 0)
					damageClaim.policyNumber = policyData.policyNumber;
			
				damageClaim.damageClaimNumber = policyData.damageClaimNumber;
				createDamagePayment(damageClaim!);
			}else{
				let _damageClaim = new DamageClaim('');
				_damageClaim.policyNumber = policyData.policyNumber;
				_damageClaim.damageClaimNumber = policyData.damageClaimNumber;

				createDamagePayment(_damageClaim!);
			}
    }
    
    useEffect(() => {
      if (currentDamageClaim)
        validateDefaultData(currentDamageClaim);
			
    }, [currentDamageClaim, validateDefaultData])
    return { submitFormData, register, handleSubmit, formState: { errors, isValid, touchedFields}, trigger, watch, getValues, isLoading };
  }

  /**
   * 
   */
  export const usePolicyDataValidation = () => {

    return { POLICY_NUMBER_ERRORS, CLAIM_PAYMENT_NUMBER_ERRORS };
  }
}
import { CopyClipboard, Card } from "src/components";
import ReferralsSkeletonLoader from "../../atoms/ReferralsSkeletonLoader/ReferralsSkeletonLoader";
import { IconFactory } from "src/factory/icon.factory";
import { ReferralsFacade } from "src/modules/referrals/controller/facades/referrals.facade";
import { REFERRALS_PATH } from "src/routes/paths/referrals.paths";
import { Util } from "src/utils/Util";
import { Outlet } from "react-router-dom";
import RewardInfo from "../../atoms/RewardInfo/RewardInfo";

import './Rewards.scss';

/**
 *
 */
const RewardsPage = (): JSX.Element => {
  const { currentUser, rewardsList, setShowInfo, showInfo } = ReferralsFacade.useReferrals();

  return (
    <>
      <div id="rewards-wrapper" className="flex column height-100">
        {
          rewardsList && currentUser ?
            <>
              <div className="current-user-referrals-info flex space-evenly">

                <section className="flex flex-grow-100 column align-center justify-center">
                  <h2>Tu c&oacute;digo</h2>
                  <p>
                    {currentUser.profile?.referral?.code}
                    <CopyClipboard text={`${Util.CONSTANT.WEB_URL}?referral=${currentUser.profile?.referral?.code!}`} message={'Has copiado el código.'} />
                  </p>
                </section>

                <section className="flex flex-grow-100 column align-center justify-center">
                  <div className="flex width-100">
                    <section className="flex flex-grow-100 column align-center justify-center">
                      <span className="block" style={{ backgroundImage: `url(${Util.ASSET.REFERRALS})` }}></span>
                      <h2>Tus referidos</h2>
                      <p>{currentUser.profile?.referral?.getCount()}</p>
                    </section>
                    <section className="flex flex-grow-100 column align-center justify-center">
                      <span className="block" style={{ backgroundImage: `url(${Util.ASSET.BADGE_POINTS})` }}></span>
                      <h2>Tus puntos Crabi</h2>
                      <p>{currentUser.profile?.referral?.points}</p>
                    </section>
                  </div>
                  <div className="flex width-100 align-center justify-center relative"
                    onMouseEnter={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}
                  >
                    <h3>M&aacute;s informaci&oacute;n {IconFactory.infoIcon()}</h3>
                    {
                      showInfo &&
                      <RewardInfo />
                    }
                  </div>

                </section>
              </div>

              <div className="rewards-list flex column flex-grow-100">
                <h2>Recompensas</h2>
                {
                  rewardsList.length > 0 ?
                    <ul>
                      {
                        rewardsList.map(reward => {
                          return (
                            <li key={reward.id} className="flex align-center">
                              <Card isLink img={reward.image} to={REFERRALS_PATH.REWARD_DETAILS(reward.title)}>
                                <div className="reward-item height-100 flex column space-between">
                                  <section className="flex space-between">
                                    <div className="reward-type flex align-center">
                                      {reward.type}
                                    </div>
                                    <div className="reward-points flex align-center">
                                      <span className="block" style={{ backgroundImage: `url(${Util.ASSET.BADGE_POINTS})` }}></span>
                                      {reward.points} ptos
                                    </div>
                                  </section>

                                  <section>
                                    <h3>{reward.title}</h3>
                                  </section>

                                  <section>
                                    <h4>{reward.brandName}</h4>
                                  </section>
                                </div>
                              </Card>
                            </li>
                          )
                        })
                      }
                    </ul>
                    :
                    <div className="empty-rewards-list flex column flex-grow-100 align-center justify-center">
                      <span className="block" style={{ backgroundImage: `url(${Util.ASSET.EMPTY_REWARD_LIST})` }}></span>
                      <h3 className="text-center">
                        Por ahora no tienes <br />
                        <span>recompensas activas disponibles.</span>
                      </h3>
                      <h4 className="text-center">
                        Pr&oacute;ximamente tendr&aacute;s ofertas, cupones o art&iacute;culos para canjear con tus puntos.
                      </h4>
                    </div>
                }

              </div>
            </>
            :
            <ReferralsSkeletonLoader />
        }

      </div>
      <Outlet>
      </Outlet>
    </>

  );
};

export default RewardsPage;
import { init, track, setUserId, Identify, identify } from '@amplitude/analytics-browser';

export namespace EventFactory {

  class Event {
    /**
     * 
     */
    private static _instance: Event;

    private constructor () {
			init(process.env.REACT_APP_AMPLITUDE_KEY!);
    }

    /**
     * 
     * @returns 
     */
    public static instance(): Event {
      if (!this._instance) {
        this._instance = new Event();
      }

      return this._instance;
    }

    /**
     * 
     * @param name 
     * @param properties 
     */
    public track(event: string, properties?: any): void {
      track(event, properties);
    }
    
    /**
     * 
     * @param userId 
     */
    public setUserId(id: string): void {
      setUserId(id);
    }

		/**
		 * 
		 * @param key 
		 * @param value 
		 */
		public setUserProperty = (key: string, value: string): void => {
			const userProperty = new Identify();
			userProperty.set(key, value);
			identify(userProperty);
		}
  }

  /**
   * 
   */
  export const initEventClient = () => {
    Event.instance();
  }

  export const EVENT_NAMES = {
    LOGIN: 'inicio_sesion_web',
    POLICY_CREATION: 'multipoliza',
    POLICY_OWNER: 'multipolizachoice',
  }

  /**
   * 
   */
  export const event = Event.instance();
}
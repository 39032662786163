import { useSelector } from "react-redux";
import { CoreSelector } from "src/core/selectors/core.selector";

/**
 *
 */
export namespace ProfileFacade {

  /**
   * 
   * @returns 
   */
  export const useCurrentUser = () => {
    const user = useSelector(CoreSelector.currentUser)!;
    return { user };
  }
}
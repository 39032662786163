import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects'
import { AuthToken } from "src/classes/AuthToken";
import { CoreTokenStorageFeature } from 'src/core/features/token-storage.feature';
import { CoreAuthSlice } from 'src/core/slices/auth.slice';
import { QuoterAction } from '../actions/quoter.action';

export namespace QuoterSaga {

  /**
   * 
   * @param action 
   */
  function* userLogin(action: PayloadAction<AuthToken>) {
    yield call(CoreTokenStorageFeature.saveTokenToStorageFeature, action.payload);
    yield put(CoreAuthSlice.actions.setAuthValidation(true));
  }


  /**
   * 
   */
  export function* quoterSaga() {
    yield takeLatest(QuoterAction.userLogin, userLogin);
  }
}


import { useForm } from "react-hook-form";
import { IPlanSummaryForm } from "../interfaces/IPlanSummaryForm";

export namespace PlanSummaryManagementFormFacade {

  /**
   * 
   * @returns 
   */
  export const usePlanSummaryForm = () => {
    const { register, formState: { isValid } } = useForm<IPlanSummaryForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    return { register, formState: { isValid } }
  }

}
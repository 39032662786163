import Otp from "src/libs/Otp/ui/template/Otp";
import { EndorsementVerificationFacade } from "src/modules/policy-endorsements/controller/facades/endorsement-verification.facade";
import { PolicyEndorsementsFacade } from "../../../controller/facades/policy-endorsements.facade"
import './EndorsementVerification.scss';

/**
 *
 */
interface IEndorsementVerification {

}

/**
 *
 */
const EndorsementVerificationPage = (props: IEndorsementVerification): JSX.Element => {
  const { currentUser } = PolicyEndorsementsFacade.useEndorsementsActors();
  const { onVerify } = EndorsementVerificationFacade.useEndorsementVerification();

  return (
    <div id="endorsement-verification-wrapper" className="height-100">
      {
        currentUser &&
        <>
          <Otp phoneNumber={currentUser.profile?.getPhoneNumber()!} email={currentUser.email} onVerify={onVerify}></Otp>
        </>
      }
    </div >
  );
};

export default EndorsementVerificationPage;
import { useNavigate } from "react-router-dom";
import { Button, ButtonBack, Modal, Text } from "src/components";
import { POLICY_CREATION_PATH, VEHICLE_ROUTE_ID } from "src/routes/paths/policy-creation.paths";
import { PolicyVehicleSalvageFacade } from "src/modules/policy-creation/controller/facades/policy-vehicle-salvage.facade";
import { Util } from "src/utils/Util";

import './PolicyVehicleSalvage.scss';

/**
 *
 */
const PolicyVehicleSalvage = (): JSX.Element => {

  const { showSalvageModal, setShowSalvageModal, nextPage } = PolicyVehicleSalvageFacade.usePolicyVehicleSalvage();
	const navigate = useNavigate();

  return (
    <div className="policyVehicleSalvage">
			<ButtonBack button text
				to={''}
				onClick={() => navigate(POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_ADITIONAL_DATA))}
			/>

      <div className="policyVehicleSalvage__content flex-grow-100">

        <div className="policyVehicleSalvage__content__wrapper width-60 text-center">
          <h1>¿Tu vehículo es o ha sido un auto de salvamento?</h1>
          <p>Los autos de salvamento son aquellos que tuvieron algún accidente donde fueron considerados pérdida total, o autos recuperados después de un robo.</p>

          <div className="flex space-around align-center">
            <Button fill="outline" className="flex-grow-45" onClick={() => setShowSalvageModal(true)}>
              Sí
            </Button>
            <Button className="flex-grow-45" onClick={nextPage}>
              No
            </Button>
          </div>
        </div>
      </div>

			<Modal show={showSalvageModal} position="center"
				backgroundDismiss
				onClose={() => setShowSalvageModal(false)}
			>
				<div className="policyVehicleSalvage__salvageMessage">
					<span className="policyVehicleSalvage__salvageMessage__image" style={{ backgroundImage: `url(${Util.ASSET.WARNING_IMAGE})` }}></span>

					<Text type="title" align="center" className="policyVehicleSalvage__salvageMessage__title">Solo se cubrirá el 50% <b>del valor comercial</b> del vehículo</Text>
					<Text type="paragraph" align="center" className="policyVehicleSalvage__salvageMessage__description">En caso de que tengamos que indemnizarte por la pérdida total, ya sea por robo o siniestro, te cubrimos un 50% del valor comercial.</Text>

					<div className="policyVehicleSalvage__salvageMessage__buttons">
						<Button type="button" className="policyVehicleSalvage__salvageMessage__buttons__button"
							onClick={nextPage}
						>
							Entiendo y acepto
						</Button>

						<Text type="label" align="left" className="">Leer
							<a target="_blank" href="https://crabi.com/legal/terminos-y-condiciones" rel="noreferrer"> términos y condiciones</a>
						</Text>
					</div>
				</div>
			</Modal>

    </div>
  );
};

export default PolicyVehicleSalvage;
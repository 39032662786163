import { AlertFactory } from "src/factory/alert.factory";
import { ClaimRegisterAction } from "../actions/claim-register.action";
import { ClaimRegisterFeature } from "../features/claim-register.feature";
import { Damage } from "src/classes/claims/Damage";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { useDispatch, useSelector } from "react-redux";
import { LoadingFactory } from "src/factory/loading.factory";
import { useParams } from "react-router-dom";
import { ClaimRegisterSelector } from "../selectors/claim-register.selector";
import { useState } from "react";

export namespace ClaimDamageFacade {

  
  /**
   * 
   */
  export const useDamage = (formType: 'people' | 'adjuster') => {
    const vehicleDamages = useSelector(ClaimRegisterSelector.vehicleDamagesList);
    const bikeDamages = useSelector(ClaimRegisterSelector.bikeDamagesList);
    const [currentType, setCurrentType] = useState<StatusTypeItem>();
		const [preexistence, setPreexistence] = useState<boolean>();
		const [description, setDescription] = useState<string>('');
    const registerInfo = useSelector(ClaimRegisterSelector.registerInfo);
		const adjusterInfo = useSelector(ClaimRegisterSelector.adjusterInfo);
    const loading = LoadingFactory.useLoader();
    const dispatch = useDispatch();
    const { key } = useParams<string>();

    /**
     * 
     * @param type 
     */
    const selectDamageTypeById = (id: string, damageType: StatusTypeItem): void => {
      if (bikeDamages && vehicleDamages) {
        let type: StatusTypeItem | undefined;
        if (damageType.isBikeStatusType()) {
          type = bikeDamages.find(b => b.id === id);
        } else if (damageType.isCarStatusType()) {
          type = vehicleDamages.find(b => b.id === id);
        }
        setCurrentType(type)
      }
    }

    /**
     * 
     * @param damage 
     */
    const createDamage = async (pictures: string[]): Promise<Damage | undefined> => {
      return new Promise<Damage | undefined>(
        async (resolve, reject) => {
          try {
            if (registerInfo && key && currentType) {
              loading.set(true);
              let dmg: Damage = new Damage(currentType);

							if (preexistence)
								dmg.damagePreexistence = preexistence;

							dmg.description = description;

              registerInfo.damages.push(dmg);

							if (formType === 'people') {
								const newInfo = await ClaimRegisterFeature.claimPersonDamageUpdateFeature(key, registerInfo, dmg, pictures);
								if (newInfo) {
									dispatch(ClaimRegisterAction.setNewClaimRegisterInfo(newInfo));
								}
							} else if (adjusterInfo) {
								const newInfo = await ClaimRegisterFeature.claimAdjusterDamageUpdateFeature(key, adjusterInfo, dmg, pictures);
								if (newInfo) {
									dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(newInfo));
								}
							}
              resolve(dmg);
            }
          } catch (e) {
            AlertFactory.errorAlert('Ha ocurrido un error');
            reject();
          } finally {
            loading.set(false);
          }
        }
      )
    }

    /**
     * 
     * @param pictures 
     * @param damage 
     * @returns 
     */
    const editDamage = (pictures: string[], damage: Damage, index: number): Promise<Damage | undefined> => {
      return new Promise<Damage | undefined>(
        async (resolve, reject) => {
          try {
            if (registerInfo && key && currentType) {
              loading.set(true);
              damage.type = currentType;
							damage.description = description;

							if (preexistence)
								damage.damagePreexistence = preexistence;

              registerInfo.damages[index] = damage;

							if (formType === 'people') {
								const newInfo = await ClaimRegisterFeature.claimPersonDamageUpdateFeature(key, registerInfo, damage, pictures);
								if (newInfo) {
									dispatch(ClaimRegisterAction.setNewClaimRegisterInfo(newInfo));
								}
							} else if (adjusterInfo) {
								const newInfo = await ClaimRegisterFeature.claimAdjusterDamageUpdateFeature(key, adjusterInfo, damage, pictures);
								if (newInfo) {
									dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(newInfo));
								}
							}
              resolve(damage);
            }
          } catch (e) {
            AlertFactory.errorAlert('Ha ocurrido un error');
            reject();
          } finally {
            loading.set(false);
          }
        }
      )
    }

    return { editDamage, selectDamageTypeById, setPreexistence, setDescription, preexistence, description, currentType, vehicleDamages, bikeDamages, createDamage, loading }
  }
}
import { BILL_PATH } from "src/routes/paths/bill.paths";
import BillPage from '../ui/pages/Bill';

/**
 *
 */
export const BillRoutes = () => {
  return {
    path: BILL_PATH.BILL,
    element: <BillPage />,
    
  }
}
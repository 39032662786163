import { AxiosError } from "axios";
import { RestorePasswordAdapter } from "../adapters/restore-password.adater";
import { ForgotPasswordService } from "../services/forgot-password.service";

/**
 *
 */
export namespace ForgotPasswordFeature {

  /**
   * 
   * @param email 
   */
  export const sendRecoveryEmailFeature = async (email: string): Promise<void> => {
    try {
      await ForgotPasswordService.sendRecoveryEmailService({ email: email });
    } catch (e: any | AxiosError) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }

  }

  /**
   * 
   * @param user 
   * @param newPassowrd 
   */
  export const restorePasswordFeature = async (newPassowrd: string, token: string): Promise<void> => {
    try {
      const data = RestorePasswordAdapter.restorePasswordRequest(newPassowrd, token);
      await ForgotPasswordService.restorePasswordService(data);
    } catch (e: any | AxiosError) {
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }

  }
}
import { useForm } from "react-hook-form"
import { ILoginForm } from "../interfaces/ILoginForm"


export namespace LoginFormFacade {

  /**
 * 
 */
  export const useLoginFormFacade = () => {
    const { register, handleSubmit, formState: { errors, isValid, touchedFields }, watch } = useForm<ILoginForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );
    const formEmail = watch('email');
    const formPassword = watch('password');

    return { register, handleSubmit, formState: { errors, isValid, touchedFields }, watch, formEmail, formPassword };
  }
}



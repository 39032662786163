import { Button } from "src/components";
import { IconFactory } from 'src/factory/icon.factory';
import { CompletionFacade } from 'src/modules/claim-payments/controller/facades/completion.facade';
import { DamageClaimNavigateValidationFacade } from 'src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade';
import { Util } from "src/utils/Util";
import './CompletionPage.scss';


/**
 *
 */
const CompletionPage = (): JSX.Element => {

  /** */
  const { isCompleted, isProposalApproved, isPaymentPending, isPaymentDone, currentDamageClaim, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, isAgreedPayment } = CompletionFacade.useCompletionFacade();
  const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id="completion-data-wrapper" className="height-100">
      {IconFactory.checkIcon()}
			{
				!currentDamageClaim && 
				<>
					<h1>Cargando...</h1>
				</>
			}
      {
        currentDamageClaim && !isProposalApproved && !isPaymentPending && !isPaymentDone && !currentDamageClaim.checkAllPhotosApproved() &&
        <>
          <h1>{isCompleted ?
            Util.CONSTANT.CLAIMS_COMPLETION.COMPLETED_TITLE : Util.CONSTANT.CLAIMS_COMPLETION.INCOMPLETED_TITLE}
          </h1>
          <p>
            {isCompleted ?
              Util.CONSTANT.CLAIMS_COMPLETION.COMPLETED_TEXT
              :
              Util.CONSTANT.CLAIMS_COMPLETION.INCOMPLETED_TEXT
            }
          </p>
        </>
      }

			{
				currentDamageClaim && currentDamageClaim.checkAllPhotosApproved() && currentDamageClaim.proposal === undefined &&
				<>
					<h1>{Util.CONSTANT.CLAIMS_COMPLETION.MISSING_PROPOSAL_TITLE}</h1>
					<p>{Util.CONSTANT.CLAIMS_COMPLETION.MISSING_PROPOSAL_TEXT}</p>
				</>
			}

      {
        currentDamageClaim && isProposalApproved && !isPaymentPending && !isPaymentDone &&
        <>
          <h1>{Util.CONSTANT.CLAIMS_COMPLETION.PROPOSAL_APPROVED_TITLE}</h1>
          <p>{Util.CONSTANT.CLAIMS_COMPLETION.PROPOSAL_APPROVED_TEXT}</p>
        </>
      }

			{
				currentDamageClaim && isPaymentPending && 
				<>
				 	<h1>{Util.CONSTANT.CLAIMS_COMPLETION.PENDING_PAYMENT_TITLE}</h1>
          <p>{Util.CONSTANT.CLAIMS_COMPLETION.PENDING_PAYMENT_TEXT}</p>
				</>
			}

			{
				currentDamageClaim && isPaymentDone && 
				<>
				 	<h1>{Util.CONSTANT.CLAIMS_COMPLETION.DAMAGE_PAID_TITLE}</h1>
					<a
						className="claim-receipt-link"
						href={currentDamageClaim.receiptSource}
						target="_blank"
						rel="noreferrer"
					>
						Ver recibo de pago
					</a>
          {/* <p>{Util.CONSTANT.CLAIMS_COMPLETION.PENDING_PAYMENT_TEXT}</p> */}
				</>
			}

      <Button onClick={() => navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward')} fill='outline'>Finalizar</Button>
    </div>
  );
};

export default CompletionPage;
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Policy } from "src/classes/Policy";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { Util } from "src/utils/Util";
import { INSURANCE_INIT_PARAMS, INSURANCE_VEHICLE_PATH } from "src/routes/paths/insurance-vehicle.paths";
import { InsuranceVehicleFeature } from "../features/insurance-vehicle.feature";
import { AlertFactory } from "src/factory/alert.factory";

export namespace InsurancePicturesFacade {

  const VEHICLE_PICTURE_TYPE_CODES = Util.STATUS_CODE.VEHICLE_PICTURE_TYPE_CODES;

  export const useInsurancePictures = () => {
    const [currentPictures, setCurrentPictures] = useState<File[]>(new Array(4));
    const [currentToken, setCurrentToken] = useState<string>('');
    const [currentPolicy, setCurrentPolicy] = useState<Policy | undefined>();
    const [isValidPictures, setIsValidPictures] = useState<boolean>(false);
    const location = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(decodeURIComponent(location.search)), [location.search]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const navigate = useNavigate();

    /**
     * 
     * @returns 
     */
    const onBack = (): string => {
      return `${INSURANCE_VEHICLE_PATH.INIT}/${INSURANCE_INIT_PARAMS.INFO.FOURTH}${location.search}`;

    }
    /**
     * 
     * @param picture 
     * @param code 
     */
    const onSelectPicture = (file: File | undefined, code: number): void => {
      setCurrentPictures(
        currentState => {
          currentState[code] = file!;
          return currentState;
        }
      );

      const validFiles = currentPictures.filter(f => f !== undefined);
      if (validFiles.length === 4) {
        setIsValidPictures(true);
      }
    }

    const uploadPictures = async (): Promise<void> => {
      try {
        setIsUploading(true);
        if (isValidPictures && currentPolicy) {
          await InsuranceVehicleFeature.uploadVehiclePicturesFeature(currentToken, currentPolicy.id, currentPictures);
          navigate(`${INSURANCE_VEHICLE_PATH.COMPLETION}${location.search}`, {replace: true});
        }
      } catch(e) {
        AlertFactory.errorAlert((e as Error).message);
        setIsUploading(false);
      }
    }

    useEffect(
      () => {
        const policyNumber = searchParams.get(Util.KEY.QUERY_PARAMS.POLICY_NUMBER);
        const policyId = searchParams.get(Util.KEY.QUERY_PARAMS.POLICY_ID);
        const brandName = searchParams.get(Util.KEY.QUERY_PARAMS.BRAND_NAME);
        const modelName = searchParams.get(Util.KEY.QUERY_PARAMS.MODEL_NAME);
        const year = searchParams.get(Util.KEY.QUERY_PARAMS.YEAR);

        if (policyNumber && policyId && brandName && modelName && year && !isNaN(parseInt(year))) {
          let policy: Policy = new Policy(policyNumber);
          policy.id = policyId;
          policy.vehicle = new Vehicle();
          policy.vehicle.brand = new VehicleBrand(0, brandName);
          policy.vehicle.model = new VehicleModel(0, 0, modelName);
          policy.vehicle.year = new VehicleYear(0, 0, parseInt(year));
          setCurrentPolicy(policy);
        }

      }, [searchParams]
    );

    useEffect(
      () => {
        try {
          const token = searchParams.get(Util.KEY.QUERY_PARAMS.TOKEN);
          if (token) {
            setCurrentToken(atob(token));
          }
        } catch (e) {
          console.error(e);
        }
      }, [searchParams]
    )

    return { VEHICLE_PICTURE_TYPE_CODES, onSelectPicture, currentPolicy, currentToken, onBack, isValidPictures, uploadPictures, isUploading }
  }

  export const usePictureEvents = () => {
    const [isPictureValidating, setIsPictureValidating] = useState<boolean>(false);
    const [errorName, setErrorName] = useState<string>('');

    return { isPictureValidating, setIsPictureValidating, errorName, setErrorName }
  }

}
import { PropsWithChildren, useEffect } from "react";
import { Text } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { ESignFacade } from "src/libs/ESign/controller/facades/e-sign.facade";
import './ESignCanvas.scss';

/**
 *
 */
interface IESignCanvas {

  onApply: (eSign?: string) => void;

  wrapperElement?: HTMLDivElement | null;

  defaultImage?: string;

  orientation?: 'portrait' | 'landscape';
}

/**
 *
 */
const ESignCanvas = (props: PropsWithChildren<IESignCanvas>): JSX.Element => {
  const { onMouseDown, onMouseMove, onMouseUp, onTouchStart, onTouchMove, onTouchEnd, clearCanvas, submitSign, submitedSign } = ESignFacade.useCanvas(props.wrapperElement!, props.defaultImage, props.orientation);

  useEffect(
    () => {
      props.onApply(submitedSign);
    }, [submitedSign, props]
  )

  return (
    <>
      <div className="eSignCanvas">

        <button type="button" className="eSignCanvas__clear-button"
          disabled={submitedSign === ''}
          onClick={() => { clearCanvas() }}>
          {IconFactory.removeIcon()}<br />
          <Text type="label">Limpiar</Text>
        </button>

        <div className="eSignCanvas__content">
          {
            props.children &&
            <Text type="paragraph" align="center">{props.children}</Text>
          }

          <div className={`eSignCanvas__content__sign-area`}>
            {
              props.orientation === 'portrait' ?
                <canvas id="e-sign" className="relative"
                  onMouseDown={onMouseDown}
                  onMouseMove={onMouseMove}
                  onMouseUp={() => { onMouseUp(); submitSign() }}
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={() => { onTouchEnd(); submitSign() }}
                >
                </canvas>
              :
                <canvas id="landscape-e-sign" className="relative"
                  onMouseDown={onMouseDown}
                  onMouseMove={onMouseMove}
                  onMouseUp={() => { onMouseUp(); submitSign() }}
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={() => { onTouchEnd(); submitSign() }}
                >
                </canvas>
            }
            <span></span>
          </div>

          {/* <div className="eSignCanvas__content__actions">
            <Button fill="outline" type="button" onClick={(e) => { e.preventDefault(); clearCanvas() }}>
              Borrar firma
            </Button>
            <Button type="button" disabled={!isDirty} onClick={(e) => { e.preventDefault(); submitSign() }}>
              Aplicar firma
            </Button>
          </div> */}
        </div>

        {/* <div className="eSignCanvas__mobile-warning">
          {IconFactory.warningIcon()}
          <Text type="subtitle" align="center">
            Debes de girar el dispositivo en horizontal para poder firmar
          </Text>
        </div> */}
      </div>
    </>
  );
};

export default ESignCanvas;
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CLAIM_REGISTER_PATH, CLAIM_REGISTER_PERSON_PARAMS, CLAIM_REGISTER_FORM_TYPE } from "src/routes/paths/claim-register.paths";
import { IClaimPersonForm } from "../interfaces/IClaimPersonForm";
import { Damage } from "src/classes/claims/Damage";
import { Address } from "src/classes/Address";
import { DebounceFactory } from "src/factory/debounce.factory";
import { ClaimRegisterFeature } from "../features/claim-register.feature";
import { LoadingFactory } from "src/factory/loading.factory";
import { useDispatch, useSelector } from "react-redux";
import { ClaimRegisterSelector } from "../selectors/claim-register.selector";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { ClaimRegister } from "src/classes/claims/ClaimRegister";
import { AlertFactory } from "src/factory/alert.factory";
import { IClaimStep } from "../interfaces/IClaimStep";
import { ClaimRegisterAction } from "../actions/claim-register.action";
import { ClaimRegisterPicture } from "src/classes/claims/ClaimRegisterPicture";

export namespace ClaimPersonFacade {

  /**
   * 
   */
  const QUERY_PARAMS = {
    ALTERNATE_LICENCE_PICTURES: 'ine',
  }

  const STEPS: { INTERNAL: IClaimStep[], EXTERNAL: IClaimStep[], INTERNAL_ROBBERY: IClaimStep[], INTERNAL_WINDSCREEN: IClaimStep[] } = {
    INTERNAL: [
      { name: CLAIM_REGISTER_PERSON_PARAMS.NAME, value: 1 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.CONTACT, value: 2 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DESCRIPTION, value: 3 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.GENERIC_PICTURES, value: 4 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LOCATION, value: 5 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LICENSE, value: 6 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LICENSE_PICTURES, value: 7 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DRIVING_PERMISSION, value: 8 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_DATA, value: 9 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_VIN, value: 10 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_PICTURES, value: 11 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DAMAGE, value: 12 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.SUMMARY, value: 13 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.SIGNATURE, value: 14 },
    ],
    INTERNAL_ROBBERY: [
      { name: CLAIM_REGISTER_PERSON_PARAMS.NAME, value: 1 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.CONTACT, value: 2 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DESCRIPTION, value: 3 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.GENERIC_PICTURES, value: 4 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LICENSE_PICTURES, value: 5 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_DATA, value: 6 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.SUMMARY, value: 7 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.SIGNATURE, value: 8 },
    ],
    INTERNAL_WINDSCREEN: [
      { name: CLAIM_REGISTER_PERSON_PARAMS.NAME, value: 1 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.CONTACT, value: 2 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DESCRIPTION, value: 3 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.GENERIC_PICTURES, value: 4 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LOCATION, value: 5 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LICENSE_PICTURES, value: 6 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DRIVING_PERMISSION, value: 7 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_DATA, value: 8 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_VIN, value: 9 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DAMAGE, value: 10 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.SUMMARY, value: 11 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.SIGNATURE, value: 12 },
    ],
    EXTERNAL: [
      { name: CLAIM_REGISTER_PERSON_PARAMS.NAME, value: 1 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.CONTACT, value: 2 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LOCATION, value: 3 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LICENSE, value: 4 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.LICENSE_PICTURES, value: 5 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DRIVING_PERMISSION, value: 6 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_DATA, value: 7 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_VIN, value: 8 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_PICTURES, value: 9 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.DAMAGE, value: 10 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.SUMMARY, value: 11 },
      { name: CLAIM_REGISTER_PERSON_PARAMS.SIGNATURE, value: 12 },
    ]
  }

  /**
   * 
   * @returns 
   */
  export const useClaimPersonCompletion = () => {
    const registerInfo = useSelector(ClaimRegisterSelector.registerInfo);
    const formDisabled = useSelector(ClaimRegisterSelector.formDisabled);
    return { registerInfo, formDisabled }
  }

  /**
   * 
   */
  export const useClaimPerson = () => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [showDamage, setShowDamage] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const { pageName, formType, formId, key } = useParams<string>();
    const location = useLocation();
    const registerInfo = useSelector(ClaimRegisterSelector.registerInfo);
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const navigate = useNavigate();

    /**
     * 
     * @returns 
     */
    const getCurrentQueryParams = (): string => {
      if (query) {
        return `?${query}`;
      }

      return '';
    }

    /**
     * 
     * @returns 
     */
    const getStepsListByFormType = (): IClaimStep[] | undefined => {
      if (formType && registerInfo) {
        // Get steps list by formType
        if (formType === CLAIM_REGISTER_FORM_TYPE.NA) {
          if (registerInfo.claimType?.isClaimRobbery()) {
            return STEPS.INTERNAL_ROBBERY;
          } else if (registerInfo.claimType?.isClaimWindscreen()) {
            return STEPS.INTERNAL_WINDSCREEN;
          }  else {
            return STEPS.INTERNAL;
          }
        } else {
          return STEPS.EXTERNAL;
        }
      }
    }

    /**
     * 
     * @param step 
     */
    const navigateByStep = (stepValue: number, replace?: boolean): void => {
      if (pageName && pageName !== CLAIM_REGISTER_PERSON_PARAMS.WELCOME) {
        // Get steps list by formType
        const stepsList = getStepsListByFormType();

        // Find step value
        if (stepsList) {
          const step = stepsList.find(s => s.value === stepValue);

          // Navigate if step exists
          if (step) {
            navigate(`${CLAIM_REGISTER_PATH.FORMS}/${formType}/${step.name}/${formId}/${key}${getCurrentQueryParams()}`, { replace: replace });
          } else if (stepValue > stepsList.length) {
            navigate(`${CLAIM_REGISTER_PATH.FORMS}/${formType}/${CLAIM_REGISTER_PERSON_PARAMS.COMPLETED}/${formId}/${key}`, { replace: true });
          }
        }
      } else {
        navigate(`${CLAIM_REGISTER_PATH.FORMS}/${formType}/${CLAIM_REGISTER_PERSON_PARAMS.NAME}/${formId}/${key}`);
      }
    }

    /**
     * 
     */
    const changeStep = (direction: 'forward' | 'backward'): void => {
      if (!showDamage) {
        const directionNumber: number = direction === 'forward' ? 1 : -1;
        const step: number = currentStep + (directionNumber * 1);
        if (!query.get('edit')) {
          // navigate using step value
          navigateByStep(step, direction === 'backward');
        } else {
          // return to Summary Page
          query.delete('edit');
          navigate(`${CLAIM_REGISTER_PATH.FORMS}/${formType}/${CLAIM_REGISTER_PERSON_PARAMS.SUMMARY}/${formId}/${key}${getCurrentQueryParams()}`, { replace: true });
        }
      } else {
        setShowDamage(false);
      }
    }

    /**
     * 
     */
    const setStepValue = useCallback(
      () => {
        if (!query.get('edit')) {
          // Get steps list by formType
          const stepsList = getStepsListByFormType();
          if (stepsList) {
            const step = stepsList.find(s => s.name === pageName);
            if (step) {
              setCurrentStep(step.value);
            } else {
              setCurrentStep(0);
            }
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageName, query]
    );

    /**
     * 
     */
    useEffect(
      () => {
        setStepValue();
      }, [setStepValue]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (pageName !== CLAIM_REGISTER_PERSON_PARAMS.DAMAGE && showDamage) {
          setShowDamage(false);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageName]
    )

    return {
      currentStep, contentRef, pageName, changeStep, setShowDamage, showDamage, getStepsListByFormType
    }
  }

  /**
   * 
   */
  export const useClaimPersonForm = () => {
    const [showUserAddress, setShowUserAddress] = useState<boolean>(true);
    const [isExternalForm, setIsExternalForm] = useState<boolean>(false);
    const [currentAddress, setCurrentAddress] = useState<Address>();
    const [licenseAlternative, setLicenseAlternative] = useState<boolean>(false);
    const [selectedLicenseType, setSelectedLicenseType] = useState<StatusTypeItem>();
    const [selectedColor, setSelectedColor] = useState<StatusTypeItem>();
    const [selectedVehicleType, setSelectedVehicleType] = useState<StatusTypeItem>();
    const [selectedDamages, setSelectedDamages] = useState<Damage[]>([]);
    const [damageToEdit, setDamageToEdit] = useState<Damage>();
    const [damageIndexToEdit, setDamageIndexToEdit] = useState<number>();
    const registerInfo = useSelector(ClaimRegisterSelector.registerInfo);
    const licenceTypes = useSelector(ClaimRegisterSelector.licenceTypes);
    const vehicleTypes = useSelector(ClaimRegisterSelector.vehicleTypes);
    const countryStates = useSelector(ClaimRegisterSelector.statesList);
    const colors = useSelector(ClaimRegisterSelector.vehicleColors);
		const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(false);
    const updating = LoadingFactory.useLoader();
    const loadingPostalCodeInfo = LoadingFactory.useLoader();
    const { formType, key } = useParams<string>();
    const location = useLocation();
    const dispatch = useDispatch();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const form = useForm<IClaimPersonForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
          savedAddress: true,
          privacy: false,
        }
      }
    );
    const navigate = useNavigate();
    const debouncePostalCode = DebounceFactory.useDebounce<string>(form.getValues('postalCode'), 1000);

    /**
     * 
     * @param damage 
     */
    const addDamage = (damage: Damage): void => {
      selectedDamages.push(damage);
      setSelectedDamages(selectedDamages);
    }

    /**
     * 
     * @param damage 
     */
    const editDamage = (damage: Damage): void => {
      const index = selectedDamages.findIndex(d => d === damage);
      if (index > -1) {
        selectedDamages[index] = damage;
        setSelectedDamages(selectedDamages);
      }
    }

    /**
     * 
     * @param index 
     */
    const removeDamage = (index: number): void => {
      selectedDamages.splice(index, 1);
      setSelectedDamages([...selectedDamages]);
    }

    /**
     * 
     */
    const onSelectAddress = (newAddress?: Address): void => {
      if (newAddress) {
        setCurrentAddress(newAddress);
        form.setValue('postalCode', newAddress.postalCode, { shouldValidate: true });
        form.trigger('postalCode');
      }
    }


    /**
     * 
     */
    const alternateLicensePictures = (remove?: boolean): void => {
      if (!remove) {
        query.set(QUERY_PARAMS.ALTERNATE_LICENCE_PICTURES, 'true');
      } else {
        query.delete(QUERY_PARAMS.ALTERNATE_LICENCE_PICTURES);
      }
      navigate(`${location.pathname}?${query}`, { replace: true });
    }


    /**
     * 
     * @param id 
     */
    const selectLicenceTypeById = (id: string): void => {
      if (licenceTypes) {
        const types = licenceTypes.find(l => l.id === id);
        if (types) {
          setSelectedLicenseType(types);
        }
      }
    }

    /**
     * 
     * @param id 
     */
    const selectColorById = (id: string): void => {
      if (colors) {
        const color = colors.find(l => l.id === id);
        if (color) {
          setSelectedColor(color);
        }
      }
    }

    /**
     * 
     * @param id 
     */
    const selectVehicleTypeById = (id: string): void => {
      if (vehicleTypes) {
        const type = vehicleTypes.find(l => l.id === id);
        if (type) {
          setSelectedVehicleType(type);
        }
      }
    }

    /**
     * 
     * @param damage 
     * @param index 
     */
    const setDamageEdition = (damage?: Damage, index?: number): void => {
      setDamageToEdit(damage);
      setDamageIndexToEdit(index);
    }

    /**
     * 
     */
    const sendCurrentRegisterInfo = async (completed: boolean, pictures?: string[], picturesType?: TClaimPictureName): Promise<void> => {
      try {
        if (registerInfo && key) {
          registerInfo.setPersonValues(form.getValues('name'), form.getValues('lastName'), form.getValues('secondLastName'), form.getValues('email'));
          registerInfo.setProfileValues(form.getValues('countryCode'), form.getValues('phone'), form.getValues('birthdate'));
          
          if (!form.getValues('postalCode') || !isPostalCodeValid)
            registerInfo.setAddressValues(undefined, undefined, undefined, undefined, undefined, undefined);
          else
            registerInfo.setAddressValues(form.getValues('postalCode'), currentAddress?.name, form.getValues('municipality'), form.getValues('state'), currentAddress?.latitude, currentAddress?.longitude);

          registerInfo.setLicenceValues(form.getValues('licenseNumber'), selectedLicenseType, form.getValues('licenseState'), form.getValues('licenseStartDate'), form.getValues('licenseEndDate'));
          registerInfo.setVehicleValues(form.getValues('vehicleBrand'), form.getValues('vehicleModel'), form.getValues('vehicleYear'), form.getValues('vin'), selectedColor, form.getValues('plates'), selectedVehicleType);
          registerInfo.setDamages(selectedDamages);
          registerInfo.facts = form.getValues('facts');
          if (form.getValues('signature') && completed) {
            registerInfo.signature = form.getValues('signature');
          }

          if (vehicleTypes && !registerInfo.vehicleType && !isExternalForm) {
            const type = vehicleTypes.find(v => v.isCarStatusType());
            if (type) {
              registerInfo.vehicleType = type
            }
          }

          updating.set(true);
          const newInfo = await ClaimRegisterFeature.claimPersonInfoUpdateFeature(key, registerInfo, completed, pictures, picturesType);
          if (newInfo) {
            dispatch(ClaimRegisterAction.setNewClaimRegisterInfo(newInfo));
          }
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
        throw e;
      } finally {
        updating.set(false);
      }
    }

    /**
     * 
     */
    const setCurrentRegisterInfo = useCallback(
      (info: ClaimRegister) => {
        form.setValue('name', info.person?.name!);
        form.setValue('lastName', info.person?.lastName!);
        form.setValue('secondLastName', info.person?.secondLastName!);
        form.setValue('birthdate', info.person?.profile?.birthdate?.toDateString()!);
        form.setValue('email', info.person?.email!);
        form.setValue('phone', info.person?.profile?.phone?.number!);
        if (isExternalForm) {
          form.setValue('postalCode', info.person?.profile?.address?.postalCode!);
        }
        form.setValue('facts', info.facts!);
        form.setValue('licenseState', info.license?.stateName!);
        form.setValue('licenseNumber', info.license?.number!);
        form.setValue('licenseStartDate', info.license?.startDate?.toDateString()!);
        form.setValue('licenseEndDate', info.license?.endDate?.toDateString()!);
        form.setValue('vin', info.vehicle?.vin!);

        form.setValue('vehicleBrand', info.vehicle?.brand?.name!);
        form.setValue('vehicleModel', info.vehicle?.model?.name!);
        if (info.vehicle && info.vehicle.year && info.vehicle.year.value > 0) {
          form.setValue('vehicleYear', info.vehicle?.year?.value.toString()!);
        }
        form.setValue('plates', info.vehicle?.plate!);
        form.trigger();

        if (info.person?.profile?.address && isExternalForm) {
          setCurrentAddress(info.person?.profile?.address);
        }

        if (info.license?.type) {
          setSelectedLicenseType(info.license.type);
        }

        if (info.vehicleType) {
          setSelectedVehicleType(info.vehicleType);
        }

        if (info.vehicleColor) {
          setSelectedColor(info.vehicleColor);
        }

        if (info.damages.length > 0) {
          let damagesList: Damage[] = [];
          for (const dmg of info.damages) {
            const newStateDmg: Damage = new Damage(dmg.type);
            newStateDmg.id = dmg.id;
            for (const picture of dmg.pictures) {
              let statePicture: ClaimRegisterPicture = new ClaimRegisterPicture(picture.id, picture.url, picture.name);
              if (picture.type) {
                statePicture.type = new StatusTypeItem(picture.type.id, picture.type?.name);
              }
              newStateDmg.pictures.push(statePicture);
            }
            damagesList.push(newStateDmg);
          }
          setSelectedDamages(damagesList);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps 
      [isExternalForm]
    )

    /**
     * 
     */
    const getStatesByPostalCode = useCallback(
      async () => {
        try {
          if (debouncePostalCode !== '') {
            loadingPostalCodeInfo.set(true);
            const address = await ClaimRegisterFeature.countryStateByPostalCodeFeature(debouncePostalCode);
            if (address) {
              form.setValue('municipality', address.municipality);
              form.setValue('state', address.state);
              setCurrentAddress((_address) => {
                if (_address) {
                  _address.municipality = address.municipality;
                  _address.state = address.state;
                }
                return _address;
              })
              setIsPostalCodeValid(true);
              form.trigger();
            } else {
              setIsPostalCodeValid(false);
              form.setValue('municipality', '');
              form.setValue('state', '');
              form.trigger('municipality');
              setCurrentAddress(undefined);
              AlertFactory.errorAlert('No reconocemos ese código postal.')
            }
            
          }
        } catch (e) {

        } finally {
          loadingPostalCodeInfo.set(false);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [debouncePostalCode]
    );

    /**
     * Select address in case user select stored option.
     */
    useEffect(() => {
      const hasAddress = registerInfo && registerInfo.person?.profile?.address;
      if (form.getValues('savedAddress') && hasAddress) {
        setCurrentAddress(registerInfo.person?.profile?.address)
      } else 
        setCurrentAddress(undefined);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.getValues('savedAddress')])

    /**
     * 
     */
    useEffect(
      () => {
        const inePictures = query.get('ine');
        if (inePictures) {
          setLicenseAlternative(true);
        } else {
          setLicenseAlternative(false);
        }

      }, [location, query]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (debouncePostalCode !== undefined) {
          getStatesByPostalCode();
        }
      }, [debouncePostalCode, getStatesByPostalCode]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (formType === CLAIM_REGISTER_FORM_TYPE.THIRD_PARTY) {
          setIsExternalForm(true);
        }
      }, [formType]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (registerInfo) {
          setCurrentRegisterInfo(registerInfo);
        }
      }, [registerInfo, setCurrentRegisterInfo]
    );

    /**
     * 
     */
    useEffect(
      () => {
        form.trigger();
      }, [location.pathname, form]
    )

    return {
      form,
      updating,
      isExternalForm,
      registerInfo,
      sendCurrentRegisterInfo,
      list: { licenceTypes, countryStates, colors, vehicleTypes },
      states: { selectedLicenseType, selectedColor, selectedVehicleType, isPostalCodeValid, selectLicenceTypeById, selectColorById, selectVehicleTypeById, selectedDamages },
      formAddress: { showUserAddress, setShowUserAddress, onSelectAddress, loadingPostalCodeInfo, currentAddress },
      formLicense: { licenseAlternative, alternateLicensePictures },
      formDamages: { addDamage, removeDamage, damageToEdit, damageIndexToEdit, setDamageEdition, editDamage }
    }
  }

}
import { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { CoreVinValidationFeature } from "src/core/features/vin-validation.feature";
import { Util } from "src/utils/Util";
import { IEndorsementConfigurationForm } from "../interfaces/IEndorsementConfigurationForm";
import { IEndorsementCurrentValue } from "../interfaces/IEndorsementCurrentValue";
import { IEndorsementType } from "../interfaces/IEndorsementType";
import { PolicyEndorsementsFacade } from "./policy-endorsements.facade";

export namespace EndorsementConfigurationFormFacade {

  /**
   * 
   */
  const VIN_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'VIN obligatorio'
    },
    {
      key: 'pattern',
      message: 'El formato del VIN es inválido.',
    },
    {
      key: 'rules',
      message: 'El VIN que ingresaste ya está en uso con otra póliza.'
    }
  ];

  /**
   * 
   */
  const PLATE_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'Placa obligatoria'
    },
    {
      key: 'pattern',
      message: 'El formato de la placa es inválido.',
    },
    {
      key: 'minLength',
      message: 'La placa debe tener al menos 5 caracteres.',
    },
  ];

  /**
   * 
   * @returns 
   */
  export const useEndorseType = () => {
    const { currentPolicy } = PolicyEndorsementsFacade.useEndorsementsActors();
    const [typeSelected, setTypeSelected] = useState<IEndorsementType>();
    const [currentValue, setCurrentValue] = useState<IEndorsementCurrentValue | undefined>();
    const [valueEmitted, setValueEmitted] = useState<boolean>(false);
    const [newValue, setNewValue] = useState<string>('');
    const [isValidatingRule, setIsValidatingRule] = useState<boolean>(false);
    const { register, formState: { errors, isValid }, clearErrors, reset, getValues, watch } = useForm<IEndorsementConfigurationForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    const onChangeNewValue = (value: string): void => {
      setNewValue(value);
    }

    /**
     * 
     * @returns 
     */
    const isTypeVin = (type?: IEndorsementType): boolean => {
      if (type) {
        return type.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.VIN ? true : false;
      } else {
        return typeSelected && typeSelected.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.VIN ? true : false;
      }
    }

    /**
     * 
     * @returns 
     */
    const isTypePlate = (type?: IEndorsementType): boolean => {
      if (type) {
        return type.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.PLATE ? true : false;
      } else {
        return typeSelected && typeSelected.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.PLATE ? true : false;
      }
    }

    /**
     * 
     * @returns 
     */
    const fieldError = (): FieldError | undefined => {
      if (isTypeVin()) {
        return errors.vin;
      } else if (isTypePlate()) {
        return errors.plate;
      }
    }

    /**
     * 
     * @returns 
     */
    const fieldSchema = (): TFormErrorMessage[] => {
      if (isTypeVin()) {
        return VIN_ERRORS;
      } else if (isTypePlate()) {
        return PLATE_ERRORS;
      } else {
        return [];
      }
    }

    /**
     * 
     */
    const errorType = (): string | undefined => {
      if (isTypeVin()) {
        return errors.vin?.type;
      } else if (isTypePlate()) {
        return errors.plate?.type;
      }
    }

    /**
     * 
     */
    const onVerifyCurrentValue = (type: IEndorsementType) => {
      clearErrors();
      reset({
        plate: '',
        vin: ''
      });

      if (isTypeVin(type)) {
        setCurrentValue({ value: currentPolicy.vehicle?.vin!, maxLength: 17, pattern: Util.PATTERN.VEHICLE_VIN });
      } else if (isTypePlate(type)) {
        setCurrentValue({ value: currentPolicy.vehicle?.plate!, maxLength: 10, pattern: Util.PATTERN.VEHICLE_PLATE });
      }
    }

    /**
     * 
     * @param value 
     */
    const validateRules = async (value: string | undefined): Promise<boolean | undefined> => {
      try {
        if (isTypeVin() && value) {
          if (Util.PATTERN.VEHICLE_VIN.test(value.toUpperCase())) {
            setIsValidatingRule(true);
            const validation = await CoreVinValidationFeature.validateVinFeature(value.toUpperCase());
            return validation;
          }
        } else {
          return true;
        }
      } catch (e) {

      } finally {
        setIsValidatingRule(false);
      }
    }


    return {
      currentValue, onVerifyCurrentValue, typeSelected, setTypeSelected, valueEmitted, setValueEmitted, validateRules, isValidatingRule, newValue, onChangeNewValue,
      form: { register, formState: { errors, isValid }, fieldError, fieldSchema, errorType, getValues, watch }
    }
  }
}
import { Checkbox, Dropdown, FormError, InputLabel } from "src/components";
import { useEffect, memo } from "react";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleManagementFormFacade } from "src/libs/VehicleManagement/controller/facades/vehicle-management-form.facade";
import { VehicleManagementFacade } from "src/libs/VehicleManagement/controller/facades/vehicle-management.facade";
import { IconFactory } from "src/factory/icon.factory";
import { Util } from "src/utils/Util";

import './VehiclePolicyManagement.scss';

/**
 *
 */
interface IVehiclePolicyManagement {

  storedVehicle?: Vehicle;

	subType?: 'basic' | 'aditional' | 'complete';

  onComplete?: (vehicle?: Vehicle) => void;
}

/**
 *
 */
const VehiclePolicyManagement = (props: IVehiclePolicyManagement): JSX.Element => {
  const { onComplete, storedVehicle, subType } = props;
  const { brandsList, modelsList, yearsList, versionsList, isLoadingBrands, isLoadingModels, isLoadingYears, isLoadingVersions } = VehicleManagementFacade.useVehicleLists();
  const { vehicle, onSelectBrand, onSelectModel, onSelectYear, onSelectVersion } = VehicleManagementFacade.useVehicle();
  const { validateVehicleData } = VehicleManagementFacade.useVehicleManagement();
  const { form, validateVehicleVin, VIN_ERRORS, PLATE_ERRORS } = VehicleManagementFormFacade.useVehiclePolicyForm(storedVehicle);

  useEffect(() => {
    if (onComplete) {
      if (vehicle && validateVehicleData(vehicle) && form.formState.isValid) {
				if (subType === 'complete') {
					vehicle.plate = form.getValues().plate.toLocaleUpperCase();
					vehicle.vin = form.getValues().vin.toLocaleUpperCase();
				}
        onComplete(vehicle);
			} else {
        onComplete();
      }
    }
  }, [vehicle, form.formState.isValid, validateVehicleData, form.getValues, onComplete, form, subType])

	useEffect(( ) => {
		if (onComplete) {
			if (subType === 'aditional' && form.formState.isValid) {
				let _vehicle = new Vehicle();
				_vehicle.plate = form.getValues().plate.toLocaleUpperCase();
				_vehicle.vin = form.getValues().vin.toLocaleUpperCase();
				onComplete(_vehicle);
			}
		}
	}, [form, form.formState.isValid, subType, onComplete])

  return (
    <form>
      <div className="sections-wrapper">
				{
					(subType === 'basic' || subType === 'complete') &&
					<>
						<div className="inline-input">
							<Dropdown
								required
								defaultValue={brandsList?.length && storedVehicle ? { id: storedVehicle?.brand?.id as number, value: storedVehicle?.brand?.name as string } : undefined}
								onChange={(e) => onSelectBrand(e.id ? e.id : e.value)}
								isLoading={isLoadingBrands}
								placeholder="Marca del auto"
								notFoundText="Aún no aseguramos esta marca"
								items={brandsList ? brandsList.map(b => { return { id: b.id, value: b.name } }) : []}
							>
								Marca
							</Dropdown>
						</div>
						<div className="inline-input">
							<Dropdown
								required
								defaultValue={modelsList?.length && storedVehicle ? { id: storedVehicle?.model?.id as number, value: storedVehicle?.model?.name as string } : undefined}
								onChange={(e) => onSelectModel(e.id ? e.id : e.value)}
								isLoading={isLoadingModels}
								placeholder="Modelo del auto"
								notFoundText="Aún no aseguramos este modelo"
								items={modelsList ? modelsList.map(m => { return { id: m.id, value: m.name } }) : []}
							>
								Modelo
							</Dropdown>
						</div>
						<div className="inline-input">
							<Dropdown
								required
								defaultValue={yearsList?.length && storedVehicle ? { id: storedVehicle?.year?.value as number, value: (storedVehicle?.year?.value as number).toString() } : undefined}
								onChange={(e) => onSelectYear(e.id ? e.id as number : +e.value)}
								isLoading={isLoadingYears}
								placeholder="Año del auto"
								notFoundText="Aún no aseguramos este año"
								items={yearsList ? yearsList.map(y => { return { id: y.value, value: y.value.toString() } }) : []}
							>
								A&ntilde;o
							</Dropdown>
						</div>
						<div className="inline-input">
							<Dropdown
								required
								defaultValue={versionsList?.length && storedVehicle ? { id: storedVehicle?.version?.id as number, value: storedVehicle?.version?.description as string } : undefined}
								onChange={(e) => onSelectVersion(e.id as number)}
								isLoading={isLoadingVersions}
								placeholder="Versión del auto"
								notFoundText="Aún no aseguramos esta versión"
								items={versionsList ? versionsList.map(v => { return { id: v.id, value: v.description } }) : []}
							>
								Versi&oacute;n
							</Dropdown>
						</div>
					</>
				}

				{
					(subType === 'aditional' || subType === 'complete') &&
					<>
						<div className="inline-input relative">
							<InputLabel
								required
								className="uppercase"
								type="text"
								maxLength={17}
								defaultValue={storedVehicle ? storedVehicle.vin : ''}
								errors={!!form.formState.errors.vin || (!!form.formState.touchedFields.vin && form.formState.errors.vin)}
								autoComplete="off"
								{...form.register("vin", {
										required: true,
										pattern: Util.PATTERN.VEHICLE_VIN,
										validate: { invalidVin: async (vin) => await validateVehicleVin(vin) }
									})
								}
							>
								VIN
							</InputLabel>
							<FormError schema={VIN_ERRORS} type={form.formState.errors.vin?.type} />
							<div className="info-wrapper flex">
								{IconFactory.infoIcon()}
								<p className="flex-grow-70">Encuéntralo en tu tarjeta de circulación o en el parabrisas de tu auto o en tu póliza de seguro como VIN o NIV. Es un número de 17 caracteres alfanuméricos</p>
							</div>
						</div>

						<div className="inline-input">
							<InputLabel
								required
								className="uppercase"
								defaultValue={storedVehicle ? storedVehicle.plate : ''}
								type="text"
								maxLength={50}
								errors={!!form.formState.errors.plate || (!!form.formState.touchedFields.plate && form.formState.errors.plate)}
								{...form.register("plate", { required: true, pattern: Util.PATTERN.VEHICLE_PLATE, minLength: 5, maxLength: 7 })}
							>
								Placas
							</InputLabel>
							<FormError schema={PLATE_ERRORS} type={form.formState.errors.plate?.type} />
							<div className="info-wrapper flex">
								{IconFactory.infoIcon()}
								<p className="flex-grow-70">Asegúrate que tus placas coincidan con las de tu auto. Debe tener entre 5-7 caracteres alfanuméricos</p>
							</div>
						</div>
					</>
				}
      </div>

			{
				(subType === 'aditional' || subType === 'complete') &&
				<>
					<div className="form-section">
						<Checkbox
							{...form.register("vehicleDataCertification", { required: true })}
						>
							Certifico que mi veh&iacute;culo no es de <b>uso comercial</b> (Uber, Didi, otros).
						</Checkbox>
					</div>
					<div className="form-section">
						<Checkbox
							{...form.register("vehicleDataTerms", { required: true })}
						>
							Acepto los <a target="_blank" href="https://crabi.com/legal/terminos-y-condiciones" rel="noreferrer">Términos y Condiciones</a>, actuando a cuenta propia.
						</Checkbox>
					</div>
				</>
			}

    </form>
  );
};

export default memo(VehiclePolicyManagement);

//export default VehiclePolicyManagement;
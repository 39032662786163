
const BASE_URL: string = '';

export const DOCUMENT_SIGNATURE_PAGES = {
  CONFIRMATION: 'confirmation',
  APPROVAL: 'approval',
  COMPLETED: 'completed',
}

/**
 * 
 */
export const COMMON_PATH = {

  INDEITY_COMPLETED: `${BASE_URL}/identity-validation/completed`,

  USER_EMAIL_VALIDATION: `${BASE_URL}/email-validation`,

  DOCUMENT_SIGUNATURE: `${BASE_URL}/document/signature`
}
import { Button, Checkbox, Spinner, Dropdown, InputCard, InputLabel } from "src/components";
import { PolicyPaymentMethodFacade } from "src/modules/policy/controller/facades/policy-payment-method.facade";
import { PolicyFacade } from "src/modules/policy/controller/facades/policy.facade";
import { Util } from "src/utils/Util";

import './PolicyPaymentMethod.scss';

/**
 *
 */
interface IPolicyPaymentMethod {

}

/**
 *
 */
const PolicyPaymentMethodPage = (props: IPolicyPaymentMethod): JSX.Element => {
  const { selectedPolicy } = PolicyFacade.usePolicyManagement();
  const { form, MONTHS, YEARS } = PolicyPaymentMethodFacade.usePolicyPaymentMethodForm();
  const { initCardUpdate, setComponentCard, isComponentCardCompleted, updating } = PolicyPaymentMethodFacade.usePolicyPaymentMethod();

  return (
    <div className="policyPaymentMethod height-100 relative flex space-around">
      {
        selectedPolicy ?
          <>
            <section className="policyPaymentMethod__section">
              <h2>Tu tarjeta 💳</h2>
              <div className="policyPaymentMethod__section__payment-card background-image image-contain relative" style={{ backgroundImage: `url(${Util.ASSET.POLICY_PAYMENT_CARD})` }}>
                <p className="absolute text-center">
                  <span>****</span>
                  <span>****</span>
                  <span>****</span>
                  <span>{selectedPolicy.paymentMethod?.cardDigits}</span>
                </p>
                <span className="background-image image-contain block absolute" style={{ backgroundImage: `url(${selectedPolicy.paymentMethod?.getBrandLogo()})` }}></span>
              </div>
            </section>
            <section className="policyPaymentMethod__section policyPaymentMethod__section--max-width">
              <h2>Cambiar m&eacute;todo de pago</h2>
              <p>Si deseas cambiar el m&eacute;todo de pago, puedes completar el siguiente formulario</p>

              <form autoComplete="off" className="policyPaymentMethod__section__form">

                {
                  selectedPolicy.paymentMethod?.isProviderByForm() ?
                    <>
                      <section className="policyPaymentMethod__section__form__section policyPaymentMethod__section__form__section--card flex">
                        <InputLabel type="text"
                          placeholder="#### #### #### ####"
                          number
                          maxLength={16}
                          errors={!!form.formState.errors.cardNumber}
                          {...form.register('cardNumber', { required: true, validate: (value) => { return value.length >= 15 ? true : false } })}
                        >
                          N&uacute;mero de tarjeta
                        </InputLabel>
                        <InputLabel type="text"
                          placeholder="###"
                          number
                          maxLength={4}
                          className="policyPaymentMethod__section__form__section__input-ccv"
                          errors={!!form.formState.errors.cvv}
                          {...form.register('cvv', { required: true, validate: (value) => { return value.length >= 3 ? true : false } })}
                        >
                          CVV
                        </InputLabel>

                      </section>

                      <section className="policyPaymentMethod__section__form__section policyPaymentMethod__section__form__section--margin-top">
                        <h3>
                          Fecha de expiraci&oacute;n
                        </h3>
                        <div className="flex space-between">
                          <Dropdown
                            placeholder="Mes de expiración"
                            cancelSearch
                            onChange={item => { form.setValue('month', item.id as number); form.trigger('month') }}
                            items={MONTHS.map(m => { return { id: m, value: `${m < 10 ? '0' : ''}${m.toString()}` } })}>
                            Mes
                          </Dropdown>
                          <Dropdown
                            placeholder="Año de expiración"
                            cancelSearch
                            onChange={item => { form.setValue('year', item.id as number); form.trigger('year') }}
                            items={YEARS.map(y => { return { id: y, value: y.toString() } })}>
                            A&ntilde;o
                          </Dropdown>

                          <input type="hidden" {...form.register('month', { required: true })} />
                          <input type="hidden" {...form.register('year', { required: true })} />
                        </div>
                      </section>
                    </>
                    :
                    <section className="policyPaymentMethod__section__form__section">
                      <InputCard onComplete={setComponentCard} />
                    </section>
                }

                <section className="policyPaymentMethod__section__form__section">
                  <InputLabel type="text"
                    required
                    placeholder="Como aparece en la tarjeta"
                    errors={!!form.formState.errors.owner}
                    {...form.register("owner", { required: true, pattern: Util.PATTERN.NAMES, minLength: 2 })}
                  >
                    Nombre del titular
                  </InputLabel>
                </section>
                <p>
                  Verifica que los datos est&eacute;n correctos. Tus pr&oacute;ximos cobros ser&aacute;n aplicados a esta tarjeta
                </p>
   
                <section className="policyPaymentMethod__section__form__section">
                  <Checkbox
                    {...form.register("terms", { required: true })}
                  >
                    Acepto que Crabi haga cobro mensual por la p&oacute;liza.
                  </Checkbox>
                </section>

                <div className="policyPaymentMethod__section__form__submit flex justify-flex-end">
                  <Button type="button"
                    disabled={!form.formState.isValid || (selectedPolicy.paymentMethod?.isProviderByComponent() && !isComponentCardCompleted) || updating.value}
                    loading={updating.value}
                    onClick={(e) => { e.preventDefault(); form.formState.isValid && initCardUpdate(form.getValues()) }}>
                    Actualizar tarjeta
                  </Button>
                </div>

              </form>

            </section>
          </>
          :
          <Spinner color="main" circles />
      }
    </div>
  );
};

export default PolicyPaymentMethodPage;
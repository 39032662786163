
import PolicyDocumentFile from "../PolicyDocumentFile/PolicyDocumentFile";
import { useEffect, useRef, useState } from "react";
import { IconFactory } from "src/factory/icon.factory";
import { PolicyDocumentFacade } from "src/modules/policy/controller/facades/policy-document.facade";
import './PolicyDocuments.scss';
import { Policy } from "src/classes/Policy";

/**
 *
 */
interface IPolicyDocuments {

  currentPolicy: Policy;

}

/**
 *
 */
const PolicyDocuments = (props: IPolicyDocuments): JSX.Element => {
  const [showDocuments, setShowDocuments] = useState<boolean>(false);
  const { getTerms, loadingTerms } = PolicyDocumentFacade.usePolicyDocumentTerms();
  const { getTermsSumary, loadingTermsSumary  } = PolicyDocumentFacade.usePolicyDocumentTermsSumary();
  const { getCover, loadingCover  } = PolicyDocumentFacade.usePolicyCover(props.currentPolicy);
  const { getReceipt, loadingReceipt  } = PolicyDocumentFacade.usePolicyReceipt(props.currentPolicy);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const closeCalendar = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowDocuments(false);
      }
    }

    document.addEventListener("mousedown", closeCalendar);
    return () => {
      document.removeEventListener("mousedown", closeCalendar);
    };
  }, [wrapperRef]);

  return (
    <div className="policy-documents-wrapper relative" ref={wrapperRef}>
      <div className="documents-toggle flex align-center" onClick={(e) => { setShowDocuments(!showDocuments); e.preventDefault() }}>
        <span>Documentos de tu p&oacute;liza</span>
        {IconFactory.downloadIcon()}
      </div>

      {
        showDocuments &&
        <div className="documents">
          <PolicyDocumentFile onClickDocument={() => {getCover(); setShowDocuments(false)}} loading={loadingCover} primary>
            <span>Tu P&oacute;liza</span>
            {IconFactory.policyDownloadIcon()}
          </PolicyDocumentFile>
          <PolicyDocumentFile onClickDocument={() => {getReceipt(); setShowDocuments(false)}} loading={loadingReceipt}>
            <span>Recibo de pago</span>
            {IconFactory.receiptIcon()}
          </PolicyDocumentFile>
          <PolicyDocumentFile onClickDocument={() => {getTerms(); setShowDocuments(false)}} loading={loadingTerms}>
            <span>Condiciones Generales</span>
            {IconFactory.termsIcon()}
          </PolicyDocumentFile>
          <PolicyDocumentFile onClickDocument={() => { getTermsSumary(); setShowDocuments(false)}} loading={loadingTermsSumary} last>
            <span>Resumen Condiciones Generales</span>
            {IconFactory.sumaryTermsIcon()}
          </PolicyDocumentFile>
        </div>
      }

    </div>
  );
};

export default PolicyDocuments;
import { useState } from "react";
import { useNavigate } from "react-router";
import { POLICY_CREATION_PATH } from "src/routes/paths/policy-creation.paths";

/**
 *
 */
 export namespace PolicyVehicleSalvageFacade {

  /**
   * 
   */
  export const usePolicyVehicleSalvage = () => {

		const [showSalvageModal, setShowSalvageModal] = useState<boolean>(false);
    const navigate = useNavigate();

    /**
     * 
     * @param value 
     */
    const nextPage = (): void => {
			navigate(POLICY_CREATION_PATH.POLICY_OWNER);
    }

    return { showSalvageModal, setShowSalvageModal, nextPage };
  }

}
import { Endorse } from "src/classes/policy/Endorse";
import { Util } from "src/utils/Util";
import { IApplyEndorseRequest } from "../interfaces/IApplyEndorseRequest";

/**
 * 
 */
export namespace PolicyEndorsementsAdapter {

  /**
   * 
   */
  export const applyEndorseRequestAdapter = (endorsements: Endorse[]): IApplyEndorseRequest => {
    let data: IApplyEndorseRequest = {
      endorse: {
        source: '1'
      }
    }

    for(const endorse of endorsements) {
      if (endorse.vehicle) {
        data['vehicle'] = {
          vin: endorse.vehicle.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.VIN? endorse.vehicle.after.vin : data['vehicle']?.vin,
          plates: endorse.vehicle.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.PLATE? endorse.vehicle.after.plate : data['vehicle']?.plates,
        }
      }
    }

    return data;
  }
}
import { useForm } from "react-hook-form";
import { IRecoveryForm } from "../interfaces/IRecoveryForm";

export namespace ForgotPasswordFormFacade {

  const RECOVERY_ERRORS: TFormErrorMessage[] = [
    {
      key: 'pattern',
      message: 'Ingresa un correo electrónico válido.',
    },
    {
      key: 'required',
      message: 'Ingresa el correo electrónico.',
    },
  ]

  /**
   * 
   * @returns 
   */
  export const useRecoveryForm = () => {
    const { register, formState: { errors, isValid }, handleSubmit, watch, getValues } = useForm<IRecoveryForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    return { RECOVERY_ERRORS, register, formState: { errors, isValid }, handleSubmit, watch, getValues }
  }

  /**
   * 
   * @returns 
   */
  export const useRestoreForm = () => {
    const { register, setValue } = useForm<IRecoveryForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    return { register, setValue }
  }
}
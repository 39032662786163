import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SIGN_OUT_PATH } from "src/routes/paths/sign-out.paths";
import { Util } from "src/utils/Util";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";

export namespace SessionTimerFacade {

  const IDLE_TIME_IN_SECONDS_LIMIT: number = 180;

  export const useSessionTimer = (isValidAuth: boolean) => {
    const [idleWarning, setIdleWarning] = useState<boolean>(false);
    const navigate = useNavigate()

    /**
     * 
     */
    const sessionAction = useCallback(
      () => {
        if (isValidAuth) {
          localStorage.setItem(Util.KEY.SESSION_ACTION, JSON.stringify(Date.now()));
        }
      }, [isValidAuth]
    );

    /**
     * 
     */
    const resetSessionAction = (): void => {
      localStorage.setItem(Util.KEY.SESSION_ACTION, JSON.stringify(Date.now()));
      setIdleWarning(false);
    }

    /**
     * 
     */
    useEffect(
      () => {
        if (isValidAuth) {
          const storedTime: number = JSON.parse(localStorage.getItem(Util.KEY.SESSION_ACTION)!);
          if (!storedTime) {
            sessionAction();
          } else {
            const time = Math.round((Date.now() - storedTime) / 1000);
            if (time >= IDLE_TIME_IN_SECONDS_LIMIT) {
              if (EnvironmentFactory.ENV.REACT_APP_API_ENV === 'prod') {
                navigate(SIGN_OUT_PATH.SIGN_OUT, { replace: true });
              }
            }
          }
        }
      }, [isValidAuth, sessionAction, navigate]
    )

    /**
     * 
     * @returns 
     */
    const useMountEffect = () => useEffect(
      () => {
        if (EnvironmentFactory.ENV.REACT_APP_API_ENV === 'prod') {
          setInterval(
            () => {
              const isAuthenticated: boolean = localStorage.getItem(Util.KEY.TOKEN_STORAGE) ? true : false;
              if (isAuthenticated && !idleWarning) {
                const storedTime: number = JSON.parse(localStorage.getItem(Util.KEY.SESSION_ACTION)!);
                const countTime: number = Math.round((Date.now() - storedTime) / 1000);
                if (countTime >= IDLE_TIME_IN_SECONDS_LIMIT) {
                  setIdleWarning(true);
                }
              }
            }, 1000
          )
        }
      }, []
    );
    useMountEffect();

    return { sessionAction, resetSessionAction, idleWarning }
  }
}
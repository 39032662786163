import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import { Policy } from "src/classes/Policy";
import { PaymentMethod } from "src/classes/policy/PaymentMethod"
import { PaymentRecord } from "src/classes/policy/PaymentRecord";
import { PolicyPaymentAdapter } from "../adapters/policy-payment.adapter";
import { ICardForm } from "../interfaces/ICardForm";
import { PolicyPaymentService } from "../services/policy-payment.service";

export namespace PolicyPaymentFeature {

  /**
   * 
   * @param policy 
   * @returns 
   */
  export const currentPaymentMethodFeature = async (policyId: string): Promise<PaymentMethod | undefined> => {
    try {
      const { data } = await PolicyPaymentService.currentPaymentMethodService(policyId);
      return PolicyPaymentAdapter.paymentMethodDataAdapter(data);
    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      return undefined;
    }
  }

  /**
   * 
   * @param policyNumber 
   */
  export const paymentHistoryFeature = async (policyNumber: string): Promise<PaymentRecord[] | undefined> => {
    try {
      const { data } = await PolicyPaymentService.paymentHistoryService(policyNumber);
      const paymentHistory = PolicyPaymentAdapter.paymentHistoryDataAdapter(data);
      return paymentHistory;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   * @param cardData 
   */
  export const paymentMethodUpdateByFormFeature = async (card: ICardForm, currentPolicy: Policy): Promise<void | undefined> => {
    try {
      const request = PolicyPaymentAdapter.paymentMethodTokenByFormAdapter(card);
      const { data } = await PolicyPaymentService.paymentMethodTokenByFormService(request);
      const updateRequest = PolicyPaymentAdapter.paymentMethodRequestAdapter(data.id);
      await PolicyPaymentService.updatePaymentMethod(updateRequest, currentPolicy.id);
    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }

  /**
   * 
   * @param card 
   * @param currentPolicy 
   */
  export const paymentMethodUpdateByComponentFeature = async (cardForm: ICardForm, currentPolicy: Policy, lib: { stripe: Stripe, elements: StripeElements }): Promise<void | undefined> => {
    try {
      const data = await lib.stripe.createPaymentMethod({ type: 'card', card: lib.elements.getElement(CardElement)!, billing_details: { name: cardForm.owner } });
      const updateRequest = PolicyPaymentAdapter.paymentMethodRequestAdapter(data?.paymentMethod?.id!);
      await PolicyPaymentService.updatePaymentMethod(updateRequest, currentPolicy.id);
    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }

  /**
   * 
   * @param policyNumber 
   */
  export const policyNextPaymentFeature = async (policyNumber: string): Promise<PaymentRecord | undefined> => {
    try {
      const { data } = await PolicyPaymentService.policyNextPaymentService(policyNumber);
      const record = PolicyPaymentAdapter.policyNextPaymentAdapter(data);
      return record;
    } catch (e) {
      console.error(e);
    }
  }
}
import { IconFactory } from 'src/factory/icon.factory';
import { PolicyEndorsementsFacade } from "src/modules/policy-endorsements/controller/facades/policy-endorsements.facade";
import { POLICY_ENDORSEMENTS_PATH } from "src/routes/paths/policy-endorsements.paths";
import StepSegment from '../../molecules/StepSegment/StepSegment';
import './EndorsementsSteps.scss';

type StepCompletion = {

  verification: boolean;

  configuration: boolean;

  sumary: boolean;

}


/**
 *
 */
interface IEndorsementsSteps {

}

const stepsElement = (completion: StepCompletion, policyNumber?: string): JSX.Element => {
  return (
    <>
      <StepSegment isFirst Icon={IconFactory.confirmationNumberIcon()}
        completed={completion.verification}
        path={POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_VERIFICATION(policyNumber!)}>
        C&oacute;digo de validaci&oacute;n
      </StepSegment>
      <StepSegment Icon={IconFactory.editIcon()}
        completed={completion.configuration}
        path={POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_CONFIGURATION(policyNumber!)}>
        Datos a modificar
      </StepSegment>
      <StepSegment isLast Icon={IconFactory.shieldIcon()}
        completed={completion.sumary}
        path={POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_SUMMARY(policyNumber!)}>
        Resumen de cambios
      </StepSegment>

    </>
  );
}

/**
 *
 */
const EndorsementsSteps = (props: IEndorsementsSteps): JSX.Element => {
  const { currentPolicy } = PolicyEndorsementsFacade.useEndorsementsActors();
  const { isVerificationStepCompleted, isConfigurationStepCompleted, isEndorsementsCompleted } = PolicyEndorsementsFacade.useStepStatus();

  const completion = {
    verification: isVerificationStepCompleted(),
    configuration: isConfigurationStepCompleted(),
    sumary: isEndorsementsCompleted()
  }

  return (
    <>
      <div id="endorsements-steps-wrapper" className="flex column">
        <div className="endorsements-steps-sidebar flex-grow-100">
          {stepsElement(
            completion,
            currentPolicy?.policyNumber
          )}
        </div>

        <div className="endorsements-steps-topbar flex-grow-100 column">
          {stepsElement(
            completion,
            currentPolicy?.policyNumber
          )}
        </div>
      </div>
    </>
  );
};

export default EndorsementsSteps;
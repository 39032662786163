import { Navigate } from "react-router-dom";
import { AGREED_PAYMENTS_LIST_PATH } from "src/routes/paths/agreed-payments-list.paths";
import ActivePoliciesPage from "../ui/pages/ActivePolicies/ActivePolicies";
import ClaimsPage from "../ui/pages/Claims";
import OpenClaimsPage from "../ui/pages/OpenClaims/OpenClaims";

/**
 *
 */
export const AgreedClaimsRoutes =
  () => {
    return {
      path: AGREED_PAYMENTS_LIST_PATH.AGREED_PAYMENTS_LIST,
      element: <ClaimsPage />,
      children: [
        { path: `${AGREED_PAYMENTS_LIST_PATH.AGREED_PAYMENTS_LIST}/`, element: <Navigate to={`${AGREED_PAYMENTS_LIST_PATH.ACTIVE_POLICIES}`} replace/> }, 
        {
          path: AGREED_PAYMENTS_LIST_PATH.OPEN_CLAIMS,
          element: <OpenClaimsPage />,
        },
        {
          path: AGREED_PAYMENTS_LIST_PATH.ACTIVE_POLICIES,
          element: <ActivePoliciesPage />,
        },
      ],
    };
  };

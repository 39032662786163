import { SkeletonLoader } from "src/components";
import { ReferralsFacade } from "src/modules/referrals/controller/facades/referrals.facade";
import { Util } from "src/utils/Util";
import RewardsHistorySkeletonLoader from "../../atoms/RewardsHistorySkeletonLoader/RewardsHistorySkeletonLoader";

import "./RewardsHistory.scss";

/**
 *
 */
interface IRewardsHistory {}

/**
 *
 */
const RewardsHistoryPage = (props: IRewardsHistory): JSX.Element => {
  const { rewardsHistoryList, tBodyRef, rewardsLoading, onScrollHandler } =
    ReferralsFacade.useRewards();

  return (
    <div id="rewards-history-wrapper" className="flex column space-between height-100">
      <div className="rewards-history-list">
        {rewardsHistoryList !== undefined ? (
          <>
            <section>
              <div className="text-center">
                <h1>Historial de recompensas</h1>
              </div>
            </section>

            <section>
              {rewardsHistoryList?.length > 0 ? (
                <div className="table relative">
                  <div className="table-header">
                    <div>
                      <h4>Titulo</h4>
                    </div>
                    <div>
                      <h4>Código Cupon</h4>
                    </div>
                    <div>
                      <h4>Fecha de canje</h4>
                    </div>
                  </div>
                  <div className="table-body" ref={tBodyRef} onScroll={onScrollHandler}>
                    {rewardsHistoryList?.map((reward) => (
                      <div key={reward.id} className="table-row">
                        <div>
                          <p>{reward.title}</p>
                        </div>
                        <div>
                          <p>{reward.code}</p>
                        </div>
                        <div>
                          <p>{reward.getExchangeDate()}</p>
                        </div>
                      </div>
                    ))}
                    {rewardsLoading ? (
                      <div className="table-row table-row-loading">
                        <SkeletonLoader />
                        <SkeletonLoader />
                        <SkeletonLoader />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div id="reward-history-empty" className="flex column justify-center align-center height-100">
                  <img alt="empty-rewards-history" src={Util.ASSET.EMPTY_REWARDS_HISTORY}/>
                  <h2 className="text-center">
                    Por ahora no has
                    <br />
                    <strong> solicitado recompensas.</strong>
                  </h2>
                  <h3 className="text-center">
                    Cuando reclames una recompensa, podrás ver aquí el
                    historial.
                  </h3>
                </div>
              )}
            </section>
          </>
        ) : (
          <RewardsHistorySkeletonLoader />
        )}
      </div>
    </div>
  );
};

export default RewardsHistoryPage;

import { SkeletonLoader } from "src/components";

import './ReferralsSkeletonLoader.scss';

/**
 *
 */
interface IReferralsSkeletonLoader {

}

/**
 *
 */
const ReferralsSkeletonLoader = (props: IReferralsSkeletonLoader): JSX.Element => {
  return (
    <>
      <div className="current-user-referrals-info skeleton-referrals flex space-evenly">
        <section className="flex flex-grow-100 column align-center justify-center">
          <SkeletonLoader />
          <SkeletonLoader />
        </section>
        <section className="flex flex-grow-100 column align-center justify-center">
          <div className="flex width-100">
            <section className="flex flex-grow-100 column align-center justify-center">
              <SkeletonLoader />
              <SkeletonLoader />
            </section>
            <section className="flex flex-grow-100 column align-center justify-center">
              <SkeletonLoader />
              <SkeletonLoader />
            </section>
          </div>
          <div className="flex width-100 align-center justify-center">
          </div>

        </section>
        <section className="flex flex-grow-100 column align-center justify-center">
          <SkeletonLoader />
        </section>
      </div>

      <div className="rewards-list flex column">
        <SkeletonLoader />
        <ul>
          <li className="flex align-center">
            <SkeletonLoader type="square" />
          </li>
          <li className="flex align-center">
            <SkeletonLoader type="square" />
          </li>
          <li className="flex align-center">
            <SkeletonLoader type="square" />
          </li>
          <li className="flex align-center">
            <SkeletonLoader type="square" />
          </li>
          <li className="flex align-center">
            <SkeletonLoader type="square" />
          </li>
          <li className="flex align-center">
            <SkeletonLoader type="square" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default ReferralsSkeletonLoader;
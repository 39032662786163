import VehicleManagement from "src/libs/VehicleManagement/ui/template/VehicleManagement";
import { DamageClaimNavigateValidationFacade } from "src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade";
import { VehicleDataFacade } from "src/modules/claim-payments/controller/facades/vehicle-data.facade";
import ButtonSection from "../../molecules/ButtonSection/ButtonSection";
import "./VehicleDataPage.scss";



/**
 *
 */
const VehicleDataPage = (): JSX.Element => {
  const {vehicle, selectedVehicle, isAgreedPayment, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, selectVehicle } = VehicleDataFacade.useVehicleDataFacade();
  const { isLoading, createVehicle } = VehicleDataFacade.useCreateVehicle();
	const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id="vehicle-data-wrapper" className="height-100">
      <section className="content height-100">
        <h1>Datos del vehículo</h1>
        <p className="info">
          Cuéntanos de tu vehículo para
          <br /> comenzar tu solicitud.
        </p>

        <div className="vehicle-management-container">
          <VehicleManagement
            onComplete={selectVehicle}
            storedVehicle={vehicle}
            type="claims"
          />
        </div>
      </section>

      <ButtonSection
        backHandler={() => navigationHandler( isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'backward')}
        nextHandler={() => createVehicle(selectedVehicle!)}
        isLoading={isLoading}
        nextButtonDisabled={selectedVehicle === undefined}
      />
    </div>
  );
};

export default VehicleDataPage;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { CoreSelector } from "src/core/selectors/core.selector";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { Util } from "src/utils/Util";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsSaga } from "../sagas/claim-payments.saga";
import { ClaimPaymentsSelector } from "../selectors/claim-payments.selector";
import { ClaimPaymentsSlice } from "../slices/claim-payments.slice";

/**
 *
 */
export namespace ClaimPaymentsFacade {

	/**
   * Returns current policy creation actors: Person (current user or new Person), current vehicle, current plan selected.
   */
	export const useDamageClaimActors = () => {
		const currentDamageClaim = useSelector(ClaimPaymentsSelector.currentDamageClaim);
		const currentPath = useSelector(ClaimPaymentsSelector.currentPath);
		const claimUser = useSelector(ClaimPaymentsSelector.claimUser);
		const claimVehicle = useSelector(ClaimPaymentsSelector.claimVehicle);
		const currentUser = useSelector(CoreSelector.currentUser);
		const currentVehicle = useSelector(ClaimPaymentsSelector.currentVehicle);
		const dataStep = useSelector(ClaimPaymentsSelector.dataStep);
		const picturesStep = useSelector(ClaimPaymentsSelector.picturesStep);
		const documentsStep = useSelector(ClaimPaymentsSelector.documentsStep);
		const accountStep = useSelector(ClaimPaymentsSelector.accountStep);
		const proposalAvailable = useSelector(ClaimPaymentsSelector.proposalAvailable);
		const isAgreedPayment = useSelector(ClaimPaymentsSelector.isAgreedPayment);
		const isLoadingDamageClaim = useSelector(ClaimPaymentsSelector.isLoadingDamageClaim)

		return { 
			currentDamageClaim, 
			currentPath,
			claimUser, 
			claimVehicle, 
			currentUser, 
			currentVehicle, 
			dataStep, 
			picturesStep, 
			documentsStep, 
			accountStep ,
			proposalAvailable,
			isAgreedPayment,
			isLoadingDamageClaim
		};
	}

	/**
	 * 
	 */
	export const useClaimPaymentFacade = () => {
		/** */
		useInjectReducer({ key: 'claimPayments', reducer: ClaimPaymentsSlice.reducer });
		useInjectSaga({ key: 'claimPayments', saga: ClaimPaymentsSaga.claimPaymentSaga });
		/** */
		const dispatch = useDispatch();
		const navigate = useNavigate();
		/** */
		const { search, pathname } = useLocation();
		const searchParams = useMemo(() => new URLSearchParams(search), [search]);
		const [checked, updateChecked] = useState(false);

		const policyNumberParam = searchParams.get("policy-number");
		const claimIdParam = searchParams.get("claim");
		const { currentDamageClaim, isAgreedPayment } = useDamageClaimActors();

		/** */
		const setCurrentClaimPolicy = useCallback((policyNumber: string) => {
			dispatch(ClaimPaymentsAction.policyByNumber(policyNumber));
		}, [dispatch]);

		/** */
		const getClaimById = useCallback((claimId: string) => {
			dispatch(ClaimPaymentsAction.getDamageClaimById({ damageClaim: claimId, isAgreedPayment: isAgreedPayment! }));
		}, [dispatch, isAgreedPayment]);

		/** */
		const checkDamageClaimStatus = useCallback(() => {
			let missingProposal: boolean = currentDamageClaim! && currentDamageClaim.checkAllPhotosApproved(isAgreedPayment) && !currentDamageClaim.proposal;

			if(currentDamageClaim?.status === Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.PENDING_PROPOSAL){
				if(isAgreedPayment)
					navigate(`${AGREED_PAYMENTS_PATH.PROPOSAL}${search}`, { replace: true });
				else
					navigate(`${CLAIM_PAYMENTS_PATH.PROPOSAL}${search}`, { replace: true });
			}else if(currentDamageClaim?.status === Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.PENDING_PAYMENT || currentDamageClaim?.status === Util.STATUS_CODE.DAMAGE_CLAIM_STATUS.PAID || missingProposal){
				if(isAgreedPayment)
					navigate(`${AGREED_PAYMENTS_PATH.COMPLETION}${search}`, { replace: true });
				else
					navigate(`${CLAIM_PAYMENTS_PATH.COMPLETION}${search}`, { replace: true });
			}
		}, [currentDamageClaim, navigate, search, isAgreedPayment]);

		/** */
		useEffect(() => {
			if (policyNumberParam && policyNumberParam !== '')
				setCurrentClaimPolicy(policyNumberParam);

			if (claimIdParam && claimIdParam !== '')
				getClaimById(claimIdParam)
		}, [policyNumberParam, claimIdParam, setCurrentClaimPolicy, getClaimById]);

    /** */
		useEffect(() => {
			if(currentDamageClaim?.vehicle && currentDamageClaim?.vehicle.vin.length > 0){
				dispatch(ClaimPaymentsAction.storeVehicle(currentDamageClaim.vehicle!))
			}
		}, [currentDamageClaim, dispatch]);

		/** */
		useEffect(() => {
			if(!checked && currentDamageClaim){
				checkDamageClaimStatus();
				updateChecked(true);
			}
		}, [currentDamageClaim, checked, checkDamageClaimStatus]);

    /** */
    useEffect(() => {
      return () => {
        dispatch(ClaimPaymentsAction.clearDamageClaim())
      }
    }, [dispatch])

		/** */
		useEffect(() => {
			const agreedRouteCondition = pathname.includes(AGREED_PAYMENTS_PATH.AGREED_PAYMENTS);
			dispatch(ClaimPaymentsAction.updateIsAgreedPayment(agreedRouteCondition));
		}, [pathname, dispatch])
	}
}

import PlanPaymentManagement from "src/libs/PlanPaymentManagement/ui/template/PlanPaymentManagement";
import { PlanPaymentRenewalFacade } from "src/modules/policy-renewal/controller/facades/plan-payment-renewal.facade";
import { PlanReviewRenewalFacade } from "src/modules/policy-renewal/controller/facades/plan-review-renewal.facade";
import { PolicyRenewalReturnValidactionFacade } from "src/modules/policy-renewal/controller/facades/policy-renewal-return-validation.facade";

import './PlanRenewalPayment.scss';


/**
 *
 */
const PlanRenewalPaymentPage = (): JSX.Element => {
  const { onReturn, steps } = PolicyRenewalReturnValidactionFacade.useReturnValidation();
  const { plan, currentPolicy } = PlanReviewRenewalFacade.usePlanReviewRenewal();
  const { onCheckoutDone } = PlanPaymentRenewalFacade.usePlanPayment(plan);
	
  return (
    <>
      {
        plan &&
					<PlanPaymentManagement
						plan={plan}
						policy={currentPolicy}
						onPaymentDone={onCheckoutDone}
						onReturn={() => onReturn(steps.payment)}
					/>
      }
    </>
  );
};

export default PlanRenewalPaymentPage;
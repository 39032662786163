import { PropsWithChildren } from 'react';
import './StepName.scss';

/**
 *
 */
interface IStepName {

}

/**
 *
 */
const StepName = (props: PropsWithChildren<IStepName>): JSX.Element => {
  return (
    <span className="step-endorsement-name inline-block vertical-align-middle">
      {props.children}
    </span>
  );
};

export default StepName;
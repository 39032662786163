import { Button } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { OnboardingFacade } from "src/modules/claim-payments/controller/facades/onboarding.facade";
import "./OnboardingPage.scss";

/**
 *
 */
interface IOnboarding {}

/**
 *
 */
const OnboardingPage = (props: IOnboarding): JSX.Element => {
  /** */
  const {
    step,
    crabelerStatus,
		isAgreedPayment,
    updateCrabelerStatus,
    authHandler,
    stepHandler,
  } = OnboardingFacade.useOnboardingFacade();

  return (
    <div id="onboarding-wrapper" className="height-100">
      <h1>Solicitud de pago { isAgreedPayment ? 'pactado' : 'de daños'} </h1>
      {step === 0 ? (
        <>
          <h3>Para comenzar tu proceso toma en cuenta lo siguiente:</h3>
          <ul>
						{
							!isAgreedPayment &&
								<li>
									{IconFactory.checkIcon({ fill: "#fff" })}
									<span>Estar cerca de tu auto, para tomar algunas fotos de la unidad.</span>
								</li>
						}
						<li>
							{IconFactory.checkIcon({ fill: "#fff" })}
							<span>Tener a la mano tu tarjeta de circulación y una identificación oficial.</span>
						</li>
						<li>
							{IconFactory.checkIcon({ fill: "#fff" })}
							<span>Contar con la factura del coche y un estado de cuenta que esté a nombre del dueño del auto.</span>
						</li>
          </ul>

          <div className="bottom-wrapper">
            <div className="left-side">
              <h4>¿Y si no tengo todo a la mano?</h4>
              <div className="info">
                No te preocupes, puedes ir llenando los datos disponibles y
                completar la información cuando tengas la demás documentación.
              </div>
            </div>
            <div className="button-wrapper">
              <Button onClick={authHandler}>Comenzar mi solicitud</Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>¡Bienvenido!</h3>
          <p>
            Ayúdanos a personalizar tu proceso. <br /> Por favor, identifícate.
          </p>
          <h3>¿Tienes seguro de auto con Crabi?</h3>

          <div
            className={`crabeler-option ${crabelerStatus === 1 && "active"}`}
            onClick={() => updateCrabelerStatus(1)}
          >
            <span>Sí, estoy asegurado con Crabi. Soy Crabeler.</span>
            <div>{IconFactory.checkIcon({ fill: "#fff" })}</div>
          </div>

          <div
            className={`crabeler-option ${crabelerStatus === 2 && "active"}`}
            onClick={() => updateCrabelerStatus(2)}
          >
            <span>Todavía no. Soy un afectado por uno de sus asegurados.</span>
            <div>{IconFactory.checkIcon({ fill: "#fff" })}</div>
          </div>

					<Button
						className="start-button"
						disabled={crabelerStatus < 1 ? true : false}
						onClick={stepHandler}
					>
						Empezar
					</Button>
        </>
      )}
    </div>
  );
};

export default OnboardingPage;

export class DocumentSignature {

  /**
   *
   */
  private _formId: string;
  set formId(_formId: string) { this._formId = _formId };
  get formId(): string { return this._formId };

  /**
   *
   */
  private _documentId: string;
  set documentId(_documentId: string) { this._documentId = _documentId };
  get documentId(): string { return this._documentId };

  /**
   *
   */
  private _key: string;
  set key(_key: string) { this._key = _key };
  get key(): string { return this._key };

  /**
   *
   */
  private _personName: string;
  set personName(_personName: string) { this._personName = _personName };
  get personName(): string { return this._personName };

  /**
   *
   */
  private _documentName: string;
  set documentName(_documentName: string) { this._documentName = _documentName };
  get documentName(): string { return this._documentName };

  /**
   *
   */
  private _typeId: string;
  set typeId(_typeId: string) { this._typeId = _typeId };
  get typeId(): string { return this._typeId };

  /**
   *
   */
  private _fileUrl: string;
  set fileUrl(_fileUrl: string) { this._fileUrl = _fileUrl };
  get fileUrl(): string { return this._fileUrl };

  /**
   *
   */
  private _signature: string | undefined;
  set signature(_signature: string | undefined) { this._signature = _signature };
  get signature(): string | undefined { return this._signature };

  /**
   *
   */
  private _completed: boolean;
  set completed(_completed: boolean) { this._completed = _completed };
  get completed(): boolean { return this._completed };

  constructor(_formId: string, _key: string, _fileUrl: string) {
    this._formId = _formId;
    this._key = _key;
    this._fileUrl = _fileUrl;
    this._documentName = '';
    this._documentId = '';
    this._personName = '';
    this._typeId = '';
    this._completed = false;
  }
}
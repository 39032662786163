import classNames from "classnames";
import { PropsWithChildren, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './MenuLink.scss';

/**
 *
 */
interface IMenuLink {

  path: string;

  onNavigate?: () => void;
}

/**
 *
 */
const MenuLink = (props: PropsWithChildren<IMenuLink>): JSX.Element => {
  
  const [isMouseIn, setIsMouseIn] = useState<boolean>(false);
  const location = useLocation();

  const matchPath = location.pathname.includes(props.path);

  const linkClasses = classNames({
    'selected': isMouseIn || matchPath
  }
  );


  return (
    <Link to={props.path} className={`menu-link-wrapper flex relative ${linkClasses}`}
    onMouseEnter={() => setIsMouseIn(true)} 
    onMouseLeave={() => setIsMouseIn(false)} 
    onClick={props.onNavigate ? props.onNavigate : undefined}
    >
      <span>
        {props.children}
      </span>
    </Link>
  );
};

export default MenuLink;
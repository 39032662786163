import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { Plan } from "src/classes/plan/Plan";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { PolicyRenewalAction } from "../actions/policy-renewal.action";
import { PolicyRenewalSlice } from "../slices/policy-renewal.slice";

/**
 *
 */
export namespace PolicyRenewalSaga {

  type PolicyByNumber = SagaReturnType<typeof CorePolicyFeature.policyByNumberFeature>;

  /**
   * 
   * @param action 
   */
  function* policyByNumber(action: PayloadAction<string>) {
    const policy: PolicyByNumber = yield call(CorePolicyFeature.policyByNumberFeature, action.payload);
    if (policy) {
      yield put(PolicyRenewalSlice.actions.currentPolicy(policy!));
    } else {
      yield put(PolicyRenewalSlice.actions.invalidPolicy(true));
    }
  }


  /**
   * 
   * @param action 
   */
  function* setPlan(action: PayloadAction<Plan>) {
    yield put(PolicyRenewalSlice.actions.setPlan(action.payload));
  }

  /**
   * 
   * @param action 
   */
  function* removePlan() {
    yield put(PolicyRenewalSlice.actions.removePlan());
  }

  /**
   * 
   */
  function* clearRenewal() {
    yield put(PolicyRenewalSlice.actions.clear());
  }

  export function* policyRenewalSaga() {
    yield takeLatest(PolicyRenewalAction.policyByNumber.type, policyByNumber);
    yield takeLatest(PolicyRenewalAction.clearRenewal.type, clearRenewal);
    yield takeLatest(PolicyRenewalAction.setPlan.type, setPlan);
    yield takeLatest(PolicyRenewalAction.removePlan.type, removePlan);
  }
}
import { FileAttachment } from "src/classes/FileAttachment"
import { PictureManagementAdapter } from "../adapters/picture-management.adapter";
import { IPictureUploadResponse } from "../interfaces/IPictureUploadResponse";
import { PictureManagementService } from "../services/picture-management.service";

/**
 *
 */
export namespace PictureManagementFeature {

  /**
   * 
   * @param attachment 
   */
  export const uploadPictureFeature = async (formData: FormData, attachment: FileAttachment): Promise<FileAttachment | undefined> => {
    try {
			const requestData = PictureManagementAdapter.pictureUploadRequestAdapter(formData, attachment);
			let response: IPictureUploadResponse;
			let updatedAttachment: FileAttachment;

			if(attachment.uid){
				const { data } = await PictureManagementService.updateClaimPictureService(requestData);
				response = data;
			}else{
				const { data } = await PictureManagementService.uploadClaimPictureService(requestData);
				response = data;
			}

			updatedAttachment = PictureManagementAdapter.pictureUploadResponseAdapter(response, attachment);
			return updatedAttachment;
    } catch(e) {

    }
  }
}
import EndorsementsSteps from "../organisms/PolicySteps/EndorsementsSteps";
import { ButtonBack, Spinner } from "src/components";
import { Outlet } from "react-router-dom";
import { PolicyEndorsementsFacade } from "../../controller/facades/policy-endorsements.facade";

import './PolicyEndorsements.scss';

/**
 *
 */
interface IPolicyEndorsements {

}

/**
 *
 */
const PolicyEndorsementsPage = (props: IPolicyEndorsements): JSX.Element => {
  const { allowCreateEndorse, isEndorseCompleted } = PolicyEndorsementsFacade.usePolicyEndorsements();
  const { currentPolicy } = PolicyEndorsementsFacade.useEndorsementsActors();
  const { onReturn } = PolicyEndorsementsFacade.useReturnValidation();
  return (
    <div id="policy-endorsements-wrapper" className="flex">
      {
        allowCreateEndorse() &&
        <EndorsementsSteps />
      }
      <div className="endorsements-content flex-grow-100 relative">
        {
          !isEndorseCompleted &&
          <ButtonBack button overlay text to={''} onClick={onReturn} />
        }
        {
          currentPolicy ?
            <Outlet></Outlet>
            :
            <Spinner color="main" />
        }
      </div>

    </div>
  );
};

export default PolicyEndorsementsPage;
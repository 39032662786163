import { ChangeEvent, createRef, KeyboardEvent, useCallback, useEffect, useState } from 'react';

/**
 *
 */
export namespace OtpInputcodeFacade {


  export const useOtpInputCode = (code: string, setCode: React.Dispatch<React.SetStateAction<string>>) => {
    const [elementWrapper, setElementWrapper] = useState<HTMLElement | null>();
    const [justClicked, setJustClicked] = useState<boolean>(false);
    const ref = createRef<HTMLInputElement>();

    /**
     * 
     * @param direction 
     * @param fieldName 
     * @param fieldIndex 
     */
    const findInput = (direction: 1 | -1, fieldName: string, fieldIndex: string): void => {
      const sibling = elementWrapper?.querySelector(`input[name=${fieldName}-${parseInt(fieldIndex) + (direction * 1)}]`) as HTMLInputElement;
      // If found, focus the next field
      if (sibling !== null) {
        sibling.focus();
      }
    }

    /**
     * 
     * @param ev 
     */
    const onCodeChangeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
      const element = ev.target as HTMLInputElement;
      const { maxLength, value, name } = element;
      const [fieldName, fieldIndex] = name.split("-");

      setCode((currentValue) => {
        return `${currentValue}${value}`;
      });

      setJustClicked(false);
      if (elementWrapper && value.length >= maxLength) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex) < elementWrapper.childNodes.length) {
          // Get the next input field
          findInput(1, fieldName, fieldIndex);
        } else {
          element.blur();
        }
      }
    }

    /**
     * 
     * @param ev 
     */
    const onCodeKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
      const element = ev.target as HTMLInputElement;
      const { value, name } = element;
      const [fieldName, fieldIndex] = name.split("-");

      if (elementWrapper && ev.key === 'Backspace') {  
        setCode((currentValue) => {
          return `${currentValue.substring(0, currentValue.length - 1)}`;
        });

        if (value.length === 0 && parseInt(fieldIndex) > 0) {
          // Get the previous input field
          findInput(-1, fieldName, fieldIndex);
        }

        //Clear inputs
        if (parseInt(fieldIndex) === 1) {
          setCode('');
          elementWrapper?.childNodes.forEach(
            (node: ChildNode) => {
              (node as HTMLInputElement).value = '';
            }
          );
        }

      } else if (elementWrapper && /^[a-z0-9]$/i.test(ev.key)) {
        //Clear inputs
        if (justClicked && code.length > 0) {
          setJustClicked(false);
          setCode('');
          elementWrapper?.childNodes.forEach(
            (node: ChildNode, key: number) => {
              (node as HTMLInputElement).value = '';

            }
          );
        }
      }
    }

    /**
     * 
     */
    const onCodePaste = (ev: React.ClipboardEvent<HTMLInputElement>): void => {
      if (ev.clipboardData.getData('text') && elementWrapper) {
        let clipboardCode: string = '';
        const clipboardData: string = ev.clipboardData.getData('text');
        elementWrapper?.childNodes.forEach(
          (node: ChildNode, key: number) => {
            clipboardCode = `${clipboardCode}${clipboardData[key]}`;
            (node as HTMLInputElement).value = clipboardData[key];
            (node as HTMLInputElement).focus();
          }
        );
        setCode(clipboardCode);
      }
    }

    const onSelectInput = useCallback(
      () => {
        if (elementWrapper) {
          const input = elementWrapper.childNodes[0] as HTMLInputElement;
          if (input) {
            input.focus();
            setJustClicked(true);
          }
        }
      }, [elementWrapper]
    );

    /**
     * 
     * @returns 
     */
    const useMountEffect = () => useEffect(
      () => {
        setElementWrapper(document.getElementById('otp-code'));
        onSelectInput();
        if (ref.current) {
          ref.current.focus();
        }
      }, []
    );
    useMountEffect()

    return { onCodeChangeHandler, onCodeKeyDown, onSelectInput, onCodePaste, ref }
  }


}
import { Navigate } from "react-router-dom";
import { REFERRALS_PATH } from "src/routes/paths/referrals.paths";
import ReferralsPage from '../ui/pages/Referrals';
import RewardDetailsPage from "../ui/pages/RewardDetails/RewardDetails";
import RewardsPage from "../ui/pages/Rewards/Rewards";
import RewardsHistoryPage from "../ui/pages/RewardsHistory/RewardsHistory";

/**
 *
 */
export const ReferralsRoutes = () => {
  return {
    path: REFERRALS_PATH.REFERRALS,
    element: <ReferralsPage />,
    children: [
      { path: `${REFERRALS_PATH.REFERRALS}/`, element: <Navigate to={`${REFERRALS_PATH.REWARDS}`} replace /> },
      {
        path: `${REFERRALS_PATH.REWARDS}`,
        element: <RewardsPage />,
        children: [
          { path: `${REFERRALS_PATH.REWARD_DETAILS_PATH}`, element: <RewardDetailsPage /> }
        ]
      },
      { path: `${REFERRALS_PATH.REWARDS_HISTORY}`, element: <RewardsHistoryPage /> },
    ]
  }
}
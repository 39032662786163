import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInjectSaga } from "redux-injectors";
import { Person  } from "src/classes/Person";
import { AlertFactory } from "src/factory/alert.factory";
import { INIT_PATH } from "src/routes/paths/init.paths";
import { LoginAction } from "../actions/login.action";
import { CoreLoginFeature } from "src/core/features/login.feature";
import { LoginSaga } from "../saga/login.saga";
import { LoginFeature } from "../features/login.feature";
import { EventFactory } from "src/factory/event.factory";

export namespace LoginFacade {

  /**
	 * 
	 */
  export const useLoginFacade = () => {

    useInjectSaga({ key: 'login', saga: LoginSaga.loginSaga });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [missingEmailValidation, setMissingEmailValidation] = useState<boolean>(false);
    const [currentToken, setCurrentToken] = useState<string>('')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();

    const closeEmailValidation = (): void => {
      setMissingEmailValidation(false);
    }
    
		/** */
		const loginAmplitudeEvent = (email: string): void => {
			EventFactory.event.setUserId(email);
			EventFactory.event.track(EventFactory.EVENT_NAMES.LOGIN);
		}

    /**
     * 
     * @param user 
     */
    const initLogin = async (email: string, password: string): Promise<void> => {
      try {
        setIsLoading(true);

        let user: Person = new Person(email);
        user.password = password;

        const token = await CoreLoginFeature.loginFeature(user);

        if (token && LoginFeature.isEmailValidatedFeature(token)) {
					loginAmplitudeEvent(user.email);
          navigate(`${INIT_PATH.INIT}${search}`);
          dispatch(LoginAction.userLogin(token!));
        } else {
          if (!missingEmailValidation) {
            setMissingEmailValidation(true);
            setCurrentToken(token?.access!);
          } else {
            AlertFactory.errorAlert('El correo electrónico no está validado.');
          }
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        setIsLoading(false);
      }
    }

    return { isLoading, initLogin, missingEmailValidation, currentToken, closeEmailValidation };
  }
}



import ESign from "src/libs/ESign/ui/template/ESign";
import { Text } from "src/components";
import './ClaimSignature.scss';
import { useEffect } from "react";

/**
 *
 */
interface IClaimSign {

  personFullName: string;

  onSign: (signature?: string) => void;

  onLoad?: () => void;

  label: string;

  defaultImage?: string;
}

/**
 *
 */
const ClaimSignature = (props: IClaimSign): JSX.Element => {

  useEffect(
    () => {
      if (props.onLoad) {
        props.onLoad();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className="claimSignature">
      <div className="claimSignature__content">
        <ESign onApply={(eSign) => props.onSign(eSign)} defaultImage={props.defaultImage} />
      </div>

      <div className="claimSignature__name">
        <Text type="label" align="center" block>{props.label}</Text>
        <Text type="paragraph" align="center" bold>{props.personFullName}</Text>
      </div>
    </div>
  );
};

export default ClaimSignature;
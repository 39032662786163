import Header from "src/libs/Header/ui/template/Header";
import { ProductLimit } from "src/components";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import { AppRoutes } from "src/routes/routes";
import { AuthValidationFacade } from "../../controller/facades/auth-validation.facade";
import { ProductLimitFacade } from "../../controller/facades/product-limit.facade";
import { SessionTimerFacade } from "../../controller/facades/session-timer.facade";
import { BodyFactory } from "src/factory/body.factory";
import Idle from "../atoms/idle/Idle";
import './Main.scss';

/**
 *
 * @returns
 */
const Main = (): JSX.Element => {
  AuthValidationFacade.useAuthValidationFacade();
  const { sessionAction, resetSessionAction, idleWarning } = SessionTimerFacade.useSessionTimer(AuthValidationFacade.useValidAuthFacade());
  const { showAlert, setShowAlert } = ProductLimitFacade.useProductLimit();
  const routing = useRoutes(AppRoutes.routes(AuthValidationFacade.useValidAuthFacade()));
  AuthValidationFacade.useAuthValidationFacade();

  return (
    <BodyFactory.BodyElement onClick={sessionAction}>
      <>
        <Header />
        {/* <div>
        <div>
          <img src="/assets/plan-bg.svg" alt="bg" />
        <div></div>

        </div>
      </div> */}
        <div><Toaster containerStyle={{ zIndex: 99999 }} /></div>
        <ProductLimit show={showAlert} onClose={() => setShowAlert(false)} />
        <Idle show={idleWarning} onHandleAction={() => resetSessionAction()} />

        {routing}
      </>
    </BodyFactory.BodyElement>
    // <div id="main" >
    // </div>
  );
};

export default Main;

import { AxiosPromise } from "axios";
import { Util } from "src/utils/Util";
import { IVehicleVinValidationData } from "../interfaces/vehicle/IVinValidationData";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";


export namespace CoreVinValidationService {

  /**
   * 
   * @param params 
   */
  export const validateVinService = (vin: string): AxiosPromise<IVehicleVinValidationData> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.VERIFY_VEHICLE_VIN(vin));
  }
}
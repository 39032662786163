import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { InsuranceVehicleAction } from "../actions/insurance-vehicle.action";
import { InsuranceVehicleSlice } from "../slices/insurance-vehicle.slice";

/**
 *
 */
export namespace InsuranceVehicleSaga {
  /**
   * 
   */
  function* picturesCompleted(action: PayloadAction<boolean>) {
    yield put(InsuranceVehicleSlice.actions.pictureCompleted(action.payload));
  }

  /**
   * 
   */
  function* clear(action: PayloadAction<void>) {
    yield put(InsuranceVehicleSlice.actions.clear());
  }

  export function* insuranceVehicleSaga() {
    yield takeLatest(InsuranceVehicleAction.picturesCompleted.type, picturesCompleted);
    yield takeLatest(InsuranceVehicleAction.clear.type, clear)
  }
}
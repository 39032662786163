export class InvoiceFile {

  /**
   *
   */
  private _name: string;
  set name(_name: string) { this._name = _name };
  get name(): string { return this._name };

  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };

  /**
   *
   */
  private _pdfBase64: string;
  set pdfBase64(_pdfBase64: string) { this._pdfBase64 = _pdfBase64 };
  get pdfBase64(): string { return this._pdfBase64 };

  /**
   *
   */
  private _xmlBase64: string;
  set xmlBase64(_xmlBase64: string) { this._xmlBase64 = _xmlBase64 };
  get xmlBase64(): string { return this._xmlBase64 };

  /**
   * 
   */
  private _isComplement: boolean;
  set isComplement(_isComplement: boolean) { this._isComplement = _isComplement };
  get isComplement(): boolean { return this._isComplement };

  /**
   *
   */
  private _fileNumber: number;
  set fileNumber(_fileNumber: number) { this._fileNumber = _fileNumber };
  get fileNumber(): number { return this._fileNumber };

  constructor(_name: string, _id: string) {
    this._name = _name;
    this._id = _id;

    this._pdfBase64 = '';
    this._xmlBase64 = '';
    this._isComplement = false;
    this._fileNumber = 0;
  }
}
import classNames from "classnames";
import { PropsWithChildren } from "react";
import ClaimPaymentSidebarIndicator from "../../atoms/ClaimPaymentSidebarIndicator/ClaimPaymentSidebarIndicator";
import ClaimPaymentSidebarItem from "../../atoms/ClaimPaymentSidebarItem/ClaimPaymentSidebarItem";
import "./ClaimPaymentSidebarSection.scss";

/**
 *
 */
interface IClaimPaymentSidebarSection {
  /** */
  step: number;
  /** */
  icon: JSX.Element;
  /** */
  done: boolean;
  /** */
  missingInfo?: boolean;
	/** */
	hasErrors?: boolean;
  /** */
  currentStep: number;
}

/**
 *
 */
const ClaimPaymentSidebarSection = (
  props: PropsWithChildren<IClaimPaymentSidebarSection>
): JSX.Element => {
  /** */
  const { step, icon, done, missingInfo, hasErrors, currentStep } = props;
  /** */
  const active = currentStep === step;
  /** */
  const indicatorClassNames = classNames({
    "claim-payment-sidebar-section": true,
    active: step === currentStep,
  });

  return (
    <div className={indicatorClassNames}>
      <div className="flex">
        <ClaimPaymentSidebarItem selected={active}>
          {props.children}
        </ClaimPaymentSidebarItem>
        <ClaimPaymentSidebarIndicator
          active = {active}
          icon = {icon}
          isDone = {done}
          isMissingInfo = {missingInfo!}
					hasErrors = {hasErrors!}
        />
      </div>
    </div>
  );
};

export default ClaimPaymentSidebarSection;

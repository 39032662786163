import { InvoicePremium } from "./InvoicePremium";

export class Invoice {

  private _netAmount: number;
  set netAmount(_netAmount: number) { this._netAmount = _netAmount };
  get netAmount(): number { return this._netAmount };

  private _taxes: number;
  set taxes(_taxes: number) { this._taxes = _taxes };
  get taxes(): number { return this._taxes };

	private _premiumList: InvoicePremium[];
	set premiumList(_premiumList: InvoicePremium[]) { this._premiumList = _premiumList };
  get premiumList(): InvoicePremium[] { return this._premiumList };

	constructor() {
		this._netAmount = 0;
		this._taxes = 0;
		this._premiumList = [];
	}

  /**
   * 
   * @returns 
   */
  public getTotal(): number {
    return this._taxes + this._netAmount;
  }
}
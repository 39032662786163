import { Spinner, Status } from "src/components";
import PictureDropZone from "../../atoms/PictureDropZone/PictureDropZone";
import PictureZoom from "../../atoms/PictureZoom/PictureZoom";
import { PropsWithChildren, useEffect, useState } from "react";
import { IconFactory } from "src/factory/icon.factory";
import { PictureManagementFacade } from "../../../controller/facades/picture-management.facade";
import { FileAttachment } from "src/classes/FileAttachment";

import './PictureManagement.scss';

/**
 *
 */
interface IPictureManagment {

  attachment: FileAttachment,

  defaultPicture: string;

  status?: boolean;

  allowPdf?: boolean;

  onUpload?: (attachment: FileAttachment) => void;

  onLoading?: (isLoading: boolean) => void;
}

/**
 * 
 * @param status 
 * @returns 
 */
const getStatus = (status: { isApproved?: boolean, isPending?: boolean, isRejected?: boolean }): JSX.Element | undefined => {
  if (status.isPending) {
    return <Status type="pending" shape="label">En revisi&oacute;n</Status>
  } else if (status.isApproved) {
    return <Status type="available" shape="label">Aprobado</Status>
  } else if (status.isRejected) {
    return <Status type="deactive" shape="label">Rechazado</Status>
  }
}

/**
 *
 */
const PictureManagement = (props: PropsWithChildren<IPictureManagment>): JSX.Element => {
  const [itemId, setItemId] = useState<string>('');
  const { selectedPicture, documentType, onSelectFile, loadingPicture } = PictureManagementFacade.useFileUpload(props.attachment, props.onUpload, props.onLoading, props.allowPdf);

  useEffect(
    () => {
      if (itemId === '') {
        const id = String.fromCharCode(Math.floor(Math.random() * 26) + 97)
          + Math.random().toString(16).slice(2)
          + Date.now().toString(16).slice(4)
        setItemId(id);
      }
    }, [itemId]
  );

  return (
    <div className="picture-management-wrapper relative flex column" picture-approved={props.status && props.attachment.isApproved() ? '' : null}>
      <h2>
        {props.children}
      </h2>
      <PictureDropZone onDropComplete={onSelectFile} />

      <div className="item-picture flex-grow-100 flex justify-center relative">
        {
          !props.allowPdf &&
          <>
            {
              !selectedPicture && !loadingPicture && !props.attachment.source &&
              <span className="block" style={{ backgroundImage: `url(${props.defaultPicture})` }}></span>
            }
            {
              !selectedPicture && !loadingPicture && props.attachment.source &&
              <img src={props.attachment.source} alt="Source" />
            }
            {
              selectedPicture && !loadingPicture &&
              <img src={selectedPicture} alt="Selected" />
            }
            {
              loadingPicture &&
              <Spinner />
            }
            <PictureZoom source={props.attachment.source ? props.attachment.source : selectedPicture} />

          </>
        }

        {
          props.allowPdf &&
          <>
            {
              !selectedPicture && !loadingPicture && !props.attachment.source &&
              <span className="block" style={{ backgroundImage: `url(${props.defaultPicture})` }}></span>
            }
            {
              (selectedPicture || props.attachment.source) && !loadingPicture && (props.attachment.contentType === 'application/pdf' || documentType === 'application/pdf') &&
              <div className="pdf-file">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
                  <path fill="#E7E9EC" d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z" />
                  <path fill="#D0D3D7" d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
                  <polygon fill="#E7E9EC" points="480,224 384,128 480,128 " />
                  <path fill="#F15642" d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z" />
                  <g>
                    <path fill="#FFFFFF" d="M101.7,303.2c0-4.2,3.3-8.8,8.7-8.8H140c16.6,0,31.6,11.1,31.6,32.5c0,20.2-15,31.5-31.6,31.5h-21.4v16.9   c0,5.6-3.6,8.8-8.2,8.8c-4.2,0-8.7-3.2-8.7-8.8V303.2z M118.6,310.4v31.9H140c8.6,0,15.4-7.6,15.4-15.5c0-8.9-6.8-16.4-15.4-16.4   H118.6z" />
                    <path fill="#FFFFFF" d="M196.7,384c-4.2,0-8.8-2.3-8.8-7.9v-72.7c0-4.6,4.6-7.9,8.8-7.9H226c58.5,0,57.2,88.5,1.2,88.5H196.7z    M204.7,311.1v57.3H226c34.5,0,36.1-57.3,0-57.3L204.7,311.1L204.7,311.1z" />
                    <path fill="#FFFFFF" d="M303.9,312.1v20.3h32.6c4.6,0,9.2,4.6,9.2,9.1c0,4.2-4.6,7.7-9.2,7.7h-32.6v26.9c0,4.5-3.2,7.9-7.7,7.9   c-5.6,0-9.1-3.4-9.1-7.9v-72.7c0-4.6,3.5-7.9,9.1-7.9h44.9c5.6,0,9,3.3,9,7.9c0,4.1-3.3,8.7-9,8.7L303.9,312.1L303.9,312.1z" />
                  </g>
                  <path fill="#D0D3D7" d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z" />
                </svg>
              </div>
            }
						{
							!selectedPicture && !loadingPicture && props.attachment.source && (props.attachment.contentType !== 'application/pdf' && documentType !== 'application/pdf') &&
							<img src={props.attachment.source} alt="Source" />
						}
						{
							selectedPicture && !loadingPicture && (props.attachment.contentType !== 'application/pdf' && documentType !== 'application/pdf') &&
							<img src={selectedPicture} alt="Selected" />
						}
            {
              loadingPicture &&
              <Spinner />
            }
          </>
        }
      </div>

      {
        props.status && (props.attachment.isApproved() || props.attachment.isPeding() || props.attachment.isDisapproved()) &&
        <div className="picture-status absolute">
          {getStatus({ isApproved: props.attachment.isApproved(), isPending: props.attachment.isPeding(), isRejected: props.attachment.isDisapproved() })}
        </div>
      }

      {
        !props.allowPdf ?
          <>
            <input type="file" id={itemId} accept="image/png, image/jpeg, image/jpg" style={{ display: 'none' }}
              onChange={(e) => onSelectFile(e.target.files ? e.target.files[0] : undefined)} />
            <label htmlFor={itemId} className="absolute flex align-center justify-center">
              <button type="button" className="flex align-center justify-center">
                {IconFactory.cameraIcon()}
              </button>
            </label>
          </>
          :
          <>
            <input type="file" id={itemId} accept="image/png, image/jpeg, image/jpg, application/pdf" style={{ display: 'none' }}
              onChange={(e) => onSelectFile(e.target.files ? e.target.files[0] : undefined)} />
            <label htmlFor={itemId} className="absolute flex align-center justify-center">
              <button type="button" className="flex align-center justify-center">
                {IconFactory.fileIcon()}
              </button>
            </label>
          </>
      }
    </div>
  );
};

export default PictureManagement;
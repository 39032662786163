import { AxiosError } from "axios";
import { ClaimProposal } from "src/classes/ClaimProposal";
import { Util } from "src/utils/Util";
import { ClaimProposalAdapter } from "../adapters/claim-proposal.adapter";
import { ClaimProposalService } from "../services/claim-proposal.service";

export namespace ClaimProposalFeature {

  /**
   * 
   */
  export const approveProposalNegotationFeature = async (damagePaymentId: string, proposal: ClaimProposal, signature: string, isAgreedPayment?: boolean): Promise<void | undefined> => {
    try {
      const data = ClaimProposalAdapter.approveProposalNegotationAdapter(signature);
			if (isAgreedPayment) {
				await ClaimProposalService.agreedProposalNegotationService(damagePaymentId, proposal.id, data);
			} else {
				await ClaimProposalService.proposalNegotationService(damagePaymentId, proposal.id, data);
			}
      proposal.status = Util.STATUS_CODE.CLAIM_PROPOSAL_STATUS.APPROVED;
    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }

      throw error;
    }
  }
}
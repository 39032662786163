import { Button } from "src/components";
import { PolicyCompletionFacade } from "src/modules/policy-creation/controller/facades/policy-completion.facade";
import { Util } from "src/utils/Util";
import './PolicyCompletion.scss';

/**
 *
 */
interface IPolicyCompletion {

}

/**
 *
 */
const PolicyCompletion = (props: IPolicyCompletion): JSX.Element => {
  const { finishPolicyManagment, query } = PolicyCompletionFacade.usePolicyCompletion();

  return (
    <div className="policy-completion-wrapper width-100 flex column align-center justify-center">
      <section className="text-center">
        <img alt="policy-completion" src={Util.ASSET.POLICY_COMPLETION_CAR} />
        <h1>¡Tu pago ha sido realizado con &eacute;xito!</h1>
        <h2>En un momento recibirás en tu correo <span>{query.get('user')}</span> el detalle de tu póliza. </h2>
        <p className="policy-completion-preview">
          N. P&oacute;liza: {query.get('policy')}
        </p>
        <h3>Recuerda que tu póliza estará activa a partir del d&iacute;a de ma&ntilde;ana.</h3>
        <div className="button-wrapper">
          <Button type="button" onClick={finishPolicyManagment}>Ver p&oacute;lizas</Button>
        </div>
      </section>
    </div>
  );
};

export default PolicyCompletion;
import { useEffect } from "react";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Checkbox, Dropdown, FormError, InputLabel } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { VehicleManagementFormFacade } from "src/libs/VehicleManagement/controller/facades/vehicle-management-form.facade";
import { VehicleManagementFacade } from "src/libs/VehicleManagement/controller/facades/vehicle-management.facade";
import { Util } from "src/utils/Util";

import './VehicleClaimsManagement.scss';

/**
 *
 */
interface IVehicleClaimsManagement {
  storedVehicle?: Vehicle;

  onComplete?: (vehicle?: Vehicle) => void;
}

/**
 *
 */
const VehicleClaimsManagement = (props: IVehicleClaimsManagement): JSX.Element => {

  const { onComplete, storedVehicle } = props;
  const { brandsList, modelsList, yearsList } = VehicleManagementFacade.useVehicleLists();
  const { vehicle, checkRef, onSelectBrand, onSelectModel, onSelectYear, setVehicle } = VehicleManagementFacade.useVehicle();
  const { setIsInputsFields, isInputsFields, validateVehicleClaimsData } = VehicleManagementFacade.useVehicleManagement(storedVehicle);
  const { form, PLATE_ERRORS, BRAND_NAME_ERRORS, MODEL_NAME_ERRORS, YEAR_VALUE_ERRORS, VIN_ERRORS, resetClaimsData } = VehicleManagementFormFacade.useVehicleClaimsForm(storedVehicle, isInputsFields);

  useEffect(() => {
    if (onComplete) {
      if (vehicle && form.formState.isValid && validateVehicleClaimsData(vehicle, form.getValues().brandName, form.getValues().modelName, form.getValues().yearValue)) {
        vehicle.plate = form.getValues().plate.toLocaleUpperCase();
        vehicle.vin = form.getValues().vin.toLocaleUpperCase();
        onComplete(vehicle);
      } else {
        onComplete();
      }
    }
  }, [vehicle, onComplete, form, form.getValues, validateVehicleClaimsData])

  useEffect(() => {
    if (!isInputsFields) {
      resetClaimsData();
    }
  }, [isInputsFields, resetClaimsData])

  return (
    <form>
      <div className="form-section">
        <Checkbox position="end"
          onChange={() => setIsInputsFields(!isInputsFields)}
          onClick={() => setVehicle(new Vehicle())}
          ref={checkRef}
        >
          <i>Ingresar manualmente</i>
        </Checkbox>
      </div>
      <div className="sections-wrapper">
        <div className="inline-input">
          {
            !isInputsFields ?
              <Dropdown
                required
                defaultValue={brandsList?.length && storedVehicle ? { id: storedVehicle?.brand?.id as number, value: storedVehicle?.brand?.name as string } : undefined}
                onChange={(e) => onSelectBrand(e.value)}
                placeholder="Marca del automóvil"
                items={brandsList ? brandsList.map(b => { return { id: b.id, value: b.name } }) : []}
              >
                Marca
              </Dropdown>
              :
              <InputLabel
                required
                placeholder="Escribe la marca del auto"
                type="text"
                maxLength={100}
                errors={!!form.formState.errors.brandName}
                {...form.register("brandName", {
                  required: true,
                  pattern: Util.PATTERN.ALPHANUMERIC,
                  minLength: 2
                })}
              >
                Marca
              </InputLabel>
          }
          <FormError schema={BRAND_NAME_ERRORS} type={form.formState.errors.brandName?.type} />
        </div>
        <div className="inline-input">
          {
            !isInputsFields ?
              <Dropdown
                required
                defaultValue={modelsList?.length && storedVehicle ? { id: storedVehicle?.model?.id as number, value: storedVehicle?.model?.name as string } : undefined}
                onChange={(e) => onSelectModel(e.value)}
                placeholder="Modelo del automóvil"
                items={modelsList ? modelsList.map(m => { return { id: m.id, value: m.name } }) : []}
              >
                Modelo
              </Dropdown>
              :

              <InputLabel
                required
                placeholder="Escribe el modelo del auto"
                type="text"
                maxLength={100}
                errors={!!form.formState.errors.modelName}
                {...form.register("modelName", {
                  required: true,
                  pattern: Util.PATTERN.ALPHANUMERIC,
                  minLength: 2
                })}
              >
                Modelo
              </InputLabel>
          }
          <FormError schema={MODEL_NAME_ERRORS} type={form.formState.errors.modelName?.type} />
        </div>
        <div className="inline-input">
          {
            !isInputsFields ?
              <Dropdown
                required
                defaultValue={yearsList?.length && storedVehicle ? { id: storedVehicle?.year?.value as number, value: (storedVehicle?.year?.value as number).toString() } : undefined}
                onChange={(e) => onSelectYear(e.id as number)}
                placeholder="Año del automóvil"
                items={yearsList ? yearsList.map(y => { return { id: y.value, value: y.value.toString() } }) : []}
              >
                A&ntilde;o
              </Dropdown>
              :
              <InputLabel
                required
                placeholder="Escribe la año del auto"
                type="text"
                maxLength={100}
                errors={!!form.formState.errors.yearValue}
                {...form.register("yearValue", {
                  required: true,
                  pattern: Util.PATTERN.NUMBERS,
                  minLength: 4,
                  maxLength: 4
                })}
              >
                A&ntilde;o
              </InputLabel>
          }
          <FormError schema={YEAR_VALUE_ERRORS} type={form.formState.errors.yearValue?.type} />
        </div>
        <div className="inline-input">
          <InputLabel
            required
            className="uppercase"
            defaultValue={storedVehicle ? storedVehicle.plate : ''}
            type="text"
            maxLength={50}
            errors={!!form.formState.errors.plate}
            {...form.register("plate", { required: true, pattern: Util.PATTERN.VEHICLE_PLATE, minLength: 5, maxLength: 7 })}
          >
            Placas
          </InputLabel>
          <FormError schema={PLATE_ERRORS} type={form.formState.errors.plate?.type} />

        </div>
        <div className="inline-input">
          <InputLabel
            required
            className="uppercase"
            defaultValue={storedVehicle ? storedVehicle.vin : ''}
            type="text"
            maxLength={50}
            errors={!!form.formState.errors.vin}
            {...form.register("vin", { required: true, pattern: Util.PATTERN.VEHICLE_VIN, minLength: 10, maxLength: 17 })}
          >
            VIN | Número de serie
          </InputLabel>
          <FormError schema={VIN_ERRORS} type={form.formState.errors.vin?.type} />
        </div>
      </div>
      <div className="sections-wrapper">
        <div className="info-wrapper flex">
          {IconFactory.infoIcon()}
          <p className="flex-grow-70">El Número de Identificación Vehicular VIN (Vehicle Identification Number) es un estándar internacional que consta de caracteres alfanuméricos que te permiten identificar y conocer los registros de un auto.</p>
        </div>
        <div className="info-wrapper flex">
          {IconFactory.infoIcon()}
          <p className="flex-grow-70">Asegúrate que tus placas coincidan con las de tu auto. Debe tener entre 5-7 caracteres alfanuméricos</p>
        </div>
      </div>
    </form>
  );
};

export default VehicleClaimsManagement;
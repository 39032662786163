
const BASE_URL: string = '/policy-renewal';

/**
 * 
 */
 export const POLICY_RENEWAL_PATH = {

  POLICY_RENEWAL: BASE_URL,
  POLICY_RENEWAL_PLAN_PATH: `${BASE_URL}/:policyNumber/plan`,
  POLICY_RENEWAL_REVIEW_PATH: `${BASE_URL}/:policyNumber/review`,
  POLICY_RENEWAL_PAYMENT_PATH: `${BASE_URL}/:policyNumber/payment`,
  POLICY_RENEWAL_COMPLETION_PATH: `${BASE_URL}/:policyNumber/completion`,
  POLICY_RENEWAL_PLAN: (number: string) => `${BASE_URL}/${number}/plan`,
  POLICY_RENEWAL_REVIEW: (number: string) => `${BASE_URL}/${number}/review`,
  POLICY_RENEWAL_PAYMENT: (number: string) => `${BASE_URL}/${number}/payment`,
  POLICY_RENEWAL_COMPLETION: (number: string) => `${BASE_URL}/${number}/completion`,
}
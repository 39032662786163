import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { VehicleManagementAction } from "../actions/vehicle-management.action";
import { VehicleManagementSaga } from "../sagas/vehicle-management.saga";
import { VehicleManagementSelector } from "../selectors/vehicle-management.selector";
import { VehicleManagementSlice } from "../slices/vehicle-management.slice";
/**
 *
 */
export namespace VehicleManagementFacade {

  /**
   * 
   */
  export const useVehicleLists = () => {
    const brandsList = useSelector(VehicleManagementSelector.vehicleBrandsList);
    const modelsList = useSelector(VehicleManagementSelector.vehicleModelsList);
    const yearsList = useSelector(VehicleManagementSelector.vehicleYearsList);
    const versionsList = useSelector(VehicleManagementSelector.vehicleVersionsList);
		const isLoadingBrands = useSelector(VehicleManagementSelector.isLoadingBrands);
		const isLoadingModels = useSelector(VehicleManagementSelector.isLoadingModels);
		const isLoadingYears = useSelector(VehicleManagementSelector.isLoadingYears);
		const isLoadingVersions = useSelector(VehicleManagementSelector.isLoadingVersions);

    return { brandsList, modelsList, yearsList, versionsList, isLoadingBrands, isLoadingModels, isLoadingYears, isLoadingVersions };
  }

  /**
   * 
   */
  export const useVehicle = () => {
    useInjectSaga({ key: 'vehicleManagement', saga: VehicleManagementSaga.vehicleDataSaga });
    useInjectReducer({ key: 'vehicleManagement', reducer: VehicleManagementSlice.reducer });

    const [vehicle, setVehicle] = useState<Vehicle | undefined>();
    const { brandsList, modelsList, yearsList, versionsList, isLoadingBrands, isLoadingModels, isLoadingYears, isLoadingVersions } = useVehicleLists();
    const dispatch = useDispatch();
		const { setIsInputsFields } = useVehicleManagement();
		const checkRef = useRef<HTMLDivElement>(null);

    /**
     * 
     */
    const getVehicleBrands = (): void => {
      dispatch(VehicleManagementAction.getBrands());
    }

    /**
     * 
     * @param brandId 
     */
    const onSelectBrand = useCallback((brand: number | string) => {
      setVehicle(() => {
        let newVehicle = new Vehicle();
        if (typeof brand === 'number') {
          newVehicle.brand = brandsList?.find(b => b.id === brand);
        } else {
          newVehicle.brand = brandsList?.find(b => b.name === brand);
        }

				if(brand && !newVehicle.brand){
					checkRef.current!.click();
					setIsInputsFields(true);
					return;
				}

        if (newVehicle.brand) {
          dispatch(VehicleManagementAction.onItemSelectedFormList('brand'));
          dispatch(VehicleManagementAction.getModels(newVehicle.brand));
        }
        return newVehicle;
      });
    }, [brandsList, dispatch, setIsInputsFields]);

    /**
     * 
     * @param modelId 
     */
    const onSelectModel = useCallback((model: number | string) => {
      setVehicle((prevState) => {
        let newVehicle = new Vehicle();
        newVehicle.brand = prevState?.brand;
        if (typeof model === 'number') {
          newVehicle.model = modelsList?.find(m => m.id === model);
        } else {
          newVehicle.model = modelsList?.find(m => m.name === model);
        }

        if (newVehicle.model) {
          dispatch(VehicleManagementAction.onItemSelectedFormList('model'));
          dispatch(VehicleManagementAction.getYears(newVehicle.model));
        }

        return newVehicle;
      });

    }, [modelsList, dispatch])

    /**
     * 
     * @param yearValue 
     */
    const onSelectYear = useCallback((yearValue: number) => {
      setVehicle((prevState) => {
        let newVehicle = new Vehicle();
        newVehicle.brand = prevState?.brand;
        newVehicle.model = prevState?.model;
        newVehicle.year = yearsList?.find(y => y.value === yearValue);

        if (newVehicle.year) {
          dispatch(VehicleManagementAction.onItemSelectedFormList('year'));
          dispatch(VehicleManagementAction.getVersion(newVehicle.year));
        }

        return newVehicle;
      });

    }, [yearsList, dispatch])

    /**
     * 
     * @param versionId 
     */
    const onSelectVersion = useCallback((versionId: number) => {
      setVehicle((prevState) => {
        let newVehicle = new Vehicle();
        newVehicle.brand = prevState?.brand;
        newVehicle.model = prevState?.model;
        newVehicle.year = prevState?.year;
        newVehicle.version = versionsList?.find(v => v.id === versionId);
        return newVehicle;
      });

    }, [versionsList])


    /**
     * 
     * @returns 
     */
    const useMountEffect = () => useEffect(
      () => {
        getVehicleBrands();
        return () => {
          dispatch(VehicleManagementAction.clear());
        }
      }, []
    );
    useMountEffect();

    return { vehicle, checkRef, onSelectBrand, onSelectModel, onSelectYear, onSelectVersion, setVehicle, isLoadingBrands, isLoadingModels, isLoadingYears, isLoadingVersions };
  }

  /**
   * 
   * @returns 
   */
  export const useVehicleManagement = (storedVehicle?: Vehicle) => {
    
    const [isInputsFields, setIsInputsFields] = useState<boolean>(false);

    /**
     * 
     */
    const validateVehicleData = (vehicle: Vehicle): boolean => {
      if (vehicle.brand && vehicle.model && vehicle.version && vehicle.year) {
        return true;
      }

      return false;
    }
    
    const validateVehicleClaimsData = (vehicle: Vehicle, brandName?: string, modelName?: string, yearValue?: string): boolean => {
      if (!isInputsFields) {
        if (vehicle.brand && vehicle.model && vehicle.year) {
          return true;
        }
      } else {
        if (brandName !== '' && modelName !== '' && yearValue !== '') {
          vehicle.brand = new VehicleBrand(undefined, `${brandName?.charAt(0).toLocaleUpperCase()}${brandName?.slice(1).toLocaleLowerCase()}`);
          vehicle.model = new VehicleModel(undefined, undefined, `${modelName?.charAt(0).toLocaleUpperCase()}${modelName?.slice(1).toLocaleLowerCase()}`);
          vehicle.year = new VehicleYear(undefined, undefined, parseInt(yearValue!));

          return true;
        }
      }

      return false;
    };

    return { validateVehicleData, validateVehicleClaimsData, setIsInputsFields, isInputsFields }
  }
}
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IApplyEndorseRequest } from "../interfaces/IApplyEndorseRequest";

/**
 *
 */
export namespace PolicyEndorsementsService {

  /**
   * 
   * @param policyId 
   * @returns 
   */
  export const applyEndorseService = (policyId: string, otpToken: string, data: IApplyEndorseRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.POLICY_ENDORSEMENTS(policyId), data, {
      headers: {
        'X-Otp-Token': otpToken
      }
    });
  }
}
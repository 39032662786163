import { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './ButtonLink.scss';

interface IButtonLinkProps extends LinkProps {
  /**
   * Values: outline, fill. Default value fill.
   */
  fill?: string;
  /**
   * 
   */
  icon?: JSX.Element;
  /**
   * 
   */
  iconposition?: 'left' | 'right';
  /**
   * 
   */
  external?: boolean;
}

/**
 *
 */
export const ButtonLink = (props: PropsWithChildren<IButtonLinkProps>): JSX.Element => {
  return (
    <>
      {
        !props.external ?
          //@ts-ignore
          <Link to={props.to} replace={props.replace} className={`c-button button-link inline-block text-center ${props.className ? props.className : ''}`} fill={props.fill ? props.fill : 'fill'}>
            <span>
              {
                props.icon && props.iconposition === 'left' &&
                <span className="button-icon left">
                  {props.icon}
                </span>
              }
              {props.children}

              {
                props.icon && (!props.iconposition || props.iconposition === 'right') &&
                <span className="button-icon">
                  {props.icon}
                </span>
              }
            </span>
          </Link>
          :
          <a
            href={props.to as string}
            //@ts-ignore
            fill={props.fill ? props.fill : 'fill'}
            className={`c-button button-link inline-block text-center ${props.className ? props.className : ''}`}
          >
            <span>
              {
                props.icon && props.iconposition === 'left' &&
                <span className="button-icon left">
                  {props.icon}
                </span>
              }
              {props.children}

              {
                props.icon && (!props.iconposition || props.iconposition === 'right') &&
                <span className="button-icon">
                  {props.icon}
                </span>
              }
            </span>
          </a>
      }

    </>
  );
};
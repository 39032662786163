import { PaymentCost } from "../PaymentCost";

export class PlanMonthly {

	/** Class attribute description. */
	private _costs: PaymentCost[];
	set costs(_costs: PaymentCost[]) { this._costs = _costs };
	get costs(): PaymentCost[] { return this._costs };

	constructor (_costs?: PaymentCost) { 
    this._costs = [];
	}
}
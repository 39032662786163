
const BASE_URL: string = '/policy-endorsement';

/**
 * 
 */
 export const POLICY_ENDORSEMENTS_PATH = {

  ENDORSEMENT_PATH: `${BASE_URL}/:policyNumber`,
  ENDORSEMENT: (policyNumber: string) => `${BASE_URL}/${policyNumber}`,
  ENDORSEMENT_VERIFICATION_PATH: `${BASE_URL}/:policyNumber/verification`,
  ENDORSEMENT_VERIFICATION: (policyNumber: string) => `${BASE_URL}/${policyNumber}/verification`,
  ENDORSEMENT_CONFIGURATION_PATH: `${BASE_URL}/:policyNumber/configuration`,
  ENDORSEMENT_CONFIGURATION:  (policyNumber: string) => `${BASE_URL}/${policyNumber}/configuration`,
  ENDORSEMENT_SUMMARY_PATH: `${BASE_URL}/:policyNumber/summary`,
  ENDORSEMENT_SUMMARY: (policyNumber: string) => `${BASE_URL}/${policyNumber}/summary`,
  ENDORSEMENT_COMPLETION_PATH: `${BASE_URL}/:policyNumber/completion`,
  ENDORSEMENT_COMPLETION: (policyNumber: string) => `${BASE_URL}/${policyNumber}/completion`,
	ENDORSEMENT_IDENTITY_VALIDATION_PATH: `${BASE_URL}/:policyNumber/identity-validation`,
	ENDORSEMENT_IDENTITY_VALIDATION: (policyNumber: string) => `${BASE_URL}/${policyNumber}/identity-validation`,
	IDENTITY_VALIDATION_SUPPORT_AGENT: `https://wa.me/5213321011715?text=Hola,%20no%20tengo%20INE%20para%20validar%20mi%20identidad.`,
}
import { SkeletonLoader } from "src/components";

import "./RewardsHistorySkeletonLoader.scss";

/**
 *
 */
interface IRewardsHistorySkeletonLoader {}

/**
 *
 */
const RewardsHistorySkeletonLoader = (props: IRewardsHistorySkeletonLoader): JSX.Element => {
  return (
    <>
      <section className="rewards-history-header-skeleton">
        <div className="text-center">
          <SkeletonLoader />
        </div>
      </section>

      <section className="rewards-history-body-skeleton">
        <div className="table">
          <div className="table-header">
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
          </div>
          <div>
            <div className="table-row">
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
            <div className="table-row">
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
            <div className="table-row">
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
            <div className="table-row">
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RewardsHistorySkeletonLoader;

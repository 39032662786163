import { Util } from "src/utils/Util";
import { Vehicle } from "../vehicle/Vehicle";
import { CoverageOption } from "./CoverageOption";

/**
 * 
 */
export class Coverage {
  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };
  /**
   * 
   */
  private _name: string;
  get name(): string { return this._name; }
  set name(_name: string) { this._name = _name; }
  /**
   *
   */
  private _type: string;
  set type(_type: string) { this._type = _type };
  get type(): string { return this._type };
  /**
   *
   */
  private _isEnabled: boolean;
  set isEnabled(_isEnabled: boolean) { this._isEnabled = _isEnabled };
  get isEnabled(): boolean { return this._isEnabled };
  /**
   *
   */
  private _assured: number;
  set assured(_assured: number) { this._assured = _assured };
  get assured(): number { return this._assured };
  /**
   *
   */
  private _deductible: number;
  set deductible(_deductible: number) { this._deductible = _deductible };
  get deductible(): number { return this._deductible };
  /**
   *
   */
  private _description: string;
  set description(_description: string) { this._description = _description };
  get description(): string { return this._description };
  /**
   *
   */
  private _includeList: string[];
  set includeList(_includeList: string[]) { this._includeList = _includeList };
  get includeList(): string[] { return this._includeList };
  /**
   *
   */
  private _excludeList: string[];
  set excludeList(_excludeList: string[]) { this._excludeList = _excludeList };
  get excludeList(): string[] { return this._excludeList };
  /**
   *
   */
  private _allowDisable: boolean;
  set allowDisable(_allowDisable: boolean) { this._allowDisable = _allowDisable };
  get allowDisable(): boolean { return this._allowDisable };
  /**
   *
   */
  private _customitationOptions: CoverageOption[];
  set customitationOptions(_customitationOptions: CoverageOption[]) { this._customitationOptions = _customitationOptions };
  get customitationOptions(): CoverageOption[] { return this._customitationOptions };
  /**
   *
   */
  private _isDeductible: boolean;
  set isDeductible(_isDeductible: boolean) { this._isDeductible = _isDeductible };
  get isDeductible(): boolean { return this._isDeductible };
  /**
   *
   */
  private _image: string;
  set image(_image: string) { this._image = _image };
  get image(): string { return this._image };
  /**
   *
   */
  private _isDefault: boolean;
  set isDefault(_isDefault: boolean) { this._isDefault = _isDefault };
  get isDefault(): boolean { return this._isDefault };

  constructor(_name?: string, _type?: string) {
    this._id = '';
    this._name = _name ? _name : '';
    this._type = _type ? _type : '';
    this._deductible = -1;
    this._assured = -1;
    this._description = '';
    this._isEnabled = false;
    this._allowDisable = true;
    this._includeList = [];
    this._excludeList = [];
    this._customitationOptions = [];
    this._isDeductible = false;
    this._image = '';
    this._isDefault = false;
  }

  /**
   * 
   * @returns 
   */
  public getCoverageDetails(vehicle: Vehicle): string {
    try {
      let details: string = '';

      if (this._isEnabled) {
        if (this._deductible) {
          details = `${this.coverageValue()} del ${this.coverageType(vehicle)}`;
        } else if (this._assured) {
          details = `${this.coverageValue()} de ${this.coverageType()}`;
        }
      } else {
        details = 'No incluido';
      }
      return details;
    } catch (e) {
      console.error(e);
      return 'Ha ocurrido un error.';
    }
  }

  /**
   * 
   * @param vehicle 
   */
  public coverageType(vehicle?: Vehicle): string {
    let type: string = '';
    if (this._isEnabled) {
      if (this._deductible && vehicle) {
        type = `${vehicle.year?.value! >= new Date().getFullYear() - 1 ? 'Valor factura' : 'Valor comercial'}`;
      } else if (this._assured) {
        type = `Suma asegurada`;
      } else if (!this._isDeductible && !this._assured) {
        type = `Incluida`;
      }

      return type;
    } else {
      type = 'No incluido';
    }
    return type;
  }

  /**
   * 
   * @returns 
   */
  public coverageValue(): string {
    let value: string = '';
    if (this._isDeductible) {
      value = `${(this._deductible * 100).toString()}%`;
    } else if (this._assured) {
      value = `${Util.TRANSFORM.NUMBER.formatNumberToString(this._assured)}`
    }
    return value;
  }

}
import { DocumentSignature } from "src/classes/document/DocumentSignature";
import { IDocumentRequest } from "../interfaces/IDocumentRequest";
import { IDocumentData } from "../interfaces/IDocumentData";
import { IDocumentSignatureRequest } from "../interfaces/IDocumentSignatureRequest";

export namespace DocumentSignatureAdapter {

  /**
   * 
   * @param formId 
   * @param key 
   */
  export const getClaimFormsDocumentRequestAdapter = (formId: string, key: string): IDocumentRequest => {
    let request: IDocumentRequest = {
      id: formId,
      key: key
    }

    return request;
  }

  /**
   * 
   * @param data 
   */
  export const claimFormsDocumentDataAdapter = (data: IDocumentData, key: string): DocumentSignature => {
    let document: DocumentSignature = new DocumentSignature(data.response.id, key, data.response.json.document.link);
    document.personName = data.response.json.name;
    document.documentName = data.response.json.document.typeStatus.name;
    document.documentId = data.response.json.document.id;
    document.typeId = data.response.json.document.typeStatus.id;
    document.completed = data.response.json.completed;

    if (data.response.json.signature) {
      document.signature = data.response.json.signature;
    }

    return document;
  }

  /**
   * 
   * @param document 
   * @param completed 
   * @returns 
   */
  export const claimsFormsDocumentUpdateRequestAdapter = (document: DocumentSignature, completed?: boolean): IDocumentSignatureRequest => {
    let request: IDocumentSignatureRequest = {
      id: document.formId,
      key: document.key,
      json: {
        completed: completed!,
        started: true,
        name: document.personName,
        document: {
          id: document.documentId,
          typeStatus: {
            id: document.typeId,
            name: document.documentName,
          }
        }
      }
    } 
    
    if (document.signature) {
      request.json.signature = document.signature;
    }

    return request;
  }
}
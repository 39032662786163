import { AxiosError } from "axios";
import { Person  } from "src/classes/Person";
import { QuoterAdapter } from "../adapters/quoter.adapter";
import { IUtmParams } from "../interfaces/IUtmParams";
import { QuoterService } from "../services/quoter.service";

/**
 *
 */
export namespace QuoterFeature {

	/**
	 * 
	 */
	export const registerUserFeature = async(user: Person, utms: IUtmParams, referral: string) => {
		try {
			const adaptedData = QuoterAdapter.registerUserRequestAdapter(user, utms, referral);
			await QuoterService.registerUser(adaptedData);
		} catch (e) {
			const axiosError = e as AxiosError;
      let error = new Error();
			
      if (axiosError.response) {
				if(axiosError.response.status === 400){
					if(axiosError.response.data.code === 10)
						error.message = `Formato de fecha incorrecto.`;
					else if(axiosError.response.data.code === 4)
        		error.message = `Debe de ingresar un género para continuar.`;
					else
						error.message = `Ha ocurrido un error con el servidor.`;
				}

				if(axiosError.response.status === 409){
					if(axiosError.response.data.code === 10){
						error.message = 'La edad no puede ser menor a 16.'
					}else if(axiosError.response.data.code === 11){
						error.message = 'No se encontró el código postal.'
					}else if(axiosError.response.data.code === 12){
						error.message = 'El email ya se encuentra registrado.'
					}else if(axiosError.response.data.code === 13){
						error.message = 'El RFC ya se encuentra registrado.'
					}
				}

				if(axiosError.response.status === 500 || axiosError.response.status === 503)
					error.message = 'Error del servidor. Por favor, intente más tarde.'

      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }

      throw error;
		}
	};

	/**
   * 
   * @returns 
   */
	export const validateEmailFeature = async (email: string): Promise<boolean> => {
		try {
			const { data } = await QuoterService.validateEmailService(email);
			return !data.is_in_use;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
}
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IVehiclePictureRequest } from "../interfaces/IVehiclePictureRequest";

/**
 *
 */
export namespace InsuranceVehicleService {


  export const isVehiclePictureService = (request: IVehiclePictureRequest) : AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp().post(Util.API_PATH.IS_VECHILE_PICTURE, request.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${request.token}`
        }
      }
    );
  }

  export const uploadVehiclePicturesService = (request: IVehiclePictureRequest) : AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp().post(Util.API_PATH.VEHICLE_UPLOAD_PICTURES(request.policyId!), request.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${request.token}`
        }
      }
    );
  }
}
import { Link, useLocation } from "react-router-dom";
import { DamageClaim } from "src/classes/DamageClaim";
import { IconFactory } from "src/factory/icon.factory";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_LIST_PATH } from "src/routes/paths/claim-payments-list.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import ClaimCardDetails from "../../atoms/ClaimCardDetails/ClaimCardDetails";
import ClaimCardPicture from "../../atoms/ClaimCardPicture/ClaimCardPicture";
import "./ClaimCard.scss";

/**
 *
 */
interface IClaimCard {
  /**
   *
   */
  claim?: DamageClaim;
  /**
   *
   */
  loader?: boolean;
}

/**
 *
 */
const ClaimCard = (props: IClaimCard): JSX.Element => {
  const { claim } = props;
	const location = useLocation();

	/** */
	const claimRoute = (): string => {
		if (location.pathname.includes(CLAIM_PAYMENTS_LIST_PATH.CLAIM_PAYMENTS_LIST)) {
			return CLAIM_PAYMENTS_PATH.POLICY_DATA;
		} else {
			return AGREED_PAYMENTS_PATH.POLICY_DATA;
		}
	};
	
  return (
    <Link
      to={`${claimRoute()}?claim=${claim?.uid}&policy-number=${claim?.policyNumber}`}
      className="claim-card-wraper inline-flex vertical-align-middle"
      is-loader={props.loader ? "" : null}
    >
      <section>
        <ClaimCardPicture loader={props.loader} />
      </section>

      <section>
        <ClaimCardDetails claim={claim!} loader={props.loader} />
      </section>

      <section>
        <div className="card-arrow flex height-100 align-center justify-flex-end">
          {IconFactory.rightArrowIcon()}
        </div>
      </section>
    </Link>
  );
};

export default ClaimCard;

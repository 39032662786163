import { PersonManagementEconomicActivityAdapter } from "../adapters/person-management-economic-activity.adapter";
import { IPersonManagementEconomicActivity } from "../interfaces/IPersonManagementEconomicActivity";
import { PersonManagementService } from "../services/person-management.service";

/**
 *
 */
export namespace PersonManagementFeature {

  /**
   * 
   * @returns 
   */
  export const validateRfcFeature = async (rfc: string): Promise<boolean> => {
    try {
      const { data } = await PersonManagementService.validateRfcService(rfc);
      return !data.is_in_use;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @returns 
   */
  export const validateEmailFeature = async (email: string): Promise<boolean> => {
    try {
      const { data } = await PersonManagementService.validateEmailService(email);
      return !data.is_in_use;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

	/**
	 * 
	 */
	export const getEconomicActivities = async(): Promise<IPersonManagementEconomicActivity[]> => {
		try {
			const { data } = await PersonManagementService.getEconomicActivities();
			const adaptedData = PersonManagementEconomicActivityAdapter.economicActivitiesDataAdapter(data);
			return adaptedData;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	/**
	 * 
	 */
	export const getOwnBusinessActivities = async(): Promise<IPersonManagementEconomicActivity[]> => {
		try {
			const { data } = await PersonManagementService.getOwnBusinessActivities();
			const adaptedData = PersonManagementEconomicActivityAdapter.ownBusinessDataAdapter(data);
			return adaptedData;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
}
import { Outlet } from 'react-router-dom';
import { QuoterFacade } from '../../controller/facades/quoter.facade';
import './Quoter.scss';

/**
 *
 */
interface IQuoter { }

/**
 *
 */
const QuoterPage = (props: IQuoter): JSX.Element => {
  QuoterFacade.useQuoterFacade();

  return (
    <div id="quoter-wrapper" className="width-100 flex column space-between border-box">
      <div className="bg-decoration"></div>

      <div className="quoter-content relative border-box height-100">
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default QuoterPage;
import { BrowserRouter as Router } from 'react-router-dom';
import { EnvironmentFactory } from './factory/EnvironmentFactory/environment.factory';
import Main from './modules/main/ui/pages/Main';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import './App.scss';

const stripePromise: Promise<Stripe | null> | null = loadStripe(EnvironmentFactory.ENV.REACT_APP_STRIPE_PUBLIC_KEY);

const App = (): JSX.Element => {
  EnvironmentFactory.initHttpClient();
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Main />
      </Router>
    </Elements>
  );
};

export default App;

import { Button } from "src/components";
import { IconFactory } from 'src/factory/icon.factory';
import { InsuranceCompletionFacade } from 'src/modules/insurance-vehicle/controller/facades/insurance-completion.facade';
import './InsuranceCompletion.scss';

/**
 *
 */
const InsuranceCompletionPage = (): JSX.Element => {
  const { navigateRoot } = InsuranceCompletionFacade.useInsuranceCompletion();

  return (
    <div id="insurance-completion-wrapper" className="flex column align-center justify-center height-100 relative">
      {IconFactory.checkIcon()}
      <h1 className="text-center"><b>Listo</b></h1>
			<h2 className="text-center">Tus fotos tardarán varios minutos en ser cargadas a nuestra plataforma.</h2>
      <p className="text-center">Ya estás completamente asegurado.</p>

      <Button type="button" className="absolute" onClick={(e) => { e.preventDefault(); navigateRoot() }}>Ir a mi sesi&oacute;n</Button>
    </div>
  );
};

export default InsuranceCompletionPage;
import { useEffect, useState } from "react";
import { Util } from "src/utils/Util";

export namespace ClaimFileFacade {

  export const useClaimFile = (defaultPicture?: string) => {
    const [showCamera, setShowCamera] = useState<boolean>();
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [source, setSource] = useState<string>('');


    /**
     * 
     */
    const onCancelCamera = (): void => {
      setShowCamera(false);
    }

    /**
     * 
     */
    const onTakePicture = (url: string): void => {
      setSource(url);
      pictureSelected(url);
    }

    /**
     * 
     */
    const openCamera = (): void => {
      setShowCamera(true);
      onCancelOptions();
    }

    /**
     * 
     */
    const onCancelOptions = (): void => {
      setShowOptions(false);
    }

    /**
     * 
     */
    const openOptions = (): void => {
      setShowOptions(true);
    }


    const pictureSelected = async (picture: string): Promise<void> => {
      // try {
      //   const file = Util.TRANSFORM.FILE.dataURLtoFile(picture);
      //   setIsLoadingPicture(true);
      //   onIsValidating(true);
      //   setPicture(picture);
      //   selectedPicture(file);
      // } catch (e) {
      //   const error = e as Error;
      //   if (error.name !== '') {
      //     onError((e as Error).name);
      //   } else {
      //     AlertFactory.errorAlert(error.message);
      //   }
      //   selectedPicture(undefined);
      //   setPicture('');
      // } finally {
      //   setIsLoadingPicture(false);
      //   onIsValidating(false);
      // }
    }

    /**
     * 
     */
    const openGallery = (): void => {
      const input = document.createElement('input');
      input.type = 'file';
      input.className = 'open-gallery-input';
      input.style.display = 'none';
      document.body.appendChild(input);
      input.accept = 'image/png, image/jpeg, image/jpg';
      onCancelOptions();
      input.onchange = async () => {
        if (input.files) {
          const files = Array.from(input.files!);
          if (files[0]) {
            const img = await Util.TRANSFORM.FILE.toBase64(files[0]);
            setSource(img);
            pictureSelected(img);
            const length = document.body.getElementsByClassName('open-gallery-input').length;
            for (let i = 0; i < length; i++) {
              document.body.removeChild(document.body.getElementsByClassName('open-gallery-input')[0]);
            }
          }
        }
      }

      input.click();
    }

    useEffect(
      () => {
        if (defaultPicture) {
          setSource(defaultPicture);
        }
      }, [defaultPicture]
    )

    return {
      source,
      camera: { showCamera, openCamera, onCancelCamera, onTakePicture },
      options: { showOptions, onCancelOptions, openOptions, openGallery }
    }
  }
}
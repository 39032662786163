import { QUOTER_PATH } from "src/routes/paths/quoter.paths";
import QuoterPage from "../ui/pages/Quoter";
import QuoterDataFormPage from "../ui/pages/QuoterDataForm/QuoterDataForm";

/**
 * 
 */
export const QuoterRoutes = () => {
  return {
    path: QUOTER_PATH.QUOTER, 
    element: <QuoterPage />, 
    children: [
      { path: `${QUOTER_PATH.DATA_FORM}`, element: <QuoterDataFormPage /> },    
    ]
  }
}

import { Address } from "src/classes/Address";
import { CoreAddressAdapter } from "../adapters/address.adapter";
import { CoreAddressService } from "../services/address.service";

export namespace CoreAddressFeature {
	
	/**
   * 
   * @returns 
   */
		 export const validatePostalCodeFeature = async (postalCode: string): Promise<Address | undefined> => {
			try {
				const { data } = await CoreAddressService.validatePostalCodeService(postalCode);
				if (data.postal_entities) {
					const address = CoreAddressAdapter.postalEntitiesDataAdapter(data);
					return address;
				}
			} catch (e) {
				console.error(e);
				throw e;
			}
		}
}
import { Util } from "src/utils/Util";
import { ClaimProposal } from "./ClaimProposal";
import { FileAttachment } from "./FileAttachment";
import { Person  } from "./Person";
import { Vehicle } from "./vehicle/Vehicle";

export class DamageClaim {

  /**
   * 
   */
  private _uid: string;
  set uid(_uid: string) { this._uid = _uid };
  get uid(): string { return this._uid };
  /**
   * 
   */
  private _status: number;
  set status(_status: number) { this._status = _status };
  get status(): number { return this._status };
  /**
   * 
   */
  private _createdAt: Date | undefined;
  set createdAt(_createdAt: Date | undefined) { this._createdAt = _createdAt };
  get createdAt(): Date | undefined { return this._createdAt };
  /**
   * 
   */
  private _claimDate: Date | undefined;
  set claimDate(_claimDate: Date | undefined) { this._claimDate = _claimDate };
  get claimDate(): Date | undefined { return this._claimDate };
  /**
   * 
   */
  private _policyUID: string;
  set policyUID(_policyUID: string) { this._policyUID = _policyUID };
  get policyUID(): string { return this._policyUID };
  /**
   * 
   */
  private _policyNumber: string;
  set policyNumber(_policyNumber: string) { this._policyNumber = _policyNumber };
  get policyNumber(): string { return this._policyNumber };
  /**
   * 
   */
  private _user: Person | undefined;
  set user(_user: Person | undefined) { this._user = _user };
  get user(): Person | undefined { return this._user };
  /**
   * 
   */
  private _vehicle: Vehicle;
  set vehicle(_vehicle: Vehicle) { this._vehicle = _vehicle };
  get vehicle(): Vehicle { return this._vehicle };
  /**
   * 
   */
  private _damageClaimNumber: string;
  set damageClaimNumber(_damageClaimNumber: string) { this._damageClaimNumber = _damageClaimNumber };
  get damageClaimNumber(): string { return this._damageClaimNumber };
  /**
   * 
   */
  private _attachmentVehicle: FileAttachment[];
  set attachmentVehicle(_attachmentVehicle: FileAttachment[]) { this._attachmentVehicle = _attachmentVehicle };
  get attachmentVehicle(): FileAttachment[] { return this._attachmentVehicle };
  /**
   *
   */
  private _attachmentDamage: FileAttachment[];
  set attachmentDamage(_attachmentDamage: FileAttachment[]) { this._attachmentDamage = _attachmentDamage };
  get attachmentDamage(): FileAttachment[] { return this._attachmentDamage };
  /**
   *
   */
  private _attachmentIdentification: FileAttachment[];
  set attachmentIdentification(_attachmentIdentification: FileAttachment[]) { this._attachmentIdentification = _attachmentIdentification };
  get attachmentIdentification(): FileAttachment[] { return this._attachmentIdentification };
  /**
   *
   */
  private _attachmentAccountStatus: FileAttachment;
  set attachmentAccountStatus(_attachmentAccountStatus: FileAttachment) { this._attachmentAccountStatus = _attachmentAccountStatus };
  get attachmentAccountStatus(): FileAttachment { return this._attachmentAccountStatus };
  /**
   *
   */
  private _attachmentLegal: FileAttachment;
  set attachmentLegal(_attachmentLegal: FileAttachment) { this._attachmentLegal = _attachmentLegal };
  get attachmentLegal(): FileAttachment { return this._attachmentLegal };
  /**
   *
   */
  private _attachmentBill: FileAttachment;
  set attachmentBill(_attachmentBill: FileAttachment) { this._attachmentBill = _attachmentBill };
  get attachmentBill(): FileAttachment { return this._attachmentBill };


  private _proposal: ClaimProposal | undefined;
  set proposal(_proposal: ClaimProposal | undefined) { this._proposal = _proposal };
  get proposal(): ClaimProposal | undefined { return this._proposal };

  /** Class attribute description. */
  private _receiptSource: string | undefined;
  set receiptSource(_receiptSource: string | undefined) { this._receiptSource = _receiptSource };
  get receiptSource(): string | undefined { return this._receiptSource };

  constructor(_uid: string, _creationDate?: Date, _status?: number, _policyNumber?: string, _claimNumber?: string) {
    this._uid = _uid;
    this._policyUID = '';
    this._policyNumber = _policyNumber ? _policyNumber : '';
    this._damageClaimNumber = _claimNumber ? _claimNumber : '';
    this._createdAt = _creationDate ? _creationDate : undefined;
    this._vehicle = new Vehicle();
    this._status = _status ? _status : 0;


    this._attachmentVehicle = this.setVehiclesAttachments();
    this._attachmentDamage = this.setDamageAttachments();
    this._attachmentIdentification = this.setIdentificationAttachments();
    this._attachmentAccountStatus = new FileAttachment(undefined, Util.KEY.CLAIM_PICTURE_TYPE.BANK, Util.KEY.PICTURE_NAME.GENERIC, 'Estado de cuenta', this._uid);
    this._attachmentAccountStatus.thumbnail = Util.ASSET.ACCOUNT_STATUS;
    this._attachmentLegal = new FileAttachment(undefined, Util.KEY.CLAIM_PICTURE_TYPE.CAR_LEGAL, Util.KEY.PICTURE_NAME.GENERIC, 'Carta de poder', this._uid);
    this._attachmentLegal.thumbnail = Util.ASSET.ACCOUNT_STATUS;
    this._attachmentBill = new FileAttachment(undefined, Util.KEY.CLAIM_PICTURE_TYPE.CAR_LEGAL, Util.KEY.PICTURE_NAME.GENERIC, 'Factura del vehículo', this._uid);
    this._attachmentBill.thumbnail = Util.ASSET.ACCOUNT_STATUS;
  }

  /**
   * 
   * @returns 
   */
  public getCreationDateFormat(): string {
    try {
      let _format: string = Util.TRANSFORM.DATE.formatDate(this._createdAt as Date);
      return _format;
    } catch (e) {
      console.error(e);
      return '--';
    }

  };

  /**
   * 
   * @returns 
   */
  public getClaimDateFormat(): string {
    try {
      let _format: string = Util.TRANSFORM.DATE.formatDate(this._claimDate as Date);
      return _format;
    } catch (e) {
      console.error(e);
      return '--';
    }
  };

  /**
   * 
   */
  private setVehiclesAttachments(): FileAttachment[] {
    let attachmentsList: FileAttachment[] = new Array(5);
    const type = Util.KEY.CLAIM_PICTURE_TYPE.VEHICLE_CARD;
    attachmentsList[0] = new FileAttachment(undefined, type, Util.KEY.PICTURE_NAME.FRONT, 'Frente', this._uid);
    attachmentsList[0].thumbnail = Util.ASSET.CAR_FRONT;
    attachmentsList[1] = new FileAttachment(undefined, type, Util.KEY.PICTURE_NAME.LEFT, 'Lado izquierdo', this._uid);
    attachmentsList[1].thumbnail = Util.ASSET.CAR_LEFT;
    attachmentsList[2] = new FileAttachment(undefined, type, Util.KEY.PICTURE_NAME.RIGHT, 'Lado derecho', this._uid);
    attachmentsList[2].thumbnail = Util.ASSET.CAR_RIGHT;
    attachmentsList[3] = new FileAttachment(undefined, type, Util.KEY.PICTURE_NAME.BACK, 'Atrás', this._uid);
    attachmentsList[3].thumbnail = Util.ASSET.CAR_BACK;
    attachmentsList[4] = new FileAttachment(undefined, type, Util.KEY.PICTURE_NAME.SERIAL, 'No. Serial', this._uid);
    attachmentsList[4].thumbnail = Util.ASSET.CAR_SERIAL;

    return attachmentsList;
  }

  /**
   * 
   */
  private setDamageAttachments(): FileAttachment[] {
    let attachmentsList: FileAttachment[] = [];
    const type = Util.KEY.CLAIM_PICTURE_TYPE.DAMAGE;
    for (let i = 0; i < 6; i++) {
      const newAttachment = new FileAttachment(undefined, type, Util.KEY.PICTURE_NAME.GENERIC, 'Daño', this._uid);
      newAttachment.thumbnail = Util.ASSET.CAR_CRASH;
      attachmentsList.push(newAttachment);
    }

    return attachmentsList;
  }

  /**
   * 
   * @returns 
   */
  private setIdentificationAttachments(): FileAttachment[] {
    let attachmentsList: FileAttachment[] = new Array(3);
    const typeId = Util.KEY.CLAIM_PICTURE_TYPE.PERSON_ID;
    const typeDocument = Util.KEY.CLAIM_PICTURE_TYPE.DOCUMENT;
    attachmentsList[0] = new FileAttachment(undefined, typeDocument, Util.KEY.PICTURE_NAME.FRONT, 'Tarjeta de circulación', this._uid);
    attachmentsList[0].thumbnail = Util.ASSET.DRIVING_CARD;
    attachmentsList[1] = new FileAttachment(undefined, typeId, Util.KEY.PICTURE_NAME.FRONT, 'Id. frente', this._uid);
    attachmentsList[1].thumbnail = Util.ASSET.INE_FRONT;
    attachmentsList[2] = new FileAttachment(undefined, typeId, Util.KEY.PICTURE_NAME.BACK, 'Id. atrás', this._uid);
    attachmentsList[2].thumbnail = Util.ASSET.INE_BACK;

    return attachmentsList;
  }

	/**
	 * 
	 * @returns 
	 */
	public checkAllPhotosApproved = (isAgreedPayment?: boolean): boolean => {

		if(!isAgreedPayment){
			for(let attachment of this.attachmentVehicle){
				if(attachment.uid.length === 0 || attachment.status !== 1)
					return false;
			}
		}

		for(let attachment of this.attachmentIdentification){
			if(attachment.uid.length === 0 || attachment.status !== 1)
				return false;
		}

		for(let attachment of this.attachmentDamage){
			if(attachment.uid.length > 0 && attachment.status !== 1)
				return false;
		}

		if(((this.attachmentBill.uid.length === 0) && (this.attachmentLegal.uid.length > 0 && this.attachmentLegal.status !== 1)) 
			|| ((this.attachmentBill.uid.length > 0 && this.attachmentBill.status !== 1) && (this.attachmentLegal.uid.length === 0))
			|| (this.attachmentBill.uid.length === 0 && this.attachmentLegal.uid.length === 0 && this.attachmentBill.status !== 1 && this.attachmentLegal.status !== 1))
			return false;

		if(this.attachmentAccountStatus.uid.length === 0 || this.attachmentAccountStatus.status !== 1)
			return false;

		return true;
	}
}
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";

export namespace UserEmailValidationService {

  /**
   * 
   * @param token 
   * @returns 
   */
  export const validateEmailService = (token: string): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp().get(`${Util.API_PATH.USER_EMAIL_VALIDATION}`, {params: {token: token}})
  }
}
import { AxiosError } from "axios";
import { Person  } from "src/classes/Person";
import { ChangePasswordAdapter } from "../adapters/change-password.adapter";
import { SecurityService } from "../services/security.service";

/**
 *
 */
export namespace SecurityFeature {

  /**
   * 
   * @param user 
   * @param newPassowrd 
   */
  export const changePasswordFeature = async (user: Person, newPassowrd: string): Promise<void> => {
    try {
      const data = ChangePasswordAdapter.passwordDataAdapter(user, newPassowrd);
      await SecurityService.changePasswordService(data);
    } catch (e: any | AxiosError) {
      if (e.response) {
        if ((e as AxiosError).response?.status === 401) {
          const error: Error = {
            message: 'La contraseña actual es incorrecta.',
            name: '401'
          }

          throw error;
        }
      }
    }

  }
}
import { useState } from "react";
import { AlertFactory } from "src/factory/alert.factory";
import { LoginFeature } from "../features/login.feature";

export namespace LoginSendValidationFacade {

  export const useSendEmail = () => {
    const [isSending, setIsSending] = useState<boolean>(false);


    const sendEmail = async (token: string): Promise<void> => {
      try {
        setIsSending(true);
        await LoginFeature.sendEmailValidationFeature(token);
        AlertFactory.successAlert('Se ha enviado el correo electrónico.');
      } catch(e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        setIsSending(false);
      }
    }

    return { isSending, sendEmail }
  }
}
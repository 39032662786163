import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CLAIM_REGISTER_FORM_TYPE, CLAIM_REGISTER_PATH } from "src/routes/paths/claim-register.paths";
import { ClaimRegisterSelector } from "../selectors/claim-register.selector";
import { Address } from "src/classes/Address";
import { UseFormReturn } from "react-hook-form";
import { IClaimPersonForm } from "../interfaces/IClaimPersonForm";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { Vehicle } from "src/classes/vehicle/Vehicle";

export namespace ClaimPersonSummaryFacade {

  export const PAGES = {
    NAME: 1,
    CONTACT: 2,
    FACTS: 3,
    REFERENCES: 4,
    LICENSE: 5,
    LICENSE_PICTURES: 6,
    DRIVING_PERMISSION: 7,
    VIN: 8,
    VEHICLE_PICTURES: 9,
    DAMAGE: 10,
  }

  /**
   * 
   */
  export const useClaimPersonSummary = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isExternalForm, setIsExternalForm] = useState<boolean>(false);
    const registerInfo = useSelector(ClaimRegisterSelector.registerInfo);
    const { formType, formId, key } = useParams<string>();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

    /**
     * 
     * @returns 
     */
    const getCurrentQueryParams = (): string => {
      return `${query.keys.length === 0 ? '?' : ''}${query}&edit=true`;
    }

    /**
     * 
     * @param pageId 
     */
    const editSection = (pageName: string): void => {
      navigate(`${CLAIM_REGISTER_PATH.FORMS}/${formType}/${pageName}/${formId}/${key}${getCurrentQueryParams()}`)
    }

    /**
     * 
     * @param pictures 
     */
    const validatePictures = (pictures: string[]): boolean => {
      let isValid: boolean = true;
      for (const picture of pictures) {
        if (!picture!!) {
          isValid = false;
          break;
        }
      }

      return isValid;
    }

    /**
     * 
     * @param address 
     * @returns 
     */
    const validateAddress = (address?: Address): boolean => {
      let isValid: boolean = false;
      if (address && (address.postalCode!! || address.name!! || address.municipality!! || address.state!!)) {
        isValid = true;
      }

      return isValid;
    }

    /**
     * 
     * @param form 
     * @param lisenceType 
     * @returns 
     */
    const validateLicense = (form: UseFormReturn<IClaimPersonForm>, lisenceType?: StatusTypeItem): boolean => {
      let isValid: boolean = false;
      if (form.getValues('licenseNumber')!! || form.getValues('licenseState')!! || lisenceType || (form.getValues('licenseStartDate') && form.getValues('licenseStartDate'))) {
        isValid = true;
      }

      return isValid;
    }

    /**
     * 
     * @param form 
     * @param vehicleType 
     */
    const validateVehicle = (vehicle?: Vehicle, vehicleType?: StatusTypeItem): boolean => {
      let isValid: boolean = false;
      if (vehicle && (vehicleType || vehicle.brand!! || vehicle.model!! || vehicle.year!!) && vehicle.plate!!) {
        isValid = true;
      }

      return isValid;
    }

    /**
     * 
     * @param form 
     * @param colorType 
     */
    const validateVin = (form: UseFormReturn<IClaimPersonForm>, colorType?: StatusTypeItem): boolean => {
      let isValid: boolean = false;
      if (colorType || form.getValues('vin')!!) {
        isValid = true;
      }

      return isValid;
    }

    /**
     * 
     */
    useEffect(
      () => {
        if (formType === CLAIM_REGISTER_FORM_TYPE.THIRD_PARTY) {
          setIsExternalForm(true);
        }
      }, [formType]
    );

    return { 
      editSection, isExternalForm, registerInfo,
      sectionValidation: { validateAddress, validatePictures, validateLicense, validateVehicle, validateVin }
     }
  }
}
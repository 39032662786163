import './Icon.scss';
import { IconBaseProps, IconType } from 'react-icons';

/**
 *
 */
export const Icon = (props: IconBaseProps, IconType: IconType): JSX.Element => {
  
  return <IconType {...props} stroke="currentColor" />

};
import { ButtonLink } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import './EndorsementCompletion.scss';

/**
 *
 */
const EndorsementCompletionPage = (): JSX.Element => {
  return (
    <div id="endorsement-completion" className="flex column align-center justify-center height-100">
      <section className="flex align-center column">
        {IconFactory.checkIcon()}
        <h2 className="text-center">Tu solicitud ha sido procesada</h2>
        <p className="text-center">
          Te enviaremos un <b>correo electr&oacute;nico</b> con los cambios realizados. 
          Tambi&eacute;n podr&aacute;s consultarlo en la web en el detalle de tu poliza.
        </p>
      </section>
      <section>
        <ButtonLink to={'/'}>Regresar a tu p&oacute;liza</ButtonLink>
      </section>
    </div>
  );
};

export default EndorsementCompletionPage;
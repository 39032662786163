import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects";
import { DamageClaim } from "src/classes/DamageClaim";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { CoreSelector } from "src/core/selectors/core.selector";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFeature } from "../features/claim-payments.feature";
import { ClaimPaymentsSelector } from "../selectors/claim-payments.selector";
import { ClaimPaymentsSlice } from "../slices/claim-payments.slice";

/**
 *
 */
export namespace ClaimPaymentsSaga {
  type PolicyByNumber = SagaReturnType<typeof CorePolicyFeature.policyByNumberFeature>;  

	/**
	 * 
	 */
	function* clear() {
		yield put(ClaimPaymentsSlice.actions.clear());
	};
	
	/**
	 * 
	 */
	function* updateCurrentPath(action: PayloadAction<string>) {
		yield put(ClaimPaymentsSlice.actions.updateCurrentPath(action.payload));
	};

  /**
   * @param action 
   */
  function* getPolicyByNumber(action: PayloadAction<string>) {
    try {
      const currentPolicy: PolicyByNumber = yield call(CorePolicyFeature.policyByNumberFeature, action.payload);
			const damageClaim = ClaimPaymentsFeature.updateDamageClaimFromPolicyFeature(currentPolicy!);
      const currentDamageClaim: DamageClaim = yield select(ClaimPaymentsSelector.currentDamageClaim);
      if (currentDamageClaim === undefined) {
        yield put(ClaimPaymentsSlice.actions.storeDamageClaim(damageClaim.damageClaim));
      }
    
			yield put(ClaimPaymentsSlice.actions.storeVehicle(damageClaim.vehicle!));
    } catch (e) {
			console.error(e);
			throw(e);
    }
  };

	/**
	 * 
	 * @param action 
	 */
	function* getDamageClaimById(action: PayloadAction<{ damageClaim: string, isAgreedPayment: boolean }>) {
		try {
			/** */
			let damageClaim: DamageClaim;

			yield put(ClaimPaymentsSlice.actions.updateIsLoadingDamageClaim(true));

			if (action.payload.isAgreedPayment) {
				damageClaim = yield call(ClaimPaymentsFeature.getAgreedClaimByIdFeature, action.payload.damageClaim);
			} else {
				damageClaim = yield call(ClaimPaymentsFeature.getClaimDamageByIdFeature, action.payload.damageClaim);
			}

			const _currentUser: Person = yield select(CoreSelector.currentUser);

      if (damageClaim) {
        yield put(ClaimPaymentsSlice.actions.storeDamageClaim(damageClaim));

				if(_currentUser && !damageClaim.user)
					yield put(ClaimPaymentsSlice.actions.storeClaimUser(_currentUser));
				else if(damageClaim.user)
        	yield put(ClaimPaymentsSlice.actions.storeClaimUser(damageClaim.user!));
					
        yield put(ClaimPaymentsSlice.actions.storeClaimVehicle(damageClaim.vehicle));
      }
		} catch (e) {
			console.error(e);
			throw(e);
		} finally { 
			yield put(ClaimPaymentsSlice.actions.updateIsLoadingDamageClaim(false));
		}
	};

	/**
	 * 
	 */
	function* updatePersonalData(action: PayloadAction<{user: Person, claimPayment: DamageClaim}>) {
		try {
      yield call(ClaimPaymentsFeature.updatePersonalDataFeature, action.payload.user, action.payload.claimPayment);
    } catch (e) {
			console.error(e);
			throw(e);
    }
	};

	/**
	 * 
	 */
	function* updateClaimPayment(action: PayloadAction<DamageClaim>) {
		yield put(ClaimPaymentsSlice.actions.storeDamageClaim(action.payload));
	};

	/**
	 * 
	 */
	function* updateVehicle(action: PayloadAction<Vehicle>) {
		yield put(ClaimPaymentsSlice.actions.storeVehicle(action.payload));
	};

	/**
	 * 
	 */
	function* updateDataStep(action: PayloadAction<number>) {
		yield put(ClaimPaymentsSlice.actions.updateDataStep(action.payload));
	}

	/**
	 * 
	 */
	function* updatePicturesStep(action: PayloadAction<number>) {
		yield put(ClaimPaymentsSlice.actions.updatePicturesStep(action.payload));
	}
	
	/**
	 * 
	 */
	 function* updateDocumentsStep(action: PayloadAction<number>) {
		yield put(ClaimPaymentsSlice.actions.updateDocumentsStep(action.payload));
	}

	/**
	 * 
	 */
	function* updateClaimVehicle(action: PayloadAction<Vehicle>) {
		yield put(ClaimPaymentsSlice.actions.storeClaimVehicle(action.payload));
	}
	
	/**
	 * 
	 */
	function* updateAccountStep(action: PayloadAction<number>) {
		yield put(ClaimPaymentsSlice.actions.updateAccountStep(action.payload));
	}

	/**
	 * 
	 */
	function* updateProposal(action: PayloadAction<boolean>) {
		yield put(ClaimPaymentsSlice.actions.updateProposal(action.payload));
	}

	/**
	 * 
	 */
	function* updateIsAgreedPayment(action: PayloadAction<boolean>) {
		yield put(ClaimPaymentsSlice.actions.updateIsAgreedPayment(action.payload))
	}

	/**
	 * 
	 */
	function* updateIsLoadingDamageClaim(action: PayloadAction<boolean>) {
		yield put(ClaimPaymentsSlice.actions.updateIsLoadingDamageClaim(action.payload))
	}

  export function* claimPaymentSaga() {
		yield takeLatest(ClaimPaymentsAction.clearDamageClaim.type, clear);
		yield takeLatest(ClaimPaymentsAction.updateCurrentPath.type, updateCurrentPath);
    yield takeLatest(ClaimPaymentsAction.policyByNumber.type, getPolicyByNumber);
		yield takeLatest(ClaimPaymentsAction.getDamageClaimById.type, getDamageClaimById);
		yield takeLatest(ClaimPaymentsAction.storeDamageClaim.type, updateClaimPayment);
		yield takeLatest(ClaimPaymentsAction.updatePersonalData.type, updatePersonalData);
		yield takeLatest(ClaimPaymentsAction.storeVehicle.type, updateVehicle);
		yield takeLatest(ClaimPaymentsAction.storeClaimVehicle.type, updateClaimVehicle);
		yield takeLatest(ClaimPaymentsAction.updateDataStep.type, updateDataStep);
		yield takeLatest(ClaimPaymentsAction.updatePicturesStep.type, updatePicturesStep);
		yield takeLatest(ClaimPaymentsAction.updateDocumentsStep.type, updateDocumentsStep);
		yield takeLatest(ClaimPaymentsAction.updateAccountStep.type, updateAccountStep);
		yield takeLatest(ClaimPaymentsAction.updateProposal.type, updateProposal);
		yield takeLatest(ClaimPaymentsAction.updateIsAgreedPayment.type, updateIsAgreedPayment);
		yield takeLatest(ClaimPaymentsAction.updateIsLoadingDamageClaim.type, updateIsLoadingDamageClaim);
  }

}
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { DamageClaim } from "src/classes/DamageClaim";
import { Policy } from "src/classes/Policy";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { ClaimsAction } from "../actions/claims.action";
import { ClaimsFeature } from "../features/claims.feature";
import { ClaimsSlice } from "../slices/claims.slice";

/**
 *
 */
export namespace ClaimsSaga {
    type PolicyList = SagaReturnType<typeof CorePolicyFeature.activePoliciesListFeature>;
    type ClaimList = SagaReturnType<typeof ClaimsFeature.activeClaimListFeature>;

    /**
     * 
     */
    function* getActivePolicyList() {
      try {
        const list: PolicyList = yield call(CorePolicyFeature.activePoliciesListFeature);
        yield put(ClaimsSlice.actions.policyList(list as Policy[]));
      } catch (e) {
  
      }
  
    }

    /**
     * 
     */
     function* getActiveClaimPolicyList() {
      try {
				yield put(ClaimsSlice.actions.claimList([] as DamageClaim[]));
        const list: ClaimList = yield call(ClaimsFeature.activeClaimListFeature);
        yield put(ClaimsSlice.actions.claimList(list as DamageClaim[]));
      } catch (e) {
  
      }
    }

		/**
     * 
     */
		function* getActiveAgreedClaimPolicyList() {
			try {
				yield put(ClaimsSlice.actions.claimList([] as DamageClaim[]));
				const list: ClaimList = yield call(ClaimsFeature.activeAgreedClaimListFeature);
				yield put(ClaimsSlice.actions.claimList(list as DamageClaim[]));
			} catch (e) {
	
			}
		}
  
    export function* policySaga() {
      yield takeLatest(ClaimsAction.policyList.type, getActivePolicyList);
      yield takeLatest(ClaimsAction.claimList.type, getActiveClaimPolicyList);
      yield takeLatest(ClaimsAction.agreedClaimList.type, getActiveAgreedClaimPolicyList);
    }
}
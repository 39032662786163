import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { Person  } from "src/classes/Person";
import { CoreUserInfoFeature } from "src/core/features/user-info.feature";
import { CoreInstanceSlice } from "src/core/slices/instance.slice";
import { DashboardAction } from "../actions/dashboard.action";

/**
 *
 */
export namespace DashboardSaga {

  type CurrentUser = SagaReturnType<typeof CoreUserInfoFeature.getUserInfoFeature>;

  /**
   * 
   */
  function* getUserInfo() {
    try {
      const user: CurrentUser = yield call(CoreUserInfoFeature.getUserInfoFeature);
      yield put(CoreInstanceSlice.actions.currentUser(user as Person));

    } catch (e) {

    }
  }

  /**
   * 
   * @param action 
   */
  function* dashbordMenu(action: PayloadAction<boolean>) {
    yield put(CoreInstanceSlice.actions.pageMenu(action.payload));
  }

  /**
   * 
   * @param action 
   */
  function* menuVisibility(action: PayloadAction<boolean>) {
    yield put(CoreInstanceSlice.actions.openMenu(action.payload));
  }

  export function* dashboardSaga() {
    yield takeLatest(DashboardAction.userInfo.type, getUserInfo);
    yield takeLatest(DashboardAction.dashboardMenu.type, dashbordMenu);
    yield takeLatest(DashboardAction.menuVisibility.type, menuVisibility);
  }
}
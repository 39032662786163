import { createRef, useEffect, useState } from 'react';
import { Coverage } from 'src/classes/plan/Coverage';
import { CoverageOption } from 'src/classes/plan/CoverageOption';
import { IconFactory } from 'src/factory/icon.factory';
import { PlanManagementFacade } from 'src/libs/PlanManagement/controller/facades/plan-management.facade';
import './PlanCoverageOption.scss';

/**
 *
 */
interface IPlanCoverageOption {
  /**
   * 
   */
  coverage: Coverage;
  /**
   * 
   */
  selectedOption?: CoverageOption;
  /**
   * 
   */
  onSelect: (option: CoverageOption) => void;
}

/**
 *
 */
const PlanCoverageOption = (props: IPlanCoverageOption): JSX.Element => {
  const { isUpdating } = PlanManagementFacade.usePlanSync();
  const [showList, setShowList] = useState<boolean>(false);
  const [currentValueText, setCurrentValueText] = useState<string>('');
  const wrapperRef = createRef<HTMLDivElement>();

  /**
   * 
   * @param option 
   */
  const onSelectOption = (option: CoverageOption): void => {
    if (option !== props.selectedOption) {
      props.onSelect(option);
    }
    setShowList(false);
  }

  /**
   * 
   */
  useEffect(() => {
    const closeList = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowList(false);
      }
    }

    document.addEventListener("mousedown", closeList);
    return () => {
      document.removeEventListener("mousedown", closeList);
    };
  }, [wrapperRef]);

  /**
   * 
   */
  useEffect(
    () => {
      if (props.selectedOption)
        setCurrentValueText(props.coverage.isDeductible ? ` Deducible de ${props.selectedOption.getDeductibleValue()}` : `Suma asegurada de ${props.selectedOption.getAssuredValue()}`);
    }, [props.selectedOption, props.coverage]
  )

  return (
    <>

      <div className="coverageOption" ref={wrapperRef}>
        <div className={`coverageOption__selected ${showList ? 'coverageOption__selected--open' : ''}`} disabled-options={isUpdating || !props.coverage.isEnabled ? '' : undefined}
          onClick={(e) => { e.preventDefault(); setShowList(!showList) }}
        >
          <p>
            {
              props.coverage.isEnabled ?
                <>
                  {
                    props.coverage.isDeductible ?
                      <>
                        Deducible de {props.selectedOption?.getDeductibleValue()}
                      </>
                      :
                      <>
                        Suma asegurada de {props.selectedOption?.getAssuredValue()}
                      </>
                  }

                </>
                :
                <>
                  {currentValueText}
                </>
              }
          </p>
          {IconFactory.editIcon()}
        </div>

        {
          showList &&
          <ul className="coverageOption__list">
            {
              props.coverage.customitationOptions.map(
                (option, index) =>
                  <li className="coverageOption__list__item" key={`option-${index}`} onClick={(e) => { e.preventDefault(); onSelectOption(option) }}>
                    <p>
                      {
                        props.coverage.isDeductible ?
                          <>
                            Deducible de <b>{option.getDeductibleValue()}</b>
                          </>
                          :
                          <>
                            Suma asegurada de <b>{option.getAssuredValue()}</b>
                          </>
                      }
                    </p>
                  </li>
              )
            }
          </ul>
        }
      </div>

    </>
  );
};

export default PlanCoverageOption;
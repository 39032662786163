import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects'
import { CorePolicyFeature } from "src/core/features/policy.feature";

import { CoreInstanceSlice } from 'src/core/slices/instance.slice';
import { MainProductLimitAction } from "../actions/product-limit.action";
import { MainAuthSelector } from '../selectors/auth.selector';

export namespace MainProductLimitSaga {

  type Limit = SagaReturnType<typeof CorePolicyFeature.isPoliciesMaxLimitFeature>;


  /**
   * 
   */
  function* getProducts() {
    try {
      const isValidAuth: boolean = yield select(MainAuthSelector.isValidAuth);
      if (isValidAuth) {
        const isMaxLimit: Limit = yield call(CorePolicyFeature.isPoliciesMaxLimitFeature);
        yield put(CoreInstanceSlice.actions.maxProducts(isMaxLimit as boolean));
      }
    } catch (e) {

    }

  }

  /**
   * 
   */
  export function* productLimitSaga() {
    yield takeLatest(MainProductLimitAction.productLimit, getProducts);
  }
}


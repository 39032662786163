import { useForm } from "react-hook-form";
import { IPlanPaymentManagementForm } from "../interfaces/IPlanPaymentManagementForm";

/**
 * 
 */
export namespace PlanPaymentManagementFormFacade {

  /**
   * 
   */
  export const usePlanPaymentManagementForm = () => {
    const { register, formState: { isValid } } = useForm<IPlanPaymentManagementForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    return { register, formState: { isValid } }
  }
}
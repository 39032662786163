import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IPolicyCreationVehicleRequest } from "../interfaces/IPolicyCreationVehicleRequest";

/**
 *
 */
export namespace PolicyVehicleService {

  /**
   * 
   * @param params 
   */
  export const createVehicleService = (data: IPolicyCreationVehicleRequest): AxiosPromise<any> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.CREATE_VEHICLE, data);
  }

}
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects";
import { Coverage } from "src/classes/plan/Coverage";
import { Plan } from "src/classes/plan/Plan";
import { AlertFactory } from "src/factory/alert.factory";
import { PlanManagementAction } from "../actions/plan-management.action";
import { PlanManagementFeature } from "../features/plan-management.feature";
import { IPlanManagement } from "../interfaces/IPlanManagement";
import { PlanManagementSelector } from "../selectors/plan-management.selector";
import { PlanManagementSlice } from "../slices/plan-management.slice";

/**
 *
 */
export namespace PlanManagementSaga {

  type Plans = SagaReturnType<typeof PlanManagementFeature.getQuotationFeature>;

  /**
   * 
   * @param action 
   */
  function* setCoverages(action: PayloadAction<Coverage[]>) {
    yield put(PlanManagementSlice.actions.coverages(action.payload));
  }


  /**
   * 
   * @param action 
   */
  function* getQuotation(action: PayloadAction<IPlanManagement>) {
    try {
      const isUpdating: boolean = yield select(PlanManagementSelector.isUpdatingPlan);
      if (!isUpdating) {
        const isCustomizing: boolean = yield select(PlanManagementSelector.isCustomizingPlan);
        if (isCustomizing) {
          yield put(PlanManagementSlice.actions.isUpdating(true));
        } else if (action.payload.loadAll) {
          yield put(PlanManagementSlice.actions.isLoading(true));
        }

        if (action.payload.coupon && action.payload.coupon !== '') {
          yield put(PlanManagementSlice.actions.currentCoupon(action.payload.coupon));
        } else if (action.payload.coupon === '') {
          yield put(PlanManagementSlice.actions.currentCoupon(''));
        }

        const coupon: string = yield select(PlanManagementSelector.currentCoupon);
        const management: IPlanManagement = { vehicle: action.payload.vehicle, user: action.payload.user, coverages: action.payload.coverages, coupon: coupon, isRenewal: action.payload.isRenewal }
        const plans: Plans = yield call(PlanManagementFeature.getQuotationFeature, management);
        yield put(PlanManagementSlice.actions.plansList(plans as Plan[]));
        const recomendedPlan: Plan = yield select(PlanManagementSelector.currentRecomendedPlan);
        if (!recomendedPlan) {
          const management: IPlanManagement = { vehicle: action.payload.vehicle, user: action.payload.user, coupon: coupon, isRenewal: action.payload.isRenewal }
          const plans: Plans = yield call(PlanManagementFeature.getQuotationFeature, management);
          yield put(PlanManagementSlice.actions.setCurrentRecomendedPlan(plans![0] as Plan));
        }

        if (action.payload.coverages) {
          yield put(PlanManagementSlice.actions.coverages(action.payload.coverages));
        } else {
          yield put(PlanManagementSlice.actions.coverages([]));
        }

      }

    } catch (e: any) {
      // Coupon Error null
      if ((e as Error).name === '4000') {
        yield put(PlanManagementSlice.actions.currentCoupon(''));
        AlertFactory.errorAlert(e.message);
        yield put(PlanManagementSlice.actions.isUpdating(false));
      }
      // End Coupon Error null
      else {
        yield put(PlanManagementSlice.actions.plansList([]));
      }
    } finally {
      yield put(PlanManagementSlice.actions.isLoading(false));
    }
  }

  /***
   * 
   */
  function* setUpdating(action: PayloadAction<boolean>) {
    yield put(PlanManagementSlice.actions.isUpdating(action.payload));
  }

  /***
   * 
   */
  function* customizationState(action: PayloadAction<boolean>) {
    yield put(PlanManagementSlice.actions.isCustomizing(action.payload));
  }

  /**
   * 
   */
  function* clearManagement() {
    yield put(PlanManagementSlice.actions.clear());
  }

  /**
   * 
   */
  function* setPlanConfiguration(action: PayloadAction<IPlanManagement>) {
    yield put(PlanManagementSlice.actions.setPlanConfiguration(action.payload));
  }

  /**
   * 
   */
  function* setCurrentPlan(action: PayloadAction<Plan>) {
    yield put(PlanManagementSlice.actions.setCurrentPlan(action.payload));
  }

  export function* planManagementSaga() {
    yield takeLatest(PlanManagementAction.coverages.type, setCoverages);
    yield takeLatest(PlanManagementAction.quotation.type, getQuotation);
    yield takeLatest(PlanManagementAction.customizationState.type, customizationState);
    yield takeLatest(PlanManagementAction.clearManagement.type, clearManagement);
    yield takeLatest(PlanManagementAction.planConfiguration.type, setPlanConfiguration);
    yield takeLatest(PlanManagementAction.currentPlan.type, setCurrentPlan);
    yield takeLatest(PlanManagementAction.setUpdating.type, setUpdating);
  }
}
import { useEffect, useState } from "react";
import { PaymentCost } from "src/classes/PaymentCost";
import { Plan } from "src/classes/plan/Plan";
import { Util } from "src/utils/Util";

export namespace PlanSummaryManagementFacade {

  export const PAYMENT_METHOD = {
    DEBIT: { name: 'Débito', code: 1 },
    CREDIT: { name: 'Crédito', code: 2 },
  }

	export const PAYMENT_TERMS = {
		ANNUAL: 0,
		SUSCRIPTION: 1,
		THREE_PAYMENTS: 1,
		SIX_PAYMENTS: 2,
		NINE_PAYMENTS: 3,
		TWELVE_PAYMENTS: 4,
	}

  /**
   * 
  */
  export const usePlanSummary = (plan?: Plan) => {
		/** */
		const [showPaymentTypeModal, setShowPaymentTypeModal] = useState<boolean>(false);
		const [selectedCost, setSelectedCost] = useState<PaymentCost>();

		useEffect(
			() => {
				if (plan && plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS) {
					if (plan.discount) {
						for (const cost of plan.discount.monthly.costs) {
							if (cost.monthsQuantity === plan.selectedPlanPayment.paymentsCount) {
								setSelectedCost(cost);
							}
						}
					} else {
						for (const cost of plan.monthly.costs) {
							if (cost.monthsQuantity === plan.selectedPlanPayment.paymentsCount) {
								setSelectedCost(cost);
							}
						}
					}
				}
			},
			[plan]
		)

    return { showPaymentTypeModal, setShowPaymentTypeModal, selectedCost };
  }
}
import { Button } from "src/components";
import { IconFactory } from 'src/factory/icon.factory';
import { IdentityValidationFacade } from 'src/modules/policy-endorsements/controller/facades/identity-validation.facade';
import { POLICY_ENDORSEMENTS_PATH } from 'src/routes/paths/policy-endorsements.paths';
import { Util } from 'src/utils/Util';
import './IdentityValidation.scss';

/**
 *
 */
interface IIdentityValidation {

}

/**
 *
 */
const IdentityValidationPage = (props: IIdentityValidation): JSX.Element => {

  /** */
  const { isLoading, identityStatus, identityErrorStatus, NO_STATUS, getTruoraToken, navigateForward } = IdentityValidationFacade.useIdentityValidation();

  return (
    <div id="identity-validation-wrapper" className="height-100">
      {
        identityStatus === NO_STATUS && (
          <>
            <h1>Hola, antes que nada necesitamos</h1>
            <h3>Validar tus documentos en tu expediente.</h3>
            <p>Por favor, ayúdanos a tomar las siguientes fotografías.</p>

            <p className='update-ine-paragraph'>Necesitas actualizar tu INE</p>

            <Button loading={isLoading} disabled={isLoading} onClick={getTruoraToken} icon={IconFactory.uploadIcon()}>
              Subir INE
            </Button>

            <p className='missing-ine-paragraph'>¿No tienes INE?
              <a href={POLICY_ENDORSEMENTS_PATH.IDENTITY_VALIDATION_SUPPORT_AGENT} target='_blank' rel="noreferrer">
                Contactar a soporte
              </a>
            </p>
          </>
        )
      }

      {
        (identityStatus === Util.STATUS_CODE.IDENTITY_STATUS.CREATED || identityStatus === Util.STATUS_CODE.IDENTITY_STATUS.INCOMPLETE) && (
          <>
            <h1>
							{
								identityStatus === Util.STATUS_CODE.IDENTITY_STATUS.INCOMPLETE ? '¡Ya comenzaste el proceso!' : '¡Estamos validando tus documentos!'
							}
						</h1>
            <p>En breve actualizaremos el estatus de tus documentos.</p>

            {IconFactory.clockIcon({ className: 'clock' })}

            <p className='missing-ine-paragraph'>¿No completaste el proceso? <span className={`link ${isLoading ? 'disabled' : ''}`} onClick={getTruoraToken}>Obtén el link nuevamente</span></p>
          </>
        )
      }

      {
        identityStatus === Util.STATUS_CODE.IDENTITY_STATUS.COMPLETE && (
          <>
						<div className='identity-image' style={ { backgroundImage: `url(${Util.ASSET.IDENTITY_CARD_SUCCESS})` } }></div>
            <h1>¡Tu validación ya está lista!</h1>
            <p className={'success-p'}>Ya puedes continuar a administrar tu póliza.</p>

            <Button onClick={navigateForward}>
              Continuar
            </Button>
          </>
        )
      }

      {
        identityStatus === Util.STATUS_CODE.IDENTITY_STATUS.DENIED &&
        <>
					<div className='identity-image' style={ { backgroundImage: `url(${Util.ASSET.IDENTITY_CARD_ERROR})` } }></div>
					<h1>¡Lo sentimos!</h1>

          {
            identityErrorStatus === Util.STATUS_CODE.IDENTITY_ERROR_STATUS.EXPIRED &&
							<h3>El tiempo de validación expiró.</h3>
          }

          {
            (identityErrorStatus === Util.STATUS_CODE.IDENTITY_ERROR_STATUS.INVALID || identityErrorStatus === Util.STATUS_CODE.IDENTITY_ERROR_STATUS.DECLINED) &&
							<h3>Ha ocurrido un error en la validación</h3>
          }

					{
						identityErrorStatus === Util.STATUS_CODE.IDENTITY_ERROR_STATUS.BLOCKED &&
							<h3>Ha fallado la identificación en línea.</h3>
					}

					{
						identityErrorStatus === Util.STATUS_CODE.IDENTITY_ERROR_STATUS.BLOCKED ? 
							(
								<p className='missing-ine-paragraph'>
									<a href={POLICY_ENDORSEMENTS_PATH.IDENTITY_VALIDATION_SUPPORT_AGENT} target='_blank' rel="noreferrer">
										Contacta con soporte 
									</a>
									para <br/> ayudarte a validar tu identidad.
								</p>
							)
						:
							(
								<>
									<p className='update-ine-paragraph'>Intenta iniciar el proceso nuevamente.</p>
									{/* <p className='update-ine-paragraph'>Necesitas actualizar tu INE</p> */}

									<Button loading={isLoading} disabled={isLoading} onClick={getTruoraToken}>
										Subir INE
										{IconFactory.uploadIcon()}
									</Button>

									<p className='missing-ine-paragraph'>¿Persiste el error? 
										<a href={POLICY_ENDORSEMENTS_PATH.IDENTITY_VALIDATION_SUPPORT_AGENT} target='_blank' rel="noreferrer">
											Contactar a soporte
										</a>
									</p>
								</>
							)	
					}
        </>
      }
    </div>
  );
};

export default IdentityValidationPage;
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Policy } from "src/classes/Policy";
import { Util } from "src/utils/Util";
import { POLICY_CREATION_PATH } from "../../../../routes/paths/policy-creation.paths";
import { PolicyCreationAction } from "../actions/policy-creation.action";
import { PolicyCreationFacade } from "./policy-creation.facade";

/**
 * 
 */
export namespace PolicyPlanPaymentFacade {

  /**
   * 
   */
  export const usePlanPayment = () => {
		/** */
    const { user, driver } = PolicyCreationFacade.usePolicyCreationActors();
    const [showPicturesPrompt, setShowPicturesPrompt] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * 
     */
    const onCheckoutDone = (policy: Policy): void => {
			const userEmail: string = driver? driver.email : user.email;
			dispatch(PolicyCreationAction.clearManagement());
			localStorage.removeItem(Util.KEY.POLICY_NEW_VEHICLE);
			localStorage.removeItem(Util.KEY.POLICY_NEW_DRIVER);
			navigate(`${POLICY_CREATION_PATH.COMPLETION}?user=${userEmail}&policy=${policy?.policyNumber}`, { replace: true });
    };

    return { onCheckoutDone, setShowPicturesPrompt, showPicturesPrompt }
  }

}
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IVehicleBrandData } from "../interfaces/IVehicleBrandData";
import { IVehicleModelData } from "../interfaces/IVehicleModelData";
import { IVehicleVersionData } from "../interfaces/IVehicleVersionData";
import { IVehicleYearData } from "../interfaces/IVehicleYearData";

/**
 *
 */
export namespace VehicleManagementService {

  /**
   * 
   * @returns 
   */
  export const vehicleBrandsService = (): AxiosPromise<IVehicleBrandData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VEHICLE_BRAND);
  }

  /**
   * 
   * @param _brand 
   * @returns 
   */
  export const vehicleModelsService = (brand: string): AxiosPromise<IVehicleModelData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VEHICLE_MODEL(brand));
  }

  /**
   * 
   * @param params Params object. Type: brand, model.
   * @returns 
   */
  export const vehicleYearsService = (data: { brand: string, model: string }): AxiosPromise<IVehicleYearData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VEHICLE_YEAR(data.brand, data.model));
  };


  /**
   * 
   * @param params Params object. Type: brand, model, year.
   * @returns 
   */
  export const vehicleVersionsService = (data: { brand: string, model: string, year: string }): AxiosPromise<IVehicleVersionData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VEHICLE_VERSION(data.brand, data.model, data.year));
  };
}
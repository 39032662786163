export const constant = {
  WEB_URL: `https://cotizador.crabi.com`,
  WA_SUPPORT: `https://api.whatsapp.com/send/?phone=525588527287&text&type=phone_number&app_absent=0`,
  WEB_REQUESTS: `https://www.crabi.com/support/solicitudes`,
  QUOTATION: {
    PLAN_NAMES: {
      CUSTOM_PLAN: {
        serverName: 'CUSTOM_PLAN',
        clientName: 'Plan Personalizable',
        edit: true,
        description: '¡No dejes nada a la suerte! Con este plan tu auto está en las mejores manos y tienes todas las coberturas para que estés tranquilo al manejar.'
      },
      EXTENDED_PLAN: {
        serverName: 'EXTENDED_PLAN',
        clientName: 'Plan Amplio',
        edit: false,
        description: 'Lo mínimo para estar bien cubierto en el camino. Puedes estar tranquilo, con este plan todo esta cubierto.'
      },
      BASIC_PLAN: {
        serverName: 'BASIC_PLAN',
        clientName: 'Plan Básico',
        edit: false,
        description: 'Lo mínimo para estar dentro de la ley. Este plan tiene las coberturas mínimas requeridas por ley para poder circular.'
      },
    },
    COVERAGE_NAMES: {
      RCP: { type: 'RCP', name: '', id: '42d81165-f6f7-4c0d-b33b-999a3f3ee905'}, //42d81165-f6f7-4c0d-b33b-999a3f3ee905
      RCB: { type: 'RCB', name: '', id: 'fa67b067-ffdf-431b-aef5-53e60dfe6be6'}, //fa67b067-ffdf-431b-aef5-53e60dfe6be6
      DT: { type: 'DT', name: 'Daños a terceros', id: '' },
      GM: { type: 'GM', name: 'Atención médica', id: '6c434f2a-d0f3-4442-b4c5-6680ec0fda6d' }, //6c434f2a-d0f3-4442-b4c5-6680ec0fda6d
      AL: { type: 'AL', name: 'Asistencia legal', id: 'ca2ead83-5b81-4480-9a0b-744ba31601c0' }, //ca2ead83-5b81-4480-9a0b-744ba31601c0
      AV: { type: 'AV', name: 'Asistencia en el camino', id: 'ed22aeaf-8623-4d4d-ad59-106a774ed9a5' }, //ed22aeaf-8623-4d4d-ad59-106a774ed9a5
      DM: { type: 'DM', name: 'Daños a mi auto', id: 'c2b3cdd7-2c89-4d92-aa04-6b343ad91425' }, //c2b3cdd7-2c89-4d92-aa04-6b343ad91425
      RT: { type: 'RT', name: 'Robo total de mi auto', id: 'fc9e6248-5746-4480-a9d0-d683cb6a6c10' }, //fc9e6248-5746-4480-a9d0-d683cb6a6c10
      RCBO: { type: 'RCBO', name: 'Responsabilidad civil bienes obligatoria', id: 'b691ceae-c137-493b-825e-c7b22fff40cd' }, //b691ceae-c137-493b-825e-c7b22fff40cd
      RCPO: { type: 'RCPO', name: 'Responsabilidad civil personas obligatoria', id: '538aa337-349f-4436-8d45-e70687628951' }, //538aa337-349f-4436-8d45-e70687628951
    },
    COVERAGE_DESCRIPTIONS: {
      DT: {
        description: 'Sin importar quien sea el conductor, nos encargamos de los daños que ocasione tu auto a peatones, otros autos y/o vía pública.',
        include: [
          'Pago de daños provocados a otros autos (Responsabilidad civil bienes)',
          'Atención médica a los pasajeros de otros autos dañados (Responsabilidad civil personas)',
          'Pago de daños ocasionados a la vía pública (postes, semáforos, árboles, etc.)',
          'Seguro de responsabilidad civil obligatoria (Seguro obligatorio para todos los autos en circulación desde el 2019)',
        ],
        exclude: [
          'Pago de daños si existe una relación entre afectados (ninguna aseguradora cubre esta situación)',
        ]
      },
      GM: {
        description: '¡Tranquilo! Cubrimos tus gastos médicos y los de tus acompañantes en caso de accidente automovilístico.',
        include: [
          'Servicio de ambulancia al hospital',
          'Atención médica en caso de agresión en intento de robo del auto asegurado',
          'Consulta y medicamentos',
          'Hospitalización en caso de ser necesario (habitación y alimentos)',
        ],
        exclude: [
          'Atención médica de lesiones o enfermedades no provocadas por un accidente en el auto asegurado',
        ]
      },
      AL: {
        description: '¡No te preocupes! Si chocas con una persona que no está asegurada o provocas alguna lesión a terceros, tu póliza incluye un abogado para apoyarte con el proceso legal.',
        include: [
          'Honorarios del abogado para gestionar los trámites ante un robo total, liberación del conductor y/o del auto, así como para asesoría funeraria',
          'Pago y trámite de fianzas para liberar al conductor del auto asegurado en caso de ser detenido por causar un choque o atropello',
        ],
        exclude: [
          'Pago de multas del auto asegurado',
          'Gestión vehicular ni liberación física del auto (si lo requiere puedes solicitarlos con costo preferencial)',
        ]
      },
      AV: {
        description: 'Las averías pasan y estamos para apoyarte con servicio de grúa, entrega de gasolina, carga de batería o cambio de llanta.',
        include: [
          'Dos servicios de grúa al año por un valor máximo de $2,500.00 MXN cada uno',
          'Dos servicios adicionales al año entre: paso de corriente, o cambio de llanta, o entrega de gasolina (incluye 5 litros sin costo) o cerrajería',
          'Servicio extra de grúa o de servicio adicional con costo preferencial si lo requieres',
        ],
        exclude: [
          'Refacciones para el servicio de cambio de llanta',
        ]
      },
      DM: {
        description: 'Reparamos los daños de tu auto acorde a su valor comercial; provocados en un choque o desastre natural.',
        include: [
          'Reparación de tu auto cuando el valor del daño sea menor al 75% de su valor comercial',
          'El pago por pérdida total cuando el valor del daño sea igual o mayor que el 75% de su valor comercial',
          'Reposición de cristales pagando un deducible del 20% sobre el valor del cristal',
        ],
        exclude: [
          'Reparación de fallas mecánicas no provocadas por un accidente automovilístico',
          'Daños menores al valor del deducible',
          'Daños a espejos, laterales, calaveras y/o faros no provocados por un accidente automovilístico',
          'Daños a accesorios no instalados de fábrica',
        ]
      },
      RT: {
        description: 'Crabi te cubre con la desaparición completa de tu auto.',
        include: [
          'Búsqueda de tu auto durante los siguientes 30 días del robo',
          'El pago de tu auto a valor comercial en los siguientes 5 días posteriores al periodo de la investigación y entrega de tus documentos',
          'Asesoría y asistencia legal para la denuncia, baja y/o liberación del auto robado',
        ],
        exclude: [
          'Robo de autopartes, accesorios del auto como la computadora del auto o pertenencias de los pasajeros del auto',
          'Pago de multas o recargos del auto al momento de dar su baja',
        ]
      }
    },
    QUOTATION_COVERAGES: [
      {
        name: "CUSTOM_PLAN",
        policies: [
          {
            period: "ANNUAL",
            coverages: [
              {
                coverage_type: {
                  name: "DM",
                  id: "c2b3cdd7-2c89-4d92-aa04-6b343ad91425"
                },
                deductible: 0.1,
                enabled: true
              },
              {
                coverage_type: {
                  name: "RT",
                  id: "fc9e6248-5746-4480-a9d0-d683cb6a6c10"
                },
                deductible: 0.15,
                enabled: true
              },
              {
                coverage_type: {
                  name: "RCB",
                  id: "fa67b067-ffdf-431b-aef5-53e60dfe6be6"
                },
                sum_assured: 1000000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "RCP",
                  id: "42d81165-f6f7-4c0d-b33b-999a3f3ee905"
                },
                sum_assured: 1000000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "GM",
                  id: "6c434f2a-d0f3-4442-b4c5-6680ec0fda6d"
                },
                sum_assured: 200000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "AL",
                  id: "ca2ead83-5b81-4480-9a0b-744ba31601c0"
                },
                enabled: true
              },
              {
                coverage_type: {
                  name: "AV",
                  id: "ed22aeaf-8623-4d4d-ad59-106a774ed9a5"
                },
                enabled: true
              }
            ]
          }
        ]
      },
      {
        name: "EXTENDED_PLAN",
        policies: [
          {
            period: "ANNUAL",
            coverages: [
              {
                coverage_type: {
                  name: "DM",
                  id: "c2b3cdd7-2c89-4d92-aa04-6b343ad91425"
                },
                deductible: 0.05,
                enabled: true
              },
              {
                coverage_type: {
                  name: "RT",
                  id: "fc9e6248-5746-4480-a9d0-d683cb6a6c10"
                },
                deductible: 0.1,
                enabled: true
              },
              {
                coverage_type: {
                  name: "RCB",
                  id: "fa67b067-ffdf-431b-aef5-53e60dfe6be6"
                },
                sum_assured: 1500000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "RCP",
                  id: "42d81165-f6f7-4c0d-b33b-999a3f3ee905"
                },
                sum_assured: 1500000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "GM",
                  id: "6c434f2a-d0f3-4442-b4c5-6680ec0fda6d"
                },
                sum_assured: 200000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "AL",
                  id: "ca2ead83-5b81-4480-9a0b-744ba31601c0"
                },
                enabled: true
              },
              {
                coverage_type: {
                  name: "AV",
                  id: "ed22aeaf-8623-4d4d-ad59-106a774ed9a5"
                },
                enabled: true
              }
            ]
          }
        ]
      },
      {
        name: "BASIC_PLAN",
        policies: [
          {
            period: "ANNUAL",
            coverages: [
              {
                coverage_type: {
                  name: "DM",
                  id: "c2b3cdd7-2c89-4d92-aa04-6b343ad91425"
                },
                deductible: 0.05,
                enabled: false
              },
              {
                coverage_type: {
                  name: "RT",
                  id: "fc9e6248-5746-4480-a9d0-d683cb6a6c10"
                },
                deductible: 0.1,
                enabled: false
              },
              {
                coverage_type: {
                  name: "RCB",
                  id: "fa67b067-ffdf-431b-aef5-53e60dfe6be6"
                },
                sum_assured: 1500000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "RCP",
                  id: "42d81165-f6f7-4c0d-b33b-999a3f3ee905"
                },
                sum_assured: 1500000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "GM",
                  id: "6c434f2a-d0f3-4442-b4c5-6680ec0fda6d"
                },
                sum_assured: 200000,
                enabled: true
              },
              {
                coverage_type: {
                  name: "AL",
                  id: "ca2ead83-5b81-4480-9a0b-744ba31601c0"
                },
                enabled: true
              },
              {
                coverage_type: {
                  name: "AV",
                  id: "ed22aeaf-8623-4d4d-ad59-106a774ed9a5"
                },
                enabled: false
              }
            ]
          }
        ]
      }
    ]
  },
  OTP: {
    CODE_TYPE: {
      EMAIL: 'email',
      SMS: 'sms'
    },
    TIME: 60
  },
  CLAIMS_PROPOSAL_STATEMENT:{
    PARAGRAPH_ONE: (value: string) => `Hago referencia al vehículo descrito anteriormente, el cual estuvo involucrado en el siniestro que se describe en la tabla anterior. 
    En relación con lo anterior, manifiesto que he aceptado que la Aseguradora realice una transferencia electrónica por la cantidad total 
    de ${value}. A la cuenta bancaria establecida en la carátula del estado de 
    cuenta, por concepto de indemnización por los daños causados al vehículo durante el siniestro, menos la cantidad correspondiente al 
    monto del deducible (la “Indemnización”).`,
    PARAGRAPH_TWO: `Manifiesto mi consentimiento en que la Aseguradora realice el pago de la indemnización en un plazo de 7 (siete) días hábiles contados a partir 
    del día siguiente en que finalice la entrega de la información y documentos solicitados para procesar el pago.`,
    PARAGRAPH_THREE: `En virtud de lo anterior, mediante la presente carta otorgo a Crabi, S.A. de C.V. el más amplio finiquito que en derecho proceda, sin reserva de 
    acción o derecho alguno, y libero a Crabi, S.A. de C.V. de cualquier pago adicional que pudiera surgir por la reparación del vehículo, así como de 
    cualquier responsabilidad, reclamación, indemnización o adeudo que pudiera surgir en un futuro en relación con el estado del vehículo y los daños a 
    terceros que se pudieran causar con el mismo. Asimismo, manifiesto que en la fecha de la presente el Taller me ha entregado el vehículo en las 
    condiciones en las que ingresó.`,
    PARAGRAPH_FOUR: `El presente documento, no constituye ni prejuzga sobre la procedencia del pago de la indemnización, por lo tanto, no priva a Crabi de las excepciones 
    que pueda oponer en contra del Asegurado o Terceros.`
  },
  CLAIMS_COMPLETION: {
    COMPLETED_TITLE: `¡Gracias!`,
    INCOMPLETED_TITLE: `¡No olvides subir lo que te falta!`,
    COMPLETED_TEXT: `De ser la documentación correcta, en un lapso de 48 a 72 horas te compartiremos el monto de indemnización, esperando tu confirmación para poder programar el pago en los próximos 7 días hábiles.`,
    INCOMPLETED_TEXT: `Una vez que tengamos toda la documentación correcta, en un lapso de 48 a 72 horas, te compartiremos el monto de indemnización esperando tu confirmación para poder programar el pago en los próximos 7 días hábiles.`,
    PROPOSAL_APPROVED_TITLE: 'Has aceptado el monto',
    PROPOSAL_APPROVED_TEXT: 'Tu recibo de pago estará disponible en un lapso de 48 a 72 horas. Podrás descargar el PDF una vez que se haya generado.',
		PENDING_PAYMENT_TITLE: 'Estamos procesando tu pago',
		PENDING_PAYMENT_TEXT: ' En breve nos comunicaremos contigo.',
		DAMAGE_PAID_TITLE: 'Se ha realizado tu pago',
		MISSING_PROPOSAL_TITLE: 'Estamos evaluando el monto',
		MISSING_PROPOSAL_TEXT: 'En breve nos comunicaremos contigo.'
		//DAMAGE_PAID_TEXT: 'Ya puedes revisar el comprobante de pago'
	},
  CARD_ERROR: {
    DECLINE: 'Tu tarjeta fue rechazada.',
    FUNDS: 'Tu tarjeta no tiene fondos suficientes.',
    EXPIRED: 'La tarjeta ha vencido.',
    CVC: 'El código de seguridad de la tarjeta no es correcto.',
    PROCESSING: 'Se produjo un error al procesar tu tarjeta. Espera un momento y vuelve a intentarlo.',
		INVALID: 'Este método de pago solo acepta tarjetas de crédito.'
  },
	CONTRACT_TYPES: {
		CARD: {
			DEBIT: 'DEBIT',
			CREDIT: 'CREDIT',
		},
		SPEI: 'SPEI'
	},
	PLAN_PAYMENT_TYPES: {
		ANNUAL: 'ANNUAL',
		SUBSCRIPTION: 'SUBSCRIPTION',
		INSTALLMENTS: 'INSTALLMENTS'
	}
}
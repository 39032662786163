import { Spinner } from "src/components";

import './SpinnerCheckout.scss';

/**
 *
 */
interface ISpinnerCheckout {

}

/**
 *
 */
export const SpinnerCheckout = (props: ISpinnerCheckout): JSX.Element => {
  return (
    <div className="checkout-spinner-wrapper fixed flex justify-center fixed align-center width-100 height-100">
      <div className="checkout-spinner-background absolute width-100 height-100"></div>
      <div className="checkout-spinner-content relative">
        <h1>¡Un momento!</h1>
        <Spinner relative></Spinner>
        <h2>Preparando tu seguro...</h2>
      </div>
    </div>
  );
};
import { Util } from 'src/utils/Util';
import './RewardInfo.scss';

/**
 *
 */
interface IRewardInfo {

}

/**
 *
 */
const RewardInfo = (props: IRewardInfo): JSX.Element => {
  return (
    <div className="reward-info-wrapper absolute">
      <div className="relative">
        <section className="flex align-center">
          <div className="reward-info-image" style={{ backgroundImage: `url(${Util.ASSET.REFERRALS})` }}></div>
          <p className="flex-grow-100">
            <b>Tus referidos:</b> Es el número de personas que se han registrado con tu código.
          </p>
        </section>
        <section className="flex align-center">
          <div className="reward-info-image" style={{ backgroundImage: `url(${Util.ASSET.BADGE_POINTS})` }}></div>
          <p className="flex-grow-100">
            <b>Tus puntos:</b> Serán reflejados 250 puntos cuando tus referidos contraten, y luego 500 puntos más cuando pasen al menos 3 meses con su póliza activa y sin cancelar.
          </p>
        </section>
      </div>
    </div>
  );
};

export default RewardInfo;
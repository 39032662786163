import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { Policy } from "src/classes/Policy";

/**
 *
 */
export namespace PolicyAction {

  export const policyList: ActionCreatorWithoutPayload = createAction('POLICY_LIST');

  export const endorsements: ActionCreatorWithPayload<string> = createAction('ENDORSEMENTS');

  export const selectPolicy: ActionCreatorWithPayload<string> = createAction('SELECT_POLICY');

  export const updateSelectedPolicy: ActionCreatorWithPayload<string> = createAction('UPDATE_SELECTED_POLICY');

	export const clear: ActionCreatorWithoutPayload = createAction('CLEAR_POLICY');
  
  export const picturetoken: ActionCreatorWithoutPayload = createAction('PICTURE_TOKEN');

  export const clearPictureToken: ActionCreatorWithoutPayload = createAction('CLEAR_PICTURE_TOKEN');

  export const clearManagement: ActionCreatorWithoutPayload = createAction('CLEAR_ENDORSEMENTS');

  export const changeToCancelStatus: ActionCreatorWithPayload<Policy> = createAction('CHANGE_TO_CANCEL_STATUS');
  
}
import { PropsWithChildren } from 'react';
import { Coverage } from 'src/classes/plan/Coverage';
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { Toggle } from 'src/components';
import { IconFactory } from 'src/factory/icon.factory';
import { PlanCoverageFacade } from 'src/libs/PlanManagement/controller/facades/plan-coverage.facade';
import { PlanManagementFacade } from 'src/libs/PlanManagement/controller/facades/plan-management.facade';
import { Util } from 'src/utils/Util';
import PlanCoverageOption from '../PlanCoverageOption/PlanCoverageOption';
import './PlanCoverages.scss';

/**
 *
 */
interface IPlanCoverages {
  /**
   * 
   */
  coverages: Coverage[];
  /**
   * 
   */
  vehicle?: Vehicle;
  /**
   * 
   */
  collapsable?: boolean;

}

/**
 *
 */
const PlanCoverages = (props: PropsWithChildren<IPlanCoverages>): JSX.Element => {
  const { onChangeCoverageValue } = PlanCoverageFacade.useCoverageCustomization();
  const { defaultOptionValue } = PlanCoverageFacade.usePlanCoverage();
  const { isUpdating } = PlanManagementFacade.usePlanSync();

  return (
    <div className="planCoverages">

      {
        props.collapsable &&
        <div className="planCoverages__header flex space-between">

          <div className="planCoverages__header__vehicle flex align-center">
            {
              props.vehicle &&
              <>
                <img alt="vehicle-default" src={Util.ASSET.PLAN_QUOTER_CAR} />
                <h1>{props.vehicle.brand?.name} - {props.vehicle.model?.name} - {props.vehicle.year?.value}</h1>

                {props.children}
              </>
            }
          </div>
        </div>
      }

      <div className={`planCoverages__content ${props.collapsable? 'planCoverages__content--border' : ''}`}>
        {
          props.coverages.map(
            (coverage, index) => (
              <section key={`dmgCoveragge-${index}`} className="planCoverages__content__item flex">
                <div className="planCoverages__content__item__image">
                  <div>
                    <img alt="coverage-indicator" src={coverage.image} />
                  </div>
                </div>

                <div className="planCoverages__content__item__values width-100">

                  <div className="planCoverages__content__item__values__name flex space-between">
                    <div>
                      <h3>{coverage.name}</h3>
                      <h4>{coverage.description}</h4>
                    </div>

                    {
                      coverage.allowDisable &&
                      <div className="planCoverages__content__item__values__name__toggle flex align-center justify-flex-end" element-disabled={isUpdating? '' : undefined}>
                        <Toggle
                          onChange={(value) => onChangeCoverageValue(coverage, value)}
                          defaultValue={coverage.isEnabled}
                        />
                      </div>
                    }
                  </div>


                  <ul>
                    {coverage.includeList.map(
                      (value, index) => <li key={`dmgCoveragge-${index}-in-${index}`} className="flex">
                        <span>{IconFactory.checkIcon()}</span>
                        <p>{value}</p>
                      </li>
                    )}
                  </ul>

                  {
                    coverage.customitationOptions.length > 0 &&
                    <PlanCoverageOption 
                      onSelect={(option) => onChangeCoverageValue(coverage, coverage.isDeductible ? option.deductible : option.assured)}
                      coverage={coverage}
                      selectedOption={defaultOptionValue(coverage.isDeductible ? coverage.deductible : coverage.assured, coverage.customitationOptions, coverage.isDeductible)}
                    />
                    // <div className="planCoverages__content__item__values__customization" element-disabled={isUpdating? '' : undefined}>
                    //   <Dropdown
                    //     onChange={(item) => onChangeCoverageValue(coverage, coverage.isDeductible ? coverage.customitationOptions[item.id].deductible : coverage.customitationOptions[item.id].assured)}
                    //     items={
                    //       coverage.customitationOptions.map(
                    //         (option, index) => {
                    //           const value: string = coverage.isDeductible ? `Deducible de ${option.getDeductibleValue()}` : `Suma asegurada de ${option.getAssuredValue()}`
                    //           return { id: index, value: value }
                    //         }
                    //       )
                    //     } />
                    // </div>
                  }
                </div>
              </section>
            )
          )
        }
      </div>
    </div>
  );
};

export default PlanCoverages;
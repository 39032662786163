import { Link } from "react-router-dom";
import { Util } from "src/utils/Util";

import "./Logo.scss";

/**
 *
 */
interface ILogo {

  type?: 'short' | 'full';

  navigateRoot?: boolean;

  className?: string;
}

/**
 *
 */
export const Logo = (props: ILogo): JSX.Element => {
  const {
    type = !props.type ? 'short' : props.type,
  } = props;

  return (
    <>
      {
        type === 'short' &&
        <>
          {
            props.navigateRoot ?
              <Link className={`logoWrapper${props.className? ` ${props.className}` : ''}`} to="/" title="Crabi">
                <img className="logoWrapper__image" alt="Logo" src={Util.ASSET.LOGO}></img>
              </Link>
              :
              <span className={`logoWrapper${props.className? ` ${props.className}` : ''}`}>
                <img className="logoWrapper__image" alt="Logo" src={Util.ASSET.LOGO}></img>
              </span>
          }

        </>
      }
      {
        type === 'full' &&
        <>
          {
            props.navigateRoot ?
              <Link className={`logoWrapper${props.className? ` ${props.className}` : ''}`} to="/" title="Crabi">
                <img className="logoWrapper__image logoWrapper__image--full" alt="Logo" src={Util.ASSET.LOGO_FULL}></img>
              </Link>
              :
              <span className={`logoWrapper${props.className? ` ${props.className}` : ''}`}>
                <img className="logoWrapper__image logoWrapper__image--full" alt="Logo" src={Util.ASSET.LOGO_FULL}></img>
              </span>
          }

        </>
      }

    </>

  );
};

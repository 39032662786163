import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { IRewardsHistoryRequest } from './../interfaces/IRewardsHistoryRequest';

/**
 *
 */
export namespace ReferralsAction {

  export const rewardsList: ActionCreatorWithoutPayload = createAction('REWARDS_LIST');

  export const rewardsHistoryList: ActionCreatorWithPayload<IRewardsHistoryRequest> = createAction("REWARDS_HISTORY_LIST");
}
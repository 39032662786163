import { IPasswordRestoreRequest } from "../interfaces/IPasswordRestoreRequest";

export namespace RestorePasswordAdapter {

  /**
   * 
   * @param newPassword 
   * @param token 
   * @returns 
   */
  export const restorePasswordRequest = (newPassword: string, token: string): IPasswordRestoreRequest => {
    let data: IPasswordRestoreRequest = {
      token: token,
      password: newPassword,
    }

    return data;
  }
}
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import './Spinner.scss';

interface ISpinnerProps {

  /**
   * Loader color name. "main", "white", "black". Default null.
   */
  color?: 'main' | 'white' | 'black';
  /**
   * Position relative. Default absolute.
   */
  relative?: boolean;

  circles?: boolean;
}

/**
 * 
 */
export const Spinner = (props: PropsWithChildren<ISpinnerProps & React.SVGAttributes<SVGSVGElement>>): JSX.Element => {

  const spinnerClassNames = classNames({
    'absolute': !props.relative ? true : false,
    'relative': props.relative ? true : false,
  })

  return (
    <>
      {
        !props.circles ?
          <svg
            spinner-color={props.color ? props.color : null}
            className={`spinner-wrapper ${spinnerClassNames}`}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="8" r="28" strokeDasharray="131.94689145077132 45.982297150257104">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.5s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>
          :
          <div className={`spinner-dots-wrapper relative flex justify-center align-center column ${spinnerClassNames}`}>
            <div className="circles relative">
              <div className="width-100 flex-grow-100">
                <div className="circle absolute"></div>
                <div className="circle absolute"></div>
                <div className="circle absolute"></div>
              </div>
            </div>
            <p className="block text-center">
              {props.children ? props.children : 'Cargando'}<span>.</span><span>.</span><span>.</span>
            </p>
          </div>
      }
    </>
  );
};
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeCardElementChangeEvent, StripeElements } from '@stripe/stripe-js';
import { useState } from 'react';
import { Label } from "src/components";

import './InputCard.scss';

/**
 *
 */
interface IInputCard {

  onComplete?: (isCompleted: boolean, stripe?: Stripe, elements?: StripeElements, cardType?: string) => void;

}

/**
 *
 */
export const InputCard = (props: IInputCard): JSX.Element => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  // Stripe module
  const stripe = useStripe();
  const elements = useElements();
  /**
   * 
   * @param event 
   */
  const handleOnChange = (event: StripeCardElementChangeEvent) => {
    if (event.complete && props.onComplete) {
      props.onComplete(true, stripe!, elements!, event.brand.toLocaleUpperCase());
    } else if (!event.complete && props.onComplete) {
      props.onComplete(false);
    }
  }

  return (
    <>
      <div className="input-card-wrapper relative" is-focused={isFocused ? '' : null}>
        <Label className="absolute">N&uacute;mero de tarjeta</Label>
        <CardElement onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} onChange={handleOnChange} />
      </div>
    </>
  );
};
import { Outlet } from "react-router-dom";
import './Referrals.scss';

/**
 *
 */
interface IReferrals {

}

/**
 *
 */
const ReferralsPage = (props: IReferrals): JSX.Element => {
  return (
    <div id="referrals-wrapper" className="height-100 border-box">
      <Outlet />
    </div>
  );
};

export default ReferralsPage;
import { Util } from "src/utils/Util";
import { IVehiclePictureRequest } from "../interfaces/IVehiclePictureRequest";

export namespace InsuranceVehicleAdapter {

  /**
   * 
   * @param token 
   * @param blob 
   */
  export const isVehiclePictureAdapter = (token: string, picture: File): IVehiclePictureRequest => {
    const formData = new FormData();
    formData.append('photo', picture);
    
    const request: IVehiclePictureRequest = {
      token: token,
      formData: formData,
    }
    
    return request;
  }

  /**
   * 
   * @param token 
   * @param pictures 
   * @returns 
   */
  export const uploadVehiclePicturesAdapter = (token: string, policyId: string, pictures: File[]): IVehiclePictureRequest => {
    const formData = new FormData();
    formData.append('vehicle-up.png', pictures[Util.STATUS_CODE.VEHICLE_PICTURE_TYPE_CODES.FRONT]);
    formData.append('vehicle-left.png', pictures[Util.STATUS_CODE.VEHICLE_PICTURE_TYPE_CODES.LEFT_SIDE]);
    formData.append('vehicle-right.png', pictures[Util.STATUS_CODE.VEHICLE_PICTURE_TYPE_CODES.RIGHT_SIDE]);
    formData.append('vehicle-down.png', pictures[Util.STATUS_CODE.VEHICLE_PICTURE_TYPE_CODES.BACK]);
    
    const request: IVehiclePictureRequest = {
      token: token,
      formData: formData,
      policyId: policyId
    }
    
    return request;
  }
}

const BASE_URL: string = '/profile';

/**
 * 
 */
 export const PROFILE_PATH = {

  PROFILE: BASE_URL,
  PROFILE_INFO: `${BASE_URL}/profile-info`,
  SECURITY: `${BASE_URL}/security`,
}
export const asset = {

  //#region backgrounds
  LOGIN_BG: 'https://crabi-assets.s3.us-east-2.amazonaws.com/login-bg.png',
  PLAN_BG: 'https://crabi-assets.s3.us-east-2.amazonaws.com/plan-bg.svg',
  //#endregion

  //#region logo
  LOGO: 'https://crabi-assets.s3.us-east-2.amazonaws.com/logo.svg',
  LOGO_FULL:'https://crabi-assets.s3.us-east-2.amazonaws.com/logo-sl.png',
  //#endregion

  //#region policy
  EMPTY_POLICY_STATUS_LIST: 'https://crabi-assets.s3.us-east-2.amazonaws.com/empty-state/empty-policy-status.png',
  POLICY_PAYMENT_CARD: 'https://crabi-assets.s3.us-east-2.amazonaws.com/payment/paymentcard.svg',
  PAYMENT_CARD_VISA_WHITE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/payment/visa-logo-white.svg',
  PAYMENT_CARD_MASTERCARD: 'https://crabi-assets.s3.us-east-2.amazonaws.com/payment/mastercard-logo.svg',
  PAYMENT_CARD_AMEX: 'https://crabi-assets.s3.us-east-2.amazonaws.com/payment/amex-logo.svg',
	PAYMENT_CALENDAR: 'https://crabi-assets.s3.us-east-2.amazonaws.com/payment/payment-calendar.svg',
	PAYMENT_CARD: 'https://crabi-assets.s3.us-east-2.amazonaws.com/payment/payment-card.svg',
  POLICY_INVOICE_FINISHED: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/invoice-finished.png',
  //#endregion

  //#region login
  PASSWORD_RECOVERY: '/assets/security/password-recovery.png',
  VALIDATE_EMAIL_CONFIRMATION: 'https://crabi-assets.s3.us-east-2.amazonaws.com/email-assets/validate-email-confirmation.png',
  VALIDATE_EMAIL: 'https://crabi-assets.s3.us-east-2.amazonaws.com/email-assets/validate_email.png',
  //#endregion

  //#region cars
  CAR_FRONT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/cars/front-side.png',
  CAR_BACK: 'https://crabi-assets.s3.us-east-2.amazonaws.com/cars/back-side.png',
  CAR_LEFT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/cars/left-side.png',
  CAR_RIGHT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/cars/right-side.png',
  CAR_SERIAL: 'https://crabi-assets.s3.us-east-2.amazonaws.com/cars/serial.png',
  CAR_CRASH: 'https://crabi-assets.s3.us-east-2.amazonaws.com/cars/car-crash.png',
  POLICY_COMPLETION_CAR: '/assets/cars/policy-contracting-car.png',
  EMPTY_CAR_PICTURE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/policy-picture.png',
  POLICY_SUMMARY_DATA: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/summary-data.png',
  POLICY_SUMMARY_CONTACT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/summary-contact.png',
  POLICY_VEHICLE_DATA: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/vehicle-default-picture.png',
  //#endregion

  //#region policy-management
  POLICY_MANAGEMENT_ENDORSEMENT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/endorsement.png',
  POLICY_MANAGEMENT_BILL: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/bill.png',
  POLICY_MANAGEMENT_PAYMENT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/payment.png',
  POLICY_MANAGEMENT_CANCELLATION: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/cancellation.png',
  //#endregion
  
	//#region documents
	DRIVING_CARD: 'https://crabi-assets.s3.us-east-2.amazonaws.com/documents/driving-card.png',
	INE_FRONT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/documents/ine-card-front.png',
	INE_BACK: 'https://crabi-assets.s3.us-east-2.amazonaws.com/documents/ine-card-back.png',
	IDENTITY_CARD_SUCCESS: 'https://crabi-assets.s3.us-east-2.amazonaws.com/documents/identity-validation-success.png',
	IDENTITY_CARD_ERROR: 'https://crabi-assets.s3.us-east-2.amazonaws.com/documents/identity-validation-error.png',
	BANK_TUTORIAL: 'https://crabi-assets.s3.us-east-2.amazonaws.com/documents/bank-tutorial.png',
	//#endregion

	//#region account status
	ACCOUNT_STATUS: 'https://crabi-assets.s3.us-east-2.amazonaws.com/documents/bank-statement.png',
	//#endregion

  //#region referrals
  REFERRALS: 'https://crabi-assets.s3.us-east-2.amazonaws.com/referrals/referrals.svg',
  BADGE_POINTS: 'https://crabi-assets.s3.us-east-2.amazonaws.com/referrals/badge.svg',
  EMPTY_REWARD_LIST: 'https://crabi-assets.s3.us-east-2.amazonaws.com/empty-state/empty-rewards-list.png',
  EMPTY_REWARDS_HISTORY: '/assets/referrals/reward-history-empty.png',
  //#endregion

  //#region policy-pictures
  POLICY_PICTURE_LIGHTS: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/picture-step-lights.png',
  POLICY_PICTURE_CAR: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/picture-step-car.png',
  POLICY_PICTURE_ITEMS: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/picture-step-items.png',
  POLICY_PICTURE_PLATES: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/picture-step-plates.png',
  POLICY_PICTURE_OUT_OF_DATE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/picture-out-of-date.png',
  POLICY_PICTURE_BLURRY: 'https://crabi-assets.s3.us-east-2.amazonaws.com/policy/picture-blurry.png',
  //#endregion

  //#region utils
  WARNING_IMAGE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/utils/warning.png',
	INFO_IMAGE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/utils/info.png',
  //#endregion

  //#region coverages
  PLAN_SHORTCUT_ACTION: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/plan-shortcut-action.png',
  PLAN_QUOTER_CAR: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/quoter-car.png',
  COVERAGE_DEFAULT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-default-star.png',
  COVERAGE_AV: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-truck.png',
  COVERAGE_GM: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-ambulance.png',
  COVERAGE_DT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-damage.png',
  COVERAGE_AL: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-law.png',
  COVERAGE_RT: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-robber.png',
  COVERAGE_DM: 'https://crabi-assets.s3.us-east-2.amazonaws.com/coverages/coverage-accident.png',
  //#endregion

	//#region otp
	PHONE_SMS: 'https://crabi-assets.s3.us-east-2.amazonaws.com/otp/phone-sms.png',
	//#endregion

  //#region claims
  CLAIMS_PANEL_CAR: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/claim_car.png',
  CLAIMS_PANEL_LICENSE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/claim_license.png',
  CLAIMS_PANEL_PEOPLE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/claim_people.png',
  CLAIMS_PANEL_CLOSURE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/claim_closure.png',
  CLAIMS_PANEL_VEHICLE_ID: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/vehicle-id.png',
  CLAIMS_PANEL_VEHICLE_SERVICE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/vehicle-service.png',
  CLAIMS_SERVICE_IMAGE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/service-image.svg',
  CLAIMS_PANEL_VEHICLE_DAMAGE: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/vehicle-dmg.png',
	CLAIMS_INFO: 'https://crabi-assets.s3.us-east-2.amazonaws.com/claims/claim-info.svg',
  //#endregion
}
import { PolicyDocumentService } from "../services/policy-document.service";

export namespace PolicyDocumentFeature {

  /**
   * 
   */
  export const policyTermsFeature = async (policyNumber: string): Promise<Blob | undefined> => {
    try {
      const { data } = await PolicyDocumentService.policyTermsService(policyNumber);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   */
  export const policyTermsSumaryFeature = async (policyNumber: string): Promise<Blob | undefined> => {
    try {
      const { data } = await PolicyDocumentService.policyTermsSumaryService(policyNumber);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   */
  export const policyCoverFeature = async (policyId: string): Promise<Blob | undefined> => {
    try {
      const { data } = await PolicyDocumentService.policyCoverService(policyId);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   */
  export const policyReceiptFeature = async (policyId: string): Promise<Blob | undefined> => {
    try {
      const { data } = await PolicyDocumentService.policyReceiptService(policyId);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
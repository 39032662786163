import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm, UseFormReturn } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Bill } from "src/classes/invoice/Bill";
import { Invoice } from "src/classes/invoice/Invoice";
import { InvoiceStatus } from "src/classes/invoice/InvoiceStatus";
import { TaxRegime } from "src/classes/invoice/TaxRegime";
import { AlertFactory } from "src/factory/alert.factory";
import { LoadingFactory } from "src/factory/loading.factory";
import { Util } from "src/utils/Util";
import { BillFeature } from "../features/bill.feature";
import { IBillPersonForm } from "../interfaces/IBillPersonForm";
import { RegimeUse } from "src/classes/invoice/RegimeUse";
import { InvoiceFile } from "src/classes/invoice/InvoiceFile";

/**
 *
 */
export namespace BillFacade {

  export const BILL_ERRORS: TFormErrorMessage[] = [
    {
      key: 'invalidName',
      message: 'El nombre ingresado no se encuentra inscrito en el SAT.',
    },
    {
      key: 'invalidRFC',
      message: 'El RFC ingresado no se encuentra inscrito en el SAT.',
    },
    {
      key: 'invalidRegime',
      message: 'El régimen fiscal seleccionado no está asociado al RFC.',
    },
    {
      key: 'invalidRegimeCdfi',
      message: 'El régimen fiscal seleccionado no puede tener el Uso de CDFI G03: GASTOS EN GENERAL.',
    },
    {
      key: 'invalidPostalcode',
      message: 'El código postal no está asociado al RFC.',
    },
  ];

  export const TAX_TYPE = {
    NATURAL: 0,
    MORAL: 1,
  }

  /**
   * 
   */
  export const useInvoice = () => {
    const [policyNumber, setPolicyNumber] = useState<string>();
    const [productId, setProductId] = useState<string>();
    const [currentToken, setCurrentToken] = useState<string>('');
    const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatus>();
    const [currentInvoice, setCurrentInvoice] = useState<Invoice>();
    const [currentBillInfo, setCurrentBillInfo] = useState<Bill>();
    const [taxRegimes, setTaxRegimes] = useState<TaxRegime[]>([]);
    const [billList, setBillList] = useState<Bill[]>();
    const [selectedDowndloadFile, setSelectedDownloadFile] = useState<InvoiceFile>();

    const [overlayVisible, setOverlayVisible] = useState<boolean>();
    const [formVisible, setFormVisible] = useState<boolean>();
    const [downloadSheetVisible, setDownloadSheetVisible] = useState<boolean>();

    const location = useLocation();
    const creating = LoadingFactory.useLoader();
    const searchParams = useMemo(() => new URLSearchParams(decodeURIComponent(location.search)), [location.search]);

    /**
     * 
     */
    const validateInvoice = useCallback(
      async () => {
        try {
          const encodedToken = searchParams.get(Util.KEY.QUERY_PARAMS.TOKEN);
          const id = searchParams.get(Util.KEY.QUERY_PARAMS.PRODUCT_ID);
          const number = searchParams.get(Util.KEY.QUERY_PARAMS.POLICY_NUMBER);
          let token: string = '';

          if (encodedToken) {
            token = atob(encodedToken);
          }

          if (id && number && token !== '') {
            const status = await BillFeature.validateInvoiceFeature(id, token);
            if (status) {
              const invoice = await BillFeature.invoiceDetailsFeature(id, token);
              setBillList([]); // workaround
              // const list = await BillFeature.invoicePersonInfoFeature(token);
              // if (list) {
              //   setBillList(list);
              //   if (list.length > 0) {
              //     setCurrentBillInfo(list[0]);
              //   }
              // }
              setPolicyNumber(number);
              setProductId(id);
              setCurrentToken(token);

              if (invoice) {
                setCurrentInvoice(invoice);
              }

              setTimeout(() => {
                setInvoiceStatus(status);
              }, 1000);
            }
          } else {
            // Redireccionar
          }
        } catch (e: unknown | DOMException) {
          if (e instanceof DOMException) {
            // Redireccionar
          } else {
            AlertFactory.errorAlert((e as Error).message);
          }
        }
      }, [searchParams]
    );

    /**
     * 
     * @param bill 
     */
    const onSelectBillInfo = (bill: Bill): void => {
      if ((currentBillInfo && currentBillInfo.id !== bill.id) || !currentBillInfo) {
        setCurrentBillInfo(bill);
      } else {
        setCurrentBillInfo(undefined);
      }
    }

    /**
     * 
     */
    const getTaxRegimes = useCallback(
      async () => {
        try {
          const regimes = await BillFeature.taxRegimensListFeature();
          if (regimes) {
            setTaxRegimes(regimes);
          }
        } catch (e) {
          AlertFactory.errorAlert((e as Error).message);
        }
      }, []
    );

    /**
     * 
     */
    const createInvoiceByBillInfo = async (): Promise<void> => {
      try {
        if (invoiceStatus && currentBillInfo) {
          creating.set(true);
          if (productId && currentToken) {
            await BillFeature.createInvoiceFeature(currentBillInfo, productId, currentToken);
            setTimeout(() => {
              invoiceStatus.status = Util.STATUS_CODE.POLICY_INVOICE_STATUS.FINISHED;
              creating.set(false);
            }, 2000);
          }
        }

      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
        creating.set(false);
      }
    }


    /**
     * 
     * @param values 
     */
    const createInvoiceByForm = async (form: UseFormReturn<IBillPersonForm, object>, regime: TaxRegime, use: RegimeUse): Promise<void> => {
      try {
        if (invoiceStatus) {
          const bill: Bill = new Bill();
          bill.rfc = form.getValues('rfc');
          bill.personName = form.getValues('name');
          bill.personEmail = form.getValues('email');
          bill.postalCode = form.getValues('postalCode');
          bill.taxRegime = regime;
          bill.regimeUse = use;
          if (billList && billList.length === 0) {
            bill.saveData = true;
          } else {
            bill.saveData = form.getValues('saveData') ? form.getValues('saveData') : false;
          }

          creating.set(true);
          if (productId && currentToken) {
            await BillFeature.createInvoiceFeature(bill, productId, currentToken);
            const status = await BillFeature.validateInvoiceFeature(productId, currentToken);
            setTimeout(() => {
              setInvoiceStatus(status);
              creating.set(false);
              setOverlayVisible(false);
              setFormVisible(false);
            }, 2000);
          }
        }

      } catch (e) {
        const error = e as Error;
        if (error.name !== '') {
          const errorCodeName = parseInt(error.name);
          if (errorCodeName === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.NAME) {
            form.setError('name', { type: 'invalidName' });
          } else if (errorCodeName === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.RFC) {
            form.setError('rfc', { type: 'invalidRFC' });
          } else if (errorCodeName === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.REGIME) {
            form.setError('regime', { type: 'invalidRegime' });
          } else if (errorCodeName === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.REGIME_CDFI) {
            form.setError('regime', { type: 'invalidRegimeCdfi' });
          } else if (errorCodeName === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.POSTAL_CODE) {
            form.setError('postalCode', { type: 'invalidPostalcode' });
          }
        } else {
          AlertFactory.errorAlert((e as Error).message);
        }
        creating.set(false);
      }
    }

    /**
     * 
     */
    useEffect(
      () => {
        validateInvoice();
      }, [validateInvoice]
    );


    /**
     * 
     */
    useEffect(
      () => {
        getTaxRegimes()
      }, [getTaxRegimes]
    );

    return {
      invoiceStatus, policyNumber, currentInvoice, billList, creating, taxRegimes, currentBillInfo,
      createInvoiceByForm, onSelectBillInfo, createInvoiceByBillInfo,
      visibilityStates: { setFormVisible, formVisible, setDownloadSheetVisible, downloadSheetVisible, setOverlayVisible, overlayVisible },
      downloadStates: { selectedDowndloadFile, setSelectedDownloadFile }
    }
  }


  export const useBillForm = (taxRegimes: TaxRegime[]) => {
    const [taxType, setTaxType] = useState<number>(TAX_TYPE.NATURAL);
    const [currentRegime, setCurrentRegime] = useState<TaxRegime>();
    const [currentUse, setCurrentUse] = useState<RegimeUse>();
    const form = useForm<IBillPersonForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    /**
     * 
     * @param type 
     */
    const onSelectType = (type: number): void => {
      setTaxType(type);
      setCurrentRegime(undefined);
      setCurrentUse(undefined);
    }

    /**
     * 
     * @param id 
     */
    const onSelectRegime = (id: number): void => {
      setCurrentRegime(taxRegimes.find(r => r.id === id));
      setCurrentUse(undefined);
      form.clearErrors('regime');
    }

    /**
     * 
     * @param id 
     */
    const onSelectUse = (id: string): void => {
      if (currentRegime) {
        setCurrentUse(currentRegime.uses.find(u => u.id === id));
      }
    }

    return { taxType, onSelectType, form, onSelectRegime, currentRegime, currentUse, onSelectUse }
  }
}
import { PropsWithChildren } from "react";
import useCollapse from "react-collapsed";
import { Plan } from "src/classes/plan/Plan";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { IconFactory } from "src/factory/icon.factory";
import SummaryItem from "../../atoms/SummaryItem/SummaryItem";
import './SummarySection.scss';

/**
 *
 */
interface ISummarySection {

  plan?: Plan;

  user?: Person;

  vehicle?: Vehicle;

}

/**
 *
 */
const SummarySection = (props: PropsWithChildren<ISummarySection>): JSX.Element => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ duration: 350, defaultExpanded: true, });

  return (
    <section className="summary-section-wrapper">
      <div className="summary-toggle flex align-center width-100" {...getToggleProps()} is-expanded={isExpanded ? '' : null}>
        <h3>
          {props.children}
        </h3>
        <span className="flex-grow-100"></span>
        {IconFactory.dropDownArrowIcon()}
      </div>

      <div className="summary-plan-data" {...getCollapseProps()}>
        {
          props.plan &&
          <>
            {
              props.plan.coveragesList.map(
                (coverage) => {
                  if (coverage.isEnabled) {
                    return (
                      <SummaryItem key={coverage.id} coverage={coverage} vehicle={props.vehicle}/>
                    )
                  }
                  return <></>
                }
              )
            }
          </>
        }

        {
          props.user && props.vehicle &&
          <>
            <SummaryItem user={props.user} />
            <SummaryItem user={props.user} isContact />
            <SummaryItem vehicle={props.vehicle} />
          </>
        }
      </div>

    </section>

  );
};

export default SummarySection;
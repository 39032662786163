import { IconFactory } from 'src/factory/icon.factory';
import './Support.scss';

/**
 *
 */
interface ISupport {

}

/**
 *
 */
export const Support = (props: ISupport): JSX.Element => {
  return (
    <div id="support-wrapper" className="text-center">
      <h2 className="text-center">N&uacute;mero de Emergencias</h2>

      <div>
        {IconFactory.phoneIcon()}
        <a className="border-box" href="tel:8009990160">
          800 999 0160
        </a>
      </div>
    </div>
  );
};
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Coverage } from "src/classes/plan/Coverage";
import { Plan } from "src/classes/plan/Plan";
import { Util } from "src/utils/Util";
import { PlanManagementAction } from "../actions/plan-management.action";
import { PlanManagementSelector } from "../selectors/plan-management.selector";

export namespace PlanWrapperFacade {

  export const usePlanWrapper = (plans: Plan[]) => {
    const currentPlan = useSelector(PlanManagementSelector.currentPlan);

    const [damageCoverages, setDamageCoverages] = useState<Coverage[]>([]);
    const [extraCoverages, setExtraCoverages] = useState<Coverage[]>([]);

    const dispatch = useDispatch();

    useEffect(
      () => {
        dispatch(PlanManagementAction.currentPlan(plans[0]));   
      }, [plans, dispatch]
    )

    useEffect(
      () => {
        if (currentPlan) {
          setDamageCoverages(
            () => {
              let list: Coverage[] = [];
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.type)!);
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.type)!);
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.type)!);

              return list;
            }
          );
          setExtraCoverages(
            () => {
              let list: Coverage[] = [];
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.type)!);
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.type)!);
              list.push(currentPlan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.type)!);
              return list;
            }
          )
        }
      }, [currentPlan]
    )

    return { coverages: {damageCoverages, extraCoverages}, currentPlan}
  }
}
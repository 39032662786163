import { PolicyCreationFacade } from "src/modules/policy-creation/controller/facades/policy-creation.facade";
import { PolicyPlanPaymentFacade } from "src/modules/policy-creation/controller/facades/policy-plan-payment.facade";
import { ReturnValidactionFacade } from "src/modules/policy-creation/controller/facades/return-validation.facade";
import { POLICY_CREATION_PATH } from "src/routes/paths/policy-creation.paths";
import './PlanPayment.scss';
import PlanPaymentManagement from "src/libs/PlanPaymentManagement/ui/template/PlanPaymentManagement";

/**
 *
 */
interface IPlanPayment {

}

/**
 *
 */
const PlanPayment = (props: IPlanPayment): JSX.Element => {
  const { plan, policy } = PolicyCreationFacade.usePolicyCreationActors();
  const { onCheckoutDone } = PolicyPlanPaymentFacade.usePlanPayment();
  const { onReturn, steps } = ReturnValidactionFacade.useReturnValidation();

  return (
		<div id="plan-payment-wrapper" className="width-100">
      {
        plan &&
					<PlanPaymentManagement
						plan={plan}
						policy={policy}
						onPaymentDone={onCheckoutDone}
            picturesPrompt={true}
						onReturn={() => onReturn(steps.payment, POLICY_CREATION_PATH.PLAN_REVIEW)}
					/>
      }
		</div>
  );
};

export default PlanPayment;
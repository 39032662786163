import { SIGN_OUT_PATH } from "src/routes/paths/sign-out.paths";
import SignOutPage from '../ui/pages/SignOut';

/**
 *
 */
export const SignOutRoutes = () => {
  return {
    path: SIGN_OUT_PATH.SIGN_OUT,
    element: <SignOutPage />,
  }
}
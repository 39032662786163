import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Util } from "src/utils/Util";
import { IVehicleStorage } from "src/core/interfaces/vehicle/IVehicleStorage";
import { CoreVehicleStorageAdapter } from "src/core/adapters/vehicle-storage.adapter";

/**
 *
 */
export namespace CoreVehicleStoreFeature {

  /**
   * 
   * @param newVehicle 
   */
  export const storeVehicleFeature = async (newVehicle: Vehicle): Promise<void | undefined> => {
    localStorage.setItem(Util.KEY.POLICY_NEW_VEHICLE, JSON.stringify(CoreVehicleStorageAdapter.vehicleStorageAdapter(newVehicle)));
  } 

  /**
   * 
   */
  export const getStoredVehicleFeature = (): Vehicle | undefined => {
    const storedData: string = localStorage.getItem(Util.KEY.POLICY_NEW_VEHICLE) as string;
    if (storedData) {
      const vehicle: Vehicle = CoreVehicleStorageAdapter.vehicleStorageAdapter(JSON.parse(storedData) as IVehicleStorage) as Vehicle;
      return vehicle;
    } else {
      return undefined;
    }
  }
}
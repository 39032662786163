import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { CoreVehicleStoreFeature } from "src/core/features/vehicle-storage.feature";
import { Util } from "src/utils/Util";

/**
 *
 */
export namespace QuoterFacade {

	export const useQuoterFacade = () => {
		/** */
		const { search } = useLocation();
		const searchParams = useMemo(() => new URLSearchParams(search), [search]);
		const brandCode = searchParams.get('brandCode');
		const brandName = searchParams.get('brandName');
		const modelCode = searchParams.get('modelCode');
		const modelName = searchParams.get('modelName');
		const year = searchParams.get('year');

		useEffect(() => {
			if(brandCode && brandName && modelCode && modelName && year){
				let vehicle: Vehicle = new Vehicle();
				vehicle.brand = new VehicleBrand(+brandCode, Util.TRANSFORM.TEXT.firstLetterUpperCase(brandName));
				vehicle.model = new VehicleModel(+modelCode, undefined, Util.TRANSFORM.TEXT.firstLetterUpperCase(modelName));
				vehicle.year = new VehicleYear(undefined, undefined, +year);

				CoreVehicleStoreFeature.storeVehicleFeature(vehicle);
			}
		}, [brandCode, brandName, modelCode, modelName, year]);
	}
}

//Jeep, 18, Cherokee, 378, 2017
import { useState } from "react"
import { FileAttachment } from "src/classes/FileAttachment";
import { PictureManagementFeature } from "../features/picture-management.feature";

/**
 *
 */
export namespace PictureManagementFacade {

  const VALID_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

  export const useFileUpload = (
    attachment: FileAttachment, 
    onUpload?: (attachment: FileAttachment) => void, 
    onLoading?: (isLoading: boolean) => void,
    allowPdf?: boolean) => {
    const [selectedPicture, setSelectedPicture] = useState<string>('');
    const [loadingPicture, setLoadingPicture] = useState<boolean>(false);
		const [documentType, updateDocumentType] = useState<string>('');

    /**
     * 
     * @param path 
     */
    const onSelectFile = async (file: File | undefined): Promise<void> => {
      try {
        if (file) {
          if ((file.type === VALID_TYPES[3] && allowPdf) || (file.type !== VALID_TYPES[3] && allowPdf) || (file.type !== VALID_TYPES[3])) {
            setLoadingPicture(true);
            if (onLoading) {
              onLoading(true);
            }
            const path = URL.createObjectURL(file as Blob);
            const formData = new FormData();

						updateDocumentType(file.type);
            formData.append(attachment.name!, file);
            const updatedAttachment = await PictureManagementFeature.uploadPictureFeature(formData, attachment);
            setSelectedPicture(path);
            if (onUpload) {
							onUpload(updatedAttachment!);
            }
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingPicture(false);
        if (onLoading) {
          onLoading(false);
        }
      }
    }

    return { selectedPicture, documentType, onSelectFile, setSelectedPicture, loadingPicture }
  }


  export const useDropZone = (onDropComplete: (file: File) => void) => {
    const [dragOver, setDragOver] = useState<boolean>(false);

    /**
     * 
     * @param file 
     */
    const onDrop = (file: File | undefined): void => {
      if (file && dragOver) {

        if (VALID_TYPES.indexOf(file.type) !== -1) {
          const k = 1024;
          const i = Math.floor(Math.log(file.size) / Math.log(k));
          const sizeKb = parseFloat((file.size / Math.pow(k, i)).toFixed(2))
          if (sizeKb < 10000) {
            onDropComplete(file);
          }
        }
      }

      setDragOver(false);
    }

    /**
     * 
     * @param isOver 
     */
    const onDragOver = (isOver: boolean): void => {
      setDragOver(isOver);
    }

    return { dragOver, onDragOver, onDrop }
  }
}
import { Util } from "src/utils/Util";

/**
 * 
 */
export class CoverageOption {

  /**
   *
   */
  private _deductible: number;
  set deductible(_deductible: number) { this._deductible = _deductible };
  get deductible(): number { return this._deductible };
  /**
   *
   */
  private _assured: number;
  set assured(_assured: number) { this._assured = _assured };
  get assured(): number { return this._assured };

  constructor(_deductible?: number, _assured?: number) {
    this._deductible = _deductible? _deductible : -1;
    this._assured = _assured? _assured : -1;
  }

  /**
   * 
   */
  public getDeductibleValue(): string {
    return `${this._deductible * 100}%`;
  }

  /**
   * 
   * @returns 
   */
  public getAssuredValue(): string {
    return Util.TRANSFORM.CURRENCY.MXN(this._assured);
  }
}
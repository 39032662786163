import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Person  } from "src/classes/Person";
import { Util } from "src/utils/Util";
import { PersonManagementFeature } from "../features/person-management.feature";
import { IPersonManagementDataForm } from "../interfaces/IPersonManagementDataForm";

export namespace PersonManagementDataFormFacade {

  /**
   * 
   */
  const NAME_ERRORS_MESSAGE = {
    REQUIRED: 'El nombre es obligatorio.',
    INVALID: 'El nombre es inválido.'
  };
  const NAME_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: NAME_ERRORS_MESSAGE.REQUIRED
    },
    {
      key: 'pattern',
      message: NAME_ERRORS_MESSAGE.INVALID
    },
    {
      key: 'minLength',
      message: NAME_ERRORS_MESSAGE.INVALID
    }
  ];

  /**
   * 
   */
  const LASTNAME_ERRORS_MESSAGE = {
    REQUIRED: 'El apellido es obligatorio.',
    INVALID: 'El apellido es inválido.'
  };
  const LASTNAME_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: LASTNAME_ERRORS_MESSAGE.REQUIRED
    },
    {
      key: 'pattern',
      message: LASTNAME_ERRORS_MESSAGE.INVALID
    },
    {
      key: 'minLength',
      message: LASTNAME_ERRORS_MESSAGE.INVALID
    }
  ];

  /**
   * 
   */
  const EMAIL_ERRORS_MESSAGE = {
    REQUIRED: 'El correo electrónico es obligatorio.',
    PATTERN: 'El formato del correo electrónico es inválido.',
    INVALID: 'El correo que ingresaste ya está ligado a otra póliza. Por favor ingresa un correo diferente.'
  };
  const EMAIL_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: EMAIL_ERRORS_MESSAGE.REQUIRED
    },
    {
      key: 'pattern',
      message: EMAIL_ERRORS_MESSAGE.PATTERN
    },
    {
      key: 'invalidEmail',
      message: EMAIL_ERRORS_MESSAGE.INVALID
    },
  ];



  /**
   * 
   */
  const PHONE_ERRORS_MESSAGE = {
    INVALID: 'El número de teléfono debe ser de 10 digitos.'
  };
  const PHONE_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: PHONE_ERRORS_MESSAGE.INVALID
    },
    {
      key: 'pattern',
      message: PHONE_ERRORS_MESSAGE.INVALID
    },
    {
      key: 'minLength',
      message: PHONE_ERRORS_MESSAGE.INVALID
    },
  ];

  /**
   * 
   */
  const RFC_ERRORS_MESSAGE = {
    FORMAT: 'El formato del RFC que ingresaste es inválido. Revisa tu RFC y vuelve a ingresarlo.',
    INVALID: 'El RFC que ingresaste ya está registrado en otra póliza.'
  };
  const RFC_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: RFC_ERRORS_MESSAGE.FORMAT
    },
    {
      key: 'pattern',
      message: RFC_ERRORS_MESSAGE.FORMAT
    },
    {
      key: 'invalidRfc',
      message: RFC_ERRORS_MESSAGE.INVALID
    },
  ];

  /**
   * 
   * @returns 
   */
  export const usePersonDataForm = (storedPerson?: Person) => {
    const { register, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues } = useForm<IPersonManagementDataForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    /**
     * 
     */
    const validateDefaultData = useCallback((storedPerson: Person): void => {
      if (storedPerson) {
				if (storedPerson.email && !touchedFields.email) {
					setValue('email', storedPerson.email, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.name && !touchedFields.name) {
					setValue('name', storedPerson.name, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.lastName && !touchedFields.firstLastname) {
					setValue('firstLastname', storedPerson.lastName, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.secondLastName && !touchedFields.secondLastname) {
					setValue('secondLastname', storedPerson.secondLastName, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile?.rfc! && !touchedFields.rfc) {
					setValue('rfc', storedPerson.profile?.rfc!, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile?.phone?.number! && !touchedFields.phone) {
					setValue('phone', storedPerson.profile?.phone?.number!, { shouldValidate: true, shouldTouch: true });
				}
				if (storedPerson.profile?.birthdate! && !touchedFields.birthdate) {
					setValue('birthdate', Util.TRANSFORM.DATE.formatDate(storedPerson.profile?.birthdate!), { shouldValidate: true, shouldTouch: true });
				}
      }

			const allDataCondition = storedPerson.email && storedPerson.name && storedPerson.lastName && storedPerson.secondLastName && storedPerson.profile?.rfc! 
				&& storedPerson.profile?.phone?.number! && storedPerson.profile?.birthdate! && storedPerson.profile?.gender!

			if(allDataCondition)
				trigger();
    }, [setValue, touchedFields, trigger]);

    /**
     * 
     * @param newDate 
     */
    const selectDate = (newDate: Date): void => {
      setValue('birthdate', Util.TRANSFORM.DATE.formatDate(newDate));
      trigger('birthdate');
    }

    /**
     * 
     * @param code 
     */
    const selectCountryCode = (code: string): void => {
      setValue('countryCode', code);
    }

    useEffect(() => {
      if (storedPerson) {
        validateDefaultData(storedPerson);
      }

    }, [storedPerson, validateDefaultData])


    return {
      selectDate, selectCountryCode,
      FORM_DATA_ERRORS: { NAME_ERRORS, LASTNAME_ERRORS, EMAIL_ERRORS, RFC_ERRORS, PHONE_ERRORS },
      formData: { register, formState: { errors, isValid, touchedFields, dirtyFields }, trigger, watch, setValue, getValues }
    }
  }

  export const useRfcValidation = () => {
    const [isValidatingRFC, setIsValidatingRFC] = useState<boolean>(false)
    const [validatedRFC, setValidatedRFC] = useState('');
    const [previousValidation, setPreviousValidation] = useState<boolean | null>(null);

    /**
     * 
     * @param rfc 
     * @returns 
     */
    const validatePersonRfc = async (rfc: string): Promise<boolean | undefined> => {
      try {
        if (Util.PATTERN.RFC.test(rfc.toUpperCase())) {
          if (validatedRFC !== rfc) {
            setIsValidatingRFC(true);
            setValidatedRFC(rfc);
            const validation = await PersonManagementFeature.validateRfcFeature(rfc.toUpperCase());
            setPreviousValidation(validation)
            return validation;
          } else {
            return previousValidation as boolean;
          }

        }
      } catch (e) {

      } finally {
        setIsValidatingRFC(false);
      }
    }

    return { validatePersonRfc, isValidatingRFC }
  }

  /**
   * 
   * @returns 
   */
  export const useEmailValidation = () => {
    const [isValidatingEmail, setIsValidatingEmail] = useState<boolean>(false)
    const [validatedEmail, setValidatedEmail] = useState('');
    const [previousValidation, setPreviousValidation] = useState<boolean | null>(null);

    /**
     * 
     * @param email 
     * @returns 
     */
    const validatePersonEmail = async (email: string): Promise<boolean | undefined> => {
      try {
        if (Util.PATTERN.EMAIL.test(email)) {
          if (validatedEmail !== email) {
            setIsValidatingEmail(true);
            setValidatedEmail(email);
            const validation = await PersonManagementFeature.validateEmailFeature(email);
            setPreviousValidation(validation)
            return validation;

          } else {
            return previousValidation as boolean;
          }

        }
      } catch (e) {

      } finally {
        setIsValidatingEmail(false);
      }
    }

    return { validatePersonEmail, isValidatingEmail }
  }
}
import { IconFactory } from "src/factory/icon.factory";
import { ClaimPaymentsStepsFacade } from "src/modules/claim-payments/controller/facades/claim-payments-steps.facade";
import { ClaimPaymentsFacade } from "src/modules/claim-payments/controller/facades/claim-payments.facade";
import ClaimPaymentSidebarSection from "../../molecules/ClaimPaymentSidebarSection/ClaimPaymentSidebarSection";
import "./ClaimPaymentSidebar.scss";

const STATUS = {
  APPROVED: 1,
  PENDING: 2,
	DENIED: 3
}

/**
 *
 */
interface ISidebar {

}

/**
 *
 */
const ClaimPaymentSidebar = (props: ISidebar): JSX.Element => {
	/** */
  const { dataStep, picturesStep, documentsStep, accountStep, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
  const { currentStep } = ClaimPaymentsStepsFacade.useMatchSteps();

  return (
    <div className="claim-payment-sidebar height-100 border-box text-center align-center">
      <div className="claim-sidebar height-100 flex">
        <h1 className="text-center">Pago {`${isAgreedPayment ? 'pactado' : 'de daños'}`}</h1>

        <div className="claim-sidebar-options">
          <ClaimPaymentSidebarSection
            step={0}
            icon={IconFactory.profileIcon({
              fill: "#9c9c9c",
              stroke: "#9c9c9c",
            })}
            done={dataStep === STATUS.APPROVED}
            currentStep={currentStep}
          >
            Datos personales
          </ClaimPaymentSidebarSection>

					{
						!isAgreedPayment &&
							<ClaimPaymentSidebarSection
								step={1}
								icon={IconFactory.carIcon({ fill: "#9c9c9c" })}
								done={picturesStep === STATUS.APPROVED}
								missingInfo={picturesStep === STATUS.PENDING}
								hasErrors={picturesStep === STATUS.DENIED}
								currentStep={currentStep}
							>
								Fotos del auto
							</ClaimPaymentSidebarSection>
					}

          <ClaimPaymentSidebarSection
            step={2}
            icon={IconFactory.userCardIcon({ fill: "#9c9c9c" })}
            done={documentsStep === STATUS.APPROVED}
            missingInfo={documentsStep === STATUS.PENDING}
						hasErrors={documentsStep === STATUS.DENIED}
            currentStep={currentStep}
          >
            Documentos de identificación
          </ClaimPaymentSidebarSection>

          <ClaimPaymentSidebarSection
            step={3}
            icon={IconFactory.billIcon({ fill: "#9c9c9c" })}
            done={accountStep === STATUS.APPROVED}
            missingInfo={accountStep === STATUS.PENDING}
						hasErrors={accountStep === STATUS.DENIED}
            currentStep={currentStep}
          >
            Estados de cuenta y factura
          </ClaimPaymentSidebarSection>
        </div>

        <div className="footer-buttons text-center">
          {/* {
            !hideProposal && !currentDamageClaim?.receiptSource && currentDamageClaim?.uid &&
            <ButtonLink
              className={`button ${proposalAvailable ? "" : "disabled-link"}`}
              to={
                CLAIM_PAYMENTS_PATH.PROPOSAL + `?claim=${currentDamageClaim?.uid}`
              }
            >
              Ver monto
            </ButtonLink>
          } */}
          {/* {
            currentDamageClaim?.receiptSource &&
            <a
              className="claim-receipt-link"
              href={currentDamageClaim.receiptSource}
              target="_blank"
            >
              Ver recibo de pago
            </a>
          } */}
        </div>
      </div>

      <div className="claim-topbar">
        <section className={`flex justify-center ${isAgreedPayment && 'agreed-mode'}`}>
          <div className="claims-step-wrapper flex align-center relative step-1"
            claims-step-done={dataStep === STATUS.APPROVED ? '' : null}
						claims-step-missing={dataStep === STATUS.PENDING ? '' : null}
						claims-step-warning={dataStep === STATUS.DENIED ? '' : null}
            claims-step-active={currentStep === 0 ? '' : null}
						>
            <div className="claims-step-icon">
              {IconFactory.profileIcon()}
            </div>
            <div className="claims-complete-icon">
              {IconFactory.checkIcon()}
            </div>
						<div className="claims-missing-icon">
              {IconFactory.minusWarningIcon()}
            </div>
						<div className="claims-denied-icon">
              {IconFactory.warningIcon()}
            </div>
            <p>
              Datos personales
            </p>
          </div>

					{
						!isAgreedPayment &&
							<div className="claims-step-wrapper flex align-center relative step-2"
								claims-step-done={picturesStep === STATUS.APPROVED ? '' : null}
								claims-step-missing={picturesStep === STATUS.PENDING ? '' : null}
								claims-step-warning={picturesStep === STATUS.DENIED ? '' : null}
								claims-step-active={currentStep === 1 ? '' : null}>
								<div className="claims-step-icon">
									{IconFactory.carIcon()}
								</div>
								<div className="claims-complete-icon">
									{IconFactory.checkIcon()}
								</div>
								<div className="claims-missing-icon">
									{IconFactory.minusWarningIcon()}
								</div>
								<div className="claims-denied-icon">
									{IconFactory.warningIcon()}
								</div>
								<p>
									Fotos del auto
								</p>
							</div>
					}

          <div className="claims-step-wrapper flex align-center relative step-3" 
						claims-step-done={documentsStep === STATUS.APPROVED ? '' : null}
						claims-step-missing={documentsStep === STATUS.PENDING ? '' : null}
						claims-step-warning={documentsStep === STATUS.DENIED ? '' : null}
						claims-step-active={currentStep === 2 ? '' : null}>
            <div className="claims-step-icon">
              {IconFactory.userCardIcon()}
            </div>
						<div className="claims-complete-icon">
              {IconFactory.checkIcon()}
            </div>
						<div className="claims-missing-icon">
              {IconFactory.minusWarningIcon()}
            </div>
						<div className="claims-denied-icon">
              {IconFactory.warningIcon()}
            </div>
            <p>
              Documentos de identificación
            </p>
          </div>
          <div className="claims-step-wrapper flex align-center relative step-4" 
						claims-step-done={accountStep === STATUS.APPROVED ? '' : null}
						claims-step-missing={accountStep === STATUS.PENDING ? '' : null}
						claims-step-warning={accountStep === STATUS.DENIED ? '' : null}
						claims-step-active={currentStep === 3 ? '' : null}>
            <div className="claims-step-icon">
              {IconFactory.billIcon()}
            </div>
						<div className="claims-complete-icon">
              {IconFactory.checkIcon()}
            </div>
						<div className="claims-missing-icon">
              {IconFactory.minusWarningIcon()}
            </div>
						<div className="claims-denied-icon">
              {IconFactory.warningIcon()}
            </div>
            <p>
              Estados de cuenta y factura
            </p>
          </div>
        </section>
        <section>

          <div className="claim-topbar-footer flex column">
            {/* {
              !hideProposal && !currentDamageClaim?.receiptSource && currentDamageClaim?.uid &&
              <ButtonLink
                className={`button ${proposalAvailable ? "" : "disabled-link"}`}
                to={
                  CLAIM_PAYMENTS_PATH.PROPOSAL + `?claim=${currentDamageClaim?.uid}`
                }
              >
              	Ver monto
              </ButtonLink>
            } */}
            {/* {
              currentDamageClaim?.receiptSource &&
              <a
                className="claim-receipt-link"
                href={currentDamageClaim.receiptSource}
                target="_blank"
              >
                Ver recibo de pago
              </a>
            } */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ClaimPaymentSidebar;

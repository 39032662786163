import { Navigate } from "react-router";
import { POLICY_PATH, POLICY_PARAMS } from "../../../routes/paths/policy.paths";
import PolicyPage from "../ui/pages/Policy";
import PolicyStatusPage from "../ui/pages/PolicyStatus/PolicyStatus";
import PolicyDetails from "../ui/pages/PolicyDetails/PolicyDetails";
import PolicyPicturesPage from "../ui/pages/PolicyPictures/PolicyPictures";
import PolicyManagementPage from "../ui/pages/PolicyManagement/PolicyManagement";
import PolicyPaymentMethodPage from "../ui/pages/PolicyPaymentMethod/PolicyPaymentMethod";
import PolicyCancellationPage from "../ui/pages/PolicyCancellation/PolicyCancellation";
import PolicyPaymentHistoryPage from "../ui/pages/PolicyPaymentHistory/PolicyPaymentHistory";
import PolicyEndorsementsHistoryPage from "../ui/pages/PolicyEndorsementsHistory/PolicyEndorsementsHistory";

/**
 * 
 */
export const PolicyRoutes = () => {
  return {
    path: POLICY_PATH.POLICY,
    element: <PolicyPage />,
    children: [
      { path: `${POLICY_PATH.POLICY}/`, element: <Navigate to={`${POLICY_PATH.STATUS}/${POLICY_PARAMS.STATUS.ACTIVE}`} replace /> },
      { path: `${POLICY_PATH.STATUS}/:status`, element: <PolicyStatusPage /> },
      { path: `${POLICY_PATH.STATUS}`, element: <Navigate to={`${POLICY_PATH.STATUS}/${POLICY_PARAMS.STATUS.ACTIVE}`} replace /> },
      { path: `${POLICY_PATH.DETAILS}`, element: <PolicyDetails />},
      { path: `${POLICY_PATH.DETAILS}/:policyNumber`, element: <PolicyDetails />},
      { path: `${POLICY_PATH.MANAGEMENT}/:policyNumber`, element: <PolicyManagementPage /> },
      { path: `${POLICY_PATH.PICTURES}/:policyNumber`, element: <PolicyPicturesPage />},
      { path: `${POLICY_PATH.PAYMENT_METHOD}/:policyNumber`, element: <PolicyPaymentMethodPage />},
      { path: `${POLICY_PATH.CANCELLATION}/:policyNumber`, element: <PolicyCancellationPage />},
      { path: `${POLICY_PATH.PAYMENT_HISTORY}/:policyNumber`, element: <PolicyPaymentHistoryPage />},
      { path: `${POLICY_PATH.ENDORSEMENTS_HISTORY}/:policyNumber`, element: <PolicyEndorsementsHistoryPage />},
    ]
  }
}

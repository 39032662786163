import { Util } from "src/utils/Util";

export class Reward {

  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };
  /**
   *
   */
  private _points: number;
  set points(_points: number) { this._points = _points };
  get points(): number { return this._points };
  /**
   *
   */
  private _title: string;
  set title(_title: string) { this._title = _title };
  get title(): string { return this._title };
  /**
   *
   */
  private _brandName: string;
  set brandName(_brandName: string) { this._brandName = _brandName };
  get brandName(): string { return this._brandName };
  /**
   *
   */
  private _type: string;
  set type(_type: string) { this._type = _type };
  get type(): string { return this._type };
  /**
   *
   */
  private _image: string;
  set image(_image: string) { this._image = _image };
  get image(): string { return this._image };
  /**
   *
   */
  private _code: string | undefined;
  set code(_code: string | undefined) { this._code = _code };
  get code(): string | undefined { return this._code };
  /**
   *
   */
  private _exchangeDate: Date | undefined;
  set exchangeDate(_exchangeDate: Date | undefined) { this._exchangeDate = _exchangeDate };
  get exchangeDate(): Date | undefined { return this._exchangeDate };

  constructor (_points: number, _title: string, _brandName: string, _type: string) {
    this._id = '';
    this._points = _points;
    this._title = _title;
    this._brandName = _brandName;
    this._type = _type;
    this._image = '';
  }

  /**
   * 
   */
   public getExchangeDate(): string {
    if (this._exchangeDate) {
      return Util.TRANSFORM.DATE.formatDate(this._exchangeDate);
    }

    return '--';
  }

}
import { PropsWithChildren, useEffect, useState } from "react";
import { Text } from "src/components";
import './ClaimContent.scss';

/** */
const LANDSCAPE_ORIENTATION = '(orientation: landscape)';

/**
 *
 */
interface IClaimContent {

  title: string;

  subtitle?: string;

  subtitleType?: 'subtitle' | 'paragraph' | 'label',

  message?: string;

  backButton?: React.ReactNode;
}

/**
 *
 */
const ClaimContent = (props: PropsWithChildren<IClaimContent>): JSX.Element => {
  const {
    title,
    subtitle,
    message,
    subtitleType = 'subtitle',
    backButton,
  } = props;

  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(
    () => {
      const landscapeListener = (e: MediaQueryListEvent) => {
        const isLandscape = e.matches;
        setIsLandscape(isLandscape)
      };

      window.matchMedia(LANDSCAPE_ORIENTATION).addEventListener("change", landscapeListener);

      return () => {
        window.matchMedia(LANDSCAPE_ORIENTATION).removeEventListener("change", landscapeListener);
      };
    }, []
  )

  return (
    <div className="claimContent">

      <section className="claimContent__section claimContent__section--header">
        {
          backButton
        }
        <div className="claimContent__section__transitioner">
          <Text type="title" align="center" className="claimContent__section__title">{title}</Text>
          {
            subtitle &&
              <Text type={subtitleType} align="center" className="claimContent__section__subtitle">{subtitle}</Text>
          }

          {
            message &&
              <Text type="label" align="center" block className="claimContent__section__message">{message}</Text>
          }
        </div>

      </section>

      <section className="claimContent__section claimContent__section--content">

        <div className={`claimContent__section__transitioner claimContent__section__transitioner--delay ${isLandscape && 'claimContent__section__transitioner--landscape'}`}>
          {props.children}
        </div>
      </section>
    </div>
  );
};

export default ClaimContent;
import { Person  } from "src/classes/Person";
import { ILoginData } from "../interfaces/login/ILoginData";

export namespace CoreLoginDataAdapter {


  /**
   * 
   * @param user 
   * @returns 
   */
  export const userLoginDataAdapter = (user: Person): ILoginData => {
    return {
      email: user.email,
      password: user.password
    }
  }
}

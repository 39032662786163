import { RegimeUse } from "../invoice/RegimeUse";
import { TaxRegime } from "../invoice/TaxRegime";

export class Bill {

  /**
   *
   */
  private _id: string | undefined;
  set id(_id: string | undefined) { this._id = _id };
  get id(): string | undefined { return this._id };

  /**
   *
   */
  private _personId: string | undefined;
  set personId(_personId: string | undefined) { this._personId = _personId };
  get personId(): string | undefined { return this._personId };


  /**
   *
   */
  private _personEmail: string;
  set personEmail(_personEmail: string) { this._personEmail = _personEmail };
  get personEmail(): string { return this._personEmail };

  /**
   *
   */
  private _personName: string;
  set personName(_personName: string) { this._personName = _personName };
  get personName(): string { return this._personName };

  /** 
   *
   */
  private _rfc: string;
  set rfc(_rfc: string) { this._rfc = _rfc };
  get rfc(): string { return this._rfc };


  /**
   *
   */
  private _postalCode: string;
  set postalCode(_postalCode: string) { this._postalCode = _postalCode };
  get postalCode(): string { return this._postalCode };

  /**
   *
   */
  private _type: number;
  set type(_type: number) { this._type = _type };
  get type(): number { return this._type };

  /**
   *
   */
  private _taxRegime: TaxRegime | undefined;
  set taxRegime(_taxRegime: TaxRegime | undefined) { this._taxRegime = _taxRegime };
  get taxRegime(): TaxRegime | undefined { return this._taxRegime };

  /**
   *
   */
  private _regimeUse: RegimeUse | undefined;
  set regimeUse(_regimeUse: RegimeUse | undefined) { this._regimeUse = _regimeUse };
  get regimeUse(): RegimeUse | undefined { return this._regimeUse };

  /**
   *
   */
  private _saveData: boolean;
  set saveData(_saveData: boolean) { this._saveData = _saveData };
  get saveData(): boolean { return this._saveData };

  constructor() {
    this._personName = '';
    this._postalCode = '';
    this._type = -1;
    this._rfc = '';
    this._personEmail = '';
    this._saveData = false;
  }
}
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import AttorneyLetterPage from "../ui/pages/AttorneyLetter/AttorneyLetterPage";
import BankAccountPage from "../ui/pages/BankAccount/BankAccountPage";
import ClaimPayments from "../ui/pages/ClaimPayments";
import ClaimsPersonalDataPage from "../ui/pages/ClaimsPersonalData/ClaimsPersonalDataPage";
import CompletionPage from "../ui/pages/Completion/CompletionPage";
import IdDocumentsPage from "../ui/pages/IdDocuments/IdDocumentsPage";
import OnboardingPage from "../ui/pages/Onboarding/OnboardingPage";
import PolicyDataPage from "../ui/pages/PolicyData/PolicyDataPage";
import ProposalPage from "../ui/pages/Proposal/ProposalPage";
import VehicleBillPage from "../ui/pages/VehicleBill/VehicleBillPage";
import VehicleDataPage from "../ui/pages/VehicleData/VehicleDataPage";
import VehicleOwnerPage from "../ui/pages/VehicleOwner/VehicleOwnerPage";

/**
 *
 */
export const AgreedPaymentsRoutes = (
  isValidAuth: boolean,
  redirect?: string
) => {
  return {
		path: AGREED_PAYMENTS_PATH.AGREED_PAYMENTS,
		element: <ClaimPayments />,
		children: [
			{ path: '', redirect: AGREED_PAYMENTS_PATH.ONBOARDING },
			{ path: AGREED_PAYMENTS_PATH.ONBOARDING, element: <OnboardingPage /> },
			{ path: AGREED_PAYMENTS_PATH.POLICY_DATA, element: <PolicyDataPage /> },
			{ path: AGREED_PAYMENTS_PATH.PERSONAL_DATA, element: <ClaimsPersonalDataPage /> },
			{ path: AGREED_PAYMENTS_PATH.VEHICLE_DATA, element: <VehicleDataPage /> },
			{ path: AGREED_PAYMENTS_PATH.ID_DOCUMENTS, element: <IdDocumentsPage /> },
			{ path: AGREED_PAYMENTS_PATH.VEHICLE_OWNER, element: <VehicleOwnerPage /> },
			{ path: AGREED_PAYMENTS_PATH.BANK_ACCOUNT, element: <BankAccountPage /> },
			{ path: AGREED_PAYMENTS_PATH.VEHICLE_BILL, element: <VehicleBillPage /> },
			{ path: AGREED_PAYMENTS_PATH.ATTORNEY_LETTER, element: <AttorneyLetterPage /> },
			{ path: AGREED_PAYMENTS_PATH.COMPLETION, element: <CompletionPage /> },
			{ path: AGREED_PAYMENTS_PATH.PROPOSAL, element: <ProposalPage /> },
		]
	};
};

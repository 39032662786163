import { PropsWithChildren } from "react";
import { Button, Modal, Text } from "src/components";
import './DeleteItemModal.scss';

/**
 *
 */
interface IDeleteItemModal {

  type: 'people' | 'damage' | 'service' | 'policy',

	show: boolean,

	onConfirmDelete: () => void;

	onCancel: () => void;
}

/**
 *
 */
const DeleteItemModal = (props: PropsWithChildren<IDeleteItemModal>): JSX.Element => {
  const {
    type,
		show,
		onConfirmDelete,
		onCancel
  } = props;

  return (
    <div className="deleteItemModal">
			<Modal show={show} position="center">
				<div className="deleteItemModal__content">
					{
						type === 'people' &&
							<Text type="subtitle" align="center">¿Estás seguro que deseas <br /> eliminar el involucrado?</Text>
					}

					{
						type === 'damage' &&
							<Text type="subtitle" align="center">¿Estás seguro que deseas <br /> eliminar el daño?</Text>
					}

					{
						type === 'service' &&
							<Text type="subtitle" align="center">¿Estás seguro que deseas <br /> eliminar el servicio?</Text>
					}

					{
						type === 'policy' &&
							<Text type="subtitle" align="center">Al aceptar, se borrarán los <br /> datos de la póliza ya insertados.</Text>
					}
					
					<div className="deleteItemModal__content__buttonWrapper">
						<Button onClick={onConfirmDelete}>Aceptar</Button>
						<Button fill="outline" onClick={onCancel}>Cancelar</Button>
					</div>
				</div>
			</Modal>
    </div>
  );
};

export default DeleteItemModal;
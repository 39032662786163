import { ButtonLink, Spinner } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { UserEmailValidationFacade } from "src/modules/common/controller/facades/user-email-validation.facade";
import { Util } from "src/utils/Util";

import './UserEmailValidation.scss';

/**
 *
 */
interface IUserEmailValidation {

}

/**
 *
 */
const UserEmailValidationPage = (props: IUserEmailValidation): JSX.Element => {
  const { isValidating, isInvalid } = UserEmailValidationFacade.useUserEmailValidation();

  return (
    <div id="user-email-validation-wrapper" className="flex column justify-center align-center relative" style={{ backgroundImage: `url(${Util.ASSET.LOGIN_BG})` }}>
      {
        isValidating ?
          <Spinner color="main" />
          :
          <>
            {
              isInvalid ?
                <>
                  <div className="email-validation-image">
                    <span className="flex align-center justify-center email-validation-icon">
                      {IconFactory.closeIcon()}
                    </span>
                  </div>
                  <h2>Ha ocurrido un error procesando la solicitud.</h2>
                  <h3>Solicita nuevamente un correo de verificaci&oacute;n y vuelva a intentarlo.</h3>
                  <ButtonLink to={'/'} replace>Regresar</ButtonLink>
                </>
                :
                <>
                  <div className="email-validation-image">
                    <span className="background-image block" style={{ backgroundImage: `url(${Util.ASSET.VALIDATE_EMAIL_CONFIRMATION})` }}></span>
                  </div>
                  <h2>¡Tu correo electr&oacute;nico ha sido validado!</h2>
                  <h3>Ya puedes ingresar a la aplicaci&oacute;n y revisar tus p&oacute;lizas.</h3>
                  <ButtonLink to={'/'} replace>Ingresar</ButtonLink>
                </>
            }

          </>
      }
    </div>
  );
};

export default UserEmailValidationPage;
import { FORGOT_PASSWORD_PATH } from "src/routes/paths/forgot-password.paths";
import PasswordRestorePage from "../ui/pages/PasswordRestore/PasswordRestore";
import RecoveryPage from "../ui/pages/PasswordRecovery/PasswordRecovery";


/**
 *
 */
export const ForgotPasswordRoutes = (/* Auth validation and redirect params if needed */) => {
  return {
    path: FORGOT_PASSWORD_PATH.FORGOT_PASSWORD,
    children : [
      { path: FORGOT_PASSWORD_PATH.RECOVERY, element: <RecoveryPage /> },
      { path: FORGOT_PASSWORD_PATH.RESTORE, element: <PasswordRestorePage />},
    ]
  }
}
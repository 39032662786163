import { Reward } from "src/classes/referrals/Reward";
import { Util } from "src/utils/Util";
import { IExchangeRewardRequest } from "../interfaces/IExchangeRewardRequest";
import { IRewardsData } from "../interfaces/IRewardsData";
import { IRewardsHistoryData } from "../interfaces/IRewardsHistoryData";

export namespace ReferralsAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const rewardsDataAdapter = (data: IRewardsData): Reward[] => {
    let rewards: Reward[] = [];

    for (const rewardData of data.rewards) {
      let productType: string = '';
      switch (rewardData.type) {
        case Util.STATUS_CODE.REWARD_TYPE_CODE.PRODUCT:
          productType = 'Producto'
          break;
      }
      let newReward: Reward = new Reward(rewardData.price, rewardData.title, rewardData.brand, productType);
      newReward.id = rewardData.id;
      newReward.image = rewardData.image ? rewardData.image : '';

      rewards.push(newReward);
    }
    return rewards;
  }


  /**
   * 
   * @param reward 
   * @returns 
   */
  export const exchangeRewardRequestAdapter = (reward: Reward): IExchangeRewardRequest => {
    let request: IExchangeRewardRequest = {
      reward_id: reward.id
    }

    return request;
  }

  export const rewardsHistoryDataAdapter = (data: IRewardsHistoryData): Reward[] => {
    let rewardsHistory: Reward[] = [];

    let productType: string = "Cupón";

    for (const rewardHistoryData of data.reward_record) {
      let newReward: Reward = new Reward(rewardHistoryData.points, rewardHistoryData.reward_title, rewardHistoryData.reward_brand, productType);
      newReward.id = rewardHistoryData.id
      newReward.exchangeDate = new Date(rewardHistoryData.date);
      newReward.code = rewardHistoryData.serial;

      rewardsHistory.push(newReward);
    }

    return rewardsHistory;
  }
}
import { useEffect, useState } from "react";

/**
 *
 */
export namespace ESignFacade {

  /**
   * 
   * @param sign 
   * @returns 
   */
  export const useESign = (sign?: string) => {
    const [currentSign, setCurrentSign] = useState<string>(() => {
      return sign ? sign : ''
    })

    return { currentSign, setCurrentSign }
  }

  /**
   * 
   * @param element 
   * @returns 
   */
  export const useCanvas = (element: HTMLDivElement, defaultImage?: string, orientation?: 'landscape' | 'portrait') => {
    const [canvas, setCanvas] = useState<HTMLCanvasElement | undefined>();
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | undefined>();
    const [drawing, setDrawing] = useState<boolean>(false);
    const [mousePos, setMousePos] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [lastPos, setLastPos] = useState<{ x: number, y: number }>(mousePos);
    const [submitedSign, setSubmitedSign] = useState<string>('');

    const draw = () => {
      if (drawing && ctx) {
        ctx.moveTo(lastPos.x, lastPos.y);
        ctx.lineTo(mousePos.x, mousePos.y);
        ctx.stroke();

        setLastPos(mousePos);
      }
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    const getMousePos = (e: React.MouseEvent<HTMLCanvasElement>) => {
      var rect = canvas?.getBoundingClientRect();
      return {
        x: e.clientX - rect?.left!,
        y: e.clientY - rect?.top!
      }
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    const getTouchPos = (e: React.TouchEvent<HTMLCanvasElement>) => {
      var rect = canvas?.getBoundingClientRect();
      return {
        x: e.touches[0].clientX - rect?.left!,
        y: e.touches[0].clientY - rect?.top!
      }
    }

    const onMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
      setDrawing(true);
      setLastPos(getMousePos(e));
    }

    const onMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
      setMousePos(getMousePos(e));
      draw();
    }

    const onMouseUp = () => {
      setDrawing(false);
    }

    const onTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) => {
      setDrawing(true);
      setLastPos(getTouchPos(e));
      setMousePos(getTouchPos(e));
    }

    const onTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
      setMousePos(getTouchPos(e));
      draw();
    }

    const onTouchEnd = () => {
      setDrawing(false);
    }

    /**
     * 
     */
    const clearCanvas = (): void => {
      if (canvas && ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        setSubmitedSign('');
      }
    }

    /**
     * 
     */
    const submitSign = (): void => {
      if (canvas) {
        setSubmitedSign(canvas.toDataURL('image/png'));
      }
    }

    /**
     * 
     */
    const createCtx = (canvas: HTMLCanvasElement): void => {
      const newCtx = canvas.getContext('2d');

      if (newCtx) {
        newCtx.strokeStyle = "#000";
        newCtx.lineWidth = 2;
        newCtx.lineCap = 'round';
        setCtx(newCtx);
      }
    }

    useEffect(() => {
      const preventDefaultEvent = (event: TouchEvent) => {
        if (event.target === canvas)
          event.preventDefault();
      }

      document.body.addEventListener("touchstart", preventDefaultEvent, { passive: false });
      document.body.addEventListener("touchend", preventDefaultEvent, { passive: false });
      document.body.addEventListener("touchmove", preventDefaultEvent, { passive: false });
      return () => {
        document.body.removeEventListener("touchstart", preventDefaultEvent);
        document.body.removeEventListener("touchend", preventDefaultEvent);
        document.body.removeEventListener("touchmove", preventDefaultEvent);
      };
    }, [canvas]);

    /**
     * 
     */
    useEffect(
      () => {
        const isPortrait = orientation === 'portrait'
        const newCanvas = document.getElementById(isPortrait ? 'e-sign' : 'landscape-e-sign') as HTMLCanvasElement;
        setCanvas(newCanvas);
        if (newCanvas) {
          if (element) {
            newCanvas.width = element.clientWidth;
          }
          createCtx(newCanvas);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [canvas, element, defaultImage]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (defaultImage && ctx) {
          const img = new Image();
          img.src = defaultImage;
          img.onload = function(){
            ctx.drawImage(img, 0, 0);
            setSubmitedSign(defaultImage);
          }

        }
      }, [defaultImage, ctx]
    )


    return { onMouseDown, onMouseMove, onMouseUp, onTouchStart, onTouchEnd, onTouchMove, clearCanvas, submitSign, submitedSign }
  }
}
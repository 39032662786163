import { ErrorMessage } from "src/components";
import { IconFactory } from 'src/factory/icon.factory';
import './FormError.scss';

/**
 *
 */
interface IFormError {

  schema: TFormErrorMessage[];

  type?: string;

}

/**
 *
 */
export const FormError = (props: IFormError): JSX.Element => {
  return (
    <div className="form-error-wrapper flex align-center">
      {
        props.type &&
        <>
          {IconFactory.warningIcon()}
          <ErrorMessage message={props.schema.find(s => s.key === props.type)?.message as string} />
        </>
      }
    </div>
  );
};
import './Label.scss';

/**
 *
 */
interface ILabel {
  required?: boolean;
}

/**
 *
 */
export const Label = (props: ILabel & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> ): JSX.Element => {
  return (
    <label {...props}>
      {props.children}
      {props.required && <span>*</span>}
    </label>
  );
};
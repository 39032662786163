import { RootState } from "src/store/store";

/**
 *
 */
export namespace PolicySelector {

  export const policyList = (state: RootState) => state.policy?.policyList;

  export const endorsements = (state: RootState) => state.policy?.endorsements;

  export const pictureToken = (state: RootState) => state.policy?.pictureToken;

  export const selectedPolicy = (state: RootState) => state.policy?.selectedPolicy;
}


import { useEffect, useState } from 'react';
import { Plan } from 'src/classes/plan/Plan';
import { PlanMonthly } from 'src/classes/plan/PlanMonthly';
import { Checkbox, RadioDropdown } from "src/components";
import { Util } from 'src/utils/Util';

import './PaymentOption.scss';

/**
 *
 */
interface IPaymentOption {

	/** */
	planType?: string;
	/** */
	plan: Plan;
	/** */
	onChange: (type?: 'down' | 'monthly' | 'installments', months?: number) => void;
}

/**
 *
 */
const PaymentOption = (props: IPaymentOption): JSX.Element => {
	/** */
	const { plan, onChange } = props;
	/** */
	const [monthlyData, setMonthlyData] = useState<PlanMonthly>(new PlanMonthly());
	const [defaultRadioValue, setDefaultRadioValue] = useState<{ id: number, label: string, secondLabel: string }>();

	/**
	 * 
	 * @param type 
	 */
	const onPaymentSelected = (type: 'down' | 'monthly' | 'installments', months?: number): void => {
		onChange(type, months);
	}

	/**
	 * 
	 */
	useEffect(
		() => {
			if (plan.discount)
				setMonthlyData(plan.discount.monthly);
			else
				setMonthlyData(plan.monthly)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	useEffect(() => {
		if (plan.selectedPlanPayment.type !== Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) {
			for (let i = 0; i < plan.monthly.costs.length; i++) {
				if (plan.monthly.costs[i].monthsQuantity === plan.selectedPlanPayment.paymentsCount) {
					setDefaultRadioValue({
						id: plan.monthly.costs[i].monthsQuantity,
						label: `${plan.monthly.costs[i].monthsQuantity} pagos de ${plan.monthly.costs[i].getAmountByMonth()}`,
						secondLabel: '',
					});
				}
			}
		}
	}, [plan]);

	// /** */
	return (
		<div className="payment-option-wrapper">

			<div className="payments flex align-center" onClick={() => onPaymentSelected('monthly')}>
				<section className={`left-section width-40 ${props.planType === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION && 'active'}`}>
					<div className="flex">
						<Checkbox checked={props.planType === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION} readOnly />
						<p>Pago mes a mes</p>
						<p className="mobile-text">Mes a mes</p>
					</div>
				</section>

				<section className="flex width-60 right-section">
					<p>12 pagos <b>(Total de {Util.TRANSFORM.CURRENCY.MXN(((plan.discount ? plan.discount.subscription.cost.total : plan.subscription.cost.total) * 11) + (plan.discount ? plan.discount.subscription.firstCost.total : plan.subscription.firstCost.total))})</b></p>
				</section>
			</div>

			<div className="payments flex align-center" onClick={() => onPaymentSelected('down')}>
				<section className={`left-section width-40 ${props.planType === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL && 'active'}`}>
					<div className="flex">
						<Checkbox checked={props.planType === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL} readOnly />
						<p>Pago de contado</p>
						<p className="mobile-text">De contado</p>
					</div>
				</section>

				<section className="right-section flex column width-60">
					<p>1 pago de {plan.discount ? plan.discount.cost.getTotal() : plan.cost.getTotal()}</p>
				</section>
			</div>

			<div className="payments flex align-center" onClick={() => onPaymentSelected('installments', 0)}>
				<section className={`left-section width-40 ${props.planType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && 'active'}`}>
					<div className="flex">
						<Checkbox checked={props.planType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS} readOnly />
						<p>Pago diferido</p>
						<p className="mobile-text">Diferido</p>
					</div>
				</section>

				<section className="flex width-60 right-section">
					{
						props.planType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS ?
							<RadioDropdown
								items={monthlyData!.costs.map((cost) => {
									return {
										id: cost.monthsQuantity,
										label: `${cost.monthsQuantity} pagos de ${cost.getAmountByMonth()}`,
										secondLabel: ''
									}
								})}
								transparent
								placeholder="Selecciona el plazo"
								firstAsDefault
								defaultValue={defaultRadioValue}
								secondPlaceholder="3, 6 o 9 meses"
								onChange={(p) => onChange('installments', p.id)}
							/>
							:
							<p>
								--
							</p>
					}

				</section>
			</div>

		</div>
	);
};

export default PaymentOption;
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Plan } from 'src/classes/plan/Plan';
import { Policy } from 'src/classes/Policy';
import { Person  } from "src/classes/Person";
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { IPolicyCreationStateReducer } from "src/store/states/modules-state/IPolicyCreationStateReducer";

/**
 *
 */
export namespace PolicyCreationSlice {
  /**
   * 
   */
  const initialState: IPolicyCreationStateReducer = {
    driver: undefined,
    vehicle: undefined,
    plan: undefined,
    review: false,
    coverages: [],
    coupon: '',
		policy: undefined,
		vehiclePostalCode: undefined
  };


  /**
   * 
   * @param state 
   * @param action 
   */
  const clear: CaseReducer<IPolicyCreationStateReducer> = (state) => {
    state.vehicle = undefined;
    state.plan = undefined;
    state.review = false;
    state.coupon = '';
    state.coverages = [];
    state.driver = undefined;
		state.policy = undefined;
		state.vehiclePostalCode = undefined;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const currentDriver: CaseReducer<IPolicyCreationStateReducer, PayloadAction<Person>> = (state, action) => {
    state.driver = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const removeDriver: CaseReducer<IPolicyCreationStateReducer> = (state) => {
    state.driver = undefined;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const currentVehicle: CaseReducer<IPolicyCreationStateReducer, PayloadAction<Vehicle>> = (state, action) => {
    state.vehicle = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const removeVehicle: CaseReducer<IPolicyCreationStateReducer> = (state) => {
    state.vehicle = undefined;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const currentPlan: CaseReducer<IPolicyCreationStateReducer, PayloadAction<Plan>> = (state, action) => {
    state.plan = action.payload;
    state.coupon = action.payload.coupon;
    if (action.payload.allowEdit) {
      state.coverages = action.payload.coveragesList;
    }
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const removePlanDetails: CaseReducer<IPolicyCreationStateReducer> = (state) => {
    state.coverages = [];
    state.coupon = '';
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const removePlan: CaseReducer<IPolicyCreationStateReducer> = (state) => {
    state.plan = undefined;
  }

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const currentPolicy: CaseReducer<IPolicyCreationStateReducer, PayloadAction<Policy>> = (state, action) => {
		state.policy = action.payload;
	}

  /**
   * 
   * @param state 
   * @param action 
   */
  const completeReview: CaseReducer<IPolicyCreationStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.review = action.payload;
  }

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const setVehiclePostalCode: CaseReducer<IPolicyCreationStateReducer, PayloadAction<string>> = (state, action) => {
		state.vehiclePostalCode = action.payload;
	}

  const reducers = {
    clear,
    currentDriver,
    removeDriver,
    currentVehicle,
    removeVehicle,
    currentPlan,
    removePlan,
    removePlanDetails,
		currentPolicy,
    completeReview,
		setVehiclePostalCode
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'policyCreation',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
import { PROFILE_PATH } from "src/routes/paths/profile.paths";
import ProfileInfoPage from "../ui/pages/ProfileInfo/ProfileInfo";
import SecurityPage from "../ui/pages/Security/Security";

/**
 *
 */
export const ProfileRoutes = () => {
  return {
    path: PROFILE_PATH.PROFILE,
    children : [
      { path: PROFILE_PATH.PROFILE_INFO, element: <ProfileInfoPage /> },
      { path: PROFILE_PATH.SECURITY, element: <SecurityPage /> },
    ]
  }
}
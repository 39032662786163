import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Policy } from "src/classes/Policy";
import { INSURANCE_VEHICLE_PATH } from "src/routes/paths/insurance-vehicle.paths";
import { Util } from "src/utils/Util";
import { PolicyAction } from "../actions/policy.action";
import { PolicySelector } from "../selectors/policy.selector";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";

export namespace PolicyPicturesFacade {

  export const usePolicyPictures = (currentPolicy: Policy | undefined) => {
    const [currentDomain, setCurrentDomain] = useState<string>('');
    const [params, setParams] = useState<string>('');
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [path, setPath] = useState<string>('');
    const pictureToken = useSelector(PolicySelector.pictureToken);
    const dispatch = useDispatch();

    useEffect(
      () => {
        let domain = `${window.location.protocol}//${window.location.hostname}`;
        if (EnvironmentFactory.ENV.REACT_APP_API_ENV === 'dev') {
          domain = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
        }
        setPath(INSURANCE_VEHICLE_PATH.INSURANCE);
        setCurrentDomain(`${domain}${INSURANCE_VEHICLE_PATH.INSURANCE}`);
      }, []
    );

    useEffect(
      () => {
        if (currentPolicy && pictureToken) {
          const newParams = `${Util.KEY.QUERY_PARAMS.TOKEN}=${btoa(pictureToken)}&` +
            `${Util.KEY.QUERY_PARAMS.POLICY_ID}=${currentPolicy.id}&` +
            `${Util.KEY.QUERY_PARAMS.POLICY_NUMBER}=${currentPolicy.policyNumber}&` +
            `${Util.KEY.QUERY_PARAMS.BRAND_NAME}=${currentPolicy.vehicle?.brand?.name}&` +
            `${Util.KEY.QUERY_PARAMS.MODEL_NAME}=${currentPolicy.vehicle?.model?.name}&` +
            `${Util.KEY.QUERY_PARAMS.YEAR}=${currentPolicy.vehicle?.year?.value}`

          setParams(encodeURIComponent(newParams));

        }
      }, [currentPolicy, pictureToken]
    );

    useEffect(
      () => {
        const isMobile: boolean = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          setIsMobile(true);
        }
      }, []
    );

    useEffect(
      () => {
        dispatch(PolicyAction.picturetoken());
      }, [dispatch]
    );

    useEffect(
      () => {
        return () => {
          dispatch(PolicyAction.clearPictureToken());
        }
      }, [dispatch]
    )

    return { currentDomain, params, isMobile, path }
  }
}
import { Navigate } from "react-router-dom";
import { POLICY_RENEWAL_PATH } from "src/routes/paths/policy-renewal.paths";
import PlanRenewalPage from "../ui/pages/PlanRenewal/PlanRenewal";
import PlanRenewalPaymentTsPage from "../ui/pages/PlanRenewalPayment/PlanRenewalPayment";
import PlanReviewRenewalPage from "../ui/pages/PlanReviewRenewal/PlanReviewRenewal";
import PolicyRenewalPage from '../ui/pages/PolicyRenewal';
import RenowalCompletionPage from "../ui/pages/RenowalCompletion/RenowalCompletion";

/**
 *
 */
export const PolicyRenewalRoutes = (isValidAuth: boolean, redirect: string) => {
  return {
    path: `${POLICY_RENEWAL_PATH.POLICY_RENEWAL}/:policyNumber`,
    element: isValidAuth === true ? <PolicyRenewalPage /> : <Navigate to={redirect} />,
    children : [
      {
        path: `${POLICY_RENEWAL_PATH.POLICY_RENEWAL_PLAN_PATH}`,
        element: <PlanRenewalPage />,
      },
      {
        path: `${POLICY_RENEWAL_PATH.POLICY_RENEWAL_REVIEW_PATH}`,
        element: <PlanReviewRenewalPage />,
      },
      {
        path: `${POLICY_RENEWAL_PATH.POLICY_RENEWAL_PAYMENT_PATH}`,
        element: <PlanRenewalPaymentTsPage />,
      },
      {
        path: `${POLICY_RENEWAL_PATH.POLICY_RENEWAL_COMPLETION_PATH}`,
        element: <RenowalCompletionPage />
      }
      
    ]
  }
}
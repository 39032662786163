import classNames from "classnames";
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import './InputPhone.scss';
import { IconFactory } from "src/factory/icon.factory";
import { DropdownItem, Label } from "src/components";
import { Util } from "src/utils/Util";

type PhoneCountryCode = {

  iso: string;

  value: string;
}

/**
 *
 */
interface IInputPhone {

  errors?: boolean;

  required?: boolean;

  lockCountryCode?: boolean;

  onCountryCodeSelected?: (code: string) => void;
}

/**
 *
 */
export const InputPhone = React.forwardRef(
  (
    props: IInputPhone & PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>>,
    ref: React.Ref<any>
  ):
    JSX.Element => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [currentCountryCode, setCurrentCountryCode] = useState<PhoneCountryCode>({ iso: 'MX', value: '+52' });
    const [isCountryCodeListOpen, setIsCountryCodeListOpen] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');

    /**
     * 
     * @param item 
     */
    const handleItemClick = (item: { id: number, value: string }): void => {
      const selectedCountry = Util.COUNTRIES.find(c => c.dialCode === item.value);
      if (selectedCountry) {
        setCurrentCountryCode({ iso: selectedCountry.id, value: selectedCountry.dialCode });
        setIsCountryCodeListOpen(false);
        setSearchText('');
      }
    }

    const searchVerification = (baseItemName: string) => {
      return baseItemName.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(searchText.toLocaleLowerCase());
    }

    // Deconstruct our props
    const {
      errors = false,
      className,
      children,
      onCountryCodeSelected,
      lockCountryCode,
      ...rest
    } = props;

    const inputWrapperClassNames = classNames({
      'input-phone-wrapper': true,
      'input-label-wrapper': true,
      'invalid': errors ? true : false,
      'flex': true,
      'align-center': true,
      'relative': true,
    })

    const inputClassNames = classNames({
      'height-100': true,
      'width-100': true,
      'border-box': true,
    })

    /**
     * 
     * @param event 
     */
    const handleClickOutside = useCallback(
      (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          if (isCountryCodeListOpen) {
            setIsCountryCodeListOpen(false);
          }
        }
      }, [isCountryCodeListOpen]
    );

    /**
     * 
     * @param value 
     */
    const toggleCountryCode = (): void => {
      if (!lockCountryCode) {
        setIsCountryCodeListOpen(!isCountryCodeListOpen)
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [wrapperRef, handleClickOutside]);


    useEffect(() => {
      if (onCountryCodeSelected) {
        onCountryCodeSelected(currentCountryCode.value);
      }
    }, [currentCountryCode, onCountryCodeSelected])

    return (
      <div className={inputWrapperClassNames} ref={wrapperRef}>
        <Label required={props.required} className="absolute">{children}</Label>

        <div className="country-selected flex flex-grow-100">

          <div className="country-code-wrapper flex align-center" onClick={() => toggleCountryCode()}>
            <span className="country-flag">
              <img alt="mx" src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${currentCountryCode.iso}.svg`} />
            </span>
            <span>
              {currentCountryCode.value}
            </span>
            {IconFactory.dropDownArrowIcon()}
          </div>
          
          <input className={`${inputClassNames} ${className ? className : ''}`} {...rest} ref={ref} type="tel" autoComplete="off" 
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}  maxLength={10}
          />
        </div>

        {
          (isCountryCodeListOpen) &&
          <div className="countries">

            <div className="search-country flex align-center">
              {IconFactory.searchIcon()}
              <input autoFocus type="text" placeholder="Buscar país" onChange={(e) => setSearchText(e.target.value)} />
            </div>

            {
              Util.COUNTRIES.map(
                (country, index) => {
                  if (searchText !== '' && (searchVerification(country.id) || searchVerification(country.label))) {
                    return (
                      <DropdownItem key={country.id} item={{ value: country.dialCode, id: index }} onSelect={(i) => handleItemClick(i as { id: number, value: string })}>
                        <>
                          <span className="inline-block vertical-align-middle country-flag">
                            <img className="country-flag" alt={country.id} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.id}.svg`} />
                          </span>
                          <span className="inline-block vertical-align-middle">
                            {country.label}
                          </span>
                        </>
                      </DropdownItem>
                    )
                  } else if (searchText === '') {
                    return (
                      <DropdownItem key={country.id} item={{ value: country.dialCode, id: index }} onSelect={(i) => handleItemClick(i as { id: number, value: string })}>
                        <>
                          <span className="inline-block vertical-align-middle country-flag">
                            <img className="country-flag" alt={country.id} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.id}.svg`} />
                          </span>
                          <span className="inline-block vertical-align-middle">
                            {country.label}
                          </span>
                        </>
                      </DropdownItem>
                    )
                  } else {
                    return undefined
                  }

                }
              )
            }
          </div>
        }

      </div>
    );
  }
);
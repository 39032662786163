import { IIdentityStatus } from "../interfaces/user/IIdentityStatus";
import { IUserIdentityData } from "../interfaces/user/IUserIdentityData";

/**
 * 
 */
export namespace CoreUserIdentityDataAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const userIdentityDataAdapter = (data: IUserIdentityData): IIdentityStatus => {
		let returnData: { status: number, errorCode: number } = {
			status: data.data && (data.data.response_code >= 0) ? data.data.response_code : -1,
			errorCode: data.data && data.data.failed_code ? data.data.failed_code : -1
		}

    return returnData;
  }


}
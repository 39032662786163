import { Button, Spinner, Text } from "src/components";
import { Util } from "src/utils/Util";
import { BillFacade } from '../../controller/facades/bill.facade';
import BillForm from "../components/BillForm/BillForm";
import './Bill.scss';
import { IconFactory } from "src/factory/icon.factory";
import classNames from "classnames";

/**
 *
 */
const BillPage = (): JSX.Element => {
  const {
    invoiceStatus,
    policyNumber,
    currentInvoice,
    billList,
    creating,
    taxRegimes,
    visibilityStates,
    downloadStates,
    createInvoiceByForm,
  } = BillFacade.useInvoice();

  const billContentClass = classNames(
    {
      'bill__content--flex': invoiceStatus && !invoiceStatus.isAvailable()
    }
  )

  const overlayAnimClass = classNames(
    {
      'bill__content__overlay--in': visibilityStates.overlayVisible === true || creating.value === true,
      'bill__content__overlay--out': visibilityStates.overlayVisible === false && creating.value === false,
    }
  )

  const bottomSheetAnimClass = classNames(
    {
      'bill__content__bottom-sheet--in': visibilityStates.downloadSheetVisible === true,
      'bill__content__bottom-sheet--out': visibilityStates.downloadSheetVisible === false,
    }
  )

  const formAnimClass = classNames(
    {
      'bill__content__invoice__form--in': visibilityStates.formVisible === true,
      'bill__content__invoice__form--out': visibilityStates.formVisible === false,
    }
  )

  return (
    <div className="bill">

      <div className={`bill__content ${billContentClass}`}>
        {
          invoiceStatus && taxRegimes && taxRegimes.length > 0 ?
            <>
              <div className={`bill__content__overlay ${overlayAnimClass}`}
                onClick={(e) => {
                  e.preventDefault();
                  visibilityStates.setOverlayVisible(false);
                  visibilityStates.setDownloadSheetVisible(false);
                  visibilityStates.setFormVisible(false);
                }}
              ></div>
              {
                invoiceStatus.isAvailable() && currentInvoice &&
                <>

                  <div className="bill__content__invoice">

                    <Text type="title" className="bill__content__invoice__title">P&oacute;liza {policyNumber}</Text>

                    <div className="bill__content__invoice__content">

                      <div className="bill__content__invoice__content__summary">
                        <div className="bill__content__invoice__content__summary__list">
                          <Text type="subtitle" bold>Resumen de la compra</Text>

                          <div className="bill__content__invoice__content__summary__list__row bill__content__invoice__content__summary__list__row--header">
                            <Text type="paragraph" bold>Descripci&oacute;n</Text>
                            <Text type="paragraph" bold align="center">IVA</Text>
                            <Text type="paragraph" bold align="right">Precio</Text>
                          </div>
                          {
                            currentInvoice.premiumList.map(
                              (premium, index) => {
                                return (
                                  <div className={`bill__content__invoice__content__summary__list__row ${currentInvoice.premiumList.length === (index + 1) && `bill__content__invoice__content__summary__list__row--last`}`} key={`invoice-item-${index}`}>
                                    <div className="bill__content__invoice__content__summary__list__row__description">
                                      <Text type="label" block className="bill__content__invoice__content__summary__list__row__description__name">{premium.description}</Text>
                                      <Text type="label">{premium.policyNumber}</Text>
                                    </div>

                                    <Text type="label" align="center">
                                      {Util.TRANSFORM.CURRENCY.MXN(premium.taxes)}
                                    </Text>
                                    <Text type="label" align="right">
                                      {Util.TRANSFORM.CURRENCY.MXN(premium.subTotal)}
                                    </Text>
                                  </div>
                                )
                              }
                            )
                          }

                          <div className="bill__content__invoice__content__summary__list__price">
                            <div className="bill__content__invoice__content__summary__list__price__item">
                              <Text type="paragraph" bold>
                                Subtotal
                              </Text>
                              <Text type="paragraph">
                                {Util.TRANSFORM.CURRENCY.MXN(currentInvoice.netAmount)}
                              </Text>
                            </div>
                            <div className="bill__content__invoice__content__summary__list__price__item">
                              <Text type="paragraph" bold>
                                IVA (16%)
                              </Text>
                              <Text type="paragraph">
                                {Util.TRANSFORM.CURRENCY.MXN(currentInvoice.taxes)}
                              </Text>
                            </div>
                            <div className="bill__content__invoice__content__summary__list__price__item">
                              <Text type="paragraph" bold>
                                Total
                              </Text>
                              <Text type="paragraph">
                                {Util.TRANSFORM.CURRENCY.MXN(currentInvoice.getTotal())}
                              </Text>
                            </div>
                          </div>
                        </div>


                      </div>

                      <div className="bill__content__invoice__content__data">
                        <div className="bill__content__invoice__content__data__icon">
                          {IconFactory.warningIcon({ size: 35 })}
                        </div>

                        <div className="bill__content__invoice__content__data__message">
                          <Text type="paragraph" align="center" bold>No tienes datos de facturaci&oacute;n</Text>
                          <Text type="paragraph" align="center">Ingresa tus datos para generar la factura</Text>

                          <Button className="bill__content__invoice__content__data__message__button"
                            onClick={() => {
                              visibilityStates.setOverlayVisible(true);
                              visibilityStates.setFormVisible(true);
                            }}
                          >
                            Agregar datos de facturaci&oacute;n
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className={`bill__content__invoice__form ${formAnimClass}`}>
                      <BillForm
                        creating={creating.value}
                        taxRegimes={taxRegimes}
                        totalAmount={currentInvoice.getTotal()}
                        saveData={billList && billList?.length === 0}
                        onClose={() => {
                          visibilityStates.setOverlayVisible(false);
                          visibilityStates.setFormVisible(false);
                        }}
                        onCreate={(form, regime, use) => createInvoiceByForm(form, regime, use)}
                      />
                    </div>

                    {
                      creating.value &&
                      <div className="bill__content__invoice__loader-wrapper">
                        <div className="bill__content__invoice__loader-wrapper__loader">
                          <Spinner color="main" relative />
                          <Text type="paragraph" bold>Procesando tu factura...</Text>
                        </div>

                      </div>
                    }

                  </div>
                </>
              }

              {
                invoiceStatus.isExpired() &&
                <div className="bill__content__prompt bill__content__prompt--animation bill__content__prompt--center">
                  <div className="bill__content__prompt__icon-title">
                    {IconFactory.warningIcon({ size: 45 })}
                  </div>
                  <Text type="title" align="center">La p&oacute;liza {policyNumber} ya no est&aacute; disponible para facturaci&oacute;n</Text>
                  <Text type="paragraph" align="center" className="bill__content__prompt__space-top">Las facturas solo pueden emitirse dentro del mes de emisión de la póliza.</Text>

                  <Text type="paragraph" align="center" className="bill__content__prompt__link">Ir a <a href={Util.CONSTANT.WEB_URL}>crabi.com</a></Text>
                </div>
              }

              {
                invoiceStatus.isEmpty() &&
                <div className="bill__content__prompt bill__content__prompt--animation bill__content__prompt--center">
                  <div className="bill__content__prompt__icon-title">
                    {IconFactory.warningIcon({ size: 45 })}
                  </div>

                  <Text type="title" align="center">La p&oacute;liza {policyNumber}</Text>
                  <Text type="paragraph" align="center">No est&aacute; disponible para ser facturada.</Text>

                  <Text type="paragraph" align="center" className="bill__content__prompt__link">Ir a <a href={Util.CONSTANT.WEB_URL}>crabi.com</a></Text>

                </div>
              }

              {
                invoiceStatus.filesError() &&
                <div className="bill__content__prompt bill__content__prompt--animation bill__content__prompt--center">
                  <div className="bill__content__prompt__icon-title">
                    {IconFactory.warningIcon({ size: 45 })}
                  </div>

                  <Text type="title" align="center">No podemos acceder a la factura <br/> de la p&oacute;liza {policyNumber}</Text>
                  <Text type="paragraph" align="center">Tu p&oacute;liza ya esta facturada, puedes volver a intentar obtener la factura m&aacute;s tarde</Text>

                  <Text type="paragraph" align="center" className="bill__content__prompt__link">Ir a <a href={Util.CONSTANT.WEB_URL}>crabi.com</a></Text>

                </div>
              }

              {
                invoiceStatus.isFinished() &&
                <>
                  <div className="bill__content__prompt bill__content__prompt--animation">

                    <div className="bill__content__prompt__icon-title">
                      {IconFactory.checkCircleIcon({ size: 35 })}
                    </div>
                    <Text type="title">La compra de la p&oacute;liza <br />{policyNumber}</Text>
                    <Text type="paragraph" className="bill__content__prompt__space-bottom">Ya se encuentra facturada.</Text>

                    {
                      invoiceStatus.invoicesFile.filter(i => !i.isComplement).map(
                        (file, index) => (
                          <div key={`invoice-file-${index}`} className="bill__content__prompt__item">
                            <div className="bill__content__prompt__item__icon bill__content__prompt__item__icon--main">
                              <div className="bill__content__prompt__item__icon__background"></div>
                              {IconFactory.file2Icon({ size: 22 })}
                            </div>

                            <div className="bill__content__prompt__item__name">
                              <Text type="paragraph" bold>{file.name}</Text>
                            </div>

                            <div className="bill__content__prompt__item__files">
                              <a
                                download={`${file.name.replaceAll(' ', '-')}-Póliza-${policyNumber}.pdf`}
                                href={file.pdfBase64}
                              >
                                <span>{IconFactory.pdfFileIcon({ size: 22 })}</span>
                                <Text type="label" bold>PDF</Text>
                              </a>
                              <a
                                download={`${file.name.replaceAll(' ', '-')}-${policyNumber}.xml`}
                                href={file.xmlBase64}
                              >
                                <span>{IconFactory.xmlIcon({ size: 22 })}</span>
                                <Text type="label" bold>XML</Text>
                              </a>
                            </div>

                            <div className="bill__content__prompt__item__download">
                              <button type="button" onClick={() => {
                                visibilityStates.setOverlayVisible(true);
                                visibilityStates.setDownloadSheetVisible(true);
                                downloadStates.setSelectedDownloadFile(file);
                              }}>
                                {IconFactory.downloadIcon({ size: 26 })}
                              </button>
                            </div>

                          </div>
                        )
                      )
                    }

                    {
                      invoiceStatus.invoicesFile.find(i => i.isComplement) !== undefined &&
                      <>
                        <Text type="subtitle" bold className="bill__content__prompt__space-top">Complementos de pago</Text>

                        {
                          invoiceStatus.invoicesFile.filter(i => i.isComplement).map(
                            (file, index) => (
                              <div key={`invoice-file-${index}`} className="bill__content__prompt__item">
                                <div className="bill__content__prompt__item__icon bill__content__prompt__item__icon--green">
                                  <div className="bill__content__prompt__item__icon__background"></div>
                                  {IconFactory.file3Icon({ size: 22 })}
                                </div>

                                <div className="bill__content__prompt__item__name">
                                  <Text type="paragraph" bold>{file.name}</Text>
                                </div>

                                <div className="bill__content__prompt__item__files">
                                  <a
                                    download={`${file.name.replaceAll(' ', '-')}-Póliza-${policyNumber}.pdf`}
                                    href={file.pdfBase64}
                                  >
                                    <span>{IconFactory.pdfFileIcon({ size: 22 })}</span>
                                    <Text type="label" bold>PDF</Text>
                                  </a>
                                  <a
                                    download={`${file.name.replaceAll(' ', '-')}-${policyNumber}.xml`}
                                    href={file.xmlBase64}
                                  >
                                    <span>{IconFactory.xmlIcon({ size: 22 })}</span>
                                    <Text type="label" bold>XML</Text>
                                  </a>
                                </div>

                                <div className="bill__content__prompt__item__download">
                                  <button type="button" onClick={() => {
                                    visibilityStates.setOverlayVisible(true);
                                    visibilityStates.setDownloadSheetVisible(true);
                                    downloadStates.setSelectedDownloadFile(file);
                                  }}>
                                    {IconFactory.downloadIcon({ size: 26 })}
                                  </button>
                                </div>

                              </div>
                            )
                          )
                        }
                      </>
                    }
                  </div>

                  <div className={`bill__content__bottom-sheet ${bottomSheetAnimClass}`}>
                    <div className="bill__content__bottom-sheet__content">
                      {
                        downloadStates.selectedDowndloadFile &&
                        <div className="bill__content__bottom-sheet__content__files">
                          <Text type="title">{downloadStates.selectedDowndloadFile.name}</Text>
                          <Text type="paragraph">Selecciona el formato que deseas descargar.</Text>

                          <div className="bill__content__bottom-sheet__content__files__type">
                            <a
                              download={`${downloadStates.selectedDowndloadFile.name.replaceAll(' ', '-')}-Póliza-${policyNumber}.pdf`}
                              href={downloadStates.selectedDowndloadFile.pdfBase64}
                            >
                              <span>{IconFactory.pdfFileIcon({ size: 24 })}</span>
                              <Text type="label" bold>PDF</Text>
                            </a>
                            <a
                              download={`${downloadStates.selectedDowndloadFile.name.replaceAll(' ', '-')}-${policyNumber}.xml`}
                              href={downloadStates.selectedDowndloadFile.xmlBase64}
                            >
                              <span>{IconFactory.xmlIcon({ size: 24 })}</span>
                              <Text type="label" bold>XML</Text>
                            </a>
                          </div>

                          <div>
                            <Button
                              onClick={() => {
                                visibilityStates.setOverlayVisible(false);
                                visibilityStates.setDownloadSheetVisible(false);
                              }}
                            >
                              Cerrar
                            </Button>
                          </div>
                        </div>
                      }


                    </div>
                  </div>
                </>
              }

            </>
            :
            <Spinner color="main" circles />
        }
      </div>
    </div>
  );
};

export default BillPage;
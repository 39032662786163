import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Coverage } from "src/classes/plan/Coverage";
import { Plan } from "src/classes/plan/Plan";
import { PlanPayment } from "src/classes/plan/PlanPayment";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Util } from "src/utils/Util";
import { PlanManagementAction } from "../actions/plan-management.action";
import { PlanManagementSaga } from "../sagas/plan-management.saga";
import { PlanManagementSelector } from "../selectors/plan-management.selector";
import { PlanManagementSlice } from "../slices/plan-management.slice";
import { PlanCostFacade } from "./plan-cost.facade";

/**
 *
 */
export namespace PlanManagementFacade {

  export const usePlanManagement = (user: Person, vehicle: Vehicle, currentCoverages: Coverage[], currentCoupon?: string, isRenewal: boolean = false) => {
    useInjectReducer({ key: 'planManagement', reducer: PlanManagementSlice.reducer });
    useInjectSaga({ key: 'planManagement', saga: PlanManagementSaga.planManagementSaga });

    const plans = useSelector(PlanManagementSelector.policyPlans);
    const currentRecomendedPlan = useSelector(PlanManagementSelector.currentRecomendedPlan);
    const currentPlan = useSelector(PlanManagementSelector.currentPlan);
		const [selectedPlanPayment, setSelectedPlanPayment] = useState<PlanPayment>();
    const [recomendedPlan, setRecomendedPlan] = useState<Plan>();
    const [showPaymentTypeModal, setShowPaymentTypeModal] = useState<boolean>(false);
		const [defaultTerm, setDefaultTerm] = useState<{ id: number, value: string }>();
    const dispatch = useDispatch();

		/** */
		const setPaymentMethod = (paymentMethod: string): void => {
			if(currentPlan) {
				for(let i = 0; i < currentPlan.payments.length; i ++) {
					if(currentPlan.payments[i].type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL){
						let planPayment = currentPlan.payments[i];
						planPayment.selectedPaymentMethod = paymentMethod;
						setSelectedPlanPayment(planPayment);
						break;
					}
				}
			} else {
				const defaultPlan = plans[0];
				for(let i = 0; i < defaultPlan.payments.length; i ++) {
					if(defaultPlan.payments[i].type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL){
						let planPayment = defaultPlan.payments[i];
						planPayment.selectedPaymentMethod = paymentMethod;
						setSelectedPlanPayment(planPayment);
						break;
					}
				}
			}
		};
		
    /**
     * 
     * @param typeCostId 
     */
    const setPlanPayment = (typeCostId: number): void => {
      if (typeCostId === PlanCostFacade.PLAN_COST.ANNUAL.id) {
        for (let i = 0; i < currentPlan.payments.length; i++) {
          if (currentPlan.payments[i].type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) {
            let planPayment = currentPlan.payments[i]
            setSelectedPlanPayment(planPayment);
            break;
          }
        }
      } else if (typeCostId === PlanCostFacade.PLAN_COST.SUSCRIPTION.id) {
        for (let i = 0; i < currentPlan.payments.length; i++) {
          if (currentPlan.payments[i].type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) {
            let planPayment = currentPlan.payments[i]
            setSelectedPlanPayment(planPayment);
            break;
          }
        }
      } else {
        for (let i = 0; i < currentPlan.payments.length; i++) {
          if (currentPlan.payments[i].type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && ((typeCostId - 1) * 3) === currentPlan.payments[i].paymentsCount) {
            let planPayment = currentPlan.payments[i];
            setSelectedPlanPayment(planPayment);
            break;
          }
        }
      }

    }


    useEffect(
      () => {
        if (currentRecomendedPlan && !recomendedPlan) {
          setRecomendedPlan(currentRecomendedPlan);
        }
      }, [currentRecomendedPlan, recomendedPlan]
    )

    useEffect(
      () => {
        return () => {
          dispatch(PlanManagementAction.clearManagement());
        }
      }, [dispatch]
    )

    /**
     * 
     */
    const useMountEffect = () => useEffect(
      () => {
        if (vehicle && user) {
          dispatch(PlanManagementAction.planConfiguration({ vehicle: vehicle, user: user, isRenewal: isRenewal }));
          dispatch(PlanManagementAction.quotation({ vehicle: vehicle, user: user, coverages: currentCoverages, coupon: currentCoupon, loadAll: true, isRenewal: isRenewal }));
        }
      }, []
    );
    useMountEffect();

    return { plans, currentPlan, showPaymentTypeModal, setShowPaymentTypeModal, defaultTerm, setDefaultTerm, selectedPlanPayment, setPaymentMethod, setPlanPayment };
  }

  /**
   * 
   */
  export const usePlanSync = (cost?: boolean) => {
    const isLoading = useSelector(PlanManagementSelector.isLoadingPlan);
    const isUpdating = useSelector(PlanManagementSelector.isUpdatingPlan);
    const dispatch = useDispatch();

    /**
     * 
     */
    useEffect(
      () => {
        if (cost) {
          dispatch(PlanManagementAction.setUpdating(true));
          setTimeout(() => {
            dispatch(PlanManagementAction.setUpdating(false));
          }, 1000);
        }
      }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return { isLoading, isUpdating }
  }
}
import { CopyClipboard } from "src/components";
import { ProfileFacade } from "src/modules/profile/controller/facades/profile.facade";
import { Util } from "src/utils/Util";
import './ProfileInfo.scss';


/**
 *
 */
const ProfileInfoPage = (): JSX.Element => {

  const { user  } = ProfileFacade.useCurrentUser();

  return (
    <div id="profile-info-wrapper">
      <>
        {
          user &&
          <>
            <h1 className="text-center">Datos personales</h1>
            <div className="flex space-evenly align-center">
              <div className="referral-code text-center">
                <h2>Tu c&oacute;digo</h2>
                <p>
                  {user.profile?.referral?.code}
                  <CopyClipboard text={`${Util.CONSTANT.WEB_URL}?referral=${user.profile?.referral?.code!}`} message={'Has copiado el código.'} />
                </p>
              </div>
              <div className="referral-count text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                  <path d="M20.5819 22.3038C20.7206 22.309 20.8597 22.3125 21 22.3125C21.1301 22.3125 21.2596 22.3096 21.3887 22.3048C24.3265 22.3683 27.2158 23.1456 29.7687 24.5664C30.5605 25.007 31.5596 24.7225 32.0002 23.9307C32.4408 23.1389 32.1563 22.1398 31.3645 21.6992C30.1891 21.0452 28.9539 20.5062 27.6785 20.0871C30.3952 18.0504 32.1563 14.805 32.1563 11.1562C32.1563 5.00455 27.1514 0 21 0C14.8483 0 9.84379 5.00455 9.84379 11.1562C9.84379 14.8073 11.6068 18.0546 14.3263 20.0909C11.835 20.9109 9.49708 22.1866 7.45078 23.8695C3.69946 26.9553 1.09112 31.2594 0.106424 35.989C-0.202154 37.471 0.167627 38.993 1.1206 40.1652C2.06877 41.3313 3.47515 42 4.97863 42H17.8008C18.7067 42 19.4414 41.2656 19.4414 40.3594C19.4414 39.4532 18.7067 38.7188 17.8008 38.7188H4.97863C4.27816 38.7188 3.85583 38.3278 3.66613 38.0949C3.33897 37.6924 3.2124 37.1688 3.31878 36.6577C5.02253 28.4741 12.2445 22.4977 20.5819 22.3038ZM13.125 11.1562C13.125 6.81404 16.6575 3.28125 21 3.28125C25.3422 3.28125 28.875 6.81404 28.875 11.1562C28.875 15.3677 25.5521 18.8172 21.3906 19.0216C21.2602 19.0191 21.1301 19.0175 21 19.0175C20.8719 19.0175 20.744 19.0194 20.6162 19.0216C16.4515 18.821 13.125 15.37 13.125 11.1562ZM42 30.5015C42.0016 31.5974 41.5732 32.6273 40.7942 33.4021L37.7427 36.4366C37.4226 36.7551 37.0041 36.9141 36.586 36.9141C36.1646 36.9141 35.7432 36.7526 35.4225 36.4302C34.7835 35.7877 34.7864 34.7492 35.4289 34.1103L37.4742 32.0761H28.1768C26.3452 32.0761 24.8555 33.5662 24.8555 35.3974C24.8555 37.2287 26.3273 38.7188 28.1368 38.7188C29.0426 38.7188 29.7774 39.4532 29.7774 40.3594C29.7774 41.2656 29.0426 42 28.1368 42C26.3721 42 24.7187 41.3111 23.4815 40.0598C22.2513 38.8158 21.5743 37.1602 21.5743 35.3974C21.5743 31.7567 24.536 28.7949 28.1768 28.7949H37.3236L35.4321 26.9245C34.788 26.2872 34.7819 25.2483 35.4193 24.6042C36.0563 23.9602 37.0951 23.9541 37.7395 24.5914L40.7862 27.6042C41.5675 28.3767 41.9984 29.4056 42 30.5015Z" fill="black" />
                </svg>
                <h2>Total de mis referidos</h2>
                <p>{user.profile?.referral?.getCount()}</p>
              </div>
            </div>
            <div className="flex space-around">
              <div className="profile-field text-center flex-grow-100">
                <h2>Nombre</h2>
                <p>{user.name}</p>
              </div>
              <div className="profile-field text-center flex-grow-100">
                <h2>Apellido Paterno</h2>
                <p>{user.lastName}</p>
              </div>
              <div className="profile-field text-center flex-grow-100">
                <h2>Apellido Materno</h2>
                <p>{user.secondLastName}</p>
              </div>
            </div>
            <div className="flex space-around">
              <div className="profile-field text-center flex-grow-100">
                <h2>Fecha de nacimiento</h2>
                <p>{user.profile?.getBirthDate()}</p>
              </div>
              <div className="profile-field text-center flex-grow-100">
                <h2>Tel&eacute;fono</h2>
                <p>{user.profile?.getPhoneNumber()}</p>
              </div>
              <div className="profile-field text-center flex-grow-100">
                <h2>RFC</h2>
                <p>{user.profile?.rfc}</p>
              </div>
            </div>
            <div className="flex space-around">
              <div className="profile-field text-center flex-grow-100">
                <h2>Direcci&oacute;n</h2>
                <p>{user.profile?.getAddress()}</p>
              </div>
            </div>
          </>
        }
      </>
    </div>
  );
};

export default ProfileInfoPage;
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInjectSaga } from "redux-injectors";
import { Address } from "src/classes/Address";
import { Phone } from "src/classes/Phone";
import { Profile } from "src/classes/Profile";
import { Person  } from "src/classes/Person";
import { CoreLoginFeature } from "src/core/features/login.feature";
import { AlertFactory } from "src/factory/alert.factory";
import { INIT_PATH } from "src/routes/paths/init.paths";
import { QuoterAction } from "../actions/quoter.action";
import { QuoterFeature } from "../features/quoter.feature";
import { IUtmParams } from "../interfaces/IUtmParams";
import { QuoterSaga } from "../sagas/quoter.saga";

export namespace QuoterDataFacade {


  /** */
  export const useQuoterDataFacade = (incomingUtms?: IUtmParams) => {

		/** */
		useInjectSaga({ key: 'quoter', saga: QuoterSaga.quoterSaga });
		/** */
		const [modalShown, setModalShown] = useState<boolean>(false);
		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [isFormDone, setIsFormDone] = useState<boolean>(false);
		const [currentUser, setCurrentUser] = useState<Person>();
		const [email, setEmail] = useState<string>('');
		const [password, setPassword] = useState<string>('');
		const [incomingPerson, setInconmingPerson] = useState<Person>();
		/** */
		const { search } = useLocation();
		const searchParams = useMemo(() => new URLSearchParams(search), [search]);
		const name = searchParams.get('name');
		const zipCode = searchParams.get('zipcode');
		const mobilePhone = searchParams.get('mobilePhone');
		const referral = searchParams.get('referral');

		/** */
		const dispatch = useDispatch();
		const navigate = useNavigate();

		/** */
		const updateUser = (user: Person | undefined) => {
			if(user){
				if (!currentUser) {
					setCurrentUser(user);
				} else if (user){
					user.email = currentUser.email;
					user.password = currentUser.password;
					setCurrentUser(user);
				}
	
				if(currentUser && email && password && user)
					setIsFormDone(true);
				else 
					setIsFormDone(false);
			}else{
				setIsFormDone(false);
				setCurrentUser(undefined);
			}
		};

		/** */
		const updateAccountData = (email: string | undefined, password: string | undefined) => {
			setEmail(email!);
			setPassword(password!);

			if(currentUser && currentUser.name && email && password)
				setIsFormDone(true);
			else
				setIsFormDone(false);
		};

		/** */
		const registerUser = async(isValid: boolean) => {
			try {
				if(isValid){
					setModalShown(false);
					setIsLoading(true);
					currentUser!.email = email;
					currentUser!.password = password;
          
					await QuoterFeature.registerUserFeature(currentUser!, incomingUtms!, referral!);
					const token = await CoreLoginFeature.loginFeature(currentUser!);
	
					if(token!){
						navigate(`${INIT_PATH.INIT}${search}`);
						dispatch(QuoterAction.userLogin(token!));
					}
				}
			} catch (e) {
				AlertFactory.errorAlert((e as Error).message);
				setIsLoading(false);
			}
		};

		/** */
		useEffect(() => {
			if(name || zipCode || mobilePhone){
				let user = new Person('');
				user.profile = new Profile();
				user.profile.address = new Address();
				user.profile.phone = new Phone();
				user.profile.birthdate = null;
				user.profile.phone.countryCode = {
					code: '52'
				};

				if(name)
					user.name = name;
				if(zipCode)
					user.profile.address.postalCode = zipCode;
				if(mobilePhone)
					user.profile.phone.number = mobilePhone;

				setInconmingPerson(user);
			}
		}, [name, zipCode, mobilePhone]);

    return { modalShown, isLoading, isFormDone, incomingPerson, currentUser, setModalShown, updateUser, updateAccountData, registerUser };
  }
}
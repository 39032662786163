import { AxiosError } from "axios";
import { InsuranceVehicleService } from "../services/insurance-vehicle.service";
import { IErrorIsVehiclePicture } from "../interfaces/IErrorIsVehiclePicture";
import { InsuranceVehicleAdapter } from "../adapters/insurance-vehicle.adapter";
import { Util } from "src/utils/Util";



/**
 *
 */
export namespace InsuranceVehicleFeature {

  /**
   * 
   * @param token 
   * @param blob 
   */
  export const isVehiclePictureFeature = async (token: string, file: File): Promise<void> => {
    try {
      const request = InsuranceVehicleAdapter.isVehiclePictureAdapter(token, file)
      await InsuranceVehicleService.isVehiclePictureService(request);
    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error()
      error.name = '';
      if (axiosError.response && axiosError.response.data && axiosError.response.data.message) {
        const errorData = axiosError.response.data as IErrorIsVehiclePicture;
        console.error(errorData);
        if (errorData.message.includes(`blurry photo`)) {
          error.name = Util.STATUS_CODE.VEHICLE_PICTURE_ERROR.BLURRY;
        } else if (errorData.message.includes(`low resolution`)) {
          error.name = Util.STATUS_CODE.VEHICLE_PICTURE_ERROR.BLURRY;
        } else if (errorData.message.includes(`the photo is old`)) {
          error.name = Util.STATUS_CODE.VEHICLE_PICTURE_ERROR.DATE;
        } else {
          error.name = Util.STATUS_CODE.VEHICLE_PICTURE_ERROR.VEHICLE;
        }
      } else if (axiosError.response) { 
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }

  /**
   * 
   */
  export const uploadVehiclePicturesFeature = async (token: string, policyId: string, pictures: File[]): Promise<void> => {
    try {
      const request = InsuranceVehicleAdapter.uploadVehiclePicturesAdapter(token, policyId, pictures);
      await InsuranceVehicleService.uploadVehiclePicturesService(request);
    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }
}
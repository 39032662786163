import { ButtonBack } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { InsuranceInitFacade } from "src/modules/insurance-vehicle/controller/facades/insurance-init.facade";
import { Util } from "src/utils/Util";
import './InsuranceInit.scss';


/**
 *
 */
const InsuranceInitPage = (): JSX.Element => {
  const { STEPS, currentStepIndex, nextStep, progressValue, prevStepPath, transition, setTransition } = InsuranceInitFacade.useInsuranceInit();
  return (
    <div id="insurance-init-wrapper" className="relative height-100 width-100" onClick={() => setTransition(true)}>
      {
        prevStepPath() !== '' &&
        <ButtonBack to={prevStepPath()} overlay text replace />
      }

      <div className="insurance-info" style={{ transform: `translateX(${-100 * currentStepIndex}%)`, transition: transition? '350ms ease-out' : ''}}>

        <section className="inline-block width-100 border-box">
          <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_PICTURE_LIGHTS})` }}></span>
          <h1 className="text-center">Verifica la iluminaci&oacute;n y calidad</h1>
          <p className="text-center">Aseg&uacute;rate de que haya buena iluminaci&oacute;n y tus fotos tengan buen enfoque.</p>
        </section>

        <section className="inline-block width-100 border-box">
          <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_PICTURE_CAR})` }}></span>
          <h1 className="text-center">Sube los cristales del veh&iacute;culo</h1>
          <p className="text-center">Es necesario saber la condici&oacute;n de tus cristales, verifica que est&eacute;n arriba cuando tomes las fotos.</p>
        </section>

        <section className="inline-block width-100 border-box">
          <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_PICTURE_ITEMS})` }}></span>
          <h1 className="text-center">Evita tomar fotos a otros objetos</h1>
          <p className="text-center">Verifica que en tus fotos est&eacute; &uacute;nicamente tu veh&iacute;culo.</p>
        </section>

        <section className="inline-block width-100 border-box">
          <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_PICTURE_PLATES})` }}></span>
          <h1 className="text-center">Incluye las placas de tu veh&iacute;culo</h1>
          <p className="text-center">Es necesario que se pueda visualizar claramente la placa de tu veh&iacute;culo.</p>
        </section>
      </div>

      <div className="carousel flex justify-center">
        {
          STEPS.map(
            (s, index) => {
              return <span className="block" key={s} is-active={index === currentStepIndex ? '' : null}></span>
            }
          )
        }
      </div>


      <div className="insurance-info-button absolute width-100 flex justify-center align-center">
        <span className={`progress-${progressValue}`}></span>
        <button type="button" className="absolute flex align-center justify-center" onClick={(e) => { e.preventDefault(); nextStep() }}>
          {
            currentStepIndex + 1 === STEPS.length ?
              IconFactory.cameraIcon()
              :
              IconFactory.rightArrowIcon()
          }
        </button>
      </div>


    </div>
  );
};

export default InsuranceInitPage;
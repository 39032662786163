import { Util } from "src/utils/Util";

export class FileAttachment {
	/**
	 * 
	 */
	private _uid: string;
	set uid(_uid: string) { this._uid = _uid };
	get uid(): string { return this._uid };
  /**
   *
   */
  private _title: string;
  set title(_title: string) { this._title = _title };
  get title(): string { return this._title };
  /**
   *
   */
  private _source: string;
  set source(_source: string) { this._source = _source };
  get source(): string { return this._source };
  /**
   *
   */
  private _status: number;
  set status(_status: number) { this._status = _status };
  get status(): number { return this._status };
  /**
   * 
   */
  private _serviceId: string | undefined;
  set serviceId(_serviceId: string | undefined) { this._serviceId = _serviceId };
  get serviceId(): string | undefined { return this._serviceId };
  /**
   *
   */
  private _name: string | undefined;
  set name(_name: string | undefined) { this._name = _name };
  get name(): string | undefined { return this._name };
  /**
   * Type location path: src/utils/resources/key.
   */
  private _type: string | undefined;
  set type(_type: string | undefined) { this._type = _type };
  get type(): string | undefined { return this._type };
	/** 
	 * 
	 */
	private _contentType: string;
	set contentType(_contentType: string) { this._contentType = _contentType };
	get contentType(): string { return this._contentType };
  /**
   *
   */
  private _thumbnail: string;
  set thumbnail(_thumbnail: string) { this._thumbnail = _thumbnail };
  get thumbnail(): string { return this._thumbnail };

  constructor(_source?: string, _type?: string, _name?: string, _title?: string, _serviceId?: string) {
		this._uid = '';
    this._title = _title ? _title : '';
    this._source = _source ? _source : '';
    this._type = _type;
    this._name = _name;
    this._serviceId = _serviceId;
    this._status = -1;
    this._thumbnail = '';
		this._contentType = '';
  }

  /**
   * 
   */
  public isPeding(): boolean {
    return this._status === Util.STATUS_CODE.CLAIM_PICTURE_STATUS.PEDING;
  }

  /**
   * 
   */
  public isApproved(): boolean {
    return this._status === Util.STATUS_CODE.CLAIM_PICTURE_STATUS.APPROVED;
  }
  /**
   * 
   */
  public isDisapproved(): boolean {
    return this._status === Util.STATUS_CODE.CLAIM_PICTURE_STATUS.DISAPPROVED;
  }
}
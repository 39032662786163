import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { INSURANCE_VEHICLE_PATH } from "src/routes/paths/insurance-vehicle.paths";
import { InsuranceVehicleAction } from "../actions/insurance-vehicle.action";
import { InsuranceVehicleSaga } from "../sagas/insurance-vehicle.saga";
import { InsuranceVehicleSelector } from "../selectors/insurance-vehicle.selector";
import { InsuranceVehicleSlice } from "../slices/insurance-vehicle.slice";

/**
 *
 */
export namespace InsuranceVehicleFacade {

  export const useInsuranceVehicle = () => {
    useInjectReducer({ key: 'insuranceVehicle', reducer: InsuranceVehicleSlice.reducer });
		useInjectSaga({ key: 'insuranceVehicle', saga: InsuranceVehicleSaga.insuranceVehicleSaga });
    const isPicturesCompleted = useSelector(InsuranceVehicleSelector.isPicturesCompleted);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
  
    useEffect(
      () => {
        if (location.pathname === INSURANCE_VEHICLE_PATH.INSURANCE) {
          navigate(`${INSURANCE_VEHICLE_PATH.INIT}${location.search}`, {replace: true})
        }
      }, [location, navigate]
    );

    useEffect(
      () => {
        if (isPicturesCompleted && location.pathname !== INSURANCE_VEHICLE_PATH.COMPLETION) {
          navigate(`/`, {replace: true})
        }
      }, [location, isPicturesCompleted, navigate]
    );

    useEffect(
      () => {
        return () => { dispatch(InsuranceVehicleAction.clear()) }
      }, [dispatch]
    )
  }

}
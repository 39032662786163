import { Button } from "src/components";
import { IconFactory } from 'src/factory/icon.factory';
import { LoginSendValidationFacade } from 'src/modules/login/controller/facades/login-send-validation.facade';
import { Util } from 'src/utils/Util';
import './LoginEmailValidation.scss';

/**
 *
 */
interface ILoginEmailValidation {

  email: string;

  token: string;

  onValidate: () => void;

  onClose: () => void;

  isLoading: boolean;
}

/**
 *
 */
const LoginEmailValidation = (props: ILoginEmailValidation): JSX.Element => {
  const { sendEmail, isSending } = LoginSendValidationFacade.useSendEmail()
  return (
    <div className="login-email-validation-wrapper relative">
      <button type="button" className="absolute" onClick={(e) => {e.preventDefault(); props.onClose()}}>
        {IconFactory.closeIcon()}
      </button>
      <span className="background-image block" style={{ backgroundImage: `url(${Util.ASSET.VALIDATE_EMAIL})` }}></span>
      <h2 className="text-center">
        Hola, es necesario que <b>valides tu correo electr&oacute;nico</b> para continuar.
      </h2>
      <h3 className="text-center">
        Revisa el correo que se ha enviado a la direcci&oacute;n <br /> <b>{props.email}</b>.
      </h3>
      <div className="flex column">
        <p className="text-center">Cuando lo hayas validado, pulsa el bot&oacute;n <br /><b>"Ya valid&eacute; mi correo electr&oacute;nico"</b></p>
        <Button type="button"
          loading={props.isLoading}
          disabled={isSending || props.isLoading}
          onClick={(e) => {e.preventDefault(); props.onValidate()}}>
          Ya valid&eacute; mi correo electr&oacute;nico
        </Button>
        <Button type="button" fill="none"
          disabled={isSending || props.isLoading}
          onClick={(e) => {e.preventDefault(); sendEmail(props.token)}}>
          No me ha llegado ning&uacute;n correo
        </Button>
      </div>
    </div>
  );
};

export default LoginEmailValidation;
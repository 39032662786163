import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AGREED_PAYMENTS_LIST_PATH } from "src/routes/paths/agreed-payments-list.paths";
import { CLAIM_PAYMENTS_LIST_PATH } from "src/routes/paths/claim-payments-list.paths";
import { DASHBOARD_PATH } from "src/routes/paths/dashboard.paths";

/**
 *
 */
export namespace InitFacade {

  const QUERY_PARAMS = {
    CLAIMS: {
      KEY: 'dc',
      VALUE: 'damage-claim'
    },
		AGREED: {
			KEY: 'dc',
			VALUE: 'agreed-claim'
		}
  }

  const TIMER = {
    LOADER_TIMER: 2,
    REDIRECT_TIMER: 2
  }

  export const useInitRedirect = () => {
    const [init, setInit] = useState<boolean>(false);
    const [hasQueryParam, setHasQueryParam] = useState<boolean>(false);
    const [loaderCount, setLoaderCount] = useState<number>(0);
    const [redirectCount, setRedirectCount] = useState<number>(0);
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const navigate = useNavigate();

    /**
     * 
     */
    const dashboardNavigation = useCallback(
      () => {
        if (redirectCount >= TIMER.REDIRECT_TIMER) {
          navigate(DASHBOARD_PATH.DASHBOARD, { replace: true });
        }
      }, [navigate, redirectCount]
    );

    /**
     * 
     */
    const claimNavigation = useCallback(
      () => {
        if (redirectCount >= TIMER.REDIRECT_TIMER) {
          navigate(CLAIM_PAYMENTS_LIST_PATH.CLAIM_PAYMENTS_LIST, { replace: true });
        }
      }, [navigate, redirectCount]
    );

		/**
     * 
     */
		const agreedNavigation = useCallback(
      () => {
        if (redirectCount >= TIMER.REDIRECT_TIMER) {
          navigate(AGREED_PAYMENTS_LIST_PATH.AGREED_PAYMENTS_LIST, { replace: true });
        }
      }, [navigate, redirectCount]
    );

    /**
     * 
     */
    const verifyClaimParam = useCallback(
      () => {
        try {
          const claimsRedirect = query.get(QUERY_PARAMS.CLAIMS.KEY);
          if (claimsRedirect && atob(claimsRedirect) === QUERY_PARAMS.CLAIMS.VALUE) {
            claimNavigation();
          } else if (claimsRedirect && atob(claimsRedirect) === QUERY_PARAMS.AGREED.VALUE) {
						agreedNavigation();
					} else {
            throw new Error();
          }
        } catch {
          dashboardNavigation();
        }
      }, [query, dashboardNavigation, agreedNavigation, claimNavigation]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (query.get(QUERY_PARAMS.CLAIMS.KEY)) {
          setHasQueryParam(true);
          verifyClaimParam()
        }
      }, [query, verifyClaimParam]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (init) {
          if (redirectCount < TIMER.REDIRECT_TIMER) {
            setTimeout(() => {
              setRedirectCount(redirectCount + 1);
            }, 1000);
          } else {
            if (!hasQueryParam) {
              dashboardNavigation()
            }
          }
        }
      }, [init, redirectCount, hasQueryParam, dashboardNavigation]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (loaderCount < TIMER.LOADER_TIMER) {
          setTimeout(() => {
            setLoaderCount(loaderCount + 1);
          }, 1000);
        } else if (!init) {
          setInit(true);

        }
      }, [loaderCount, init]
    );

    return { init, redirectCount }
  }
}
import { AxiosError } from "axios";
import { Person  } from "src/classes/Person";
import { Util } from "src/utils/Util";
import { DriverDataAdapter } from "../adapters/driver-data.adapter";
import { IPersonStorage } from "../interfaces/IPersonStorage";
import { PolicyDriverService } from "../services/policy-driver.service";

export namespace PolicyDriverFeature {

  /**
   * 
   * @param person 
   */
  export const createDriverFeature = async (newDriver: Person): Promise<string | undefined> => {
    try {
      const request = DriverDataAdapter.driverCreationRequestAdapter(newDriver);
      const { data } = await PolicyDriverService.createDriverService(request);
      localStorage.setItem(Util.KEY.POLICY_NEW_DRIVER, JSON.stringify(DriverDataAdapter.driverStorageAdapter(newDriver)));
      return data.id;

    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }

  /**
   * 
   */
  export const getDriverStoredFeature = (): Person | undefined => {
    const storedData: string = localStorage.getItem(Util.KEY.POLICY_NEW_DRIVER) as string;
    if (storedData) {
      const driver: Person = DriverDataAdapter.driverStorageAdapter(JSON.parse(storedData) as IPersonStorage) as Person;
      return driver;
    } else {
      return undefined;
    }

  }
}
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ISidebarPath } from "../interfaces/ISidebarPath";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFacade } from "./claim-payments.facade";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";

export namespace ClaimPaymentsStepsFacade {

  /** */
  const paths: ISidebarPath = {
    personalData: [
      CLAIM_PAYMENTS_PATH.ONBOARDING,
      CLAIM_PAYMENTS_PATH.POLICY_DATA,
      CLAIM_PAYMENTS_PATH.PERSONAL_DATA,
      CLAIM_PAYMENTS_PATH.VEHICLE_DATA,
			AGREED_PAYMENTS_PATH.ONBOARDING,
			AGREED_PAYMENTS_PATH.POLICY_DATA,
      AGREED_PAYMENTS_PATH.PERSONAL_DATA,
      AGREED_PAYMENTS_PATH.VEHICLE_DATA
    ],
    vehiclePictures: [
      CLAIM_PAYMENTS_PATH.VEHICLE_PICTURES
    ],
    documents: [
      CLAIM_PAYMENTS_PATH.ID_DOCUMENTS,
			AGREED_PAYMENTS_PATH.ID_DOCUMENTS
    ],
    accountAndBill: [
      CLAIM_PAYMENTS_PATH.BANK_ACCOUNT,
      CLAIM_PAYMENTS_PATH.VEHICLE_BILL,
      CLAIM_PAYMENTS_PATH.ATTORNEY_LETTER,
			AGREED_PAYMENTS_PATH.BANK_ACCOUNT,
      AGREED_PAYMENTS_PATH.VEHICLE_BILL,
      AGREED_PAYMENTS_PATH.ATTORNEY_LETTER
    ]
  };


  /**
   * 
   */
  export const useStepStatus = () => {
    /** */
    const { currentDamageClaim, currentPath, claimVehicle, claimUser } = ClaimPaymentsFacade.useDamageClaimActors();
    /** */
    const dispatch = useDispatch();
		const location = useLocation();

		/** */
		const checkDataStep = useCallback(() => {
      const vehicleCondition = claimVehicle?.brand?.name && claimVehicle?.model?.name && claimVehicle?.year?.value && claimVehicle?.plate;
      const userCondition = claimUser?.email && claimUser?.name && claimUser?.lastName && claimUser.profile?.phone?.number;

      if (vehicleCondition && userCondition)
        dispatch(ClaimPaymentsAction.updateDataStep(1));
    }, [claimVehicle, claimUser, dispatch]);

		/** */
		const checkVehicleStep = useCallback(() => {
      const vehicleCondition = currentDamageClaim?.vehicle.vin.length! > 0 ? true : false;
      const damageClaimCondition = currentDamageClaim?.attachmentVehicle[0].source.length! > 0 && currentDamageClaim?.attachmentVehicle[1].source.length! > 0
        && currentDamageClaim?.attachmentVehicle[2].source.length! > 0
        && currentDamageClaim?.attachmentVehicle[3].source.length! > 0
        && currentDamageClaim?.attachmentVehicle[4].source.length! > 0
        && currentDamageClaim?.attachmentDamage[0].source.length! > 0;

			const notAllAttachmentsCondition = currentDamageClaim?.attachmentVehicle[0].source.length! > 0 || currentDamageClaim?.attachmentVehicle[1].source.length! > 0
				|| currentDamageClaim?.attachmentVehicle[2].source.length! > 0
				|| currentDamageClaim?.attachmentVehicle[3].source.length! > 0
				|| currentDamageClaim?.attachmentVehicle[4].source.length! > 0
				|| currentDamageClaim?.attachmentDamage[0].source.length! > 0;

			const anyDeniedCondition = currentDamageClaim?.attachmentVehicle[0].status === 2 || currentDamageClaim?.attachmentVehicle[1].status === 2
				|| currentDamageClaim?.attachmentVehicle[2].status === 2 || currentDamageClaim?.attachmentVehicle[3].status === 2
				|| currentDamageClaim?.attachmentVehicle[4].status === 2 || currentDamageClaim?.attachmentDamage[0].status === 2
				|| currentDamageClaim?.attachmentDamage[1].status === 2 || currentDamageClaim?.attachmentDamage[2].status === 2
				|| currentDamageClaim?.attachmentDamage[3].status === 2 || currentDamageClaim?.attachmentDamage[4].status === 2
				|| currentDamageClaim?.attachmentDamage[5].status === 2;

			const pathCondition: boolean = currentDamageClaim! && (paths.documents.indexOf(location.pathname) >= 0 || paths.accountAndBill.indexOf(location.pathname) >= 0 || CLAIM_PAYMENTS_PATH.COMPLETION === location.pathname);

			if(anyDeniedCondition)
				dispatch(ClaimPaymentsAction.updatePicturesStep(3))
			else if (vehicleCondition && damageClaimCondition)
				dispatch(ClaimPaymentsAction.updatePicturesStep(1));
			else if(notAllAttachmentsCondition || vehicleCondition || pathCondition)
				dispatch(ClaimPaymentsAction.updatePicturesStep(2));
    }, [currentDamageClaim, location, dispatch]);

		/** */
		const checkDocumentsStep = useCallback(() => {
			const documentsCondition = currentDamageClaim?.attachmentIdentification[0].source.length! > 0
				&& currentDamageClaim?.attachmentIdentification[1].source.length! > 0
				&& currentDamageClaim?.attachmentIdentification[2].source.length! > 0;

			const notAllDocumentsCondition = currentDamageClaim?.attachmentIdentification[0].source.length! > 0
				|| currentDamageClaim?.attachmentIdentification[1].source.length! > 0
				|| currentDamageClaim?.attachmentIdentification[2].source.length! > 0;

			const atLeastOneDeniedCondition = currentDamageClaim?.attachmentIdentification[0].status === 2
				|| currentDamageClaim?.attachmentIdentification[1].status === 2
				|| currentDamageClaim?.attachmentIdentification[2].status === 2;

			const pathCondition: boolean = currentDamageClaim! && (paths.accountAndBill.indexOf(location.pathname) >= 0 || CLAIM_PAYMENTS_PATH.COMPLETION === location.pathname);

			if(atLeastOneDeniedCondition)
				dispatch(ClaimPaymentsAction.updateDocumentsStep(3));
			else if (documentsCondition)
				dispatch(ClaimPaymentsAction.updateDocumentsStep(1));
			else if(notAllDocumentsCondition || pathCondition)
				dispatch(ClaimPaymentsAction.updateDocumentsStep(2));

    }, [currentDamageClaim, dispatch, location])

		/** */
    const checkAccountStep = useCallback(() => {
      const accountCondition = currentDamageClaim?.attachmentAccountStatus.source.length! > 0
				&& (currentDamageClaim?.attachmentBill.source.length! > 0 || currentDamageClaim?.attachmentLegal.source.length! > 0);

			const notAllAccountCondition = currentDamageClaim?.attachmentAccountStatus.source.length! > 0
				|| (currentDamageClaim?.attachmentBill.source.length! > 0 || currentDamageClaim?.attachmentLegal.source.length! > 0);

			const atLeastOneDeniedCondition = currentDamageClaim?.attachmentBill.status === 2 || currentDamageClaim?.attachmentLegal.status === 2
				|| currentDamageClaim?.attachmentAccountStatus.status === 2

			const pathsCondition: boolean = currentDamageClaim! && (CLAIM_PAYMENTS_PATH.COMPLETION === location.pathname)

			if(atLeastOneDeniedCondition)
				dispatch(ClaimPaymentsAction.updateAccountStep(3))
			else if(accountCondition)
				dispatch(ClaimPaymentsAction.updateAccountStep(1));
			else if(notAllAccountCondition || pathsCondition)
				dispatch(ClaimPaymentsAction.updateAccountStep(2));

    }, [currentDamageClaim, dispatch, location]);

		/** */
		const checkProposal = useCallback(() => {
      const curr = currentDamageClaim?.proposal?.id;
      if (curr) {
        dispatch(ClaimPaymentsAction.updateProposal(true));
      } else {
        dispatch(ClaimPaymentsAction.updateProposal(false));
      }
    }, [currentDamageClaim, dispatch])

		/** */
    useEffect(() => {
      checkDataStep();
    }, [currentDamageClaim, claimVehicle, claimUser, currentPath, checkDataStep])

		useEffect(() => {
			checkVehicleStep();
    }, [currentDamageClaim, claimUser, claimVehicle, currentPath, checkVehicleStep])

		useEffect(() => {
      checkDocumentsStep();
    }, [currentDamageClaim, claimUser, currentPath, checkDocumentsStep])

		useEffect(() => {
      checkAccountStep();
    }, [currentDamageClaim, claimUser, currentPath, checkAccountStep])

    useEffect(() => {
      checkProposal();
    }, [currentDamageClaim, claimUser, currentPath, checkProposal])
  }

  export const useMatchSteps = () => {
    const [hideProposal, setHideProposal] = useState<boolean>(true);
    const location = useLocation();

    /** Compares locations and marks true if correct */
    const matchPaths = (): number => {
      let _match: boolean = false;
      let _value: number = 0;

      Object.keys(paths).forEach((_key, index) => {
        (paths as any)[_key].forEach((_path: string) => {
          if (!_match) {
            _match = _match || location.pathname.includes(_path);
            _value = index;
          }
        });
      });

			if(!_match)
				_value = -1;
      return _value;
    };

    useEffect(
      () => {
        if (location.pathname.includes(CLAIM_PAYMENTS_PATH.PROPOSAL) || location.pathname.includes(CLAIM_PAYMENTS_PATH.COMPLETION) || 
				location.pathname.includes(AGREED_PAYMENTS_PATH.PROPOSAL) || location.pathname.includes(AGREED_PAYMENTS_PATH.COMPLETION)) {
          setHideProposal(true);
        } else {
          setHideProposal(false);
        }
      }, [location]
    )

    const currentStep = matchPaths();

    return { currentStep, hideProposal }
  }
}
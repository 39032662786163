import { Button, Checkbox, Modal, SpinnerCheckout, InputCard, InputLabel, ButtonBack, Radio } from "src/components";
import { Util } from "src/utils/Util";
import { Plan } from "src/classes/plan/Plan";
import { Policy } from "src/classes/Policy";
import { PlanPaymentManagementFormFacade } from "../../controller/facades/plan-payment-management-form.facade";
import { PlanPaymentManagementFacade } from "../../controller/facades/plan-payment-management.facade";
import PaymentOption from "src/libs/PlanPaymentManagement/ui/molecules/PaymentOption/PaymentOption";
import { IconFactory } from "src/factory/icon.factory";
import useCollapse from "react-collapsed";

import './PlanPaymentManagement.scss';

/**
 *
 */
interface IPlanPaymentManagementProps {
	/** */
	plan: Plan;
	/** */
	onPaymentDone: (policy: Policy) => void;
	/** */
	onReturn?: () => void;
	/** */
	policy?: Policy;
	/** */
	picturesPrompt?: boolean;
	/** */
	hideButtons?: boolean;
	/** */
	type?: 'default' | 'quoter';
}

export const PAYMENT_METHOD = {
	DEBIT: { name: 'Débito', code: 1 },
	CREDIT: { name: 'Crédito', code: 2 },
}

export const PAYMENT_TERMS = {
	ANNUAL: 0,
	SUSCRIPTION: 1,
	THREE_PAYMENTS: 1,
	SIX_PAYMENTS: 2,
	NINE_PAYMENTS: 3,
	TWELVE_PAYMENTS: 4,
}

/**
 *
 */
const PlanPaymentManagement = (props: IPlanPaymentManagementProps): JSX.Element => {

	const { plan, onPaymentDone, onReturn, policy, hideButtons, type } = props;
	const {
		onPaymentChange,
		showDetailsModal,
		setShowDetailsModal,
		paymentCost,
		isCardCompleted,
		isCompletingCheckout,
		initCheckout,
		setCheckoutOwner,
		setCheckoutCard,
		showPicturesPrompt,
		setShowPicturesPrompt,
		showRenewalModal,
		setShowRenewalModal,
		isAutomaticRenewal,
		setIsAutomaticRenewal,
		selectedPlanType,
	} = PlanPaymentManagementFacade.usePlanManagementPayment(plan, policy, onPaymentDone);
	const { register, formState } = PlanPaymentManagementFormFacade.usePlanPaymentManagementForm();

	const summaryCollapse = useCollapse({ duration: 250 });
	return (
		<>
			<div className="plan-payment height-100 flex column">
				{
					isCompletingCheckout &&
					<SpinnerCheckout />
				}

				{
					onReturn &&
					<ButtonBack button text
						to={''}
						onClick={onReturn}
					/>
				}

				<section>
					<h1 className="section-title">Pago de p&oacute;liza</h1>
				</section>

				<section className="content flex">
					<div className="payments-wrapper flex-grow-100">
						<section>
							<div>
								<div className="credit-content">

									<p>1. Completa tus datos de pago</p>

									<form>
										<InputLabel
											autoComplete="off"
											required
											{...register("owner", { required: true, pattern: Util.PATTERN.NAMES, minLength: 2, onChange: (e) => setCheckoutOwner(e.target.value) })}
										>
											Nombre del titular
										</InputLabel>
									</form>

									<div className="payment-iframe-wrapper">
										<InputCard onComplete={setCheckoutCard} />
									</div>

									<p>2. Selecciona c&oacute;mo quieres pagar</p>

									<PaymentOption
										planType={selectedPlanType} plan={plan} onChange={onPaymentChange}
									/>

								</div>
							</div>
						</section>
					</div>

					<aside className={`${type}`}>
						<section className="amounts">
							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL &&
								<div>
									<p className="text-center">Total a pagar</p>

									<h3 className="text-center">{paymentCost.getTotal()}</h3>
								</div>
							}

							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION &&
								<div>
									<p className="text-center small">
										*1er Pago 1er mes de {plan.discount ? plan.discount.subscription.firstCost.getNetAmount() : plan.subscription.firstCost.getNetAmount()} <br /> + Responsabilidad Civil {plan.discount ? plan.discount.subscription.firstCost.getCivilTaxAmount() : plan.subscription.firstCost.getCivilTaxAmount()}
										<br /> + Tarifa {plan.discount ? plan.discount.subscription.firstCost.getMonthFeeAmount() : plan.subscription.firstCost.getMonthFeeAmount()} + Costo de expedición* {plan.discount ? Util.TRANSFORM.CURRENCY.MXN(plan.discount.subscription.firstCost.expeditionRights) : Util.TRANSFORM.CURRENCY.MXN(plan.subscription.firstCost.expeditionRights)} + IVA {plan.discount ? plan.discount.subscription.firstCost.getTaxes() : plan.subscription.firstCost.getTaxes()}
									</p>

									<h3 className="text-center">{plan.discount ? plan.discount.subscription.firstCost.getTotal() : plan.subscription.firstCost.getTotal()}</h3>
									<p className="text-center">*Pago inicial 1ra cuota</p>

									<h3 className="text-center">{plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal()}</h3>
									<p className="text-center">*Por pagar 11 cuotas</p>
								</div>
							}

							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS &&
								<div className="monthly-payments">
									<p className="text-center">{paymentCost.monthsQuantity} pagos de</p>
									<h3 className="text-center">{paymentCost.getAmountByMonth() ? paymentCost.getAmountByMonth() : Util.TRANSFORM.CURRENCY.MXN(0)}</h3>
									<p className="text-center total">Total a pagar <span>{paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0)}</span></p>

									{
										paymentCost.monthsQuantity === 0 &&
										<p className="text-center months-error">Selecciona el plazo a meses.</p>
									}
								</div>
							}
						</section>

						<button className="split-button" {...summaryCollapse.getToggleProps()} is-expanded={summaryCollapse.isExpanded ? '' : null}>
							<p>Desglose del monto</p>
							{IconFactory.dropDownArrowIcon()}
						</button>

						<section className="summary">
							<div className="container" {...summaryCollapse.getCollapseProps()}>

								{
									(selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL || selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS) &&
									<>
										<h2 className="text-center">Resumen de tu plan seleccionado</h2>
										<ul>
											<li>
												<span>Prima neta</span>
												<span>{paymentCost.getNetAmount()}</span>
											</li>

											<li>
												<span>Costo de expedici&oacute;n</span>
												<span>{paymentCost.getExpeditionRights()}</span>
											</li>

											<li>
												<span>Responsabilidad Civil</span>
												<span>{paymentCost.getCivilTaxAmount()}</span>
											</li>


											{
												selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS &&
												<li className="monthly-item flex column">
													<div className="flex">
														<span>Pago diferido ({paymentCost.getFeePercent()})</span>
														<span>{paymentCost.getMonthFeeAmount()}</span>
													</div>
												</li>
											}

											<li>
												<span>Subtotal</span>
												<span>{paymentCost.getSubTotal()}</span>
											</li>

											<li>
												<span>IVA (16%)</span>
												<span>{paymentCost.getTaxes()}</span>
											</li>

											{
												selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS &&
												<li>
													<span>Total por mes</span>
													<span>{paymentCost.getAmountByMonth()}</span>
												</li>
											}

											<li>
												<span>Total</span>
												<span>{paymentCost.getTotal()}</span>
											</li>

											<p className="reimbursementText">* Este monto no será reembolsado en caso de cancelación anticipada.</p>
										</ul>
									</>
								}

								{
									selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION &&
									<>
										<h2 className="text-center">Resumen de primer pago</h2>

										<ul>
											<li>
												<span>Prima neta</span>
												<span>{plan.discount ? plan.discount.subscription.firstCost.getNetAmount() : plan.subscription.firstCost.getNetAmount()}</span>
											</li>

											<li>
												<span>Responsabilidad Civil</span>
												<span>{plan.discount ? plan.discount.subscription.firstCost.getCivilTaxAmount() : plan.subscription.firstCost.getCivilTaxAmount()}</span>
											</li>

											<li>
												<span>Pago diferido*</span>
												<span>{plan.discount ? plan.discount.subscription.firstCost.getMonthFeeAmount() : plan.subscription.firstCost.getMonthFeeAmount()}</span>
											</li>

											<li>
												<span>Costo de expedición*</span>
												<span>{plan.discount ? Util.TRANSFORM.CURRENCY.MXN(plan.discount.subscription.firstCost.expeditionRights) : Util.TRANSFORM.CURRENCY.MXN(plan.subscription.firstCost.expeditionRights)}</span>
											</li>

											<li>
												<span>Subtotal</span>
												<span>{plan.discount ? plan.discount.subscription.firstCost.getSubTotal() : plan.subscription.firstCost.getSubTotal()}</span>
											</li>

											<li>
												<span>IVA (16%)</span>
												<span>{plan.discount ? plan.discount.subscription.firstCost.getTaxes() : plan.subscription.firstCost.getTaxes()}</span>
											</li>

											<li className="total">
												<span>Total</span>
												<span>{plan.discount ? plan.discount.subscription.firstCost.getTotal() : plan.subscription.firstCost.getTotal()}</span>
											</li>
										</ul>

										<h2 className="text-center second-title">Resumen de los siguientes 11 pagos</h2>

										<ul>
											<li>
												<span>Prima neta</span>
												<span>{plan.discount ? plan.discount.subscription.cost.getNetAmount() : plan.subscription.cost.getNetAmount()}</span>
											</li>

											<li>
												<span>Pago diferido*</span>
												<span>{plan.discount ? plan.discount.subscription.cost.getMonthFeeAmount() : plan.subscription.cost.getMonthFeeAmount()}</span>
											</li>

											<li>
												<span>Subtotal</span>
												<span>{plan.discount ? plan.discount.subscription.cost.getSubTotal() : plan.subscription.cost.getSubTotal()}</span>
											</li>

											<li>
												<span>IVA (16%)</span>
												<span>{plan.discount ? plan.discount.subscription.cost.getTaxes() : plan.subscription.cost.getTaxes()}</span>
											</li>

											<li className="total">
												<span>Total</span>
												<span>{plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal()}</span>
											</li>

											<p className="reimbursementText">* Este monto no será reembolsado en caso de cancelación anticipada.</p>
										</ul>
									</>
								}
							</div>

							<div className="bottom-section text-center">
								<form>
									<Checkbox
										{...register("acceptResourcesOwner", { required: true })}
									>
										Declaro que soy el propietario de los recursos
									</Checkbox>
									<Checkbox
										{...register("acceptDigitalCover", { required: true })}
									>
										Estoy de acuerdo con recibir mi carátula de póliza en formato digital .PDF*
									</Checkbox>
								</form>

								<Button type="button"
									disabled={!formState.isValid || !isCardCompleted || isCompletingCheckout}
									onClick={() => setShowRenewalModal(true)}>
									Pagar
								</Button>
							</div>
						</section>
					</aside>
				</section>

				<section>
					<h3 className="salvage-message">*Si no está de acuerdo con recibir la carátula en formato digital, póngase en contacto con nuestro <b>centro de atención.</b></h3>
				</section>

				{
					!hideButtons &&
					<section className={`button-section plan-management-bottom-section ${type}`}>
						<div className="mobile-price-details">
							<p className="details" onClick={() => setShowDetailsModal(true)}>Ver detalles</p>

							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL &&
								<div>
									<p className="text-center">Total a pagar</p>

									<h2 className="text-center">{paymentCost.getTotal()}</h2>
								</div>
							}

							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION &&
								<div className="subscription flex">
									<div className="flex column">
										<h2 className="text-center">{plan.discount ? plan.discount.subscription.firstCost.getTotal() : plan.subscription.firstCost.getTotal()}</h2>
										<p className="text-center">*Pago inicial 1ra cuota</p>
									</div>

									<div className="flex column">
										<h2 className="text-center">{plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal()}</h2>
										<p className="text-center">*Por pagar 11 cuotas</p>
									</div>
								</div>
							}

							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS &&
								<div>
									<p className="text-center">{paymentCost.monthsQuantity} pagos de</p>
									<h3 className="text-center">{paymentCost.getAmountByMonth() ? paymentCost.getAmountByMonth() : Util.TRANSFORM.CURRENCY.MXN(0)}</h3>
									<p className="text-center">Total a pagar <span>{paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0)}</span></p>

									{
										paymentCost.monthsQuantity === 0 &&
										<p className="text-center months-error">Selecciona el plazo a meses.</p>
									}
								</div>
							}
						</div>

						<form>
							<Checkbox
								{...register("acceptResourcesOwner", { required: true })}
							>
								Declaro que soy el propietario de los recursos
							</Checkbox>

							<Checkbox
								{...register("acceptDigitalCover", { required: true })}
							>
								Estoy de acuerdo con recibir mi carátula de póliza en formato digital .PDF*
							</Checkbox>
						</form>

						<div className="button-wrapper flex align-center space-around border-box">
							<Button type="button"
								disabled={!formState.isValid || !isCardCompleted || isCompletingCheckout}
								onClick={() => setShowRenewalModal(true)}
							>
								Pagar
							</Button>
						</div>
					</section>
				}

				<Modal show={showPicturesPrompt} position="center" backgroundDismiss onClose={() => setShowPicturesPrompt(false)}>
					<div className="pictures-prompt-wrapper">
						<span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.WARNING_IMAGE})` }}></span>
						<h2 className="text-center">
							<b>Después de pagar tu póliza, tendrás hasta 3 días</b> para enviarnos fotos de tu auto y as&iacute; evitar que el seguro sea desactivado.
						</h2>
						<p className="text-center">
							<b>Tu póliza estará activa</b> a partir del d&iacute;a de ma&ntilde;ana con todas las coberturas. Sin embargo, necesitaremos las fotos de tu auto para poder darte una mejor experiencia al momento de un siniestro.
						</p>

						<section>
							<Button type="button" className="block" onClick={() => { setShowPicturesPrompt(false); initCheckout(); }}>Pagar P&oacute;liza</Button>
							<Button type="button" className="block" fill="outline" onClick={() => setShowPicturesPrompt(false)}>Cancelar</Button>
						</section>
					</div>
				</Modal>

				<Modal show={showDetailsModal} position="center" backgroundDismiss onClose={() => setShowDetailsModal(false)}>
					<div className="modal-details-wrapper">
						{IconFactory.closeIcon({ onClick: () => setShowDetailsModal(false) })}

						<section className="amounts">
							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL &&
								<div>
									<p className="text-center">Total a pagar</p>

									<h3 className="text-center">{paymentCost.getTotal()}</h3>
								</div>
							}

							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION &&
								<div>
									<p className="text-center small">
										*1er Pago (1er mes de {plan.discount ? plan.discount.subscription.firstCost.getNetAmount() : plan.subscription.firstCost.getNetAmount()} <br /> + Responsabilidad Civil {plan.discount ? plan.discount.subscription.firstCost.getCivilTaxAmount() : plan.subscription.firstCost.getCivilTaxAmount()}
										<br /> + Tarifa {plan.discount ? plan.discount.subscription.firstCost.getMonthFeeAmount() : plan.subscription.firstCost.getMonthFeeAmount()} + Costo de expedición* {plan.discount ? Util.TRANSFORM.CURRENCY.MXN(plan.discount.subscription.firstCost.expeditionRights) : Util.TRANSFORM.CURRENCY.MXN(plan.subscription.firstCost.expeditionRights)} + IVA {plan.discount ? plan.discount.subscription.firstCost.getTaxes() : plan.subscription.firstCost.getTaxes()})
									</p>

									<h3 className="text-center">{plan.discount ? plan.discount.subscription.firstCost.getTotal() : plan.subscription.firstCost.getTotal()}</h3>
									<p className="subscription text-center">*Pago inicial 1ra cuota</p>

									<h3 className="text-center">{plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal()}</h3>
									<p className="text-center">*Por pagar 11 cuotas</p>

								</div>
							}

							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS &&
								<div>
									<p className="text-center">{paymentCost.monthsQuantity} pagos de</p>
									<h3 className="text-center">{paymentCost.getAmountByMonth() ? paymentCost.getAmountByMonth() : Util.TRANSFORM.CURRENCY.MXN(0)}</h3>
									<p className="text-center">Total a pagar <span>{paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0)}</span></p>

									{
										paymentCost.monthsQuantity === 0 &&
										<p className="text-center months-error">Selecciona el plazo a meses.</p>
									}
								</div>
							}
						</section>

						<section className="summary">
							{
								(selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL || selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS) &&
								<>
									<h2 className="text-center">Resumen de tu plan seleccionado</h2>
									<ul>
										<li>
											<span>Prima neta</span>
											<span>{paymentCost.getNetAmount()}</span>
										</li>

										<li>
											<span>Costo de expedici&oacute;n</span>
											<span>{paymentCost.getExpeditionRights()}</span>
										</li>

										<li>
											<span>Responsabilidad Civil</span>
											<span>{paymentCost.getCivilTaxAmount()}</span>
										</li>


										{
											selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS &&
											<li className="monthly-item flex column">
												<div className="flex">
													<span>Pago diferido ({paymentCost.getFeePercent()})</span>
													<span>{paymentCost.getMonthFeeAmount()}</span>
												</div>
											</li>
										}

										<li>
											<span>Subtotal</span>
											<span>{paymentCost.getSubTotal()}</span>
										</li>

										<li>
											<span>IVA (16%)</span>
											<span>{paymentCost.getTaxes()}</span>
										</li>

										{
											selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS &&
											<li>
												<span>Total por mes</span>
												<span>{paymentCost.getAmountByMonth()}</span>
											</li>
										}

										<li>
											<span>Total</span>
											<span>{paymentCost.getTotal()}</span>
										</li>

										<p className="reimbursementText">* Este monto no será reembolsado en caso de cancelación anticipada.</p>
									</ul>
								</>
							}

							{
								selectedPlanType === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION &&
								<>
									<h2 className="text-center">Resumen de primer pago</h2>

									<ul>
										<li>
											<span>Prima neta</span>
											<span>{plan.discount ? plan.discount.subscription.firstCost.getNetAmount() : plan.subscription.firstCost.getNetAmount()}</span>
										</li>

										<li>
											<span>Responsabilidad Civil</span>
											<span>{plan.discount ? plan.discount.subscription.firstCost.getCivilTaxAmount() : plan.subscription.firstCost.getCivilTaxAmount()}</span>
										</li>

										<li>
											<span>Pago diferido*</span>
											<span>{plan.discount ? plan.discount.subscription.firstCost.getMonthFeeAmount() : plan.subscription.firstCost.getMonthFeeAmount()}</span>
										</li>

										<li>
											<span>Costo de expedición*</span>
											<span>{plan.discount ? Util.TRANSFORM.CURRENCY.MXN(plan.discount.subscription.firstCost.expeditionRights) : Util.TRANSFORM.CURRENCY.MXN(plan.subscription.firstCost.expeditionRights)}</span>
										</li>

										<li>
											<span>Subtotal</span>
											<span>{plan.discount ? plan.discount.subscription.firstCost.getSubTotal() : plan.subscription.firstCost.getSubTotal()}</span>
										</li>

										<li>
											<span>IVA (16%)</span>
											<span>{plan.discount ? plan.discount.subscription.firstCost.getTaxes() : plan.subscription.firstCost.getTaxes()}</span>
										</li>

										<li className="total">
											<span>Total</span>
											<span>{plan.discount ? plan.discount.subscription.firstCost.getTotal() : plan.subscription.firstCost.getTotal()}</span>
										</li>
									</ul>

									<h2 className="text-center second-title">Resumen de los siguientes 11 pagos</h2>

									<ul>
										<li>
											<span>Prima neta</span>
											<span>{plan.discount ? plan.discount.subscription.cost.getNetAmount() : plan.subscription.cost.getNetAmount()}</span>
										</li>

										<li>
											<span>Pago diferido*</span>
											<span>{plan.discount ? plan.discount.subscription.cost.getMonthFeeAmount() : plan.subscription.cost.getMonthFeeAmount()}</span>
										</li>

										<li>
											<span>Subtotal</span>
											<span>{plan.discount ? plan.discount.subscription.cost.getSubTotal() : plan.subscription.cost.getSubTotal()}</span>
										</li>

										<li>
											<span>IVA (16%)</span>
											<span>{plan.discount ? plan.discount.subscription.cost.getTaxes() : plan.subscription.cost.getTaxes()}</span>
										</li>

										<li className="total">
											<span>Total</span>
											<span>{plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal()}</span>
										</li>

										<p className="reimbursementText">* Este monto no será reembolsado en caso de cancelación anticipada.</p>
									</ul>
								</>
							}
						</section>
					</div>
				</Modal>

				<Modal show={showRenewalModal} position="center" backgroundDismiss onClose={() => setShowRenewalModal(false)}>
					<div className="renewal-modal text-center">
						<span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.INFO_IMAGE})` }}></span>
						<h2 className="text-center"><b>Ayúdanos a saber qué tipo de experiencia prefieres</b></h2>

						<form className="flex column space-evenly text-left">
							<Radio name="allow-renewal" withoutForm={true} defaultChecked={isAutomaticRenewal === true} onChange={() => setIsAutomaticRenewal(true)}>Quiero que el pago de mi renovación sea <b>automático</b>.</Radio>
							<Radio name="allow-renewal" withoutForm={true} defaultChecked={isAutomaticRenewal === false} onChange={() => setIsAutomaticRenewal(false)}>Quiero realizar el pago de mi renovación <b>manualmente</b>.</Radio>
						</form>

						<Button
							type="button"
							className="block"
							disabled={isAutomaticRenewal === undefined}
							onClick={() => { setShowRenewalModal(false); !props.picturesPrompt ? initCheckout() : setShowPicturesPrompt(true) }}
						>
							Finalizar contratación
						</Button>
						<a target="_blank" href="https://wa.me/5213321011715?text=Tengo%20una%20duda." rel="noreferrer">Tengo dudas 💬</a>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default PlanPaymentManagement;
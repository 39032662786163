import { Button, Modal, Spinner } from "src/components";
import Otp from 'src/libs/Otp/ui/template/Otp';
import PersonManagement from 'src/libs/PersonManagement/ui/template/PersonManagement';
import { QuoterDataFacade } from 'src/modules/quoter/controller/facades/quoter-data.facade';
import { UtmsFacade } from 'src/modules/quoter/controller/facades/utms.facade';
import AccountData from '../../organisms/AccountData/AccountData';

import './QuoterDataForm.scss';

/**
 *
 */
interface IQuoterDataForm { }

/**
 *
 */
const QuoterDataFormPage = (props: IQuoterDataForm): JSX.Element => {
  /** */
  const { utms } = UtmsFacade.useUtmsFacade();
  /** */
  const { modalShown, isLoading, isFormDone, incomingPerson, currentUser, setModalShown, updateUser, updateAccountData, registerUser } = QuoterDataFacade.useQuoterDataFacade(utms);

  return (
    <div id='quoter-data-form'>
      {
        isLoading && <Spinner color={'main'} circles>¡Estamos creando tu cuenta!</Spinner>
      }

      {
        !isLoading &&
        <>
          <div className={`form-wrapper ${isLoading && 'opacity'}`}>
            <h1>Verifica y completa tus datos</h1>
            <p>Con esta información te compartiremos el precio exacto de tu póliza.</p>

            <PersonManagement hideEmail={true} showEconomicActivities={true} storedPerson={incomingPerson} onComplete={updateUser} />

            <p className='password-text'>Guardaremos la cotización en tu cuenta, solo necesitas una contraseña.</p>

            <AccountData storedPerson={undefined} onComplete={updateAccountData} />
          </div>

          {
            currentUser &&
            <Modal show={modalShown} position={'center'} backgroundDismiss={false}>
              <Otp phoneNumber={`${currentUser!.profile!.phone!.countryCode!.code}${currentUser!.profile!.phone!.number}`} phoneOnly={true} onVerify={registerUser} close={() => setModalShown(false)} />
            </Modal>
          }



          <div className='button-wrapper text-center'>
            <p>Enviaremos un código para validar tu tel&eacute;fono</p>
            <Button disabled={!isFormDone} onClick={() => setModalShown(true)}>Continuar</Button>
          </div>
        </>
      }
    </div>
  );
};

export default QuoterDataFormPage;
import { DamageClaim } from "src/classes/DamageClaim";
import { AgreedClaimsDataAdapter } from "../adapters/agreed-claims.adapter";
import { ClaimsDataAdapter } from "../adapters/claims.adapter";
import { ClaimsService } from "../services/claims.service";

/**
 *
 */
export namespace ClaimsFeature {

  /**
   * Get damage payments list feature.
   */
  export const activeClaimListFeature = async (): Promise<DamageClaim[] | undefined> => {
    try {
      const { data } = await ClaimsService.activeClaimListService();
      const list = ClaimsDataAdapter.claimListDataAdapter(data);

      return list;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * Get agreed damage payments list feature.
   */
	 export const activeAgreedClaimListFeature = async (): Promise<DamageClaim[] | undefined> => {
    try {
      const { data } = await ClaimsService.activeAgreedClaimListService();
      const list = AgreedClaimsDataAdapter.agreedClaimListDataAdapter(data);

      return list;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
import { Button, Modal } from "src/components";

import './ProductLimit.scss';

/**
 *
 */
interface IProductLimit {

  show: boolean;

  onClose?: () => void;
}

/**
 *
 */
export const ProductLimit = (props: IProductLimit): JSX.Element => {
  return (
    <Modal position="center" show={props.show} onClose={props.onClose}>
      <div className="product-limit-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="103" height="88" viewBox="0 0 103 88" fill="none">
          <path d="M41.0156 83.0156C63.6679 83.0156 82.0312 64.6523 82.0312 42C82.0312 19.3477 63.6679 0.984375 41.0156 0.984375C18.3633 0.984375 0 19.3477 0 42C0 64.6523 18.3633 83.0156 41.0156 83.0156Z" fill="url(#paint0_linear_2530_15010)" />
          <path opacity="0.49" d="M81.4089 49.1515L53.3266 21.0691H29.0692L26.7838 42.3972L27.8279 43.7489L32.8365 48.7574H25.77C25.77 52.6754 27.1691 56.0518 29.584 58.5252L52.4551 81.397C67.3093 77.0913 78.6899 64.6144 81.4089 49.1515Z" fill="url(#paint1_linear_2530_15010)" />
          <path d="M40.8008 35.0647C49.9623 35.0647 56.2617 40.6725 56.2617 48.6949C56.2617 57.0061 49.8911 62.9309 40.9681 62.9309C32.0526 62.9309 25.77 57.0603 25.77 48.758H36.2639C36.2639 51.5359 38.2163 53.5232 40.9681 53.5232C43.6587 53.5232 45.6201 51.4646 45.6201 48.6941C45.6201 45.7557 43.5613 43.7495 40.5614 43.7495H27.8279L26.7838 42.3978L29.0692 21.0697H53.3266V30.3957H37.5087L36.9932 35.0642H40.7995L40.8008 35.0647Z" fill="white" />
          <path d="M101.11 78.1268L85.5587 49.1867C84.3727 47.2015 82.3102 46 80.0156 46C77.7211 46 75.6586 47.2015 74.4726 49.1867L58.9213 78.1268C57.7355 80.1904 57.7097 82.6456 58.8699 84.709C60.03 86.7723 62.1182 88 64.4644 88H95.5669C97.9131 88 100.001 86.7723 101.161 84.709C102.322 82.6456 102.296 80.1904 101.11 78.1268Z" fill="white" />
          <path d="M101.161 84.709C100.001 86.7723 97.9131 88 95.5669 88H80.0156V46C82.3102 46 84.3727 47.2016 85.5587 49.1867L101.11 78.1269C102.296 80.1904 102.322 82.6456 101.161 84.709Z" fill="white" />
          <path d="M96.3185 77.6207L82.8877 52.9503C82.2866 51.9261 81.1956 51.325 80.0155 51.325C78.8353 51.325 77.7443 51.9262 77.1433 52.9503L63.7125 77.6207C63.0891 78.6672 63.0891 79.9362 63.6904 80.9828C64.2915 82.0514 65.3824 82.675 66.5849 82.675H93.4462C94.6486 82.675 95.7396 82.0514 96.3407 80.9828C96.9418 79.9362 96.9418 78.6672 96.3185 77.6207Z" fill="#FFDE33" />
          <path d="M96.3408 80.9828C95.7397 82.0514 94.6487 82.675 93.4462 82.675H80.0156V51.325C81.1958 51.325 82.2868 51.9262 82.8878 52.9503L96.3186 77.6207C96.942 78.6672 96.942 79.9362 96.3408 80.9828Z" fill="#FFBC33" />
          <path d="M80.0156 80.4485C78.7882 80.4485 77.7891 79.4494 77.7891 78.2219C77.7891 76.9945 78.7882 75.9954 80.0156 75.9954C81.2431 75.9954 82.2422 76.9945 82.2422 78.2219C82.2422 79.4494 81.2431 80.4485 80.0156 80.4485Z" fill="#495A79" />
          <path d="M82.2422 71.5422C82.2422 72.7697 81.2431 73.7688 80.0156 73.7688C78.7882 73.7688 77.7891 72.7697 77.7891 71.5422V60.4094C77.7891 59.182 78.7882 58.1829 80.0156 58.1829C81.2431 58.1829 82.2422 59.182 82.2422 60.4094V71.5422Z" fill="#495A79" />
          <path d="M82.2422 78.2219C82.2422 76.9945 81.2431 75.9954 80.0156 75.9954V80.4485C81.2431 80.4485 82.2422 79.4494 82.2422 78.2219Z" fill="#42516D" />
          <path d="M82.2422 71.5422V60.4094C82.2422 59.182 81.2431 58.1829 80.0156 58.1829V73.7688C81.2431 73.7688 82.2422 72.7697 82.2422 71.5422Z" fill="#42516D" />
          <defs>
            <linearGradient id="paint0_linear_2530_15010" x1="0" y1="42" x2="82.0312" y2="42" gradientUnits="userSpaceOnUse">
              <stop stopColor="#0D4EC9" />
              <stop offset="0.5" stopColor="#1565D8" />
              <stop offset="1" stopColor="#1565D8" />
            </linearGradient>
            <linearGradient id="paint1_linear_2530_15010" x1="25.898" y1="24.2403" x2="71.338" y2="69.6803" gradientUnits="userSpaceOnUse">
              <stop />
              <stop offset="1" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <h1>Has alcanzado tu m&aacute;ximo de p&oacute;lizas activas</h1>
        <p>Actualmente solo puedes tener 5 p&oacute;lizas activas, pero puedes seguir cotizando y consultando precios</p>
        <Button type="button" onClick={(e) => {e.preventDefault(); props.onClose !== undefined ? props.onClose() : <></> }}>Entendido</Button>
      </div>
    </Modal>
  );
};
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Reward } from "src/classes/referrals/Reward";
import { IReferralsStateReducer } from "src/store/states/modules-state/IReferralsStateReducer";

/**
 *
 */
export namespace ReferralsSlice {
  /**
    * 
    */
  const initialState: IReferralsStateReducer = {
    rewardsList: undefined,
    rewardsHistoryList: undefined,
    rewardsHistoryListOffset: 0,
    isLoading: false,
  };


  /**
   * 
   * @param state 
   * @param action 
   */
  const clear: CaseReducer<IReferralsStateReducer> = (state) => {
    state.rewardsList = undefined;
    state.rewardsHistoryList = undefined
    state.rewardsHistoryListOffset = 0
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const rewardsList: CaseReducer<IReferralsStateReducer, PayloadAction<Reward[]>> = (state, action) => {
    state.rewardsList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
   const rewardsHistoryList: CaseReducer<IReferralsStateReducer, PayloadAction<Reward[]>> = (state, action) => {
     const rewards = (state.rewardsHistoryList) ?  state.rewardsHistoryList : []

     state.rewardsHistoryList = [
       ...rewards,
       ...action.payload
     ]
  }

  const rewardsHistoryListClear: CaseReducer<IReferralsStateReducer> = (state) => {
    state.rewardsHistoryList = undefined;
  }
  
  /**
   * 
   * @param state 
   * @param action 
   */
  const rewardsHistoryListOffset: CaseReducer<IReferralsStateReducer, PayloadAction<number>> = (state, action) => {
    state.rewardsHistoryListOffset = action.payload
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const rewardsLoading: CaseReducer<IReferralsStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.isLoading = action.payload
  }

  const reducers = {
    clear,
    rewardsList,
    rewardsHistoryList,
    rewardsHistoryListOffset,
    rewardsLoading,
    rewardsHistoryListClear
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'referrals',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
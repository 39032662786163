import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Address } from "src/classes/Address";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { CoreAddressFeature } from "src/core/features/address.feature";
import { Util } from "src/utils/Util";
import { POLICY_CREATION_PATH } from "../../../../routes/paths/policy-creation.paths";
import { PolicyCreationAction } from "../actions/policy-creation.action";
import { PolicyVehicleDataAction } from "../actions/policy-vehicle-data.action";
import { PolicyVehicleFeature } from "../features/policy-vehicle.feature";
import { PolicyVehicleDataSaga } from "../sagas/policy-vehicle-data.saga";
import { PolicyVehicleDataSelector } from "../selectors/policy-vehicle-data.selector";
import { PolicyVehicleDataSlice } from "../slices/policy-vehicle-data.slice";
import { PolicyCreationFacade } from "./policy-creation.facade";

/**
 *
 */
export namespace PolicyVehicleFacade {

  /**
   * 
   * @param newVehicle 
   */
  export const useVehicleData = () => {
    useInjectSaga({ key: 'vehicleData', saga: PolicyVehicleDataSaga.vehicleDataSaga });
    useInjectReducer({ key: 'vehicleData', reducer: PolicyVehicleDataSlice.reducer });

		const { vehicle } = PolicyCreationFacade.usePolicyCreationActors();
    const storedVehicle = useSelector(PolicyVehicleDataSelector.storedVehicle);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>();
    const [isCreatingVehicle, setIsCreatingVehicle] = useState<boolean>(false);
		const [isValidatingPostalCode, setIsValidatingPostalCode] = useState<boolean>(false);
    const [validatedPostalCode, setValidatedPostalCode] = useState<string>('');
    const [previousPostalCodeValidation, setPreviousPostalCodeValidation] = useState<boolean | null>(null);
		const [vehicleAddress, setVehicleAddress] = useState<Address>();
		const { view } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

		/**
     * 
     * @param vehicle 
     */
		const selectVehicle = useCallback(
			(vehicle?: Vehicle) => {
				setSelectedVehicle(vehicle);
			}, 
			[]
		)

		/**
		 * 
		 */
		const navigateToPlanSelection = async(): Promise<void> => {
			if(selectedVehicle && vehicleAddress) {
				//CoreVehicleStoreFeature.storeVehicleFeature(selectedVehicle);
				dispatch(PolicyCreationAction.currentVehicle(selectedVehicle));
				dispatch(PolicyCreationAction.setVehiclePostalCode(vehicleAddress.postalCode));
				navigate(POLICY_CREATION_PATH.PLAN_SELECTION);
			}
		}

    /**
     * 
     * @returns 
     */
    const createVehicle = async (): Promise<void> => {
      try {
        if (selectedVehicle) {
          setIsCreatingVehicle(true);
					let currentVehicle = vehicle;
					currentVehicle.plate = selectedVehicle.plate;
					currentVehicle.vin = selectedVehicle.vin;
          await PolicyVehicleFeature.createVehicleFeature(currentVehicle);
          dispatch(PolicyCreationAction.currentVehicle(vehicle));
          navigate(POLICY_CREATION_PATH.POLICY_VEHICLE_SALVAGE);
        }
      } catch (e) {
        console.error(e);
        setIsCreatingVehicle(false);
      }
    }

		/**
     * 
     * @param postalCode 
     * @returns 
     */
		const verifyPostalCode = async (postalCode: string): Promise<boolean | undefined> => {
			try {
				if (Util.PATTERN.NUMBERS.test(postalCode)) {
					if (validatedPostalCode !== postalCode) {
						setIsValidatingPostalCode(true);
						setValidatedPostalCode(postalCode);
						const address = await CoreAddressFeature.validatePostalCodeFeature(postalCode);
						if (address) {
							setPreviousPostalCodeValidation(true);
							setVehicleAddress(address);
							return true;
						} else {
							return false;
						}
					} else {
						return previousPostalCodeValidation as boolean;
					}
				}
			} catch (e) {

			} finally {
				setIsValidatingPostalCode(false);
			}
		};

    const useMountEffect = () => useEffect(
      () => {
        dispatch(PolicyVehicleDataAction.getVehicleStored());
      }, []
    );
    useMountEffect();

    return { view, selectedVehicle, isCreatingVehicle, createVehicle, navigateToPlanSelection, selectVehicle, storedVehicle, verifyPostalCode, isValidatingPostalCode };
  }
}
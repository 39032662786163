import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Plan } from "src/classes/plan/Plan";
import { POLICY_RENEWAL_PATH } from "src/routes/paths/policy-renewal.paths";
import { PolicyRenewalAction } from "../actions/policy-renewal.action";

export namespace PlanRenewalFacade {

  /**
   * 
   */
  export const usePlanRenewal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { policyNumber } = useParams<string>();

    /**
     * 
     * @param plan 
     */
    const selectPlan = (plan: Plan): void => {
      dispatch(PolicyRenewalAction.setPlan(plan));
      navigate(POLICY_RENEWAL_PATH.POLICY_RENEWAL_REVIEW(policyNumber!));
    }

    return { selectPlan };
  }
}
const BASE_URL: string = '/agreed-payments';

/**
 * 
 */
 export const AGREED_PAYMENTS_PATH = {
  AGREED_PAYMENTS: BASE_URL,
	ONBOARDING: `${BASE_URL}/onboarding`,
	PERSONAL_DATA: `${BASE_URL}/personal-data`,
	VEHICLE_DATA: `${BASE_URL}/vehicle-data`,
	POLICY_DATA: `${BASE_URL}/policy-data`,
	ID_DOCUMENTS: `${BASE_URL}/id-documents`,
	VEHICLE_OWNER: `${BASE_URL}/vehicle-owner`,
	BANK_ACCOUNT: `${BASE_URL}/bank-account`,
	VEHICLE_BILL: `${BASE_URL}/vehicle-bill`,
	ATTORNEY_LETTER: `${BASE_URL}/attorney-letter`,
	COMPLETION: `${BASE_URL}/completed`,
	PROPOSAL: `${BASE_URL}/proposal`,
	MESSAGE_AGENT:`https://wa.me/5213321011715?text=Tengo%20una%20duda.`,
}
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertFactory } from "src/factory/alert.factory";
import { LoadingFactory } from "src/factory/loading.factory";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { PolicyAction } from "../actions/policy.action";
import { PolicyPaymentFeature } from "../feature/policy-payment.feature";
import { ICardForm } from "../interfaces/ICardForm";
import { PolicySelector } from "../selectors/policy.selector";

export namespace PolicyPaymentMethodFacade {

  /**
   * 
   * @returns 
   */
  export const usePolicyPaymentMethodForm = () => {
    const YEARS = useMemo(
      () => {
        let list: number[] = [];
        const today = new Date();
        const mill = Math.floor(today.getFullYear() / 1000);
        for (let i = today.getFullYear(); i <= today.getFullYear() + 19; i++) {
          list.push(i - (1000 * mill));
        }
        return list;
      }, []
    )
    const MONTHS = useMemo(
      () => {
        let list: number[] = [];
        for (let i = 1; i <= 12; i++) {
          list.push(i);
        }
        return list;
      }, []
    );
    const { register, formState: { errors, isValid }, setValue, trigger, getValues } = useForm<ICardForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    return { YEARS, MONTHS, form: { register, formState: { errors, isValid }, setValue, trigger, getValues } }
  }


  /**
   * 
   */
  export const usePolicyPaymentMethod = () => {
    const selectedPolicy = useSelector(PolicySelector.selectedPolicy);
    const [stripe, setStripe] = useState<Stripe | undefined>();
    const [stripeElement, setStripeElement] = useState<StripeElements | undefined>();
    const [isComponentCardCompleted, setIsComponentCardCompleted] = useState<boolean>(false);
    const updating = LoadingFactory.useLoader();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    /**
     * 
     * @param isCompleted 
     * @param type 
     * @param stripe 
     * @param elements 
     */
    const setComponentCard = (isCompleted: boolean, stripe?: Stripe, elements?: StripeElements): void => {
      setIsComponentCardCompleted(isCompleted);
      if (isCompleted) {
        setStripe(stripe);
        setStripeElement(elements);
      } else {
        setStripe(undefined);
        setStripeElement(undefined);
      }
    }

    /**
     * 
     * @param card 
     */
    const initCardUpdate = async (card: ICardForm): Promise<void> => {
      try {
        if (selectedPolicy) {
          updating.set(true);
          if (selectedPolicy.paymentMethod?.isProviderByForm()) {
            await PolicyPaymentFeature.paymentMethodUpdateByFormFeature(card, selectedPolicy);
          } else {
            await PolicyPaymentFeature.paymentMethodUpdateByComponentFeature(card, selectedPolicy, {stripe: stripe!, elements: stripeElement!});
          }
          dispatch(PolicyAction.updateSelectedPolicy(selectedPolicy.policyNumber));
          AlertFactory.successAlert('Método de pago actualizado.')
          updating.set(false);
          navigate(`${POLICY_PATH.MANAGEMENT}/${selectedPolicy.policyNumber}`)
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
        updating.set(false);
      }
    }

    return { initCardUpdate, isComponentCardCompleted, setComponentCard, updating }
  }
  
} 
import { Navigate } from "react-router";
import { ClaimsRoutes } from "src/modules/claims/routes/claims.routes";
import { DashboardRoutes } from "src/modules/dashboard/routes/dashboard.routes";
import { PolicyCreationRoutes } from "src/modules/policy-creation/routes/policy-creation.routes";
import { PolicyRoutes } from "src/modules/policy/routes/policy.routes";
import { ProfileRoutes } from "src/modules/profile/routes/profile.routes";
import { PolicyRenewalRoutes } from "src/modules/policy-renewal/routes/policy-renewal.routes";
import { PolicyEndorsementsRoutes } from "src/modules/policy-endorsements/routes/policy-endorsements.routes";
import { LOGIN_PATH } from "./paths/login.paths";
import { ReferralsRoutes } from "src/modules/referrals/routes/referrals.routes";
import { AgreedClaimsRoutes } from "src/modules/claims/routes/agreed-claims.routes";


export const privateRoutes = (isValidAuth: boolean) => {
  return [
    {
      ...DashboardRoutes(isValidAuth, LOGIN_PATH.LOGIN),
      children: [
        { path: '/', element: <Navigate to={PolicyRoutes().path} /> },
        { path: '/profile', element: <Navigate to={PolicyRoutes().path} replace /> },
        PolicyRoutes(),
        ProfileRoutes(),
        ClaimsRoutes(),
				AgreedClaimsRoutes(),
        ReferralsRoutes(),
      ],
    },
    {
      ...PolicyCreationRoutes(isValidAuth, LOGIN_PATH.LOGIN),
    },
    {
      ...PolicyRenewalRoutes(isValidAuth, LOGIN_PATH.LOGIN)
    },
    {
      ...PolicyEndorsementsRoutes(isValidAuth, LOGIN_PATH.LOGIN)
    },
    {
      path: '*', element: <Navigate to={'/'} />
    }
  ]
}

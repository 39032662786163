import { AxiosResponse } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IUserData } from "../interfaces/user/IUserData";
import { IUserIdentityData } from "../interfaces/user/IUserIdentityData";

/**
 *
 */
export namespace CoreUserInfoService {

	/** */
  export const getUserInfoService = (): Promise<AxiosResponse<IUserData>> => {

    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.USER_INFO(new Date().getTime()));
  }

	/** */
	export const getIdentityStatusService = (): Promise<AxiosResponse<IUserIdentityData>> => {

		return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.USER_IDENTITY_STATUS(new Date().getTime()));
	}
}
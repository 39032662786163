import { useEffect, useMemo, useState } from 'react';
import { PaymentCost } from 'src/classes/PaymentCost';
import { Spinner } from 'src/components';
import { PlanManagementFacade } from 'src/libs/PlanManagement/controller/facades/plan-management.facade';
import { Util } from 'src/utils/Util';
import './PlanCost.scss';

/**
 *
 */
interface IPlanCost {

  /**
   * 
   */
  discountCost?: PaymentCost;

  /**
   * 
   */
  cost?: PaymentCost;

  /**
   * 
   */
  paymentMethod: { name: string, code: number }

}

/**
 *
 */
const PlanCost = (props: IPlanCost): JSX.Element => {
  const { cost, discountCost } = props;
  const { isUpdating } = PlanManagementFacade.usePlanSync(true);
  const [displayCost, setDisplayCost] = useState<PaymentCost>();
  const discountValue = useMemo(
    () => {
      if (cost) {
        if (discountCost) {
          return cost?.monthsQuantity > 0 ? cost.getAmountByMonth() : cost.getTotal();
        } else {
          let localDiscount = 0;
          if (cost.monthsQuantity > 0) {
            const totalByMonths = cost.total / cost.monthsQuantity;
            localDiscount = totalByMonths + (totalByMonths * 0.10);
          } else {
            localDiscount = cost.total + (cost.total * 0.10);
          }
          return Util.TRANSFORM.CURRENCY.MXN(localDiscount);
        }
      }
    }, [discountCost, cost]
  );
  const discountValueArray = useMemo(
    () => {
      if (discountValue) {
        return Array.from(discountValue.split('.')[0]);
      }
      return [];
    }, [discountValue]
  )

  /**
   * 
   * @param ref 
   * @param hideElment 
   */
  const valueElement = (ref: HTMLDivElement | null, hideElment?: boolean, isNumber?: boolean): void => {
    if (hideElment && ref) {
      ref.setAttribute('roll-to-hide', '');
      if (!isNumber) {
        setTimeout(() => {
          ref.setAttribute('hide-field', '');
        }, 2500);
      }
      else {
        setTimeout(() => {
          ref.setAttribute('hide-field', '');
        }, 1500);
      }
    }
  }

  /**
   * 
   * @returns 
   */
  const getCostValue = (): string => {
    let costValue: string = '';
    if (displayCost) {
      costValue = displayCost.monthsQuantity > 0 ? displayCost.getAmountByMonth() : displayCost.getTotal();
    }
    return costValue;
  }

  useEffect(
    () => {
      if (discountCost) {
        setDisplayCost(discountCost);
      } else if (cost && !discountCost) {
        setDisplayCost(cost);
      }
    }, [cost, discountCost]
  )

  return (
    <div className={`planCost flex column align-center justify-center relative ${!isUpdating ? 'planCost--animateWrapper' : ''}`}>
      {
        !isUpdating && displayCost ?
          <>
            <div className="planCost__circleRing width-98 height-98 absolute planCost__circleRing--animate"><div></div></div>
            <div className="planCost__circleRing width-98 height-98 absolute planCost__circleRing--animate"><div></div></div>
            {
              discountCost && cost ?
                <p className="planCost__discountText absolute">
                  {
                    cost.monthsQuantity > 0 ?
                      <>{cost.getAmountByMonth()}</>
                      :
                      <>{cost.getTotal()}</>
                  }
                </p>
                :
                <p className="planCost__discountText absolute">
                  {discountValue}
                </p>
            }

            {
              displayCost?.monthsQuantity === 0 && !displayCost.isSubscription &&
              <p className="planCost__infoText text-center">Pago único</p>
            }

            {
              displayCost?.monthsQuantity === 0 && displayCost.isSubscription &&
              <p className="planCost__infoText text-center">Primer pago de</p>
            }

            {
              displayCost.monthsQuantity > 0 &&
              <p className="planCost__infoText text-center">{`${displayCost.monthsQuantity} pagos de`}</p>
            }


            <div className="planCost__costWrapper flex">

              {/* Hidden value */}
              {
                displayCost.monthsQuantity > 0 ?
                  <span className="planCost__costWrapper__total absolute">{displayCost.getAmountByMonth()}</span>
                  :
                  <span className="planCost__costWrapper__total absolute">{displayCost.getTotal()}</span>
              }
              {/* end Hidden value */}

              {discountValueArray.map(
                (char, index) => {
                  let numbers = [];
                  let step = 10;
                  let limitNumber = -1;
                  let hideField = false;
                  const currentNumber = parseInt(char);
                  // Create an array of current cost and reverse it to verify length differences between discountValueArray and cost.
                  const currentValue = getCostValue();
                  const curentCost = Array.from(currentValue.split('.')[0]).reverse();
                  const currentCostIndex = (discountValueArray.length - 1) - index;
                  if (!isNaN(+curentCost[currentCostIndex])) {
                    limitNumber = parseInt(curentCost[currentCostIndex]);
                  } else if (curentCost.length !== discountValueArray.length) {
                    if (char === ',' && curentCost.length < 5) {
                      hideField = true;
                    } else if (char !== ',' && char !== '$') {
                      hideField = true;
                    }
                  }

                  for (let i = 1; i <= 10; i++) {
                    numbers.push(currentNumber - i < 0 ? (step + (currentNumber - i)) : currentNumber - i);
                  }
                  if (limitNumber > -1) {
                    const lastNumber = numbers[numbers.length - 1];
                    for (let i = lastNumber; i !== limitNumber; i--) {
                      numbers.push(i >= 0 ? i : 0);
                      if (i - 1 < 0) {
                        i = 10;
                      }
                    }
                    numbers.push(limitNumber);
                  }

                  return (
                    <div key={`element-${index}`} className={`planCost__costWrapper__value ${char !== '$' && char !== ',' ? 'planCost__costWrapper__value--number' : ''}`}
                      ref={(ref) => valueElement(ref, hideField, char === ',')}
                    >
                      {
                        char !== '$' && char !== ',' ?
                          <>
                            <div className="planCost__costWrapper__value__slot">
                              {char}
                            </div>
                            {
                              numbers.map(
                                (number, slotIndex) => <div key={`slot-${index}-${slotIndex}`} className="planCost__costWrapper__value__slot">
                                  {number}
                                </div>
                              )
                            }
                          </>
                          :
                          <div className="planCost__costWrapper__value__slot">
                            {char}
                          </div>
                      }
                    </div>
                  )
                }
              )}
              <div className="planCost__costWrapper__value planCost__costWrapper__value--decimal"> <div className="planCost__costWrapper__value__slot">.</div></div>
              {
                displayCost.monthsQuantity > 0 ?
                  <>
                    {Array.from(displayCost.getAmountByMonth().split('.')[1]).map(
                      (char, index) => <div key={`decimal-${index}`} className="planCost__costWrapper__value planCost__costWrapper__value--decimal"> <div className="planCost__costWrapper__value__slot">{char}</div></div>
                    )}
                  </>
                  :
                  <>
                    {Array.from(displayCost.getTotal().split('.')[1]).map(
                      (char, index) => <div key={`decimal-${index}`} className="planCost__costWrapper__value planCost__costWrapper__value--decimal"> <div className="planCost__costWrapper__value__slot">{char}</div></div>
                    )}
                  </>
              }
            </div>

            {
              displayCost.monthsQuantity > 0 &&
              <p className="planCost__infoText--bottom text-center">{`Total a pagar ${displayCost.getTotal()}`}</p>
            }


          </>
          :
          <Spinner color="white" />
      }
    </div>
  );
};

export default PlanCost;
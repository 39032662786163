import { Button, ButtonLink, Checkbox, Spinner, Dropdown, InputArea } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { PolicyCancellationFacade } from 'src/modules/policy/controller/facades/policy-cancellation.facade';
import { PolicyFacade } from "src/modules/policy/controller/facades/policy.facade";
import { POLICY_PARAMS, POLICY_PATH } from "src/routes/paths/policy.paths";
import { Util } from "src/utils/Util";

import './PolicyCancellation.scss';

/**
 *
 */
const PolicyCancellationPage = (): JSX.Element => {
  const { selectedPolicy } = PolicyFacade.usePolicyManagement();
  const { reasonsList, form, selectReasonByIndex, selectedReason, isCancelling, cancelPolicy, limitDate, cancellationCompleted } = PolicyCancellationFacade.useCancellation();
  return (
    <div id="policy-cancellation-wrapper" className="height-100 relative flex column align-center">

      {
        !selectedPolicy &&
        <Spinner color="main" circles />
      }

      {
        selectedPolicy && (!selectedPolicy.isCanceled() || cancellationCompleted) &&
        <>
          {
            !cancellationCompleted ?
              <>
                <h1 className="text-center">¡No te quedes desprotegido!</h1>

                <ul>
                  <li>
                    Si cancelas quedas cubierto hasta el final de tu periodo de contratación ({limitDate()}).
                  </li>
                  <li>
                    A partir de este momento no volveremos a hacer ningún cargo a tu tarjeta.
                  </li>
                </ul>

                <h2 className="text-center">
                  No somos perfectos 😟 pero queremos ser mejores para ti. ¿Nos ayudas?
                </h2>

                <form>

                  <section>
                    <Dropdown
                      items={reasonsList.map((r, index) => { return { id: index, value: r.description } })}
                      placeholder="Motivo de la cancelación"
                      required
                      onChange={(item) => selectReasonByIndex(item.id as number)}
                    >
                      ¿Por qué decidiste cancelar?
                    </Dropdown>
                  </section>

                  <section>
                    <InputArea rows={5} maxLength={200}
                      errors={!!form.formState.errors.customReason}
                      {...form.register("customReason", { pattern: Util.PATTERN.DESCRIPTION })}
                    >
                      Cuéntanos cómo podemos mejorar
                    </InputArea>
                  </section>

                  <section>
                    <Checkbox
                      {...form.register("terms", { required: true })}
                    >
                      Acepto los <a target="_blank" href="https://crabi.com/legal/terminos-y-condiciones" rel="noreferrer">Términos y Condiciones</a> de cancelaciones.
                    </Checkbox>
                  </section>
                </form>

                <div className="button-wrapper flex justify-center">
                  <Button type="button"
                    disabled={!form.formState.isValid || !selectedReason || isCancelling.value}
                    color="red"
                    loading={isCancelling.value}
                    onClick={(e) => { e.preventDefault(); cancelPolicy() }}
                  >
                    Cancelar póliza
                  </Button>
                </div>
              </>
              :
              <>
                <div className="policy-cancelled flex column align-center">
                  {IconFactory.checkIcon()}
                  <h1 className="text-center">Tu p&oacute;liza {selectedPolicy.policyNumber} ha sido cancelada.</h1>
                  <h3 className="text-center">Ya no haremos ningún cargo a tu tarjeta y seguirás protegido hasta el final de tu periodo de contatación el {limitDate()}</h3>

                  <ButtonLink to={`${POLICY_PATH.STATUS}/${POLICY_PARAMS.STATUS.CANCELLED}`} replace>Entendido</ButtonLink>
                </div>
              </>
          }
        </>
      }


    </div>
  );
};

export default PolicyCancellationPage;
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { ICardTokenByFormData } from "../interfaces/ICardTokenByFormData";
import { ICardtokenRequest } from "../interfaces/ICardTokenRequest";
import { IPaymentMethodData } from "../interfaces/IPaymentMethodData";
import { IPolicyNextPaymentData } from "../interfaces/IPolicyNextPaymentData";
import { IPolicyPaymentHistoryData } from "../interfaces/IPolicyPaymentHistoryData";
import { IUpdateCardRequest } from "../interfaces/IUpdateCardRequest";

export namespace PolicyPaymentService {

  /**
   * 
   * @param policyId 
   * @returns 
   */
  export const currentPaymentMethodService = (policyId: string): AxiosPromise<IPaymentMethodData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_PAYMENT_METHOD(policyId));
  }

  /**
   * 
   * @param policyNumber 
   * @returns 
   */
  export const paymentHistoryService = (policyNumber: string): AxiosPromise<IPolicyPaymentHistoryData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_PAYMENT_HISTORY(policyNumber));
  }

  /**
   * 
   * @param policyNumber 
   * @returns 
   */
  export const policyNextPaymentService = (policyNumber: string): AxiosPromise<IPolicyNextPaymentData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.CHECK_NEXT_PAYMENT(policyNumber));
  }

  /**
   * 
   * @param request 
   * @param policyId 
   * @returns 
   */
  export const updatePaymentMethod = (request: IUpdateCardRequest, policyId: string): AxiosPromise<void> => {
    return EnvironmentFactory.Http.SecureHttp().put(Util.API_PATH.POLICY_PAYMENT_METHOD(policyId), request);
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const paymentMethodTokenByFormService = (request: ICardtokenRequest): AxiosPromise<ICardTokenByFormData> => {
    return EnvironmentFactory.Http.ExternalHttp(EnvironmentFactory.ENV.REACT_APP_OPENPAY_API_URL!).post('/tokens', request,
      {
        headers: {
          'Authorization': `Basic ${btoa(EnvironmentFactory.ENV.REACT_APP_OPENPAY_API_KEY! + ':')}`,
          'Content-Type': 'application/json',
        }
      }
    )
  }
}
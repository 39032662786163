import { useEffect, useState } from 'react';
import { Document as ReactPdfDocument, Page, pdfjs } from 'react-pdf';
import { Spinner, Text } from 'src/components';
import React from "react";
import './document.factory.scss';

export namespace DocumentFactory {

  interface IDocument {

    pdfUrl: string;


  }

  const useDocument = () => {
    const [documentLoaded, setDocumentLoaded] = useState<boolean>(false);

    const onLoad = (): void => {
      setDocumentLoaded(true);
    }

    useEffect(
      () => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }, []
    )

    return { onLoad, documentLoaded }
  }

  export const Document = React.memo(
    (props: IDocument) => {
      const { onLoad, documentLoaded } = useDocument();

      const options = {
        cMapUrl: 'cmaps/',
        standardFontDataUrl: 'standard_fonts/',
      };
  
      return (
        <div className="documentWrapper">
          <ReactPdfDocument
  
            file={{ url: props.pdfUrl }}
            onLoadSuccess={() => onLoad()} options={options}
            loading={<Spinner color="main" relative />}
          >
            <Page pageNumber={1} width={250}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              onClick={() => window.open(props.pdfUrl, '_blank')}
            />
          </ReactPdfDocument>
          {
            documentLoaded &&
            <Text type="label" align="center" block className="documentWrapper__link">
              <a rel="noreferrer" href={props.pdfUrl} target="_blank"><i>Ver documento completo</i></a>
            </Text>
          }
  
  
        </div>
      )
    }
  )
}

import { Util } from "src/utils/Util";
import { IClaimPaymentNegotationRequest } from "../interfaces/IClaimPaymentNegotationRequest";

export namespace ClaimProposalAdapter {


  /**
   * 
   * @param proposal 
   * @returns 
   */
  export const approveProposalNegotationAdapter = (signature: string): IClaimPaymentNegotationRequest => {
    const data: IClaimPaymentNegotationRequest = {
      status: Util.STATUS_CODE.CLAIM_PROPOSAL_NEGOTATION_STATUS.APPROVE,
			signature: signature
    }

    return data;
  }
}
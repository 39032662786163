import { Checkbox } from "src/components";
import ButtonSection from "../../molecules/ButtonSection/ButtonSection";
import { ClaimProposalFacade } from "src/modules/claim-payments/controller/facades/claim-proposal.facade";
import { ClaimProposalFormFacade } from "src/modules/claim-payments/controller/facades/claim-proposal-form.facade";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { Util } from "src/utils/Util";
import "./ProposalPage.scss";
import ESign from "src/libs/ESign/ui/template/ESign";

/**
 *
 */
const ProposalPage = (): JSX.Element => {
  const { currentDamageClaim, approveProposal, isLoading, eSign, updateESign } = ClaimProposalFacade.useClaimProposal();
  const { register, formState, getValues } = ClaimProposalFormFacade.useClaimProposalForm();

  return (
    <>
      {
        currentDamageClaim && currentDamageClaim.proposal &&
        <div id="proposal-wrapper" className="flex column relative height-100">
          <h1 className="text-center">Datos del Siniestro</h1>
          <section>
            <div className="claim-details flex">
              <div className="item">
                <p>Fecha ocurrida del siniestro</p>
                <p>{currentDamageClaim?.getClaimDateFormat()}</p>
              </div>

              <div className="item">
                <p>Siniestro</p>
                <p>{currentDamageClaim?.damageClaimNumber}</p>
              </div>

              <div className="item">
                <p>Póliza</p>
                <p>{currentDamageClaim?.policyNumber}</p>
              </div>

              <div className="item">
                <p>Número de serie del vehículo</p>
                <p>{currentDamageClaim?.vehicle.vin}</p>
              </div>

              <div className="item">
                <p>Placa</p>
                <p>{currentDamageClaim?.vehicle.plate}</p>
              </div>

              <div className="item">
                <p>Marca</p>
                <p>{currentDamageClaim?.vehicle.brand?.name}</p>
              </div>
              <div className="item">
                <p>Modelo</p>
                <p>{currentDamageClaim?.vehicle.model?.name}</p>
              </div>

              <div className="item">
                <p>A&ntilde;o</p>
                <p>{currentDamageClaim?.vehicle.year?.value}</p>
              </div>

            </div>
          </section>

          <section>
            <div className="claim-terms">
              <h3>Ref: Solicitud de pago y finiquito del siniestro.</h3>
              <p>{Util.CONSTANT.CLAIMS_PROPOSAL_STATEMENT.PARAGRAPH_ONE(currentDamageClaim?.proposal.getFinalValue()!)}</p>
              <p>{Util.CONSTANT.CLAIMS_PROPOSAL_STATEMENT.PARAGRAPH_TWO}</p>
              <p>{Util.CONSTANT.CLAIMS_PROPOSAL_STATEMENT.PARAGRAPH_THREE}</p>
              <p>{Util.CONSTANT.CLAIMS_PROPOSAL_STATEMENT.PARAGRAPH_FOUR}</p>
            </div>
          </section>

          <section>
            <h1 className="text-center">Monto de la Indemnización</h1>

            <div className="claim-amount flex width-100">
              <div className="item">
                <p>Monto valuado</p>
                <p>{currentDamageClaim?.proposal.getClaimValue()}</p>
              </div>

              <div className="item">
                <p>Deducible</p>
                <p>{`${currentDamageClaim?.proposal.deductiblePercent} %`}</p>
              </div>

              <div className="item">
                <p>Monto deducible</p>
                <p>{currentDamageClaim?.proposal.getDeductibleValue()}</p>
              </div>

              <div className="item">
                <p>Monto final</p>
                <p>{currentDamageClaim?.proposal.getFinalValue()}</p>
              </div>
            </div>

						<ESign onApply={(sign) => {updateESign(sign!)}}/>
						{/* <p className="sign-text">Firma</p> */}

            {
              currentDamageClaim?.proposal.isPendingNegotiation() &&
              <form className="checkbox-accept-proposal flex justify-center">
                <Checkbox {...register("acceptProposal", { required: true })} disabled={isLoading}>
                  Acepto que se me pague el monto de indemnización propuesto.
                </Checkbox>
              </form>
            }
          </section>

          {
            currentDamageClaim?.proposal.isPendingNegotiation() &&
            <ButtonSection
              backHandler={() => {
                window.open(CLAIM_PAYMENTS_PATH.MESSAGE_AGENT, "_blank");
              }}
              nextHandler={() => {
                approveProposal(getValues().acceptProposal)
              }}
              nextButtonText="Aceptar"
              backButtonText="Hablar con un agente"
              nextButtonDisabled={!formState.isValid || !eSign}
							isLoading={isLoading}
            />
          }
        </div>
      }

    </>
  );
};

export default ProposalPage;

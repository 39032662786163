import classNames from "classnames";
import React, { PropsWithChildren, useState } from "react";
import { Label, Spinner } from "src/components";
import InputMask from 'react-input-mask';
import { IconFactory } from "src/factory/icon.factory";

import './InputLabel.scss';

/**
 *
 */
interface IInputLabel {

  errors?: boolean;

  required?: boolean;

  icon?: JSX.Element;

  number?: boolean;

	amount?: boolean;

  loading?: boolean;

  mask?: string;

	uppercase?: boolean;
}

/**
 *
 */
export const InputLabel = React.forwardRef(
  (
    props: IInputLabel & PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>>,
    ref: React.Ref<any>
  ): JSX.Element => {

    // Deconstruct our props
    const {
      errors = false,
      className,
      children,
      type,
      number,
			amount,
      loading,
      mask,
			uppercase,
      ...rest
    } = props;

    const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

    /**
     * 
     */
    const onTogleVisibility = () => {
      setPasswordVisibility(!passwordVisibility);
    }

    const inputWrapperClassNames = classNames({
      'input-label-wrapper': true,
      'invalid': errors ? true : false,
      'flex': true,
      'align-center': true,
      'input-disabled': props.disabled,
      'relative': true,
    })

    const inputClassNames = classNames({
      'height-100': true,
      'width-100': true,
      'with-icon': !!props.icon,
      'border-box': true,
			'uppercase': uppercase && uppercase === true
    })

    return (
      <div className={inputWrapperClassNames}>
        <Label required={props.required} className="absolute">{children}</Label>
        {!!props.icon &&
          <div className="icon-wrapper inline-flex justify-center align-center absolute">
            {props.icon}
          </div>
        }

        {
          type !== 'password' &&
          <>
            {
              !mask ?
                <input 
									className={`${inputClassNames} ${className ? className : ''}`}
                  {...rest} 
									ref={ref} 
									type={type} 
									onKeyPress={(e) => number && !amount && !/[0-9]/.test(e.key) && e.preventDefault()} 
									step={amount ? '.01' : undefined}
								>
                </input>
                :
									<InputMask 
										mask={mask} 
										alwaysShowMask={true} 
										maskPlaceholder={null} 
										className={`${inputClassNames} ${className ? className : ''}`}
										{...rest} 
										ref={ref} 
										type={type} 
										onKeyPress={(e) => number && !/[0-9]/.test(e.key) && e.preventDefault()}
										step={amount ? '.01' : undefined}
									/>
            }

            {
              loading &&
              <span className="input-loader absolute">
                <Spinner />
              </span>
            }
          </>
        }

        {
          type === 'password' &&
          <input className={`${inputClassNames} ${className ? className : ''}`} {...rest} ref={ref} type={passwordVisibility === true ? 'text' : 'password'}></input>
        }

        {
          type === 'password' && passwordVisibility !== undefined &&
          <button tabIndex={-1} className="absolute flex justify-center align-center" type="button" onClick={onTogleVisibility} is-visible={passwordVisibility ? '' : null}>
            {!passwordVisibility ? IconFactory.eyeIcon() : IconFactory.eyeOffIcon()}
          </button>
        }

      </div>
    );
  }
);
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { Coverage } from "src/classes/plan/Coverage";
import { Plan } from "src/classes/plan/Plan";
import { IPlanManagement } from "../interfaces/IPlanManagement";

/**
 *
 */
export namespace PlanManagementAction {

  /**
   * 
   */
  export const coverages: ActionCreatorWithPayload<Coverage[]> = createAction('PLAN_NEW_COVERAGES');
  /**
   * 
   */
  export const customizationState: ActionCreatorWithPayload<boolean> = createAction('PLAN_CUSTOMIZATION');
  /**
   * 
   */
  export const setUpdating: ActionCreatorWithPayload<boolean> = createAction('PLAN_SET_UPDATING_STATE');
  /**
   * 
   */
   export const clearManagement: ActionCreatorWithoutPayload = createAction('CLEAR_PLAN_MANAGEMENT');
  /**
   * 
   */
  export const quotation: ActionCreatorWithPayload<IPlanManagement> = createAction('GET_QUOTATION_PLAN');
  /**
   * 
   */
  export const planConfiguration: ActionCreatorWithPayload<IPlanManagement> = createAction('QUOTATION_PLAN_CONFIGURATION');
  /**
   * 
   */
  export const currentPlan: ActionCreatorWithPayload<Plan> = createAction('QUOTATION_CURRENT_PLAN');
}
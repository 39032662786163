import { useEffect } from "react";
import { useNavigate } from "react-router";
import PlanManagement from "src/libs/PlanManagement/ui/template/PlanManagement";
import { PolicyCreationFacade } from 'src/modules/policy-creation/controller/facades/policy-creation.facade';
import { PolicyPlanFacade } from 'src/modules/policy-creation/controller/facades/policy-plan.facade';
import { ReturnValidactionFacade } from 'src/modules/policy-creation/controller/facades/return-validation.facade';
import { POLICY_CREATION_PATH, VEHICLE_ROUTE_ID } from "src/routes/paths/policy-creation.paths";
import './PlanSelection.scss';

/**
 *
 */
interface IPlanSelection {

}

/**
 *
 */
const PlanSelection = (props: IPlanSelection): JSX.Element => {

  const { vehicle, coupon, coverages, plan } = PolicyCreationFacade.usePolicyCreationActors();
  const { selectPlan, clearQuotationVehicle, vehicleUser } = PolicyPlanFacade.usePlans();
  const { onReturn, steps } = ReturnValidactionFacade.useReturnValidation();
	const navigate = useNavigate();
	
	const useMountEffect = ( ) => {
		useEffect(() => {
			if (!vehicle) {
				navigate(POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_DATA), { replace: true });
			}
		},[])
	};
	useMountEffect();

  return (
    <div className="policyCreationStepWrapper height-100 width-100">
      <PlanManagement
				storedPlan={plan}
        user={vehicleUser}
        vehicle={vehicle}
        onSelectPlan={selectPlan}
        onCancel={() => {clearQuotationVehicle(); onReturn(steps.selection, POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_DATA))}}
        coupon={coupon}
        coverages={coverages}
        onBackButton={() => onReturn(steps.selection, POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_DATA))}
			/>
    </div>
  );
};

export default PlanSelection;
import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Person  } from "src/classes/Person";
import { CoreUserInfoFeature } from "src/core/features/user-info.feature";
import { CoreSelector } from "src/core/selectors/core.selector";
import { AlertFactory } from "src/factory/alert.factory";
import { POLICY_ENDORSEMENTS_PATH } from "src/routes/paths/policy-endorsements.paths";
import { Util } from "src/utils/Util";
import { PolicyEndorsementsAction } from "../actions/policy-endorsements.action";
import { IdentityValidationFeature } from "../features/identity-validation.feature";

const NO_STATUS: number = -1;

export namespace IdentityValidationFacade {
	/** */
	const truoraURL: string = 'https://identity.truora.com/?token=';
	/** 10 seconds. */
	const CHECK_IDENTITY_STATUS_DELAY: number = 10000;

	/** */
  export const useIdentityValidation = () => {
		/** */ 
		const user = useSelector(CoreSelector.currentUser);

		const [isLoading, updateIsLoading] = useState<boolean>(false);
		const [identityStatus, updateIdentityStatus] = useState<number>(-1);
		const [timeoutSemaphore, updateTimeoutSemaphore] = useState<boolean>(false);
		const [identityErrorStatus, updateIdentityErrorStatus] = useState<number>(-1);
		//const [uploadIsClicked, updateUploadIsClicked] = useState<boolean>(false);
		const intervalRef = useRef<number>(0);

		const { policyNumber } = useParams<string>();
		//const location = useLocation();
		const navigate = useNavigate();
		const dispatch = useDispatch();

		/** */
		const getTruoraToken = async() => { 
			//updateUploadIsClicked(true);
      try {
        updateIsLoading(true);
				const token: string = await IdentityValidationFeature.createTruoraTokenFeature();
				truoraRedirection(token);
			} catch (e) {
				const error = e as Error;
				AlertFactory.errorAlert(error.message);
			} finally {
				updateIsLoading(false);
				checkIdentityStatus();
			}
		};

		/** */
		const getTruoraURL = (_token: string): string => {
			const url: string = `${truoraURL}${_token}`;
			return url;
		}

		/** */
		const truoraRedirection = (_token: string) => {
			window.open(getTruoraURL(_token), '_blank');
		};
		
		const { checkIdentityStatus, checkIdentityInterval } = useMemo(() => {
			const checkIdentityStatus = async() => {
				try {
					const status = await CoreUserInfoFeature.getUserIdentityStatusFeature();
	
					if(status.status !== identityStatus)
						updateIdentityStatus(status.status);
	
					if(status.status === Util.STATUS_CODE.IDENTITY_STATUS.DENIED){
						//updateUploadIsClicked(false);
						updateIdentityErrorStatus(status.errorCode);
					}
	
					if(status.status === Util.STATUS_CODE.IDENTITY_STATUS.COMPLETE){
						let _user: Person = user;
						_user.profile!.identityStatus = status.status;
	
						dispatch(PolicyEndorsementsAction.updateUserStatus(_user));
					}
	
					const statusCondition: boolean = status.status !== Util.STATUS_CODE.IDENTITY_STATUS.COMPLETE 
						&& status.status !== Util.STATUS_CODE.IDENTITY_STATUS.DENIED
						&& status.status !== NO_STATUS;
	
					const stopIntervalCondition: boolean = (status.status === Util.STATUS_CODE.IDENTITY_STATUS.COMPLETE 
						|| status.status === Util.STATUS_CODE.IDENTITY_STATUS.DENIED)
						&& intervalRef.current !== 0;
	
					if(statusCondition && intervalRef.current === 0){
						checkIdentityInterval();
					}else if(stopIntervalCondition){
						stopInterval();
					}
	
				} catch(e) {
					console.error(e);
					throw(e);
				}
			};

			const checkIdentityInterval = () => {
				if(intervalRef.current === 0){
					intervalRef.current = window.setInterval(() => {
						checkIdentityStatus();
					}, CHECK_IDENTITY_STATUS_DELAY);	
				}
			};
			return { checkIdentityStatus, checkIdentityInterval };
		}, [updateIdentityStatus, updateIdentityErrorStatus, identityStatus, dispatch, user]);
			
		/** */
		const stopInterval = () => {
			if(intervalRef.current !== 0){
				clearInterval(intervalRef.current);
				intervalRef.current = 0;
			}
		};

		/** */
		const navigateForward = () => {
			navigate(POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_VERIFICATION(policyNumber!));
		};

		/** */
		useEffect(() => {
			if(!timeoutSemaphore){
				updateTimeoutSemaphore(true);

				if(user && user.profile){
					updateIdentityStatus(user.profile.identityStatus);
	
					if(user.profile.identityStatus !== Util.STATUS_CODE.IDENTITY_STATUS.COMPLETE && user.profile.identityStatus !== Util.STATUS_CODE.IDENTITY_STATUS.DENIED)
						checkIdentityInterval();
				
					if(user.profile.identityStatus === Util.STATUS_CODE.IDENTITY_STATUS.DENIED)
						checkIdentityStatus();
				}
			}
		}, [user, timeoutSemaphore, checkIdentityInterval, checkIdentityStatus]);

		/** */
		useEffect(() => {
			return () => {
				stopInterval();
			}
		}, []);

    return { isLoading, policyNumber, identityStatus, identityErrorStatus, NO_STATUS, getTruoraToken, navigateForward };
  }
}
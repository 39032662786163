import { Endorse } from "src/classes/policy/Endorse";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { IEndorsementsData } from "../interfaces/IEndorsementsData";

export namespace PolicyEndorsementsHistoryAdapter {

  /**
   * 
   * @param endorsementsData 
   */
  export const endorsementsHistoryDataAdapter = (endorsementsData: IEndorsementsData): Endorse[] => {
    let endorsements: Endorse[] = [];
    for (let data of endorsementsData.data) {
      let newEndorse: Endorse = new Endorse(new Date(data.updatedAt));

      if (data.vehicle) {
        let vehicleAfter: Vehicle = new Vehicle();
        let vehicleBefore: Vehicle = new Vehicle();
        if (data.vehicle.before.vin !==  data.vehicle.after.vin) {
          vehicleBefore.vin = data.vehicle.before.vin;
          vehicleAfter.vin = data.vehicle.after.vin;
        }

        if (data.vehicle.before.plates !==  data.vehicle.after.plates) {
          vehicleBefore.plate = data.vehicle.before.plates;
          vehicleAfter.plate = data.vehicle.after.plates;
        }

        newEndorse.vehicle = { after: vehicleAfter, before: vehicleBefore }
        endorsements.push(newEndorse);
      }
    }
    
    return endorsements
  }
}
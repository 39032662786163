import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IDocumentRequest } from "../interfaces/IDocumentRequest";
import { IDocumentData } from "../interfaces/IDocumentData";
import { IDocumentSignatureRequest } from "../interfaces/IDocumentSignatureRequest";

export namespace DocumentSignatureService {

  /**
   * 
   * @param request 
   * @returns 
   */
  export const getClaimFormsDocumentService = (request: IDocumentRequest): AxiosPromise<IDocumentData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.DOCUMENT_SIGNATURE_CLAIM_FORMS(request.id, request.key));
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const claimFormsDocumentService = (request: IDocumentSignatureRequest): AxiosPromise<IDocumentData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).put(Util.API_PATH.DOCUMENT_SIGNATURE_CLAIM_FORMS(), request);
  }
}
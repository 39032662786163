import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Person  } from 'src/classes/Person';
import { IInstanceStateReducer } from "src/store/states/core-state/IInstanceStateReducer";


export namespace CoreInstanceSlice {

  /**
   * 
   */
  const initialState: IInstanceStateReducer = {
    user: undefined,
    maxProducts: false,
    pageMenu: false,
    openMenu: false,
  };

  /**
   * 
   * @param state 
   * @param action 
   */
  const currentUser: CaseReducer<IInstanceStateReducer, PayloadAction<Person>> = (state, action) => {
    state.user = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const maxProducts: CaseReducer<IInstanceStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.maxProducts = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const pageMenu: CaseReducer<IInstanceStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.pageMenu = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const openMenu: CaseReducer<IInstanceStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.openMenu = action.payload;
  }



  /**
   * 
   * @param state 
   * @param action 
   */
  const clear: CaseReducer<IInstanceStateReducer> = (state) => {
    state.user = undefined;
    state.maxProducts = false;
    state.pageMenu = false;
    state.openMenu = false;
  }


  const reducers = {
    currentUser,
    maxProducts,
    pageMenu,
    openMenu,
    clear,
  };

  /**
   * 
   */
  const instanceSlice = createSlice({
    name: 'instance',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = instanceSlice;
}




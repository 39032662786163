import { StatusTypeItem } from "./StatusTypeItem";

export class License {

  /**
   *
   */
  private _number: string;
  set number(_number: string) { this._number = _number };
  get number(): string { return this._number };

  /**
   *
   */
  private _stateName: string;
  set stateName(_stateName: string) { this._stateName = _stateName };
  get stateName(): string { return this._stateName };

  /**
   *
   */
  private _startDate: Date | undefined;
  set startDate(_startDate: Date | undefined) { this._startDate = _startDate };
  get startDate(): Date | undefined { return this._startDate };

  /**
   *
   */
  private _endDate: Date | undefined;
  set endDate(_endDate: Date | undefined) { this._endDate = _endDate };
  get endDate(): Date | undefined { return this._endDate };

  /**
   *
   */
  private _type: StatusTypeItem | undefined;
  set type(_type: StatusTypeItem | undefined) { this._type = _type };
  get type(): StatusTypeItem | undefined { return this._type };

  constructor(_number?: string) {
    this._number = _number? _number : '';
    this._stateName = '';
  }
}
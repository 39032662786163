const BASE_URL: string = '/insurance/vehicle';

export const INSURANCE_INIT_PARAMS = {
  INFO: {
    FIRST: 'first-step',
    SECOND: 'second-step',
    THIRD: 'third-step',
    FOURTH: 'fourth-step'
  }
}

/**
 * 
 */
export const INSURANCE_VEHICLE_PATH = {

  INSURANCE: BASE_URL,
  INIT: `${BASE_URL}/init`,
  PICTURES: `${BASE_URL}/pictures`,
  COMPLETION: `${BASE_URL}/completion`,
}
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Person  } from "src/classes/Person";
import { CoreSelector } from "src/core/selectors/core.selector";
import { AlertFactory } from "src/factory/alert.factory";
import { DASHBOARD_PATH } from "src/routes/paths/dashboard.paths";
import { SecurityFeature } from "../features/security.feature";

export namespace SecurityFacade {


  /**
   * 
   */
  export const useChangePassword = () => {

    const currentUser = useSelector(CoreSelector.currentUser);
    const [newPassword, setNewPassword] = useState<string>('');
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(false);
    const navigate = useNavigate();

    /**
     * 
     * @param isValid 
     * @param password 
     */
    const validateNewPassword = (isValid: boolean, password?: {password: string, confirmPassword: string}): void => {
      if (isValid && password && password.password === password.confirmPassword) {
        setNewPassword(password.password);
        setIsNewPasswordValid(true);
      } else {
        setIsNewPasswordValid(false);
      }
    }

    /**
     * 
     */
    const changePassword = async (currentPassword: string): Promise<void> => {
      let user: Person = new Person(currentUser.email);
      user.password = currentPassword;
      try {
        setIsUpdating(true);
        await SecurityFeature.changePasswordFeature(user, newPassword);
        AlertFactory.successAlert('Tu contraseña ha sido actualizada');
        navigate(DASHBOARD_PATH.DASHBOARD);
      } catch (e) {
        if ((e as Error).message) {
          AlertFactory.errorAlert((e as Error).message);
        }
      } finally {
        setIsUpdating(false);
      }
    }

    return { isNewPasswordValid, isUpdating, validateNewPassword, changePassword }
  }
}
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleDataAdapter } from "../adapters/vehicle-data.adapter";
import { PolicyVehicleService } from "../services/policy-vehicle.service";
import { CoreVehicleStoreFeature } from "src/core/features/vehicle-storage.feature";

/**
 *
 */
export namespace PolicyVehicleFeature {

  /**
   * 
   * @param newVehicle 
   */
  export const createVehicleFeature = async (newVehicle: Vehicle): Promise<void | undefined> => {
    try {
      const data = VehicleDataAdapter.vehicleCreationRequestAdapter(newVehicle);
      await PolicyVehicleService.createVehicleService(data);
			CoreVehicleStoreFeature.storeVehicleFeature(newVehicle);
    } catch (e) {
      console.error(e);
      throw e;
    }
  } 
}
import { StatusTypeItem } from "./StatusTypeItem";

export class ClaimRegisterPicture {

  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };

  /**
   *
   */
  private _url: string;
  set url(_url: string) { this._url = _url };
  get url(): string { return this._url };

  /**
   *
   */
  private _name: string;
  set name(_name: string) { this._name = _name };
  get name(): string { return this._name };

  /**
   *
   */
  private _type: StatusTypeItem | undefined;
  set type(_type: StatusTypeItem | undefined) { this._type = _type };
  get type(): StatusTypeItem | undefined { return this._type };

  constructor(_id: string, _url: string, _name: string) {
    this._id = _id;
    this._url = _url;
    this._name = _name;
  }
}
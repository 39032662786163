import { AxiosPromise } from "axios";
import { Util } from "src/utils/Util";
import { IAutomaticRenewalRequest } from "../interfaces/policy/IAutomaticRenewalRequest";
import { IPolicyHasPicture } from "../interfaces/policy/IPolicyHasPicture";
import { IPolicyLimitData } from "../interfaces/policy/IPolicyLimitData";
import { IPolicyListData } from "../interfaces/policy/IPolicyListData";
import { IPolicyMonthlyPaymentRequest } from "../interfaces/policy/IPolicyMonthlyPaymentRequest";
import { IPolicyPaymentData } from "../interfaces/policy/IPolicyPaymentData";
import { IPolicyPaymentRequest } from "../interfaces/policy/IPolicyPaymentRequest";
import { IPolicyRequest } from "../interfaces/policy/IPolicyRequest";
import { IPolicyRequestData } from "../interfaces/policy/IPolicyRequestData";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";

export namespace CorePolicyService {

  /**
   * 
   * @returns 
   */
  export const policyListService = (): AxiosPromise<IPolicyListData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_LIST(new Date().getTime()));
  }

  /**
   * 
   * @returns 
   */
  export const policyHasPicturesService = (policyId: string): AxiosPromise<IPolicyHasPicture> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_HAS_PICTURES(policyId));
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const policyRequestService = (data: IPolicyRequest): AxiosPromise<IPolicyRequestData> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.POLICY_REQUEST, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const policyPaymentService = (data: IPolicyPaymentRequest): AxiosPromise<IPolicyPaymentData> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.POLICY_PAYMENT, data);
  }

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	export const policyMonthlyPaymentService = (data: IPolicyMonthlyPaymentRequest): AxiosPromise<IPolicyPaymentData> => {
		return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.POLICY_MONTHLY_PAYMENT, data);
	}

  /**
   * 
   * @returns 
   */
  export const policiesMaxLimitService = (): AxiosPromise<IPolicyLimitData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_ALLOW_NEW_POLICY);
  }

	/**
   * 
   * @returns 
   */
	export const automaticRenewalService = (data: IAutomaticRenewalRequest): AxiosPromise<void> => {
		return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).post(Util.API_PATH.POLICY_AUTOMATIC_RENEWAL, data);
	}
}
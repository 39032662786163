import { AxiosResponse } from "axios";
import { IAuthTokenData } from "src/core/interfaces/auth/IAuthTokenData";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { ILoginData } from "../interfaces/login/ILoginData";


export namespace CoreLoginService {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const loginService = (data: ILoginData): Promise<AxiosResponse<IAuthTokenData>> => {
    return EnvironmentFactory.Http.PublicHttp().post(Util.API_PATH.USER_LOGIN, data);
  }
}

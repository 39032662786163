import PasswordValidator from "password-validator";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { CoreSelector } from "src/core/selectors/core.selector";
import { IPasswordManagmentForm } from "../interfaces/IPasswordManagmentForm";

/**
 *
 */
export namespace PasswordManagmentFacade {

  const PATTERN_REPEATED_ELEMENTS = /(.)\1{2}/;

  const PATTERN_SPECIFIC_WORD = /CRABI/;

  const PATTERN_SECUENTIAL_ELEMENTS = /(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(890)|(098)|(987)|(876)|(765)|(654)|(543)|(432)|(321)|(210)|(ABC)|(BCD)|(CDE)|(DEF)|(EFG)|(FGH)|(GHI)|(HIJ)|(IJK)|(JKL)|(KLM)|(LMN)|(MNO)|(NOP)|(OPQ)|(PQR)|(QRS)|(RST)|(STU)|(TUV)|(UVW)|(VWX)|(WXY)|(XYZ)|(ZYX)|(YXW)|(XWV)|(WVU)|(VUT)|(UTS)|(TSR)|(SRQ)|(RQP)|(QPO)|(PON)|(ONM)|(NML)|(MLK)|(LKJ)|(KJI)|(JIH)|(IHG)|(HGF)|(GFE)|(FED)|(EDC)|(DCB)|(CBA)/;

  /**
   * 
   */
  const PASSWORD_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'La contraseña no cumple con los parametros requeridos.',
    },
    {
      key: 'secuentialElements',
      message: 'La contraseña no puede contener secuencias de 3 o más números o letras',
    },
    {
      key: 'repeatedElements',
      message: 'La contraseña no puede tener 3 o más caracteres iguales juntos.',
    },
    {
      key: 'patternSpecificWord',
      message: 'La contraseña no puede tener la palabra CRABI',
    },
    {
      key: 'invalidParams',
      message: 'La contraseña no cumple con los parametros requeridos.',
    },
    {
      key: 'includesCurrentEmail',
      message: 'La contraseña no puede tener el correo que estas registrando o registraste.',
    },
  ];

  const CONFIRM_PASSWORD_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'Confirma tu contraseña.',
    },
    {
      key: 'notSame',
      message: 'Las contraseña no coinciden.',
    },

  ]

  /**
   * 
   * @returns 
   */
  export const usePasswordValidation = () => {
    const { register, formState: { errors, isValid, dirtyFields }, watch, setError, clearErrors, getValues } = useForm<IPasswordManagmentForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );
    const user = useSelector(CoreSelector.currentUser);
    const [hasUppercase, setHasUppercase] = useState<boolean>(false);
    const [hasLowercase, setHasLowercase] = useState<boolean>(false);
    const [hasDigit, setHasDigit] = useState<boolean>(false);
    const [validLength, setValidLength] = useState<boolean>(false);

    const passwordValidator = new PasswordValidator();
    passwordValidator
      .is().min(8)
      .has().uppercase()
      .has().lowercase()
      .has().digits()
      .has().not().spaces()

    /**
     * 
     */
    const validateOnChange = (password: string, confirmPassword: string): void => {
      if (password === '') {
        setHasUppercase(false);
        setHasLowercase(false);
        setHasDigit(false);
        setValidLength(false);
      }
      
      if (password && dirtyFields.confirmNewPassword && password !== confirmPassword) {
        setError('confirmNewPassword', {type: 'notSame'});
      } else if (password && dirtyFields.confirmNewPassword && password === confirmPassword) {
        clearErrors('confirmNewPassword');
      }
    }

    /**
     * 
     */
    const validatePasswordParams = (password: string): boolean => {
      const invalidParams: string[] = passwordValidator.validate(password, { list: true }) as string[];
      setHasUppercase(invalidParams.find(p => p === 'uppercase') ? false : true);
      setHasLowercase(invalidParams.find(p => p === 'lowercase') ? false : true);
      setHasDigit(invalidParams.find(p => p === 'digits') ? false : true);
      setValidLength(invalidParams.find(p => p === 'min') ? false : true);
      return passwordValidator.validate(password) as boolean;
    }

    /**
     * 
     */
    const validateSpecificWord = (password: string): boolean => {
      return !PATTERN_SPECIFIC_WORD.test(password.toLocaleUpperCase());
    }

    /**
     * 
     */
    const validateRepeatedElements = (password: string): boolean => {
      return !PATTERN_REPEATED_ELEMENTS.test(password.toLocaleUpperCase());
    }

    /**
     * 
     */
    const validateSecuentialElements = (password: string): boolean => {
      return !PATTERN_SECUENTIAL_ELEMENTS.test(password.toLocaleUpperCase());
    }

    /**
     * 
     */
    const validateCurrentEmail = (password: string, email?: string): boolean => {
      let emailName: string = '';
      if (email) {
        emailName = email.split('@')[0];
        return !password.toLocaleUpperCase().includes(emailName.toLocaleUpperCase());
      } else if (user) {
        emailName = user.email.split('@')[0];
        return !password.toLocaleUpperCase().includes(emailName.toLocaleUpperCase());
      } else {
        return true;
      }

    }

    /**
     * 
     */
    const validateSamePassword = (confirmNewPassword: string, newPassword: string): boolean => {
      return confirmNewPassword === newPassword;
    }

    return {
      register, formState: { errors, isValid, dirtyFields }, watch, setError, clearErrors, getValues,
      PASSWORD_ERRORS,
      CONFIRM_PASSWORD_ERRORS,
      passwordState: { hasUppercase, hasLowercase, hasDigit, validLength },
      validatePassword: { 
        validatePasswordParams, validateSpecificWord, validateRepeatedElements, 
        validateSecuentialElements, validateOnChange, validateCurrentEmail, validateSamePassword}
    }
  }
}
import { Spinner } from "src/components";
import { SignOutFacade } from "../../controller/facades/sign-out.facade";
import './SignOut.scss';

/**
 *
 */
interface ISignOut {

}

/**
 *
 */
const SignOutPage = (props: ISignOut): JSX.Element => {
  SignOutFacade.useSignOut();

  return (
    <div id="sign-out-wrapper">
      <Spinner />
    </div>
  );
};

export default SignOutPage;
import { Logo, Spinner } from "src/components";
import { Outlet } from "react-router-dom";
import { ClaimRegisterFacade } from "../../controller/facades/claim-register.facade";
import './ClaimRegister.scss';

/**
 *
 */
const ClaimRegisterPage = (): JSX.Element => {
  const { loading } = ClaimRegisterFacade.useClaimRegister();

  return (
    <div className="claimRegister">
      {
        loading.value ?
          <div className="claimRegister__logo">
            <Logo type="full" />
            <Spinner color="main" relative />
          </div>
          :
          <Outlet />
      }

    </div>
  );
};

export default ClaimRegisterPage;
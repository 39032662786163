import { RootState } from "src/store/store";

/**
 *
 */
export namespace PolicyRenewalSelector {

  export const currentPolicy = (state: RootState) => state.policyRenewal?.currentPolicy!;

  export const plan = (state: RootState) => state.policyRenewal?.plan!;

  export const invalidPolicy = (state: RootState) => state.policyRenewal?.invalidPolicy!;
}
import { ButtonLink, Spinner } from 'src/components';
import { PolicyFacade } from 'src/modules/policy/controller/facades/policy.facade';
import { POLICY_ENDORSEMENTS_PATH } from 'src/routes/paths/policy-endorsements.paths';
import { Util } from 'src/utils/Util';
import './PolicyEndorsementsHistory.scss';

/**
 *
 */
const PolicyEndorsementsHistoryPage = (): JSX.Element => {
  const { selectedPolicy, endorsements } = PolicyFacade.usePolicyManagement();

  return (
    <div className="policyEndorsementsHistory height-100 relative flex column">
      {
        !selectedPolicy && !endorsements &&
        <Spinner color="main" circles />
      }

      {
        selectedPolicy && endorsements &&
        <>
          {
            endorsements.length > 0 &&
            <>
              <div className="policyEndorsementsHistory__wrapper">
                {
                  endorsements.map(
                    (e, index) => {
                      return (
                        <div className="policyEndorsementsHistory__wrapper__endorsement" key={index}>
                          <h3>Endoso #{endorsements.length - index} - {e.getDate()}</h3>
                          <ul>
                            {
                              e.vehicle?.after.vin &&
                              <li>
                                <p><span>Cambio de VIN</span> de <i>{e.vehicle?.before.vin}</i> a <i>{e.vehicle?.after.vin}</i></p>
                              </li>
                            }
                            {
                              e.vehicle?.after.plate &&
                              <li>
                                <p><span>Cambio de Placas</span> de <i>{e.vehicle?.before.plate}</i> a <i>{e.vehicle?.after.plate}</i></p>
                              </li>
                            }
                          </ul>
                        </div>
                      )
                    })
                }
              </div>
              <div className="policyEndorsementsHistory__button-wrapper policyEndorsementsHistory__button-wrapper--bottom">
                <ButtonLink type="button" to={POLICY_ENDORSEMENTS_PATH.ENDORSEMENT(selectedPolicy.policyNumber)}>
                  Solicitar cambios
                </ButtonLink>
              </div>
            </>
          }

          {
            endorsements.length === 0 &&
            <div className="policyEndorsementsHistory__empty height-100 flex column align-center justify-center">
              <span className="policyEndorsementsHistory__empty__image background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_MANAGEMENT_ENDORSEMENT})` }}></span>
              <h3 className="text-center">Por ahora no tienes <br /><b>Modificaciones en tu p&oacute;liza</b></h3>
              <h4 className="text-center">No hay registros de cambios en tu p&oacute;liza, puedes solicitarlo si necesitas uno.</h4>

              <div className="policyEndorsementsHistory__button-wrapper">
                <ButtonLink type="button" to={POLICY_ENDORSEMENTS_PATH.ENDORSEMENT(selectedPolicy.policyNumber)}>
                  Solicitar cambios
                </ButtonLink>
              </div>
            </div>
          }

        </>
      }
    </div>
  );
};

export default PolicyEndorsementsHistoryPage;
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { CoreVinValidationFeature } from "src/core/features/vin-validation.feature";
import { Util } from "src/utils/Util";
import { IVehicleClaimsForm } from "../interfaces/IVehicleClaimsForm";
import { IVehiclePolicyForm } from "../interfaces/IVehiclePolicyForm";

export namespace VehicleManagementFormFacade {

  /**
   * 
   */
  const VIN_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'VIN obligatorio'
    },
    {
      key: 'pattern',
      message: 'El formato del VIN es inválido.',
    },
    {
      key: 'invalidVin',
      message: 'El VIN que ingresaste ya está en uso con otra póliza.'
    }
  ];

  /**
   * 
   */
  const PLATE_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'Placa obligatoria'
    },
    {
      key: 'pattern',
      message: 'El formato de la placa es inválido.',
    },
    {
      key: 'minLength',
      message: 'La placa debe tener al menos 5 caracteres.',
    },
    {
      key: 'maxLength',
      message: 'La placa no debe de tener más de 7 caracteres.',
    }
  ];

  /**
   * 
   */
  const BRAND_NAME_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'Marca obligatoria'
    },
    {
      key: 'pattern',
      message: 'La marca es inválida.',
    },
    {
      key: 'minLength',
      message: 'La marca es inválida.',
    },
  ];
  
  /**
   * 
   */
  const MODEL_NAME_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'Modelo obligatorio'
    },
    {
      key: 'pattern',
      message: 'El modelo es inválido.',
    },
    {
      key: 'minLength',
      message: 'El modelo es inválido.',
    },
  ];
  
  /**
   * 
   */
  const YEAR_VALUE_ERRORS: TFormErrorMessage[] = [
    {
      key: 'required',
      message: 'Año obligatoria'
    },
    {
      key: 'pattern',
      message: 'El año es inválido.',
    },
    {
      key: 'minLength',
      message: 'El año es inválido.',
    },
    {
      key: 'maxLength',
      message: 'El año es inválido.',
    },
  ];
  
  /**
   * 
   * @returns 
   */
  export const useVehiclePolicyForm = (storedVehicle?: Vehicle) => {
    const [isValidatingVin, setIsValidatingVin] = useState<boolean>(false)
    const [validatedVin, setValidatedVin] = useState('');
    const [previousValidation, setPreviousValidation] = useState<boolean | null>(null);
    const { register, formState: { errors, isValid, touchedFields }, trigger, watch, setValue, getValues } = useForm<IVehiclePolicyForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    /**
     * 
     */
    const validateDefaultData = useCallback((storedVehicle: Vehicle): void => {
      if (storedVehicle && storedVehicle.vin && !touchedFields.vin) {
        setValue('vin', storedVehicle.vin, { shouldValidate: true, shouldTouch: true });
      }

      if (storedVehicle && storedVehicle.plate && !touchedFields.plate) {
        setValue('plate', storedVehicle.plate, { shouldValidate: true, shouldTouch: true });
      }

			if (storedVehicle.vin || storedVehicle.plate)
      	trigger();
    }, [setValue, touchedFields, trigger]);

    /**
     * 
     * @param vin 
     * @returns 
     */
    const validateVehicleVin = async (vin: string): Promise<boolean | undefined> => {
      try {
        if (Util.PATTERN.VEHICLE_VIN.test(vin.toUpperCase())) {
          if (validatedVin !== vin) {
            setIsValidatingVin(true);
            setValidatedVin(vin);
            const validation = await CoreVinValidationFeature.validateVinFeature(vin.toUpperCase());
            setPreviousValidation(validation)
            return validation;
          } else {
            return previousValidation as boolean;
          }

        }
      } catch (e) {

      } finally {
        setIsValidatingVin(false);
      }
    }



    useEffect(() => {
      if (storedVehicle) {
        validateDefaultData(storedVehicle);
      }

    }, [storedVehicle, validateDefaultData])

    return {
      form: { register, formState: { errors, isValid, touchedFields }, trigger, watch, getValues },
      isValidatingVin, validateVehicleVin, VIN_ERRORS, PLATE_ERRORS
    }
  }

  /**
   * 
   */
  export const useVehicleClaimsForm = (storedVehicle?: Vehicle, isInputsFields?: boolean) => {
    const { register, formState: { errors, isValid, touchedFields }, getValues, reset, setValue, trigger } = useForm<IVehicleClaimsForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

		/**
     * 
     */
		const validateDefaultData = useCallback((storedVehicle: Vehicle): void => {
			if (storedVehicle && !touchedFields.plate) {
				setValue('plate', storedVehicle.plate, { shouldValidate: true, shouldTouch: true });
			}

      if (storedVehicle && !touchedFields.vin) {
				setValue('vin', storedVehicle.vin, { shouldValidate: true, shouldTouch: true });
			}

			if(isInputsFields && storedVehicle && !touchedFields.brandName){
				setValue('brandName', storedVehicle.brand!.name, { shouldValidate: true, shouldTouch: true })
			}

			if(isInputsFields && storedVehicle && !touchedFields.modelName){
				setValue('modelName', storedVehicle.model!.name, { shouldValidate: true, shouldTouch: true })
			}

			if(isInputsFields && storedVehicle && !touchedFields.yearValue){
				setValue('yearValue', storedVehicle.year!.value.toString(), { shouldValidate: true, shouldTouch: true })
			}

			trigger();

		}, [setValue, touchedFields, trigger, isInputsFields]);

    /**
     * 
     */
    const resetClaimsData = useCallback(() => {
      reset({brandName: '', modelName: '', yearValue: '', plate: getValues().plate, vin: getValues().vin})
    }, [reset, getValues]);

    useEffect(() => {
      if (storedVehicle) {
        validateDefaultData(storedVehicle);
      }

    }, [storedVehicle, validateDefaultData])

    return {
      form: { register, formState: { errors, isValid, touchedFields }, getValues },
      PLATE_ERRORS, BRAND_NAME_ERRORS, MODEL_NAME_ERRORS, YEAR_VALUE_ERRORS,VIN_ERRORS, resetClaimsData
    }
  }
}
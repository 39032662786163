
import { Policy } from "src/classes/Policy";
import { CoreQuotationDataAdapter } from "src/core/adapters/quotation-data.adapter";
import { Util } from "src/utils/Util";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { VehicleVersion } from "src/classes/vehicle/VehicleVersion";
import { IPolicyListData } from "../interfaces/policy/IPolicyListData";
import { IPolicyProductData } from "../interfaces/policy/IPolicyProductData";
import { IPolicyData } from "../interfaces/policy/IPolicyData";

export namespace CorePolicyDataAdapter {

  /**
   * 
   * @param policiesData 
   * @returns 
   */
  const getValidPolicyData = (policiesData: IPolicyData[]): IPolicyData | undefined => {
    const data: IPolicyData | undefined = policiesData.find(pd => !pd.number.includes('XZ'))
    return data
  }

  /**
   * 
   * @param productData 
   * @returns 
   */
  const createPolicy = (productData: IPolicyProductData): Policy | undefined => {
    let newPolicy: Policy;
    const policyData = getValidPolicyData(productData.policies);
    if (policyData) {
      const vehicleData = policyData.vehicle;
      const policyNumber = policyData.number;
      if (policyData.end_date && policyData.start_date) {
        newPolicy = new Policy(policyNumber, new Date(policyData.end_date), new Date(policyData.start_date));
        newPolicy.expirationDate?.setUTCMinutes(0);
        newPolicy.startDate?.setUTCMinutes(0);
      } else {
        newPolicy = new Policy(policyNumber);
      }
      let vehicle: Vehicle = new Vehicle();
      if (vehicleData.brand && vehicleData.model && vehicleData.year) {
        vehicle.brand = new VehicleBrand(undefined, vehicleData.brand.name.charAt(0).toUpperCase() + vehicleData.brand.name.slice(1).toLowerCase());
        vehicle.model = new VehicleModel(undefined, undefined, vehicleData.model.name.charAt(0).toUpperCase() + vehicleData.model.name.slice(1).toLowerCase());
        vehicle.year = new VehicleYear(undefined, undefined, parseInt(vehicleData.year.name));
      } else {
        vehicle.brand = new VehicleBrand();
        vehicle.model = new VehicleModel();
        vehicle.year = new VehicleYear();
      }

      if (vehicleData.version && vehicleData.version.name) {
        vehicle.version = new VehicleVersion(vehicleData.version.code, vehicleData.version.name.charAt(0).toUpperCase() + vehicleData.version.name.slice(1).toLowerCase());
      }

      vehicle.plate = vehicleData.plates;
      vehicle.vin = vehicleData.vin;

      newPolicy.id = policyData.id;
      if (productData.payment_status) {
        newPolicy.paymentStatus = productData.payment_status;
      }

      if (productData.product_payment_type_id) {
        newPolicy.type = productData.product_payment_type_id;
      }

      newPolicy.coverages = CoreQuotationDataAdapter.coverageDataAdapter(policyData.coverages);
      newPolicy.vehicle = vehicle;

      // Renewal validation (60 days remaining)
      const differenceInTime = newPolicy.expirationDate?.getTime()! - new Date().getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if (Math.round(differenceInDays) <= 90) {
        newPolicy.allowRenewal = true;
      }
      //end Renewal validation

      return newPolicy;
    }

  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const policyListDataAdapter = (data: IPolicyListData): Policy[] => {
    let genericList: Policy[] = [];
    let policyActiveCanceledList: Policy[] = [];

    if (!data.products)
      return [];

    for (const productData of data.products) {
      let newPolicy = createPolicy(productData);
      if (newPolicy) {
        //Set policy status
        const policyData = getValidPolicyData(productData.policies);
        if (policyData) {
          const statusAction = policyData.action.code;
          const today: Date = new Date();
          if (!newPolicy.expirationDate || !newPolicy.startDate) {
            newPolicy.status = { pending: true };
          } else if ((statusAction === Util.STATUS_CODE.POLICY_STATUS_ACTION.ISSUE || statusAction === Util.STATUS_CODE.POLICY_STATUS_ACTION.REHABILITATED) && (today >= newPolicy.startDate && today < newPolicy.expirationDate)) {
            newPolicy.status = { active: true };
          } else if (statusAction === Util.STATUS_CODE.POLICY_STATUS_ACTION.CANCEL) {
            newPolicy.status = { canceled: true };
            newPolicy.allowRenewal = false;
          } else if (today > newPolicy.expirationDate) {
            newPolicy.status = { expired: true };
          } else if (today <= newPolicy.startDate && statusAction === Util.STATUS_CODE.POLICY_STATUS_ACTION.ISSUE) {
            newPolicy.status = { toBegin: true };
          }
          //end Set policy status
          genericList.push(newPolicy);
        }
      }
    }

    for (const policy of genericList) {
      // Renewal
      if (policy.vehicle?.vin && (policy.isActive())) {
        const previousPolicy = genericList.find(p => p.vehicle?.vin === policy.vehicle?.vin);
        if (previousPolicy && previousPolicy.toBegin()) {
          policy.renewalDate = previousPolicy.startDate;
          policy.allowRenewal = false;
        }
      } else if (policy.toBegin()) {
        const previousPolicy = genericList.find(p => p.vehicle?.vin === policy.vehicle?.vin && p.id !== policy.id);
        if (previousPolicy) {
          policy.renewed = true;
        }
      }
      //end Renewal

      if ((policy.isActive() || policy.isCanceled() || policy.toBegin() || policy.isPending()) && !policy.renewed) {
        policyActiveCanceledList.push(policy);
      } else if (policy.renewed && policy.toBegin()) {
        const expiredPolicy = genericList.find(p => p.vehicle?.vin === policy.vehicle?.vin && p.id !== policy.id);
        if (expiredPolicy) {
          policyActiveCanceledList.push(policy);
        }
      } else if (policy.isExpired()) {

        // Remove renewal option for those policies that are renewed
        const samePolicy = genericList.find(p => p.vehicle?.vin === policy.vehicle?.vin && p.id !== policy.id && !p.isExpired() && !p.isCanceled() && p.startDate);
        if (samePolicy) {
          policy.allowRenewal = false;
          policy.renewalDate = undefined;
        }

        policyActiveCanceledList.push(policy);
      }
    }

    return policyActiveCanceledList;
  }

  /**
   * 
   * @param data 
   * @param id 
   */
  export const policyByNumberDataAdapter = (data: IPolicyListData, number: string): Policy | undefined => {
    for (const productData of data.products) {
      const policyData = getValidPolicyData(productData.policies);
      if (policyData && policyData.number === number) {
        let newPolicy = createPolicy(productData);
        return newPolicy;
      }
    }
  }


  /**
   *
   */
  export const activePoliciesListDataAdapter = (data: IPolicyListData): Policy[] => {
    let policyList: Policy[] = [];
    const today: Date = new Date();

    for (const productData of data.products) {
      let newPolicy = createPolicy(productData);
      const policyData = getValidPolicyData(productData.policies);
      if (newPolicy && policyData) {
        const statusAction = policyData.action.code;
        if (statusAction === Util.STATUS_CODE.POLICY_STATUS_ACTION.ISSUE && (today >= newPolicy.startDate! && today < newPolicy.expirationDate!)) {
          newPolicy.status = { active: true };
        }
  
        if (newPolicy.isActive()) {
          policyList.push(newPolicy);
        }
      }
    }
    return policyList;
  };

}
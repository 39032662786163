import { FileAttachment } from "src/classes/FileAttachment";
import { IPictureUploadRequest } from "../interfaces/IPictureUploadRequest";
import { IPictureUploadResponse } from "../interfaces/IPictureUploadResponse";

export namespace PictureManagementAdapter {

  /**
   * 
   */
  export const pictureUploadRequestAdapter = (formData: FormData, attachment: FileAttachment): IPictureUploadRequest => {
    let data: IPictureUploadRequest = {
      id: attachment.serviceId,
      requirement: attachment.type,
			hash: attachment.uid,
      picture: formData
    }

    return data;
  }

	/**
	 * 
	 */
	export const pictureUploadResponseAdapter = (data: IPictureUploadResponse, attachment: FileAttachment): FileAttachment => {
		attachment.uid = data.details.hash;
		attachment.contentType = data.details.content_type;
		attachment.source = data.url;
		attachment.status = data.details.status;

		return attachment;
	}
}
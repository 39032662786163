import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { AlertFactory } from "src/factory/alert.factory";
import { UserEmailValidationFeature } from "../features/user-email-validation.feature";

export namespace UserEmailValidationFacade {

  export const useUserEmailValidation = () => {
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const { search } = useLocation();
    const navigate = useNavigate();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);


    /**
     * 
     */
    const validateEmail = useCallback(
      async () => {
        try {
          const token = searchParams.get('token');
          setIsValidating(true);
          if (token) {
            await UserEmailValidationFeature.validateEmailFeature(token);
          } else {
            AlertFactory.errorAlert('Acceso inválido.');
            navigate('/', {replace: true});
          }
        } catch (e) {
          AlertFactory.errorAlert((e as Error).message);
          setIsInvalid(true);
        } finally {
          setIsValidating(false);
        }
      }, [searchParams, navigate]
    )

    


    useEffect(
      () => {
        validateEmail();
      }, [validateEmail]
    )

    return { isValidating, isInvalid }
  }
}
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { IPolicyCreationVehicleRequest } from "../interfaces/IPolicyCreationVehicleRequest";

/**
 * 
 */
export namespace VehicleDataAdapter {

  /**
   * 
   * @param vehicle 
   * @returns 
   */
  export const vehicleCreationRequestAdapter = (vehicle: Vehicle): IPolicyCreationVehicleRequest => {
    const data: IPolicyCreationVehicleRequest = {
      vin: vehicle.vin,
      version_id: vehicle.version?.id as number,
      brand_id: vehicle.brand?.id as number,
      type_id: vehicle.model?.id as number,
      model: vehicle.year?.value as number,
      plates: vehicle.plate,
      color: vehicle.color
    }

    return data;
  }
}
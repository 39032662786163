import { Navigate } from "react-router";
import { LOGIN_PATH } from "src/routes/paths/login.paths";
import Login from "../ui/pages/Login";


/**
 * 
 */
export const LoginRoutes = (isValidAuth: boolean, redirect: string) => {
  return {
    path: LOGIN_PATH.LOGIN,
    element: !isValidAuth ? <Login /> : <Navigate to={redirect} />,
  }
}

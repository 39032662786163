import { useState } from "react";
import { Person  } from "src/classes/Person";

export namespace AccountDataFacade {

	/** */
	type Password = {
		password: string;
		confirmPassword: string;
	}

  /** */
  export const useAccountDataFacade = (storedPerson?: Person) => {
		/** */
		const [password, setPassword] = useState<string>('');
		const [email, setEmail] = useState<string>('');

		/** */
		const checkPasswordForm = (isValid: boolean, password?: Password) => {
			if(isValid && password)
				setPassword(password?.password);
			else
				setPassword('');
		}

    return { email, password, setEmail, checkPasswordForm }
  }
}
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInsuranceVehicleStateReducer } from "src/store/states/modules-state/IInsuranceVehicleStateReducer";

/**
 *
 */
export namespace InsuranceVehicleSlice {

  /**
   * 
   */
  const initialState: IInsuranceVehicleStateReducer = {
    isPicturesCompleted: false
  };


  /** */
  const clear: CaseReducer<IInsuranceVehicleStateReducer> = (state) => {
    state.isPicturesCompleted = false;
  }
  
  /** */
  const pictureCompleted: CaseReducer<IInsuranceVehicleStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.isPicturesCompleted = action.payload;
  }

  const reducers = {
    pictureCompleted,
    clear,
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'insuranceVehicle',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
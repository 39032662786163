import { Checkbox, Label, Radio, Dropdown, FormError, InputDate, InputLabel, InputPhone } from "src/components";
import { useEffect } from "react";
import { Person  } from "src/classes/Person";
import { Util } from "src/utils/Util";
import { PersonManagementAddressFormFacade } from "../../controller/facades/person-management-address-form.facade";
import { PersonManagementDataFormFacade } from "../../controller/facades/person-management-data-form.facade";
import { PersonManagementFacade } from "../../controller/facades/person-management.facade";

import './PersonManagement.scss';

/**
 *
 */
interface IPersonManagement {
  /** */
  storedPerson?: Person;
  /** */
  hideEmail?: boolean;
  /** */
  showEconomicActivities?: boolean;
  /** */
  onComplete?: (user?: Person) => void;
}

/**
 *
 */
const PersonManagement = (props: IPersonManagement): JSX.Element => {
  const { onComplete, storedPerson, hideEmail, showEconomicActivities } = props;
  const {
    verifyPostalCode,
    isValidatingPostalCode,
    currentPerson,
    setPersonValues,
    economicActivities,
    economicOwnBusiness,
    chosenEconomicActivity,
    isEconomicActivitiesValid,
    gender,
    setGender,
    setChosenEconomicActivity,
    setChosenOwnBusiness
  } = PersonManagementFacade.usePersonManagement(showEconomicActivities);
  const { formData, FORM_DATA_ERRORS, selectDate, selectCountryCode } = PersonManagementDataFormFacade.usePersonDataForm(storedPerson);
  const { formAddress, FORM_ADDRESS_ERRORS } = PersonManagementAddressFormFacade.usePersonAddressForm(storedPerson);
  const { validatePersonRfc, isValidatingRFC } = PersonManagementDataFormFacade.useRfcValidation();
  PersonManagementDataFormFacade.useEmailValidation();
  //TODO Refactor

  useEffect(
    () => {
      if (storedPerson && !gender) {
        setGender(storedPerson.profile?.gender)
      }
    }, [storedPerson, setGender, gender]
  )

  useEffect(() => {
    if (onComplete) {
      if (gender !== undefined && currentPerson.profile) {
        if (showEconomicActivities && isEconomicActivitiesValid && formData.formState.isValid && formAddress.formState.isValid) {
          currentPerson.profile.gender = gender;
          onComplete(currentPerson);
        } else if (formData.formState.isValid && formAddress.formState.isValid && !hideEmail) {
          currentPerson.profile.gender = gender;
          onComplete(currentPerson);
        } else {
          onComplete();
        }
      }
    } return () => onComplete ? onComplete() : undefined;
  }, [formData.formState.isValid, formAddress.formState.isValid, onComplete, currentPerson, isEconomicActivitiesValid, showEconomicActivities, hideEmail, gender])

  /**
   * 
   */
  const onSetPerson = (date?: Date) => {
    if (date) {
      selectDate(date);
    }
    setPersonValues(formData.getValues(), formAddress.getValues());
    if (onComplete) {
      if (formData.formState.isValid && formAddress.formState.isValid) {
        onComplete(currentPerson);
      } else {
        onComplete();
      }
    }
  }

  return (
    <div id="person-management-wrapper">
      <form onChange={() => onSetPerson()}>
        <div className="sections-wrapper">
          <div className="inline-input">
            <InputLabel
              required
              defaultValue={storedPerson ? storedPerson.name : ''}
              type="text"
              maxLength={200}
              errors={!!formData.formState.errors.name}
              {...formData.register("name", { required: true, pattern: Util.PATTERN.NAMES, minLength: 2 })}
            >
              Nombre(s)
            </InputLabel>
            <FormError schema={FORM_DATA_ERRORS.NAME_ERRORS} type={formData.formState.errors.name?.type} />
          </div>

          <div className="inline-input">
            <InputLabel
              required
              defaultValue={storedPerson ? storedPerson.lastName : ''}
              type="text"
              maxLength={100}
              errors={!!formData.formState.errors.firstLastname}
              {...formData.register("firstLastname", { required: true, pattern: Util.PATTERN.NAMES, minLength: 2 })}
            >
              Apellido paterno
            </InputLabel>
            <FormError schema={FORM_DATA_ERRORS.LASTNAME_ERRORS} type={formData.formState.errors.firstLastname?.type} />
          </div>

          <div className="inline-input">
            <InputLabel
              required
              defaultValue={storedPerson ? storedPerson.secondLastName : ''}
              type="text"
              maxLength={100}
              errors={!!formData.formState.errors.secondLastname}
              {...formData.register("secondLastname", { required: true, pattern: Util.PATTERN.NAMES, minLength: 2 })}
            >
              Apellido materno
            </InputLabel>
            <FormError schema={FORM_DATA_ERRORS.LASTNAME_ERRORS} type={formData.formState.errors.secondLastname?.type} />
          </div>

          <div className="inline-input">
            <InputDate required validateConsent={true}
              defaultValue={storedPerson && storedPerson.profile?.birthdate! ? storedPerson.profile?.birthdate?.toDateString() : ''}
              errors={!!formData.formState.errors.birthdate}
              {...formData.register("birthdate", { required: true })}
              onSelectDate={(date) => onSetPerson(date)}
            >
              Fecha de nacimiento
            </InputDate>
            <FormError schema={[]} type={formData.formState.errors.birthdate?.type} />
          </div>

          {
            (hideEmail === undefined || hideEmail === false) &&
            <div className="inline-input">
              <InputLabel
                required
                defaultValue={storedPerson ? storedPerson.email : ''}
                type="email"
                maxLength={100}
                errors={!!formData.formState.errors.email}
                {...formData.register("email", {
                  required: true,
                  pattern: Util.PATTERN.EMAIL
                })}
              >
                Correo electr&oacute;nico

              </InputLabel>
              <FormError schema={FORM_DATA_ERRORS.EMAIL_ERRORS} type={formData.formState.errors.email?.type} />
            </div>
          }

          <div className="inline-input">
            <InputPhone
              required
              defaultValue={storedPerson ? storedPerson.profile?.phone?.number : ''}
              errors={!!formData.formState.errors.phone}
              onCountryCodeSelected={selectCountryCode}
              {...formData.register("phone", { required: true, pattern: Util.PATTERN.NUMBERS, minLength: 10 })}
            >
              Tel&eacute;fono
            </InputPhone>
            <FormError schema={FORM_DATA_ERRORS.PHONE_ERRORS} type={formData.formState.errors.phone?.type} />
          </div>

          <div className={`inline-input ${showEconomicActivities && 'last-item'}`}>
            <InputLabel
              required
              className="uppercase"
              defaultValue=""
              type="text"
              maxLength={13}
              loading={hideEmail && isValidatingRFC}
              errors={!!formData.formState.errors.rfc}
              {...formData.register("rfc", {
                required: true,
                pattern: Util.PATTERN.RFC,
                validate: hideEmail ? { invalidRfc: async (rfc) => await validatePersonRfc(rfc) } : {}
              })}
            >
              RFC
            </InputLabel>
            <FormError schema={FORM_DATA_ERRORS.RFC_ERRORS} type={formData.formState.errors.rfc?.type} />
          </div>
          {
            storedPerson && gender &&
            <div className="inline-input">
              <Label required>Sexo</Label>
              <Radio
                name="gender"
                withoutForm={true}
                onChange={e => setGender(parseInt(e.target.value))}
                defaultValue={Util.STATUS_CODE.GENDER.FEMALE}
                defaultChecked={gender === Util.STATUS_CODE.GENDER.FEMALE ? true : false}
              >
                Femenino
              </Radio>
              <Radio
                name="gender"
                withoutForm={true}
                onChange={e => setGender(parseInt(e.target.value))}
                defaultValue={Util.STATUS_CODE.GENDER.MALE}
                defaultChecked={gender === Util.STATUS_CODE.GENDER.MALE ? true : false}
              >
                Masculino
              </Radio>
            </div>
          }

          {
            !storedPerson &&
            <div className="inline-input">
              <Label required>Sexo</Label>
              <Radio
                name="gender"
                withoutForm={true}
                onChange={e => setGender(parseInt(e.target.value))}
                defaultValue={Util.STATUS_CODE.GENDER.FEMALE}
              >
                Femenino
              </Radio>
              <Radio
                name="gender"
                withoutForm={true}
                onChange={e => setGender(parseInt(e.target.value))}
                defaultValue={Util.STATUS_CODE.GENDER.MALE}
              >
                Masculino
              </Radio>
            </div>
          }

          {
            showEconomicActivities &&
            <>
              <div className="inline-input">
                <Dropdown items={economicActivities ? economicActivities : []} onChange={setChosenEconomicActivity} >
                  Actividad económica <span>*</span>
                </Dropdown>
              </div>

              <div className="inline-input">
                <Dropdown items={economicOwnBusiness ? economicOwnBusiness : []} onChange={setChosenOwnBusiness} disabled={!(chosenEconomicActivity && chosenEconomicActivity.id === 0)}>
                  Sector económico <span>*</span>
                </Dropdown>
              </div>
            </>
          }

        </div>

        <h1 className="section-title">
          Direcci&oacute;n
        </h1>


        <div className="sections-wrapper">
          <div className="inline-input flex space-between align-center relative">
            <div className="flex-grow-100">
              <InputLabel
                required
                defaultValue=""
                type="text"
                maxLength={5}
                number
                loading={isValidatingPostalCode}
                errors={!!formAddress.formState.errors.postalCode}
                {...formAddress.register("postalCode", {
                  required: true,
                  minLength: 5,
                  validate: { invalidPostalCode: async (postalCode) => await verifyPostalCode(postalCode) },
                  pattern: Util.PATTERN.NUMBERS
                })}
              >
                C&oacute;digo postal
              </InputLabel>
              <FormError schema={FORM_ADDRESS_ERRORS.POSTAL_CODE_ERRORS} type={formAddress.formState.errors.postalCode?.type} />
            </div>
            {/* TODO: Usar CP usuario actual */}
            {/* <div className="check-current-postal-code flex-grow-70">
              <Checkbox>
                Usar el mismo de la cuenta principal
              </Checkbox>
            </div> */}
          </div>

          <div className="inline-input">
            <InputLabel
              required
              defaultValue=""
              type="text"
              maxLength={150}
              errors={!!formAddress.formState.errors.neighborhood}
              {...formAddress.register("neighborhood", { required: true, pattern: Util.PATTERN.ADDRESS })}
            >
              Colonia / Asentamiento
            </InputLabel>
            <FormError schema={FORM_ADDRESS_ERRORS.ADDRESS_ERRORS} type={formAddress.formState.errors.neighborhood?.type} />
          </div>

          <div className="inline-input">
            <InputLabel
              required
              defaultValue=""
              type="text"
              maxLength={150}
              errors={!!formAddress.formState.errors.street}
              {...formAddress.register("street", { required: true, pattern: Util.PATTERN.ADDRESS })}
            >
              Calle
            </InputLabel>
            <FormError schema={FORM_ADDRESS_ERRORS.ADDRESS_ERRORS} type={formAddress.formState.errors.street?.type} />
          </div>

          <div className="inline-input flex space-between">
            <div className="flex-grow-45">
              <InputLabel
                required
                defaultValue=""
                type="text"
                maxLength={50}
                errors={!!formAddress.formState.errors.externalNumber}
                {...formAddress.register("externalNumber", { required: true, pattern: Util.PATTERN.ADDRESS })}
              >
                No. Exterior
              </InputLabel>
              <FormError schema={FORM_ADDRESS_ERRORS.ADDRESS_ERRORS} type={formAddress.formState.errors.externalNumber?.type} />
            </div>
            <div className="flex-grow-45">
              <InputLabel
                defaultValue=""
                type="text"
                errors={!!formAddress.formState.errors.internalNumber}
                {...formAddress.register("internalNumber", { pattern: Util.PATTERN.ADDRESS })}
              >
                No. Interior
              </InputLabel>
              <FormError schema={FORM_ADDRESS_ERRORS.ADDRESS_ERRORS} type={formAddress.formState.errors.internalNumber?.type} />
            </div>

          </div>

        </div>

        <div className="form-section margin">
          <Checkbox
            {...formAddress.register("confirmation", { required: true })}
          >
            <b>He revisado el RFC y certifico que es correcto. </b> Acepto los <a target="_blank" href="https://crabi.com/legal/terminos-y-condiciones" rel="noreferrer">Términos y Condiciones</a>,
            actuando a cuenta propia para el beneficiario o conductor.

          </Checkbox>
        </div>
      </form>
    </div>
  );
};

export default PersonManagement;
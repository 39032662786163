import { Button, ButtonLink, Spinner, Status } from "src/components";
import PolicyDocuments from "../../components/PolicyDocuments/PolicyDocuments";
import { IconFactory } from "src/factory/icon.factory";
import { PolicyFacade } from "src/modules/policy/controller/facades/policy.facade";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { Util } from "src/utils/Util";

import './PolicyDetails.scss';

/**
 *
 */
const PolicyDetails = (): JSX.Element => {
  const { selectedPolicy, policyRenewal } = PolicyFacade.usePolicyManagement();

  return (
    <div id="policy-details-wrapper" className="flex column space-between height-100 relative">
      <div className="policy-details">
        {
          selectedPolicy? 
            <>
              <section>

                <div className="policy-details-vehicle-picture-wrapper flex align-center">

                  <div className="add-picture flex align-center space-evenly width-100"
                    style={{ backgroundImage: `url(${Util.ASSET.LOGIN_BG})` }}
                  >
                  </div>
                </div>
                {
                  selectedPolicy?.renewalDate &&
                  <div className="renewal-badge right-badge absolute">
                    <span className="absolute block text-center">
                      Renovada
                    </span>
                  </div>
                }
              </section>

              <section className="flex">
                <div className="policy-vehicle-details text-center">
                  <div className="flex space-evenly">
                    <div className="vehicle-item inline-flex column vertical-align-middle">
                      <h3>Marca:</h3>
                      <p>{selectedPolicy.vehicle?.brand?.name}</p>
                    </div>
                    <div className="vehicle-item inline-flex column vertical-align-middle">
                      <h3>Modelo:</h3>
                      <p>{selectedPolicy.vehicle?.model?.name}</p>
                    </div>
                  </div>
                  <div className="flex space-evenly">
                    <div className="vehicle-item inline-flex column vertical-align-middle">
                      <h3>Placas:</h3>
                      <p>{selectedPolicy.vehicle?.plate}</p>
                    </div>
                    <div className="vehicle-item inline-flex column vertical-align-middle">
                      <h3>A&ntilde;o:</h3>
                      <p>{selectedPolicy.vehicle?.year?.getYearDescription()}</p>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="vehicle-item inline-flex column vertical-align-middle">
                      <h3>VIN</h3>
                      <p>{selectedPolicy.vehicle?.vin}</p>
                    </div>
                  </div>
                </div>

                <div className="policy-status-details text-center relative">

                  <span className="policy-card-renew block" hide-renew={!selectedPolicy.allowRenewal ? '' : null}>
                    {
                      !selectedPolicy.isCanceled() &&
                      <Status type="available" shape="label">Renovaci&oacute;n disponible</Status>
                    }
                  </span>
                  <div>
                    <span>Vigencia:</span>
                    {
                      selectedPolicy.isActive() &&
                      <Status className="inline-flex align-center justify-center" type="active" shape="background">
                        {selectedPolicy.getStatusValue()}
                      </Status>
                    }
                    {
                      selectedPolicy.isCanceled() &&
                      <Status className="inline-flex align-center justify-center" type="deactive" shape="background">
                        {selectedPolicy.getStatusValue()}
                      </Status>
                    }
                    {
                      selectedPolicy.isPending() &&
                      <Status className="inline-flex align-center justify-center" type="pending" shape="background">
                        {selectedPolicy.getStatusValue()}
                      </Status>
                    }
                    {
                      selectedPolicy.toBegin() &&
                      <Status className="inline-flex align-center justify-center" type="toBegin" shape="background">
                        {selectedPolicy.getStatusValue()}
                      </Status>
                    }
                    {
                      selectedPolicy.isExpired() &&
                      <Status className="inline-flex align-center justify-center" type="expired" shape="background">
                        {selectedPolicy.getStatusValue()}
                      </Status>
                    }
                  </div>
                  <p><span>Pago:</span> {selectedPolicy.getTypeName()}</p>
                  <p>
                    {
                      !selectedPolicy.isExpired() ?
                        <span>
                          Vence el:
                        </span>
                        :
                        <span>
                          Venci&oacute; el:
                        </span>
                    }
                    &nbsp;<br />
                    {selectedPolicy.getExpirationDateFormat()}
                  </p>
                </div>
              </section>

              <section>
                <h2 className="text-center">Coberturas</h2>
                <div className="policy-coverages-details text-left">
                  {
                    selectedPolicy.coverages.map(
                      coverage => {
                        return (
                          <div className="coverage-item inline-flex column" key={coverage.id}>
                            <h3 className="flex">
                              {
                                coverage.isEnabled ?
                                  <span className="coverage-status inline-flex vertical-align-middle align-center justify-center">{IconFactory.checkIcon()}</span> :
                                  <span className="coverage-status inline-flex vertical-align-middle align-center justify-center excluded">{IconFactory.closeIcon()}</span>
                              }
                              <span className="inline-box vertical-align-middle">{coverage.name}</span>
                            </h3>
                            <div className="coverage-item-value">
                              <p>{!coverage.isDeductible && coverage.assured ?
                                Util.TRANSFORM.CURRENCY.MXN(coverage.assured)
                                :
                                coverage.coverageType(selectedPolicy.vehicle)}</p>
                              <h3>Deducible</h3>
                              <p>{coverage.isDeductible && coverage.deductible ? coverage.coverageValue() : 'No aplica'}</p>
                            </div>
                          </div>
                        )
                      }
                    )
                  }
                </div>
              </section>
            </>
            :
            <Spinner color="main" circles />
        }
      </div>
      <footer className="flex align-center space-between" actions-disabled={!selectedPolicy ? '' : null}>
        <section className="flex align-center">
          <PolicyDocuments currentPolicy={selectedPolicy!} />
          {
            selectedPolicy?.isActive() || selectedPolicy?.toBegin() ?
              <ButtonLink fill="outline" to={`${POLICY_PATH.MANAGEMENT}/${selectedPolicy?.policyNumber}`}>Administrar P&oacute;liza</ButtonLink>
              :
              <Button disabled>Administrar P&oacute;liza</Button>
          }
        </section>
        <section>
          {
            selectedPolicy && !selectedPolicy?.isCanceled() &&
            <>
              <Button disabled={!selectedPolicy?.allowRenewal} onClick={(e) => { policyRenewal(); e.preventDefault() }}>Renovar P&oacute;liza</Button>
            </>
          }
        </section>
      </footer>
    </div>
  );
};

export default PolicyDetails;
import { Button, ButtonBack, FormError, InputLabel } from 'src/components';
import { PolicyVehicleFacade } from 'src/modules/policy-creation/controller/facades/policy-vehicle.facade';
import { ReturnValidactionFacade } from 'src/modules/policy-creation/controller/facades/return-validation.facade';
import { POLICY_PATH } from 'src/routes/paths/policy.paths';
import { PolicyCreationVehicleFormFacade } from 'src/modules/policy-creation/controller/facades/policy-creation-vehicle-form.facade';
import { Util } from 'src/utils/Util';
import VehicleManagement from "src/libs/VehicleManagement/ui/template/VehicleManagement";

import './PolicyCreationVehicle.scss';
import { POLICY_CREATION_PATH, VEHICLE_ROUTE_ID } from 'src/routes/paths/policy-creation.paths';
import { PolicyCreationFacade } from 'src/modules/policy-creation/controller/facades/policy-creation.facade';

/**
 *
 */
const PolicyCreationVehiclePage = (): JSX.Element => {

	const { vehicle, vehiclePostalCode } = PolicyCreationFacade.usePolicyCreationActors();
  const { view, selectedVehicle, isCreatingVehicle, createVehicle, navigateToPlanSelection, selectVehicle, storedVehicle, verifyPostalCode, isValidatingPostalCode } = PolicyVehicleFacade.useVehicleData();
  const { onReturn, steps } = ReturnValidactionFacade.useReturnValidation();
	const { form, POSTAL_CODE_ERRORS } = PolicyCreationVehicleFormFacade.usePolicyCreationVehicleFormFacade();

  return (
    <div className="policyCreationStepWrapper flex-grow-100 flex column">
			<ButtonBack button text
				to={''}
				onClick={() => view === VEHICLE_ROUTE_ID.VEHICLE_DATA ? onReturn(steps.vehicle, POLICY_PATH.STATUS) : onReturn(undefined, POLICY_CREATION_PATH.PLAN_SELECTION)}
			/>

      <section className="content-section">
        <header>
          <h1>
            Completa y verifica los datos de tu auto
          </h1>
          <p>
            Con esta información te compartiremos el precio exacto de tu póliza.
          </p>
        </header>

        <div className="policy-step-content">
          <VehicleManagement onComplete={selectVehicle} storedVehicle={storedVehicle ? storedVehicle : vehicle} type="policy" subType={ view === VEHICLE_ROUTE_ID.VEHICLE_DATA ? 'basic' : 'aditional' } />

					{
						view === VEHICLE_ROUTE_ID.VEHICLE_DATA &&
						<form>
							<div className="inline-input">
								<InputLabel
									required
									number
									type="text"
									placeholder='C.P. donde se encuentra el auto'
									defaultValue={vehiclePostalCode}
									maxLength={5}
									loading={isValidatingPostalCode}
									errors={!!form.formState.errors.postalCode}
									{...form.register("postalCode", {	
										required: true,
										minLength: 5,
										pattern: Util.PATTERN.NUMBERS,
										validate: { invalidPostalCode: async (postalCode) => await verifyPostalCode(postalCode) }
									})}
								>
									Código postal
								</InputLabel>
								<FormError schema={POSTAL_CODE_ERRORS} type={form.formState.errors.postalCode?.type} />
							</div>
						</form>
					}
        </div>
      </section>

      <section className="plan-management-bottom-section flex align-center">
        <div className="plan-button width-100 flex space-evenly">
          <Button type="button"
            loading={isCreatingVehicle}
            onClick={() => view === VEHICLE_ROUTE_ID.VEHICLE_DATA ? navigateToPlanSelection() : createVehicle()}
            disabled={(view === VEHICLE_ROUTE_ID.VEHICLE_DATA && (isCreatingVehicle || !form.formState.isValid)) || !selectedVehicle}
          >
            Siguiente
          </Button>
        </div>
      </section>
    </div >
  );
};

export default PolicyCreationVehiclePage;
import { FormError, InputLabel, InputPhone } from "src/components";
import { ClaimsPersonalDataFormFacade } from "src/modules/claim-payments/controller/facades/claims-personal-data-form.facade";
import { ClaimsPersonalDataFacade } from "src/modules/claim-payments/controller/facades/claims-personal-data.facade";
import { DamageClaimNavigateValidationFacade } from "src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade";
import { Util } from "src/utils/Util";
import ButtonSection from "../../molecules/ButtonSection/ButtonSection";

import "./ClaimsPersonalDataPage.scss";

/**
 *
 */
interface IClaimsPersonalDataPage {}

/**
 *
 */
const ClaimsPersonalDataPage = (props: IClaimsPersonalDataPage): JSX.Element => {
	const { AGREED_CURRENT_PATH, CLAIM_CURRENT_PATH } = ClaimsPersonalDataFacade.useClaimsPersonalDataFacade();
  const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();
  const { isAgreedPayment, user, isLoading } = ClaimsPersonalDataFacade.useCreatePersonalData();
  const { submitFormData, formState, register, getValues, selectCountryCode } = ClaimsPersonalDataFormFacade.useClaimsPersonalDataForm(user);
  const { NAME_ERRORS, LAST_NAME_ERRORS, EMAIL_ERRORS, PHONE_ERRORS } = ClaimsPersonalDataFormFacade.useClaimsPersonalDataValidation();

  return (
    <div id="personal-data-wrapper" className="height-100">
      <section className="content height-100">
        <h1>Sobre tí</h1>
        <p className="text">Ingresa tus datos personales</p>

        <form>
          <div className="form-section flex ">
            <div className="inline-input flex-grow-45 relative">
              <InputLabel
                required
                type="text"
                defaultValue={user?.name! ? user.name! : ""}
                errors={ !!formState.errors.name || (!!formState.touchedFields.name && formState.errors.name) }
                {...register("name", {
                  required: true,
                  pattern: Util.PATTERN.NAMES,
                  minLength: 2,
                  maxLength: 30,
                })}
              >
                Nombre
              </InputLabel>
              <FormError schema={NAME_ERRORS} type={formState.errors.name?.type} />
            </div>

            <div className="inline-input flex-grow-45 relative">
              <InputLabel
                required
                className=""
                defaultValue={
                  user?.lastName! ? user.lastName! : ""
                } //storedPerson.attr
                type="text"
                errors={ !!formState.errors.lastName || (!!formState.touchedFields.lastName && formState.errors.lastName) }
                {...register("lastName", {
                  required: true,
                  pattern: Util.PATTERN.NAMES,
                  minLength: 2,
                  maxLength: 30,
                })}
              >
                Apellido paterno
              </InputLabel>
              <FormError schema={LAST_NAME_ERRORS} type={formState.errors.lastName?.type} />
            </div>
          </div>

          <div className="form-section flex ">
            <div className="inline-input flex-grow-45 relative">
              <InputLabel
                required
                type="text"
                defaultValue={ user?.secondLastName! ? user.secondLastName! : "" }
                errors={ !!formState.errors.secondLastName || (!!formState.touchedFields.secondLastName && formState.errors.secondLastName) }
                {...register("secondLastName", {
                  required: true,
                  pattern: Util.PATTERN.NAMES,
                  minLength: 2,
                  maxLength: 30,
                })}
              >
                Apellido materno
              </InputLabel>
              <FormError schema={LAST_NAME_ERRORS} type={formState.errors.secondLastName?.type} />
            </div>

            <div className="inline-input flex-grow-45 relative">
              <InputLabel
                required
                defaultValue={user?.email! ? user.email! : ""}
                type="text"
                errors={ !!formState.errors.email || (!!formState.touchedFields.email && formState.errors.email) }
                {...register("email", {
                  required: true,
                  pattern: Util.PATTERN.EMAIL,
                  minLength: 5,
                  maxLength: 70,
                })}
              >
                Correo electrónico
              </InputLabel>
              <FormError schema={EMAIL_ERRORS} type={formState.errors.email?.type}
              />
            </div>
          </div>

          <div className="form-section flex ">
            <div className="inline-input flex-grow-45 relative">
              <InputPhone
                required
                defaultValue={user?.profile?.phone?.number}
                errors={ !!formState.errors.phone || (!!formState.touchedFields.phone && formState.errors.phone) }
								onCountryCodeSelected={selectCountryCode}
                {...register("phone", {
                  required: true,
                  pattern: Util.PATTERN.NUMBERS,
                  minLength: 10
                })}
              >
                Número de celular
              </InputPhone>
              <FormError schema={PHONE_ERRORS} type={formState.errors.phone?.type}
              />
            </div>
          </div>
        </form>
      </section>

      <ButtonSection
        backHandler={() => { navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'backward'); }}
        nextHandler={() => submitFormData(getValues())}
        nextButtonDisabled={!formState.isValid}
				isLoading={isLoading}
      />
    </div>
  );
};

export default ClaimsPersonalDataPage;

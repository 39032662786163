import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Plan } from "src/classes/plan/Plan";
import { Person  } from "src/classes/Person";
import { Util } from "src/utils/Util";
import { POLICY_CREATION_PATH, VEHICLE_ROUTE_ID } from "../../../../routes/paths/policy-creation.paths";
import { PolicyCreationAction } from "../actions/policy-creation.action";
import { PolicyCreationFacade } from "./policy-creation.facade";

/**
 *
 */
export namespace PolicyPlanFacade {
  /**
   * 
   */
  export const usePlans = () => {
		const { user, vehiclePostalCode } = PolicyCreationFacade.usePolicyCreationActors();
		const [vehicleUser] = useState<Person>(
			() => {
				let _user = user;
				if (vehiclePostalCode){
					_user.profile!.address!.postalCode = vehiclePostalCode;
				}
				return _user;
			}
		);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /**
     * 
     * @param plan 
     */
    const selectPlan = (plan: Plan): void => {
      dispatch(PolicyCreationAction.currentPlan(plan));
      navigate(POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_ADITIONAL_DATA));
      window.scrollTo(0, 0);
    }

    /**
     * 
     */
    const clearQuotationVehicle = (): void => {
      localStorage.removeItem(Util.KEY.POLICY_NEW_VEHICLE);
      dispatch(PolicyCreationAction.clearQuotationVehicle());
    }

    return { selectPlan, clearQuotationVehicle, vehicleUser };
  }
}
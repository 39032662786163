import { IconFactory } from 'src/factory/icon.factory';
import './SetpIcon.scss';

/**
 *
 */
interface ISetpIcon {

  Icon: JSX.Element;

  completed?: boolean;

  active?: boolean

  isFirst?: boolean;

  isLast?: boolean;
}

/**
 *
 */
const SetpIcon = (props: ISetpIcon): JSX.Element => {
  return (
    //@ts-check
    <span className="step-endorsement-icon inline-flex justify-center align-center vertical-align-middle relative"
      is-first={props.isFirst ? '' : null}
      is-last={props.isLast ? '' : null}
      step-active={props.active ? '' : null}
      step-completed={props.completed ? '' : null}>
      {
        ((props.completed && props.active) || (!props.completed && props.active) || (!props.completed && !props.active)) &&
        <>
          {props.Icon}
        </>
      }
      {!props.active && props.completed && IconFactory.checkIcon()}
    </span>
  );
};

export default SetpIcon;
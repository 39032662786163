import { Button, ButtonBack, Checkbox, Modal } from "src/components";
import SummarySection from "../molecules/SummarySection/SummarySection";
import { Plan } from "src/classes/plan/Plan";
import { Person } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { PlanSummaryManagementFormFacade } from "src/libs/PlanSummaryManagement/controller/facades/plan-summary-management-form.facade";
import { IconFactory } from "src/factory/icon.factory";
import { PlanSummaryManagementFacade } from "../../controller/facades/plan-summary-management.facade";

import './PlanSummaryManagement.scss';
import { Util } from "src/utils/Util";

interface PlanSummaryManagementProps {
	/** */
	user: Person;
	/** */
	plan: Plan;
	/** */
	vehicle?: Vehicle;
	/** */
	onComplete: () => void;
	/** */
	type?: 'default' | 'quoter';
	/** */
	isLoading: boolean;
	/** */
	hideButtons?: boolean;
	/** */
	onBackButton?: () => void;
}

/**
 *
 */
const PlanSummaryManagement = (props: PlanSummaryManagementProps): JSX.Element => {
	const { user, plan, vehicle, hideButtons, type, onBackButton } = props;
	const { register, formState } = PlanSummaryManagementFormFacade.usePlanSummaryForm();
	const { showPaymentTypeModal, setShowPaymentTypeModal, selectedCost } = PlanSummaryManagementFacade.usePlanSummary(plan);

	return (
		<div className={`plan-summary-management-wrapper flex column ${type}`}>
			{
				onBackButton &&
				<ButtonBack button text
					to={''}
					onClick={onBackButton}
				/>
			}

			<section className="plan-summary-content flex-grow-100">
				<section>
					{type !== 'quoter' && <h1>Revisa tu plan.</h1>}
					<p>
						¡Échale un ojo 👀! Lee cuidadosamente el detalle de tu plan y asegúrate de que toda la información es correcta.
					</p>
				</section>

				<section className="flex baseline">
					<section className="flex-grow-100">
						<SummarySection plan={plan} vehicle={vehicle}>
							Tu plan
						</SummarySection>
						<SummarySection user={user} vehicle={vehicle}>
							Tus datos
						</SummarySection>
					</section>

					<aside className={`${type}`}>
						{
							plan &&
							<section>
								<h3 className="text-center">Forma de pago</h3>

								{
									(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) &&
									<div className="plan-cost">
										<section className="flex space-evenly align-center">
											<h4 className="text-center">
												Pago mes a mes (12 meses)
											</h4>
										</section>
										<section className="text-center">
											{
												plan.discount &&
												<h5 className="text-line-through">{plan.subscription.firstCost.getTotal()}</h5>
											}
											<h5>{plan.discount ? plan.discount.subscription.firstCost.getTotal() : plan.subscription.firstCost.getTotal()}</h5>
											<span>*Pago inicial 1ra cuota</span>

											{
												plan.discount &&
												<h5 className="text-line-through">{plan.subscription.cost.getTotal()}</h5>
											}
											<h5>{plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal()}</h5>
											<span>11 cuotas restantes</span>

										</section>
									</div>
								}


								{
									(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) &&
									<div className="plan-cost">
										<section className="flex space-evenly align-center text-center">
											<h4>
												Pago de contado
											</h4>
										</section>
										<section className="text-center">
											{
												plan.discount &&
												<h5 className="text-line-through">{plan.cost.getTotal()}</h5>
											}
											<h5>{plan.discount ? plan.discount?.cost.getTotal() : plan.cost.getTotal()}</h5>

											<p className="discount-text">El pago de contado es hasta 12.5% más barato que la opción diferida</p>
										</section>
									</div>
								}

								{
									(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && plan.monthly.costs.length > 0) &&
									<div className="plan-cost">
										{
											plan.monthly.costs.map((cost, index) => {
												if (cost.monthsQuantity === plan.selectedPlanPayment.paymentsCount) {
													return (
														<div className="monthly-wrapper" key={index}>
															<section className="flex column align-center">
																<h4>
																	{`${cost.monthsQuantity} pago${cost.monthsQuantity > 1 ? 's' : ''} de`}
																</h4>
																<div className="flex-grow-100 ">
																	{
																		plan.discount &&
																		<h4 className="monthly-cost text-line-through text-left">{cost.getAmountByMonth()}</h4>
																	}
																	<h4 className="monthly-cost text-left">{plan.discount ? plan.discount.monthly.costs[index].getAmountByMonth() : cost.getAmountByMonth()}</h4>
																</div>
																{/* <p className="flex-grow-80 text-right">diferido a {cost.monthsQuantity} meses</p> */}
															</section>
														</div>
													)
												} else {
													return undefined;
												}
											})
										}
									</div>
								}


								<div className="bottom-section text-center">
									<form>
										<Checkbox
											{...register("acceptTerms", { required: true })}
										>
											Acepto los&nbsp;
											<a target="_blank" href="https://crabi.com/legal/terminos-y-condiciones" rel="noreferrer">términos y condiciones</a>&nbsp;y&nbsp;
											<a target="_blank" href="https://crabi.com/legal/condiciones-generales" rel="noreferrer">condiciones generales</a>
										</Checkbox>
									</form>

									<Button type="button"
										disabled={!formState.isValid || props.isLoading}
										loading={props.isLoading}
										onClick={(e) => { e.preventDefault(); props.onComplete() }}>
										Proceder al pago
									</Button>
								</div>
							</section>
						}
					</aside>

				</section>

			</section>

			{
				!hideButtons &&
				<section className="plan-management-bottom-section flex column align-center space-between border-box">
					{/* <button className="payment-types" onClick={() => setShowPaymentTypeModal(true)}>Tipos de pago</button> */}

					<div className="mobile-price-details">
						<div className="title flex justify-center align-center">
							<span>
								{plan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.DEBIT ? 'Débito' : 'Crédito'}
							</span>

							{
								(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) &&
								<p><b>Pago de contado</b></p>
							}

							{
								(plan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.DEBIT) &&
								<>
									{
										(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) &&
										<p><b>Pago a meses</b> (12 meses)</p>
									}
								</>
							}

							{
								(plan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.CREDIT) &&
								<>
									{
										(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && plan.monthly.costs.length > 0) &&
										<p><b>Pago diferido</b> ({plan.selectedPlanPayment.paymentsCount} meses)</p>
									}
								</>
							}
						</div>

						<div className="amounts">
							{
								(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) &&
								<h3 className="text-center">{plan.discount ? plan.discount.cost.getTotal() : plan.cost.getTotal()}</h3>
							}

							{
								(plan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.DEBIT) &&
								<>
									{
										(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) &&
										<div className="subscription flex justify-center align-center">
											<div className="first-payment flex column align-center">
												<h3>{plan.discount ? plan.discount.subscription.firstCost.getTotal() : plan.subscription.firstCost.getTotal()}</h3>
												<p>Pago inicial <span>1ra cuota</span></p>
											</div>
											{IconFactory.rightArrowIcon()}
											<div className="quota-payments flex column align-center">
												<h3>{plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal()}</h3>
												<p>Por pagar <span>11 cuotas</span></p>
											</div>
										</div>
									}
								</>
							}

							{
								(plan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.CREDIT) &&
								<>
									{
										(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && plan.monthly.costs.length > 0) &&
										<div className="monthly flex column align-center">
											<p><b>{plan.selectedPlanPayment.paymentsCount} pagos de</b></p>
											<h5>{selectedCost && selectedCost.getAmountByMonth()}</h5>
										</div>
									}
								</>
							}
						</div>
					</div>

					<form>
						<Checkbox
							{...register("acceptTerms", { required: true })}
						>
							Acepto&nbsp;
							<a target="_blank" href="https://crabi.com/legal/condiciones-generales" rel="noreferrer">Condiciones Generales</a>
							&nbsp;y declaro ser el propietario de los recursos.
						</Checkbox>
					</form>
					<div className="plan-button width-100 flex">
						<Button type="button"
							disabled={!formState.isValid || props.isLoading}
							loading={props.isLoading}
							onClick={(e) => { e.preventDefault(); props.onComplete() }}>
							Proceder al pago
						</Button>
					</div>
				</section>
			}

			<Modal show={showPaymentTypeModal} position="center" backgroundDismiss onClose={() => setShowPaymentTypeModal(false)}>
				<div className="payment-type-modal relative">
					{IconFactory.closeIcon({ onClick: () => { setShowPaymentTypeModal(false) } })}

					<section>
						<div className="plan-payment-type">
							<h3 className="text-center">Tipo de pago</h3>
							<p className="segment">
								{plan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.DEBIT ? 'Débito' : 'Crédito'}
							</p>
						</div>
					</section>

					{
						plan &&
						<section>
							<h3 className="text-center">Formas de pago</h3>
							{
								plan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.DEBIT &&
								<>

									{
										(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) &&
										<div className="plan-cost">
											<section className="flex space-evenly align-center text-center">
												<h4>
													Pago de contado
												</h4>
											</section>
											<section className="text-center">
												{
													plan.discount &&
													<h5 className="text-line-through">{plan.cost.getTotal()}</h5>
												}
												<h5>{plan.discount ? plan.discount.cost.getTotal() : plan.cost.getTotal()}</h5>

												<p className="discount-text">El pago de contado es 20% <br /> más barato que la opción mensual</p>
											</section>
										</div>
									}

									{
										(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) &&
										<div className="plan-cost">
											<section className="flex space-evenly align-center">
												<h4 className="text-center">
													Pago mensual
												</h4>
												<p className="text-center">(12 meses)</p>
											</section>
											<section className="text-center">
												{
													plan.discount &&
													<h5 className="text-line-through">{plan.subscription.firstCost.getTotal()}</h5>
												}
												<h5>{plan.discount ? plan.discount.subscription.firstCost.getTotal() : plan.subscription.firstCost.getTotal()}</h5>
												<span>*Pago inicial 1ra cuota</span>

												{
													plan.discount &&
													<h5 className="text-line-through">{plan.subscription.cost.getTotal()}</h5>
												}
												<h5>{plan.discount ? plan.discount.subscription.cost.getTotal() : plan.subscription.cost.getTotal()}</h5>
												<span>11 cuotas restantes</span>

											</section>
										</div>
									}
								</>
							}

							{
								plan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.CREDIT &&
								<>
									{
										(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.ANNUAL) &&
										<div className="plan-cost">
											<section className="flex space-evenly align-center text-center">
												<h4>
													Pago de contado
												</h4>
											</section>
											<section className="text-center">
												{
													plan.discount &&
													<h5 className="text-line-through">{plan.cost.getTotal()}</h5>
												}
												<h5>{plan.discount ? plan.discount?.cost.getTotal() : plan.cost.getTotal()}</h5>

												<p className="discount-text">El pago de contado es hasta 12.5% más barato que la opción diferida</p>
											</section>
										</div>
									}

									{
										(plan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS && plan.monthly.costs.length > 0) &&
										<div className="plan-cost">
											<section className="flex space-evenly align-center text-center">
												<h4>
													Pago diferido
												</h4>
											</section>

											{
												plan.monthly.costs.map((cost, index) => {
													if (cost.monthsQuantity === plan.selectedPlanPayment.paymentsCount) {
														return (
															<div className="monthly-wrapper" key={index}>
																<section className="flex column align-center">
																	<p className="flex-grow-80 text-right">{cost.monthsQuantity} pagos de</p>
																	<div className="flex-grow-100 ">
																		{
																			plan.discount &&
																			<h4 className="monthly-cost text-line-through text-left">{cost.getAmountByMonth()}</h4>
																		}
																		<h4 className="monthly-cost text-left">{plan.discount ? plan.discount.monthly.costs[index].getAmountByMonth() : cost.getAmountByMonth()}</h4>
																	</div>
																</section>
															</div>
														)
													} else {
														return undefined;
													}
												})
											}
										</div>
									}

								</>
							}
						</section>
					}
				</div>
			</Modal>
		</div>
	);
};

export default PlanSummaryManagement;
import classNames from "classnames";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Label } from "src/components";

import './InputArea.scss';

/**
 *
 */
interface IInputLabel {

  errors?: boolean;

  required?: boolean;

  rows: number;

  maxLength: number;
}

/**
 *
 */
export const InputArea = React.forwardRef(
  (
    props: IInputLabel & PropsWithChildren<React.TextareaHTMLAttributes<HTMLTextAreaElement>>,
    ref: React.Ref<any>
  ): JSX.Element => {

    // Deconstruct our props
    const {
      errors = false,
      className,
      children,
      rows,
      maxLength,
      ...rest
    } = props;

    const inputWrapperClassNames = classNames({
      'input-label-wrapper': true,
      'input-area-wrapper': true,
      'invalid': errors ? true : false,
      'flex': true,
      'align-center': true,
      'input-disabled': props.disabled,
      'relative': true,
    })

    const [currentText, setCurrentText] = useState<string>('')

    const textArea = document.querySelector(`[name=${rest.name}]`) as HTMLTextAreaElement;

    useEffect(
      () => {
        if (textArea) {
          textArea.addEventListener('input', () => {
            setCurrentText(textArea.value);     
          })
        }
      }, [textArea]
    )


    useEffect(
      () => {
        if (textArea) {
          setCurrentText(textArea.value);
        }
      }, [textArea]
    )

    return (
      <div className={inputWrapperClassNames}>
        <Label required={props.required} className="absolute">{children}</Label>
        <textarea maxLength={maxLength} rows={rows} {...rest} ref={ref} className="width-100 height-100 border-box"></textarea>
        <span className="text-max-length absolute">{currentText.length}/{maxLength}</span>
      </div>
    );
  }
);
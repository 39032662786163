import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFacade } from "./claim-payments.facade";

const CLAIM_CURRENT_PATH: string = CLAIM_PAYMENTS_PATH.VEHICLE_OWNER;
const AGREED_CURRENT_PATH: string = AGREED_PAYMENTS_PATH.VEHICLE_OWNER;

/**
 *
 */
export namespace VehicleOwnerFacade {

	/** */
	export const useVehicleOwnerFacade = () => {
		/** */
		const { currentDamageClaim, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
		/** 1 for user owner, 2 for third party */
		const [vehicleOwner, updateVehicleOwner] = useState<number>(0);
		const dispatch = useDispatch();
		
		useEffect(() => {
			dispatch(ClaimPaymentsAction.updateCurrentPath(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH));
		}, [dispatch, isAgreedPayment])

		return { vehicleOwner, currentDamageClaim, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, isAgreedPayment, updateVehicleOwner };
	};

}
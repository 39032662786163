import { ClaimsFacade } from "src/modules/claims/controller/facades/claims.facade";
import ActiveClaimsList from "../../organisms/ActiveClaimsList/ActiveClaimsList";
import "./OpenClaims.scss";
/**
 *
 */

interface IOpenClaimsList { }

/**
 *
 */

const OpenClaimsPage = (props: IOpenClaimsList): JSX.Element => {
  const { claimList } = ClaimsFacade.useClaimList();
	
  return (
    <div className="height-100 flex column space-between">
      {
        claimList && claimList.length > 0 &&
        <>
          <h2 className="text-center">Selecciona el siniestro activo</h2>
          <p className="text-center">para continuar con el proceso</p>
        </>
      }

      <ActiveClaimsList claimList={claimList} />
    </div>
  );
};

export default OpenClaimsPage;

import { useRef } from 'react';
import PictureManagement from 'src/modules/claim-payments/ui/organisms/PictureManagement/PictureManagement';
import { BankAccountFacade } from 'src/modules/claim-payments/controller/facades/bank-account.facade';
import { DamageClaimNavigateValidationFacade } from 'src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade';
import ButtonSection from '../../molecules/ButtonSection/ButtonSection';
import './BankAccountPage.scss';
import { Util } from 'src/utils/Util';

/**
 *
 */
interface IBankAccount {

}

/**
 *
 */
const BankAccountPage = (props: IBankAccount): JSX.Element => {
	
	/** */
	const contentRef = useRef<HTMLDivElement>(null);
	const { currentDamageClaim, picturesValidation, isAgreedPayment, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, validatePictures } = BankAccountFacade.useBankAccountFacade(contentRef);
	const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id='bank-account-wrapper' className='flex-grow-100 flex'>
			<section className='content height-100' ref={contentRef}>
				<h1>{`Solicitud pago ${ isAgreedPayment ? 'pactado' : 'de daño'}`}</h1>
				<h3>Estado de cuenta con CLABE y nombre</h3>
				<p>Necesitamos saber a dónde se transferirá el monto del pago de daños.</p>
				<p>La cuenta bancaria debe estar a nombre del dueño del auto afectado. <br /> NO puede ser una foto de tu banca móvil o tarjeta.</p>

				<div className="bank-handler-wrapper">
					<div className="bank-tutorial" style={{ backgroundImage: `url(${Util.ASSET.BANK_TUTORIAL})` }}></div>

					{
						currentDamageClaim &&
							<PictureManagement 
								attachment={currentDamageClaim.attachmentAccountStatus} 
								defaultPicture={currentDamageClaim.attachmentAccountStatus.thumbnail} 
								status
								onUpload={(a) => {currentDamageClaim.attachmentAccountStatus = a; validatePictures()}}
                allowPdf
							>
								{currentDamageClaim.attachmentAccountStatus.title}
							</PictureManagement>
					}
				</div>
			</section>

			<ButtonSection 
				backHandler={() => {navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'backward')}} 
				nextHandler={() => {navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward')}} 
				skipHandler={() => navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward')}
				nextButtonDisabled={!picturesValidation}
				hasSkip={true}
			/>
    </div>
  );
};

export default BankAccountPage;
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthStateReducer } from 'src/store/states/core-state/IAuthStateReducer';

export namespace CoreAuthSlice {


  /**
   * 
   */
  const initialState: IAuthStateReducer = {
    isValidAuth: false,
  };

  /**
   * 
   * @param state 
   * @param action 
   */
  const setAuthValidation: CaseReducer<IAuthStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.isValidAuth = action.payload;
  }


  const reducers = {
    setAuthValidation,
  };

  /**
   * 
   */
  const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = authSlice;
}




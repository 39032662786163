import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { useInjectSaga } from "redux-injectors";
import { LOGIN_PATH } from "src/routes/paths/login.paths";
import { Util } from "src/utils/Util";
import { SignOutAction } from "../actions/sign-out.action";
import { SignOutSaga } from "../sagas/sign-out.saga";
import { SignOutSelector } from "../selectors/sign-out.selector";

/**
 *
 */
export namespace SignOutFacade {

  /**
   * 
   */
  export const useSignOut = () => {
    useInjectSaga({key: 'signOut', saga: SignOutSaga.signOutSaga});
    const isValidAuth = useSelector(SignOutSelector.isValidAuth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(
      () => {
        if (isValidAuth === false) {
          localStorage.removeItem(Util.KEY.SESSION_ACTION);
          navigate(LOGIN_PATH.LOGIN, { replace: true });
          window.location.reload();
        }
      }, [isValidAuth, navigate]
    );

    useEffect(
      () => {
        dispatch(SignOutAction.signOut());
      }, [dispatch]
    );
  }
}
import { ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";

/**
 *
 */
export namespace PolicyDriverDataAction {

  /**
   * 
   */
   export const getDriverStored: ActionCreatorWithoutPayload = createAction('GET_DRIVER_STORED');
}
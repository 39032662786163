import { useEffect } from "react";
import { Dropdown, FormError, InputLabel } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { EndorsementConfigurationFormFacade } from "src/modules/policy-endorsements/controller/facades/endorsement-configuration-form.facade";
import { IEndorsementType } from "src/modules/policy-endorsements/controller/interfaces/IEndorsementType";

import './EndorsementConfigurationItem.scss';

/**
 *
 */
interface IEndorsementConfigurationItem {

  index: number;

  typesList: IEndorsementType[];

  onChangeType: (type: IEndorsementType, index: number) => void;

  onRemove: (index: number) => void;

  onNewValue: (isValid: boolean, type: IEndorsementType, newValue?: string) => void;
}

/**
 *
 */
const EndorsementConfigurationItem = (props: IEndorsementConfigurationItem): JSX.Element => {
  const { onNewValue } = props;
  const {
    currentValue, typeSelected, isValidatingRule, newValue, onChangeNewValue,
    setTypeSelected, onVerifyCurrentValue, form, valueEmitted, setValueEmitted, validateRules
  } = EndorsementConfigurationFormFacade.useEndorseType();

  /**
   * 
   * @param item 
   */
  const onChange = (item: { id: number | string, value: string }): void => {
    const type: IEndorsementType = props.typesList.find(t => t.code === item.id)!;
    setTypeSelected(type);
    onVerifyCurrentValue(type);
    props.onChangeType(type, props.index);
  }

  useEffect(
    () => {
      if (typeSelected) {
        if (form.formState.isValid && form.getValues(typeSelected.name) !== '') {
          form.getValues(typeSelected.name)?.toLocaleUpperCase()
          onNewValue(true, typeSelected, form.getValues(typeSelected.name)?.toLocaleUpperCase()!);
          setValueEmitted(true);
        } else if (!form.formState.isValid && valueEmitted) {
          onNewValue(false, typeSelected);
        }
      }
    }, [form, typeSelected, onNewValue, setValueEmitted, valueEmitted, newValue]
  )

  return (
    <div className="endorsement relative" index-zero={props.index === 0 ? '' : null}>
      <div className="endorsement-inputs flex">
        <div>
          <Dropdown placeholder="Selecciona un dato" onChange={onChange} allowEmpty
            items={props.typesList.map(e => { return { id: e.code, value: e.value } })}>
            Tipo de dato
          </Dropdown>
        </div>
        <div>
          <InputLabel disabled defaultValue={currentValue?.value}>
            Dato actual
          </InputLabel>
        </div>
        {
          !typeSelected ?
            <div>
              <InputLabel disabled>
                Dato nuevo
              </InputLabel>
            </div>
            :
            <div>
              <InputLabel
                disabled={!typeSelected}
                maxLength={currentValue?.maxLength}
                errors={!!form.fieldError()}
                loading={isValidatingRule}
                className="new-endorse-data-input"
                {...form.register(typeSelected.name!, {
                  required: true,
                  pattern: currentValue?.pattern,
                  onChange: e => onChangeNewValue(e),
                  validate: {
                    rules: async (value) => await validateRules(value)
                  }
                })}
              >
                Dato nuevo
              </InputLabel>
              <FormError schema={form.fieldSchema()} type={form.errorType()} />
            </div>
        }
      </div>
      <div className="absolute">
        <button type="button" className="width-100 height-100" onClick={(e) => { e.preventDefault(); props.onRemove(props.index) }}>
          {IconFactory.closeIcon()}
        </button>
      </div>
    </div>
  );
};

export default EndorsementConfigurationItem;
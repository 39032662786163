import useCollapse from "react-collapsed";
import { Coverage } from "src/classes/plan/Coverage";
import { Person  } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { IconFactory } from "src/factory/icon.factory";
import { Util } from "src/utils/Util";
import './SummaryItem.scss';

/**
 *
 */
interface ISummaryItem {

  coverage?: Coverage;

  user?: Person;

  vehicle?: Vehicle;

  isContact?: boolean;

}

/**
 *
 */
const SummaryItem = (props: ISummaryItem): JSX.Element => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ duration: 250, defaultExpanded: props.coverage ? false : true, });

  return (
    <div className="summary-item-wrapper">
      <section {...getToggleProps()} className="summary-item-toggle flex column align-center space-between">

        <div className="width-100 flex align-center space-between">
          <div className="summary-item-name flex align-center space-between">
            {
              props.coverage &&
              <>
                <span className="block height-100 background-image image-contain" style={{ backgroundImage: `url(${props.coverage.image})` }}></span>
                <h3 className="flex-grow-100 text-center">{props.coverage.name}</h3>
              </>
            }

            {
              props.vehicle && !props.coverage &&
              <>
                <span className="block height-100 background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_VEHICLE_DATA})` }}></span>
                <h3 className="flex-grow-100 text-center">Datos del veh&iacute;culo</h3>
              </>
            }

            {
              props.user && !props.isContact &&
              <>
                <span className="block height-100 background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_SUMMARY_DATA})` }}></span>
                <h3 className="flex-grow-100 text-center">Datos personales</h3>
              </>
            }

            {
              props.user && props.isContact &&
              <>
                <span className="block height-100 background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_SUMMARY_CONTACT})` }}></span>
                <h3 className="flex-grow-100 text-center">Datos de contacto</h3>
              </>
            }

          </div>

          {
            props.coverage && props.vehicle &&
            <div className="summary-coverage-details flex-grow-100">
              <p>
                {
                  props.coverage.isDeductible &&
                  <b>Deducible: &nbsp;</b>
                }
                {
                  !props.coverage.isDeductible && props.coverage.assured &&
                  <b>Cobertura de: &nbsp;</b>
                }
                {
                  !props.coverage.isDeductible && !props.coverage.assured &&
                  <b>Incluido</b>
                }
                {props.coverage.getCoverageDetails(props.vehicle!)}
              </p>
            </div>
          }

          <aside is-expanded={isExpanded ? '' : null}>
            {IconFactory.dropDownArrowIcon()}
          </aside>
        </div>

        {
            props.coverage && props.vehicle &&
            <div className="summary-coverage-details flex-grow-100">
              <p>
                {
                  props.coverage.isDeductible &&
                  <b>Deducible: &nbsp;</b>
                }
                {
                  !props.coverage.isDeductible && props.coverage.assured &&
                  <b>Cobertura de: &nbsp;</b>
                }
                {
                  !props.coverage.isDeductible && !props.coverage.assured &&
                  <b>Incluido</b>
                }
                {props.coverage.getCoverageDetails(props.vehicle!)}
              </p>
            </div>
          }
        
      </section>

      <section {...getCollapseProps()} className="summary-item-content">
        {
          props.coverage &&
          <>
            <h4 className="text-center">No incluye</h4>
            <ul className="text-center">
              {
                props.coverage.excludeList.map(
                  (value, index) => <li key={index}><span className="relative">{value}</span></li>
                )
              }
            </ul>
            <h4 className="text-center">S&iacute; incluye </h4>
            <ul className="text-center">
              {
                props.coverage.includeList.map(
                  (value, index) => <li key={index}><span className="relative">{value}</span></li>
                )
              }
            </ul>
          </>
        }

        {
          props.user && !props.isContact &&
          <>
            <ul className="flex text-center">
              <li>
                <b>Nombre</b><br />
                <span>{props.user.name}</span>
              </li>
              <li>
                <b>Apellido paterno</b><br />
                <span>{props.user.lastName}</span>
              </li>
              <li>
                <b>Apellido materno</b><br />
                <span>{props.user.secondLastName}</span>
              </li>
              <li>
                <b>Sexo</b><br />
                <span>{props.user.profile?.gender === Util.STATUS_CODE.GENDER.FEMALE ? 'Femenino' : 'Masculino'}</span>
              </li>
              <li>
                <b>RFC</b><br />
                <span>{props.user.profile?.rfc}</span>
              </li>
            </ul>
          </>
        }

        {
          props.user && props.isContact &&
          <>
            <ul className="flex text-center">
              <li>
                <b>Tel&eacute;fono</b><br />
                <span>{props.user.profile?.getPhoneNumber()}</span>
              </li>
              <li>
                <b>Correo electr&oacute;nico</b><br />
                <span>{props.user.email}</span>
              </li>
            </ul>
          </>
        }

        {
          props.vehicle && !props.coverage &&
          <>
            <ul className="flex text-center">
              <li>
                <b>Marca</b><br />
                <span>{props.vehicle.brand?.name}</span>
              </li>
              <li>
                <b>Modelo</b><br />
                <span>{props.vehicle.model?.name}</span>
              </li>
              <li>
                <b>A&ntilde;o</b><br />
                <span>{props.vehicle.year?.value}</span>
              </li>
              <li>
                <b>Versi&oacute;n</b><br />
                <span>{props.vehicle.version?.description}</span>
              </li>
              <li>
                <b>Placas</b><br />
                <span>{props.vehicle.plate}</span>
              </li>
              <li>
                <b>VIN</b><br />
                <span>{props.vehicle.vin}</span>
              </li>
            </ul>
          </>
        }
      </section>
    </div>
  );
};

export default SummaryItem;
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "src/components/atoms";
import { IconFactory } from "src/factory/icon.factory";
import './Card.scss';

/**
 *
 */
interface ICard {
  /**
   * 
   */
  isLink?: boolean;
  /**
   * 
   */
  to?: string;
  /**
   * 
   */
  disabled?: boolean;
  /**
   * 
   */
  img: string;
  /**
   * 
   */
  imageSize?: 'small' | 'large',
  /**
   * 
   */
  onClick?: () => void;
  /**
   * 
   */
  loading?: boolean;
}

/**
 *
 */
export const Card = (props: PropsWithChildren<ICard>): JSX.Element => {
  const imgClasses = classNames(
    {
      'width-100': props.imageSize !== 'small',
      'height-100': props.imageSize !== 'small',
      'width-60': props.imageSize === 'small',
      'height-60': props.imageSize === 'small',
    }
  );

  return (
    <>
      {
        props.isLink && !props.disabled &&
        <Link className="card inline-flex vertical-align-middle" to={props.to ? props.to : ''} card-hover="">
          <section>
            <div className="item-image height-100 flex align-center justify-center">
              <img alt="itemImage" className={imgClasses} src={props.img} />
            </div>
          </section>
          <section className="flex align-center">
            {props.children}
          </section>
          <section>
            <div className="card-arrow flex height-100 align-center justify-flex-end">
              {IconFactory.rightArrowIcon()}
            </div>
          </section>
        </Link>
      }
      {
        (!props.isLink || props.disabled) &&
        <div className="card inline-flex vertical-align-middle relative"
          card-hover={!props.disabled && !props.loading ? '' : null}
          onClick={() => !props.loading && !props.disabled && props.onClick && props.onClick()}
        >
          {
            !props.loading ?
              <>
                <section>
                  <div className="item-image height-100 flex align-center justify-center">
                    <img alt="itemImage" className={imgClasses} src={props.img} />
                  </div>
                </section>
                <section className="flex align-center">
                  {props.children}
                </section>
                {
                  !props.disabled &&
                  <section>
                    <div className="card-arrow flex height-100 align-center justify-flex-end">
                      {IconFactory.rightArrowIcon()}
                    </div>
                  </section>
                }
              </>
              :
              <Spinner color="main" />
          }
        </div>
      }
    </>
  );
};
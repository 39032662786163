import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { CoreSelector } from "src/core/selectors/core.selector";
import { EventFactory } from "src/factory/event.factory";
import { DASHBOARD_PATH } from "src/routes/paths/dashboard.paths";
import { Util } from "src/utils/Util";
import { POLICY_CREATION_PATH, VEHICLE_ROUTE_ID } from "../../../../routes/paths/policy-creation.paths";
import { PolicyCreationSaga } from "../sagas/policy-creation.saga";
import { PolicyCreationSelector } from "../selectors/policy-creation.selector";
import { PolicyCreationSlice } from "../slices/policy-creation.slice";

/**
 *
 */
export namespace PolicyCreationFacade {

  /**
   * Inject redux saga and redux reducers.
   */
  export const usePolicyCreation = () => {
    useInjectSaga({ key: 'policyCreation', saga: PolicyCreationSaga.policyCreationSaga });
    useInjectReducer({ key: 'policyCreation', reducer: PolicyCreationSlice.reducer });
  }

  /**
   * Returns current policy creation actors: Person (current user or new Person), current vehicle, current plan selected.
   */
  export const usePolicyCreationActors = () => {
    const user = useSelector(CoreSelector.currentUser);
    const driver = useSelector(PolicyCreationSelector.currentDriver);
    const vehicle = useSelector(PolicyCreationSelector.currentVehicle);
    const plan = useSelector(PolicyCreationSelector.currentPlan);
    const coupon = useSelector(PolicyCreationSelector.currentCoupon);
    const coverages = useSelector(PolicyCreationSelector.coverages);
		const vehiclePostalCode = useSelector(PolicyCreationSelector.vehiclePostalCode);
		const policy = useSelector(PolicyCreationSelector.currentPolicy);

    return { user, vehicle, plan, driver, coupon, coverages, vehiclePostalCode, policy }
  }

  /**
   * Validate whether the owner is the current user or a new user.
   * Navigate to the respective page for each case.
   */
  export const useOwnerValidation = (): boolean => {
    //const navigate = useNavigate();
    const isCurrentUser = localStorage.getItem(Util.KEY.POLICY_CREATION_OWNER) ? true : false;

    // const useMountEffect = () => useEffect(
    //   () => { 
    //     // Navigate to vehicle data when user refresh page
    //     navigate(POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_DATA), { replace: true });
    //   }, []);

    // useMountEffect();

    return isCurrentUser;
  }

  /**
   * 
   */
  export const useStepStatus = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const currentDriver = useSelector(PolicyCreationSelector.currentDriver);
    const currentVehicle = useSelector(PolicyCreationSelector.currentVehicle);
    const currentPlan = useSelector(PolicyCreationSelector.currentPlan);
    const planReview = useSelector(PolicyCreationSelector.planReview);
    const [isLastStep, setIsLastStep] = useState<boolean>(false);

    useEffect(() => {
      if (location.pathname === POLICY_CREATION_PATH.COMPLETION) {
        setIsLastStep(true);
      }   
    }, [location.pathname]);

    useEffect(() => {
      if (location.pathname !== POLICY_CREATION_PATH.COMPLETION && isLastStep) {
        navigate(DASHBOARD_PATH.DASHBOARD, {replace: true});
      }   
    }, [location.pathname, isLastStep, navigate]);

    /**
     * 
     */
    const isDriverStepCompleted = (): boolean => {
      let isCompleted: boolean = false;
      if (currentDriver) {
        isCompleted = true;
      }
      return isCompleted;
    }

    /**
     * 
     * @returns 
     */
    const isVehicleStepCompleted = (): boolean => {
      let isCompleted: boolean = false;
      if (currentVehicle && currentVehicle.brand && currentVehicle.model && currentVehicle.year
        && currentVehicle.version && currentVehicle.vin !== '' && currentVehicle.plate !== '') {
        isCompleted = true;

      }
      return isCompleted;
    }

    /**
     * 
     * @returns 
     */
    const isPlanStepCompleted = (): boolean => {
      let isCompleted: boolean = false;
      if (currentPlan) {
        isCompleted = true;
      }
      return isCompleted;
    }
    
    /**
     * 
     * @returns 
     */
    const isReviewStepCompleted = (): boolean => {
      return planReview;
    }

		useEffect(( ) => {
			const event = EventFactory.EVENT_NAMES.POLICY_CREATION;
			let step = -1;

			if(location.pathname.includes(POLICY_CREATION_PATH.PERSON_DATA)){
				step = 0;
			}else if(location.pathname.includes(POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_DATA))){
				step = 1;
			}else if(location.pathname.includes(POLICY_CREATION_PATH.PLAN_SELECTION)){
				step = 2;
			}else if(location.pathname.includes(POLICY_CREATION_PATH.PLAN_REVIEW)){
				step = 3;
			}else if(location.pathname.includes(POLICY_CREATION_PATH.PLAN_PAYMENT)){
				step = 4;
			}else if(location.pathname.includes(POLICY_CREATION_PATH.COMPLETION)){
				step = 5;
			}

			if(step !== -1){
				const property = { 'multipoliza': step };
				EventFactory.event.track(event, property);
			}
		}, [location.pathname]);
    
    return { isLastStep, isVehicleStepCompleted, isPlanStepCompleted, isReviewStepCompleted, isDriverStepCompleted };
  }
}
import VehiclePolicyManagement from "../atoms/VehiclePolicyManagement/VehiclePolicyManagement";
import VehicleClaimsManagement from "../atoms/VehicleClaimsManagement/VehicleClaimsManagement";
//import { memo } from 'React';
import { Vehicle } from "src/classes/vehicle/Vehicle";
import './VehicleManagement.scss';

/**
 *
 */
interface IVehicleManagement {
  /**
   * Type of management. Values: policy, claims.
   */
  type: 'policy' | 'claims';

	subType?: 'basic' | 'aditional' | 'complete';

  storedVehicle?: Vehicle;

  onComplete?: (vehicle?: Vehicle) => void;
}

/**
 *
 */
const VehicleManagement = (props: IVehicleManagement): JSX.Element => {
	/** */
	const {type, subType, storedVehicle, onComplete} = props;

  return (
    <div id="vehicle-management-wrapper">
      {
        type === 'policy' &&
        <VehiclePolicyManagement onComplete={onComplete} subType={subType} storedVehicle={storedVehicle} />
      }

      {
        type === 'claims' &&
        <VehicleClaimsManagement onComplete={onComplete} storedVehicle={storedVehicle}/>
      }
    </div>
  );
};

export default VehicleManagement;
import { Card, Modal, Spinner, Status } from "src/components";
import PolicyCancellationPrompt from '../../components/PolicyCancellationPrompt/PolicyCancellationPrompt';
import { PolicyFacade } from "src/modules/policy/controller/facades/policy.facade";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { Util } from "src/utils/Util";

import './PolicyManagement.scss';
import PolicyCancelledPrompt from "../../components/PolicyCancelledPrompt/PolicyCancelledPrompt";
import { PolicyCancellationFacade } from "src/modules/policy/controller/facades/policy-cancellation.facade";

/**
 *
 */
interface IPolicyManagement {

}

/**
 *
 */
const PolicyManagementPage = (props: IPolicyManagement): JSX.Element => {
  const { selectedPolicy } = PolicyFacade.usePolicyManagement();
  const { prompt, validateCancellationFlow, cancellationDate, loading, navigateToCancellation } = PolicyCancellationFacade.useCancellationPrompt();

  return (
    <div className="policyManagement height-100 relative flex column">
      {
        !selectedPolicy &&
        <Spinner color="main" circles />
      }

      {
        selectedPolicy &&
        <>
          <section className="policyManagement__section flex space-between">

            <div className="policyManagement__section__item flex-grow-100">
              <h2>P&oacute;liza {selectedPolicy.policyNumber}</h2>
              <ul className="vehicle-details flex column flex-grow-100">
                <li className="flex space-between align-center">
                  Pago
                  <span className="text-right">{selectedPolicy.getTypeName()}</span>
                </li>
                <li className="flex space-between align-center">
                  Vigencia
                  {
                    selectedPolicy.isActive() &&
                    <Status className="inline-flex align-center justify-center" type="active" shape="background">
                      {selectedPolicy.getStatusValue()}
                    </Status>
                  }
                  {
                    selectedPolicy.isCanceled() &&
                    <Status className="inline-flex align-center justify-center" type="deactive" shape="background">
                      {selectedPolicy.getStatusValue()}
                    </Status>
                  }
                  {
                    selectedPolicy.isPending() &&
                    <Status className="inline-flex align-center justify-center" type="pending" shape="background">
                      {selectedPolicy.getStatusValue()}
                    </Status>
                  }
                  {
                    selectedPolicy.toBegin() &&
                    <Status className="inline-flex align-center justify-center" type="toBegin" shape="background">
                      {selectedPolicy.getStatusValue()}
                    </Status>
                  }
                  {
                    selectedPolicy.isExpired() &&
                    <Status className="inline-flex align-center justify-center" type="expired" shape="background">
                      {selectedPolicy.getStatusValue()}
                    </Status>
                  }
                </li>
                <li className="flex space-between align-center">
                  Fecha de inicio
                  <span className="text-right">{selectedPolicy.getStartDateFormat()}</span>
                </li>
                <li className="flex space-between align-center">
                  Fecha de vencimiento
                  <span className="text-right">{selectedPolicy.getExpirationDateFormat()}</span>
                </li>
              </ul>
            </div>


            <div className="policyManagement__section__item flex-grow-100 flex column">
              <h2>Veh&iacute;culo</h2>
              <ul className="vehicle-details flex column flex-grow-100">
                <li className="flex space-between align-center">
                  Marca
                  <span className="text-right">{selectedPolicy.vehicle?.brand?.name}</span>
                </li>
                <li className="flex space-between align-center">
                  Modelo
                  <span className="text-right">{selectedPolicy.vehicle?.model?.name}</span>
                </li>
                <li className="flex space-between align-center">
                  A&ntilde;o
                  <span className="text-right">{selectedPolicy.vehicle?.year?.value}</span>
                </li>
                <li className="flex space-between align-center">
                  Versi&oacute;n
                  <span className="text-right">{selectedPolicy.vehicle?.version?.description}</span>
                </li>
                <li className="flex space-between align-center">
                  Placas
                  <span className="text-right">{selectedPolicy.vehicle?.plate}</span>
                </li>
                <li className="flex space-between align-center">
                  VIN
                  <span className="text-right">{selectedPolicy.vehicle?.vin}</span>
                </li>
              </ul>
            </div>

          </section>

          <section className="policyManagement__section policyManagement__section--actions">
            {
              selectedPolicy.isPlanPaymentMonthly() && selectedPolicy.paymentMethod &&
              <Card img={Util.ASSET.POLICY_MANAGEMENT_PAYMENT} imageSize="small" isLink to={`${POLICY_PATH.PAYMENT_METHOD}/${selectedPolicy.policyNumber}`}>
                <div className="policyManagement__section__card flex column">
                  <h2>M&eacute;todo de pago</h2>
                  <p>Verifica o cambia tu m&eacute;todo de pago</p>
                </div>
              </Card>
            }

            <Card img={Util.ASSET.POLICY_MANAGEMENT_ENDORSEMENT} imageSize="small" isLink to={`${POLICY_PATH.ENDORSEMENTS_HISTORY}/${selectedPolicy.policyNumber}`}>
              <div className="policyManagement__section__card flex column">
                <h2>Administrar endosos</h2>
                <p>Solicita modificaci&oacute;n de datos de tu P&oacute;liza</p>
              </div>
            </Card>

            {
              selectedPolicy.isPlanPaymentMonthly() &&
              <Card img={Util.ASSET.POLICY_MANAGEMENT_BILL} imageSize="small" isLink to={`${POLICY_PATH.PAYMENT_HISTORY}/${selectedPolicy.policyNumber}`}>
                <div className="policyManagement__section__card flex column">
                  <h2>Historial de pago</h2>
                  <p>Revisa y descarga la factura de tus pagos</p>
                </div>
              </Card>
            }

            <Card img={Util.ASSET.POLICY_MANAGEMENT_CANCELLATION} imageSize="small"
              loading={loading.value}
              onClick={() => validateCancellationFlow(selectedPolicy)}
            >
              <div className="policyManagement__section__card flex column">
                <h2>Cancelar P&oacute;liza</h2>
                <p>Solicita la cancelaci&oacute;n de tu P&oacute;liza</p>
              </div>
            </Card>

          </section>
          <Modal show={prompt.showAlreadyCancelledPrompt} position="center" backgroundDismiss onClose={() => prompt.setShowAlreadyCancelledPrompt(false)}>
            <PolicyCancelledPrompt cancelledDate={cancellationDate}
              onBack={() => prompt.setShowAlreadyCancelledPrompt(false)}
            />
          </Modal>

          <Modal show={prompt.showCancellationPrompt} position="center" backgroundDismiss onClose={() => prompt.setShowCancellationPrompt(false)}>
            <PolicyCancellationPrompt selectedPolicy={selectedPolicy}
              onBack={() => prompt.setShowCancellationPrompt(false)}
              onNavigate={() => { prompt.setShowCancellationPrompt(false); navigateToCancellation(selectedPolicy) }}
            />
          </Modal>
        </>
      }
    </div>
  );
};

export default PolicyManagementPage;
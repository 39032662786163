import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleVersion } from "src/classes/vehicle/VehicleVersion";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { VehicleManagementAdapter } from "../adapters/vehicle-management.adapter";
import { VehicleManagementService } from "../services/vehicle-management.service";

/**
 *
 */
export namespace VehicleManagementFeature {

  /**
   * 
   */
  export const vehicleBrandsFeature = async (): Promise<VehicleBrand[]  | undefined> => {
    try {
      const { data } = await VehicleManagementService.vehicleBrandsService();
      const brands: VehicleBrand[] = VehicleManagementAdapter.brandsDataAdapter(data);
    	return brands;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param brand 
   * @returns 
   */
  export const vehicleModelsFeature = async (brand: VehicleBrand): Promise<VehicleModel[] | undefined> => {
    try {
      const { data } = await VehicleManagementService.vehicleModelsService(brand.id.toString());
      const models: VehicleModel[] = VehicleManagementAdapter.modelsDataAdapter(data);
      return models;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param brand 
   * @returns 
   */
   export const vehicleYearsFeature = async (model: VehicleModel): Promise<VehicleYear[] | undefined> => {
    try {
      const { data } = await VehicleManagementService.vehicleYearsService({brand: model.brand.toString(), model: model.id.toString()});
      const vehicleYears: VehicleYear[] = VehicleManagementAdapter.vehicleYearsDataAdapter(data);
      return vehicleYears;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * 
   * @param brand 
   * @returns 
   */
   export const vehicleVersionsFeature = async (year: VehicleYear): Promise<VehicleVersion[] | undefined> => {
    try {
      const { data } = await VehicleManagementService.vehicleVersionsService({brand: year.brand.toString(), model: year.model.toString(), year: year.value.toString()});
      const vehicleVersions: VehicleVersion[] = VehicleManagementAdapter.vehicleVersionsDataAdapter(data);
      return vehicleVersions;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
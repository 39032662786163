import { Outlet } from "react-router-dom";
import { Spinner } from "src/components";
import { PolicyRenewalFacade } from "../../controller/facades/policy-renewal.facade";

import './PolicyRenewal.scss';

/**
 *
 */
const PolicyRenewalPage = (): JSX.Element => {
  const { validatingPolicy, isLastStep } = PolicyRenewalFacade.usePolicyRenewal();

  return (
    <div id="policy-renewal-wrapper" className="flex-grow-100 flex">
      <div className="border-box width-100">
        {
          validatingPolicy &&
          <Spinner color="main"/>
        }
        {
          !validatingPolicy &&
          <div className="renewal-content relative height-100 border-box" is-last-step={isLastStep ? '' : null}>
            <Outlet />
          </div>
        }
      </div>
    </div>
  );
};

export default PolicyRenewalPage;
import { Button } from "src/components";
import { EndorsementSummaryFacade } from "src/modules/policy-endorsements/controller/facades/endorsement-summary.facade";
import { Util } from "src/utils/Util";
import './EndorsementSummary.scss';

/**
 *
 */
const EndorsementSummaryPage = (): JSX.Element => {
  const { currentPolicy, endorsements, isLoading, getTypeName, getConfigurationValue, apllyChanges } = EndorsementSummaryFacade.useEndorsementSummary();

  return (
    <div id="endorsement-summary-wrapper" className="height-100 flex column space-between">
      <section>
        <h1 className="text-center">Verifica los cambios realizados</h1>
        <p className="text-center">
          Este es un resumen de los cambios que deseas realizar y que procesaremos con respecto a
          tu <b>p&oacute;liza {currentPolicy.policyNumber}.</b>
        </p>
        <p className="text-center">Verifica que cada uno este bien y que no tengas alg&uacute;n error en los datos a modificar.</p>
      </section>

      <section className="relative flex-grow-100">
        <h2 className="text-center">Endoso - {Util.TRANSFORM.DATE.formatDate(new Date())}</h2>
        <ul className="text-center">
          {
            endorsements.map((e, index) => {
              return (
                <li key={index}>
                  <b>Cambio de {getTypeName(e)} </b>: De {getConfigurationValue(e)}
                </li>
              )
            }
            )
          }
        </ul>
      </section>
      <section className="buttons-wrapper text-center">
        <Button type="button"
          disabled={isLoading}
          loading={isLoading}
          onClick={(e) => { e.preventDefault(); apllyChanges() }}>
          Aplicar cambios
        </Button>
      </section>
    </div>
  );
};

export default EndorsementSummaryPage;
import { Button } from "src/components";
import { Util } from "src/utils/Util";
import './InsurancePictureError.scss';

/**
 *
 */
interface IInsurancePictureError {

  errorName: string;

  onClose: () => void;

}

/**
 *
 */
const InsurancePictureError = (props: IInsurancePictureError): JSX.Element => {
  
  return (
    <>
      {
        props.errorName !== '' &&
        <div className="validation-error border-box fixed width-100 height-100 flex column align-center justify-center">
          {
            props.errorName === Util.STATUS_CODE.VEHICLE_PICTURE_ERROR.DATE &&
            <>
              <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_PICTURE_OUT_OF_DATE})` }}></span>
              <h1 className="text-center">La foto tiene m&aacute;s de 48 horas de haber sido tomada.</h1>
              <p className="text-center">Es necesario que la foto sea lo m&aacute;s reciente posible.</p>
            </>
          }
          {
            props.errorName === Util.STATUS_CODE.VEHICLE_PICTURE_ERROR.BLURRY &&
            <>
              <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_PICTURE_BLURRY})` }}></span>
              <h1 className="text-center">La foto se encuentra borrosa o tiene baja resoluci&oacute;n.</h1>
              <p className="text-center">Intenta de nuevo con una mejor imagen.</p>
            </>
          }
          {
            props.errorName === Util.STATUS_CODE.VEHICLE_PICTURE_ERROR.VEHICLE &&
            <>
              <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.POLICY_PICTURE_ITEMS})` }}></span>
              <h1 className="text-center">No hemos detectado un veh&iacute;culo en la foto</h1>
              <p className="text-center">Intenta de nuevo y veirif&iacute;ca que el veh&iacute;culo est&eacute; visible.</p>
            </>
          }

          <Button className="absolute" type="button" onClick={(e) => { e.preventDefault(); props.onClose() }}>Volver a intentar</Button>
        </div>
      }
    </>
  );
};

export default InsurancePictureError;
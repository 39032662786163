import { PayloadAction } from '@reduxjs/toolkit';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects'
import { VehicleBrand } from 'src/classes/vehicle/VehicleBrand';
import { VehicleModel } from 'src/classes/vehicle/VehicleModel';
import { VehicleVersion } from 'src/classes/vehicle/VehicleVersion';
import { VehicleYear } from 'src/classes/vehicle/VehicleYear';
import { VehicleManagementAction } from '../actions/vehicle-management.action';
import { VehicleManagementFeature } from '../features/vehicle-management.feature'
import { VehicleManagementSlice } from '../slices/vehicle-management.slice';

/**
 *
 */
export namespace VehicleManagementSaga {
	
	const TRIES = 10;
	const DELAY = 1000;
  type Brands = SagaReturnType<typeof VehicleManagementFeature.vehicleBrandsFeature>;
  type Models = SagaReturnType<typeof VehicleManagementFeature.vehicleModelsFeature>;
  type Years = SagaReturnType<typeof VehicleManagementFeature.vehicleYearsFeature>;
  type Versions = SagaReturnType<typeof VehicleManagementFeature.vehicleVersionsFeature>;

  /**
   * 
   */
  function* getVehicleBrands() {
    try {
			yield put(VehicleManagementSlice.actions.setLoadingBrands(true));
      const brands: Brands = yield retry(TRIES, DELAY, VehicleManagementFeature.vehicleBrandsFeature);
      yield put(VehicleManagementSlice.actions.vehicleBrandsList(brands as VehicleBrand[]));
			yield put(VehicleManagementSlice.actions.setLoadingBrands(false));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   */
  function* getVehicleModels(action: PayloadAction<VehicleBrand>): any {
    try {
			yield put(VehicleManagementSlice.actions.setLoadingModels(true));
      const models: Models = yield retry(TRIES, DELAY, VehicleManagementFeature.vehicleModelsFeature, action.payload);
      yield put(VehicleManagementSlice.actions.vehicleModelsList(models as VehicleModel[]));
			yield put(VehicleManagementSlice.actions.setLoadingModels(false));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   */
  function* getVehicleYears(action: PayloadAction<VehicleModel>) {
    try {
			yield put(VehicleManagementSlice.actions.setLoadingYears(true));
      const years: Years = yield retry(TRIES, DELAY, VehicleManagementFeature.vehicleYearsFeature, action.payload);
      yield put(VehicleManagementSlice.actions.vehicleYearsList(years as VehicleYear[]));
			yield put(VehicleManagementSlice.actions.setLoadingYears(false));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   */
  function* getVehicleVersions(action: PayloadAction<VehicleYear>): any {
    try {
			yield put(VehicleManagementSlice.actions.setLoadingVersion(true));
      const versions: Versions = yield retry(TRIES, DELAY, VehicleManagementFeature.vehicleVersionsFeature, action.payload);
      yield put(VehicleManagementSlice.actions.vehicleVersionsList(versions as VehicleVersion[]));
			yield put(VehicleManagementSlice.actions.setLoadingVersion(false));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 
   */
  function* onItemSelected(action: PayloadAction<string>) {
    if (action.payload === 'brand') {
      yield put(VehicleManagementSlice.actions.vehicleVersionsList([]));
      yield put(VehicleManagementSlice.actions.vehicleModelsList([]));
      yield put(VehicleManagementSlice.actions.vehicleYearsList([]));
    } else if (action.payload === 'model') {
      yield put(VehicleManagementSlice.actions.vehicleYearsList([]));
      yield put(VehicleManagementSlice.actions.vehicleVersionsList([]));
    } else if (action.payload === 'year') {
      yield put(VehicleManagementSlice.actions.vehicleVersionsList([]));
    }
  }

  /**
   * 
   */
  function* clearManagement() {
    yield put(VehicleManagementSlice.actions.clear());
  }

  export function* vehicleDataSaga() {
    yield takeLatest(VehicleManagementAction.getBrands.type, getVehicleBrands);
    yield takeLatest(VehicleManagementAction.getModels.type, getVehicleModels);
    yield takeLatest(VehicleManagementAction.getYears.type, getVehicleYears);
    yield takeLatest(VehicleManagementAction.getVersion.type, getVehicleVersions);
    yield takeLatest(VehicleManagementAction.onItemSelectedFormList.type, onItemSelected);
    yield takeLatest(VehicleManagementAction.clear.type, clearManagement)
  }
}
import { Navigate } from "react-router";
import PlanPayment from "../ui/pages/PlanPayment/PlanPayment";
import PlanReview from "../ui/pages/PlanReview/PlanReview";
import PlanSelection from "../ui/pages/PlanSelection/PlanSelection";
import PolicyCompletion from "../ui/pages/PolicyCompletion/PolicyCompletion";
import PolicyCreationPage from "../ui/pages/PolicyCreation";
import PolicyCreationVehiclePage from "../ui/pages/PolicyCreationVehicle/PolicyCreationVehicle";
import PolicyCreationDriverPage from "../ui/pages/PolicyCreationDriver/PolicyCreationDriver";
import { POLICY_CREATION_PATH } from "../../../routes/paths/policy-creation.paths";
import PolicyOwner from "../ui/pages/PolicyOwner/PolicyOwner";
import PolicyVehicleSalvage from "../ui/pages/PolicyVehicleSalvage/PolicyVehicleSalvage";

/**
 * 
 */
export const PolicyCreationRoutes = (isValidAuth: boolean, redirect: string) => {
  return {
    path: POLICY_CREATION_PATH.POLICY_CREATION,
    element: isValidAuth === true ? <PolicyCreationPage /> :  <Navigate to={redirect} />,
    children: [
      { path: `${POLICY_CREATION_PATH.PERSON_DATA}`, element: <PolicyCreationDriverPage /> },
      { path: `${POLICY_CREATION_PATH.VEHICLE_DATA_PATH}`, element: <PolicyCreationVehiclePage /> },
      { path: `${POLICY_CREATION_PATH.PLAN_SELECTION}`, element: <PlanSelection /> },
			{ path: `${POLICY_CREATION_PATH.POLICY_VEHICLE_SALVAGE}`, element: <PolicyVehicleSalvage /> },
			{ path: `${POLICY_CREATION_PATH.POLICY_OWNER}`, element: <PolicyOwner /> },
      { path: `${POLICY_CREATION_PATH.PLAN_REVIEW}`, element: <PlanReview /> },
      { path: `${POLICY_CREATION_PATH.PLAN_PAYMENT}`, element: <PlanPayment /> },
      { path: `${POLICY_CREATION_PATH.COMPLETION}`, element: <PolicyCompletion /> },
    ]
  }
}

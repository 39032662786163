import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertFactory } from "src/factory/alert.factory";
import { LOGIN_PATH } from "src/routes/paths/login.paths";
import { Util } from "src/utils/Util";
import { ForgotPasswordFeature } from "../features/forgot-password.feature";

/**
 *
 */
export namespace ForgotPasswordFacade {

  /**
   * 
   */
  export const useRecoveryEmail = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [emailSended, setemailSended] = useState(false);

    /**
     * 
     */
    const sendEmail = async (email: string) => {
      try {
        setIsLoading(true);
        await ForgotPasswordFeature.sendRecoveryEmailFeature(email);
        setemailSended(true);
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        setIsLoading(false);
      }
    }

    return { sendEmail, emailSended, isLoading }
  }

  /**
   * 
   */
  export const useRestorePassword = () => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const { search } = useLocation();
    const navigate = useNavigate();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const [email, setEmail] = useState<string>('');
    const [token, setToken] = useState<string>('');

    /**
     * 
     * @param isValid 
     * @param password 
     */
    const validateNewPassword = (isValid: boolean, password?: { password: string, confirmPassword: string }): void => {
      if (isValid && password && password.password === password.confirmPassword) {
        setNewPassword(password.password);
        setIsNewPasswordValid(true);
      } else {
        setIsNewPasswordValid(false);
      }
    }

    /**
     * 
     */
    const restorePassword = async (): Promise<void> => {
      try {
        setIsUpdating(true);
        await ForgotPasswordFeature.restorePasswordFeature(newPassword, token);
        AlertFactory.successAlert('Se ha restablecido tu contraseña.');
        navigate(LOGIN_PATH.LOGIN, {replace: true});
      } catch (e) {
        if ((e as Error).message) {
          AlertFactory.errorAlert((e as Error).message);
        }
      } finally {
        setIsUpdating(false);
      }
    }

    /**
     * 
     */
    const invlidParams = useCallback(
      () => {
        navigate(LOGIN_PATH.LOGIN, { replace: true });
        AlertFactory.errorAlert('La URL es inválida.');
      }, [navigate],
    );

    /**
     * 
     */
    const emailDecode = useCallback(
      () => {
        try {
          const paramsEmail = atob(query.get('k')!);
          if (Util.PATTERN.EMAIL.test(paramsEmail)) {
            setEmail(paramsEmail);
          } else {
            throw new Error();
          }
        } catch {
          invlidParams();
        }

      }, [setEmail, invlidParams, query]
    )

    useEffect(
      () => {
        if (query.get('k') && query.get('token')) {
          emailDecode();
          setToken( query.get('token')!);
        } else {
          invlidParams();
        }
      }, [query, invlidParams, emailDecode]
    );

    return { email, validateNewPassword, isNewPasswordValid, restorePassword, isUpdating }
  }
}
import { PaymentCost } from "../PaymentCost";

/**
 * 
 */
export class PlanCheckout {

  /**
   *
   */
  private _paymentId: string;
  set paymentId(_paymentId: string) { this._paymentId = _paymentId };
  get paymentId(): string { return this._paymentId };
  /**
   *
   */
  private _checkoutUrl: string;
  set checkoutUrl(_checkoutUrl: string) { this._checkoutUrl = _checkoutUrl };
  get checkoutUrl(): string { return this._checkoutUrl };
  /**
   *
   */
  private _secretId: string;
  set secretId(_secretId: string) { this._secretId = _secretId };
  get secretId(): string { return this._secretId };
  /**
   *
   */
  private _cardType: string;
  set cardType(_cardType: string) { this._cardType = _cardType };
  get cardType(): string { return this._cardType };
  /**
   *
   */
  private _onwerName: string;
  set ownerName(_onwerName: string) { this._onwerName = _onwerName };
  get ownerName(): string { return this._onwerName };
  /**
   *
   */
  private _cost: PaymentCost | undefined;
  set cost(_cost: PaymentCost | undefined) { this._cost = _cost };
  get cost(): PaymentCost | undefined { return this._cost };
	/**
	 * 
	 */
	private _isMonthly: boolean;
	set isMonthly(_isMonthly: boolean) { this._isMonthly = _isMonthly };
	get isMonthly(): boolean { return this._isMonthly };

  constructor(_cost?: PaymentCost) {
    this._paymentId = '';
    this._checkoutUrl = '';
    this._secretId = '';
    this._cardType = '';
    this._onwerName = '';
    this._cost = _cost;
		this._isMonthly = false;
  }
	
}
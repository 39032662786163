import { Button, ButtonLink, Spinner, Status } from "src/components";
import { Policy } from "src/classes/Policy";
import { Util } from "src/utils/Util";
import { POLICY_PARAMS, POLICY_PATH } from "src/routes/paths/policy.paths";

import './PolicyList.scss';

/**
 *
 */
interface IPolicyList {
  /**
   * 
   */
  policyList: Policy[] | undefined;

  status: string | undefined;
}

/**
 *
 */
const PolicyList = (props: IPolicyList): JSX.Element => {
  const { policyList } = props;

  return (
    <div className="policy-list-wrapper height-100 relative">
      {
        policyList === undefined ?
          <Spinner circles />
          :
          <>
            {
              policyList.length > 0 ?

                <ul>
                  {
                    policyList.map(
                      policy => {
                        return (
                          <li key={policy.id} className="width-100 relative">
                            <section className="policy-top-section flex space-between align-center">
                              {
                                policy?.renewalDate &&
                                <div className="renewal-badge absolute">
                                  <span className="absolute block text-center">
                                    Renovada
                                  </span>
                                </div>
                              }
                              <div>
                                <h2>P&oacute;liza {policy.policyNumber}</h2>
                                <h3>Pago {policy.getTypeName()}</h3>
                              </div>
                              <div>
                                {
                                  policy.isActive() && <Status type="active" shape="background">{policy.getStatusValue()}</Status>
                                }
                                {
                                  policy.isCanceled() && <Status type="deactive" shape="background">{policy.getStatusValue()}</Status>
                                }
                                {
                                  policy.isPending() && <Status type="pending" shape="background">{policy.getStatusValue()}</Status>
                                }
                                {
                                  policy.toBegin() && <Status type="toBegin" shape="background">{policy.getStatusValue()}</Status>
                                }
                                {
                                  policy.isExpired() && <Status type="expired" shape="background">{policy.getStatusValue()}</Status>
                                }
                              </div>
                            </section>
                            <section className="policy-bottom-section">

                              <div className="flex relative">
                                <span className="policy-card-renew block absolute text-center">
                                  {
                                    policy.allowRenewal &&
                                    <Status type="available" shape="label">Renovaci&oacute;n disponible</Status>
                                  }
                                </span>
                                <div className="policy-left-side flex-grow-80">
                                  <span className="block background-image image-contain" style={{ backgroundImage: `url(${Util.ASSET.EMPTY_CAR_PICTURE})` }}></span>
                                </div>
                                <div className="policy-right-side flex column justify-center">
                                  <h4>{policy.vehicle?.brand?.name} / {policy.vehicle?.model?.name}</h4>
                                  <h5>
                                    {
                                      !policy.isExpired() ?
                                        <>
                                          Vence el:
                                        </>
                                        :
                                        <>
                                          Venci&oacute; el:
                                        </>
                                    }
                                    &nbsp;
                                    <span>{policy.getExpirationDateFormat()}</span>
                                  </h5>
                                </div>
                              </div>

                              <div className="flex">
                                {
                                  policy.isActive() || policy.toBegin() ?
                                    <ButtonLink className="flex-grow-100" to={`${POLICY_PATH.MANAGEMENT}/${policy.policyNumber}`} fill="none">Administrar</ButtonLink>
                                    :
                                    <Button className="flex-grow-100" type="button" fill="none" disabled>Administrar</Button>
                                }
                                <ButtonLink className="flex-grow-100" to={`${POLICY_PATH.DETAILS}/${policy.policyNumber}`}>Detalles</ButtonLink>
                              </div>
                            </section>
                          </li>
                        )
                      }
                    )
                  }
                </ul>
                :

                <div className="policy-empty-list height-100 text-center flex column space-evenly text-center">
                  <h2>
                    {
                      props.status === POLICY_PARAMS.STATUS.ACTIVE &&
                      <>
                        Por ahora no tienes <br />
                        <span>P&oacute;lizas activas</span><br />
                        disponibles
                      </>
                    }
                    {
                      props.status === POLICY_PARAMS.STATUS.EXPIRED &&
                      <>
                        No tienes p&oacute;lizas<br />
                        <span>Vencidas</span><br />
                        en tu historial
                      </>
                    }
                    {
                      props.status === POLICY_PARAMS.STATUS.CANCELLED &&
                      <>
                        No tienes p&oacute;lizas<br />
                        <span>Canceladas</span><br />
                        en tu historial
                      </>
                    }

                  </h2>
                  <span className="block" style={{ backgroundImage: `url(${Util.ASSET.EMPTY_POLICY_STATUS_LIST})` }}></span>
                </div>


            }

          </>
      }
    </div>
  );
};

export default PolicyList;
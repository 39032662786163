import { Util } from "src/utils/Util";

export class ClaimProposal {

	/** Class attribute description. */
	private _id: string;
	set id(_id: string) { this._id = _id };
	get id(): string { return this._id };

	/** Class attribute description. */
	private _proposalDate: Date | undefined;
	set proposalDate(_proposalDate: Date | undefined) { this._proposalDate = _proposalDate };
	get proposalDate(): Date | undefined { return this._proposalDate };

	/** Class attribute description. */
	private _carValue: number;
	set carValue(_carValue: number) { this._carValue = _carValue };
	get carValue(): number { return this._carValue };

	/** Class attribute description. */
	private _claimValue: number;
	set claimValue(_claimValue: number) { this._claimValue = _claimValue };
	get claimValue(): number { return this._claimValue };

	/** Class attribute description. */
	private _deductiblePercent:  number;
	set deductiblePercent(_deductiblePercent:  number) { this._deductiblePercent = _deductiblePercent };
	get deductiblePercent():  number { return this._deductiblePercent };

	/** Class attribute description. */
	private _deductibleValue:  number;
	set deductibleValue(_deductibleValue:  number) { this._deductibleValue = _deductibleValue };
	get deductibleValue():  number { return this._deductibleValue };

	/** Class attribute description. */
	private _finalValue: number;
	set finalValue(_finalValue: number) { this._finalValue = _finalValue };
	get finalValue(): number { return this._finalValue };

	/** Class attribute description. */
	private _status: number;
	set status(_status: number) { this._status = _status };
	get status(): number { return this._status };

	constructor(){
		this._id = '';
		this._carValue = -1;
		this._claimValue = -1;
		this._deductiblePercent = -1;
		this._deductibleValue = -1;
		this._finalValue = -1;
		this._status = -1;
	}

  public getFinalValue(): string {
    return Util.TRANSFORM.CURRENCY.MXN(this._finalValue);
  }

  public getClaimValue(): string {
    return Util.TRANSFORM.CURRENCY.MXN(this._claimValue);
  }

  public getDeductibleValue(): string {
    return Util.TRANSFORM.CURRENCY.MXN(this._deductibleValue);
  }

  /**
   * 
   */
  public isPendingNegotiation(): boolean {
    return this.status === Util.STATUS_CODE.CLAIM_PROPOSAL_STATUS.NEUTRAL;
  }

  /**
   * 
   * @returns 
   */
  public isApproved(): boolean {
    return this.status === Util.STATUS_CODE.CLAIM_PROPOSAL_STATUS.APPROVED;
  }

  /**
   * 
   * @returns 
   */
  public isDisapproved(): boolean {
    return this.status === Util.STATUS_CODE.CLAIM_PROPOSAL_STATUS.DISAPPROVED;
  }
}
import { ClaimRegister } from "src/classes/claims/ClaimRegister";
import { ClaimRegisterAdapter } from "../adapters/claim-register.adapter";
import { ClaimRegisterService } from "../services/claim-register.service"
import { Address } from "src/classes/Address";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { ClaimRegisterPicture } from "src/classes/claims/ClaimRegisterPicture";
import { Damage } from "src/classes/claims/Damage";
import { AxiosError } from "axios";
import { ClaimAdjuster } from "src/classes/claims/ClaimAdjuster";
import { Util } from "src/utils/Util";
import { Provider } from "src/classes/claims/Provider";

/**
 *
 */
export namespace ClaimRegisterFeature {

  /**
   * 
   * @param typeId 
   * @returns 
   */
  export const statusTypeFeature = async (typeId: string): Promise<StatusTypeItem[] | undefined> => {
    try {
      const { data } = await ClaimRegisterService.statusTypeService(typeId);
      const types = ClaimRegisterAdapter.statusTypeDataAdapter(data);
      return types;
    } catch (e) {

    }
  }

  /**
   * 
   * @param claimId 
   * @returns 
   */
  export const liabilityListFeature = async (claimId: string): Promise<StatusTypeItem[] | undefined> => {
    try {
      const params = ClaimRegisterAdapter.liabilityRequestParamsAdapter(claimId);
      const { data } = await ClaimRegisterService.liabilityListService(params);
      const types = ClaimRegisterAdapter.statusTypeDataAdapter(data);
      return types;
    } catch (e) {

    }
  }

	/**
   * 
   * @param typeId 
   * @returns 
   */
	export const serviceTypesFeature = async (claimId: string, personTypeId: string, liabilityId?: string, ): Promise<StatusTypeItem[] | undefined> => {
		try {
			const serviceTypeId = Util.STATUS_CODE.CLAIMS_LISTS_TYPES.SERVICE_TYPE;
			const params = ClaimRegisterAdapter.serviceTypesRequestAdapter(claimId, personTypeId, liabilityId)
			const { data } = await ClaimRegisterService.statusTypeService(serviceTypeId, params);
			const types = ClaimRegisterAdapter.statusTypeDataAdapter(data);
			return types;
		} catch (e) {

		}
	}

	/**
   * 
   * @param typeId 
   * @returns 
   */
	export const providersListFeature = async (
		providerType?: string, 
		serviceType?: string, 
		isSubProvider?: boolean, 
		category?: string, 
		claimId?: string,
		state?: string,
		repairProviderType?: number
	): Promise<Provider[] | undefined> => {
		try {
			const params = ClaimRegisterAdapter.providersListRequestAdapter(providerType, serviceType, isSubProvider, category, claimId, state, repairProviderType)
			const { data } = await ClaimRegisterService.providersListService(params);
			const providers = ClaimRegisterAdapter.providersListDataAdapter(data);
			return providers;
		} catch (e) {

		}
	}

	/**
	 * 
	 * @param serviceType 
	 * @returns 
	 */
	export const categoriesListFeature = async(serviceType: string): Promise<StatusTypeItem[] | undefined> => {
		try {
			const { data } = await ClaimRegisterService.categoriesListService(serviceType);
			const dropdowns = ClaimRegisterAdapter.statusTypeDataAdapter(data);
			return dropdowns;
		} catch (e) {
			throw (e);
		}
	}

	/**
	 * 
	 * @param serviceType 
	 * @returns 
	 */
	export const causesListFeature = async(): Promise<StatusTypeItem[] | undefined> => {
		try {
			const { data } = await ClaimRegisterService.causesListService();
			const list = ClaimRegisterAdapter.statusTypeDataAdapter(data);
			return list;
		} catch (e) {
			throw (e);
		}
	}

	/**
	 * 
	 * @param serviceType 
	 * @returns 
	 */
	export const coveragesListFeature = async(claimId: string, incomingService: string, personTypeId: string, liabilityId?: string): Promise<StatusTypeItem[] | undefined> => {
		try {
			const params = ClaimRegisterAdapter.coveragesListRequestAdapter(claimId, incomingService, personTypeId, liabilityId);
			const { data } = await ClaimRegisterService.coveragesTypeService(params);
			const dropdowns = ClaimRegisterAdapter.statusTypeDataAdapter(data);
			return dropdowns;
		} catch (e) {
			throw (e);
		}
	}

  /**
   * 
   * @returns 
   */
  export const countryStatesFeature = async (): Promise<Address[] | undefined> => {
    try {
      const { data } = await ClaimRegisterService.countryStatesService();
      const list = ClaimRegisterAdapter.statesDataAdapter(data);
      return list;
    } catch (e) {

    }
  }

  /**
   * 
   * @param postalCode 
   * @returns 
   */
  export const countryStateByPostalCodeFeature = async (postalCode: string): Promise<Address | undefined> => {
    try {
      const { data } = await ClaimRegisterService.countryStateByPostalCodeService(postalCode);
      const address = ClaimRegisterAdapter.statesByPostalCodeDataAdapter(data);
      return address;
    } catch (e) {

    }
  }

  /**
   * 
   * @param id 
   * @param key 
   * @returns 
   */
  export const claimPersonInfoFeature = async (id: string, key: string): Promise<ClaimRegister | undefined> => {
    try {
      const request = ClaimRegisterAdapter.claimFormRequestAdapter(id, key);
      const { data } = await ClaimRegisterService.claimPersonInfoService(request);
      const registerInfo = ClaimRegisterAdapter.claimPersonInfoDataAdapter(data);
      return registerInfo;
    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        if (axiosError.response.status === 400 || axiosError.response.status === 409) {
          error.message = `El formulario ha concluído.`;
        } else {
          error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
        }
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }

      throw error;
    }
  }

  /**
   * 
   * @param id 
   * @param key 
   * @returns 
   */
  export const claimAdjusterInfoFeature = async (id: string, key: string): Promise<ClaimAdjuster | undefined> => {
    try {
      const request = ClaimRegisterAdapter.claimFormRequestAdapter(id, key);
      const { data } = await ClaimRegisterService.claimAdjusterInfoService(request);
      const adjusterInfo = ClaimRegisterAdapter.claimAdjusterInfoDataAdapter(data);
      return adjusterInfo;
    } catch (e) {
      console.error(e);
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        if (axiosError.response.status === 400 || axiosError.response.status === 409) {
          error.message = `El formulario ha concluído.`;
        } else {
          error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
        }
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }

      throw error;
    }
  }

  /**
   * 
   * @param key 
   * @param info 
   * @param completed 
   * @param pictures 
   * @param type 
   * @returns 
   */
  export const claimPersonInfoUpdateFeature = async (key: string, claimPerson: ClaimRegister, completed?: boolean, pictures?: string[], type?: TClaimPictureName): Promise<ClaimRegister | undefined> => {
    try {
      let registerInfo: ClaimRegister;
      if (pictures && type) {
        let newPictures: ClaimRegisterPicture[] | undefined = undefined;
        const picturesRequest = ClaimRegisterAdapter.claimPictureRequestAdapter(claimPerson.formId, pictures, type);

        if (picturesRequest) {
          const picturesResponse = await ClaimRegisterService.claimPictureService(picturesRequest);
          newPictures = ClaimRegisterAdapter.claimPicturesDataAdapter(picturesResponse.data);
        }

        const request = ClaimRegisterAdapter.claimPersonInfoUpdateRequestAdapter(key, claimPerson, completed, newPictures, type);
        const { data } = await ClaimRegisterService.claimPersonInfoUpdateService(request);
        registerInfo = ClaimRegisterAdapter.claimPersonInfoDataAdapter(data);
      } else {
        const request = ClaimRegisterAdapter.claimPersonInfoUpdateRequestAdapter(key, claimPerson, completed);
        const { data } = await ClaimRegisterService.claimPersonInfoUpdateService(request);
        registerInfo = ClaimRegisterAdapter.claimPersonInfoDataAdapter(data);
      }
      return registerInfo;
    } catch (e) {
      console.error(e);
      const error: Error = {
        message: 'Ha ocurrido un error',
        name: ''
      }
      throw error;
    }
  }

  /**
   * 
   * @param key 
   * @param claimAdjuster 
   * @param completed 
   * @param pictures 
   * @param type 
   */
  export const claimAdjusterInfoUpdateFeature = async (key: string, claimAdjuster: ClaimAdjuster, completed?: boolean, pictures?: string[], type?: TClaimPictureName, personIndex?: number): Promise<ClaimAdjuster | undefined> => {
    try {
      let adjusterInfo: ClaimAdjuster;

      if (pictures && type) {
        let newPictures: ClaimRegisterPicture[] | undefined = undefined;
        const picturesRequest = ClaimRegisterAdapter.claimPictureRequestAdapter(claimAdjuster.formId, pictures, type);

        if (picturesRequest) {
          const picturesResponse = await ClaimRegisterService.claimPictureService(picturesRequest);
          newPictures = ClaimRegisterAdapter.claimPicturesDataAdapter(picturesResponse.data);
        }

        const request = ClaimRegisterAdapter.claimAdjusterInfoUpdateRequestAdapter(key, claimAdjuster, completed, newPictures, type, personIndex);
        const { data } = await ClaimRegisterService.claimAdjusterInfoUpdateService(request);
        adjusterInfo = ClaimRegisterAdapter.claimAdjusterInfoDataAdapter(data);
      } else {
        const request = ClaimRegisterAdapter.claimAdjusterInfoUpdateRequestAdapter(key, claimAdjuster, completed, undefined, undefined, personIndex);
        const { data } = await ClaimRegisterService.claimAdjusterInfoUpdateService(request);
        adjusterInfo = ClaimRegisterAdapter.claimAdjusterInfoDataAdapter(data);
      }

      if (completed) {
        adjusterInfo.completed = completed;
      }

      return adjusterInfo;
    } catch (e) {
      console.error(e);
      const error: Error = {
        message: 'Ha ocurrido un error',
        name: ''
      }
      throw error;
    }
  }

  /**
   * 
   * @param key 
   * @param info 
   * @param damage 
   * @returns 
   */
  export const claimPersonDamageUpdateFeature = async (key: string, info: ClaimRegister, damage: Damage, pictures?: string[]): Promise<ClaimRegister | undefined> => {
    try {
      let registerInfo: ClaimRegister;
      if (pictures) {
        const picturesRequest = ClaimRegisterAdapter.claimPictureRequestAdapter(info.formId, pictures, 'damages');
        let newPictures: ClaimRegisterPicture[] | undefined = undefined;
        if (picturesRequest) {
          const picturesResponse = await ClaimRegisterService.claimPictureService(picturesRequest);
          newPictures = ClaimRegisterAdapter.claimPicturesDataAdapter(picturesResponse.data);
        }
        
        if (newPictures) {
          damage.pictures = damage.pictures.concat(newPictures);
        }
        const request = ClaimRegisterAdapter.claimPersonInfoUpdateRequestAdapter(key, info, false);
        const { data } = await ClaimRegisterService.claimPersonInfoUpdateService(request);
        registerInfo = ClaimRegisterAdapter.claimPersonInfoDataAdapter(data);
      } else {
        const request = ClaimRegisterAdapter.claimPersonInfoUpdateRequestAdapter(key, info, false);
        const { data } = await ClaimRegisterService.claimPersonInfoUpdateService(request);
        registerInfo = ClaimRegisterAdapter.claimPersonInfoDataAdapter(data);
      }
      return registerInfo;
    } catch (e) {
      throw e;
    }
  }

  /**
   * 
   * @param key 
   * @param info 
   * @param damage 
   * @param pictures 
   * @returns 
   */
  export const claimServicePicturesFeature = async (info: ClaimRegister, pictures: string[]): Promise<ClaimRegisterPicture[] | undefined> => {
    try {
      const picturesRequest = ClaimRegisterAdapter.claimPictureRequestAdapter(info.formId, pictures, 'services');
      let newPictures: ClaimRegisterPicture[] | undefined = undefined;
      if (picturesRequest) {
        const picturesResponse = await ClaimRegisterService.claimPictureService(picturesRequest);
        newPictures = ClaimRegisterAdapter.claimPicturesDataAdapter(picturesResponse.data);
      }
      
      return newPictures;
    } catch (e) {
      throw e;
    }
  }

	/**
   * 
   * @param key 
   * @param info 
   * @param damage 
   * @returns 
   */
		export const claimAdjusterDamageUpdateFeature = async (key: string, claimAdjuster: ClaimAdjuster, damage: Damage, pictures?: string[]): Promise<ClaimAdjuster | undefined> => {
			try {
				let adjusterInfo: ClaimAdjuster;

				if (pictures) {
					const picturesRequest = ClaimRegisterAdapter.claimPictureRequestAdapter(claimAdjuster.formId, pictures, 'damages');
					let newPictures: ClaimRegisterPicture[] | undefined = undefined;
					if (picturesRequest) {
						const picturesResponse = await ClaimRegisterService.claimPictureService(picturesRequest);
						newPictures = ClaimRegisterAdapter.claimPicturesDataAdapter(picturesResponse.data);
					}
					
					if (newPictures) {
						damage.pictures = damage.pictures.concat(newPictures);
					}
					const request = ClaimRegisterAdapter.claimAdjusterInfoUpdateRequestAdapter(key, claimAdjuster, false);
					const { data } = await ClaimRegisterService.claimAdjusterInfoUpdateService(request);
					adjusterInfo = ClaimRegisterAdapter.claimAdjusterInfoDataAdapter(data);
				} else {
					const request = ClaimRegisterAdapter.claimAdjusterInfoUpdateRequestAdapter(key, claimAdjuster, false);
					const { data } = await ClaimRegisterService.claimAdjusterInfoUpdateService(request);
					adjusterInfo = ClaimRegisterAdapter.claimAdjusterInfoDataAdapter(data);
				}
				return adjusterInfo;
			} catch (e) {
				throw e;
			}
		}
}
import { VehicleBrand } from './VehicleBrand';
import { VehicleModel } from './VehicleModel';
import { VehicleVersion } from './VehicleVersion';
import { VehicleYear } from './VehicleYear';

/**
 * 
 */
export class Vehicle {

  /**
   *
   */
  private _id: string | undefined;
  set id(_id: string | undefined) { this._id = _id };
  get id(): string | undefined { return this._id };

  private _vin: string;
  get vin(): string { return this._vin; }
  set vin(_vin: string) { this._vin = _vin; }

  private _plate: string;
  get plate(): string { return this._plate; }
  set plate(_plate: string) { this._plate = _plate; }

  private _brand: VehicleBrand | undefined;
  get brand(): VehicleBrand | undefined { return this._brand; }
  set brand(_brand: VehicleBrand | undefined) { this._brand = _brand; }

  private _model: VehicleModel | undefined;
  get model(): VehicleModel | undefined { return this._model; }
  set model(_model: VehicleModel | undefined) { this._model = _model; }

  private _year: VehicleYear | undefined;
  get year(): VehicleYear | undefined { return this._year; }
  set year(_year: VehicleYear | undefined) { this._year = _year; }

  private _version: VehicleVersion | undefined;
  get version(): VehicleVersion | undefined { return this._version; }
  set version(_version: VehicleVersion | undefined) { this._version = _version; }

  private _color: string;
  get color(): string { return this._color; }
  set color(_color: string) { this._color = _color; }

	private _comercialValue: number;
	set comercialValue(_comercialValue: number) { this._comercialValue = _comercialValue };
	get comercialValue(): number { return this._comercialValue };

  constructor() {
    this._vin = '';
    this._plate = '';
    this._brand = undefined;
    this._model = undefined;
    this._year = undefined;
    this._version = undefined;
    this._color = 'no_color_set';
		this._comercialValue = -1;
  }
}
import { RootState } from "src/store/store";

/**
 *
 */
export namespace PolicyCreationSelector {

  //TODO Refactorizar selectors
  export const currentDriver = (state: RootState) => state.policyCreation?.driver!;

  export const currentVehicle = (state: RootState) => state.policyCreation?.vehicle!;

  export const currentPlan = (state: RootState) => state.policyCreation?.plan!;

  export const planReview = (state: RootState) => state.policyCreation?.review!;
  
  export const coverages = (state: RootState) => state.policyCreation?.coverages!;

  export const currentCoupon = (state: RootState) => state.policyCreation?.coupon!;

	export const currentPolicy = (state: RootState) => state.policyCreation?.policy!;
	
	export const vehiclePostalCode = (state: RootState) => state.policyCreation?.vehiclePostalCode;
}
import { useNavigate } from "react-router";
import { POLICY_CREATION_PATH } from "src/routes/paths/policy-creation.paths";
import { Util } from "src/utils/Util";

/**
 *
 */
 export namespace PolicyOwnerFacade {

  /**
   * 
   */
  export const usePolicyOwner = () => {

    const navigate = useNavigate();

    /**
     * 
     * @param value 
     */
    const isCurrentUserOwner = (value: boolean, isFirstPolicy?: boolean): void => {
      if (value) {
        localStorage.setItem(Util.KEY.POLICY_CREATION_OWNER, 'true');
      } else {
        localStorage.removeItem(Util.KEY.POLICY_CREATION_OWNER);
      }
      localStorage.removeItem(Util.KEY.POLICY_NEW_DRIVER);

			if(!isFirstPolicy)
      	localStorage.removeItem(Util.KEY.POLICY_NEW_VEHICLE);

			if (value) {
				navigate(POLICY_CREATION_PATH.PLAN_REVIEW);
			} else {
				navigate(POLICY_CREATION_PATH.PERSON_DATA);
			}
    }

    return isCurrentUserOwner;
  }

}
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Policy } from "src/classes/Policy";
import { AlertFactory } from "src/factory/alert.factory";
import { EventFactory } from "src/factory/event.factory";
import { DASHBOARD_PATH } from "src/routes/paths/dashboard.paths";
import { POLICY_RENEWAL_PATH } from "src/routes/paths/policy-renewal.paths";
import { POLICY_PARAMS, POLICY_PATH } from "src/routes/paths/policy.paths";
import { PolicyAction } from "../actions/policy.action";
import { PolicySaga } from "../sagas/policy.saga";
import { PolicySelector } from "../selectors/policy.selector";
import { PolicySlice } from "../slices/policy.slice";

/**
 *
 */
export namespace PolicyFacade {

  /**
   * 
   */
  export const usePolicy = () => {
    useInjectReducer({ key: 'policy', reducer: PolicySlice.reducer });
    useInjectSaga({ key: 'policy', saga: PolicySaga.policySaga });
    const [currentPolicyStatus, setCurrentPolicyStatus] = useState<string>('');
    const selectedPolicy = useSelector(PolicySelector.selectedPolicy);
    const { policyNumber } = useParams<string>();
    const dispatch = useDispatch();
    const location = useLocation();

    /**
     * 
     */
    useEffect(() => {
      dispatch(PolicyAction.policyList());
    }, [dispatch])

    /**
     * 
     */
    useEffect(
      () => {
        if (location.pathname.includes(POLICY_PATH.STATUS))
          dispatch(PolicyAction.clearManagement());
      }, [dispatch, location]
    )

    /**
     * 
     */
    useEffect(
      () => {
        return () => {
          dispatch(PolicyAction.clear());
        };
      }, [dispatch]
    );


    const selectStatus = useCallback(
      () => {
        if (selectedPolicy) {
          if (selectedPolicy.isActive() || selectedPolicy.toBegin()) {
            setCurrentPolicyStatus(`/${POLICY_PARAMS.STATUS.ACTIVE}`);
          } else if (selectedPolicy.isExpired()) {
            setCurrentPolicyStatus(`/${POLICY_PARAMS.STATUS.EXPIRED}`);
          } else if (selectedPolicy.isCanceled()) {
            setCurrentPolicyStatus(`/${POLICY_PARAMS.STATUS.CANCELLED}`);
          }
        }
      }, [selectedPolicy]
    )

    useEffect(
      () => {
        if (selectedPolicy) {
          selectStatus();
        }
      }, [selectedPolicy, selectStatus]
    )

		useEffect(
			() => {
				if (location.pathname.includes(POLICY_PATH.OWNER)){
					const event = EventFactory.EVENT_NAMES.POLICY_OWNER;
					EventFactory.event.track(event);
				}
			}, [location.pathname]
		)

    return { policyNumber, currentPolicyStatus }
  }

  /**
   * 
   */
  export const usePolicyList = () => {
    const policyList = useSelector(PolicySelector.policyList);
    const [policyFilteredList, setPolicyFilteredList] = useState<Policy[] | undefined>(undefined);
    const [missingPolicyPictures, setMissingPolicyPictures] = useState<Policy | undefined>(undefined);
    const [failedPolicyPayment, setFailedPolicyPayment] = useState<Policy | undefined>(undefined);
    const { status } = useParams<string>();

    /**
     * 
     */
    useEffect(
      () => {
        if (policyList) {
          const policy = policyList.find(p => p.hasPictures !== undefined && p.hasPictures === false);
          if (policy) {
            setMissingPolicyPictures(policy);
          }
        }
      }, [policyList]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (policyList) {
          const policy = policyList.find(p => p.isPaymentFailed());
          if (policy) {
            setFailedPolicyPayment(policy);
          }
        }
      }, [policyList]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (status && policyList) {
          if (status === POLICY_PARAMS.STATUS.ACTIVE) {
            setPolicyFilteredList(policyList.filter(p => p.isActive() || p.toBegin()));
          } else if (status === POLICY_PARAMS.STATUS.EXPIRED) {
            setPolicyFilteredList(policyList.filter(p => p.isExpired()));
          } else if (status === POLICY_PARAMS.STATUS.CANCELLED) {
            setPolicyFilteredList(policyList.filter(p => p.isCanceled()));
          }

        }
      }, [policyList, status]
    )

    return { policyFilteredList, status, missingPolicyPictures, failedPolicyPayment }
  }

  /**
   * 
   */
  export const usePolicyManagement = () => {
    const policyList = useSelector(PolicySelector.policyList);
    const endorsements = useSelector(PolicySelector.endorsements);
    const selectedPolicy = useSelector(PolicySelector.selectedPolicy);
    const { policyNumber } = useParams<string>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * 
     */
    const policyRenewal = (): void => {
      if (selectedPolicy?.allowRenewal) {
        navigate(`${POLICY_RENEWAL_PATH.POLICY_RENEWAL}/${selectedPolicy.policyNumber}`);
      }
    }

    /**
     * 
     */
    const invalidPolicyNumber = useCallback(
      () => {
        navigate(DASHBOARD_PATH.DASHBOARD, { replace: true });
        AlertFactory.errorAlert('Número de póliza inválido.');
      }, [navigate]
    );

    /**
     * 
     */
    const findPolicy = useCallback(
      async () => {
        if (policyList && !selectedPolicy) {
          const currentPolicy = policyList.find(p => p.policyNumber === policyNumber);
          if (!currentPolicy) {
            invalidPolicyNumber();
          } else {
            dispatch(PolicyAction.selectPolicy(currentPolicy.policyNumber));
          }
        }
      }, [policyList, policyNumber, invalidPolicyNumber, dispatch, selectedPolicy]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (selectedPolicy) {
          if (!endorsements && (selectedPolicy.isActive() || selectedPolicy.toBegin())) {
            dispatch(PolicyAction.endorsements(selectedPolicy.id));
          }
        }
      }, [selectedPolicy, dispatch, endorsements]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (!policyNumber) {
          invalidPolicyNumber();
        } else if (policyList && policyList.length > 0) {
          findPolicy();
        }
      }, [policyList, policyNumber, invalidPolicyNumber, findPolicy]
    );

    return { selectedPolicy, policyRenewal, endorsements }
  }

  
}
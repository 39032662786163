/**
 * 
 */
 export class VehicleYear {

  private _brand: number;
  get brand(): number { return this._brand; }
  set brand(_brand: number) { this._brand = _brand; }

  private _model: number;
  get model(): number { return this._model; }
  set model(_model: number) { this._model = _model; }

  private _value: number;
  get value(): number { return this._value; }
  set value(_value: number) { this._value = _value; }

  constructor(_brand?: number, _model?: number, _value?: number) {
    this._brand = _brand? _brand : 0;
    this._model = _model? _model : 0;
    this._value = _value? _value : 0;
  }

  /**
   * 
   * @returns 
   */
  public getYearDescription(): string {
    let description: string = '';
    if (this._value > 0) {
      description = this._value.toString();
    } else {
      description = 'N/A'
    }

    return description;
  }
}
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Util } from "src/utils/Util";
import { IUtmParams } from "../interfaces/IUtmParams";

export namespace UtmsFacade {

  /** */
  export const useUtmsFacade = () => {
		/** */
		const [utms, setUtms] = useState<IUtmParams>();
		/** UTM's */
		const { search } = useLocation();
		const searchParams = useMemo(() => new URLSearchParams(search), [search]);

		/** */
		useEffect(() => {
			let _utms: IUtmParams = { 
				utm_source: searchParams.get(Util.KEY.REGISTER_UTMS.UTM_SOURCE)!,
				utm_campaign: searchParams.get(Util.KEY.REGISTER_UTMS.UTM_CAMPAIGN)!,
				utm_content: searchParams.get(Util.KEY.REGISTER_UTMS.UTM_CONTENT)!,
				utm_term: searchParams.get(Util.KEY.REGISTER_UTMS.UTM_TERM)!,
				utm_medium: searchParams.get(Util.KEY.REGISTER_UTMS.UTM_MEDIUM)!
			};
			setUtms(_utms);
		}, [searchParams]);

    return { utms };
  }
}
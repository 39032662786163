import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IPersonManagementEconomicActivityData } from "../interfaces/IPersonManagementEconomicActivityData";
import { IPersonManagementOwnBusinessData } from "../interfaces/IPersonManagementOwnBusinessData";
import { IPersonManagementValidationData } from "../interfaces/IPersonManagementValidationData";

/**
 *
 */
export namespace PersonManagementService {

  /**
   * 
   * @returns 
   */
  export const validateRfcService = (rfc: string): AxiosPromise<IPersonManagementValidationData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VERIFY_PERSON_RFC(rfc));
  }

  /**
   * 
   * @returns 
   */
  export const validateEmailService = (email: string): AxiosPromise<IPersonManagementValidationData> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VERIFY_EMAIL(email));
  }

	/**
	 * 
	 * @returns
	 */
	export const getEconomicActivities = (): AxiosPromise<IPersonManagementEconomicActivityData> => {
		return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.GET_ECONOMIC_ACTIVITIES);
	}

	/**
	 *
	 * @returns
	 */
	export const getOwnBusinessActivities = (): AxiosPromise<IPersonManagementOwnBusinessData> => {
		return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.GET_OWN_BUSINESS_ACTIVITIES);
	}
}
import { Support } from "src/components";
// import { AGREED_PAYMENTS_LIST_PATH } from "src/routes/paths/agreed-payments-list.paths";
// import { CLAIM_PAYMENTS_LIST_PATH } from "src/routes/paths/claim-payments-list.paths";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { PROFILE_PATH } from "src/routes/paths/profile.paths";
import { REFERRALS_PATH } from "src/routes/paths/referrals.paths";
import MenuSection from "../../molecules/MenuSection/MenuSection";

import './Menu.scss';

/**
 *
 */
interface IMenu {
  showMenu?: boolean;

  onHideMenu?: () => void;
}

/**
 *
 */
const navElements = (props?: IMenu): JSX.Element => (
  <>
    <MenuSection
      onNavigate={props && props.onHideMenu ? props.onHideMenu : undefined}
      title="Pólizas"
      items={
        [
          { name: 'Tus pólizas', path: POLICY_PATH.POLICY },
        ]
      }
    />
    {/* <MenuSection
      onNavigate={props && props.onHideMenu ? props.onHideMenu : undefined}
      title="Siniestros"
      items={
        [
          { name: 'Pago de daños', path: CLAIM_PAYMENTS_LIST_PATH.CLAIM_PAYMENTS_LIST },
          { name: 'Pago pactado', path: AGREED_PAYMENTS_LIST_PATH.AGREED_PAYMENTS_LIST },
        ]
      }
    /> */}
    <MenuSection
      onNavigate={props && props.onHideMenu ? props.onHideMenu : undefined}
      title="Referidos"
      items={
        [
          { name: 'Referidos', path: REFERRALS_PATH.REWARDS },
          { name: 'Historial de recompensas', path: REFERRALS_PATH.REWARDS_HISTORY }
        ]
      }
    />
    <MenuSection
      onNavigate={props && props.onHideMenu ? props.onHideMenu : undefined}
      title="Perfil"
      items={
        [
          { name: 'Datos personales', path: PROFILE_PATH.PROFILE_INFO },
          { name: 'Seguridad', path: PROFILE_PATH.SECURITY }
        ]
      }
    />
  </>


);

/**
 *
 */
const Menu = (props: IMenu): JSX.Element => {
  return (
    <div id="menu-wrapper" menu-visible={props.showMenu ? "" : null}>
      <aside className="flex column space-between">
        <nav>
          {navElements(props)}
        </nav>

        <footer className="relative">
          <Support />
        </footer>
      </aside>

      {props.showMenu && (
        <div className="menu-content-background fixed"
          onClick={props.onHideMenu}
        ></div>
      )}

      <aside className="mobile flex column fixed height-100 space-between border-box">
        <nav>
          {navElements(props)}
        </nav>
        <footer className="relative">
          <Support />
        </footer>
      </aside>
    </div>
  );
};

export default Menu;
import { Controller, UseFormReturn } from "react-hook-form";
import { TaxRegime } from "src/classes/invoice/TaxRegime";
import { RegimeUse } from "src/classes/invoice/RegimeUse";
import { Button, Checkbox, Dropdown, FormError, InputLabel, Radio, Text } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { BillFacade } from "src/modules/bill/controller/facades/bill.facade";
import { IBillPersonForm } from "src/modules/bill/controller/interfaces/IBillPersonForm";
import { Util } from "src/utils/Util";
import './BillForm.scss';

/**
 *
 */
interface IBillForm {

  totalAmount: number;

  saveData?: boolean;

  onClose: () => void;

  onCreate: (form: UseFormReturn<IBillPersonForm, object>, regime: TaxRegime, use: RegimeUse) => void;

  taxRegimes: TaxRegime[];

  creating: boolean;
}

/**
 *
 */
const BillForm = (props: IBillForm): JSX.Element => {
  const { onSelectType, taxType, form, currentRegime, onSelectRegime, currentUse, onSelectUse } = BillFacade.useBillForm(props.taxRegimes);
  return (
    <div className="billForm">

      <section className="billForm__section">
        <button className="billForm__section__close-button" type="button" onClick={() => props.onClose()}>
          {IconFactory.closeIcon()}
        </button>
        <Text type="subtitle" bold>Completa los datos</Text>
        <Text type="paragraph">
          Completa los datos y confirma para generar la facturaci&oacute;n de la p&oacute;liza.
        </Text>

        <form className="billForm__section__form">

          <div className="billForm__section__form__field billForm__section__form__field--radio">
            <Text type="label" bold block>Tipo de persona</Text>
            <Radio name="person-type" defaultChecked={taxType === BillFacade.TAX_TYPE.NATURAL}
              onChange={() => onSelectType(BillFacade.TAX_TYPE.NATURAL)}
            >
              Persona f&iacute;sica
            </Radio>
            <Radio name="person-type"
              onChange={() => onSelectType(BillFacade.TAX_TYPE.MORAL)}
            >
              Persona moral
            </Radio>

          </div>

          <div className="billForm__section__form__field">
            <Controller
              control={form.control}
              name="name"
              rules={{
                required: true,
                pattern: Util.PATTERN.NAMES,
              }}
              render={({ field: { onChange } }) => (
                <InputLabel
                  placeholder="Ingresa tu nombre completo"
                  errors={!!form.formState.errors.name}
                  maxLength={200}
                  onChange={(e) => onChange(e.target.value)}
                >
                  Nombre completo
                </InputLabel>
              )}
            />
            {
              form.formState.errors.name?.type === BillFacade.BILL_ERRORS[0].key &&
              <FormError schema={BillFacade.BILL_ERRORS} type={form.formState.errors?.name?.type} />
            }
          </div>

          <div className="billForm__section__form__field">
            <Controller
              control={form.control}
              name="rfc"
              rules={{
                required: true,
                pattern: Util.PATTERN.RFC,
              }}
              render={({ field: { onChange } }) => (
                <InputLabel
                  placeholder="Ingresa tu RFC"
                  className="billForm__section__form__field__input billForm__section__form__field__input--uppercase"
                  errors={!!form.formState.errors.rfc}
                  maxLength={13}
                  onChange={(e) => onChange(e.target.value)}
                >
                  RFC
                </InputLabel>
              )}
            />
            {
              form.formState.errors.rfc?.type === BillFacade.BILL_ERRORS[1].key &&
              <FormError schema={BillFacade.BILL_ERRORS} type={form.formState.errors?.rfc?.type} />
            }
          </div>

          <div className="billForm__section__form__field billForm__section__form__field--space">
            <Controller
              control={form.control}
              name="postalCode"
              rules={{
                required: true,
                minLength: 5
              }}
              render={({ field: { onChange } }) => (
                <InputLabel number
                  placeholder="Ingresa tu código postal"
                  errors={!!form.formState.errors.postalCode}
                  onChange={(e) => onChange(e.target.value)}
                >
                  C&oacute;digo postal
                </InputLabel>
              )}
            />
            {
              form.formState.errors.postalCode?.type === BillFacade.BILL_ERRORS[3].key &&
              <FormError schema={BillFacade.BILL_ERRORS} type={form.formState.errors?.postalCode?.type} />
            }
          </div>

          {
            taxType === BillFacade.TAX_TYPE.NATURAL &&
            <div className="billForm__section__form__field">
              <Dropdown
                items={props.taxRegimes.filter(t => t.isIndependant === true).map(tr => { return { id: tr.id, value: tr.description } })}
                placeholder="Selecciona el régimen fiscal"
                onChange={(item) => onSelectRegime(item.id as number)}
              >
                R&eacute;gimen fiscal
              </Dropdown>
              <FormError schema={BillFacade.BILL_ERRORS} type={form.formState.errors?.regime?.type} />
            </div>
          }

          {
            taxType === BillFacade.TAX_TYPE.MORAL &&
            <div className="billForm__section__form__field">
              <Dropdown
                items={props.taxRegimes.filter(t => t.isMoral === true).map(tr => { return { id: tr.id, value: tr.description } })}
                placeholder="Selecciona el régimen fiscal"
                onChange={(item) => onSelectRegime(item.id as number)}
              >
                R&eacute;gimen fiscal
              </Dropdown>
              <FormError schema={BillFacade.BILL_ERRORS} type={form.formState.errors?.regime?.type} />
            </div>
          }

          <div className="billForm__section__form__field billForm__section__form__field--space">
            <Dropdown
              items={!currentRegime ? [] : currentRegime.uses.map(u => { return { id: u.id, value: u.name } })}
              placeholder="Selecciona el uso CFDI"
              onChange={(item) => { onSelectUse(item.id as string) }}
            >
              Uso CFDI
            </Dropdown>
          </div>

          <div className="billForm__section__form__field">
            <Controller
              control={form.control}
              name="email"
              rules={{
                required: true,
                pattern: Util.PATTERN.EMAIL
              }}
              render={({ field: { onChange } }) => (
                <InputLabel
                  placeholder="Ingresa tu correo electrónico"
                  errors={!!form.formState.errors.email}
                  onChange={(e) => onChange(e.target.value)}
                >
                  C&oacute;rreo Electr&oacute;nico
                </InputLabel>
              )}
            />
          </div>
          {
            !props.saveData &&
            <div className="billForm__section__form__field">
              <Controller
                control={form.control}
                name="saveData"
                rules={{
                  required: false
                }}
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={(e) => onChange(e.target.checked)}>
                    <b>Guardar datos</b> para compras posteriores
                  </Checkbox>
                )}
              />
            </div>
          }
        </form>

      </section>

      <section className="billForm__section billForm__section--action">
        <Button
          disabled={!form.formState.isValid || !currentRegime || !currentUse || props.creating}
          onClick={() => props.onCreate(form, currentRegime!, currentUse!)}
          loading={props.creating}
        >
          Facturar compra por {Util.TRANSFORM.CURRENCY.MXN(props.totalAmount)}
        </Button>
      </section>
    </div>
  );
};

export default BillForm;
import { useForm } from "react-hook-form";
import { IAcceptPaymentProposalForm } from "../interfaces/IAcceptPaymentProposalForm";

export namespace ClaimProposalFormFacade {

  /**
   * 
   * @returns 
   */
  export const useClaimProposalForm = () => {
    const { register, formState: { isValid }, getValues } = useForm<IAcceptPaymentProposalForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    return { register, formState: { isValid }, getValues }
  }

}
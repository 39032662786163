import './SkeletonLoader.scss';

/**
 *
 */
interface ISkeletonLoader {

  /**
   * Skeleton type. Values: square, radial, text. Default value text.
   */
  type?: 'square' | 'radial' | 'text';
}

/**
 *
 */
export const SkeletonLoader = (props: ISkeletonLoader): JSX.Element => {
  return (
    <div className="skeleton-loader" skeleton-type={props.type === 'square' || props.type === 'radial' ? props.type : 'text'}></div>
  );
};
import Camera from 'src/libs/Camera/ui/template/Camera';
import { Button, Modal, Spinner } from "src/components";
import { PropsWithChildren } from 'react';
import { IconFactory } from 'src/factory/icon.factory';
import { InsurancePictureItemFacade } from "src/modules/insurance-vehicle/controller/facades/insurance-picture-item.facade";

import './InsurancePictureItem.scss';

/**
 *
 */
interface IInsurancePictureItem {

  token: string;

  backgroundImage: string;

  onSelect: (file: File | undefined) => void;

  onIsValidating: (value: boolean) => void;

  onError: (name: string) => void;
}

/**
 *
 */
const InsurancePictureItem = (props: PropsWithChildren<IInsurancePictureItem>): JSX.Element => {
  const { onSelect, onIsValidating, onError } = props;
  const { camera, options, picture, onSelectPicture, isLoadingPicture } = InsurancePictureItemFacade.usePictureItem(props.token, onSelect, onIsValidating, onError);

  return (
    <>
      <div className="insurance-picture-item flex column relative">

        <section className="vehicle-picture-name">
          <h3 className="text-center">{props.children}</h3>
        </section>
        <section className="flex-grow-100 height-100 relative">
          {
            isLoadingPicture ?
              <Spinner color="main" />
              :
              <>
                {
                  picture === '' ?
                    <span className="background-image block width-100 height-100" style={{ backgroundImage: `url(${props.backgroundImage})` }}></span>
                    :
                    <img className="width-100 height-100" src={picture} alt="Insurance" />
                }
              </>
          }

        </section>

        {
          !isLoadingPicture &&
          <button type="button" className="absolute"
            onClick={(e) => { e.preventDefault(); options.openOptions() }}>
            {IconFactory.cameraIcon()}
          </button>
        }

        {
          camera.showCamera &&
          <Camera onSelect={(picture) => onSelectPicture(picture)} onCancel={camera.onCancelCamera}></Camera>
        }

      </div>

      <Modal show={options.showOptions} position="bottom" backgroundDismiss onClose={options.onCancelOptions}>
        <div className="vehicle-picture-options flex column">
          <Button type="button" icon={IconFactory.cameraIcon()} onClick={(e) => { e.preventDefault(); camera.openCamera() }}>
            Tomar fotos
          </Button>
          <Button type="button" fill="outline" icon={IconFactory.galleryIcon()} onClick={(e) => { e.preventDefault(); options.openGallery() }}>
            Subir desde galer&iacute;a
          </Button>
        </div>
      </Modal>
    </>

  );
};

export default InsurancePictureItem;
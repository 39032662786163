import { Button, Modal } from "src/components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconFactory } from "src/factory/icon.factory";
import { SIGN_OUT_PATH } from "src/routes/paths/sign-out.paths";

import './Idle.scss';

/**
 *
 */
interface IIdle {

  show: boolean;

  onHandleAction: () => void;

}

/**
 *
 */
const Idle = (props: IIdle): JSX.Element => {
  const [remainingTime, setRemainingTime] = useState<number>(59);
  const navigate = useNavigate()

  const resetTimer = (): void => {
    setRemainingTime(59);
  }

  useEffect(() => {
    let mounted: boolean = true;
    if (props.show) {
      setTimeout(
        () => {
          if (mounted) {
            if (remainingTime > 0) {
              setRemainingTime(remainingTime - 1);
            } else {
              resetTimer();
              navigate(SIGN_OUT_PATH.SIGN_OUT, { replace: true });
            }
          }
  
        }, 1000
      )
    }
    return () => {
      mounted = false;
    }
  }, [remainingTime, navigate, props.show]
  )


  return (
    <Modal position="center" show={props.show} backgroundDismiss={false}>
      <div className="iddle-wrapper text-center">
        {IconFactory.sandClockIcon()}
        <h2>Hola ¿sigues ahí?</h2>
        <p>00:{remainingTime >= 10 ? remainingTime : (`0${remainingTime >= 0 ? remainingTime : 0}`)}</p>
        <p className="description">
          Hemos detectado inactividad en tu sesi&oacute;n, por seguridad a tus datos personales, cerraremos el acceso.
          Para volver a ingresar, inicia sesi&oacute;n nuevamente.
        </p>
        <Button type="button" onClick={(e) => { e.preventDefault(); resetTimer(); props.onHandleAction() }}>Seguir en sesi&oacute;n</Button>
      </div>

    </Modal>

  );
};

export default Idle;
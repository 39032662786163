import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IQuoterValidationData } from "../interfaces/IQuoterValidationData";
import { IRegisterRequest } from "../interfaces/IRegisterRequest";

/**
 *
 */
export namespace QuoterService {

	/**
	 * 
	 */
	export const registerUser = (_body: IRegisterRequest): AxiosPromise<any> => {
		return EnvironmentFactory.Http.PublicHttp().post(Util.API_PATH.QUOTER_REGISTER_USER, _body);
	};

	/**
   * 
   * @returns 
   */
	export const validateEmailService = (email: string): AxiosPromise<IQuoterValidationData> => {
		return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.VERIFY_EMAIL(email));
	}
}
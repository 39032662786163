import classNames from "classnames";
import { PropsWithChildren } from "react";

import './Status.scss';

/**
 *
 */
interface IStatus {

  type: 'active' | 'pending' | 'toBegin' | 'deactive' | 'expired' | 'available';

  shape: 'label' | 'background'

  className?: string;
}

/**
 *
 */
export const Status = (props: PropsWithChildren<IStatus>): JSX.Element => {
  const wraperClassNames = classNames({
    'status-component-wrapper': true
  })
  return (
    <div className={`${wraperClassNames} ${props.className? props.className : ''}`}>
      {
        props.shape === 'label' &&
        <>
          <span className="status-item status-label inline-block vertical-align-middle" status-type={props.type}></span>
          <span className="inline-block vertical-align-middle">
            {props.children}
          </span>
        </>
      }
      {
        props.shape === 'background' &&
        <>
          <span className="status-item status-background inline-flex" status-type={props.type}>
            {props.children}
          </span>
        </>
      }
    </div>
  );
};
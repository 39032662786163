export class Referral {

  /**
   *
   */
  private _code: string;
  set code(_code: string) { this._code = _code };
  get code(): string { return this._code };

  /**
   *
   */
  private _points: number;
  set points(_points: number) { this._points = _points };
  get points(): number { return this._points };

  /**
   *
   */
  private _count: number;

  constructor(_code: string, _count?: number ) {
    this._code = _code;
    this._count = _count? _count : 0;
    this._points = 0;
  }

  /**
   * 
   * @returns 
   */
  public getCount(): string {
    return this._count.toString();
  }
}
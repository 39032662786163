import { useEffect } from "react";
import { Button, Spinner } from "src/components";
import { IconFactory } from "src/factory/icon.factory";
import { Util } from "src/utils/Util";
import { OtpInputcodeFacade } from "../../controller/facades/otp-input-code.facade";
import { OtpFacade } from "../../controller/facades/otp.facade";

import './Otp.scss';

/** */
interface IOtp {

  phoneNumber?: string;

  email?: string;

	phoneOnly?: boolean;

	close?: () => void;

  onVerify: (isValid: boolean, token?: string) => void;
}

/**
 *
 */
const Otp = (props: IOtp): JSX.Element => {
	const { phoneNumber, email, phoneOnly, close, onVerify } = props;
  const { code, loader, timer, setCode, generateCode } = OtpFacade.useOtp(onVerify, phoneOnly, phoneNumber);
  const { onCodeChangeHandler, onCodeKeyDown, onSelectInput, onCodePaste, ref } = OtpInputcodeFacade.useOtpInputCode(code, setCode);
	
	/** */
	const useMountEffect = () => useEffect(() => {
		if(phoneOnly)
			generateCode(Util.CONSTANT.OTP.CODE_TYPE.SMS);
	}, []);
	useMountEffect();

  return (
    <div className={`otp-wrapper ${phoneOnly && 'phone-mode'}`}>
      <section className="relative" verifying-code={loader.isVerifyingCode ? '' : null}>
				{
					phoneOnly ? 
						<>
							<div className="phone-sms-image" style={ { backgroundImage: `url(${Util.ASSET.PHONE_SMS})`}}></div> 
							<h1 className="text-center">Valida tu número de teléfono</h1>
							<h2 className="text-center">Hemos enviado un código <br/>al número <b>{phoneNumber}</b></h2>
						</>
					:
						<h1 className="text-center">Verifica tu identidad</h1>
				}
				{
					!phoneOnly && <h2 className="text-center">Ingresa el c&oacute;digo de validaci&oacute;n</h2>
				}
        
        <div id="otp-code" className="text-center" onClick={(e) => { e.preventDefault(); onSelectInput() }}>
          <input autoComplete="off" name="otpc-1" maxLength={1} tabIndex={-1} onChange={onCodeChangeHandler} onKeyDown={onCodeKeyDown} onPaste={onCodePaste} ref={ref}
            onKeyPress={(e) => !/[a-zA-Z0-9]/.test(e.key) && e.preventDefault()} />
          <input autoComplete="off" name="otpc-2" maxLength={1} tabIndex={-1} onChange={onCodeChangeHandler} onKeyDown={onCodeKeyDown} onPaste={onCodePaste}
            onKeyPress={(e) => !/[a-zA-Z0-9]/.test(e.key) && e.preventDefault()} />
          <input autoComplete="off" name="otpc-3" maxLength={1} tabIndex={-1} onChange={onCodeChangeHandler} onKeyDown={onCodeKeyDown} onPaste={onCodePaste}
            onKeyPress={(e) => !/[a-zA-Z0-9]/.test(e.key) && e.preventDefault()} />
          <input autoComplete="off" name="otpc-4" maxLength={1} tabIndex={-1} onChange={onCodeChangeHandler} onKeyDown={onCodeKeyDown} onPaste={onCodePaste}
            onKeyPress={(e) => !/[a-zA-Z0-9]/.test(e.key) && e.preventDefault()} />
          <input autoComplete="off" name="otpc-5" maxLength={1} tabIndex={-1} onChange={onCodeChangeHandler} onKeyDown={onCodeKeyDown} onPaste={onCodePaste}
            onKeyPress={(e) => !/[a-zA-Z0-9]/.test(e.key) && e.preventDefault()} />
          <input autoComplete="off" name="otpc-6" maxLength={1} tabIndex={-1} onChange={onCodeChangeHandler} onKeyDown={onCodeKeyDown} onPaste={onCodePaste}
            onKeyPress={(e) => !/[a-zA-Z0-9]/.test(e.key) && e.preventDefault()} />
        </div>
        {
          loader.isVerifyingCode &&
          <Spinner></Spinner>
        }
      </section>

			{
				phoneOnly &&
					<section className="relative">
						<p className="text-center">Ingresa el c&oacute;digo de validaci&oacute;n</p>
						<p className="resend-text text-center">
							¿No recibiste el sms? 
							<button onClick={(e) => { e.preventDefault(); timer.smsCountdownSeconds === 0 && generateCode(Util.CONSTANT.OTP.CODE_TYPE.SMS) }}>
								{ timer.smsCountdownSeconds === 0 ? 'Reenviar' : timer.smsTimer }
							</button>
						</p>
						<div className="button-wrapper">						
							<Button onClick={close} fill="outline" disabled={loader.isVerifyingCode}>
								Regresar
							</Button>
						</div>
					</section>
			}
			
			{
				!phoneOnly && 
					<section className="flex column justify-center align-center">
					<div className="code-sender-wrapper width-100 flex align-center border-box">
						<div className="sender-icon flex align-center justify-center">
							{IconFactory.SmsIcon()}
						</div>
						<div className="sender-name flex-grow-100">
							<h3>SMS</h3>
							<h4>Enviar al tel&eacute;fono terminado en {phoneNumber!.substring(phoneNumber!.length - 4, phoneNumber!.length)}</h4>
						</div>
						<Button
							fill="outline"

							loading={loader.smsCodeLoading}
							disabled={loader.smsCodeLoading || timer.smsCountdownSeconds > 0}
							onClick={(e) => { e.preventDefault(); generateCode(Util.CONSTANT.OTP.CODE_TYPE.SMS) }}>
							{
								timer.smsCountdownSeconds === 0 ?
									'Enviar'
									:
									<>
										{timer.smsTimer}
									</>
							}
						</Button>
					</div>
					<div className="code-sender-wrapper width-100 flex align-center  border-box">
						<div className="sender-icon flex align-center justify-center">
							{IconFactory.mailOutlineIcon()}
						</div>
						<div className="sender-name flex-grow-100">
							<h3>Correo electr&oacute;nico</h3>
							<h4>Enviar al correo {email}</h4>
						</div>
						<Button
							fill="outline"
							loading={loader.emailCodeLoading}
							disabled={loader.emailCodeLoading || timer.emailCountdownSeconds > 0}
							onClick={(e) => { e.preventDefault(); generateCode(Util.CONSTANT.OTP.CODE_TYPE.EMAIL) }}>
							{
								timer.emailCountdownSeconds === 0 ?
									'Enviar'
									:
									<>
										{timer.emailTimer}
									</>
							}
						</Button>
					</div>
				</section>
			}
    </div>
  );
};

export default Otp;
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Reward } from "src/classes/referrals/Reward";
import { CoreSelector } from "src/core/selectors/core.selector"
import { AlertFactory } from "src/factory/alert.factory";
import { REFERRALS_PATH } from "src/routes/paths/referrals.paths";
import { ReferralsAction } from "../actions/referrals.action";
import { ReferralsFeature } from "../features/referrals.feature";
import { ReferralsSaga } from "../sagas/referrals.saga";
import { ReferralsSelector } from "../selectors/referrals.selector";
import { ReferralsSlice } from "../slices/referrals.slice";

/**
 *
 */
export namespace ReferralsFacade {

  /**
   * 
   * @returns 
   */
  export const useReferrals = () => {
    useInjectReducer({ key: 'referrals', reducer: ReferralsSlice.reducer });
    useInjectSaga({ key: 'referrals', saga: ReferralsSaga.referralsSaga });
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const currentUser = useSelector(CoreSelector.currentUser);
    const rewardsList = useSelector(ReferralsSelector.rewardsList);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(ReferralsAction.rewardsList());
    }, [dispatch])

    return { currentUser, rewardsList, showInfo, setShowInfo }
  }

  /**
   * 
   */
  export const useRewardDetails = () => {
    const { title } = useParams<string>();
    const [currentReward, setCurrentReward] = useState<Reward>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const currentUser = useSelector(CoreSelector.currentUser);
    const rewardsList = useSelector(ReferralsSelector.rewardsList);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /***
     * 
     */
    const onReturn = () => {
      navigate(REFERRALS_PATH.REWARDS, {replace: true});
    }

    /**
     * 
     */
    const exchangePoints = async (): Promise<void> => {
      let exchanged: boolean | undefined = false;
      try {
        if (currentReward) {
          setIsLoading(true);
          exchanged = await ReferralsFeature.exchangeRewardFeature(currentReward);
        }
      } catch(e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        setIsLoading(false);
        if (exchanged) {
          AlertFactory.successAlert(`Has canjeado ${currentReward?.title}.`);
          dispatch(ReferralsAction.rewardsList());
          onReturn();
        }
      }
    }

    /**
     * 
     */
    useEffect(() => {
      if (title && rewardsList) {
        const reward = rewardsList.find(r => r.title === title);
        if (reward) {
          setCurrentReward(reward);
        }
      }
    }, [rewardsList, title])

    return { currentReward, onReturn, currentUser, isLoading, exchangePoints }
  }
  
  /**
   * 
   * @returns 
   */
  export const useRewards = () => {
    useInjectReducer({ key: 'referrals', reducer: ReferralsSlice.reducer });
    useInjectSaga({ key: 'referrals', saga: ReferralsSaga.referralsSaga });
    const LIMIT = 25;
    const tBodyRef = useRef<HTMLDivElement>(null);
    const rewardsHistoryList = useSelector(ReferralsSelector.rewardsHistoryList);
    const rewardsHistoryListOffset = useSelector(ReferralsSelector.rewardsHistoryListOffset);
    const rewardsLoading = useSelector(ReferralsSelector.rewardsLoading);
    const dispatch = useDispatch();

    const getRewardsHistoryList = useCallback((force?: boolean) => {
      if (force) {
        dispatch(ReferralsAction.rewardsHistoryList({ offset: 0, limit: LIMIT }));
      } else if (rewardsHistoryListOffset! > -1) {
        dispatch(ReferralsAction.rewardsHistoryList({ offset: rewardsHistoryListOffset!, limit: LIMIT }));
      }
    }, [dispatch, rewardsHistoryListOffset])

    /**
     * 
     */
    const onScrollHandler = () => {
      const offset: number = 50;
      const scrollTop = tBodyRef.current?.scrollTop;
      const scrollHeight = tBodyRef.current?.scrollHeight;
      const offsetHeight = tBodyRef.current?.offsetHeight;
  
      if (scrollTop && offsetHeight && scrollHeight) {
        if (scrollHeight - scrollTop - offsetHeight < offset) {
          if (rewardsLoading) return;

          getRewardsHistoryList();
        }
      }
    };

    /**
     * 
     */
    useEffect(() => {
      dispatch(ReferralsSlice.actions.rewardsHistoryListClear());
      dispatch(ReferralsAction.rewardsHistoryList({ offset: 0, limit: LIMIT }));
    }, [dispatch]);

    return { rewardsHistoryList, tBodyRef, rewardsLoading, onScrollHandler };
  };
}
import './PasswordManagmentSecurityRule.scss';


/**
 *
 */
const PasswordManagmentSecurityRule = (): JSX.Element => {
  return (
    <div className="password-security-message">
    <p>Por tu seguridad la contraseña <span>no</span> debe incluir</p>
    <ul>
      <li>
        <p>La palabra Crabi <span>(ej. crabi, Crabi, CRABi, etc.)</span>.</p>
      </li>
      <li>
        <p>3 o más caracteres iguales y juntos <span>(ej. 111, aAa, BBB, etc.)</span>.</p>
      </li>
      <li>
        <p>Secuencia de 3 o más números o letras <span>(ej. 123, abc, etc.)</span>.</p>
      </li>
      <li>
        <p>No puede contener el nombre del correo que registrarás o registraste <span>(ej. si el correo es paula@gmail.com, no puede contener Paula, PAULA, paula, etc.)</span>.</p>
      </li>
    </ul>
  </div>
  );
};

export default PasswordManagmentSecurityRule;
import { Util } from "src/utils/Util";
import { PaymentCost } from "../PaymentCost";

export class PlanSubscription {

  /**
   *
   */
  private _firstCost: PaymentCost;
  set firstCost(_firstCost: PaymentCost) { this._firstCost = _firstCost };
  get firstCost(): PaymentCost { return this._firstCost };
  /**
   *
   */
  private _cost: PaymentCost;
  set cost(_cost: PaymentCost) { this._cost = _cost };
  get cost(): PaymentCost { return this._cost };

  constructor(_firstCost?: PaymentCost, _cost?: PaymentCost) {
    this._firstCost = _firstCost ? _firstCost : new PaymentCost();
    this._cost = _cost ? _cost : new PaymentCost();
  }

	/** */
	public getTotal = () => {
		return Util.TRANSFORM.CURRENCY.MXN(this.firstCost.total + (this.cost.total * 11));
	}
}
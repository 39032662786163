import { createContext, PropsWithChildren, ReactNode, useContext, useState } from "react";

export namespace BodyFactory {

  /**
   * 
   */
  interface IBodyProps {

    onClick?: () => void;
  }

  /**
   * 
   */
  interface IBodyContext {

    element: ReactNode;

    setElement?: (element: ReactNode) => void; 
  }


  /**
   * 
   */
  const BodyContext = createContext<IBodyContext>({element: <></>});

  /**
   * 
   * @param props 
   * @returns 
   */
  export const BodyElement = (props: PropsWithChildren<IBodyProps>): JSX.Element => {
    const [child, setChild] = useState<ReactNode>(<></>);

    return (
      <BodyContext.Provider value={{element: child, setElement: (newElement: ReactNode) => setChild(newElement)}}>
        <div id="main" onClick={() => { props.onClick && props.onClick() }}>
          {child}
          {props.children}
        </div>
      </BodyContext.Provider>
    )
  }

  /**
   * 
   */
  export const useBody = () => {
    const bodyContext = useContext(BodyContext);
    
    /**
     * 
     */
    const clearElement = (): void => {
      if (bodyContext.setElement) {
        bodyContext.setElement(<></>);
      }
    }

    /**
     * 
     * @param element 
     */
    const setElement = (element: ReactNode): void => {
      if (bodyContext.setElement) {
        bodyContext.setElement(element);
      }
    }

    return { setElement, clearElement, bodyContext }
  }
}
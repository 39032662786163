import { useState } from "react";
import { AlertFactory } from "src/factory/alert.factory";
import { InsuranceVehicleFeature } from "../features/insurance-vehicle.feature";
import { Util } from "src/utils/Util";

export namespace InsurancePictureItemFacade {

  export const usePictureItem = (token: string, selectedPicture: (file: File | undefined) => void, onIsValidating: (value: boolean) => void, onError: (name: string) => void) => {
    const [showCamera, setShowCamera] = useState<boolean>(false);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [picture, setPicture] = useState<string>('');
    const [isLoadingPicture, setIsLoadingPicture] = useState<boolean>(false);

    /**
     * 
     * @param picture 
     */
    const onSelectPicture = async (picture: string): Promise<void> => {
      try {
        console.log('Picture:');
        console.log(picture);
        const file = Util.TRANSFORM.FILE.dataURLtoFile(picture);
        console.log('File');
        console.log(file);
        setIsLoadingPicture(true);
        onIsValidating(true);
        await InsuranceVehicleFeature.isVehiclePictureFeature(token, file);
        setPicture(picture);
        selectedPicture(file);
      } catch (e) {
        const error = e as Error;
        if (error.name !== '') {
          onError((e as Error).name);
        } else {
          AlertFactory.errorAlert(error.message);
        }
        selectedPicture(undefined);
        setPicture('');
      } finally {
        setIsLoadingPicture(false);
        onIsValidating(false);
      }

    }

    /**
     * 
     */
    const onCancelCamera = (): void => {
      setShowCamera(false);
    }

    /**
     * 
     */
    const openCamera = (): void => {
      setShowCamera(true);
      onCancelOptions();
    }

    /**
     * 
     */
    const onCancelOptions = (): void => {
      setShowOptions(false);
    }

    /**
     * 
     */
    const openOptions = (): void => {
      setShowOptions(true);
    }

    /**
     * 
     */
    const openGallery = (): void => {
      const input = document.createElement('input');
      input.type = 'file';
      input.className = 'open-gallery-input';
      input.style.display = 'none';
      document.body.appendChild(input);
      input.accept = 'image/png, image/jpeg, image/jpg';
      onCancelOptions();
      input.onchange = async () => {
        if (input.files) {
          const files = Array.from(input.files!);
          if (files[0]) {
            const img = await Util.TRANSFORM.FILE.toBase64(files[0]);
            onSelectPicture(img);
            const length = document.body.getElementsByClassName('open-gallery-input').length;
            for (let i = 0; i < length; i++) {
              document.body.removeChild(document.body.getElementsByClassName('open-gallery-input')[0]);
            }
          }
        }
      }

      input.click();
    }

    return {
      picture, onSelectPicture, isLoadingPicture,
      camera: { showCamera, onCancelCamera, openCamera },
      options: { showOptions, onCancelOptions, openOptions, openGallery }
    }
  }
}
import { AxiosResponse } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { ITruoraTokenData } from "../interfaces/ITruoraTokenData";

export namespace IdentityValidationService {

	/** */
	export const createTruoraToken = (): Promise<AxiosResponse<ITruoraTokenData>> => {
		
		return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.CREATE_TRUORA_TOKEN);
	}
}
import { RootState } from "src/store/store";

/**
 *
 */
export namespace VehicleManagementSelector {

  export const vehicleBrandsList = (state: RootState) => state.vehicleManagement?.brands!;

  export const vehicleModelsList = (state: RootState) => state.vehicleManagement?.models!;

  export const vehicleYearsList = (state: RootState) => state.vehicleManagement?.years!;

  export const vehicleVersionsList = (state: RootState) => state.vehicleManagement?.version!;

	export const isLoadingBrands = (state: RootState) => state.vehicleManagement?.isLoadingBrands!;

	export const isLoadingModels = (state: RootState) => state.vehicleManagement?.isLoadingModels!;

	export const isLoadingYears = (state: RootState) => state.vehicleManagement?.isLoadingYears!;

	export const isLoadingVersions = (state: RootState) => state.vehicleManagement?.isLoadingVersions!;
}
import { useDispatch, useSelector } from "react-redux";
import { Coverage } from "src/classes/plan/Coverage";
import { CoverageOption } from "src/classes/plan/CoverageOption";
import { Plan } from "src/classes/plan/Plan";
import { Util } from "src/utils/Util";
import { PlanManagementAction } from "../actions/plan-management.action";
import { PlanManagementSelector } from "../selectors/plan-management.selector";

export namespace PlanCoverageFacade {

  /**
   * 
   * @param coverage 
   * @returns 
   */
   const createCustomizableCoverage = (coverage: Coverage): Coverage => {
    let newCoverage: Coverage = new Coverage();
    if (coverage) {
      newCoverage.name = coverage.name;
      newCoverage.type = coverage.type;
      newCoverage.assured = coverage.assured;
      newCoverage.isEnabled = coverage.isEnabled;
      newCoverage.deductible = coverage.deductible;
      newCoverage.allowDisable = coverage.allowDisable;
      newCoverage.customitationOptions = coverage.customitationOptions;
      newCoverage.isDeductible = coverage.isDeductible;
    }

    return newCoverage;
  }

  /**
   * 
   * @param plan 
   * @returns 
   */
   const createCopyCoverageList = (plan: Plan): Coverage[] => {
    const coveragesList: Coverage[] = [];
    coveragesList[0] = createCustomizableCoverage(plan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DM.type) as Coverage);
    coveragesList[1] = createCustomizableCoverage(plan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.RT.type) as Coverage);
    coveragesList[2] = createCustomizableCoverage(plan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.DT.type) as Coverage);
    coveragesList[3] = createCustomizableCoverage(plan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.GM.type) as Coverage);
    coveragesList[4] = createCustomizableCoverage(plan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AV.type) as Coverage);
    coveragesList[5] = createCustomizableCoverage(plan.coveragesList.find(c => c.type === Util.CONSTANT.QUOTATION.COVERAGE_NAMES.AL.type) as Coverage);
    return coveragesList;
  }

  /**
   * 
   * @param plan 
   * @returns 
   */
  export const useCoverageCustomization = () => {
    const currentPlan = useSelector(PlanManagementSelector.currentPlan);
    const user = useSelector(PlanManagementSelector.currentUser);
    const vehicle = useSelector(PlanManagementSelector.currentVehicle);
    const isRenewal = useSelector(PlanManagementSelector.isRenewal);
    const dispatch = useDispatch();
    /**
     * 
     * @param coverage 
     * @param value 
     */
    const onChangeCoverageValue = (coverage: Coverage, value: number | boolean): void => {
      try {
        const copyList = [...createCopyCoverageList(currentPlan)];
        const index = copyList.findIndex(c => c.type === coverage.type);
        
        dispatch(PlanManagementAction.customizationState(true));
        if (typeof value === 'boolean') {
          copyList[index].isEnabled = value as boolean;
          if (value === false) {
            copyList[index].deductible = -1;
            copyList[index].assured = -1;
          } else {
            copyList[index].deductible = currentPlan.coveragesList.find(c => c.type === coverage.type)?.deductible as number;
            copyList[index].assured = currentPlan.coveragesList.find(c => c.type === coverage.type)?.assured as number;
          }
          dispatch(PlanManagementAction.quotation({ user: user, vehicle: vehicle, coverages: copyList, isRenewal: isRenewal }));

        } else if ((coverage.isDeductible && coverage.deductible !== value as number) || (!coverage.isDeductible && coverage.assured !== value as number)) {
          if (coverage.isDeductible) {
            copyList[index].deductible = value as number;
          } else {
            copyList[index].assured = value as number;
          }
          dispatch(PlanManagementAction.quotation({ user: user, vehicle: vehicle, coverages: copyList, isRenewal: isRenewal }));
        }

      } catch (e) {
        console.error(e);
      }
    }

    return { onChangeCoverageValue }
  }


  export const usePlanCoverage = () => {

    /**
     * 
     * @param value 
     * @param isDeductible 
     * @returns 
     */
    const coverageValue = (option: CoverageOption): string => {
      if (option.deductible > -1) {
        return `${(option.deductible * 100).toString()}%`;
      } else {
        return `${Util.TRANSFORM.NUMBER.formatNumberToString(option.assured)}`
      }
    }

    /**
     * 
     * @param options 
     * @returns 
     */
    const createCoverageValueLabels = (options: CoverageOption[]): { [value: number]: string } => {
      let labels: { [value: number]: string } = {};
      for (let i: number = 0; i < options.length; i++) {
        let label: { [value: number]: string } = {}
        label[i] = coverageValue(options[i]);
        Object.assign(labels, label);
      }
      return labels;
    }

    /**
     * 
     * @param value 
     * @param options 
     * @param isDeductible 
     * @returns 
     */
    const defaultOptionValue = (value: number, options: CoverageOption[], isDeductible: boolean): CoverageOption => {
      let index: number = -1;
      if (isDeductible) {
        index = options.findIndex(o => o.deductible === value);
      } else {
        index = options.findIndex(o => o.assured === value);
      }
      return options[index];
    }

    return { createCoverageValueLabels, defaultOptionValue }
  }

  export const useCoverageStatus = () => {

    /**
     * 
     * @param coverages 
     */
    const coverageEnabledCount = (coverages: Coverage[]): number => {
      let count: number = 0;
      for(const coverage of coverages) {
        if (coverage.isEnabled) {
          count ++;
        }
      }
      return count;
    }

    return { coverageEnabledCount }
  }
}
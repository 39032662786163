import { AxiosError } from "axios";
import { AuthToken } from "src/classes/AuthToken";
import { ITokenDecoded } from "../interfaces/ITokenDecoded";
import { LoginService } from "../services/login.service";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";

export namespace LoginFeature {

  /**
   * 
   * @param authToken 
   * @returns 
   */
  export const isEmailValidatedFeature = (authToken: AuthToken): boolean | undefined => {
    try {
      const tokenDecoded: ITokenDecoded = JSON.parse(atob(authToken.access.split('.')[1]));
      const validated = tokenDecoded.roles.find(r => r === 'VALIDATED_EMAIL');
      if (!validated && EnvironmentFactory.ENV.REACT_APP_VALIDATE_EMAIL === 'validate') {
        return false;
      } else {
        return true;
      }

    } catch (e) {
      console.error(e);
      const error: Error = {
        message: 'Ha ocurrido un error de autenticación.',
        name: 'Token'
      }

      throw error;
    }
  }

  /**
   * 
   * @param token 
   */
  export const sendEmailValidationFeature = async (token: string): Promise<void> => {
    try {
      await LoginService.sendEmailValidationService(token);
    } catch (e) {
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }
}

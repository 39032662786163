import { Person  } from "src/classes/Person";
import UserName from "../../atoms/UserName/UserName";
import UserPicture from "../../atoms/UserPicture/UserPicture";
import './CurrentUser.scss';

/**
 *
 */
interface ICurrentUser {

  user: Person;
}

/**
 *
 */
const CurrentUser = (props: ICurrentUser): JSX.Element => {
  return (
    <div className="current-user-wrapper flex align-center">
      <UserPicture />

      <UserName name={props.user.name} />
    </div>
  );
};

export default CurrentUser;
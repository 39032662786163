import { Policy } from "src/classes/Policy";
import { SkeletonLoader } from "src/components";
import { Util } from "src/utils/Util";

import "./ClaimPolicyCardPicture.scss";

/**
 *
 */
interface IClaimPolicyCardPicture {
  /**
   *
   */
  policy?: Policy;
  /**
   *
   */
  loader?: boolean;
}

/**
 *
 */
const ClaimPolicyCardPicture = (
  props: IClaimPolicyCardPicture
): JSX.Element => {
  const { policy } = props;
  return (
    <div
      className="claim-picture claim-policy height-100 flex align-center justify-center"
      with-loader={props.loader ? "" : null}
    >
      {!props.loader && policy ? (
        <div className="vehicle-default-picture flex column justify-center align-center height-80 width-90">
          <span className="block background-image image-contain" style={{backgroundImage: `url(${Util.ASSET.EMPTY_CAR_PICTURE})`}}></span>
        </div>
      ) : (
        <SkeletonLoader type="square" />
      )}
    </div>
  );
};

export default ClaimPolicyCardPicture;

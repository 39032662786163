import PlanSummaryManagement from 'src/libs/PlanSummaryManagement/ui/template/PlanSummaryManagement';
import { PolicyCreationFacade } from 'src/modules/policy-creation/controller/facades/policy-creation.facade';
import { PolicyPlanReviewFacade } from 'src/modules/policy-creation/controller/facades/policy-plan-review.facade';
import { ReturnValidactionFacade } from 'src/modules/policy-creation/controller/facades/return-validation.facade';
import { POLICY_CREATION_PATH } from "src/routes/paths/policy-creation.paths";
import './PlanReview.scss';

/**
 *
 */
interface IPlanReview {

}

/**
 *
 */
const PlanReview = (props: IPlanReview): JSX.Element => {
  const { user, driver, vehicle, plan } = PolicyCreationFacade.usePolicyCreationActors();
	const isCurrentUser = PolicyCreationFacade.useOwnerValidation();
  const { isLoading, initPolicyRequest } = PolicyPlanReviewFacade.usePlanReview();
  const { onReturn } = ReturnValidactionFacade.useReturnValidation();
  const owner = driver ? driver : user;

  return (
    <div className="policyCreationStepWrapper height-100 width-100">
      {
        plan && owner && vehicle &&
        <PlanSummaryManagement
          user={owner}
          plan={plan}
          vehicle={vehicle}
          isLoading={isLoading}
          onComplete={() => initPolicyRequest()}
          onBackButton={() => onReturn(undefined, isCurrentUser ? POLICY_CREATION_PATH.POLICY_OWNER : POLICY_CREATION_PATH.PERSON_DATA)}
        />
      }
    </div>
  );
};

export default PlanReview;
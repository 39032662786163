import { useEffect, useState } from "react";
import { Address } from "src/classes/Address";
import { Phone } from "src/classes/Phone";
import { Profile } from "src/classes/Profile";
import { Person  } from "src/classes/Person";
import { Util } from "src/utils/Util";
import { PersonManagementFeature } from "../features/person-management.feature";
import { IPersonManagementEconomicActivity } from "../interfaces/IPersonManagementEconomicActivity";
import { IPersonManagementAddressForm } from "../interfaces/IPersonManagementAddressForm";
import { IPersonManagementDataForm } from "../interfaces/IPersonManagementDataForm";
import { CoreAddressFeature } from "src/core/features/address.feature";

/**
 *
 */
export namespace PersonManagementFacade {

  /**
   * 
   * @returns 
   */
  export const usePersonManagement = (showEconomicActivities?: boolean) => {
    const [isValidatingPostalCode, setIsValidatingPostalCode] = useState<boolean>(false)
    const [validatedPostalCode, setValidatedPostalCode] = useState<string>('');
    const [previousPostalCodeValidation, setPreviousPostalCodeValidation] = useState<boolean | null>(null);
    const [currentAddress, setCurrentAddress] = useState<Address>();
    const [gender, setGender] = useState<number | undefined>();
    const [currentPerson, setCurrentPerson] = useState<Person>(() => {
      let person = new Person('');
      person.profile = new Profile();
      person.profile.phone = new Phone();
      person.profile.address = new Address();

      return person;
    });
		const [economicActivities, setEconomicActivities] = useState<IPersonManagementEconomicActivity[]>();
		const [economicOwnBusiness, setEconomicOwnBusiness] = useState<IPersonManagementEconomicActivity[]>();
		const [chosenEconomicActivity, setChosenEconomicActivity] = useState<IPersonManagementEconomicActivity>();
		const [chosenOwnBusiness, setChosenOwnBusiness] = useState<IPersonManagementEconomicActivity>();
		const [isEconomicActivitiesValid, setIsEconomicActivitiesValid] = useState<boolean>(false);

    /**
     * 
     * @param personForm 
     * @param addressForm 
     * @returns 
     */
    const setPersonValues = (personForm: IPersonManagementDataForm, addressForm: IPersonManagementAddressForm): void => {
      currentPerson.email = personForm.email;
      currentPerson.name = personForm.name;
      currentPerson.lastName = personForm.firstLastname;
      currentPerson.secondLastName = personForm.secondLastname;
      if (currentPerson.profile) {
        if (personForm.birthdate) {
          const dateSplited = personForm.birthdate.split('/');
          currentPerson.profile.birthdate = new Date(parseInt(dateSplited[2]), parseInt(dateSplited[1]) - 1, parseInt(dateSplited[0]));
        }
        currentPerson.profile.rfc = personForm.rfc;
        if (currentPerson.profile.phone) {
          currentPerson.profile.phone.number = personForm.phone;
          currentPerson.profile.phone.countryCode = { code: personForm.countryCode };
        }
        if (currentPerson.profile.address && currentAddress) {
          currentPerson.profile.address = currentAddress!;
          currentPerson.profile.address.street = addressForm.street;
          currentPerson.profile.address.neighborhood = addressForm.neighborhood;
          currentPerson.profile.address.externalReference = addressForm.externalNumber;
          currentPerson.profile.address.internalReference = addressForm.internalNumber ? addressForm.internalNumber : '';
        }
      }
      setCurrentPerson(currentPerson);
    }

    /**
     * 
     * @param postalCode 
     * @returns 
     */
    const verifyPostalCode = async (postalCode: string): Promise<boolean | undefined> => {
      try {
        if (Util.PATTERN.NUMBERS.test(postalCode)) {
          if (validatedPostalCode !== postalCode) {
            setIsValidatingPostalCode(true);
            setValidatedPostalCode(postalCode);
						const address = await CoreAddressFeature.validatePostalCodeFeature(postalCode);
            if (address) {
              setPreviousPostalCodeValidation(true);
              setCurrentAddress(address);
              return true;
            } else {
              return false;
            }
          } else {
            return previousPostalCodeValidation as boolean;
          }
        }
      } catch (e) {

      } finally {
        setIsValidatingPostalCode(false);
      }
    };

		/** Use effect for retrieving the economic activities data. */
		useEffect(() => {
			if(showEconomicActivities && showEconomicActivities === true){

				const fetchEconomicActivities = async() => {
					const economicActivitiesData = await PersonManagementFeature.getEconomicActivities();
					const economicOwnBusinessData = await PersonManagementFeature.getOwnBusinessActivities();

					setEconomicActivities(economicActivitiesData);
					setEconomicOwnBusiness(economicOwnBusinessData);
				};

				fetchEconomicActivities()
					.catch(e => {
						console.error(e);
					});
			}
		}, [showEconomicActivities]);

		/** Use effect for validating if economic activities has a valid value. */
		useEffect(() => {
			if(chosenEconomicActivity && chosenEconomicActivity.id !== 0){
				currentPerson.profile!.economicActivity = chosenEconomicActivity.id as number;
			}else if(chosenEconomicActivity && chosenEconomicActivity.id === 0 && chosenOwnBusiness){
				currentPerson.profile!.economicActivity = chosenOwnBusiness.id as number;
			}else{
				currentPerson.profile!.economicActivity = -1;
			}

			if(chosenEconomicActivity){
				if(chosenEconomicActivity.id === 0 && !chosenOwnBusiness)
					setIsEconomicActivitiesValid(false);
				else
					setIsEconomicActivitiesValid(true);
			}
		}, [chosenEconomicActivity, chosenOwnBusiness, currentPerson]);

    return { 
			currentPerson,
			verifyPostalCode,
			isValidatingPostalCode,
			setPersonValues,
			economicActivities,
			economicOwnBusiness,
			chosenEconomicActivity,
			isEconomicActivitiesValid,
      gender,
			setChosenEconomicActivity,
			setChosenOwnBusiness,
      setGender,
		};
  }
}
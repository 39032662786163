import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects'
import { Plan } from 'src/classes/plan/Plan';
import { Policy } from 'src/classes/Policy';
import { Person  } from 'src/classes/Person';
import { Vehicle } from 'src/classes/vehicle/Vehicle';
import { PolicyCreationAction } from '../actions/policy-creation.action';
import { PolicyCreationSlice } from '../slices/policy-creation.slice';
import { PolicyDriverDataSlice } from "../slices/policy-driver-data.slice";
import { PolicyVehicleDataSlice } from "../slices/policy-vehicle-data.slice";

/**
 *
 */
export namespace PolicyCreationSaga {

  /**
   * 
   * @param action 
   */
  function* setCurrentDriver(action: PayloadAction<Person>) {
    yield put(PolicyCreationSlice.actions.currentDriver(action.payload));
  }
  /**
   * 
   * @param action 
   */
  function* setCurrentVehicle(action: PayloadAction<Vehicle>) {
    yield put(PolicyCreationSlice.actions.currentVehicle(action.payload));
  }

  /**
   * 
   * @param action 
   */
  function* setCurrentPlan(action: PayloadAction<Plan>) {
    yield put(PolicyCreationSlice.actions.currentPlan(action.payload));
  }

  /**
   * 
   * @param action 
   */
  function* completeReview() {
    yield put(PolicyCreationSlice.actions.completeReview(true));
  }

  /**
   * 
   */
  function* clearManagement() {
    yield put(PolicyCreationSlice.actions.clear());
    yield put(PolicyVehicleDataSlice.actions.clear());
    yield put(PolicyDriverDataSlice.actions.clear());
  }

  /**
   * Clear vehicle from storage to init a new quotation
   */
  function* clearQuotationVehicle() {
    yield put(PolicyVehicleDataSlice.actions.clear());
  }

  function* stepBackToDriver() {
    yield put(PolicyCreationSlice.actions.removeDriver());
  }

  function* stepBackToVehicle() {
    //yield put(PolicyCreationSlice.actions.removeVehicle());
    yield put(PolicyCreationSlice.actions.removePlanDetails());
  }

  function* stepBackToPlan() {
    yield put(PolicyCreationSlice.actions.removePlan());
  }

  function* stepBackToReview() {
    yield put(PolicyCreationSlice.actions.completeReview(false));
  }

	function* setVehiclePostalCode(action: PayloadAction<string>) {
		yield put(PolicyCreationSlice.actions.setVehiclePostalCode(action.payload));
	}

	function* setCurrentPolicy(action: PayloadAction<Policy>) {
		yield put(PolicyCreationSlice.actions.currentPolicy(action.payload));
	}

  export function* policyCreationSaga() {
    yield takeLatest(PolicyCreationAction.currentDriver.type, setCurrentDriver);
    yield takeLatest(PolicyCreationAction.currentVehicle.type, setCurrentVehicle);
    yield takeLatest(PolicyCreationAction.currentPlan.type, setCurrentPlan);
    yield takeLatest(PolicyCreationAction.completeReview.type, completeReview);
    yield takeLatest(PolicyCreationAction.clearManagement.type, clearManagement);
    yield takeLatest(PolicyCreationAction.clearQuotationVehicle.type, clearQuotationVehicle);
		yield takeLatest(PolicyCreationAction.setVehiclePostalCode.type, setVehiclePostalCode);
		yield takeLatest(PolicyCreationAction.currentPolicy.type, setCurrentPolicy);
    
    yield takeLatest(PolicyCreationAction.stepBackToDriver.type, stepBackToDriver);
    yield takeLatest(PolicyCreationAction.stepBackToVehicle.type, stepBackToVehicle);
    yield takeLatest(PolicyCreationAction.stepBackToPlan.type, stepBackToPlan);
    yield takeLatest(PolicyCreationAction.stepBackToReview.type, stepBackToReview);
  }
}
import { Button } from "src/components";
import './ButtonSection.scss';

/**
 *
 */
interface IButtonSection {
	/** */
	backHandler: () => void;
	/** */
	nextHandler?: () => void;
	/** */
	skipHandler?: () => void;
	/** */
	backButtonText?: string;
	/** */
	nextButtonText?: string;
	/** */
	nextButtonDisabled?: boolean;
	/** */
	isLoading?: boolean;
	/** */
	hasSkip?: boolean;
}

/**
 *
 */
const ButtonSection = (props: IButtonSection): JSX.Element => {
	/** */
	const { backHandler, nextHandler, skipHandler, backButtonText, nextButtonText, nextButtonDisabled, isLoading, hasSkip } = props;

  return (
    <div className='button-section'>
			<Button className='back-btn' fill='outline' onClick={backHandler} disabled={isLoading}>{ backButtonText ? backButtonText : 'Atrás' } </Button>
			<Button className='next-btn' disabled={nextButtonDisabled || isLoading} loading={isLoading} onClick={nextHandler}>
				{ nextButtonText ? nextButtonText : 'Continuar' }
			</Button>
			{
				hasSkip && (
					<button className='skip' onClick={skipHandler}>
						Saltar
					</button>
				)
			}
		
    </div>
  );
};

export default ButtonSection;
import { Util } from "src/utils/Util";

export class StatusTypeItem {

  /**
   *
   */
  private _name: string;
  set name(_name: string) { this._name = _name };
  get name(): string { return this._name };

  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };

  constructor(_id: string, _name: string) {
    this._id = _id;
    this._name = _name;
  }

  /**
   * 
   * @returns 
   */
  public isBikeStatusType(): boolean {
    return this._id === Util.STATUS_CODE.CLAIMS_VEHICLE_TYPES.BIKE;
  }

  /**
   * 
   * @returns 
   */
  public isCarStatusType(): boolean {
    return this._id === Util.STATUS_CODE.CLAIMS_VEHICLE_TYPES.CAR;
  }

  /**
   * 
   * @returns 
   */
  public isPersonFrontLicensePicture(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.LICENSE_FRONT_PICTURE;
  }

  /**
   * 
   * @returns 
   */
  public isPersonBackLicensePicture(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.LICENSE_BACK_PICTURE;
  }

  /**
   * 
   * @returns 
   */
  public isPersonFrontInePicture(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.INE_FRONT_PICTURE;
  }

  /**
   * 
   * @returns 
   */
  public isPersonBackInePicture(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.INE_BACK_PICTURE;
  }

  /**
   * 
   * @returns 
   */
  public isPersonDrivingPermission(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.DRIVING;
  }

  /**
   * 
   * @returns 
   */
  public isPersonVehicleVin(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.VIN;
  }

  /**
   * 
   * @returns 
   */
  public isPersonVehicle(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.VEHICLE;
  }

  /**
   * 
   * @returns 
   */
  public isReferencePictures(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.CLAIMS.NAMES.REFERENCES;
  }

  /**
   * 
   * @returns 
   */
  public isDUAPicture(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_PICTURES_TYPES.CLAIMS.NAMES.DUA;
  }

	/**
	 * 
	 * @returns 
	 */
	public isNA(): boolean {
		return this._id === Util.STATUS_CODE.CLAIMS_PERSON_TYPES.INSURED;
	}

	/**
	 * 
	 * @returns 
	 */
	public isAgreedPaymentServiceCategory(): boolean {
		return this._id === Util.STATUS_CODE.CLAIMS_SERVICE_CATEGORY_TYPES.AGREED_PAYMENT;
	}

	/**
	 * 
	 * @returns 
	 */
	public isThirdPartyDriver(): boolean {
		return this._id === Util.STATUS_CODE.CLAIMS_PERSON_TYPES.THIRD_PARTY_DRIVER;
	}

  /**
   * 
   * @returns 
   */
  public isCyclistPersonType(): boolean {
    return this._id === Util.STATUS_CODE.CLAIMS_PERSON_TYPES.CYCLIST;
  }

  /**
   * 
   * @returns 
   */
  public isPropertyOwnerPersonType(): boolean {
    return this._id === Util.STATUS_CODE.CLAIMS_PERSON_TYPES.PROPERTY_OWNER;
  }

  /**
   * 
   */
  public isClaimRobbery(): boolean {
    return this._id === Util.STATUS_CODE.CLAIMS_TYPES.ROBBERY;
  }

  /**
   * 
   */
  public isClaimWindscreen(): boolean {
    return this._id === Util.STATUS_CODE.CLAIMS_TYPES.WINDSCREEN;
  }

  /**
   * 
   */
  public isClaimCollision(): boolean {
    return this._id === Util.STATUS_CODE.CLAIMS_TYPES.COLLISION;
  }

  /**
   * 
   * @returns 
   */
  public isClaimTotalLoss(): boolean {
    return this._id === Util.STATUS_CODE.CLAIMS_TYPES.TOTAL_LOSS;
  }

	/**
	 * 
	 * @returns 
	 */
	public isRepairServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.REPAIR;
	}

	/**
	 * 
	 * @returns 
	 */
	public isMedicalServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.MEDICAL;
	}

	/**
	 * 
	 * @returns 
	 */
	public isCraneServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.CRANE;
	}

	/**
	 * 
	 * @returns 
	 */
	public isLawyerServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.LAWYER;
	}

	/**
	 * 
	 * @returns 
	 */
	public isInvestigatorServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.INVESTIGATOR;
	}

	/**
	 * 
	 * @returns 
	 */
	public isContractorServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.CONTRACTOR;
	}

	/**
	 * 
	 * @returns 
	 */
	public isSipacServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.SIPAC;
	}

	/**
	 * 
	 * @returns 
	 */
	public isTraditionalOrderServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.TRADITIONAL_ORDER;
	}

	/**
	 * 
	 * @returns 
	 */
	public isPaymentServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.PAYMENT;
	}

	/**
	 * 
	 * @returns 
	 */
	public isClaimPaymentServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.CLAIM_PAYMENT;
	}

	/**
	 * 
	 * @returns 
	 */
	public isNoCostServiceType(): boolean {
		return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.NO_COST;
	}
	
	/**
	 * 
	 * @returns 
	 */
	public isCraneConcessionedCategory(): boolean {
		return this._id === Util.STATUS_CODE.CLAIMS_CRANE_CATEGORIES.CONCESSIONED;
	}

  /**
   * 
   * @returns 
   */
  public isAssistanceServiceType(): boolean {
    return this._id === Util.STATUS_CODE.CLAIM_SERVICE_TYPES.ASSISTANCE;
  }


}
import { Spinner, FormError, InputLabel } from "src/components";
import { DamageClaimNavigateValidationFacade } from 'src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade';
import { PolicyDataFormFacade } from 'src/modules/claim-payments/controller/facades/policy-data-form.facade';
import { PolicyDataFacade } from 'src/modules/claim-payments/controller/facades/policy-data.facade';
import ButtonSection from '../../molecules/ButtonSection/ButtonSection';
import ClaimPaymentPolicyCard from '../../molecules/ClaimPaymentPolicyCard/ClaimPaymentPolicyCard';

import './PolicyDataPage.scss';


/**
 *
 */
const PolicyDataPage = (): JSX.Element => {
	/** */
	const { currentDamageClaim, currentVehicle, isAgreedPayment, hasPolicyNumber, currentPath, isLoadingDamageClaim } = PolicyDataFacade.usePolicyDataFacade();
	/** */
	const { register, formState, submitFormData, getValues, isLoading } = PolicyDataFormFacade.usePolicyDataForm(); 
	/** */
	const { POLICY_NUMBER_ERRORS, CLAIM_PAYMENT_NUMBER_ERRORS } = PolicyDataFormFacade.usePolicyDataValidation();
	/** */
	const { damageClaim } = PolicyDataFacade.useClaimPolicy();
	/** */
	const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id='policy-data-wrapper' className='flex-grow-100 flex'>
			{
				isLoadingDamageClaim ?
					<div className='loading-wrapper'>
						<Spinner color='main'/>
					</div>
				:
					<>
						<section className='content height-100'>
							<h1>Solicitud pago { isAgreedPayment ? 'pactado' : 'de daño'}</h1>
							{
								!hasPolicyNumber ?
									<>
										<h3>No. Póliza.</h3>
										<InputLabel
											required
											type="text"
											defaultValue={currentDamageClaim?.policyNumber ? currentDamageClaim.policyNumber : ''}
											disabled={isLoading}
											uppercase
											errors={!!formState.errors.policyNumber || (!!formState.touchedFields.policyNumber && formState.errors.policyNumber)}
											{...register('policyNumber', { required: true, minLength: 4, maxLength: 100 })}
										>
											No. Póliza
										</InputLabel>
										<FormError schema={POLICY_NUMBER_ERRORS} type={formState.errors.policyNumber?.type} />
									</>
								:
									<>
										<p>Póliza seleccionada</p>
										<ClaimPaymentPolicyCard damageClaim={damageClaim} vehicle={currentVehicle} />
									</>
							}
							<InputLabel
								required
								type="text"
								mask="CRA9999999999"
								defaultValue={currentDamageClaim?.damageClaimNumber ? currentDamageClaim.damageClaimNumber : ''}
								disabled={isLoading}
								errors={!!formState.errors.damageClaimNumber || (!!formState.touchedFields.damageClaimNumber && formState.errors.damageClaimNumber)}
								{...register('damageClaimNumber', { required: true, minLength: 4, maxLength: 100 })}
							>
								No. de Siniestro
							</InputLabel>
							<FormError schema={CLAIM_PAYMENT_NUMBER_ERRORS} type={formState.errors.damageClaimNumber?.type} />
						</section>

						<ButtonSection 
							backHandler={() => navigationHandler(currentPath, 'backward')} 
							nextHandler={() => submitFormData(getValues())}
							nextButtonDisabled={!formState.isValid || isLoading || (hasPolicyNumber && damageClaim?.policyNumber.length === 0)}
							isLoading={isLoading}
						/>
					</>
			}

    </div>
  );
};

export default PolicyDataPage;
import { Person  } from "src/classes/Person"
import { Util } from "src/utils/Util"
import { IRegisterRequest } from "../interfaces/IRegisterRequest"
import { IUtmParams } from "../interfaces/IUtmParams"

export namespace QuoterAdapter {

  /**
   * 
   * @param endorsementsData 
   */
  export const registerUserRequestAdapter = (data: Person, utms: IUtmParams, referral: string): IRegisterRequest => {
    let returnData: IRegisterRequest = {
			user: {
				email: data.email.toLocaleLowerCase(),
				password: data.password
			},
			role: 'POLICY',
			person: {
				first_name: data.name.trim(),
				first_last_name: data.lastName.trim(),
				second_last_name: data.secondLastName.trim(),
				email: data.email.toLocaleLowerCase(),
				phone: `${data.profile!.phone?.countryCode?.code}${data.profile!.phone?.number}`.replace('+', ''),
				gender_code: data.profile!.gender,
				civil_status_code: 0, //DEFAULT VALUE
				birthdate: Util.TRANSFORM.DATE.formatServerDate(data.profile!.birthdate!, '-'),
				rfc: data.profile!.rfc.toLocaleUpperCase(),
				address: {
					street: data.profile!.address!.street!,
					external_number: data.profile!.address!.externalReference,
					internal_number: data.profile!.address!.internalReference ? data.profile!.address!.internalReference : '',
					neighborhood: data.profile!.address!.neighborhood,
					postal_code: data.profile!.address!.postalCode,
					municipality_code: data.profile!.address!.municipalityCode,
					state_code: data.profile!.address!.stateCode,
					country_code: data.profile!.address!.countryCode
				},
				pld_data: {
					nationality_code: data.profile!.address?.countryCode!,
					country_of_origin_code: data.profile!.address?.countryCode!,
					state_of_origin_code: data.profile!.address?.stateCode!,
					economic_activity_code: data.profile!.economicActivity
				},
				referral_from: referral ? referral : '',
				utm_campaign: utms.utm_campaign ? utms.utm_campaign : '',
				utm_medium: utms.utm_medium ? utms.utm_medium : '',
				utm_source: utms.utm_source ? utms.utm_source : '',
				utm_term: utms.utm_term ? utms.utm_term : '',
				utm_content: utms.utm_content ? utms.utm_content : ''
			},
		}
    return returnData
  }
}
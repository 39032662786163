import { Navigate, useLocation } from "react-router-dom";
import { INSURANCE_INIT_PARAMS, INSURANCE_VEHICLE_PATH } from "src/routes/paths/insurance-vehicle.paths";
import InsuranceCompletionPage from "../ui/pages/InsuranceCompletion/InsuranceCompletion";
import InsuranceInitPage from "../ui/pages/InsuranceInit/InsuranceInit";
import InsurancePicturesPage from "../ui/pages/InsurancePictures/InsurancePictures";
import InsuranceVehiclePage from '../ui/pages/InsuranceVehicle';

/**
 *
 */
export const InsuranceVehicleRoutes = () => {
  const { search } = useLocation();
  return {
    path: INSURANCE_VEHICLE_PATH.INSURANCE,
    element: <InsuranceVehiclePage />,
    children : [
      { path: `${INSURANCE_VEHICLE_PATH.INIT}`, element: <Navigate to={`${INSURANCE_VEHICLE_PATH.INIT}/${INSURANCE_INIT_PARAMS.INFO.FIRST}${search}`} replace/>},
      {
        path: `${INSURANCE_VEHICLE_PATH.INIT}/:info`,
        element: <InsuranceInitPage />
      },
      {
        path: INSURANCE_VEHICLE_PATH.PICTURES,
        element: <InsurancePicturesPage />
      },
      {
        path: INSURANCE_VEHICLE_PATH.COMPLETION,
        element: <InsuranceCompletionPage />
      }
    ]
  }
}
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DamageClaim } from "src/classes/DamageClaim";
import { CoreTokenStorageFeature } from "src/core/features/token-storage.feature";
import { ClaimPaymentsFacade } from "./claim-payments.facade";
import { CLAIM_PAYMENTS_LIST_PATH } from "src/routes/paths/claim-payments-list.paths";
import { AGREED_PAYMENTS_LIST_PATH } from "src/routes/paths/agreed-payments-list.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { Util } from "src/utils/Util";

/** */
const REDIRECT_OPTIONS = {
  FIRST: 1,
  SECOND: 2
}
export namespace DamageClaimNavigateValidationFacade {

  /**
   * 
   */
  export const useDamageClaimNavigateValidationFacade = () => {
		const { isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const policyNumberParam = searchParams.get('policy-number');
    const claimIdParam = searchParams.get('claim');

    /**
     * 
     * @param _currentPath 
     * @param _navigateDirection 
     * @param _incomingDamageClaim 
     * @param _ownerRedirection      { 1, 2 ... n, for redirecting options. }
     */
    const navigationHandler = (_currentPath: string, _navigateDirection?: 'forward' | 'backward', _incomingDamageClaim?: DamageClaim, _ownerRedirection?: number) => {
			let route: string;

			if(isAgreedPayment)
				route = agreedRoutesHandler(_currentPath, _navigateDirection, _incomingDamageClaim, _ownerRedirection);
			else
				route = routesHandler(_currentPath, _navigateDirection, _incomingDamageClaim, _ownerRedirection);

      navigate(route, { replace: _navigateDirection === 'backward' ? true : false });
    };

    /** */
    const routesHandler = (_currentPath: string, _navigateDirection?: 'forward' | 'backward', _incomingDamageClaim?: DamageClaim, _ownerRedirection?: number): string => {
      let route: string = '';

      switch (_currentPath) {
        case CLAIM_PAYMENTS_PATH.POLICY_DATA:
					const storageKey = localStorage.getItem(Util.KEY.REDIRECT_ONBOARDING_STORAGE);
          route = _navigateDirection === 'forward' ?
            `${CLAIM_PAYMENTS_PATH.PERSONAL_DATA}${getURLParamsRoute(_incomingDamageClaim)}`
            :
            authValidation() && !storageKey ? `${CLAIM_PAYMENTS_LIST_PATH.CLAIM_PAYMENTS_LIST}` : `${CLAIM_PAYMENTS_PATH.ONBOARDING}`
          break;

        case CLAIM_PAYMENTS_PATH.PERSONAL_DATA:
          route = _navigateDirection === 'forward' ?
            `${CLAIM_PAYMENTS_PATH.VEHICLE_DATA}${getURLParamsRoute()}`
            :
            `${CLAIM_PAYMENTS_PATH.POLICY_DATA}${getURLParamsRoute()}`
          break;

        case CLAIM_PAYMENTS_PATH.VEHICLE_DATA:
          route = _navigateDirection === 'forward' ?
            `${CLAIM_PAYMENTS_PATH.VEHICLE_PICTURES}${getURLParamsRoute()}`
            :
            `${CLAIM_PAYMENTS_PATH.PERSONAL_DATA}${getURLParamsRoute()}`
          break;

        case CLAIM_PAYMENTS_PATH.VEHICLE_PICTURES:
          route = _navigateDirection === 'forward' ?
            `${CLAIM_PAYMENTS_PATH.ID_DOCUMENTS}${getURLParamsRoute()}`
            :
            `${CLAIM_PAYMENTS_PATH.VEHICLE_DATA}${getURLParamsRoute()}`
          break;

        case CLAIM_PAYMENTS_PATH.ID_DOCUMENTS:
          route = _navigateDirection === 'forward' ?
            `${CLAIM_PAYMENTS_PATH.BANK_ACCOUNT}${getURLParamsRoute()}`
            :
            `${CLAIM_PAYMENTS_PATH.VEHICLE_PICTURES}${getURLParamsRoute()}`
          break;

        case CLAIM_PAYMENTS_PATH.BANK_ACCOUNT:
          route = _navigateDirection === 'forward' ?
            `${CLAIM_PAYMENTS_PATH.VEHICLE_OWNER}${getURLParamsRoute()}`
            :
            `${CLAIM_PAYMENTS_PATH.ID_DOCUMENTS}${getURLParamsRoute()}`
          break;

        case CLAIM_PAYMENTS_PATH.VEHICLE_OWNER:
          route = _navigateDirection === 'forward' ?
            (_ownerRedirection === REDIRECT_OPTIONS.FIRST ? `${CLAIM_PAYMENTS_PATH.VEHICLE_BILL}${getURLParamsRoute()}` : `${CLAIM_PAYMENTS_PATH.ATTORNEY_LETTER}${getURLParamsRoute()}`)
            :
            `${CLAIM_PAYMENTS_PATH.BANK_ACCOUNT}${getURLParamsRoute()}`
          break;

        case CLAIM_PAYMENTS_PATH.VEHICLE_BILL:
          route = _navigateDirection === 'forward' ?
            `${CLAIM_PAYMENTS_PATH.COMPLETION}${getURLParamsRoute()}`
            :
            `${CLAIM_PAYMENTS_PATH.VEHICLE_OWNER}${getURLParamsRoute()}`
          break;

        case CLAIM_PAYMENTS_PATH.ATTORNEY_LETTER:
          route = _navigateDirection === 'forward' ?
            `${CLAIM_PAYMENTS_PATH.COMPLETION}${getURLParamsRoute()}`
            :
            `${CLAIM_PAYMENTS_PATH.VEHICLE_OWNER}${getURLParamsRoute()}`
          break;

        case CLAIM_PAYMENTS_PATH.PROPOSAL:
          route = `${CLAIM_PAYMENTS_PATH.COMPLETION}${getURLParamsRoute(undefined, true)}`
          break;

        /** CHANGE IT UNCOMPLETED */
        case CLAIM_PAYMENTS_PATH.COMPLETION:
          route = _navigateDirection === 'forward' ?
            `/`
            :
            `${CLAIM_PAYMENTS_PATH.COMPLETION}${getURLParamsRoute()}`
          break;
      }

      return route;
    };
		
		/** */
		const agreedRoutesHandler = (_currentPath: string, _navigateDirection?: 'forward' | 'backward', _incomingDamageClaim?: DamageClaim, _ownerRedirection?: number): string => {
			let route: string = '';

			switch (_currentPath) {
				case AGREED_PAYMENTS_PATH.POLICY_DATA:
					const storageKey = localStorage.getItem(Util.KEY.REDIRECT_ONBOARDING_STORAGE);
					route = _navigateDirection === 'forward' ?
						`${AGREED_PAYMENTS_PATH.PERSONAL_DATA}${getURLParamsRoute(_incomingDamageClaim)}`
						:
						authValidation() && !storageKey ? `${AGREED_PAYMENTS_LIST_PATH.AGREED_PAYMENTS_LIST}` : `${AGREED_PAYMENTS_PATH.ONBOARDING}`
					break;

				case AGREED_PAYMENTS_PATH.PERSONAL_DATA:
					route = _navigateDirection === 'forward' ?
						`${AGREED_PAYMENTS_PATH.VEHICLE_DATA}${getURLParamsRoute()}`
						:
						`${AGREED_PAYMENTS_PATH.POLICY_DATA}${getURLParamsRoute()}`
					break;

				case AGREED_PAYMENTS_PATH.VEHICLE_DATA:
					route = _navigateDirection === 'forward' ?
						`${AGREED_PAYMENTS_PATH.ID_DOCUMENTS}${getURLParamsRoute()}`
						:
						`${AGREED_PAYMENTS_PATH.PERSONAL_DATA}${getURLParamsRoute()}`
					break;

				case AGREED_PAYMENTS_PATH.ID_DOCUMENTS:
					route = _navigateDirection === 'forward' ?
						`${AGREED_PAYMENTS_PATH.BANK_ACCOUNT}${getURLParamsRoute()}`
						:
						`${AGREED_PAYMENTS_PATH.VEHICLE_DATA}${getURLParamsRoute()}`
					break;

				case AGREED_PAYMENTS_PATH.BANK_ACCOUNT:
					route = _navigateDirection === 'forward' ?
						`${AGREED_PAYMENTS_PATH.VEHICLE_OWNER}${getURLParamsRoute()}`
						:
						`${AGREED_PAYMENTS_PATH.ID_DOCUMENTS}${getURLParamsRoute()}`
					break;

				case AGREED_PAYMENTS_PATH.VEHICLE_OWNER:
					route = _navigateDirection === 'forward' ?
						(_ownerRedirection === REDIRECT_OPTIONS.FIRST ? `${AGREED_PAYMENTS_PATH.VEHICLE_BILL}${getURLParamsRoute()}` : `${AGREED_PAYMENTS_PATH.ATTORNEY_LETTER}${getURLParamsRoute()}`)
						:
						`${AGREED_PAYMENTS_PATH.BANK_ACCOUNT}${getURLParamsRoute()}`
					break;

				case AGREED_PAYMENTS_PATH.VEHICLE_BILL:
					route = _navigateDirection === 'forward' ?
						`${AGREED_PAYMENTS_PATH.COMPLETION}${getURLParamsRoute()}`
						:
						`${AGREED_PAYMENTS_PATH.VEHICLE_OWNER}${getURLParamsRoute()}`
					break;

				case AGREED_PAYMENTS_PATH.ATTORNEY_LETTER:
					route = _navigateDirection === 'forward' ?
						`${AGREED_PAYMENTS_PATH.COMPLETION}${getURLParamsRoute()}`
						:
						`${AGREED_PAYMENTS_PATH.VEHICLE_OWNER}${getURLParamsRoute()}`
					break;

				case AGREED_PAYMENTS_PATH.PROPOSAL:
					route = `${AGREED_PAYMENTS_PATH.COMPLETION}${getURLParamsRoute(undefined, true)}`
					break;

				/** CHANGE IT UNCOMPLETED */
				case AGREED_PAYMENTS_PATH.COMPLETION:
					route = _navigateDirection === 'forward' ?
						`/`
						:
						`${AGREED_PAYMENTS_PATH.COMPLETION}${getURLParamsRoute()}`
					break;
			}

			return route;
		};

    /** */
    const getURLParamsRoute = (_incomingDamageClaim?: DamageClaim, proposalParam?: boolean): string => {
      let route: string = '';

      if (_incomingDamageClaim) {
        route = `?claim=${_incomingDamageClaim.uid}`;
        return route;
      }

      if (policyNumberParam) {
        route = `?policy-number=${policyNumberParam}`;

        if (claimIdParam)
          route = `${route}&claim=${claimIdParam}`;
      } else if (claimIdParam) {
        route = `?claim=${claimIdParam}`;
      }

			if (proposalParam) {
				route = `${route}${route.length > 0 ? '&' : '?'}proposal=true`
			}

      return route;
    }

    /** */
    const authValidation = () => {
      const isValid = CoreTokenStorageFeature.verifyTokenFeature();
      return isValid;
    }

    return { navigationHandler };
  }
}
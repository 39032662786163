import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CorePolicyFeature } from "src/core/features/policy.feature";
import { CoreSelector } from "src/core/selectors/core.selector";
import { AlertFactory } from "src/factory/alert.factory";
import { POLICY_CREATION_PATH } from "../../../../routes/paths/policy-creation.paths";
import { PolicyCreationAction } from "../actions/policy-creation.action";
import { PolicyCreationFacade } from "./policy-creation.facade";

export namespace PolicyPlanReviewFacade {

  /**
   * 
   * @returns 
   */
  export const usePlanReview = () => {
    const { vehicle, plan, driver } = PolicyCreationFacade.usePolicyCreationActors();
    const maxProducts = useSelector(CoreSelector.maxProducts);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * 
     */
    const initPolicyRequest = async () => {
      try {
        setIsLoading(true);
        if (!maxProducts) {
          const currentPolicy = await CorePolicyFeature.policyRequestFeature(vehicle, plan, driver);
          if (currentPolicy) {
            dispatch(PolicyCreationAction.completeReview());
						dispatch(PolicyCreationAction.currentPolicy(currentPolicy));
						navigate(POLICY_CREATION_PATH.PLAN_PAYMENT);
          }
        } else {
          throw new Error('Has alcanzado el máximo de pólizas activas.')
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
        setIsLoading(false);
      }

    }

    return { isLoading, initPolicyRequest }
  }

}
import { AxiosError } from "axios";
import { Util } from "src/utils/Util";
import { OtpAdapter } from "../adapters/otp.adapter";
import { IOtpStoredTime } from "../interfaces/IOtpStoredTime";
import { OtpService } from "../services/otp.service";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";

/**
 *
 */
export namespace OtpFeature {

  /**
   * 
   * @param smsCountdown 
   * @param emailCountdown 
   */
  export const storeCurrentTime = (smsCountdown?: number, emailCountdown?: number): void => {
    if (smsCountdown === 0) {
      localStorage.setItem(Util.KEY.OTP_SMS_TIME, JSON.stringify(Date.now()));
    }

    if (emailCountdown === 0) {
      localStorage.setItem(Util.KEY.OTP_EMAIL_TIME, JSON.stringify(Date.now()));
    }
  }

  /**
   * 
   * @returns 
   */
  export const getStoredTime = (): IOtpStoredTime => {
    const smsStoredTime: number = JSON.parse(localStorage.getItem(Util.KEY.OTP_SMS_TIME)!);
    const emailStoredTime: number = JSON.parse(localStorage.getItem(Util.KEY.OTP_EMAIL_TIME)!);
    let remainingTime: IOtpStoredTime = { sms: 0, email: 0 }

    if (smsStoredTime) {
      const time = Math.round((Date.now() - smsStoredTime) / 1000);
      if (time < Util.CONSTANT.OTP.TIME) {
        remainingTime.sms = Util.CONSTANT.OTP.TIME - time;
      } else {
        localStorage.removeItem(Util.KEY.OTP_SMS_TIME);
      }
    }

    if (emailStoredTime) {
      const time = Math.round((Date.now() - emailStoredTime) / 1000);
      if (time < Util.CONSTANT.OTP.TIME) {
        remainingTime.email = Util.CONSTANT.OTP.TIME - time;
      } else {
        localStorage.removeItem(Util.KEY.OTP_EMAIL_TIME);
      }
    }

    return remainingTime;
  }

  /**
   * 
   * @param type 
   */
  export const generateCodeFeature = async (type: string, phone?: string, uuid?: string): Promise<void> => {
    try {
			if (phone && uuid) {
				const request = OtpAdapter.generateUUIDCodeRequestAdapter(type, phone, uuid);
				OtpService.generateUUIDCodeService(request);
			} else {
				const request = OtpAdapter.generateCodeRequestAdapter(type);
				OtpService.generateCodeService(request);
			}
    } catch (e) {
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }
  }

  /**
   * 
   * @param type 
   */
  export const verifyCodeFeature = async (code: string, uuid?: string): Promise<string> => {
    try {
      /** DEVELOPMENT VALIDATATION */
      if (code === '111111' && EnvironmentFactory.ENV.REACT_APP_API_ENV === 'dev') {
        return 'd3V3l0pM3Nt_t0k3N';
      /** END DEVELOPMENT VALIDATION */
      } else {
				if(uuid){
					const request = OtpAdapter.verifyUUIDCodeRequestAdapter(code, uuid);
					const { data } = await OtpService.verifyUUIDCodeService(request);
					return OtpAdapter.verifyCodeDataAdapter(data);
				} else {
					const request = OtpAdapter.verifyCodeRequestAdapter(code);
					const { data } = await OtpService.verifyCodeService(request);
					return OtpAdapter.verifyCodeDataAdapter(data);
				}
      }
    } catch (e) {
      const axiosError = e as AxiosError;
      let error = new Error();
      if (axiosError.response) {
        if (axiosError.response.status === 400) {
          error.message = `(${axiosError.response.status}) El código ingresado es inválido.`;
        } else {
          error.message = `(${axiosError.response.status}) Ha ocurrido un error.`;
        }
      } else {
        error.message = `(Timeout) Ha ocurrido un error. Revisa tu conexión de internet.`;
      }
      throw error;
    }

  }

}
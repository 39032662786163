import { Navigate } from "react-router";
import Dashboard from "../ui/pages/Dashboard";
import { DASHBOARD_PATH } from "../../../routes/paths/dashboard.paths";

/**
 * 
 */
export const DashboardRoutes = (isValidAuth: boolean, redirect: string) => {
  return {
    path: DASHBOARD_PATH.DASHBOARD,
    element : isValidAuth === true? <Dashboard /> : <Navigate to={redirect} />,
  }
}

import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { ICameraWindowSize } from "../interfaces/ICameraWindowSize";

export namespace CameraFacade {

  const ANIMATION_TIME: number = 250;

  const ANIMATION = {
    IN: `${ANIMATION_TIME}ms ease-out forwards cameraLibIn`,
    OUT: `${ANIMATION_TIME}ms ease-in forwards cameraLibOut`,
  }

  export const useCamera = (onSelect: (picture: string) => void) => {
    const [currentPicture, setCurrentPicture] = useState<string | undefined>();
    const [currentAnimation, setCurrentAnimation] = useState<string>(ANIMATION.IN)
    const webcamRef = useRef<Webcam>(null);
    const [windowSize, setWindowSize] = useState<ICameraWindowSize>({ width: 0, height: 0 });

    const takePicture = useCallback(
      () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
          setCurrentPicture(imageSrc);
        }
      }, [webcamRef]
    );

    /**
     * 
     */
    const removeCurrentPicture = (): void => {
      setCurrentPicture(undefined);
    }

    /**
     * 
     */
    const selectCurrentPicture = (onCancel: () => void): void => {
      if (currentPicture) {
        onSelect(currentPicture);
        cancelPicture(onCancel);
      }
    }

    /**
     * 
     */
    const cancelPicture = (onCancel: () => void): void => {
      setCurrentAnimation(ANIMATION.OUT);
      setTimeout(() => {
        onCancel();
      }, ANIMATION_TIME);
    }

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return { windowSize, webcamRef, takePicture, currentPicture, removeCurrentPicture, cancelPicture, selectCurrentPicture, currentAnimation };
  }


  /**
   * 
   * @returns 
   */
  export const usePermissions = () => {
    const [cameraAvailable, setCameraAvailable] = useState<boolean | undefined>(undefined);
    const [currentStream, setCurrentStream] = useState<MediaStream | undefined>(undefined);

    const verifyPermissions = useCallback(
      async () => {
        try {
          const constraints: MediaStreamConstraints = {
            video: true
          };
          const stream = await navigator.mediaDevices.getUserMedia(constraints)
          setCameraAvailable(true);
          setCurrentStream(stream);
        } catch (e) {
          setCameraAvailable(false);
          console.error(e)
        }
      }, []
    );

    useEffect(
      () => {
        return () => {
          if (currentStream) {
            currentStream.getTracks()[0].stop();
          }
        }
      }, [currentStream]
    )

    const useMountEffect = () => useEffect(
      () => {
        verifyPermissions();
      }, []
    );
    useMountEffect();

    return { verifyPermissions, cameraAvailable }
  }
}
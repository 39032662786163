import { AxiosError } from "axios";
import { Bill } from "src/classes/invoice/Bill";
import { Invoice } from "src/classes/invoice/Invoice";
import { InvoiceStatus } from "src/classes/invoice/InvoiceStatus";
import { TaxRegime } from "src/classes/invoice/TaxRegime";
import { Util } from "src/utils/Util";
import { BillAdapter } from "../adapters/bill.adapter";
import { BillService } from "../services/bill.service";

/**
 *
 */
export namespace BillFeature {

  /**
   * 
   * @param productId 
   * @returns 
   */
  export const validateInvoiceFeature = async (productId: string, token: string): Promise<InvoiceStatus | undefined> => {
    try {
      const request = BillAdapter.invoiceRequestAdapter(productId, token);
      const { data } = await BillService.validateInvoiceService(request);
      const status = BillAdapter.validateInvoiceDataAdapter(data);

      if (status.invoicesFile.length > 0) {
        for (const invoiceFile of status.invoicesFile) {
          const response = await BillService.invoiceFileService(invoiceFile.id);
          BillAdapter.invoiceFileDataAdapter(response.data, invoiceFile);
        }
      }

      return status;
    } catch (e: any | AxiosError) {
      console.error(e);
      if (e.response) {
        if (e.response.data && e.response.data.statusCode) {
          return BillAdapter.validateInvoiceFilesErrorAdapter();
        } else {
          return BillAdapter.validateInvoiceErrorAdapter();
        }
      } else {
        throw new Error('Ha ocurrido un error.')
      }
    }
  }

  /**
   * 
   * @param productId 
   * @returns 
   */
  export const invoiceDetailsFeature = async (productId: string, token: string): Promise<Invoice | undefined> => {
    try {
      const request = BillAdapter.invoiceRequestAdapter(productId, token);
      const { data } = await BillService.invoiceDetailsService(request);
      const invoice = BillAdapter.invoiceDetailsAdapter(data);

      return invoice;

    } catch (e) {
      console.error(e);
      throw new Error('Ha ocurrido un error.')
    }
  }

  export const invoicePersonInfoFeature = async (token: string): Promise<Bill[] | undefined> => {
    try {
      const { data } = await BillService.invoicePersonInfoService(token);
      const list = BillAdapter.invoicePersonInfoAdapter(data);
      return list;
    } catch (e: any | AxiosError) {
      if (e.response) {
        if ((e as AxiosError).response?.status === 404) {
          return [];
        }
      } else {
        console.error(e);
        throw new Error('Ha ocurrido un error.')
      }
    }
  }

  /**
   * 
   * @returns 
   */
  export const taxRegimensListFeature = async (): Promise<TaxRegime[] | undefined> => {
    try {
      const { data } = await BillService.taxRegimesList();
      const taxRegimes = BillAdapter.taxRegimesListDataAdapter(data);
      return taxRegimes;
    } catch (e) {
      console.error(e);
      throw new Error('Ha ocurrido un error.')
    }
  }

  /**
   * 
   * @returns 
   */
  export const createInvoiceFeature = async (bill: Bill, productId: string, token: string): Promise<void> => {
    try {
      const request = BillAdapter.createInvoiceRequestAdapter(bill, productId);
      await BillService.createInvoiceService(request, token);
    } catch (e: any | AxiosError) {
      console.error(e);
      let error: Error = new Error('Ha ocurrido un error.');
      error.name = '';
      if (e.response) {
        if ((e as AxiosError).response?.data.code === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.NAME) {
          error.name = Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.NAME.toString();
        } else if ((e as AxiosError).response?.data.code === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.RFC) {
          error.name = Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.RFC.toString();
        } else if ((e as AxiosError).response?.data.code === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.REGIME) {
          error.name = Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.REGIME.toString();
        } else if ((e as AxiosError).response?.data.code === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.POSTAL_CODE) {
          error.name = Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.POSTAL_CODE.toString();
        } else if ((e as AxiosError).response?.data.code === Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.REGIME_CDFI) {
          error.name = Util.STATUS_CODE.INVOICE_CREATION_ERROR_CODE.REGIME_CDFI.toString();
        } else if ((e as AxiosError).response?.data.code === 400) {
          error.message = (e as AxiosError).response?.data.response.message;
          // console.log((e as AxiosError).response?.data.response.message.replaceAll(`'`, `"`));
          // const serverErrorObject = JSON.parse((e as AxiosError).response?.data.response.message.replaceAll(`'`, `"`));
          // console.log(serverErrorObject)
        }
        

        throw error;
      }

      throw error;
    }

  }
}
import { INIT_PATH } from "src/routes/paths/init.paths";
import InitPage from '../ui/pages/Init';

/**
 *
 */
export const InitRoutes = (/* Auth validation and redirect params if needed */) => {
  return {
    path: INIT_PATH.INIT,
    element: <InitPage />,
    //Optional children : []
  }
}
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { ICreateDamagePaymentRequest } from "../interfaces/ICreateDamagePaymentRequest";
import { ICreateDamagePaymentResponse } from "../interfaces/ICreateDamagePaymentResponse";
import { IUpdatePersonalDataRequest } from "../interfaces/IUpdatePersonalDataRequest";
import { IUpdateVehicleDataRequest } from "../interfaces/IUpdateVehicleDataRequest";
import { IDamageClaimDetailsResponse } from "../interfaces/IDamageClaimDetailsResponse";

/**
 *
 */
export namespace ClaimPaymentsService {

	/**
	 * 
	 * @param damageClaim 
	 * @returns 
	 */
	export const getDamageClaimByIdService = (damageClaim: string): AxiosPromise<IDamageClaimDetailsResponse> => {
		const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;

		return EnvironmentFactory.Http.SecureHttp(baseURL).get(Util.API_PATH.GET_DAMAGE_PAYMENT_BY_ID(damageClaim));
	};

	/**
	 * 
	 * @param damageClaim 
	 * @returns 
	 */
	export const getAgreedClaimByIdService = (damageClaim: string): AxiosPromise<IDamageClaimDetailsResponse> => {
		const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;

		return EnvironmentFactory.Http.SecureHttp(baseURL).get(Util.API_PATH.GET_AGREED_PAYMENT_BY_ID(damageClaim));
	};

	/**
	 * 
	 * @param data 
	 * @param isAuthenticated 
	 * @returns 
	 */
	export const createDamagePaymentService = (data: ICreateDamagePaymentRequest, isAuthenticated: boolean): AxiosPromise<ICreateDamagePaymentResponse> => { //CHANGE IT ANY
		const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;
		
		if(isAuthenticated)
			return EnvironmentFactory.Http.SecureHttp(baseURL).post(Util.API_PATH.DAMAGE_PAYMENT, data)
		else
			return EnvironmentFactory.Http.PublicHttp(baseURL).post(Util.API_PATH.DAMAGE_PAYMENT, data);
	};

	/**
	 * 
	 * @param data 
	 * @param isAuthenticated 
	 * @returns 
	 */
	export const createAgreedPaymentService = (data: ICreateDamagePaymentRequest, isAuthenticated: boolean): AxiosPromise<ICreateDamagePaymentResponse> => { //CHANGE IT ANY
		const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;
		
		if(isAuthenticated)
			return EnvironmentFactory.Http.SecureHttp(baseURL).post(Util.API_PATH.AGREED_PAYMENT, data)
		else
			return EnvironmentFactory.Http.PublicHttp(baseURL).post(Util.API_PATH.AGREED_PAYMENT, data);
	};

	/** */
	export const updatePersonalDataService = (data: IUpdatePersonalDataRequest, damagePaymentID: string): AxiosPromise<{}> => {
		const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;
		const requirement: string = 'personal_info';
		
		return EnvironmentFactory.Http.SecureHttp(baseURL).put(Util.API_PATH.CLAIMS_UPDATE_USER(damagePaymentID, requirement), data);
	};

	/** */
	export const updateVehicleDataService = (data: IUpdateVehicleDataRequest, damagePaymentID: string): AxiosPromise<{}> => {
		const baseURL: string = EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API;
		const requirement: string = 'vehicle_info';
		
		return EnvironmentFactory.Http.SecureHttp(baseURL).put(Util.API_PATH.CLAIMS_UPDATE_VEHICLE(damagePaymentID, requirement), data);
	};
}
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingFactory } from "src/factory/loading.factory"
import { DocumentSignatureFeature } from "../features/document-signature.feature";
import { DocumentSignature } from "src/classes/document/DocumentSignature";
import { useForm } from "react-hook-form";
import { IDocumentSignatureForm } from "../interfaces/IDocumentSignatureForm";
import { DOCUMENT_SIGNATURE_PAGES, COMMON_PATH } from "src/routes/paths/common.paths";

export namespace DocumentSignatureFacade {

  export const useDocumentSignature = () => {
    const [currentDocument, setCurrentDocument] = useState<DocumentSignature>();
    const { documentType, id, key, page } = useParams<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const form = useForm<IDocumentSignatureForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
          confirmation: false,
        }
      }
    )
    const loading = LoadingFactory.useLoader(true);
    const updating = LoadingFactory.useLoader(false);

    /**
     * 
     */
    const getDocument = useCallback(
      async () => {
        try {
          if (id && key && !currentDocument) {
            const document = await DocumentSignatureFeature.getClaimFormsDocumentFeature(id, key);
            if (document) {
              setCurrentDocument(document);
            }
            loading.set(false);
          }
        } catch (e) {

        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [loading, id, key]
    )

    /**
     * 
     * @param pageToNavigate 
     * @param replace 
     */
    const changePage = (direction: 'forward' | 'replace', pageToNavigate: string): void => {
      navigate(`${COMMON_PATH.DOCUMENT_SIGUNATURE}/${pageToNavigate}/${documentType}/${id}/${key}`, { replace: direction === 'replace' });
    }

    /**
     * 
     * @param page 
     */
    const sendRequest = (completed?: boolean): Promise<void> => {
      return new Promise<void>(
        async (resolve, reject) => {
          try {
            if (currentDocument) {
              currentDocument.signature = form.getValues('signature');

              const newDocument = await DocumentSignatureFeature.claimFormsDocumentFeature(currentDocument, completed);
              if (newDocument) {
                setCurrentDocument(newDocument);
              }
              resolve();
            }
          } catch (e) {
            reject();
          }
        }
      )
    }

    useEffect(
      () => {
        if (currentDocument && currentDocument.completed && !location.pathname.includes(DOCUMENT_SIGNATURE_PAGES.COMPLETED)) {
          navigate(`${COMMON_PATH.DOCUMENT_SIGUNATURE}/${DOCUMENT_SIGNATURE_PAGES.COMPLETED}/${documentType}/${id}/${key}`, { replace: true });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentDocument, location, navigate]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (documentType && id && key && page) {
          getDocument();
        }
      }, [documentType, id, key, page, getDocument]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (!page && documentType && id && key) {
          navigate(`${COMMON_PATH.DOCUMENT_SIGUNATURE}/${DOCUMENT_SIGNATURE_PAGES.APPROVAL}/${documentType}/${id}/${key}`, { replace: true });
        }
      }, [page, documentType, id, key, navigate]
    )

    return { loading, updating, currentDocument, form, page, changePage, sendRequest }
  }
}
import { IconFactory } from "src/factory/icon.factory";
import { PictureManagementFacade } from "../../../controller/facades/picture-management.facade";
import './PictureDropZone.scss';

/**
 *
 */
interface IPictureDropZone {

  onDropComplete: (file: File) => void
}

/**
 *
 */
const PictureDropZone = (props: IPictureDropZone): JSX.Element => {
  const { dragOver, onDrop, onDragOver } = PictureManagementFacade.useDropZone(props.onDropComplete);

  return (
    <div className="picture-drop-zone absolute width-100 height-100" drag-overlay={dragOver ? '' : undefined}
      onDrop={(e) => { e.preventDefault(); onDrop(e.dataTransfer.files[0]); }}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={(e) => { e.preventDefault(); onDragOver(false) }}
      onDragEnter={(e) => { e.preventDefault(); onDragOver(true) }}
    >
      {
        dragOver &&
        <div className="drop-zone-icon flex justify-center align-center column width-100 height-100">
          <p>Suelta la im&aacute;gen aqu&iacute;</p>
          {IconFactory.dropFileIcon()}
        </div>
      }
    </div>
  );
};

export default PictureDropZone;
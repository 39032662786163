import PlanSummaryManagement from "src/libs/PlanSummaryManagement/ui/template/PlanSummaryManagement";
import { PlanReviewRenewalFacade } from "src/modules/policy-renewal/controller/facades/plan-review-renewal.facade";
import { PolicyRenewalReturnValidactionFacade } from "src/modules/policy-renewal/controller/facades/policy-renewal-return-validation.facade";
import './PlanReviewRenewal.scss';

/**
 *
 */
const PlanReviewRenewalPage = (): JSX.Element => {
  const { onReturn, steps } = PolicyRenewalReturnValidactionFacade.useReturnValidation();
  const { plan, user, currentPolicy, initPolicyRenewalRequest, isLoading } = PlanReviewRenewalFacade.usePlanReviewRenewal();

  return (
    <div id="plan-review-renewal-wrapper">

      {
        plan && user && currentPolicy.vehicle &&
        <>
          <PlanSummaryManagement
            user={user}
            plan={plan}
            isLoading={isLoading}
            vehicle={currentPolicy.vehicle}
            onComplete={() => initPolicyRenewalRequest()}
            onBackButton={() => onReturn(steps.review)}
          />
        </>
      }
    </div>
  );
};

export default PlanReviewRenewalPage;
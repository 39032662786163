import { COMMON_PATH } from "src/routes/paths/common.paths"
import EndorsementIdentityCompletedPage from "../ui/pages/EndorsementIdentityCompleted/EndorsementIdentityCompleted"
import UserEmailValidationPage from "../ui/pages/UserEmailValidation/UserEmailValidation"
import DocumentSignaturePage from "../ui/pages/DocumentSignature/DocumentSignature"

/**
 *
 */
export const CommonRoutes = () => {
  return {
    path: '',
    children : [
      { path: COMMON_PATH.INDEITY_COMPLETED, element: <EndorsementIdentityCompletedPage /> },
      { path: COMMON_PATH.USER_EMAIL_VALIDATION, element: <UserEmailValidationPage /> },
      { path: `${COMMON_PATH.DOCUMENT_SIGUNATURE}/:documentType/:id/:key`, element: <DocumentSignaturePage /> },
      { path: `${COMMON_PATH.DOCUMENT_SIGUNATURE}/:page/:documentType/:id/:key`, element: <DocumentSignaturePage /> },
    ]
  }
}
import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects'
import { Person  } from 'src/classes/Person';
import { CoreTokenStorageFeature } from 'src/core/features/token-storage.feature';
import { CoreUserInfoFeature } from 'src/core/features/user-info.feature';
import { CoreAuthSlice } from 'src/core/slices/auth.slice';
import { CoreInstanceSlice } from 'src/core/slices/instance.slice';
import { MainAuthAction } from '../actions/auth.action';
import { MainCurrentUserAction } from '../actions/current-user.action';
import { MainAuthSelector } from '../selectors/auth.selector';

export namespace MainAuthValidationSaga {

  type CurrentUser = SagaReturnType<typeof CoreUserInfoFeature.getUserInfoFeature>;

  /**
   * 
   */
  function* authValidation() {
    const isValid =  CoreTokenStorageFeature.verifyTokenFeature();
    yield put(CoreAuthSlice.actions.setAuthValidation(isValid as boolean));
  }

  /**
   * 
   */
	function* getUserInfo() {
    try {
      const isValidAuth: boolean = yield select(MainAuthSelector.isValidAuth);
      if (isValidAuth) {
        const user: CurrentUser = yield call(CoreUserInfoFeature.getUserInfoFeature);
        yield put(CoreInstanceSlice.actions.currentUser(user as Person));
      }
    } catch (e) {

    }
  }

  /**
   * 
   */
  export function* authValidationSaga() {
    yield takeLatest(MainAuthAction.authValidation, authValidation);
    yield takeLatest(MainCurrentUserAction.userInfo, getUserInfo);
  }
}


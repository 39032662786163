import { AxiosError } from "axios";
import { Plan } from "src/classes/plan/Plan";
import { PlanManagementAdapter } from "../adapters/plan-management.adapter";
import { IPlanManagement } from "../interfaces/IPlanManagement";
import { PlanManagementService } from "../services/plan-management.service";

/**
 *
 */
export namespace PlanManagementFeature {
  /**
   * 
   * @param vehicle 
   * @param user 
   * @param coupon 
   * @returns 
   */
  export const getQuotationFeature = async (management: IPlanManagement): Promise<Plan[] | undefined> => {
    try {
      const requestData = PlanManagementAdapter.quotationRequestAdapter(management.vehicle, management.user, management.coverages, management.coupon, management.isRenewal);
      const { data } = await PlanManagementService.quotationService(requestData);
      const plans: Plan[] = PlanManagementAdapter.quotationPlanDataAdapter(data);
      return plans;
    } catch (e: any | AxiosError) {
      console.error(e);
      if (e.response) {
        let error = new Error();
        const axiosError = e as AxiosError<TErrorServer>;
        const message = axiosError.response?.data.message;
        if (message?.includes("coupon") && message?.includes("doesn't exists")) {
          error = {
            message: 'El cupón ingresado no existe.',
            name: '4000'
          }
        } else if (message?.includes('coupon') && message?.includes('invalid')) {
          error = {
            message: 'El cupón ingresado es inválido.',
            name: '4000'
          }
        }

        throw error;
      } else {
        throw e;
      }
    }
  }

}
import ESignCanvas from "../components/ESignCanvas/ESignCanvas";
import { ESignFacade } from "../../controller/facades/e-sign.facade";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

import './ESign.scss';
import { Spinner, Text } from "src/components";

const PORTRAIT_ORIENTATION = '(orientation: portrait)';

/**
 *
 */
interface IESign {

  onApply?: (eSign?: string) => void;

  defaultImage?: string;
}

/**
 *
 */
const ESign = (props: PropsWithChildren<IESign>): JSX.Element => {
  const { setCurrentSign } = ESignFacade.useESign();
  const eSignRef = useRef<HTMLDivElement>(null);
  const [redAdquired, setRefAdquired] = useState<boolean>(false);
  const [isPortrait, setIsPortrait] = useState<boolean>(window.matchMedia(PORTRAIT_ORIENTATION).matches);
  const [isLoadingLayout, setIsLoadingLayout] = useState<boolean>(false);

  /**
   * 
   * @param eSign 
   */
  const onApply = (eSign?: string): void => {
    if (eSign && eSign !== '') {
      setCurrentSign(eSign);
    }

    if (props.onApply) {
      props.onApply(eSign);
    }
  }

  useEffect(
    () => {
      if (eSignRef) {
        setRefAdquired(true);
      }
    }, []
  )

  /**
   * 
   */
  useEffect(
    () => {
      const portraitListener = (e: MediaQueryListEvent) => {
        const isPortrait = e.matches;
        setIsPortrait((currentPortrait) => {
          if (currentPortrait !== isPortrait)
            setIsLoadingLayout(true);

          return isPortrait;
        })
      };

      window.matchMedia(PORTRAIT_ORIENTATION).addEventListener("change", portraitListener);

      return () => {
        window.matchMedia(PORTRAIT_ORIENTATION).removeEventListener("change", portraitListener);
      };
    }, []
  );

  useEffect(() => {
    if (isLoadingLayout)
      setTimeout(() => setIsLoadingLayout(false), 1000);
  }, [isLoadingLayout])

  return (
    <>
      <div className="eSign"
        ref={eSignRef}
      >
        {
          eSignRef.current !== null && redAdquired && !isLoadingLayout &&
          <ESignCanvas onApply={onApply} wrapperElement={eSignRef.current} defaultImage={props.defaultImage} orientation={isPortrait ? 'portrait' : 'landscape'}>
            {props.children}
          </ESignCanvas>
        }

        {
          isLoadingLayout &&
          <div className="eSign__resizeInfo">
            <Text type="paragraph" align="center">Espera mientras reajustamos el lienzo para firmar...</Text>
            <Spinner color="main"></Spinner>
          </div>
        }
      </div>
    </>
  );
};

export default ESign;
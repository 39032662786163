import { Button } from "src/components";
import { PolicyRenewalFacade } from "src/modules/policy-renewal/controller/facades/policy-renewal.facade";
import { Util } from "src/utils/Util";
import './RenowalCompletion.scss';

/**
 *
 */
const RenowalCompletionPage = (): JSX.Element => {
  const { finishPolicyRenewal, query } = PolicyRenewalFacade.useRenewalCompletion();
  const { currentPolicy } = PolicyRenewalFacade.usePolicyRenewalActors();

  return (
    <div id="renewal-completion-wrapper" className="flex column align-center justify-center height-100">
      <section className="text-center">
        <img alt="policy-completion" src={Util.ASSET.POLICY_COMPLETION_CAR} />
        <h1>¡Tu renovación ha sido realizada con &eacute;xito!</h1>
        <h2>En un momento recibirás en tu correo <span>{query.get('user')}</span> el detalle de tu renovación. </h2>
        <p className="policy-completion-preview">
          N. P&oacute;liza: {query.get('policy')}
        </p>
        {
          currentPolicy.isExpired() ?
            <h3>Recuerda que la póliza renovada estará activa a partir del d&iacute;a de ma&ntilde;ana.</h3>
            :
            <h3>Recuerda que la póliza renovada estará activa a partir de las 11:59 PM del {Util.TRANSFORM.DATE.formatDate(currentPolicy.expirationDate!)}</h3>
        }
        <div className="button-wrapper">
          <Button type="button" onClick={finishPolicyRenewal}>Ver p&oacute;lizas</Button>
        </div>
      </section>
    </div>
  );
};

export default RenowalCompletionPage;
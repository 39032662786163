import classNames from "classnames";
import { InitFacade } from "../../controller/facades/init.facade";
import './Init.scss';

/**
 *
 */
const InitPage = (): JSX.Element => {
  const { init } = InitFacade.useInitRedirect();



  return (
    <div className="init-wrapper flex align-center justify-center">
      <div className={classNames({
        'svg-loader': true,
        'rotate': !init,
        'present': init,
      })}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 22 24" fill="none">
          <path d="M0 12.2184C0 5.79807 4.843 0.616104 12.4501 0.616104C16.0911 0.616104 18.9074 1.59226 21.4393 4.11352C21.8516 4.50955 21.8109 4.95579 21.3928 5.35183L17.0608 9.60227C16.6485 9.99831 16.184 9.95927 15.7717 9.4684C14.8948 8.49225 13.9251 8.18546 12.6359 8.18546C10.377 8.18546 8.80915 9.95927 8.80915 12.2184C8.80915 14.4775 10.377 16.3349 12.6359 16.3349C13.9251 16.3349 14.8948 15.9779 15.7717 15.0074C16.184 14.5221 16.6485 14.4775 17.0608 14.8735L21.3928 19.1239C21.8051 19.52 21.8516 19.9662 21.3928 20.4069C18.9016 22.8891 16.0446 23.9099 12.4443 23.9099C4.83719 23.9099 0 18.6833 0 12.2184Z" fill="#0D4EC9" />
        </svg>
      </div>

      <div className="logo-letters-wrapper">
        <svg className={`letter-r ${init === true? 'translation' : ''}`} xmlns="http://www.w3.org/2000/svg" width="20" height="26" viewBox="0 0 17 23" fill="none">
          <path d="M15.8412 1.37862C15.7657 1.32284 15.6728 1.27264 15.5682 1.22802C15.5276 1.21686 15.4869 1.20571 15.4463 1.18897C15.4405 1.18897 15.4405 1.18339 15.4347 1.18339C12.0724 -0.116283 10.1562 1.1555 9.80774 1.37305C9.45932 1.59617 8.42568 2.47749 7.61852 1.59617C7.15396 1.08857 6.86361 0.809668 6.69521 0.66464C6.64295 0.608859 6.58488 0.558657 6.5152 0.525189C6.37583 0.447097 6.19581 0.408051 5.97515 0.408051H1.22506C0.626945 0.408051 0.301758 1.16108 0.301758 1.73562V20.7789C0.301758 21.3535 0.626945 22.1065 1.22506 22.1065H6.78231C7.38043 22.1065 7.70562 21.7941 7.70562 21.2196V9.79584C7.70562 8.16706 8.28051 7.22438 9.28511 6.90643C9.29092 6.90643 9.29673 6.90085 9.29673 6.90085C9.49416 6.8395 9.70902 6.80603 9.9413 6.78929C10.5917 6.83392 11.4047 7.2188 11.997 7.45865C12.4151 7.63157 12.7228 7.53117 12.9203 7.40287C13.0306 7.35825 13.1235 7.269 13.199 7.12955C13.2048 7.12397 13.2048 7.1184 13.2048 7.1184L13.9016 5.91355L16.1141 2.37709C16.1547 2.24322 16.1663 2.10934 16.1605 1.98105C16.2651 1.78024 16.178 1.57385 15.8412 1.37862Z" fill="#0D4EC9" />
        </svg>
        <svg className={`letter-a ${init === true? 'translation' : ''}`} xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 26 24" fill="none">
          <path d="M23.9292 1.40784H19.2953C19.2488 1.40784 19.2082 1.40784 19.1675 1.41342C17.8726 1.50267 17.6635 2.50671 17.6635 2.50671L17.5764 2.60711C15.904 1.31301 13.2734 0.610184 10.9274 0.610184C5.67215 0.610184 0.509766 5.39612 0.509766 12.2571C0.509766 18.7722 5.16114 23.4131 10.1319 23.8705H10.1377C13.8599 24.4394 17.2512 22.5262 17.2512 22.5262C17.2512 22.5262 17.71 22.1915 17.8261 22.3031C17.9422 22.4146 18.6158 23.1119 18.6158 23.1119H18.8423H21.7284H23.935C24.5331 23.1119 25.2009 22.7995 25.2009 22.225V2.73541C25.1951 2.16087 24.5273 1.40784 23.9292 1.40784ZM13.0296 7.52691C15.7472 7.52691 17.6635 9.46806 17.6635 12.2292C17.6635 14.9903 15.7472 16.937 13.0296 16.937C10.3119 16.937 8.39561 14.9959 8.39561 12.2292C8.40141 9.46806 10.3177 7.52691 13.0296 7.52691Z" fill="#0D4EC9" />
        </svg>
        <svg className={`letter-b ${init === true? 'translation' : ''}`} xmlns="http://www.w3.org/2000/svg" width="27" height="39" viewBox="0 0 26 33" fill="none">
          <path d="M25.2818 21.2579C25.2818 14.5029 20.282 9.76162 15.1139 9.61101C15.0442 9.60543 14.9571 9.60543 14.8642 9.60543C13.7899 9.60543 12.7098 9.75046 11.6994 10.0349C10.2883 10.364 9.19658 10.8159 8.56943 11.1505C8.01196 11.4462 7.82033 11.2844 7.76226 11.0725V0.998567C7.76226 0.424032 7.43707 0.111664 6.83895 0.111664H1.2817C0.683586 0.111664 0.358398 0.424032 0.358398 0.998567V31.2258C0.358398 31.8003 0.683586 32.1127 1.2817 32.1127H6.18858H6.38021H6.56022C6.56022 32.1127 6.61248 32.1015 6.68797 32.0792C6.69378 32.0792 6.69378 32.0792 6.69959 32.0737C6.7054 32.0737 6.7112 32.0681 6.71701 32.0681C6.74024 32.0625 6.76346 32.0569 6.78669 32.0458C6.98993 31.9844 7.27448 31.8784 7.41384 31.7446C7.64612 31.5214 7.87839 31.1979 8.45328 31.5828C9.02817 31.9677 12.1813 33.5853 16.3682 32.7765C16.374 32.7765 16.374 32.7765 16.3798 32.7765C21.066 31.9677 25.2818 27.4606 25.2818 21.2579ZM13.0814 25.9378C10.3638 25.9378 8.44748 23.9967 8.44748 21.23C8.44748 18.4689 10.3638 16.5277 13.0814 16.5277C15.7991 16.5277 17.7154 18.4689 17.7154 21.23C17.7154 23.9911 15.7991 25.9378 13.0814 25.9378Z" fill="#0D4EC9" />
        </svg>
        <svg className={`letter-i ${init === true? 'translation' : ''}`} xmlns="http://www.w3.org/2000/svg" width="10" height="38" viewBox="0 0 9 33" fill="none">
          <path d="M4.77828 7.99886C7.16494 7.99886 8.99994 6.23064 8.99994 3.94365C8.99994 1.76265 7.15913 0 4.77828 0C2.39162 0 0.556624 1.76823 0.556624 3.94365C0.550817 6.23621 2.39162 7.99886 4.77828 7.99886Z" fill="#0D4EC9" />
          <path d="M8.52361 11.7636C8.52361 10.9994 7.87903 10.3802 7.08348 10.3802C7.06025 10.3802 7.03702 10.3802 7.0138 10.3858C6.99057 10.3858 6.96734 10.3802 6.94412 10.3802H2.71665C2.68181 10.3802 2.64696 10.3858 2.61212 10.3858C2.56567 10.3802 2.51921 10.3802 2.46695 10.3802C1.67139 10.3802 1.02682 10.9994 1.02682 11.7636C1.02682 11.9142 1.02682 12.405 1.02682 12.5222V30.4834C1.02682 30.5168 1.02682 30.6507 1.02682 30.6897C1.02682 31.4539 1.67139 32.0731 2.46695 32.0731C2.5134 32.0731 2.56567 32.0731 2.61212 32.0675C2.64696 32.0731 2.68181 32.0731 2.71665 32.0731H6.94412C6.96734 32.0731 6.99057 32.0731 7.0138 32.0675C7.03702 32.0675 7.06025 32.0731 7.08348 32.0731C7.87903 32.0731 8.52361 31.4539 8.52361 30.6897V11.7636Z" fill="#0D4EC9" />
        </svg>
      </div>
    </div>
  );
};

export default InitPage;
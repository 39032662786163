import { AxiosPromise } from "axios"
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";

export namespace PolicyDocumentService {

  /**
   * 
   * @param policyNumber 
   * @returns 
   */
  export const policyTermsService = (policyNumber: string): AxiosPromise<Blob> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_DOCUMENT_TERMS(policyNumber),
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf'
        }
      }
    );
  }

  /**
   * 
   * @param policyNumber 
   * @returns 
   */
  export const policyTermsSumaryService = (policyNumber: string): AxiosPromise<Blob> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_DOCUMENT_TERMS_SUMARY(policyNumber),
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf'
        }
      }
    );
  }

  /**
   * 
   * @param policyId 
   * @returns 
   */
  export const policyCoverService = (policyId: string): AxiosPromise<Blob> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_DOCUMENT_COVER(policyId),
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf'
        }
      }
    );
  }

  /**
   * 
   * @param policyId 
   * @returns 
   */
  export const policyReceiptService = (policyId: string): AxiosPromise<Blob> => {
    return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.POLICY_DOCUMENT_RECEIPT(policyId),
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf'
        }
      }
    );
  }
}
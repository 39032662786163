import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Person  } from "src/classes/Person";
import { AlertFactory } from "src/factory/alert.factory";
import { POLICY_CREATION_PATH } from "src/routes/paths/policy-creation.paths";
import { PolicyCreationAction } from "../actions/policy-creation.action";
import { PolicyDriverDataAction } from "../actions/policy-driver-data.action";
import { PolicyDriverFeature } from "../features/policy-driver.feature";
import { PolicyDriverDataSaga } from "../sagas/policy-driver-data.saga";
import { PolicyDriverDataSelector } from "../selectors/policy-driver-data.selector";
import { PolicyDriverDataSlice } from "../slices/policy-driver-data.slice";

export namespace PolicyDriverFacade {

  /**
   * 
   */
  export const usePolicyDriver = () => {
    useInjectSaga({ key: 'driverData', saga: PolicyDriverDataSaga.driverDataSaga });
    useInjectReducer({ key: 'driverData', reducer: PolicyDriverDataSlice.reducer });

    const storedDriver = useSelector(PolicyDriverDataSelector.storedDriver);
    const [currentDriver, setCurrentDriver] = useState<Person | undefined>();
    const [isCreatingDriver, setIsCreatingDriver] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    /**
     * 
     * @param person 
     */
    const setDriver = useCallback(
      (person?: Person) => {
        //FIXME Evaluar llama en useEffect para actualizar person
        if (person) {
          setCurrentDriver(person);
        } else if (person === undefined && currentDriver) {
          setCurrentDriver(undefined);
        }
      }, [currentDriver],
    );


    /**
     * 
     */
    const createDriver = async (): Promise<void> => {
      try {
        if (currentDriver) {
          setIsCreatingDriver(true);
          const id = await PolicyDriverFeature.createDriverFeature(currentDriver);
          currentDriver.id = id as string;
          dispatch(PolicyCreationAction.currentDriver(currentDriver));
          dispatch(PolicyDriverDataAction.getDriverStored());
          navigate(POLICY_CREATION_PATH.PLAN_REVIEW);
        }
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
        setIsCreatingDriver(false);
      }
    }

    const useMountEffect = () => useEffect(
      () => {
        dispatch(PolicyDriverDataAction.getDriverStored());
      }, []
    );
    useMountEffect();

    return { currentDriver, isCreatingDriver, setDriver, createDriver, storedDriver }
  }
}
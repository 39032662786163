import { RootState } from "src/store/store";

/**
 *
 */
export namespace PlanManagementSelector {

  export const policyPlans = (state: RootState) => state.planManagement?.plansList!;

  export const coverages = (state: RootState) => state.planManagement?.coverages!;

  export const isUpdatingPlan = (state: RootState) => state.planManagement?.isUpdating!;

  export const isCustomizingPlan = (state: RootState) => state.planManagement?.isCustomizing!;

  export const isLoadingPlan = (state: RootState) => state.planManagement?.isLoading!;

  export const currentCoupon = (state: RootState) => state.planManagement?.coupon!;

  export const currentUser = (state: RootState) => state.planManagement?.user!;

  export const currentVehicle = (state: RootState) => state.planManagement?.vehicle!;

  export const isRenewal = (state: RootState) => state.planManagement?.isRenewal;

  export const currentPlan = (state: RootState) => state.planManagement?.currentPlan!;

  export const currentRecomendedPlan = (state: RootState) => state.planManagement?.currentRecomendedPlan!;
}
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AGREED_PAYMENTS_PATH } from "src/routes/paths/agreed-payments.paths";
import { CLAIM_PAYMENTS_PATH } from "src/routes/paths/claim-payments.paths";
import { ClaimPaymentsAction } from "../actions/claim-payments.action";
import { ClaimPaymentsFacade } from "./claim-payments.facade";

const CLAIM_CURRENT_PATH: string = CLAIM_PAYMENTS_PATH.ATTORNEY_LETTER;
const AGREED_CURRENT_PATH: string = AGREED_PAYMENTS_PATH.ATTORNEY_LETTER;

/**
 *
 */
export namespace AttorneyLetterFacade {

	/** */
	export const useAttorneyLetterFacade = () => {
		/** */
		const { currentDamageClaim, isAgreedPayment } = ClaimPaymentsFacade.useDamageClaimActors();
		const [picturesValidation, updatePicturesValidation] = useState(false);
		const dispatch = useDispatch();

		/**
		 * 
		 */
		const validatePictures = useCallback(() => {
			const idDocumentsCondition: boolean = currentDamageClaim?.attachmentLegal.source.length! > 0;

			updatePicturesValidation(idDocumentsCondition);
		}, [updatePicturesValidation, currentDamageClaim]);

		useEffect(() => {
			dispatch(ClaimPaymentsAction.updateCurrentPath( isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH));
			validatePictures();
		}, [dispatch, currentDamageClaim, validatePictures, isAgreedPayment]);

		return { currentDamageClaim, picturesValidation, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, isAgreedPayment, validatePictures };
	};

}
import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IPolicyCreationPersonData } from "../interfaces/IPolicyCreationPersonData";
import { IPolicyCreationPersonRequest } from "../interfaces/IPolicyCreationPersonRequest";

export namespace PolicyDriverService {

  /**
   * 
   * @param params 
   */
   export const createDriverService = (data: IPolicyCreationPersonRequest): AxiosPromise<IPolicyCreationPersonData> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.CREATE_DRIVER, data);
  }
}
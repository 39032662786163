import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { Endorse } from "src/classes/policy/Endorse";
import { Person  } from "src/classes/Person";

/**
 *
 */
export namespace PolicyEndorsementsAction {

  export const clear: ActionCreatorWithoutPayload = createAction('CLEAR_POLICY_ENDORSEMENT');
  
  export const policyEndorsement: ActionCreatorWithPayload<string> = createAction('POLICY_ENDORSEMENT');

  export const endorsementVerification: ActionCreatorWithoutPayload = createAction('ENDORSEMENT_VERIFICATION');

  export const endorsementCompleted: ActionCreatorWithoutPayload = createAction('ENDORSEMENT_COMPLETED');

  export const newEndorsements: ActionCreatorWithPayload<Endorse[]> = createAction('NEW_ENDORSEMENTS');

	export const updateUserStatus: ActionCreatorWithPayload<Person> = createAction('UPDATE_USER_STATUS');
}
import { RootState } from "src/store/store";

/**
 *
 */
export namespace ClaimPaymentsSelector {
	
	export const currentPolicy = (state: RootState) => state.claimPayments?.policyByNumber;

	export const currentPath = (state: RootState) => state.claimPayments?.currentPath;

	export const currentDamageClaim = (state: RootState) => state.claimPayments?.claimPayment;

	export const claimUser = (state: RootState) => state.claimPayments?.claimUser;

	export const claimVehicle = (state: RootState) => state.claimPayments?.claimVehicle;

	export const currentVehicle = (state: RootState) => state.claimPayments?.vehicle!;

	export const dataStep = (state: RootState) => state.claimPayments?.dataStep!;

	export const picturesStep = (state: RootState) => state.claimPayments?.picturesStep;

	export const documentsStep = (state: RootState) => state.claimPayments?.documentsStep;

	export const accountStep = (state: RootState) => state.claimPayments?.accountStep;

	export const proposalAvailable = (state: RootState) => state.claimPayments?.proposalAvailable;

	export const isAgreedPayment = (state: RootState) => state.claimPayments?.isAgreedPayment;

	export const isLoadingDamageClaim = (state: RootState) => state.claimPayments?.isLoadingDamageClaim;
}
import { PaymentMethod } from "src/classes/policy/PaymentMethod"
import { PaymentRecord } from "src/classes/policy/PaymentRecord";
import { ICardForm } from "../interfaces/ICardForm";
import { ICardtokenRequest } from "../interfaces/ICardTokenRequest";
import { IPaymentMethodData } from "../interfaces/IPaymentMethodData"
import { IPolicyNextPaymentData } from "../interfaces/IPolicyNextPaymentData";
import { IPolicyPaymentHistoryData } from "../interfaces/IPolicyPaymentHistoryData";
import { IUpdateCardRequest } from "../interfaces/IUpdateCardRequest";

export namespace PolicyPaymentAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const paymentMethodDataAdapter = (data: IPaymentMethodData): PaymentMethod => {
    let method: PaymentMethod = new PaymentMethod(data.card.card_number.substring(data.card.card_number.length - 4, data.card.card_number.length));
    method.brand = data.card.brand;
    method.provider = data.card.provider;
    return method;
  }

  /**
   * 
   * @param tokenId 
   * @returns 
   */
  export const paymentMethodRequestAdapter = (tokenId: string): IUpdateCardRequest => {
    let request: IUpdateCardRequest = {
      device_session_id: 'UND3F1N3D',
      encoded_card: tokenId,
      cvv: ''
    }

    return request;
  }

  /**
   * 
   * @param card 
   * @returns 
   */
  export const paymentMethodTokenByFormAdapter = (card: ICardForm): ICardtokenRequest => {
    let request: ICardtokenRequest = {
      card_number: card.cardNumber,
      cvv2: card.cvv,
      expiration_month: card.month.toString(),
      expiration_year: card.year.toString(),
      holder_name: card.owner
    }

    return request
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const paymentHistoryDataAdapter = (data: IPolicyPaymentHistoryData): PaymentRecord[] => {
    let paymentHistory: PaymentRecord[] = [];

    for (let paymentData of data.payments) {
      let record: PaymentRecord = new PaymentRecord(new Date(paymentData.payment_date), paymentData.amount / 1000);
      record.count = data.payments.length - (paymentData.payment_number - 1);
      record.status = paymentData.status;
      record.invoiceUrl = paymentData.hosted_invoice_url;
      paymentHistory.push(record);
    }

    return paymentHistory;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const policyNextPaymentAdapter = (data: IPolicyNextPaymentData): PaymentRecord => {
    let record: PaymentRecord = new PaymentRecord(new Date(data.payment.payment_date), data.payment.amount / 1000);
    return record;
  }


}
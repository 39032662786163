import { AxiosPromise } from "axios"
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { ICancellationReasons } from "../interfaces/ICancellationReasonsData";
import { ICancelPolicyRequest } from "../interfaces/ICancelPolicyRequest";
import { ITemporalCancellationData } from "../interfaces/ITemporalCancellationData";

export namespace PolicyCancellationService {

  /**
   * 
   * @returns 
   */
  export const cancellationReasonsService = (): AxiosPromise<ICancellationReasons> => {
    return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.CANCELLATION_REASONS);
  }

  /**
   * 
   * @returns 
   */
  export const cancelPolicyService = (request: ICancelPolicyRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.SecureHttp().post(Util.API_PATH.POLICY_CANCELLATION, request);
  }

	/**
   * 
   * @returns 
   */
	export const checkTemporalCancellationService = (policyId: string): AxiosPromise<ITemporalCancellationData> => {
		return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.CHECK_TEMPORAL_CANCELLATION(policyId));
	}

	/**
   * 
   * @returns 
   */
	export const checkPolicyPaymentsService = (policyNumber: string): AxiosPromise<void> => {
		return EnvironmentFactory.Http.SecureHttp().get(Util.API_PATH.CHECK_NEXT_PAYMENT(policyNumber));
	}
}
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DamageClaim } from "src/classes/DamageClaim";
import { Policy } from "src/classes/Policy";
import { IClaimsStateReducer } from "src/store/states/modules-state/IClaimsStateReducer";

/**
 *
 */
export namespace ClaimsSlice {
	
	/**
   * 
   */
 const initialState: IClaimsStateReducer = {
    policyList: undefined,
    claimList: undefined,
    licenceTypes: [],
    vehicleColors: [],
    vehicleDamagesList: [],
    bikeDamagesList: [],
    vehicleTypes: [],
		personTypes: [],
		serviceTypesList: [],
		cancellingReasonsList: [],
		coveragesList: [],
    causesList: [],
    liabilityList: [],
    states: [],
    claimRegister: undefined,
    claimAdjuster: undefined,
    formDisabled: false,
  };

  /**
   * 
   * @param state 
   * @param action 
   */
  const clear: CaseReducer<IClaimsStateReducer> = (state) => {
    state.policyList = [];
    state.claimList = undefined;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const policyList: CaseReducer<IClaimsStateReducer, PayloadAction<Policy[]>> = (state, action) => {
    state.policyList = action.payload;
  }
    /**
   * 
   * @param state 
   * @param action 
   */
     const claimList: CaseReducer<IClaimsStateReducer, PayloadAction<DamageClaim[]>> = (state, action) => {
      state.claimList = action.payload;
    }

  const reducers = {
    clear,
    policyList,
    claimList
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'claims',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";

/**
 *
 */
export namespace DashboardAction {

  export const userInfo: ActionCreatorWithoutPayload = createAction('USER_INFO');

  export const dashboardMenu: ActionCreatorWithPayload<boolean> = createAction('DASHBOARD_MENU');

  export const menuVisibility: ActionCreatorWithPayload<boolean> = createAction('MENU_VISIBILITY');
}
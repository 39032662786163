import { DocumentSignature } from "src/classes/document/DocumentSignature";
import { DocumentSignatureAdapter } from "../adapters/document-signature.adapter"
import { DocumentSignatureService } from "../services/document-signature.service"

export namespace DocumentSignatureFeature {

  /**
   * 
   * @param id 
   * @param key 
   * @returns 
   */
  export const getClaimFormsDocumentFeature = async (id: string, key: string): Promise<DocumentSignature | undefined> => {
    try {
      const request = DocumentSignatureAdapter.getClaimFormsDocumentRequestAdapter(id, key);
      const { data } = await DocumentSignatureService.getClaimFormsDocumentService(request);
      const document = DocumentSignatureAdapter.claimFormsDocumentDataAdapter(data, key);

      return document;
    } catch (e) {
      console.error(e);
      let completedDocument: DocumentSignature = new DocumentSignature('', '', '');
      completedDocument.completed = true;
      return completedDocument;
    }
  }

  /**
   * 
   * @param document 
   * @param completed 
   * @returns 
   */
  export const claimFormsDocumentFeature = async (document: DocumentSignature, completed?: boolean):  Promise<DocumentSignature | undefined> => {
    try {
      const request = DocumentSignatureAdapter.claimsFormsDocumentUpdateRequestAdapter(document, completed);
      const { data } = await DocumentSignatureService.claimFormsDocumentService(request);
      const newDocument = DocumentSignatureAdapter.claimFormsDocumentDataAdapter(data, document.key);

      return newDocument;
    } catch {

    }
  }
}
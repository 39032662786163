import { AxiosError } from "axios";
import { IdentityValidationService } from "../services/identity-validation.service"

/**
 *
 */
export namespace IdentityValidationFeature {

	/** */
	export const createTruoraTokenFeature = async(): Promise<string> => {
		try {
			const { data } = await IdentityValidationService.createTruoraToken();
			return data.data.token;
		} catch(e) {
			const axiosError = e as AxiosError;
			let error: Error = new Error();

			if(axiosError.response?.status === 429)
				error.message = 'Ha superado el límite de intentos, prueba más tarde.';

			throw(error);
		}
	}
}
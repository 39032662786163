import { useForm } from "react-hook-form";
import { IPolicyCreationVehicleForm } from "../interfaces/IPolicyCreationVehicleForm";

/**
 *
 */
export namespace PolicyCreationVehicleFormFacade {

	/**
   * 
   */
	const POSTAL_CODE_ERRORS_MESSAGE = {
		LENGTH: 'El código postal debe ser de 5 digitos.',
		INVALID: 'Aún no llegamos a tu municipio.'
	};
	const POSTAL_CODE_ERRORS: TFormErrorMessage[] = [
		{
			key: 'required',
			message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
		},
		{
			key: 'pattern',
			message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
		},
		{
			key: 'minLength',
			message: POSTAL_CODE_ERRORS_MESSAGE.LENGTH
		},
		{
			key: 'invalidPostalCode',
			message: POSTAL_CODE_ERRORS_MESSAGE.INVALID
		}
	];

  export const usePolicyCreationVehicleFormFacade = () => {    
		/** */
		const { register, formState: { errors, isValid, touchedFields }, trigger, watch, setValue, getValues } = useForm<IPolicyCreationVehicleForm>(
			{
				mode: 'onChange',
				reValidateMode: 'onChange'
			}
		);

    return {
			form: { register, formState: { errors, isValid, touchedFields }, trigger, watch, setValue, getValues }, 
			POSTAL_CODE_ERRORS
		}
  }
}
import { Util } from "src/utils/Util";

export class PaymentMethod {

  /**
   *
   */
  private _holderName: string;
  set holderName(_holderName: string) { this._holderName = _holderName };
  get holderName(): string { return this._holderName };
  /**
   *
   */
  private _cardDigits: string;
  set cardDigits(_cardDigits: string) { this._cardDigits = _cardDigits };
  get cardDigits(): string { return this._cardDigits };
  /**
   *
   */
  private _brand: 'visa' | 'mastercard' | 'amex' | 'none';
  set brand(_brand: 'visa' | 'mastercard' | 'amex' | 'none') { this._brand = _brand };
  /**
   *
   */
  private _provider: 'OPENPAY' | 'STRIPE' | 'none';
  set provider(_provider: 'OPENPAY' | 'STRIPE' | 'none') { this._provider = _provider };

  constructor (_cardDigits: string) {
    this._cardDigits = _cardDigits;
    this._holderName = '';
    this._brand = 'none';
    this._provider = 'none';
  }

  /**
   * 
   */
  public getBrandLogo(): string {
    let logo: string = '';
    if (this._brand === 'visa') {
      logo = Util.ASSET.PAYMENT_CARD_VISA_WHITE;
    } else if (this._brand === 'mastercard') {
      logo = Util.ASSET.PAYMENT_CARD_MASTERCARD;
    } else if (this._brand === 'amex') {
      logo = Util.ASSET.PAYMENT_CARD_AMEX;
    }

    return logo;
  }

  /**
   * 
   * @returns 
   */
  public isProviderByForm(): boolean {
    return this._provider === 'OPENPAY';
  }
  
  /**
   * 
   * @returns 
   */
  public isProviderByComponent(): boolean {
    return this._provider === 'STRIPE';
  }
}

const BASE_URL: string = '/claim-payments';

/**
 * 
 */
 export const CLAIM_PAYMENTS_LIST_PATH = {

  CLAIM_PAYMENTS_LIST: BASE_URL,
  ACTIVE_POLICIES: `${BASE_URL}/active-policies`,
  OPEN_CLAIMS: `${BASE_URL}/open-claims`,
}
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { useInjectSaga } from "redux-injectors"
import { CoreSelector } from "src/core/selectors/core.selector"
import { QUOTER_PATH } from "src/routes/paths/quoter.paths"
import { HeaderAction } from "../actions/header.action"
import { HeaderSaga } from "../sagas/header.saga"

/**
 *
 */
export namespace HeaderFacade {

  /**
   * 
   */
  export const useHeader = () => {
    useInjectSaga({key: 'header', saga: HeaderSaga.headerSaga});
    const pageMenu = useSelector(CoreSelector.pageMenu);
    const openMenu = useSelector(CoreSelector.openMenu);
    const dispatch = useDispatch();


    const onClickMenu = (): void => {
      dispatch(HeaderAction.toggleMenu(!openMenu));
    }

    return { pageMenu, onClickMenu }
  }

  /**
   * 
   */
  export const useCurrentUser = () => {
    const user = useSelector(CoreSelector.currentUser)!;
    return {user };
  }

  /**
   * 
   */
   export const useSession = () => {
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [showSession, setShowSession] = useState<boolean>(true);
    const location = useLocation();

    /**
     * 
     */
    const toggleOptions = (value: boolean): void => {
      setShowOptions(value);
    }

    useEffect(
      () => {
        if (location.pathname.includes(QUOTER_PATH.QUOTER)) {
          setShowSession(false);
        } else if (!location.pathname.includes(QUOTER_PATH.QUOTER) && !showSession) {
          setShowSession(true);
        }
      }, [location, showSession]
    )

    return { showOptions, toggleOptions, showSession };
  }

}
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { CoreVehicleStoreFeature } from "src/core/features/vehicle-storage.feature";
import { POLICY_CREATION_PATH, VEHICLE_ROUTE_ID } from "src/routes/paths/policy-creation.paths";
import { PolicySelector } from "../selectors/policy.selector";

/**
 *
 */
export namespace PolicyOnboardingFacade {

  /**
   * 
   */
  export const usePolicyOnboardingFacade = () => {
		/** */
		const policyList = useSelector(PolicySelector.policyList);
		/** */
		const [showOnboarding, setShowOnboarding] = useState<boolean>(false);
		const [storedVehicle, setStoredVehicle] = useState<Vehicle>();
		const navigate = useNavigate();

		/** */
		useEffect(() => {
			if(policyList && policyList.length === 1 && policyList[0].isPending())
				setShowOnboarding(true);
		}, [policyList, setShowOnboarding]);

		/** */
		useEffect(() => {
			const vehicle = CoreVehicleStoreFeature.getStoredVehicleFeature();
			if(vehicle)
				setStoredVehicle(vehicle);
		}, []);

		/**
		 * 
		 * @param vehicle 
		 */
		const navigateToVehicleCreation = (vehicle: Vehicle) => {
			CoreVehicleStoreFeature.storeVehicleFeature(vehicle)
			navigate(POLICY_CREATION_PATH.VEHICLE_DATA(VEHICLE_ROUTE_ID.VEHICLE_DATA));
		}

    return { showOnboarding, storedVehicle, setShowOnboarding, policyList, navigateToVehicleCreation };
  }

}
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Endorse } from "src/classes/policy/Endorse";
import { AlertFactory } from "src/factory/alert.factory";
import { POLICY_ENDORSEMENTS_PATH } from "src/routes/paths/policy-endorsements.paths";
import { Util } from "src/utils/Util";
import { PolicyEndorsementsAction } from "../actions/policy-endorsements.action";
import { PolicyEndorsementsFeature } from "../features/policy-endorsements.feature";
import { EndorsementConfigurationFacade } from "./endorsement-configuration.facade"
import { PolicyEndorsementsFacade } from "./policy-endorsements.facade";

export namespace EndorsementSummaryFacade {

  /**
   * 
   */
  export const useEndorsementSummary = () => {
    const { currentPolicy, endorsements } = PolicyEndorsementsFacade.useEndorsementsActors();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const TYPE_LIST = EndorsementConfigurationFacade.ENDORSEMENTS_TYPE_LIST;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    /**
     * 
     * @param endorse 
     * @returns 
     */
    const getTypeName = (endorse: Endorse): string => {
      let typeValue: string = '';
      if (endorse.vehicle) {
        const type = TYPE_LIST.find(t => t.code === endorse.vehicle?.code);
        if (type) {
          typeValue = type.value;
        }
      }

      return typeValue;
    }

    /**
     * 
     * @param endorse 
     */
    const getConfigurationValue = (endorse: Endorse): string => {
      let configurationValue: string = '';
      if (endorse.vehicle) {
        if (endorse.vehicle.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.VIN) {
          configurationValue = `${endorse.vehicle.before.vin} a ${endorse.vehicle.after.vin}`;
        } else if (endorse.vehicle.code === Util.STATUS_CODE.ENDORSEMENT_TYPE_CODE.PLATE) {
          configurationValue = `${endorse.vehicle.before.plate} a ${endorse.vehicle.after.plate}`;
        }

      }

      return configurationValue;
    }

    /**
     * 
     */
    const apllyChanges = async (): Promise<void> => {
      try {
        setIsLoading(true);
        await PolicyEndorsementsFeature.applyEndorseFeature(currentPolicy.id, query.get('otp')!, endorsements);
        dispatch(PolicyEndorsementsAction.endorsementCompleted());
        navigate(POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_COMPLETION(currentPolicy.policyNumber));
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        setIsLoading(false);
      }
    }

    return { currentPolicy, endorsements, isLoading, getTypeName, getConfigurationValue, apllyChanges }
  }
}
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { AGREED_PAYMENTS_LIST_PATH } from "src/routes/paths/agreed-payments-list.paths";
import { CLAIM_PAYMENTS_LIST_PATH } from "src/routes/paths/claim-payments-list.paths";
import { ClaimsAction } from "../actions/claims.action";
import { ClaimsSaga } from "../sagas/claims.saga";
import { ClaimsSelector } from "../selectors/claims.selector";
import { ClaimsSlice } from "../slices/claims.slice";

/**
 *
 */
export namespace ClaimsFacade {

  /**
   * 
   */
  export const usePolicy = () => {
    useInjectReducer({ key: 'claims', reducer: ClaimsSlice.reducer });
    useInjectSaga({ key: 'claims', saga: ClaimsSaga.policySaga });
		/** */
		const [segmentData, setSegmentData] = useState<{ to: string, name: string }[]>([]);
		/** */
    const dispatch = useDispatch();
		const location = useLocation();
		const [paymentType, setIsPaymentType] = useState<string>('');

		/** */
    useEffect(() => {
      dispatch(ClaimsAction.policyList());

			if(location.pathname.includes(CLAIM_PAYMENTS_LIST_PATH.CLAIM_PAYMENTS_LIST) && paymentType !== 'claim'){
				dispatch(ClaimsAction.claimList());
				setIsPaymentType('claim');
			} else if(location.pathname.includes(AGREED_PAYMENTS_LIST_PATH.AGREED_PAYMENTS_LIST) && paymentType !== 'agreed' ) {
				dispatch(ClaimsAction.agreedClaimList());
				setIsPaymentType('agreed');
			}
		
    }, [dispatch, location, paymentType])

		/** */
		useEffect(() => {
			if(location.pathname.includes(CLAIM_PAYMENTS_LIST_PATH.CLAIM_PAYMENTS_LIST)){
				setSegmentData([
					{ to: `${CLAIM_PAYMENTS_LIST_PATH.ACTIVE_POLICIES}`, name: 'Pólizas Activas' },
					{ to: `${CLAIM_PAYMENTS_LIST_PATH.OPEN_CLAIMS}`, name: 'Siniestros Activos' },
				]);
			} else {
				setSegmentData([
					{ to: `${AGREED_PAYMENTS_LIST_PATH.ACTIVE_POLICIES}`, name: 'Pólizas Activas' },
					{ to: `${AGREED_PAYMENTS_LIST_PATH.OPEN_CLAIMS}`, name: 'Siniestros Activos' },
				]);
			}
		}, [setSegmentData, location])

		return { segmentData };
  }

  /**
   * 
   * @returns 
   */
  export const usePolicyList = () => {
    const policyList = useSelector(ClaimsSelector.policyList);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    /**
     * 
     */
    useEffect(() => {
      if (policyList && policyList.length >= 0 && isLoading) {
        setIsLoading(false);
      }
    }, [policyList, isLoading])


    return { isLoading, policyList }
  }


  /**
   * 
   * @returns 
   */
  export const useClaimList = () => {
    const claimList = useSelector(ClaimsSelector.claimList);

    return { claimList }
  }
}
import { IconFactory } from 'src/factory/icon.factory';
import { DamageClaimNavigateValidationFacade } from 'src/modules/claim-payments/controller/facades/damage-claim-navigate-validation.facade';
import { VehicleOwnerFacade } from 'src/modules/claim-payments/controller/facades/vehicle-owner.facade';
import ButtonSection from '../../molecules/ButtonSection/ButtonSection';
import './VehicleOwnerPage.scss';

/**
 *
 */
interface IVehicleOwner { }

/**
 *
 */
const VehicleOwnerPage = (props: IVehicleOwner): JSX.Element => {
	
	/** 1 for user owner, 2 for third party */
	const { vehicleOwner, CLAIM_CURRENT_PATH, AGREED_CURRENT_PATH, isAgreedPayment, updateVehicleOwner } = VehicleOwnerFacade.useVehicleOwnerFacade();
	const { navigationHandler } = DamageClaimNavigateValidationFacade.useDamageClaimNavigateValidationFacade();

  return (
    <div id='vehicle-owner-wrapper' className='flex-grow-100 flex'>
			<section className='content height-100'>
				<h1>{`Solicitud pago ${isAgreedPayment ? 'pactado' : 'de daño'}`}</h1>
				<h3>¿Eres el dueño del vehículo?</h3>

				<div className={`owner-option ${vehicleOwner === 1 && 'active'}`} onClick={() => updateVehicleOwner(1)}>
					<span>Sí, yo soy el propietario.</span>
					<div>
						{IconFactory.checkIcon({ fill: '#fff' })}
					</div>
				</div>

				<div className={`owner-option ${vehicleOwner === 2 && 'active'}`} onClick={() => updateVehicleOwner(2)}>
					<span>No, el vehículo es de otra persona.</span>
					<div>
						{IconFactory.checkIcon({ fill: '#fff' })}
					</div>
				</div>
			</section>

			<ButtonSection 
				nextHandler={() => {navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'forward', undefined, vehicleOwner)}} 
				backHandler={() => {navigationHandler(isAgreedPayment ? AGREED_CURRENT_PATH : CLAIM_CURRENT_PATH, 'backward')}} 
				nextButtonDisabled={vehicleOwner === 0}
			/>
    </div>
  );
};

export default VehicleOwnerPage;
export class PlanPayment {

  /**
   *
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };
  /**
   *
   */
  private _type: string;
  set type(_type: string) { this._type = _type };
  get type(): string { return this._type };
  /**
   *
   */
  private _paymentsCount: number;
  set paymentsCount(_paymentsCount: number) { this._paymentsCount = _paymentsCount };
  get paymentsCount(): number { return this._paymentsCount };
  /**
   *
   */
  private _isSubscription: boolean;
  set isSubscription(_isSubscription: boolean) { this._isSubscription = _isSubscription };
  get isSubscription(): boolean { return this._isSubscription };
	/**
	 * 
	 */
	private _selectedPaymentMethod: string | undefined;
	set selectedPaymentMethod(_selectedPaymentMethod: string | undefined) { this._selectedPaymentMethod = _selectedPaymentMethod };
	get selectedPaymentMethod(): string | undefined { return this._selectedPaymentMethod };

  constructor(_id?: string, _type?: string) {
    this._id = _id? _id : '';
    this._type = _type? _type : '';
    this._paymentsCount = 0;
    this._isSubscription = false;
  }
}
import { Phone } from "../Phone";
import { ClaimRegisterPicture } from "./ClaimRegisterPicture";
import { Provider } from "./Provider";
import { StatusTypeItem } from "./StatusTypeItem";

export class Service {

	/**
	 *
	 */
	private _id: string | undefined;
	set id(_id: string | undefined) { this._id = _id };
	get id(): string | undefined { return this._id };

	/**
	 *
	 */
	private _type: StatusTypeItem | undefined;
	set type(_type: StatusTypeItem | undefined) { this._type = _type };
	get type(): StatusTypeItem | undefined { return this._type };

	/**
	 *
	 */
	private _category: StatusTypeItem | undefined;
	set category(_category: StatusTypeItem | undefined) { this._category = _category };
	get category(): StatusTypeItem | undefined { return this._category };

	/**
	 * 
	 */
	private _provider: Provider | undefined;
	set provider(_provider: Provider | undefined) { this._provider = _provider };
	get provider(): Provider | undefined { return this._provider };

	/**
	 * 
	 */
	private _coverage: StatusTypeItem | undefined;
	set coverage(_coverage: StatusTypeItem | undefined) { this._coverage = _coverage };
	get coverage(): StatusTypeItem | undefined { return this._coverage };

	/**
	 * 
	 */
	private _amount: number | undefined;
	set amount(_amount: number | undefined) { this._amount = _amount };
	get amount(): number | undefined { return this._amount };

	/**
	 * 
	 */
	private _itemNumber: string | undefined;
	set itemNumber(_itemNumber: string | undefined) { this._itemNumber = _itemNumber };
	get itemNumber(): string | undefined { return this._itemNumber };

	/**
	 * 
	 */
	private _hasDeductible: boolean | undefined;
	set hasDeductible(_hasDeductible: boolean | undefined) { this._hasDeductible = _hasDeductible };
	get hasDeductible(): boolean | undefined { return this._hasDeductible };

	/**
	 * 
	 */
	private _operatorName: string | undefined;
	set operatorName(_operatorName: string | undefined) { this._operatorName = _operatorName };
	get operatorName(): string | undefined { return this._operatorName };

	/**
	 * 
	 */
	private _operatorPhone: Phone | undefined;
	set operatorPhone(_operatorPhone: Phone | undefined) { this._operatorPhone = _operatorPhone };
	get operatorPhone(): Phone | undefined { return this._operatorPhone };

	/**
	 * 
	 */
	private _description: string;
	set description(_description: string) { this._description = _description };
	get description(): string { return this._description };

	/**
	 *
	 */
	private _pictures: ClaimRegisterPicture[];
	set pictures(_pictures: ClaimRegisterPicture[]) { this._pictures = _pictures };
	get pictures(): ClaimRegisterPicture[] { return this._pictures };

	/**
	 * 
	 */
	private _isPrior: boolean;
	set isPrior(_isPrior: boolean) { this._isPrior = _isPrior };
	get isPrior(): boolean { return this._isPrior };

	constructor () { 
		this._description = '';
		this._pictures = [];
		this._isPrior = false;
	}
}
import { PaymentCost } from "../PaymentCost";
import { Coverage } from "./Coverage";
import { PlanCheckout } from "./PlanCheckout";
import { PlanDiscount } from "./PlanDiscount";
import { PlanMonthly } from "./PlanMonthly";
import { PlanPayment } from "./PlanPayment";
import { PlanSubscription } from "./PlanSubscription";

/**
 * 
 */
export class Plan {

  /**
   * 
   */
  private _id: string;
  set id(_id: string) { this._id = _id };
  get id(): string { return this._id };
  /**
   * 
   */
  private _name: string;
  get name(): string { return this._name; }
  set name(_name: string) { this._name = _name; }
  /**
   *
   */
  private _quotation: string;
  set quotation(_quotation: string) { this._quotation = _quotation };
  get quotation(): string { return this._quotation };
  /**
   * 
   */
  private _coupon: string;
  get coupon(): string { return this._coupon; }
  set coupon(_coupon: string) { this._coupon = _coupon; }
  /**
   *
   */
  private _description: string;
  set description(_description: string) { this._description = _description };
  get description(): string { return this._description };
  /**
   *
   */
  private _cost: PaymentCost;
  set cost(_cost: PaymentCost) { this._cost = _cost };
  get cost(): PaymentCost { return this._cost };
  /**
   *
   */
  private _subscription: PlanSubscription;
  set subscription(_subscription: PlanSubscription) { this._subscription = _subscription };
  get subscription(): PlanSubscription { return this._subscription };
	/**
	 * 
	 */
	private _monthly: PlanMonthly;
	set monthly(_monthly: PlanMonthly) { this._monthly = _monthly };
	get monthly(): PlanMonthly { return this._monthly };
  /**
   *
   */
  private _discount: PlanDiscount | undefined;
  set discount(_discount: PlanDiscount | undefined) { this._discount = _discount };
  get discount(): PlanDiscount | undefined { return this._discount };
  /**
   *
   */
  private _coveragesList: Coverage[];
  set coveragesList(_coveragesList: Coverage[]) { this._coveragesList = _coveragesList };
  get coveragesList(): Coverage[] { return this._coveragesList };
  /**
   *
   */
  private _payments: PlanPayment[];
  set payments(_payments: PlanPayment[]) { this._payments = _payments };
  get payments(): PlanPayment[] { return this._payments };
  /**
   *
   */
  private _allowEdit: boolean;
  set allowEdit(_allowEdit: boolean) { this._allowEdit = _allowEdit };
  get allowEdit(): boolean { return this._allowEdit };
  /**
   *
   */
  private _planCheckout: PlanCheckout | undefined;
  set planCheckout(_planCheckout: PlanCheckout | undefined) { this._planCheckout = _planCheckout };
  get planCheckout(): PlanCheckout | undefined { return this._planCheckout };
	/**
	 * 
	 */
	private _selectedPlanPayment: PlanPayment;
	set selectedPlanPayment(_selectedPlanPayment: PlanPayment) { this._selectedPlanPayment = _selectedPlanPayment };
	get selectedPlanPayment(): PlanPayment { return this._selectedPlanPayment };

  constructor() {
    this._id = '';
    this._name = '';
    this._description = '';
    this._quotation = '';
    this._coupon = '';
    this._coveragesList = [];
    this._payments = [];
    this._allowEdit = false;
    this._planCheckout = undefined;
    this._cost = new PaymentCost();
    this._subscription = new PlanSubscription();
		this._monthly = new PlanMonthly();
		this._selectedPlanPayment = new PlanPayment();
  }

}
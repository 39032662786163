import { useDispatch, useSelector } from "react-redux"
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { CoreSelector } from "src/core/selectors/core.selector"
import { PolicyEndorsementsSlice } from "../slices/policy-endorsements.slice";
import { PolicyEndorsementsSaga } from "../sagas/policy-endorsements.saga";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PolicyEndorsementsAction } from "../actions/policy-endorsements.action";
import { PolicyEndorsementsSelector } from "../selectors/policy-endorsements.selector";
import { POLICY_ENDORSEMENTS_PATH } from "src/routes/paths/policy-endorsements.paths";
import { POLICY_PATH } from "src/routes/paths/policy.paths";
import { Util } from "src/utils/Util";

/**
 *
 */
export namespace PolicyEndorsementsFacade {

  /**
   * 
   */
  export const useEndorsementsActors = () => {
    const currentUser = useSelector(CoreSelector.currentUser);
    const currentPolicy = useSelector(PolicyEndorsementsSelector.currentPolicy);
    const endorsements = useSelector(PolicyEndorsementsSelector.newEndorsements);

    return { currentUser, currentPolicy, endorsements }
  }

  /**
   * 
   */
  export const usePolicyEndorsements = () => {
    useInjectReducer({ key: 'policyEndorsements', reducer: PolicyEndorsementsSlice.reducer });
    useInjectSaga({ key: 'policyEndorsements', saga: PolicyEndorsementsSaga.policyEndorsementsSaga });
    const { currentUser, currentPolicy } = useEndorsementsActors();
    const { policyNumber } = useParams<string>();
    const isEndorseCompleted = useSelector(PolicyEndorsementsSelector.completed);
    const isEndorseVerified = useSelector(PolicyEndorsementsSelector.verified);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const allowCreateEndorse = useCallback(
      () => {
        return currentUser && currentUser.profile?.identityStatus === Util.STATUS_CODE.IDENTITY_STATUS.COMPLETE;
      }, [currentUser]
    )

    /**
     * 
     */
    useEffect(() => {
      if (!policyNumber) {
        // Retornar
      } else {
        dispatch(PolicyEndorsementsAction.policyEndorsement(policyNumber));
        if (location.pathname === POLICY_ENDORSEMENTS_PATH.ENDORSEMENT(policyNumber)) {
          if (allowCreateEndorse()) {
            navigate(`${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_VERIFICATION(policyNumber!)}`);
          } else {
            navigate(`${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_IDENTITY_VALIDATION(policyNumber!)}`);
          }
        }
      }
    }, [policyNumber, dispatch, allowCreateEndorse, navigate, location]);

    /**
     * 
     */
    useEffect(
      () => {
        if (currentPolicy) {
          if (location.pathname !== POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_COMPLETION(currentPolicy.policyNumber) && isEndorseCompleted) {
            navigate(`${POLICY_PATH.MANAGEMENT}/${currentPolicy.policyNumber}`, { replace: true });
          }
        }
      }, [currentPolicy, location.pathname, isEndorseCompleted, navigate]
    );

    useEffect(
      () => {
        if (currentPolicy && allowCreateEndorse() && !isEndorseVerified && location.pathname !== POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_VERIFICATION(currentPolicy.policyNumber)) {
          navigate(POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_VERIFICATION(currentPolicy.policyNumber), { replace: true });
        }
      }, [currentPolicy, allowCreateEndorse, isEndorseVerified, location.pathname, navigate]
    )

    /**
     * 
     */
    useEffect(() => {
      return () => {
        dispatch(PolicyEndorsementsAction.clear());
      }
    }, [dispatch]);

    return { allowCreateEndorse, isEndorseCompleted }
  }

  /**
   * 
   * @returns 
   */
  export const useStepStatus = () => {

    const isVerified = useSelector(PolicyEndorsementsSelector.verified);
    const isEndorseCompleted = useSelector(PolicyEndorsementsSelector.completed);
    const newEndorsements = useSelector(PolicyEndorsementsSelector.newEndorsements);

    /**
     * 
     */
    const isVerificationStepCompleted = (): boolean => {
      let isCompleted: boolean = false;
      if (isVerified) {
        isCompleted = true;
      }
      return isCompleted;
    }

    /**
     * 
     */
    const isEndorsementsCompleted = (): boolean => {
      let isCompleted: boolean = false;
      if (isEndorseCompleted) {
        isCompleted = true;
      }
      return isCompleted;
    }

    /**
     * 
     */
    const isConfigurationStepCompleted = (): boolean => {
      let isCompleted: boolean = false;
      if (newEndorsements.length > 0) {
        isCompleted = true;
      }
      return isCompleted;
    }

    return { isVerificationStepCompleted, isConfigurationStepCompleted, isEndorsementsCompleted };
  }

  /**
   * 
   */
  export const useReturnValidation = () => {
    const { currentPolicy } = useEndorsementsActors();
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = useLocation();

    const onReturn = () => {
      if (currentPolicy) {
        if (location.pathname === POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_VERIFICATION(currentPolicy.policyNumber) ||
          location.pathname === POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_CONFIGURATION(currentPolicy.policyNumber)) {
          navigate(`${POLICY_PATH.ENDORSEMENTS_HISTORY}/${currentPolicy?.policyNumber}`, { replace: true });
        } else if (location.pathname === POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_SUMMARY(currentPolicy.policyNumber)) {
          navigate(`${POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_CONFIGURATION(currentPolicy.policyNumber)}${search}`, { replace: true });
        } else if (location.pathname === POLICY_ENDORSEMENTS_PATH.ENDORSEMENT_IDENTITY_VALIDATION(currentPolicy.policyNumber)) {
          navigate(`${POLICY_PATH.ENDORSEMENTS_HISTORY}/${currentPolicy.policyNumber}`, { replace: true });
        }
      }
    }

    return { onReturn }
  }
}
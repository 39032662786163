import { AxiosPromise } from "axios";
import { IClaimPaymentNegotationRequest } from "../interfaces/IClaimPaymentNegotationRequest";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";

export namespace ClaimProposalService {

  /**
   * 
   * @param damagePaymentID 
   * @returns 
   */
  export const proposalNegotationService = (damagePaymentId: string, proposalId: string, data: IClaimPaymentNegotationRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).put(Util.API_PATH.CLAIM_PAYMENT_NEGOTATION(damagePaymentId, proposalId), data);
  };

	/**
   * 
   * @param damagePaymentID 
   * @returns 
   */
		 export const agreedProposalNegotationService = (damagePaymentId: string, proposalId: string, data: IClaimPaymentNegotationRequest): AxiosPromise<void> => {
			return EnvironmentFactory.Http.SecureHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.PUBLIC_API).put(Util.API_PATH.AGREED_PAYMENT_NEGOTATION(damagePaymentId, proposalId), data);
		};

}
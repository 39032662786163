import { ICancellationReasons } from "../interfaces/ICancellationReasonsData";
import { ICancelPolicyRequest } from "../interfaces/ICancelPolicyRequest";
import { IReasons } from "../interfaces/IReasons";
import { ITemporalCancellationData } from "../interfaces/ITemporalCancellationData";

export namespace PolicyCancellationAdapter {

  /**
   * 
   * @param data 
   * @returns 
   */
  export const cancellationReasonsDataADapter = (data: ICancellationReasons): IReasons[] => {
    let reasons: IReasons[] = [];
    for (const reasonData of data.cancellation_reasons) {
      if (reasonData.cancellation_reason_public_desc) {
        reasons.push({id: reasonData.cancellation_reason_id, description: reasonData.cancellation_reason_public_desc})
      }
    }
    
    return reasons;

  }

  /**
   * 
   * @param policyId 
   * @param userId 
   * @param reasonId 
   * @param customDescription 
   * @returns 
   */
  export const cancelPolicyRequestAdapter = (policyId: string, userId: string, reasonId: string, customDescription?: string): ICancelPolicyRequest => {
    let request: ICancelPolicyRequest = {
      policy_id: policyId,
      user_id: userId,
      cancellation_reason_id: reasonId,
      cancel_date: new Date().toISOString(),
    }

    if (customDescription) {
      request['reason_detail_desc'] = customDescription;
    }

    return request;
  }

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	export const checkTemporalCancellationAdapter = (data: ITemporalCancellationData): Date => {
		const date = new Date(data.cancel_date);
		return date;
	}
}
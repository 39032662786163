import { Policy } from "src/classes/Policy";
import ActivePolicyClaimCard from "../../molecules/ActivePolicyClaimCard/ActivePolicyClaimCard";
import "./ActivePoliciesList.scss";

/**
 *
 */
interface IActivePoliciesList {
  /**
   *
   */
  policyList: Policy[];
  /**
   *
   */
  isloading: boolean;
}

/**
 *
 */
const ActivePoliciesList = (props: IActivePoliciesList): JSX.Element => {
  const { policyList } = props;
  return (
    <div className="claims-list-wrapper height-100 text-center">
      {props.isloading ? (
        <>
          <ActivePolicyClaimCard loader />
          <ActivePolicyClaimCard loader />
          <ActivePolicyClaimCard loader />
          <ActivePolicyClaimCard loader />
        </>
      ) : (
        <>
          {policyList.map((policy) => {
            return <ActivePolicyClaimCard key={policy.id} policy={policy} />;
          })}
        </>
      )}
    </div>
  );
};

export default ActivePoliciesList;


const BASE_URL: string = '/policy';

export const POLICY_PARAMS = {
  STATUS: {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
    EXPIRED: 'expired'
  }
}

/**
 * 
 */
 export const POLICY_PATH = {

  POLICY: BASE_URL,
  STATUS: `${BASE_URL}/status`,
  OWNER: `${BASE_URL}/owner`,
  DETAILS: `${BASE_URL}/details`,
  MANAGEMENT: `${BASE_URL}/management`,
  PICTURES: `${BASE_URL}/pictures`,
  PAYMENT_METHOD: `${BASE_URL}/payment-method`,
  CANCELLATION: `${BASE_URL}/cancellation`,
  PAYMENT_HISTORY: `${BASE_URL}/payment-history`,
  ENDORSEMENTS_HISTORY: `${BASE_URL}/endorsements-history`,
}
import classNames from "classnames";
import { DamageClaim } from "src/classes/DamageClaim";
import ClaimCard from "../../molecules/ClaimCard/ClaimCard";
import "./ActiveClaimsList.scss";

/**
 *
 */
interface IActiveClaimsList {
  /**
   *
   */
   claimList: DamageClaim[] | undefined;
}

/**
 *
 */
const ActiveClaimsListPage = (props: IActiveClaimsList): JSX.Element => {
	/** */
  const { claimList } = props;

	const classnames = classNames({
		'claims-list-wrapper': true,
		'height-100': true,
		'text-center': true,
		'no-claims': !claimList || claimList.length === 0
	});

  return (
		<div className={classnames}>
			{!claimList ? (
				<>
					<ClaimCard loader />
					<ClaimCard loader />
					<ClaimCard loader />
					<ClaimCard loader />
				</>
			) : (
				<>
					{claimList.map((claim) => {
						return <ClaimCard key={claim.damageClaimNumber} claim={claim} />;
					})}

					{
						claimList.length === 0 && 
							<p className="empty-data">No hay siniestros activos.</p>
					}
				</>
			)}
		</div>
	);
};

export default ActiveClaimsListPage;

import { Button, ButtonBack, Modal, Spinner, Card } from "src/components";
import InsurancePictureItem from "../../atoms/InsurancePictureItem/InsurancePictureItem";
import InsurancePictureError from "../../atoms/InsurancePictureError/InsurancePictureError";
import { InsurancePicturesFacade } from "src/modules/insurance-vehicle/controller/facades/insurance-pictrures.facade";
import { Util } from "src/utils/Util";

import './InsurancePictures.scss';


/**
 *
 */
const InsurancePicturesPage = (): JSX.Element => {
  const { VEHICLE_PICTURE_TYPE_CODES, onSelectPicture, currentPolicy, currentToken, onBack, isValidPictures, isUploading, uploadPictures } = InsurancePicturesFacade.useInsurancePictures();
  const { isPictureValidating, setIsPictureValidating, errorName, setErrorName } = InsurancePicturesFacade.usePictureEvents();

  return (
    <div id="insurance-pictures-wrapper" className="width-100 relative">
      <ButtonBack to={onBack()} text />
      <h1>Fotos de tu veh&iacute;culo</h1>
      {
        currentPolicy &&
        <section className="flex align-center justify-center">
          <Card img={Util.ASSET.EMPTY_CAR_PICTURE} imageSize="small" disabled>
            <ul className="insurance-policy-info">
              <li>
                <b>P&oacute;liza: </b>{currentPolicy.policyNumber}
              </li>
              <li>
                <b>Marca: </b>{currentPolicy.vehicle?.brand?.name}
              </li>
              <li>
                <b>Modelo: </b>{currentPolicy.vehicle?.model?.name}
              </li>
              <li>
                <b>A&ntilde;o: </b>{currentPolicy.vehicle?.year?.value}
              </li>
            </ul>
          </Card>
        </section>
      }
      <section>
        <h2 className="text-center">Toma las siguientes fotos</h2>
        <div className="vehicle-picture-list flex space-around" validating-picture={isPictureValidating === true ? '' : null}>
          <InsurancePictureItem
            token={currentToken}
            onSelect={(file) => onSelectPicture(file, VEHICLE_PICTURE_TYPE_CODES.FRONT)}
            onIsValidating={setIsPictureValidating}
            onError={(name) => setErrorName(name)}
            backgroundImage={Util.ASSET.CAR_FRONT}>
            Frente
          </InsurancePictureItem>
          <InsurancePictureItem
            token={currentToken}
            onSelect={(file) => onSelectPicture(file, VEHICLE_PICTURE_TYPE_CODES.RIGHT_SIDE)}
            onIsValidating={setIsPictureValidating}
            onError={(name) => setErrorName(name)}
            backgroundImage={Util.ASSET.CAR_RIGHT}>
            Lado derecho
          </InsurancePictureItem>
          <InsurancePictureItem
            token={currentToken}
            onSelect={(file) => onSelectPicture(file, VEHICLE_PICTURE_TYPE_CODES.LEFT_SIDE)}
            onIsValidating={setIsPictureValidating}
            onError={(name) => setErrorName(name)}
            backgroundImage={Util.ASSET.CAR_LEFT}>
            Lado izquierdo
          </InsurancePictureItem>
          <InsurancePictureItem
            token={currentToken}
            onSelect={(file) => onSelectPicture(file, VEHICLE_PICTURE_TYPE_CODES.BACK)}
            onIsValidating={setIsPictureValidating}
            onError={(name) => setErrorName(name)}
            backgroundImage={Util.ASSET.CAR_BACK}>
            Atr&aacute;s
          </InsurancePictureItem>
        </div>
      </section>
      <section className="flex align-center justify-center button-wrapper border-box width-100">
        <Button
          onClick={(e) => { e.preventDefault(); uploadPictures() }}
          disabled={!isValidPictures || isUploading}
          type="button">Enviar
        </Button>
      </section>

      <InsurancePictureError errorName={errorName} onClose={() => setErrorName('')} />

      <Modal position="center" show={isUploading}>
        <div className="uploading-wrapper flex column align-center justify-center">
          <Spinner color="main" relative />
          <p className="text-center"><b>Estamos cargando las fotos de tu veh&iacute;culo.</b></p>
          <p className="text-center">Por favor, espere...</p>
        </div>
      </Modal>
    </div>
  );
};

export default InsurancePicturesPage;